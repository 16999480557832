import * as React from "react"
import ApiClient, { QueryWhereClause } from "../api/ApiClient"
import { createLazyResource, formatNumber, safeNull } from "../common/Util"
import { observer } from "mobx-react"
import { observable } from "mobx"
import { Button } from "reactstrap"
import BaseView from "./BaseView"
import { LinkContainer } from "react-router-bootstrap"
import { Routes } from "../routes/AppRoutes"
import { route } from "../routes/routes"
import { Link } from "react-router-dom"
import classNames from 'classnames'
import Category from "../models/Category"
import RequirePermission, { Permission } from "../components/RequirePermission"
import { ApiTableViewAdapter } from "../components/table-view/ApiTableViewAdapter"
import { UserRole } from "../models/AuthUser"
import RequireRole from "../components/RequireRole"
import ManagedTableView from "../components/table-view/ManagedTableView";
import { ManagedChooseableColumn } from "../components/table-view/ManagedTableViewColumnChooser";
import CategoriesCsvTransformer from "../models/renderers/CategoriesCsvTransformer";

@observer
export default class CategoriesView extends BaseView {
  @observable private numberOfActiveCategories = createLazyResource<number>(() => {
    return ApiClient.query(`categories { * }`, { returnTotal: true, limit: 0, where: [{ _scope: 'active' }] })
  }, response => response.data._meta.total);

  @observable private showInactive = false

  @observable private availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'name',
      column: {
        title: 'Category Name',
        accessor: 'name',
        sortable: true,
        renderItem: (value, item) => {
          return <>
            <Link type="link" to={{ pathname: route(Routes.system.categories.edit, { id: item.id }), state: { id: item.id } }}>
              <div className={classNames({ 'text-muted': item.status === 'Inactive' })}>
                {item.name}{item.status === 'Inactive' && ' (Inactive)'}
              </div>
            </Link>
            <div className="text-muted">{item.description}</div>
          </>
        }
      },
      default: true
    },
    {
      id: 'activeMembers',
      column: {
        title: 'Active Members',
        accessor: 'numberOfActiveMembers',
        headerClassName: 'justify-content-center',
        sortable: true,
        renderItem: (value, item) => {
          return (
            <div className="text-center">
              <Link type="link" to={{ pathname: route(Routes.system.members.index), state: { category: { id: item.id, name: item.name } } }}>{formatNumber(item.numberOfActiveMembers)}</Link>
            </div>
          )
        },
      },
      default: true
    }
  ]

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>Categories</h1>
        <ul className="content-header-actions">
          <RequirePermission permission={Permission.CreateCategory}>{() =>
            <li>
              <LinkContainer to={route(Routes.system.categories.create)}>
                <Button color="primary" onClick={() => {
                }}><i className="fa fa-plus-circle"/> Add New Category</Button>
              </LinkContainer>
            </li>
          }</RequirePermission>
        </ul>
      </>
    )
  }

  private tableViewRef = React.createRef<ManagedTableView>()

  private categoriesAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      if (!safeNull(() => this.tableViewRef.current!.tableViewFilters!.showInactive)) {
        where.push({ _scope: 'active' })
      }

      return {
        query: `
      categories {
        *
      }
    `,
        where: where,
      }
    },
    'categories',
    Category
  )

  renderContentBody (): React.ReactNode {
    return this.renderTable()
  }

  private renderTableViewHeader = () => {
    return <RequireRole role={UserRole.Admin}>
      {() => <>
        <div className="list-view-info-icon">
          <i className="flaticon-list-3"/>
        </div>
        <div className="list-view-info-text">
          <span>{this.numberOfActiveCategories.loading ? <i className="fa fa-spinner fa-spin"/> : formatNumber(this.numberOfActiveCategories.current!)}</span> Active Categories
        </div>
      </>}
    </RequireRole>
  }

  private renderTable = () => {
    return <ManagedTableView
      ref={this.tableViewRef}
      stateKey="CategoriesView"
      availableColumns={this.availableColumns}
      defaultSort={{ sortKey: 'name' }}
      searchLabel="Find A Category"
      allowShowInactive={true}
      adapter={this.categoriesAdapter}
      header={this.renderTableViewHeader()}
      exportTransformer={CategoriesCsvTransformer}
    />
  }
}