import * as React from "react"
import { observer } from "mobx-react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { DocumentLibraryDescriptor } from "../../models/DocumentLibraryItem"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import { route } from "../../routes/routes"
import { observable } from "mobx"
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import FormHelper from "../../forms/FormHelper"
import Util, { modelToSnakeCase } from "../../common/Util"
import AppStateStore from "../../stores/AppStateStore"
import ButtonLoader from "../ButtonLoader"

type CreateFolderModalProps = {
  toggle: () => void
  isOpen: boolean
  libraryDescriptor: DocumentLibraryDescriptor
  onFolderCreated: () => void
  parentDocumentLibraryItemId?: string
}

@observer
export default class CreateFolderModal extends React.Component<CreateFolderModalProps> {
  private initialFormState = {
    name: '',
  }

  @observable private formState = new FormState(this.initialFormState)
  @observable private formErrors = new ErrorBag()
  @observable private submitting = false

  private inputRef = React.createRef<HTMLInputElement>()

  private toggle = () => {
    this.formErrors.clearErrors()
    this.formState.setAll(this.initialFormState)

    if (!this.submitting) {
      this.props.toggle()
    }
  }

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private submit = () => {
    this.formErrors.clearErrors()
    this.submitting = true

    ApiClient.getInstance().post(route(ApiRoutes.documentLibrary.createFolder),
      modelToSnakeCase({
        ...this.formState.toObject(),
        ...this.props.libraryDescriptor,
        parentId: this.props.parentDocumentLibraryItemId,
      }))
      .then(() => {
        this.formState.setAll(this.initialFormState)
        this.props.onFolderCreated()
      })
      .catch(error => {
        Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      })
      .then(() => this.submitting = false)
  }

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <Modal
      isOpen={this.props.isOpen}
      toggle={this.toggle}
      onOpened={() => {
        this.inputRef.current && this.inputRef.current.focus()
      }}
    >
      <ModalHeader toggle={this.toggle}>
        Create New Folder
      </ModalHeader>
      <ModalBody>
        <form onSubmit={ev => {
          ev.preventDefault()
          this.submit()
        }}>
          {
            this.formHelper.renderTextInput({
              ref: this.inputRef,
              label: 'Folder Name',
              name: 'name',
              disabled: this.submitting,
            })
          }
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="secondary"
          onClick={this.toggle}
          disabled={this.submitting}
        >Cancel</Button>
        <ButtonLoader
          type="button"
          color="primary"
          onClick={() => this.submit()}
          loading={this.submitting}
        >Create Folder</ButtonLoader>
      </ModalFooter>
    </Modal>
  }
}