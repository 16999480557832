import * as React from "react"
import { observer } from "mobx-react"
import ILazyResource from "../models/ILazyResource"

type LazyResourceViewProps<T> = {
  resource: ILazyResource<T>
  children: ((resource: T) => React.ReactNode) | React.ReactNode
  emptyMessage: React.ReactNode
}

@observer
export default class LazyResourceView<T> extends React.Component<LazyResourceViewProps<T>, never> {
  static defaultProps = {
    emptyMessage: null
  }

  componentWillMount (): void {
    this.props.resource.refresh().then(() => {
    })
  }

  componentDidUpdate (prevProps: Readonly<LazyResourceViewProps<T>>, prevState: Readonly<never>, snapshot?: any): void {
    if (!this.props.resource.loaded) {
      this.props.resource.refresh().then(() => {})
    }
  }

  private renderChildren = (resource: T) => {
    return typeof this.props.children === 'function' ? (this.props.children as any)(resource) : this.props.children
  }

  render (): React.ReactNode {
    const resource = this.props.resource
    return resource.loaded && resource.current !== undefined ? this.renderChildren(resource.current) : this.props.emptyMessage
  }
}