import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone/moment-timezone"
import Conversation from "./Conversation"
import User from "./User"
import ReceivedMessage from "./ReceivedMessage"

export default class ConversationParticipant extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment

  conversationId: number
  userId: number

  conversation: Conversation
  user: User
  receivedMessages: ReceivedMessage

  getCasts (): {} {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',

      conversationId: 'number',
      userId: 'number',
    }
  }

  getFullName = () => {
    return this.user.member ? this.user.member.getPreferredFullName() : this.user.name
  }

  setConversation (c?: {}) {
    return c ? new Conversation().init(c) : null
  }

  setUser (u?: {}) {
    return u ? new User().init(u) : null
  }

  setReceivedMessages (m?: []) {
    return m ? m.map(mm => new ReceivedMessage().init(mm)) : []
  }
}