import * as React from "react"
import { SyntheticEvent } from "react"
import ApiClient, { QueryWhereClause } from "../api/ApiClient"
import { createLazyResource, formatNumber, safeNull } from "../common/Util"
import { observer } from "mobx-react"
import { computed, observable } from "mobx"
import * as _ from 'lodash'
import { Alert, Button } from "reactstrap"
import BaseView from "./BaseView"
import { LinkContainer } from "react-router-bootstrap"
import { Routes } from "../routes/AppRoutes"
import { route } from "../routes/routes"
import { Link } from "react-router-dom"
import classNames from 'classnames'
import Chapter from "../models/Chapter"
import RequirePermission, { Permission } from "../components/RequirePermission"
import ChaptersCsvTransformer from "../models/renderers/ChaptersCsvTransformer"
import { createBrowserHistory } from "history"
import { ApiTableViewAdapter } from "../components/table-view/ApiTableViewAdapter"
import { ManagedChooseableColumn } from "../components/table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "../components/table-view/ManagedTableView";
import AsyncSelectFilter, { AsyncSelectFilterTargetType } from '../components/table-view/filters/AsyncSelectFilter'
import { loadAreaOptionsForAdmin, loadChapterOptionsForAdmin, loadChapterStateOptions, loadMeetingDayOptions, loadMeetingTimeOptions, loadRegionOptionsForAdmin } from '../api/AsyncHelpers'
import SelectFilter, { SelectFilterTargetType } from '../components/table-view/filters/SelectFilter'

@observer
export default class ChaptersView extends BaseView {
  private history = createBrowserHistory()

  @observable private numberOfActiveChapters = createLazyResource<number>(() => {
    return ApiClient.query(`chapters { * }`, { returnTotal: true, limit: 0, where: [{ _scope: 'active' }, { _scope: 'userIsAdmin' }] })
  }, response => response.data._meta.total);

  @observable private filteredSupportArea?: {id: number, name: string}
  @observable private filteredArea?: {id: number, name: string}
  @observable private filteredRegion?: {id: number, name: string}

  @computed get tableViewStateKey () {
    let parts = ['ChaptersView']

    if (this.filteredSupportArea) {
      parts.push(`SupportArea-${this.filteredSupportArea.id}`)
    }

    if (this.filteredArea) {
      parts.push(`Area-${this.filteredArea.id}`)
    }

    if (this.filteredRegion) {
      parts.push(`Region-${this.filteredRegion.id}`)
    }

    return parts.join('-')
  }

  componentWillMount (): void {
    this.filteredSupportArea = _.get(this, 'props.location.state.supportArea')
    this.filteredArea = _.get(this, 'props.location.state.area')
    this.filteredRegion = _.get(this, 'props.location.state.region')
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>Chapters</h1>
        <ul className="content-header-actions">
          <RequirePermission permission={Permission.CreateChapter}>{() =>
            <li>
              <LinkContainer to={route(Routes.system.chapters.create)}>
                <Button color="primary" onClick={() => {
                }}><i className="fa fa-plus-circle"/> Add New Chapter</Button>
              </LinkContainer>
            </li>
          }</RequirePermission>
        </ul>
      </>
    )
  }

  @observable private availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'name',
      column: {
        title: 'Chapter Name',
        sortable: true,
        accessor: 'name',
        renderItem: (value, item) => {
          return (<div className={classNames({ 'text-muted': item.isDisabled() })}>
            <Link to={route(Routes.system.chapters.show, { id: item.id })}>{item.name}{item.isDisabled() && ` (${item.status})`}</Link>
          </div>)
        },
      },
      default: true
    },
    {
      id: 'region',
      column: {
        title: 'Region',
        sortable: true,
        accessor: 'area.region.name',
      },
      default: true
    },
    {
      id: 'area',
      column: {
        title: 'Area',
        sortable: true,
        accessor: 'area.name',
      },
      default: true
    },
    {
      id: 'state',
      column: {
        title: 'State',
        sortable: true,
        accessor: 'meetingLocationAddress.state',
      },
      default: true
    },
    {
      id: 'memberCount',
      column: {
        title: 'Members #',
        headerClassName: 'justify-content-center',
        accessor: 'numberOfActiveMembers',
        sortable: true,
        renderItem: (value, item) => {
          return (
            <div className="text-center">
              <Link type="link" to={{ pathname: route(Routes.system.members.index), state: { chapter: { id: item.id, name: item.name } } }}>{item.numberOfActiveMembers !== undefined ? formatNumber(item.numberOfActiveMembers) : 'N/A'}</Link>
            </div>
          )
        },
      },
      default: true
    },
    {
      id: 'status',
      column: {
        title: 'Status',
        sortable: true,
        accessor: 'status',
      },
      default: true
    }
  ]

  private tableViewRef = React.createRef<ManagedTableView>()

  private chaptersAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      if (this.filteredArea) {
        where.push({
          _scope: 'area',
          value: this.filteredArea.id,
        })
      }

      if (this.filteredRegion) {
        where.push({
          _scope: 'region',
          value: this.filteredRegion.id,
        })
      }

      if (this.filteredSupportArea) {
        where.push({
          _scope: 'supportArea',
          value: this.filteredSupportArea.id
        })
      }

      if (!safeNull(() => this.tableViewRef.current!.tableViewFilters!.showInactive)) {
        where.push({ _scope: 'active' })
      }

      where.push({ _scope: 'userIsAdmin' })

      return {
        query: `
      chapters {
        *
        area {
          *
          region {
            *
          }
        }
        meetingLocationAddress {
          *
        }
        president {
          *
          
          member {
            *
          }
        }
      }
    `,
        where: where,
      }
    },
    'chapters',
    Chapter
  )

  private removeSupportAreaFilter = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.history.replace(window.location.pathname, { ...this.history.location.state, supportArea: undefined })
    this.filteredSupportArea = undefined
    this.tableViewRef.current!.fetchData()
  }

  private removeAreaFilter = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.history.replace(window.location.pathname, { ...this.history.location.state, area: undefined })
    this.filteredArea = undefined
    this.tableViewRef.current!.fetchData()
  }

  private removeRegionFilter = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.history.replace(window.location.pathname, { ...this.history.location.state, region: undefined })
    this.filteredRegion = undefined
    this.tableViewRef.current!.fetchData()
  }

  renderContentBody (): React.ReactNode {
    return this.renderTable()
  }

  private renderTableViewHeader = () => {
    return <>
      <div className="list-view-info-icon">
        <i className="flaticon-network"/>
      </div>
      <div className="list-view-info-text">
        <span>{this.numberOfActiveChapters.loading ? <i className="fa fa-spinner fa-spin"/> : formatNumber(this.numberOfActiveChapters.current!)}</span> Active Chapters
      </div>
    </>
  }

  private renderFilters = () => {
    return <>
      {this.filteredSupportArea && (
        <Alert
          color="info"
        >
          You are currently only viewing chapters in the support area: <b>{this.filteredSupportArea.name}</b><br/>
          <a onClick={this.removeSupportAreaFilter.bind(this)} href="">Click here to view all areas</a>
        </Alert>
      )}
      {this.filteredArea && (
        <Alert
          color="info"
        >
          You are currently only viewing chapters in the area: <b>{this.filteredArea.name}</b><br/>
          <a onClick={this.removeAreaFilter.bind(this)} href="">Click here to view all areas</a>
        </Alert>
      )}
      {this.filteredRegion && (
        <Alert
          color="info"
        >
          You are currently only viewing chapters in the region: <b>{this.filteredRegion.name}</b><br/>
          <a onClick={this.removeRegionFilter.bind(this)} href="">Click here to view all regions</a>
        </Alert>
      )}
    </>
  }

  private filters = [
    new AsyncSelectFilter('area', AsyncSelectFilterTargetType.Field, 'area.id', 'Area', loadAreaOptionsForAdmin),
    new AsyncSelectFilter('state', AsyncSelectFilterTargetType.Field, 'meetingLocationAddress.state', 'State', loadChapterStateOptions),
    new AsyncSelectFilter('region', AsyncSelectFilterTargetType.Field, 'area.region.id', 'Region', loadRegionOptionsForAdmin),
    new AsyncSelectFilter('chapter', AsyncSelectFilterTargetType.Field, 'id', 'Chapter Name', loadChapterOptionsForAdmin),
    new AsyncSelectFilter('meetingDay', AsyncSelectFilterTargetType.Field, 'meetingDay', 'Meeting Day', loadMeetingDayOptions),
    new AsyncSelectFilter('meetingTime', AsyncSelectFilterTargetType.Field, 'meetingTime', 'Meeting Time', loadMeetingTimeOptions),
    new SelectFilter('status', SelectFilterTargetType.Field, 'status', 'Status', [
      'Active',
      'Closed',
      'Disbanded',
      'Incubation',
      'Pre-Sign',
    ].map(s => ({ value: s, label: s }))),
  ]

  private renderTable = () => {
    return <ManagedTableView
      ref={this.tableViewRef}
      stateKey={this.tableViewStateKey}
      hideItemCount={true}
      adapter={this.chaptersAdapter}
      availableColumns={this.availableColumns}
      searchLabel="Find A Chapter"
      defaultSort={{ sortKey: 'name' }}
      exportTransformer={ChaptersCsvTransformer}
      header={this.renderTableViewHeader()}
      tableHeader={this.renderFilters()}
      allowShowInactive={true}
      filters={this.filters}
    />
  }
}
