import Config from "./Config"
import { promiseTimeout } from "./Util"
import { IAddress } from "../models/Address"
import _ from 'lodash'

export function loadGoogleMapsApi (apiKey: string, timeout: number = 1000) {
  const win = window as any

  if (win.googleApiPromise) {
    return win.googleApiPromise
  }

  win.googleApiPromise = new Promise((resolve, reject) => {
    if (typeof win.google != 'undefined') {
      resolve()
      return
    }

    try {
      const readyCallback = 'reactGoogleJsApiReadyCallback'

      const ready = new Promise(resolve => {
        window[readyCallback] = () => {
          resolve()
        }
      })

      const googleApiScript = document.createElement('script')
      googleApiScript.src = `https://maps.googleapis.com/maps/api/js?key=${Config.GOOGLE_API_KEY}&libraries=places&callback=${readyCallback}`
      googleApiScript.async = true
      googleApiScript.defer = true
      document.body.appendChild(googleApiScript)

      promiseTimeout(timeout, ready).then((p) => resolve(p), (p) => reject(p))
    } catch {
      reject(new Error("Error loading Google Maps API"))
    }
  })

  return win.googleApiPromise
}

export function placeResultToAddress (place: google.maps.places.PlaceResult): IAddress {
  return {
    address: [
      findAddressComponent(place.address_components!, 'street_number'),
      findAddressComponent(place.address_components!, 'route'),
    ].filter(p => !!p).join(' '),
    address2: '',
    city: findAddressComponent(place.address_components!, 'locality') || '',
    state: findAddressComponent(place.address_components!, 'administrative_area_level_1') || '',
    zipCode: findAddressComponent(place.address_components!, 'postal_code') || '',
    country: findAddressComponent(place.address_components!, 'country') || '',
  }
}

export function findAddressComponent (addressComponents: google.maps.GeocoderAddressComponent[], types: string[] | string) {
  if (!_.isArray(types)) {
    types = [types]
  }

  for (let idx = 0; idx < types.length; ++idx) {
    const component = addressComponents.find(c => c.types.indexOf(types[idx]) > -1)

    if (component) {
      return component.long_name
    }
  }

  return undefined
}