import { observer } from "mobx-react"
import * as React from "react"
import { SyntheticEvent } from "react"
import { computed, observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import AppStateStore from "../stores/AppStateStore"
import { submitFormRequest } from "../api/ApiHelper"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { toast } from "react-toastify"
import { modelToSnakeCase, safeNull } from "../common/Util"
import { Button } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import { route } from "../routes/routes"
import FormError from "./FormError"
import Event from '../models/Event'
import Config from "../common/Config"
import HelpTooltip from './HelpTooltip'
import { can, Permission } from './RequirePermission'
import moment from 'moment-timezone'
import auth from "../stores/AuthStore"

export type EditEventFormProps = {
  onCancel: () => void
  onSaved: () => void
  event: Event
}

@observer
export default class EditEventForm extends React.Component<EditEventFormProps> {
  static defaultProps = {
    clearAfterSave: true,
  }

  private initialFormState = {
    date: this.props.event.startsAt.clone().tz(this.props.event.timezone).format('MM/DD/YYYY'),
    startTime: this.props.event.startsAt.clone().tz(this.props.event.timezone).format('h:mm A'),
    endTime: this.props.event.endsAt.clone().tz(this.props.event.timezone).format('h:mm A'),
    timezone: this.props.event.timezone,
    showInLocalTimezone: this.props.event.showInLocalTimezone,
    title: this.props.event.title,
    notes: this.props.event.notes,
    hasWebConferenceDetails: !!this.props.event.webConferenceDetails,
    webConferenceDetails: this.props.event.webConferenceDetails,

    hasLocation: !!this.props.event.address,
    locationName: this.props.event.locationName || '',
    address: safeNull(() => this.props.event.address!.address) || '',
    address2: safeNull(() => this.props.event.address!.address2) || '',
    city: safeNull(() => this.props.event.address!.city) || '',
    state: safeNull(() => this.props.event.address!.state) || '',
    zipCode: safeNull(() => this.props.event.address!.zipCode) || '',
    country: safeNull(() => this.props.event.address!.country) || '',
  }

  @observable private formState = new FormState(this.initialFormState)

  @observable private formErrors = new ErrorBag()
  @observable private submitting = false

  @computed get canManageEvent () {
    return auth.getUser()!.isBoardMember || can(Permission.ManageEvent, { event: this.props.event, chapterId: safeNull(() => this.props.event!.chapterId), regionId: safeNull(() => this.props.event!.regionId) })
  }

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private onSubmit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()

    this.submitting = true
    AppStateStore.showModalSpinner()

    submitFormRequest(
      ApiClient.getInstance().put(route(ApiRoutes.calendar.updateEvent, { eventId: this.props.event.id }), {
        ...modelToSnakeCase(this.formState.toObject()),
      }),
      this.formState,
      this.formErrors,
      () => {
        toast.success('Calendar Event Updated')
        this.props.onSaved()
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private renderTimezoneOption = (tz: string) => <option value={tz}>{tz} - {moment().tz(tz).format('h:mm A')}</option>

  render (): React.ReactNode {
    return <>
      <form onSubmit={this.onSubmit}>
        <div className="form-row">
          <div className="col-12">
            {this.formHelper.renderTextInput({
              name: 'title',
              label: 'Event Title',
              disabled: !this.canManageEvent,
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-4">
            {this.formHelper.renderDatePickerInput({
              name: 'date',
              label: 'Date',
              disabled: !this.canManageEvent,
            })}
          </div>
          <div className="col-4">
            {this.formHelper.renderTextInput({
              type: 'time',
              name: 'startTime',
              label: 'Start Time',
              disabled: !this.canManageEvent,
            })}
          </div>
          <div className="col-4">
            {this.formHelper.renderTextInput({
              type: 'time',
              name: 'endTime',
              label: 'End Time',
              disabled: !this.canManageEvent,
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-4"/>
          <div className="col-8">
            <div className="form-group">
              <label>Timezone</label>
              <select
                className="form-control"
                name="timezone"
                value={this.formState.get('timezone') || ''}
                onChange={this.formState.onChange}
              >
                <option value="">(choose one)</option>
                <optgroup label="Pacific Time">
                  {this.renderTimezoneOption('America/Vancouver')}
                  {this.renderTimezoneOption('America/Los_Angeles')}
                </optgroup>
                <optgroup label="Mountain Time">
                  {this.renderTimezoneOption('America/Edmonton')}
                  {this.renderTimezoneOption('America/Denver')}
                  {this.renderTimezoneOption('America/Phoenix')}
                </optgroup>
                <optgroup label="Central Time">
                  {this.renderTimezoneOption('America/Winnipeg')}
                  {this.renderTimezoneOption('America/Chicago')}
                </optgroup>
                <optgroup label="Eastern Time">
                  {this.renderTimezoneOption('America/Toronto')}
                  {this.renderTimezoneOption('America/New_York')}
                </optgroup>
                <optgroup label="Hawaii Time">
                  {this.renderTimezoneOption('Pacific/Honolulu')}
                </optgroup>
                <optgroup label="Alaska Time">
                  {this.renderTimezoneOption('America/Anchorage')}
                </optgroup>
                <optgroup label="Newfoundland Time">
                  {this.renderTimezoneOption('America/St_Johns')}
                </optgroup>
                <optgroup label="Atlantic Time">
                  {this.renderTimezoneOption('America/Halifax')}
                </optgroup>
              </select>
              <FormError errors={this.formErrors} fieldName="timezone"/>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-4"/>
          <div className="col-8">
            <div className="form-check form-checkbox">
              <label>
                <input type="checkbox"
                       className="form-check-input"
                       name="showInLocalTimezone"
                       checked={this.formState.get('showInLocalTimezone')}
                       onChange={this.formState.onChange}
                       disabled={!this.canManageEvent}
                />
                <span className="label-text">Display times in local timezone</span>
              </label>
            </div>
            <FormError errors={this.formErrors} fieldName="showInLocalTimezone"/>
          </div>
        </div>
        <div className="form-row">
          <div className="col-12">
            {this.formHelper.renderTextAreaInput({
              name: 'notes',
              label: 'Notes',
              disabled: !this.canManageEvent,
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-12">
            <div className="form-check form-checkbox">
              <label>
                <input type="checkbox"
                       className="form-check-input"
                       name="hasLocation"
                       checked={this.formState.get('hasLocation')}
                       onChange={this.formState.onChange}
                       disabled={!this.canManageEvent}
                />
                <span className="label-text">This event has a location</span>
              </label>
            </div>
            <FormError errors={this.formErrors} fieldName="hasLocation"/>
          </div>
        </div>
        {this.formState.get('hasLocation') && <>
          <div className="form-row">
            <div className="col-12">
              {this.formHelper.renderTextInput({
                name: 'locationName',
                label: 'Location Name',
                disabled: !this.canManageEvent,
              })}
            </div>
          </div>
          <div className="form-row">
            <div className="col-sm-8">
              {this.formHelper.renderTextInput({
                name: 'address',
                type: 'text',
                label: 'Street',
                disabled: !this.canManageEvent,
              })}
            </div>
            <div className="col-sm-4">
              {this.formHelper.renderTextInput({
                name: 'address2',
                type: 'text',
                label: 'Suite / Room / Apt #',
                disabled: !this.canManageEvent,
              })}
            </div>
          </div>

          <div className="form-row">
            <div className="col-sm-3">
              {this.formHelper.renderTextInput({
                name: 'city',
                type: 'text',
                label: 'City',
                disabled: !this.canManageEvent,
              })}
            </div>
            <div className="col-sm-3">
              {this.formHelper.renderTextInput({
                name: 'state',
                type: 'text',
                label: 'State',
                disabled: !this.canManageEvent,
              })}
            </div>
            <div className="col-sm-3">
              {this.formHelper.renderTextInput({
                name: 'zipCode',
                type: 'text',
                label: 'Zip Code',
                disabled: !this.canManageEvent,
              })}
            </div>
            <div className="col-sm-3">
              {this.formHelper.renderSelectInput({
                name: 'country',
                options: Config.COUNTRY_OPTIONS.map(c => ({ value: c, text: c })),
                label: 'Country',
                disabled: !this.canManageEvent,
              })}
            </div>
          </div>
        </>}

        <div className="form-row">
          <div className="col-sm-12">
            <div className="form-check form-checkbox">
              <label>
                <input type="checkbox"
                       className="form-check-input"
                       name="hasWebConferenceDetails"
                       checked={this.formState.get('hasWebConferenceDetails')}
                       onChange={this.formState.onChange}
                />
                <span className="label-text">This event has web conference details</span>
              </label>
            </div>
            <FormError errors={this.formErrors} fieldName="hasWebConferenceDetails"/>
          </div>
        </div>
        {
          this.formState.get('hasWebConferenceDetails')
            ? <div className="form-row">
              <div className="col-12">
                {this.formHelper.renderTextAreaInput({
                  name: 'webConferenceDetails',
                  label: 'Web Conference Details',
                  labelAccessory: <HelpTooltip
                    content="Paste the info needed to join your web conference in this field. Make sure to include any web links, conference phone numbers and pin codes provided for your web conference."
                    placement="right"
                  />,
                })}
              </div>
            </div>
            : null
        }

        <div className="form-buttons">
          <Button color="secondary" onClick={() => this.props.onCancel()}>Cancel</Button>
          <ButtonLoader type="submit" color="primary" loading={this.submitting}>Save Changes</ButtonLoader>
        </div>
      </form>
    </>
  }
}
