import BaseView from "./BaseView"
import { observer } from "mobx-react"
import * as React from "react"
import BackLink from "../components/BackLink"
import Application, { ApplicationType } from "../models/Application"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { observable } from "mobx"
import { BarLoader } from "react-spinners"
import { LinkContainer } from "react-router-bootstrap"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import Util, { formatCurrency, safeNull } from "../common/Util"
import AddressView from "../components/AddressView"
import { Link, Redirect } from "react-router-dom"
import { ApproveApplicationModal } from "../components/ApproveApplicationModal"
import AuthStore from "../stores/AuthStore"
import AppStateStore from "../stores/AppStateStore"

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

@observer
export default class ApplicationDetail extends BaseView<Props> {
  @observable private application: Application
  @observable private error: string
  @observable private redirect: string

  @observable private isApproveModalVisible = false
  @observable private isDeclineModalVisible = false

  componentDidMount (): void {
    super.componentDidMount()

    ApiClient.query(`
application {
  *

  membershipPaymentMethod {
    id
    description
  }

  renewalPaymentMethod {
    id
    description
  }

  member {
    id
    fullName
  }

  guest {
    id
    fullName
  }

  category {
    id
    name
  }

  targetChapter {
    id
    name
  }

  sponsor {
    id
    fullName

    chapter {
      id
      name
    }
  }

  chapterTransferRequest {
    oldChapter {
      id
      name
    }
  }
}
    `, {
      where: [{ id: this.props.match.params.id }]
    }).then(response => this.application = new Application().init(response.data.application))
      .catch(error => {
        this.error = error.response.status === 404 ? 'Application not found' : 'There was an error loading the application'
      })
  }

  private declineApplication = () => {
    this.isDeclineModalVisible = false

    ApiClient.getInstance().post(route(ApiRoutes.applications.deny, { id: this.application.id }))
      .then(response => this.redirect = route(Routes.system.guests.show, { id: this.application.guestId }))
      .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>Member Application</h1>
        {this.application && <ul className="content-header-actions">
          <li>
            {
              this.application.applicationType === ApplicationType.ChapterTransfer
                ? <LinkContainer to={route(Routes.system.members.show, { id: this.application.member.id })}>
                  <Button color="primary" onClick={() => {
                  }}>View Member Profile</Button>
                </LinkContainer>
                : <LinkContainer to={route(Routes.system.guests.show, { id: this.application.guest.id })}>
                  <Button color="primary" onClick={() => {
                  }}>View Guest Profile</Button>
                </LinkContainer>
            }
          </li>
        </ul>}
      </>
    )
  }

  renderContentBody (): React.ReactNode | null {
    return this.redirect
      ? <Redirect to={this.redirect}/>
      : this.error
        ? <div className="alert alert-danger">{this.error}</div>
        : this.application ? this.renderContent(this.application) : <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
  }

  private renderContent = (application: Application) => {
    if (application.status === 'Pending') {
      return <>
        {
          application.applicationType === ApplicationType.ChapterTransfer
            ? <p>The outgoing chapter ({application.chapterTransferRequest.oldChapter.name}) has not approved the chapter transfer yet</p>
            : <p>{application.guest.fullName} has not submitted their application</p>
        }
      </>
    } else {
      return this.renderApplication(application)
    }
  }

  private renderApplication = (application: Application) => {
    let sectionNumber = 1

    return <div className="application-detail">
      {!application.isProcessed && <>
        <div className="row mt-4">
          <div className="col-md-6 col-sm-12">
            <Button
              block
              onClick={() => this.isDeclineModalVisible = true}
              color="danger">
              Decline Application
            </Button>
          </div>
          <div className="col-md-6 col-sm-12">
            <Button
              block
              onClick={() => this.isApproveModalVisible = true}
              color="success">
              Approve Application
            </Button>
          </div>
        </div>
        <br/>
      </>}

      {
        application.applicationType === ApplicationType.ChapterTransfer
          ? <Alert color="info">
            This is a chapter transfer from <b>{application.chapterTransferRequest.oldChapter.name}</b>
          </Alert>
          : null
      }

      <div className="form-row">
        <div className="col-sm-12 col-md-5">
          <label>Chapter</label>
          {safeNull(() => application.targetChapter!.name) || '---'}
        </div>
        <div className="col-sm-12 col-md-5">
          <label>Category</label>
          {safeNull(() => application.category!.name) || '---'}
        </div>
        <div className="col-sm-12 col-md-2">
          <label>Submitted</label>
          {safeNull(() => application.submittedAt.format('MM/DD/YYYY')) || '---'}
        </div>
      </div>

      <SectionHeader number={sectionNumber++} title="Contact Information"/>

      <div className="form-row">
        <div className="col-sm-2">
          <label>Prefix</label>
          {safeNull(() => application.prefix) || '---'}
        </div>
        <div className="col-sm-3">
          <label>First Name</label>
          {safeNull(() => application.firstName) || '---'}
        </div>
        <div className="col-sm-3">
          <label>Last Name</label>
          {safeNull(() => application.lastName) || '---'}
        </div>
        <div className="col-sm-2">
          <label>Prefix</label>
          {safeNull(() => application.suffix) || '---'}
        </div>
        <div className="col-sm-2">
          <label>Gender</label>
          {safeNull(() => application.gender) || '---'}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <label>Email</label>
          {safeNull(() => application.email) || '---'}
        </div>
        <div className="col-sm-6">
          <label>Phone</label>
          {safeNull(() => application.phone) || '---'}
        </div>
      </div>

      <h6>Address Information</h6>

      <div>
        <AddressView address={{
          address: application.address || '',
          address2: application.address2 || '',
          city: application.city || '',
          state: application.state || '',
          zipCode: application.zipCode || '',
          country: application.country || '',
        }}/>
      </div>

      <SectionHeader number={sectionNumber++} title="Business Information"/>

      <h6>Business Category</h6>
      <div className="form-row">
        <div className="col-sm-12">
          {safeNull(() => application.category!.name) || '---'}
        </div>
      </div>

      <h6>Contact Information</h6>

      <div className="form-row">
        <div className="col-sm-4">
          <label>Business Name</label>
          {safeNull(() => application.businessName) || '---'}
        </div>
        <div className="col-sm-4">
          <label>Business Phone</label>
          {safeNull(() => application.businessPhone) || '---'}
        </div>
        <div className="col-sm-4">
          <label>Business Fax</label>
          {safeNull(() => application.businessFax) || '---'}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-8">
          <label>Business Email Address</label>
          {safeNull(() => application.businessEmail) || '---'}
        </div>
        <div className="col-sm-4">
          <label>Business Website</label>
          {safeNull(() => application.businessWebsite) || '---'}
        </div>
      </div>

      <h6>Address Information</h6>

      <div>
        <AddressView address={{
          address: application.businessAddress || '',
          address2: application.businessAddress2 || '',
          city: application.businessCity || '',
          state: application.businessState || '',
          zipCode: application.businessZipCode || '',
          country: application.businessCountry || '',
        }}/>
      </div>

      {
        application.applicationType !== ApplicationType.CompanyReplacement && application.sponsor
          ? <>
            <SectionHeader number={sectionNumber++} title="Sponsor Information"/>

            <div className="form-row">
              <div className="col-sm-6">
                <label>Sponsor's Name</label>
                {safeNull(() => <Link to={route(Routes.system.members.show, { id: application.sponsor.id })}>{application.sponsor.fullName}</Link>) || '---'}
              </div>
              <div className="col-sm-6">
                <label>Sponsor's Chapter</label>
                {safeNull(() => <Link to={route(Routes.system.chapters.show, { id: application.sponsor.chapter!.id })}>{application.sponsor.chapter!.name}</Link>) || '---'}
              </div>
            </div>
          </>
          : null
      }
      <SectionHeader number={sectionNumber++} title="Payment Information"/>

      <div className="form-row">
        <div className="col-sm-12">
          {safeNull(() => application.membershipPaymentMethod.description) || '---'}
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-6">
          {
            this.application.isVeteran
              ? <div className="callout-box">
                Veteran discount has been applied
              </div>
              : null
          }
        </div>
        <div className="col-md-6">
          <div className="callout-box text-right">
            <span style={{ fontSize: 16 }}>
              Total Due: {formatCurrency(application.membershipFee)} USD
              {application.taxRate ? ' (plus tax)' : ''}
            </span>
          </div>
        </div>
      </div>

      <SectionHeader number={sectionNumber++} title="Renewal"/>

      <div className="form-row">
        <div className="col-sm-6">
          <label>Renewal Method</label>
          {application.renewalMethod}
        </div>
        <div className="col-sm-6">
          <label>Payment Method</label>
          {
            application.renewalPaymentMethod
              ? application.renewalPaymentMethod.description
              : 'Same As Above'
          }
        </div>
      </div>

      <SectionHeader number={sectionNumber++} title="Acceptance and Signature"/>

      <p>
        I am interested in: <b>{application.boardInterestOption}</b>. My interests are <b>{application.boardInterestText}</b>.
      </p>

      <div className="application-subheader">Application Signature</div>
      <p>
        My signature below attests that I understand that <b>LeTip International dues are non-refundable</b>,
        and that I have read, understand, and agree to abide by LeTip International's Application Agreement,
        fee structure and program requirements. I also understand that if I resign from LeTip, or my membership
        is terminated by the board of directors or by a LeTip International representative, <b>my membership
        and renewal dues are non-refundable.</b>
      </p>

      <div>
        <img src={`${application.renewalSignatureImageUrl}?token=${AuthStore.generateTempAuthToken()}`}/>
      </div>

      <div className="application-subheader">Application Agreement and Signature</div>

      <div>
        <img src={`${application.applicationSignatureImageUrl}?token=${AuthStore.generateTempAuthToken()}`}/>
      </div>

      {!application.isProcessed && <>
        <div className="row mt-4">
          <div className="col-md-6 col-sm-12">
            <Button
              block
              onClick={() => this.isDeclineModalVisible = true}
              color="danger">
              Decline Application
            </Button>
          </div>
          <div className="col-md-6 col-sm-12">
            <Button
              block
              onClick={() => this.isApproveModalVisible = true}
              color="success">
              Approve Application
            </Button>
          </div>
        </div>
      </>}

      <ApproveApplicationModal
        isOpen={this.isApproveModalVisible}
        toggle={() => this.isApproveModalVisible = !this.isApproveModalVisible}
        onCancel={() => this.isApproveModalVisible = false}
        onSaved={memberId => this.redirect = route(Routes.network.member, { memberId })}
        application={application}/>

      <Modal
        isOpen={this.isDeclineModalVisible}
        size="md"
      >
        <ModalHeader
          toggle={() => this.isDeclineModalVisible = false}
        >
          Decline Application
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you want to decline this application? The applicant will be notified and no payment will be collected.</p>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => this.isDeclineModalVisible = false}
            color="secondary">
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={() => this.declineApplication()}
          >
            Decline Application
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  }
}

@observer
class SectionHeader extends React.Component
  <{
    number: number,
    title: string,
  }> {

  render (): React.ReactNode {
    return <div className="application-section-header">
      <div className="application-section-number">{this.props.number}</div>
      <div className="application-section-title">{this.props.title}</div>
    </div>
  }
}
