import * as React from "react"
import AppStateStore from "../stores/AppStateStore"
import Config from "../common/Config"

export default class BadgeCountUpdater extends React.Component {
  private interval: any

  componentDidMount (): void {
    AppStateStore.fetchBadgeCounts()

    this.interval = setInterval(() => AppStateStore.fetchBadgeCounts(), Config.FETCH_BADGE_COUNTS_INTERVAL)
  }

  componentWillUnmount (): void {
    clearInterval(this.interval)
  }

  render () {
    return null
  }
}