import * as React from "react"
import { observer } from "mobx-react"
import { computed, observable } from "mobx"
import { Badge, Popover, PopoverBody, PopoverHeader } from "reactstrap"
import { formatNumber, humanizeTimePeriod } from "../common/Util"
import MemberNotification, { NotificationType } from "../models/MemberNotification"
import moment from 'moment-timezone'
import classNames from 'classnames'
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { route } from "../routes/routes"
import AuthStore from "../stores/AuthStore"
import * as _ from "lodash"
import { Routes } from "../routes/AppRoutes"
import { TIPS_VIEW_TABS } from "../views/member/MemberTipsView"
import { RouteComponentProps, withRouter } from "react-router"
import Config from "../common/Config"

@observer
class MemberNotifications extends React.Component<RouteComponentProps> {
  @observable private showMemberNotifications = false

  @observable private memberNotifications: MemberNotification[] = []

  fetchMemberNotifications = () => {
    if (AuthStore.getUser()!.member) {
      ApiClient.query(`
      memberNotifications {
        *
      }`, {
        where: [
          { memberId: AuthStore.getUser()!.member!.id },
          { _scope: 'recent' },
        ],
        order: [
          { id: 'createdAt', desc: true },
        ]
      }).then(response => {
        this.memberNotifications = response.data.memberNotifications.map((n: {}) => new MemberNotification().init(n))
      }).catch(() => {
      })
    }
  }

  private interval?: any

  componentDidMount (): void {
    this.fetchMemberNotifications()

    this.interval = setInterval(() => this.fetchMemberNotifications(), Config.FETCH_NOTIFICATIONS_INTERVAL)
  }

  componentWillUnmount (): void {
    clearInterval(this.interval)
  }

  @computed
  private get unreadNotificationCount () {
    return this.memberNotifications.filter(n => !n.readAt).length
  }

  private markAllRead = () => {
    this.memberNotifications.map(notification => {
      notification.readAt = moment()
    })

    if (AuthStore.getUser()!.member && this.memberNotifications.length) {
      const max = _.maxBy(this.memberNotifications, 'id')

      max && ApiClient.getInstance().post(route(ApiRoutes.members.markNotificationsRead, { id: AuthStore.getUser()!.member!.id }), {
        last_id: max.id,
      })
        .then(() => {
        })
        .catch(() => {
        })
    }

    this.showMemberNotifications = false
  }

  private markRead = (notification: MemberNotification) => {
    notification.readAt = moment()

    if (AuthStore.getUser()!.member) {
      ApiClient.getInstance().post(route(ApiRoutes.members.markNotificationsRead, { id: AuthStore.getUser()!.member!.id }), {
        id: notification.id,
      })
        .then(() => {
        })
        .catch(() => {
        })
    }
  }

  private onNotificationClicked = (notification: MemberNotification) => {
    if (!notification.readAt) {
      this.markRead(notification)
    }

    if (notification.type === NotificationType.TipReceived) {
      this.props.history.push(route(Routes.member.tips, { tab: TIPS_VIEW_TABS.receivedTips }))
      this.showMemberNotifications = false
    } else if (notification.type === NotificationType.MessageReceived) {
      this.props.history.push(route(Routes.member.messages, { conversationId: notification.meta.conversationId }))
      this.showMemberNotifications = false
    }
  }

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <>
      <a
        id="memberPopoverIcon"
        href=""
        style={{
          position: 'relative',
        }}
        onClick={ev => {
          ev.preventDefault()
          this.showMemberNotifications = true
        }}>
        <i style={{ fontSize: 30 }} className={classNames("flaticon flaticon-alert text-info notification-bell", { ring: this.unreadNotificationCount > 0 })}/>
        {
          this.unreadNotificationCount
            ? <div style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              minWidth: 20,
              height: 20,
              paddingLeft: 3,
              paddingRight: 3,
              backgroundColor: '#f00',
              color: '#fff',
              borderRadius: 10,
              textAlign: 'center',
              fontWeight: 500,
            }}>{formatNumber(this.unreadNotificationCount)}
            </div>
            : null
        }
      </a>
      <Popover placement="bottom" isOpen={this.showMemberNotifications} target="memberPopoverIcon" toggle={() => this.showMemberNotifications = false}>
        <PopoverHeader>
          <div className="member-notifications-header">
            <div className="member-notifications-header-title">
              Notifications
            </div>
            <a href=""
               style={{
                 fontSize: 10,
               }}
               onClick={ev => {
                 ev.preventDefault()
                 this.markAllRead()
               }}>Mark All Read</a>
            <a href=""
               style={{
                 color: '#555',
               }}
               onClick={ev => {
                 ev.preventDefault()
                 this.showMemberNotifications = false
               }}><i className="fa fa-times"/></a>
          </div>
        </PopoverHeader>
        <PopoverBody>
          {
            this.memberNotifications.length
              ? <NotificationList
                notifications={this.memberNotifications}
                onNotificationClicked={this.onNotificationClicked}/>
              : <p>You do not have any notifications</p>
          }
        </PopoverBody>
      </Popover>
    </>
  }
}

@observer
class NotificationList extends React.Component<{
  notifications: MemberNotification[]
  onNotificationClicked: (notification: MemberNotification) => void
}> {

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <ul className="member-notification-list">
      {this.props.notifications.map(notification => <NotificationListItem
        key={notification.id}
        notification={notification}
        onClick={this.props.onNotificationClicked}
      />)}
    </ul>;
  }
}

@observer
class NotificationListItem extends React.Component<{
  notification: MemberNotification
  onClick: (notification: MemberNotification) => void
}> {
  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <li
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        this.props.onClick(this.props.notification)
      }}
    >
      <div className="notification-age">
        {
          this.props.notification.readAt
            ? null
            : <Badge color="success">New</Badge>
        }
        &nbsp;
        {humanizeTimePeriod(moment().unix() - this.props.notification.createdAt.unix(), 1)} ago
      </div>
      <div className="notification-content">
        <i className={classNames('notification-icon', this.props.notification.iconClass)}/>
        <div className="notification-text">{this.props.notification.message}</div>
      </div>
      {
        (this.props.notification.type === NotificationType.NotificationReceived && this.props.notification.meta.url)
          ? <div className="notification-link"><a target="_blank" href={this.props.notification.meta.url}>View Link</a></div>
          : null
      }
    </li>;
  }
}

export default withRouter(MemberNotifications)
