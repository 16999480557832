import * as React from "react"
import Member from "../models/Member"
import ContactMethod from "../models/ContactMethod"
import AddressView from "./AddressView"
import { observable } from "mobx"
import { observer } from "mobx-react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import MemberBusinessInfoForm from "./MemberBusinessInfoForm"
import MemberBusinessDescriptionForm from "./MemberBusinessDescriptionForm"
import ContactMethodForm from "./ContactMethodForm"
import { can, Permission } from "./RequirePermission"
import { normalizeUrl, safeNull } from "../common/Util"
import BusinessAddressForm from "./BusinessAddressForm"

type Props = {
  member: Member
  onMemberInvalidated: () => void
}

@observer
export default class MemberBusiness extends React.Component<Props, never> {
  @observable
  private showModal = false
  @observable
  private editModalContent?: React.ReactNode
  @observable
  private editModalSize = 'lg'
  @observable
  private editModalTitle = 'Edit'

  private showEditModal = (title: string, component: React.ReactNode, size: string = 'lg') => {
    this.editModalTitle = title
    this.editModalContent = component
    this.showModal = true
    this.editModalSize = size
  }

  private closeEditModal = () => {
    this.showModal = false
    this.editModalContent = undefined
  }

  render (): React.ReactNode {
    const { member } = this.props
    const business = member.business

    return business ?
      <>
        <div className="detail-pane">
          <h5 className="text-muted">Business Information {
            can(Permission.EditMember, { memberId: member.id, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region.id) })
              ? <a href="" onClick={ev => {
                ev.preventDefault()
                this.showEditModal('Business Info',
                  <MemberBusinessInfoForm
                    member={member}
                    onCancel={() => this.closeEditModal()}
                    onSaved={() => {
                      this.closeEditModal()
                      this.props.onMemberInvalidated()
                    }}
                  />)
              }}><i className="fa fa-pencil"/></a>
              : null
          }</h5>
          <div className="row">
            <div className="col-sm-6">
              <label>Name</label>
              {business.name || '---'}
            </div>
            <div className="col-sm-6">
              <label>Website</label>
              {business.websiteUrl ? <a href={normalizeUrl(business.websiteUrl)} target="_blank">{business.websiteUrl}</a> : '---'}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <label>Contact Phone</label>
              {business.phoneNumber || '---'}
            </div>
            <div className="col-sm-6">
              <label>Contact Email</label>
              {business.emailAddress ? <a target="_blank" href={`mailto:${business.emailAddress}`}>{business.emailAddress}</a> : '---'}
            </div>
          </div>

          <h5 className="text-muted">Social Media Profiles</h5>
          <div className="form-row">
            <div className="col-12">
              {this.renderSocialMediaLinks(business.socialLinks)}
            </div>
          </div>

          <div className="detail-pane-spacer"/>
          <div className="row">
            <div className="col-sm-12">
              <h5 className="text-muted">Business Address {
                can(Permission.EditMember, { memberId: member.id, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region.id) })
                  ? <a href="" onClick={ev => {
                    ev.preventDefault()
                    this.showEditModal('Business Address',
                      <BusinessAddressForm
                        memberId={member.id}
                        businessId={business.id}
                        onSaved={() => {
                          this.props.onMemberInvalidated()
                          this.closeEditModal()
                        }}
                        onCancel={() => this.closeEditModal()}
                      />, 'md')
                  }}><i className="fa fa-pencil"/></a>
                  : null
              }</h5>
              {
                business.address
                  ? <AddressView address={business.address}/>
                  : <div className="text-muted">You have not entered a business address yet</div>
              }
            </div>
          </div>
          <div className="detail-pane-spacer"/>
          <div className="row">
            <div className="col-sm-12">
              <h5 className="text-muted">About My Business {
                can(Permission.EditMember, { memberId: member.id, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region.id) })
                  ? <a href="" onClick={ev => {
                    ev.preventDefault()
                    this.showEditModal('About My Business',
                      <MemberBusinessDescriptionForm
                        member={member}
                        onFinished={(dirty) => {
                          if (dirty) {
                            this.props.onMemberInvalidated()
                          }

                          this.closeEditModal()
                        }}
                      />, 'md')
                  }}><i className="fa fa-pencil"/></a>
                  : null
              }</h5>
              {
                business.description
                  ? <div className="display-linebreaks">{business.description}</div>
                  : <div className="text-muted">You have not entered a business description yet</div>
              }
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.showModal}
          size={this.editModalSize}
        >
          <ModalHeader>{this.editModalTitle}</ModalHeader>
          <ModalBody>{this.editModalContent}</ModalBody>
        </Modal>
      </>
      :
      null
  }

  private renderSocialMediaLinks (social: {}) {
    const sites = {
      'facebook': { name: 'Facebook', icon: 'fa fa-facebook-square' },
      'twitter': { name: 'Twitter', icon: 'fa fa-twitter-square' },
      'linkedin': { name: 'Linkedin', icon: 'fa fa-linkedin-square' },
      'instagram': { name: 'Instagram', icon: 'fa fa-instagram' },
      'youtube': { name: 'YouTube', icon: 'fa fa-youtube' },
      'alignable': { name: 'Alignable', icon: 'fa fa-home' },
      'pinterest': { name: 'Pinterest', icon: 'fa fa-pinterest' },
      'snapchat': { name: 'Snapchat', icon: 'fa fa-snapchat' },
      'vimeo': { name: 'Vimeo', icon: 'fa fa-vimeo' },
    }

    const links = [
      'facebook',
      'twitter',
      'linkedin',
      'instagram',
      'youtube',
      'alignable',
      'pinterest',
      'snapchat',
      'vimeo',
    ].filter(soc => !!social[soc])

    return links.length
      ? links.map(soc => <div key={soc} className="social-link-list-item">
        <a href={normalizeUrl(social[soc])} target="_blank"><i className={sites[soc].icon}/> {sites[soc].name}</a>
      </div>)
      : <p>Social media links have not been entered</p>
  }
}
