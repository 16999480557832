import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"

export default class SystemMessageTemplate extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  templateName: string
  templateSource: string
  hash: string

  getCasts () {
    return {
      createdAt: 'datetime',
      updatedAt: 'datetime',
    }
  }
}
