import { observer } from "mobx-react"
import * as React from "react"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import { observable, toJS } from "mobx"
import SearchableMemberList, { SearchableMemberListFilterType } from "../SearchableMemberList"
import Member from "../../models/Member"
import ApiClient from "../../api/ApiClient"
import { modelToCamelCase } from "../../common/Util"
import { Routes } from "../../routes/AppRoutes"
import { route } from "../../routes/routes"
import * as _ from "lodash"
import { LinkContainer } from "react-router-bootstrap"

type Props = {
  value?: number
  onChange: (member: Member | undefined) => void
  showViewProfile: boolean
  filters?: SearchableMemberListFilterType
  loading: boolean
  allowClickableColumns: boolean
  initialMemberCache: Member[]
  placeholder?: string
  allowClear: boolean
}

@observer
export class MemberPickerInput extends React.Component<Props> {
  static defaultProps = {
    showViewProfile: false,
    onChange: () => {
    },
    loading: false,
    initialMemberCache: [],
    placeholder: undefined,
    allowClickableColumns: true,
    allowClear: true
  }

  private membersCache = {}

  @observable
  private isOpen = false
  @observable
  private member?: Member
  @observable
  private loading = false
  @observable
  private error?: string

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    this.warmCache()

    if (this.props.value !== prevProps.value) {
      this.updateMember()
    }
  }

  private warmCache = () => {
    this.props.initialMemberCache.forEach(m => this.membersCache[m.id] = toJS(m))
  }

  private updateMember = () => {
    if (this.props.value) {
      if (!this.member || this.member.id !== this.props.value) {
        this.loading = true

        if (this.membersCache[this.props.value]) {
          this.member = this.membersCache[this.props.value]
          this.loading = false
        } else {
          ApiClient.query(`
member {
  *
}
          `, {
            where: [{ id: this.props.value }],
          })
            .then(response => {
              this.member = new Member(modelToCamelCase(response.data.member))
              this.membersCache[this.member.id] = this.member
            })
            .catch(() => this.error = 'Error loading data')
            .then(() => {
              this.loading = false
            })
        }
      }
    } else {
      this.member = undefined
    }
  }

  componentWillMount (): void {
    this.warmCache()
    this.updateMember()
  }

  private showModal = () => {
    this.isOpen = true
  }

  private closeModal = () => {
    this.isOpen = false
  }

  private renderMember = () => {
    return this.member ? this.member.fullName : `Member #${this.props.value}`
  }

  private clear = () => {
    this.props.onChange(undefined)
  }

  render (): React.ReactNode {
    return <>
      <div className="item-picker-input">
        <div className="item-picker-selection">
          {this.loading || this.props.loading
            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
            : (this.props.value ? this.renderMember() : <div className="item-picker-empty">{this.props.placeholder || 'None'}</div>)}
        </div>
        {
          this.props.value && this.props.allowClear
            ? <Button
              type="button"
              title="Clear Selection"
              color="danger"
              onClick={this.clear}
              className="item-picker-button"
            ><i className="fa fa-times"/></Button>
            : null
        }
        {
          this.member && this.props.showViewProfile
            ? <LinkContainer
              to={route(Routes.system.members.show, { id: _.get(this.member, 'id') || 0 })}>
              <Button
                title="View Profile"
                color="secondary"
                className="item-picker-button"
              ><i className="fa fa-external-link"/></Button>
            </LinkContainer>
            : null
        }
        <Button
          title="Search Members"
          type="button"
          color="primary"
          onClick={this.showModal}
          className="item-picker-button"
        ><i className="fa fa-search"/></Button>
      </div>
      <Modal
        isOpen={this.isOpen}
        size="lg"
      >
        <ModalHeader toggle={this.closeModal}>
          Browse Members
        </ModalHeader>
        <ModalBody>
          <SearchableMemberList
            onChoose={(member) => {
              this.membersCache[member.id] = member
              this.closeModal()
              this.props.onChange(member)
            }}
            filters={this.props.filters}
            allowClickableColumns={this.props.allowClickableColumns}
            style={{
              maxHeight: "400px",
            }}
          />
        </ModalBody>
      </Modal>
    </>
  }
}
