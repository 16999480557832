import BaseModel from "../../models/BaseModel"
import ApiClient, { QueryOptions, QueryOrderClause, QueryWhereClause } from "../../api/ApiClient"
import axios, { CancelTokenSource } from "axios"
import { toJS } from "mobx"
import Util from "../../common/Util"
import { FetchParams, TableViewAdapter } from "./TableViewAdapter"
import EventBus from "../../common/EventBus";

type ApiTableViewAdapterFetchResults<T extends BaseModel> = {
  items: T[]
  total: number
}

export type ApiTableViewAdapterOptions<T extends BaseModel> = {
  transformResponse?: (items: T[]) => T[]
  onFetched?: (results: ApiTableViewAdapterFetchResults<T>) => void
  where?: QueryWhereClause[]
}

export type ApiTableViewAdapterQuery = {
  query: string
  where?: QueryWhereClause[]
}

export class ApiTableViewAdapter<T extends BaseModel> implements TableViewAdapter {
  private fetchDataCancelToken?: CancelTokenSource
  readonly eventBus = new EventBus()

  constructor (private query: () => ApiTableViewAdapterQuery, private resourceKey: string, private modelClass: new () => T, private options?: ApiTableViewAdapterOptions<T>) {
  }

  fetchData (fetchParams: FetchParams, callback: (items: T[], total: number) => void, error: (message: string) => void, finished?: () => void): void {
    if (this.fetchDataCancelToken) {
      this.fetchDataCancelToken.cancel()
      this.fetchDataCancelToken = undefined
    }
    this.fetchDataCancelToken = axios.CancelToken.source()

    const sort: QueryOrderClause[] = []

    if (fetchParams.sortDescriptor) {
      sort.push({
        id: fetchParams.sortDescriptor.sortKey,
        desc: fetchParams.sortDescriptor.descending,
      })
    }

    const queryOptions: QueryOptions = {
      order: sort,
    }

    const where: QueryWhereClause[] = []

    if (fetchParams.filters) {
      if (fetchParams.filters['search']) {
        where.push({ _scope: 'search', value: fetchParams.filters['search'] })
      }

      if (fetchParams.filters['filters']) {
        where.push(...fetchParams.filters['filters'].map((m: any) => toJS(m)))
      }
    }

    if (fetchParams.where) {
      where.push(...fetchParams.where)
    }

    let queryTotal = false

    if (fetchParams.offset !== undefined) {
      queryOptions.offset = fetchParams.offset
    }

    if (fetchParams.limit !== undefined) {
      queryOptions.limit = fetchParams.limit
      queryTotal = true
    }

    if (queryTotal) {
      queryOptions.returnTotal = true
    }

    const query = this.query()

    if (query.where) {
      where.push(...query.where)
    }

    queryOptions.where = where

    ApiClient.query(query.query,
      queryOptions,
      {
        cancelToken: this.fetchDataCancelToken.token,
      }
    )
      .then(response => {
        let items = response.data[this.resourceKey].map((g: {}) => new this.modelClass().init(g))
        if (this.options && this.options.transformResponse) {
          items = this.options.transformResponse(items)
        }
        const total = queryTotal ? Number(response.data._meta.total) : items.length
        callback(items, total)

        if (this.options && this.options.onFetched) {
          this.options.onFetched({
            items: items,
            total: total,
          })
        }

        this.eventBus.dispatch('fetched', {
          items: items,
          total: total,
        })

        finished && finished()
      })
      .catch(ex => {
        if (!axios.isCancel(ex)) {
          error(Util.extractErrorMessage(ex.response))
          finished && finished()
        }
      })
  }
}