import BaseView from "../BaseView"
import * as React from "react"
import { observer } from "mobx-react"
import { Button, Nav, NavItem, NavLink, TabContent } from "reactstrap"
import classNames from "classnames"
import { computed, observable, toJS } from "mobx"
import { createBrowserHistory } from 'history'
import LazyTabPane from "../../components/LazyTabPane"
import MemberCardSearch from "../../components/MemberCardSearch"
import { TipForm, TipFormModal } from "../../components/TipFormModal"
import AuthStore from "../../stores/AuthStore"
import TipCardList, { TipCardListProps } from "../../components/TipCardList"
import TipsDashboard from "../../components/TipsDashboard"
import TipBatches from "../../components/member/TipBatches"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { RouteComponentProps } from "react-router"
import Member from "../../models/Member"
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import FormHelper from "../../forms/FormHelper"
import moment from "moment-timezone"
import { LoadOptionsMethod } from "../../components/inputs/SelectInput"
import * as AsyncHelpers from '../../api/AsyncHelpers'

const TABS = {
  dashboard: 'dashboard',
  sendTip: 'send-tip',
  sendEscrowTip: 'send-escrow-tip',
  receivedTips: 'received-tips',
  sentTips: 'sent-tips',
  monthlyBatch: 'monthly-batch',
}

type Props = {
  match: {
    params: {
      tab?: string
    }
  }
} & RouteComponentProps

export { TABS as TIPS_VIEW_TABS }

@observer
export default class MemberTipsView extends BaseView<Props> {
  @observable
  private activeTab = TABS.dashboard

  private history = createBrowserHistory()

  @observable private selectedMember?: Member

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(route(Routes.member.tips, { tab: this.activeTab }))
    }
  }

  componentDidMount (): void {
    super.componentDidMount()

    this.activeTab = this.props.match.params.tab || TABS.dashboard
  }

  renderContentHeader (): React.ReactNode | null {
    return <>
      <h1>Tips</h1>
      <ul className="content-header-actions">
        <li>
          <Button color="primary" onClick={() => this.setTab(TABS.sendTip)}><i className="fa fa-money"/> Pass A New Tip</Button>
        </li>
      </ul>
    </>
  }

  render (): React.ReactNode | null {
    return <>
      <div className="card">
        <div className="card-header">
          <Nav tabs className="card-header-tabs">
            <NavItem className={classNames({ active: this.activeTab === TABS.dashboard })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.dashboard)
                }}
              >Dashboard</NavLink>
            </NavItem>
            <NavItem className={classNames({ active: this.activeTab === TABS.sendTip })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.sendTip)
                }}
              >Send A Tip</NavLink>
            </NavItem>
            <NavItem className={classNames({ active: this.activeTab === TABS.sendEscrowTip })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.sendEscrowTip)
                }}
              >Send An Escrow Tip</NavLink>
            </NavItem>
            <NavItem className={classNames({ active: this.activeTab === TABS.receivedTips })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.receivedTips)
                }}
              >My Received Tips</NavLink>
            </NavItem>
            <NavItem className={classNames({ active: this.activeTab === TABS.sentTips })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.sentTips)
                }}
              >My Sent Tips</NavLink>
            </NavItem>
            <NavItem className={classNames({ active: this.activeTab === TABS.monthlyBatch })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.monthlyBatch)
                }}
              >Monthly Batch</NavLink>
            </NavItem>
          </Nav>
        </div>

        <div className="card-body">
          <TabContent activeTab={this.activeTab}>
            <LazyTabPane tabId={TABS.dashboard} activeTab={this.activeTab}>
              <TipsDashboard/>
            </LazyTabPane>
            <LazyTabPane tabId={TABS.sendTip} activeTab={this.activeTab}>
              <MemberCardSearch
                defaultChapter={AuthStore.getUser()!.chapter}
                onCardClick={member => this.selectedMember = member}
                cardActionsComponent={member => <Button
                  onClick={() => this.selectedMember = member}
                  className="member-card-button"
                  type="button"
                  color="success"
                  block
                ><i className="fa fa-send"/>&nbsp;&nbsp;&nbsp;Send Tip</Button>}
              />
              <TipFormModal
                isOpen={!!this.selectedMember}
                member={this.selectedMember}
                onCancel={() => this.selectedMember = undefined}
                toggle={() => this.selectedMember = undefined}
                onSaved={() => {
                  this.selectedMember = undefined
                }}
              />
            </LazyTabPane>
            <LazyTabPane tabId={TABS.sendEscrowTip} activeTab={this.activeTab}>
              <div style={{ maxWidth: 800 }}>
                <TipForm
                  onSaved={() => {
                  }}
                  onCancel={() => {
                  }}
                />
              </div>
            </LazyTabPane>
            <LazyTabPane tabId={TABS.receivedTips} activeTab={this.activeTab}>
              <MemberTipsList tipDirection={'received'} memberId={AuthStore.getUser()!.member!.id} chapterId={AuthStore.getUser()!.chapter!.id}/>
            </LazyTabPane>
            <LazyTabPane tabId={TABS.sentTips} activeTab={this.activeTab}>
              <MemberTipsList tipDirection={'sent'} memberId={AuthStore.getUser()!.member!.id} chapterId={AuthStore.getUser()!.chapter!.id}/>
            </LazyTabPane>
            <LazyTabPane tabId={TABS.monthlyBatch} activeTab={this.activeTab}>
              <TipBatches
                memberId={AuthStore.getUser()!.member!.id}/>
            </LazyTabPane>
          </TabContent>
        </div>
      </div>
    </>
  }
}

type MemberTipsListProps = {
  tipDirection: 'sent' | 'received'
  memberId: number
  chapterId?: number
}

@observer
class MemberTipsList extends React.Component<MemberTipsListProps> {
  @observable private formState = new FormState({
    startDate: '',
    endDate: '',
    status: [],
    sourceMemberId: undefined,
    targetMemberId: undefined,
  })

  @observable private formErrors = new ErrorBag()
  private formHelper = new FormHelper(this.formState, this.formErrors)
  @observable private showEntireNetworkReceived = false

  @computed get tipListProps (): TipCardListProps {
    const props: TipCardListProps = {}

    if (this.props.tipDirection === 'sent') {
      props.sourceMemberId = this.props.memberId
    } else if (this.props.tipDirection === 'received') {
      props.targetMemberId = this.props.memberId
    }

    if (this.formState.get('startDate').trim()) {
      props.startDate = moment(this.formState.get('startDate'))
    }

    if (this.formState.get('endDate').trim()) {
      props.endDate = moment(this.formState.get('endDate'))
    }

    if (this.formState.get('status').length) {
      props.status = toJS(this.formState.get('status'))
    }

    if (this.props.tipDirection === 'received' && this.formState.get('sourceMemberId')) {
      props.sourceMemberId = toJS(this.formState.get('sourceMemberId'))
    }

    if (this.props.tipDirection === 'sent' && this.formState.get('targetMemberId')) {
      props.targetMemberId = toJS(this.formState.get('targetMemberId'))
    }

    return props
  }

  private loadStatusOptions: LoadOptionsMethod = (searchString, callback) => {
    callback([
      'New',
      'Bogus',
      'Invalid',
      'Closed',
      'Working',
    ].filter(o => o.toLowerCase().indexOf(searchString.trim().toLowerCase()) > -1).map(o => ({
      value: o,
      label: o,
    })))
  }

  render () {
    return <>
      <div className="row">
        <div className="col-3">
          {this.formHelper.renderDatePickerInput({
            label: 'Start Date',
            name: 'startDate',
          })}
        </div>
        <div className="col-3">
          {this.formHelper.renderDatePickerInput({
            label: 'End Date',
            name: 'endDate',
          })}
        </div>
        <div className="col-3">
          {this.formHelper.renderAsyncSelectInput({
            label: 'Status',
            name: 'status',
            isMulti: true,
            loadOptions: this.loadStatusOptions,
          })}
        </div>
        <div className="col-3">
          {
            this.props.tipDirection === 'received'
              ? <>
                <div key={`filter-received-${this.showEntireNetworkReceived ? 'network' : 'chapter'}`}>
                  {
                    this.formHelper.renderAsyncSelectInput({
                      name: 'sourceMemberId',
                      label: 'Received From',
                      loadOptions: AsyncHelpers.loadMemberOptions(this.showEntireNetworkReceived ? undefined : this.props.chapterId),
                    })
                  }
                </div>
                <div className="form-check form-checkbox">
                  <label>
                    <input type="checkbox"
                           className="form-check-input"
                           checked={this.showEntireNetworkReceived}
                           onChange={(ev) => {
                             this.showEntireNetworkReceived = ev.target.checked
                             this.formState.set('sourceMemberId', undefined)
                           }}
                    />
                    <span className="label-text">Show Entire Network</span>
                  </label>
                </div>
              </>
              : this.formHelper.renderAsyncSelectInput({
                name: 'targetMemberId',
                label: 'Sent To',
                loadOptions: AsyncHelpers.loadMemberOptions(),
              })
          }
        </div>
      </div>
      <TipCardList
        {...this.tipListProps}
        allowPrint={true}
      />
    </>
  }
}
