import * as React from "react"
import { ChangeEvent } from "react"
import { QueryWhereClause } from "../api/ApiClient"
import { observer } from "mobx-react"
import { observable } from "mobx"
import { Button } from "reactstrap"
import Config from "../common/Config"
import classNames from 'classnames'
import Chapter from "../models/Chapter"
import { ChooseableColumn, TableViewColumnChooser } from "./table-view/TableViewColumnChooser"
import TableView from "./table-view/TableView"
import { ApiTableViewAdapter } from "./table-view/ApiTableViewAdapter"
import { TableViewFilters } from "./table-view/TableViewFilters"
import { TableViewPagination } from "./table-view/TableViewPagination"

type Props = {
  hideActions: boolean
  onChoose: (chapter: Chapter) => void
  filters?: SearchableChapterListFilterType
  style: {}
  actionsComponent: (row: Chapter, onChoose: (chapter: Chapter) => void) => React.ReactNode
}

export type SearchableChapterListFilterType = {
  chapter?: number
  showInactive?: boolean
}

@observer
export default class SearchableChapterList extends React.Component<Props> {
  static defaultProps = {
    hideActions: false,
    onChoose: () => {
    },
    style: {},
    hideColumns: [],
    actionsComponent: (row: Chapter, onChoose: (chapter: Chapter) => void) => <ActionsComponent row={row} onChoose={onChoose}/>
  }

  @observable private showInactive = false

  @observable private availableColumns: ChooseableColumn[] = [
    {
      column: {
        title: 'Chapter',
        accessor: 'name',
        sortable: true,
        renderItem: (value, item) => {
          return (<div className={classNames({ 'text-muted': item.isDisabled() })}>
            {item.name}{item.isDisabled() && ` (${item.status})`}
          </div>)
        },
      },
      default: true
    },
    {
      column: {
        title: 'Area',
        accessor: 'area.name',
        sortable: true
      },
      default: true
    },
    {
      column: {
        title: 'Region',
        accessor: 'area.region.name',
        sortable: true
      },
      default: true
    },
    {
      column: {
        title: 'State',
        accessor: 'meetingLocationAddress.state',
        sortable: true
      },
      default: true
    },
    {
      column: {
        title: 'Status',
        accessor: 'status',
        sortable: true
      },
      default: true
    },
    {
      column: {
        renderItem: (value, item) => this.props.actionsComponent(item, this.onChoose)
      },
      fixed: true,
      hidden: true
    }
  ]

  @observable visibleColumns = TableViewColumnChooser.getInitialColumns(this.availableColumns)
  @observable private numberOfPages: number = 0
  @observable private currentPage: number = 1
  @observable private filters = {}
  private tableViewRef = React.createRef<TableView>()

  private setPage = (page: number) => {
    this.currentPage = page
  }

  private chaptersAdapter = new ApiTableViewAdapter(() => {
    const where: QueryWhereClause[] = []

    if (!this.showInactive) {
      where.push({ _scope: 'active' })
    }

    return {
      query: `
      chapters {
        *
        area {
          *
          region {
            *
          }
        }
        meetingLocationAddress {
          *
        }
      }
    `,
      where: where,
    }
  }, 'chapters', Chapter, {
    onFetched: result => {
      this.numberOfPages = Math.ceil(result.total / Config.TABLE_VIEW_PAGE_SIZE)
      if (this.currentPage < 1) {
        this.currentPage = 1
      }
      if (this.currentPage > this.numberOfPages) {
        this.currentPage = this.numberOfPages
      }
    }
  })

  private onShowInactiveChanged = (ev: ChangeEvent<HTMLInputElement>) => {
    this.showInactive = ev.target.checked
    this.tableViewRef.current!.fetchData()
  }

  private onChoose = (chapter: Chapter) => {
    this.props.onChoose(chapter)
  }

  render (): React.ReactNode {
    return this.renderTable()
  }

  private renderTable = () => {
    return (
      <>
        <div className="row list-view-header">
          <div className="col-sm-12 col-md-6 col-lg-8">
            <TableViewFilters
              initialState={{ searchText: '' }}
              searchLabel="Select A Chapter"
              onChanged={filters => this.filters = { search: filters.search, filters: filters.filters.map(f => f.getWhereClause()) }}
            />
            <div className="list-view-search-inactive form-check form-checkbox">
              <label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={this.showInactive}
                  onChange={this.onShowInactiveChanged}
                />
                <span className="label-text">Show Inactive</span>
              </label>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="d-flex align-items-center justify-content-end" style={{ height: '100%' }}>
              <TableViewColumnChooser
                columns={this.availableColumns}
                onChange={columns => this.visibleColumns = columns}
              />
            </div>
          </div>
        </div>
        <TableView
          hideItemCount={true}
          ref={this.tableViewRef}
          adapter={this.chaptersAdapter}
          columns={this.visibleColumns}
          pagination={{
            offset: Math.max((this.currentPage - 1), 0) * 10,
            limit: 10,
          }}
          filters={this.filters}
        />
        {
          this.numberOfPages > 1
            ? <TableViewPagination numberOfPages={this.numberOfPages} currentPage={this.currentPage} setPage={page => this.setPage(page)}/>
            : null
        }
      </>
    )
  }
}

type ActionsComponentProps = {
  row: Chapter
  onChoose: (chapter: Chapter) => void
}

class ActionsComponent extends React.Component<ActionsComponentProps> {
  static defaultProps = {
    onChoose: () => {
    }
  }

  render (): React.ReactNode {
    return <div className="text-right">
      <Button
        type="button"
        color="primary"
        size="sm"
        onClick={() => this.props.onChoose(this.props.row)}
      >choose</Button>
    </div>
  }
}