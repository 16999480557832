import Member from "../models/Member"
import * as React from "react"
import { SyntheticEvent } from "react"
import { Button } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import Util, { logException, modelToSnakeCase, transformIf } from "../common/Util"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import { toast } from "react-toastify"
import { observer } from "mobx-react"
import LazyResource from "../models/LazyResource"
import EventBus, { EventBusContext } from "../common/EventBus"
import MembershipRenewal from "../models/MembershipRenewal"

type Props = {
  memberId: number
  onCancel: () => void
  onSaved: () => void
}

type EditFormState = {
  endDate: string
}

@observer
export default class ActiveMembershipForm extends React.Component<Props> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  @observable private member = new LazyResource<Member>((callback, error) => {
    ApiClient.query(`
    member {
      *
      
      memberships {
        *
      }
    }
    `, {
      where: [{ id: this.props.memberId }],
    })
      .then(response => callback(new Member().init(response.data.member)))
      .catch(err => error(Util.extractErrorMessage(err.response)))
  })

  @observable
  private formState = new FormState<EditFormState>({
    endDate: '',
  })

  @observable
  private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable
  private submittingEndDate = false

  componentDidMount (): void {
    this.addListeners(this.context.eventBus)

    this.member.get().then(member => {
      member.activeMembership.get().then(activeMembership => {
        if (activeMembership) {
          this.formState.set('endDate', transformIf(activeMembership.endsAt, d => d.format('MM/DD/YYYY')))
        }
      })
    })
  }

  componentWillUnmount (): void {
    this.removeListeners(this.context.eventBus)
  }

  private addListeners = (eventBus: EventBus) => {
    eventBus.on('member-invalidated', this.onMemberInvalidated)
  }

  private removeListeners = (eventBus: EventBus) => {
    eventBus.remove(this.onMemberInvalidated)
  }

  private onMemberInvalidated = () => {
    this.member.invalidate()
  }

  private submitEndDate = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submittingEndDate = true
    AppStateStore.showModalSpinner()

    ApiClient.members.updateMembership(this.member.current!.id, this.member.current!.activeMembership.current!.id, modelToSnakeCase(this.formHelper.toObject()))
      .then(() => {
        toast.success('Membership updated')
        this.context.eventBus.dispatch('member-invalidated')
        this.onSaved()
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submittingEndDate = false
      })
  }

  private onCancelClicked = () => {
    this.props.onCancel()
  }

  private onSaved = () => {
    this.props.onSaved()
  }

  render (): React.ReactNode {
    return <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submitEndDate}>
      <div className="form-row">
        <div className="col-sm-12">
          {this.formHelper.renderDatePickerInput({
            name: 'endDate',
            label: 'End Date',
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-12">
        </div>
      </div>

      <div className="form-buttons">
        <Button type="button" color="secondary" onClick={this.onCancelClicked}>Cancel</Button>
        <ButtonLoader
          type="button"
          color="primary"
          onClick={this.submitEndDate}
          loading={this.submittingEndDate}
          block>Save</ButtonLoader>
      </div>
    </form>
  }
}