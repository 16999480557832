import { observer } from "mobx-react"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import Util, { createLazyResource, modelToSnakeCase, nl2br } from "../common/Util"
import ApiClient, { ApiRoutes, QueryWhereClause } from "../api/ApiClient"
import Note from "../models/Note"
import LazyResourcePanel from "./LazyResourcePanel"
import ButtonLoader from "./ButtonLoader"
import { route } from "../routes/routes"
import { toast } from "react-toastify"
import AppStateStore from "../stores/AppStateStore"
import EventBus from "../common/EventBus"

type Props = {
  noteType: string
  chapterId?: number
  memberId?: number
  guestId?: number
  eventBus?: EventBus
}

@observer
export default class NotesList extends React.Component<Props> {
  static defaultProps = {
    noteType: 'default',
  }

  @observable private notes = createLazyResource<Note[]>(() => {
    const where: QueryWhereClause[] = [
      { noteType: this.props.noteType },
    ]

    if (this.props.chapterId) {
      where.push({ _scope: 'chapter', value: this.props.chapterId })
    } else if (this.props.memberId) {
      where.push({ _scope: 'member', value: this.props.memberId })
    } else if (this.props.guestId) {
      where.push({ _scope: 'guest', value: this.props.guestId })
    }

    return ApiClient.query(
      `
notes {
  *
  user {
    *
  }
}
      `,
      {
        where: where,
        order: [{ id: 'createdAt', desc: true }],
      }
    )
  }, response => response.data.notes.map((n: []) => new Note().init(n)))

  private initialFormState = {
    note: '',
  }

  @observable private submitting = false
  @observable private formState = new FormState(this.initialFormState)
  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  componentDidMount (): void {
    if (this.props.eventBus) {
      this.props.eventBus.on('refresh', this.invalidateNotes)
    }
  }

  private invalidateNotes = () => {
    this.notes.invalidate().then()
  }

  componentWillUnmount (): void {
    if (this.props.eventBus) {
      this.props.eventBus.remove(this.invalidateNotes)
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    if (this.submitting) {
      return
    }

    this.submitting = true
    this.formErrors.clearErrors()

    ApiClient.getInstance()
      .post(route(ApiRoutes.notes.store), modelToSnakeCase({
        ...this.formHelper.toObject(),
        chapterId: this.props.chapterId,
        memberId: this.props.memberId,
        guestId: this.props.guestId,
        noteType: this.props.noteType,
      }))
      .then(response => {
        toast.success('Note added')
        this.notes.invalidate()
        this.formState.setAll(this.initialFormState)
      })
      .catch(error => Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      }))
      .then(() => this.submitting = false)
  }

  render () {
    return <>
      <form action="" method="post" onSubmit={this.submit}>
        <div className="row">
          <div className="col-12">
            {
              this.formHelper.renderTextAreaInput({
                name: 'note',
                disabled: this.submitting,
              })
            }
          </div>
        </div>
        <div className="form-buttons">
          <ButtonLoader
            type="submit"
            color="primary"
            loading={this.submitting}>Add Note</ButtonLoader>
        </div>
      </form>
      <LazyResourcePanel resource={this.notes}>
        {
          notes => notes.map(note => <div key={note.id} className="note-list-item">
            <p>{nl2br(note.note)}</p>
            <div className="note-list-meta">{note.user ? note.user.username : 'System'} @ {note.createdAt.format('MM/DD/YYYY h:mm a')}</div>
          </div>)
        }
      </LazyResourcePanel>
    </>
  }
}
