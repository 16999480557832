import { observer } from "mobx-react"
import * as React from "react"
import Conversation, { CachedParticipant } from "../../models/Conversation"
import AuthStore from "../../stores/AuthStore"
import _ from 'lodash';
import classNames from 'classnames'
import MemberProfileImage from "../MemberProfileImage"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import { route } from "../../routes/routes"
import { toast } from "react-toastify"
import { EventBusContext } from "../../common/EventBus"

type Props = {
  conversations: Conversation[]
  selectedConversationId?: number
  onConversationSelected: (conversation: Conversation) => void
}

@observer
export default class ConversationList extends React.Component<Props> {
  render () {
    return this.props.conversations.map(conversation => <ConversationListItem
      key={conversation.id}
      conversation={conversation}
      isSelected={this.props.selectedConversationId === conversation.id}
      onClick={() => this.props.onConversationSelected(conversation)}
    />)
  }
}

type ItemProps = {
  conversation: Conversation
  isSelected: boolean
  onClick: () => void
}

@observer
class ConversationListItem extends React.Component<ItemProps> {
  
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>
  render() {
    const { conversation } = this.props
    const otherParticipants = conversation.participantsCache.filter(p => p.user.id != AuthStore.getUser()!.id)
    const photoParticipant: CachedParticipant | undefined = _.find(otherParticipants, p => !!p.member && !!p.member.profileImageUrl)
    
    const handleDelete = (id1: number) => {
      ApiClient.getInstance()
        .post(route(ApiRoutes.messaging.deleteConversation, { conversationId: id1 }))
        .then(response => {
          toast.success('Conversation Deleted');
          this.context.eventBus.dispatch('messages-invalidated')
          this.props.onClick
        })
        .catch(error => {

          toast.error('Conversation delete failed, please try again!'+error);
          return true
        })
    };

    return (
      <div>
        {
          !(conversation.isDeleted) &&
          <div
            className={classNames('conversation-list-item', { active: this.props.isSelected })}
            onClick={this.props.onClick}
          >
            <div>
              <div className="text-right text-muted">
                {
                  conversation.lastMessage
                    ? conversation.lastMessage.createdAt.format("M/D/YY h:mma") : null
                    // : conversation.createdAt.format("M/D/YY h:mma")
                }
              </div>
              <div className="d-flex">
                <div style={{ marginRight: 10 }}>
                  <MemberProfileImage profileImageUrl={photoParticipant ? (photoParticipant.member ? photoParticipant.member.profileImageUrl : undefined) : undefined} size={45} />
                </div>
                <div className="flex-fill">
                  <div><b>{
                    conversation.groupName
                      ? conversation.groupName
                      : conversation.participantsCache.filter(p => p.user.id != AuthStore.getUser()!.id).map(p => p.member ? p.member.preferredFullName : p.user.name).join(', ')
                  }</b></div>
                  {
                    conversation.lastMessage
                      ? <div className="text-muted force-wrap">{_.truncate(conversation.lastMessage.message, {
                        length: 100,
                      })}</div>
                      : null
                  }
                </div>
                <div style={{ marginLeft: 10 }}>
                  <div>{conversation.isBroadcast}</div>
                  {!conversation.isBroadcast ?
                    (<button style={{ backgroundColor: '#ffffff' }} onClick={() => handleDelete(conversation.id)}>Delete</button>) : ''
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}
