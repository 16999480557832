import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import * as React from "react"
import { SyntheticEvent } from "react"
import Member from "../models/Member"
import { observer } from "mobx-react"
import { autorun, computed, observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import AppStateStore from "../stores/AppStateStore"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import Util, { logException, modelToSnakeCase, safeNull } from "../common/Util"
import { toast } from "react-toastify"
import AuthStore from "../stores/AuthStore"
import { renderAsyncSelectInput } from "../forms/FormInputs"
import * as AsyncHelpers from "../api/AsyncHelpers"
import RequirePermission, { Permission } from "./RequirePermission"
import * as _ from "lodash"
import { MemberPickerInput } from "./inputs/MemberPickerInput"
import moment from 'moment-timezone'
import { UserRole } from "../models/AuthUser"
import { route } from "../routes/routes"
import HelpTooltip from './HelpTooltip'
import BankCode from './BankCode'
import Tip from '../models/Tip'

type TipFormModalProps = {
  isOpen: boolean
  toggle: () => void
} & TipFormProps

@observer
export class TipFormModal extends React.Component<TipFormModalProps> {
  static defaultProps = {
    allowCancel: false,
    clearAfterSave: true,
  }

  render (): React.ReactNode {
    const { isOpen, toggle, ...rest } = this.props

    const member = this.props.member

    return <Modal isOpen={isOpen} onClosed={this.props.onCancel} size="lg">
      <ModalHeader toggle={toggle}>
        {!member && 'Escrow '}Tip Form
      </ModalHeader>
      <ModalBody>
        <TipForm
          {...rest}
        />
      </ModalBody>
    </Modal>
  }
}

type TipFormProps = {
  onCancel: () => void
  onSaved: () => void
  duplicateTip?: Tip
  member?: Member
  allowCancel: boolean
  clearAfterSave: boolean
}

@observer
export class TipForm extends React.Component<TipFormProps> {
  static defaultProps = {
    allowCancel: false,
    clearAfterSave: true,
  }

  private initialFormState = this.props.duplicateTip ?
    {
      sourceMemberId: safeNull(() => AuthStore.getUser()!.member!.id),
      date: moment().format('MM/DD/YYYY'),
      tipType: this.props.duplicateTip.tipType,
      name: this.props.duplicateTip.contactName,
      companyName: this.props.duplicateTip.contactCompany,
      phone: this.props.duplicateTip.contactPhone,
      email: this.props.duplicateTip.contactEmail,
      tipDetails: this.props.duplicateTip.tipDetails,
      categoryId: this.props.duplicateTip.targetCategoryId,
      memberId: this.props.duplicateTip.targetMemberId,
      specifyValue: this.props.duplicateTip.value,
      value: this.props.duplicateTip.value,
      isPrivate: this.props.duplicateTip.isPrivate,
      socialMediaLink: this.props.duplicateTip.socialMediaLink,
    }
    : {
      sourceMemberId: safeNull(() => AuthStore.getUser()!.member!.id),
      date: moment().format('MM/DD/YYYY'),
      tipType: !this.props.member || (AuthStore.getUser()!.chapter && safeNull(() => this.props.member!.chapter!.id) === AuthStore.getUser()!.chapter.id) ? 'Inside' : 'Inter-Chapter',
      name: '',
      companyName: '',
      phone: '',
      email: '',
      tipDetails: '',
      categoryId: undefined,
      memberId: this.props.member ? this.props.member.id : undefined,
      specifyValue: false,
      value: '',
      isPrivate: false,
      socialMediaLink:'',
    }

  @observable private formState = new FormState(this.initialFormState)

  @observable private formErrors = new ErrorBag()
  @observable private submitting = false
  @observable private showCategoryWarning = false
  @observable private sourceMember?: Member
  @observable private isFormDirty = !!this.props.duplicateTip
  @observable isPrivateBoxChecked = false;

  @computed get sourceMemberId () {
    return this.formState.get('sourceMemberId') || this.props.member!.id || safeNull(() => AuthStore.getUser()!.member!.id)
  }

  @computed get tipTypeOptions () {
    const options = []

    if (!this.props.member || this.props.member.chapter!.id === AuthStore.getUser()!.chapter.id) {
      // escrow/same chapter
      options.push({ value: 'Inside', text: 'Inside' })

      if (this.props.member) {
        options.push({ value: 'Outside', text: 'Outside' })
        options.push({ value:'Social-Media', text:'Social-Media'})
      }
    }

    if (this.props.member && this.props.member.chapter!.id !== AuthStore.getUser()!.chapter.id) {
      // not escrow
      options.push({ value: 'Inter-Chapter', text: 'Inter-Chapter' })
    }

    return options
  }

  private formHelper = new FormHelper(this.formState, this.formErrors)

  componentDidMount (): void {
    autorun(() => {
      ApiClient.query(`
    member {
      *
      defaultTipPrivacy
      business {
        *
      }
    }
    `, {
        where: [{ id: this.sourceMemberId }]
      })
        .then(response => {
          this.sourceMember = new Member(response.data.member)
        })
        .catch(() => {
        })
    })

    autorun(() => {
      if (!this.isFormDirty) {
        if (this.formState.get('tipType') === 'Inside') {
          this.formState.setAll({
            name: safeNull(() => this.sourceMember!.fullName) || '',
            phone: safeNull(() => this.sourceMember!.phoneNumber) || '',
            email: safeNull(() => this.sourceMember!.emailAddress) || '',
            companyName: safeNull(() => this.sourceMember!.business!.name) || '',
            isPrivate: safeNull(() => this.sourceMember!.defaultTipPrivacy) === 'private',
          })
        } else if (this.formState.get('tipType') === 'Outside') {
          this.formState.setAll({
            name: '',
            phone: '',
            email: '',
            companyName: '',
          })
        } else if(this.formState.get('tipType') === 'Social-Media'){
          this.formState.setAll({
            name:'',
            phone:'',
            email:'',
            companyName:'',
          })
        }
      }
    })
  }

  private onSubmit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()

    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.tips.create(modelToSnakeCase(this.formState.toObject())).then(() => {
      toast.success(this.props.member ? 'Tip Submitted' : 'Escrow Tip Submitted')
      this.props.onSaved()
      if (this.props.clearAfterSave) {
        this.formState.setAll(this.initialFormState)
      }
    }, error => {
      const errors = new ErrorBag()
      if(error.response.data && error.response.data.errors && error.response.data.errors.status){
        toast.error(error.response.data.errors.status[0])
      }
      Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      })

      this.formErrors.addErrors(errors.getErrorList())
    })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private checkCategoryAvailability = () => {
    if (this.formState.get('categoryId')) {
      ApiClient.getInstance()
        .get(route(ApiRoutes.chapters.checkCategoryAvailability, { id: AuthStore.getUser()!.chapter.id, categoryId: this.formState.get('categoryId') }))
        .then(response => this.showCategoryWarning = !response.data.available)
        .catch(() => this.showCategoryWarning = false)
    } else {
      this.showCategoryWarning = false
    }
  }

  render (): React.ReactNode {
    const { member } = this.props

    return <>
      <form onSubmit={this.onSubmit}>
        {member
          ? <>
            <div className="tip-form-header">
              <div className="tip-form-member">
                <div style={{ display: 'flex', flex: 1 }}>
                  <div style={{ flex: 1 }}>
                    <img className="profile-image" src={member.getProfileImageUrl()} alt={member.fullName}/>
                    <div className="member-info">
                      <div className="member-name">{member.fullName}</div>
                      {member.business ? <div className="business-name">{member.business.name}</div> : null}
                      {member.category ? <div className="text-muted category-name">{member.category.name}</div> : null}
                      {member.chapter ? <div className="chapter-name">{member.chapter.name}</div> : null}
                    </div>
                  </div>
                  <div>
                    {
                      member.bankCode
                        ? <BankCode bankCode={member.bankCode} documentUrl={member.bankCodeDocumentUrl!}/>
                        : null
                    }
                  </div>
                </div>
              </div>
            </div>
            <div style={{ padding: 10, fontSize: '90%', fontWeight: 500 }} className="text-center">
              <p>My qualified Business Tip is for a company or person who is interested in a specific service or product and is expecting a call from a LeTip member.</p>
            </div>
          </>
          : <div>
            <h5 className="text-muted">{AuthStore.getUser()!.chapter!.name}</h5>
          </div>}
        {!member && <>
          <div className="form-row">
            <div className="col-sm-12">
              {renderAsyncSelectInput(this.formState, this.formErrors, {
                name: 'categoryId',
                label: 'Tip For',
                labelAccessory: <HelpTooltip
                  content={<><b>What is an Escrow Tip?</b><br/>The Escrow Tip is a legitimate, qualified Tip that is written to a category, never to a person. An Escrow Tip must never be passed until the category is filled. An Escrow Tip is always an Inside Tip.</>}
                  placement="right"
                />,
                value: this.formState.get('categoryId'),
                loadOptions: AsyncHelpers.loadCategoryOptions(),
                onChange: () => {
                  this.checkCategoryAvailability()
                }
              })}
              <p className="text-danger">Escrow Tips are not given out until the guest joins the chapter.</p>
            </div>
          </div>
          {
            this.showCategoryWarning
              ? <div className="alert alert-warning">The category you have selected is already filled for this chapter. Are you sure you want to choose this category?</div>
              : null
          }
        </>}
        {/* <RequirePermission permission={Permission.SendTipAsMember} renderIfDenied={false}>
          {() =>  */}
          <div className="form-row">
            <div className="col-12">
              <label>Tip From(DO NOT CHANGE THIS UNLESS YOU ARE ENTERING A TIP FROM ANOTHER MEMBER)</label>
              <MemberPickerInput
                filters={AuthStore.getUser()!.hasAnyRole([UserRole.Admin, UserRole.Employee]) ? {} : { chapter: AuthStore.getUser()!.chapter.id }}
                placeholder="Choose Member"
                onChange={member => this.formState.set('sourceMemberId', _.get(member, 'id'))}
                value={this.formState.get('sourceMemberId')}
                allowClear={false}
              />
            </div>
          </div>
          {/* }
        </RequirePermission> */}
        <div className="form-row">
          <div className="col-sm-6">
            {this.formHelper.renderDatePickerInput({
              name: 'date',
              label: 'Date',
            })}
          </div>
          <div className="col-sm-6">
            {this.formHelper.renderSelectInput({
              name: 'tipType',
              label: 'Tip Type',
              options: this.tipTypeOptions,
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-6">
            {this.formHelper.renderTextInput({
              name: 'name',
              label: 'Person To Contact',
              onChange: () => this.isFormDirty = true
            })}
          </div>
          <div className="col-sm-6">
            {this.formHelper.renderTextInput({
              name: 'companyName',
              label: 'Company Name',
              onChange: () => this.isFormDirty = true
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-6">
            {this.formHelper.renderTextInput({
              name: 'phone',
              label: 'Phone Number',
              type: 'tel',
              onChange: () => this.isFormDirty = true
            })}
          </div>
          <div className="col-sm-6">
            {this.formHelper.renderTextInput({
              name: 'email',
              label: 'Email Address',
              type: 'email',
              onChange: () => this.isFormDirty = true
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-12">
            {this.formHelper.renderTextAreaInput({
              name: 'tipDetails',
              label: 'Tip Details',
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-6">
            {this.formHelper.renderCheckboxInput({
              name: 'specifyValue',
              label: 'Specify the tip value',
            })}
          </div>
          <div className="col-sm-6">
            {
              this.formState.get('specifyValue')
                ? this.formHelper.renderTextInput({
                  name: 'value',
                  prepend: '$',
                  label: 'Value',
                })
                : null
            }
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-12">
            <div style={{ display: 'flex' }}>
              <div>
                {this.formHelper.renderCheckboxInput({
                  name: 'isPrivate',
                  label: 'This is a semi-private tip',
                  onChange: () => this.isPrivateBoxChecked = !this.isPrivateBoxChecked
                })}
              </div>
              <div style={{ marginLeft: 10 }}>
                <HelpTooltip
                  placement="right"
                  content="Checking this box will hide contact info and tip details from other chapter members"
                />
              </div>
            </div>
            <div  style={{padding:'5px', marginBottom:'20px', border:'1px solid transparent', borderRadius:'4px', backgroundColor:'#fcf8e3', borderColor:'#faebcc',color:'#8a6d3b',width:'80%', display:this.isPrivateBoxChecked==false?'none':'block' }}>
                Tip details are visible to Board members, please do not add any sensitive info.
              </div>
          </div>
        </div>
        {
          (() => {
            if(this.formState.get('tipType') === 'Social-Media'){
              return(
                <div className="form-row">
                  <div style={{ marginLeft: 30}}>
                    Social Media Link
                  </div>
                  <div style={{ marginLeft: 10}}>
                    <HelpTooltip
                      placement="left"
                      content="What is Social Media Link? You can add the Link of the tip you want to pass."
                    />
                  </div>
                  <div className="col-sm-6" style={{ marginLeft: 10}}>
                    {this.formHelper.renderTextAreaInput({
                      name:'socialMediaLink',
                      height:50
                    })}
                  </div>
                </div>
              )
            }
            return null
          })()
        }
        <div className="form-buttons">
          {this.props.allowCancel && <Button color="secondary" onClick={() => this.props.onCancel()}>Cancel</Button>}
          <ButtonLoader type="submit" color="primary" loading={this.submitting}>Submit Tip</ButtonLoader>
        </div>
      </form>
    </>
  }
}
