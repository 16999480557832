import * as React from "react"
import { Link } from "react-router-dom"
import { createBrowserHistory } from 'history'

export default class BackLink extends React.Component {
  private history = createBrowserHistory()

  render (): React.ReactNode {
    return <Link onClick={(ev) => {
      ev.preventDefault()
      this.history.goBack()
    }} to="" className="header-back-link">
      <i className="fa fa-chevron-left"/>
    </Link>
  }
}