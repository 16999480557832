import * as React from "react"
import { SyntheticEvent } from "react"
import { Prompt, Redirect } from "react-router-dom"
import { observable } from "mobx"
import { observer } from "mobx-react"
import { BarLoader } from "react-spinners"
import { Button } from "reactstrap"
import { LinkContainer } from "react-router-bootstrap"
import { toast } from "react-toastify"
import BaseView from "../BaseView"
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import FormHelper from "../../forms/FormHelper"
import BackLink from "../../components/BackLink"
import AppStateStore from "../../stores/AppStateStore"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import ApiClient from "../../api/ApiClient"
import Util, { logException, modelToCamelCase, modelToSnakeCase } from "../../common/Util"
import ButtonLoader from "../../components/ButtonLoader"
import NtsEvent from '../../models/NtsEvent'
import FormError from '../../components/FormError'
import moment from 'moment-timezone'

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

@observer
export default class EditNtsEventView extends BaseView<Props> {
  @observable private formState = new FormState({
    date: '',
    time: '',
    timezone: '',
    zoomMeetingInfo: '',
  })
  @observable private formErrors = new ErrorBag()
  private formHelper = new FormHelper(this.formState, this.formErrors)
  @observable private submitting = false
  @observable private loading = false
  @observable private redirect = false
  @observable private editNtsEvent?: NtsEvent

  private get isEdit () {
    return !!this.props.match.params.id
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>{this.isEdit ? 'Update Existing NTS Event' : 'Add New NTS Event'}</h1>
        {this.isEdit && this.editNtsEvent && <ul className="content-header-actions">
        </ul>}
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    if (this.props.match.params.id) {
      this.loading = true

      ApiClient.query(`
        ntsEvent {
          *
        }
        `, {
          where:
            [{ id: this.props.match.params.id }],
        }
      )
        .then(response => {
          const ntsEventData = modelToCamelCase(response.data.ntsEvent)
          this.editNtsEvent = new NtsEvent().init(ntsEventData)
          this.formState.setAll({
            date: this.editNtsEvent.date.clone().tz(this.editNtsEvent.timezone).format('MM/DD/YYYY'),
            time: this.editNtsEvent.date.clone().tz(this.editNtsEvent.timezone).format('h:mm A'),
            timezone: this.editNtsEvent.timezone,
            zoomMeetingInfo: this.editNtsEvent.zoomMeetingInfo,
          })
          this.formState.clearDirty()
          this.loading = false
        })
        .catch(ex => {
          AppStateStore.showAlertModal('Error', 'The NTS Event was not found', m => {
            m.hide()
            this.redirect = true
          })
        })
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()

    this.submitting = true
    AppStateStore.showModalSpinner()

    const apiCall = this.editNtsEvent
      ? () => ApiClient.ntsEvents.update(this.editNtsEvent!.id, modelToSnakeCase(this.formHelper.toObject()))
      : () => ApiClient.ntsEvents.create(modelToSnakeCase(this.formHelper.toObject()))

    apiCall().then(() => {
      toast.success(this.isEdit ? 'NTS Event updated' : 'NTS Event created')
      this.redirect = true
    }, error => {
      const errors = new ErrorBag()
      Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      })

      this.formErrors.addErrors(errors.getErrorList())
    })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  renderContentBody (): React.ReactNode {
    return this.redirect
      ? this.renderRedirect()
      : this.loading ? this.renderLoading() : this.renderForm()
  }

  private renderLoading = () => <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>

  private renderTimezoneOption = (tz: string) => <option value={tz}>{tz} - {moment().tz(tz).format('h:mm A')}</option>

  private renderForm = () => (
    <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      {this.formState.isDirty && <Prompt message="Are you sure you want to leave this page? You will lose any unsaved changes!"/>}
      <div className="form-row">
        <div className="col-3">
          {this.formHelper.renderDatePickerInput({
            name: 'date',
            label: 'Date',
          })}
        </div>
        <div className="col-3">
          {this.formHelper.renderTextInput({
            type: 'time',
            name: 'time',
            label: 'Start Time',
          })}
        </div>
        <div className="col-6">
          <div className="form-group">
            <label>Timezone</label>
            <select
              className="form-control"
              name="timezone"
              value={this.formState.get('timezone') || ''}
              onChange={this.formState.onChange}
            >
              <option value="">(choose one)</option>
              <optgroup label="Pacific Time">
                {this.renderTimezoneOption('America/Vancouver')}
                {this.renderTimezoneOption('America/Los_Angeles')}
              </optgroup>
              <optgroup label="Mountain Time">
                {this.renderTimezoneOption('America/Edmonton')}
                {this.renderTimezoneOption('America/Denver')}
                {this.renderTimezoneOption('America/Phoenix')}
              </optgroup>
              <optgroup label="Central Time">
                {this.renderTimezoneOption('America/Winnipeg')}
                {this.renderTimezoneOption('America/Chicago')}
              </optgroup>
              <optgroup label="Eastern Time">
                {this.renderTimezoneOption('America/Toronto')}
                {this.renderTimezoneOption('America/New_York')}
              </optgroup>
              <optgroup label="Hawaii Time">
                {this.renderTimezoneOption('Pacific/Honolulu')}
              </optgroup>
              <optgroup label="Alaska Time">
                {this.renderTimezoneOption('America/Anchorage')}
              </optgroup>
              <optgroup label="Newfoundland Time">
                {this.renderTimezoneOption('America/St_Johns')}
              </optgroup>
              <optgroup label="Atlantic Time">
                {this.renderTimezoneOption('America/Halifax')}
              </optgroup>
            </select>
            <FormError errors={this.formErrors} fieldName="timezone"/>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-12">
          {this.formHelper.renderTextAreaInput({
            name: 'zoomMeetingInfo',
            label: 'Zoom Meeting Info',
            height: 200,
          })}
        </div>
      </div>
      <div className="form-buttons">
        <LinkContainer to={this.isEdit ? route(Routes.system.ntsEvents.detail, {id: this.props.match.params.id}) : route(Routes.system.ntsEvents.index)}><Button type="button" color=""><i className="fa fa-ban"/> Cancel</Button></LinkContainer>
        <ButtonLoader type="submit" color="success" loading={this.submitting}><i className="fa fa-save"/> {this.isEdit ? 'Update NTS Event' : 'Save New NTS Event'}</ButtonLoader>
      </div>
    </form>
  )

  private renderRedirect = () => <Redirect to={route(Routes.system.ntsEvents.index)}/>
}
