import BaseModel from "./BaseModel"
import Area from "./Area"
import Address from "./Address"
import LazyResource from "./LazyResource"
import ApiClient from "../api/ApiClient"
import { modelToCamelCase } from "../common/Util"
import MemberPosition from "./MemberPosition"
import * as _ from "lodash"
import DerivedLazyResource from "./DerivedLazyResource"
import { BOARD_MEMBER_TYPE, CHAIR_MEMBER_TYPE } from "./Position"
import FileUpload from "./FileUpload"
import { Moment } from "moment-timezone"
import SupportArea from "./SupportArea"
import Member from "./Member"
import PaymentMethod from "./PaymentMethod"

export type SocialLinksType = {
  facebook: string | undefined,
  twitter: string | undefined,
  linkedin: string | undefined,
  instagram: string | undefined,
  youtube: string | undefined,
  pinterest: string | undefined,
  snapchat: string | undefined,
  vimeo: string | undefined,
  alignable: string | undefined
}

export const ChapterStatuses = [
  'Active',
  'Closed',
  'Disbanded',
  'Incubation',
  'Pre-Sign',
]

export default class Chapter extends BaseModel {
  id: number
  name: string
  websiteUrl: string | null
  bio: string | null
  description: string | null
  meetingDay: string
  meetingTime: string
  meetingLocationDescription: string
  status: string | 'Active' | 'Closed' | 'Disbanded' | 'Incubation' | 'Pre-Sign'
  dateFounded: Moment | null
  numberOfProgramSlots: number
  socialLinks: SocialLinksType
  timezone: string
  currentProgramSlot?: number
  defaultTipPrivacy: string
  billingRegion: string

  president: MemberPosition

  members: Member[]
  activeMembers: Member[]
  area?: Area
  meetingLocationAddress?: Address
  supportAreas: SupportArea[]

  numberOfActiveMembers: number

  constructor (data = {}) {
    super()
    this.init(data)

    if (!this.members) {
      this.members = []
    }

    if (!this.activeMembers) {
      this.activeMembers = []
    }
  }

  getCasts () {
    return {
      dateFounded: 'datetime',
      currentProgramSlot: 'number',
    }
  }

  setSocialLinks (s?: {}) {
    return {
      facebook: '',
      twitter: '',
      linkedin: '',
      instagram: '',
      youtube: '',
      pinterest: '',
      snapchat: '',
      vimeo: '',
      alignable: '',
      ...(s || {}),
    }
  }

  setMembers (m?: []) {
    return m ? m.map(mm => new Member().init(mm)) : []
  }

  setActiveMembers (m?: []) {
    return m ? m.map(mm => new Member().init(mm)) : []
  }

  setArea = (r: {}): Area => {
    return new Area(r)
  }

  setSupportAreas (a?: []) {
    return a ? a.map(a => new SupportArea().init(a)) : []
  }

  setMeetingLocationAddress = (a?: {}): Address | null => {
    return a ? new Address().init(a) : null
  }

  setPresident (p?: {}) {
    return p ? new MemberPosition().init(p) : null
  }

  isDisabled () {
    return ['Closed', 'Disbanded'].indexOf(this.status) > -1
  }

  memberPositions = new LazyResource<MemberPosition[]>((callback, error) => {
    ApiClient.chapters.getMemberPositions(this.id)
      .then(response => {
        callback(_.map(response.data.member_positions, m => (new MemberPosition()).init(modelToCamelCase(m))))
      })
      .catch(() => error('There was an error loading the chair/board members'))
  })

  boardMembers = new DerivedLazyResource<MemberPosition[], MemberPosition[]>(this.memberPositions, members => {
    return _.orderBy(_.filter(members, m => (m.position.displayType || m.position.type) === BOARD_MEMBER_TYPE), 'position.displayOrder')
  })

  chairMembers = new DerivedLazyResource<MemberPosition[], MemberPosition[]>(this.memberPositions, members => _.orderBy(_.filter(members, m => (m.position.displayType || m.position.type) === CHAIR_MEMBER_TYPE), 'position.displayOrder'))

  incomingMemberPositions = new LazyResource<MemberPosition[]>((callback, error) => {
    ApiClient.chapters.getIncomingMemberPositions(this.id)
      .then(response => {
        callback(_.map(response.data.member_positions, m => (new MemberPosition()).init(modelToCamelCase(m))))
      })
      .catch(() => error('There was an error loading the chair/board members'))
  })

  incomingBoardMembers = new DerivedLazyResource<MemberPosition[], MemberPosition[]>(this.incomingMemberPositions, members => {
    return _.orderBy(_.filter(members, m => (m.position.displayType || m.position.type) === BOARD_MEMBER_TYPE), 'position.displayOrder')
  })

  incomingChairMembers = new DerivedLazyResource<MemberPosition[], MemberPosition[]>(this.incomingMemberPositions, members => _.orderBy(_.filter(members, m => (m.position.displayType || m.position.type) === CHAIR_MEMBER_TYPE), 'position.displayOrder'))

  private memberPositionCache = {}

  getMemberForPosition (position: string, type: string) {
    const key = `${type}:${position}`

    if (!this.memberPositionCache.hasOwnProperty(key)) {
      this.memberPositionCache[key] = _.find<MemberPosition>(this.memberPositions.current, mp => mp.position.type === type && mp.position.name === position)
    }

    return this.memberPositionCache[key]
  }

  paymentMethods = new LazyResource<PaymentMethod[]>((callback, error) => {
    ApiClient.query(`
      paymentMethods {
        *
      }`, {
      where: [{ chapterId: this.id }],
      order: [{ id: 'isDefault', desc: true }, 'id'],
    })
      .then(response => callback(response.data.paymentMethods.map((p: {}) => new PaymentMethod(p))))
      .catch(() => error('There was an error loading the payment types'))
  })

  defaultPaymentMethod = new DerivedLazyResource<PaymentMethod, PaymentMethod[]>(this.paymentMethods, paymentMethods => _.find(paymentMethods, m => m.isDefault))

  documents = new LazyResource<FileUpload[]>((callback, error) => {
    ApiClient.query(`
      fileUploads {
        id
        createdAt
        filename
        mimeType
        fileSizeBytes
        url
      }
    `,
      {
        where: [
          { code: FileUpload.CODE_CHAPTER_DOCUMENT },
          { uploadable_id: this.id },
          { uploadable_type: 'chapter' },
        ],
        order: [{ id: 'createdAt', desc: true }],
      })
      .then(response => callback(response.data.fileUploads.map((t: {}) => new FileUpload().init(t))))
      .catch(() => error('There was an error loading the documents'))
  })

  getStatusColor = () => {
    if (this.status === 'Active') {
      return 'success'
    } else if (this.status === 'Incubation' || this.status === 'Pre-Sign') {
      return 'primary'
    } else {
      return 'secondary'
    }
  }
}
