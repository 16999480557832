import * as React from "react"
import BaseView from "../BaseView"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { computed, observable } from "mobx"
import { observer } from "mobx-react"
import ApiClient, { apiRoute, ApiRoutes } from "../../api/ApiClient"
import { createLazyResource, makeUrl, safeNull } from "../../common/Util"
import { BarLoader } from "react-spinners"
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, TabContent } from "reactstrap"
import classNames from 'classnames'
import { createBrowserHistory } from 'history'
import * as _ from 'lodash'
import LazyTabPane from "../../components/LazyTabPane"
import LazyResourcePanel from "../../components/LazyResourcePanel"
import AuthStore from "../../stores/AuthStore"
import { LinkContainer } from "react-router-bootstrap"
import { TIPS_VIEW_TABS } from "../member/MemberTipsView"
import { InviteGuestModal } from "../../components/InviteGuestModal"
import Chapter from "../../models/Chapter"
import ChapterDashboard from "../../components/chapter/ChapterDashboard"
import ChapterOverview from "../../components/chapter/ChapterOverview"
import SearchableMemberList, { MemberListColumn, SearchableMemberListContext, SearchableMemberListRowType } from "../../components/SearchableMemberList"
import ChapterRoster from "../../components/chapter/ChapterRoster"
import RequirePermission, { can, Permission } from "../../components/RequirePermission"
import ChapterBilling from "../../components/ChapterBilling"
import EventBus, { EventBusContext } from "../../common/EventBus"
import Member from "../../models/Member"
import RequestResignationModal from "../../components/RequestResignationModal"
import ManageChapterFees from '../../components/chapter/ManageChapterFees'
import EmailChapterButton from '../../components/EmailChapterButton'
import RequestCompanyReplacementModal from '../../components/member/RequestCompanyReplacementModal'
import ChapterIncomingPositionsForm from "../../components/ChapterIncomingPositionsForm"
import ArchivedBoardMembersView from "../../components/ArchivedBoardMembersView"
import ChapterPhotosView from "../../components/ChapterPhotosView"

const TABS = {
  dashboard: 'dashboard',
  overview: 'overview',
  roster: 'roster',
  billing: 'billing',
  inComingBoard: 'inComingBoard',
  archiveBoardMembers: 'archiveBoardMembers',
  chapterFees: 'chapterFees',
  chapterPhotos: 'chapterPhotos',
}

@observer
export default class MemberChapterView extends BaseView {
  @observable
  private showInviteGuestModal = false

  @observable private chapter = createLazyResource<Chapter>(() => {
    if (!AuthStore.getUser()!.chapter) {
      return Promise.resolve({ data: { chapter: {} } })
    }

    return ApiClient.query(
      `
chapter {
  *

  numberOfActiveMembers

  meetingLocationAddress {
    *
  }

  area {
    *
  }

  supportAreas {
    *

    supportAreaUsers {
      *

      user {
        *

        member {
          *
        }
      }
    }
  }
}
      `,
      {
        where: [{ id: AuthStore.getUser()!.chapter.id }]
      }
    )
  }, response => new Chapter(response.data.chapter))

  @observable private activeTab = TABS.dashboard

  @computed
  private get chapterId () {
    return this.chapter.current ? this.chapter.current.id : undefined
  }

  @computed get rosterColumns () {
    const columns = [
      MemberListColumn.Name,
      MemberListColumn.Company,
      MemberListColumn.Category,
      MemberListColumn.PhoneNumber,
      MemberListColumn.EmailAddress,
      MemberListColumn.NTS,
      MemberListColumn.Position,
      MemberListColumn.BankCode,
    ]

    if (this.chapter.current && can(Permission.RequestMemberResignation, { chapterId: this.chapter.current.id })) {
      columns.push(MemberListColumn.Actions)
    }

    return columns
  }

  private rosterActionsComponent = (row: SearchableMemberListRowType, onChoose: (member: Member) => void) => {
    return <RosterActions
      member={row.original}
      onAction={(action, member) => {
        if (action === RosterAction.Resign) {
          this.resignationMemberId = member.id
          this.showResignationModal = true
        } else if (action === RosterAction.CompanyReplacement) {
          this.companyReplacementMember = { memberId: member.id, chapterId: member.chapterId }
        }
      }}
    />
  }

  @observable private showResignationModal = false
  @observable private resignationMemberId?: number

  @observable private companyReplacementMember?: {memberId: number, chapterId: number}

  private history = createBrowserHistory()

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>My Chapter</h1>
        {AuthStore.getUser()!.chapter &&
        <ul className="content-header-actions">
          <li>
            <Button color="primary" onClick={() => this.showInviteGuestModal = true}><i className="fa fa-user"/> Invite A Guest</Button>
          </li>
          <li>
            <LinkContainer to={{ pathname: route(Routes.member.tips, { tab: TIPS_VIEW_TABS.sendTip }) }}>
              <Button color="primary"><i className="fa fa-money"/> Pass A New Tip</Button>
            </LinkContainer>
          </li>
        </ul>}
      </>
    )
  }

  private eventBus = new EventBus()

  componentDidMount (): void {
    super.componentDidMount()

    this.activeTab = _.get(this.history.location.state, 'activeTab') || TABS.dashboard
    this.chapter.refresh().then(() => {
    })

    this.eventBus.on('chapter-invalidated', () => this.onChapterInvalidated())
  }

  componentWillUnmount (): void {
    this.eventBus.remove(this.onChapterInvalidated)
  }

  private onChapterInvalidated = () => {
    this.chapter.invalidate()
  }

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(window.location.pathname, { ...this.history.location.state, activeTab: this.activeTab })
    }
  }

  render (): React.ReactNode {
    return <>
      <EventBusContext.Provider value={{ eventBus: this.eventBus }}>
        <LazyResourcePanel resource={this.chapter} initialLoader={<BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>}>
          {chapter => <>
            <div className="card">
              <div className="card-header">
                <Nav tabs className="card-header-tabs">
                  <NavItem className={classNames({ active: this.activeTab === TABS.dashboard })}>
                    <NavLink
                      onClick={() => {
                        this.setTab(TABS.dashboard)
                      }}
                    >Dashboard</NavLink>
                  </NavItem>
                  <NavItem className={classNames({ active: this.activeTab === TABS.overview })}>
                    <NavLink
                      onClick={() => {
                        this.setTab(TABS.overview)
                      }}
                    >Overview</NavLink>
                  </NavItem>
                  <NavItem className={classNames({ active: this.activeTab === TABS.roster })}>
                    <NavLink
                      onClick={() => {
                        this.setTab(TABS.roster)
                      }}
                    >Roster</NavLink>
                  </NavItem>
                  {
                    AuthStore.getUser()!.isBoardMember && AuthStore.getUser()!.isBoardMemberForChapter(chapter.id)?
                    <NavItem className={classNames({ active: this.activeTab === TABS.inComingBoard })}>
                      <NavLink
                        onClick={() => {
                          this.setTab(TABS.inComingBoard)
                        }}>
                          Incoming Board
                      </NavLink>
                  </NavItem>:null
                  }
                  {
                    AuthStore.getUser()!.isBoardMember && AuthStore.getUser()!.isBoardMemberForChapter(chapter.id)?
                    <NavItem className={classNames({ active: this.activeTab === TABS.archiveBoardMembers })}>
                      <NavLink
                        onClick={() => {
                          this.setTab(TABS.archiveBoardMembers)
                        }}>
                          Archived Board Members
                      </NavLink>
                    </NavItem>:null
                  }
                  <RequirePermission permission={Permission.ViewChapterBilling}>
                    {() => <NavItem className={classNames({ active: this.activeTab === TABS.billing })}>
                      <NavLink
                        onClick={() => {
                          this.setTab(TABS.billing)
                        }}
                      >Billing</NavLink>
                    </NavItem>}
                  </RequirePermission>
                  <RequirePermission permission={Permission.ManageChapterFees} context={{ chapterId: this.chapterId }}>
                    {() => <NavItem className={classNames({ active: this.activeTab === TABS.chapterFees })}>
                      <NavLink
                        onClick={() => {
                          this.setTab(TABS.chapterFees)
                        }}
                      >Fees/Dues</NavLink>
                    </NavItem>}
                  </RequirePermission>
                  {
                    AuthStore.getUser()!.isBoardMember && AuthStore.getUser()!.isBoardMemberForChapter(chapter.id)?
                    <NavItem className={classNames({ active: this.activeTab === TABS.chapterPhotos })}>
                      <NavLink
                        onClick={() => {
                          this.setTab(TABS.chapterPhotos)
                        }}>
                          Chapter Photos
                      </NavLink>
                  </NavItem>:null
                  }
                 </Nav>
              </div>
              <div className="card-body">
                <TabContent activeTab={this.activeTab}>
                  <LazyTabPane tabId={TABS.dashboard} activeTab={this.activeTab}>
                    <ChapterDashboard chapter={chapter}/>
                  </LazyTabPane>
                  <LazyTabPane tabId={TABS.overview} activeTab={this.activeTab}>
                    <ChapterOverview chapter={chapter}/>
                  </LazyTabPane>
                  <LazyTabPane tabId={TABS.roster} activeTab={this.activeTab}>
                    <ChapterRoster chapterId={chapter.id}/>
                  </LazyTabPane>
                  {
                     AuthStore.getUser()!.isBoardMember && AuthStore.getUser()!.isBoardMemberForChapter(chapter.id)?
                     <LazyTabPane tabId={TABS.inComingBoard} activeTab={this.activeTab}>
                      <ChapterIncomingPositionsForm chapter={chapter} onChapterInvalidated={this.chapter.invalidate}/>
                     </LazyTabPane> : null
                  }
                  {
                     AuthStore.getUser()!.isBoardMember && AuthStore.getUser()!.isBoardMemberForChapter(chapter.id)?
                     <LazyTabPane tabId={TABS.archiveBoardMembers} activeTab={this.activeTab}>
                      <ArchivedBoardMembersView chapter={chapter}/>
                     </LazyTabPane> : null
                  }
                  <RequirePermission permission={Permission.ViewChapterBilling}>
                    {() => <LazyTabPane tabId={TABS.billing} activeTab={this.activeTab}>
                      <ChapterBilling chapterId={chapter.id} onChapterInvalidated={this.chapter.invalidate}/>
                    </LazyTabPane>}
                  </RequirePermission>
                  <RequirePermission permission={Permission.ManageChapterFees} context={{ chapterId: this.chapterId }}>
                    {() => <LazyTabPane tabId={TABS.chapterFees} activeTab={this.activeTab}>
                      <ManageChapterFees chapterId={chapter.id} onChapterInvalidated={this.chapter.invalidate}/>
                    </LazyTabPane>}
                  </RequirePermission>
                  {
                    AuthStore.getUser()!.isBoardMember && AuthStore.getUser()!.isBoardMemberForChapter(chapter.id)?
                    <LazyTabPane tabId={TABS.chapterPhotos} activeTab={this.activeTab}>
                     { <ChapterPhotosView chapter={chapter}/>}
                    </LazyTabPane> : null
                  }
                </TabContent>
              </div>
            </div>

            <TabContent activeTab={this.activeTab}>
              {
                // TODO: move this into a component, make it editable by board
              }
              <LazyTabPane activeTab={this.activeTab} tabId={TABS.roster}>
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex">
                      <div className="flex-fill">
                        <h5>{chapter.name} Members</h5>
                      </div>
                      <div>
                        <EmailChapterButton chapterId={chapter.id} className='mr-1'/>
                        <a
                          target="_blank"
                          href=""
                          className="btn btn-primary"
                          onClick={ev => {
                            ev.preventDefault()

                            const url = apiRoute(route(ApiRoutes.printChapterRoster, {chapterId: chapter.id}), {
                              token: AuthStore.generateTempAuthToken(),
                            })

                            const link = document.createElement('a')
                            link.setAttribute('target', '_blank')
                            link.setAttribute('href', url)
                            link.style.visibility = 'hidden'
                            document.body.appendChild(link)
                            link.click()
                            link.remove()
                          }}
                        >Print Roster</a>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <SearchableMemberList
                      linkToNetworkPage={true}
                      allowExport={true}
                      showColumnChooser={true}
                      filters={{
                        chapter: chapter.id,
                        showInactive: false,
                      }}
                      context={SearchableMemberListContext.Network}
                      actionsComponent={this.rosterActionsComponent}
                      columns={this.rosterColumns}
                      additionalColumns={[
                        MemberListColumn.JoinDate,
                        MemberListColumn.BadgeColor,
                        MemberListColumn.MembershipType,
                      ]}
                    />
                  </div>
                </div>
              </LazyTabPane>
            </TabContent>

            <InviteGuestModal
              isOpen={this.showInviteGuestModal}
              toggle={() => this.showInviteGuestModal = !this.showInviteGuestModal}
              onCancel={() => this.showInviteGuestModal = false}
              onSaved={() => {
                this.showInviteGuestModal = false
              }}
            />
          </>}
        </LazyResourcePanel>

        {
          this.resignationMemberId
            ? <RequestResignationModal
              boardMemberId={safeNull(() => AuthStore.getUser()!.member!.id)}
              memberId={this.resignationMemberId}
              isOpen={this.showResignationModal}
              toggle={() => this.showResignationModal = false}
              onClosed={() => this.resignationMemberId = undefined}
            />
            : null
        }

        {
          this.companyReplacementMember
            ? <RequestCompanyReplacementModal
              onClosed={() => this.companyReplacementMember = undefined}
              memberId={this.companyReplacementMember.memberId}
              chapterId={this.companyReplacementMember.chapterId}
            />
            : null
        }
      </EventBusContext.Provider>
    </>
  }
}

enum RosterAction {
  Resign,
  CompanyReplacement,
}

@observer
class RosterActions extends React.Component<{member: Member, onAction: (action: RosterAction, member: Member) => void}> {
  @observable private isOpen = false

  private toggle = () => {
    this.isOpen = !this.isOpen
  }

  render () {
    return can(Permission.RequestMemberResignation, { chapterId: this.props.member.chapterId })
      ? <Dropdown isOpen={this.isOpen} toggle={this.toggle} color="primary" direction="left">
        <DropdownToggle caret/>
        <DropdownMenu>
          <DropdownItem onClick={() => this.props.onAction(RosterAction.Resign, this.props.member)}>Resign Member</DropdownItem>
          {
            this.props.member.membershipType === 'Company'
              ? <DropdownItem onClick={() => this.props.onAction(RosterAction.CompanyReplacement, this.props.member)}>Company Replacement</DropdownItem>
              : null
          }
        </DropdownMenu>
      </Dropdown>
      : null
  }
}
