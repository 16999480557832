import { observer } from "mobx-react"
import * as React from "react"
import { observable } from "mobx"
import { createLazyResource, formatNumber, modelToCamelCase } from "../../common/Util"
import { AxiosPromise } from "axios"
import LazyResourcePanel from "../LazyResourcePanel"
import MemberProfileImage from "../MemberProfileImage"
import FlipMove from "react-flip-move"

export type TopTipperData = {
  memberId: number
  fullName: string
  profileImageUrl: string | null
  numberOfTips: number
}

@observer
export default class TopTippersWidget extends React.Component<{
  period: string,
  load: (period: string) => AxiosPromise,
}> {
  @observable private period = 'month'
  @observable private topTippers = createLazyResource<TopTipperData[]>(() => {
    return this.props.load(this.props.period)
  }, response => response.data.top_tippers.map((t: {}) => modelToCamelCase(t)))

  componentDidMount (): void {
    this.topTippers.refresh().then(() => {
    })
  }

  componentDidUpdate (prevProps: Readonly<{ period: string; load: (period: string) => AxiosPromise }>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.period !== this.props.period) {
      this.topTippers.invalidate()
    }
  }

  render (): React.ReactNode {
    return <LazyResourcePanel
      resource={this.topTippers}
      emptyMessage={"There is no tip data for the selected period"}
    >
      {topTippers => <FlipMove>
        {topTippers.map(topTipper => <div className="d-flex align-items-center mt-4" key={topTipper.memberId}>
          <div>
            <MemberProfileImage
              size={45}
              profileImageUrl={topTipper.profileImageUrl}
              memberName={topTipper.fullName}
            />
          </div>
          <div className="flex-fill ml-2 mr-2">
            {topTipper.fullName}
          </div>
          <div>
            <h6 className="text-success">{formatNumber(topTipper.numberOfTips)}</h6>
          </div>
        </div>)}
      </FlipMove>
      }
    </LazyResourcePanel>
  }
}
