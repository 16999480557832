import * as React from "react"
import { observer } from "mobx-react"
import AppStateStore from "../stores/AppStateStore"

@observer
export default class BaseView<P = any, S = any> extends React.Component<P, S> {
  componentDidMount (): void {
    AppStateStore.setContentHeaderRenderer(this.renderContentHeader.bind(this))
    window.scrollTo(0, 0)
  }

  componentWillUnmount (): void {
    AppStateStore.setContentHeaderRenderer(undefined)
  }

  renderContentHeader (): React.ReactNode | null {
    return null
  }

  renderContentBody (): React.ReactNode | null {
    return null
  }

  render (): React.ReactNode {
    return (
      <div className="card">
        <div className="card-body">
          {this.renderContentBody()}
        </div>
      </div>
    )
  }
}