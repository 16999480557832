import BaseModel from "./BaseModel"
import LazyResource from "./LazyResource"
import ApiClient from "../api/ApiClient"
import { modelToCamelCase } from "../common/Util"
import SupportAreaUser from "./SupportAreaUser"
import Member from "./Member"

export interface IUser {
  id: number
  username: string
  email: string
  name: string
  status: string
  roles: string[]
  adminRegionIds: number[]
  memberId: number | undefined
}

export default class User extends BaseModel implements IUser {
  id: number
  username: string
  email: string
  name: string
  status: string
  roles: string[]
  memberId: number | undefined
  adminRegionIds: number[]
  calendarUuid: string

  member: Member | null

  constructor (data = {}) {
    super();
    this.init(data);
  }

  setMember (m?: {}) {
    return m ? new Member(m) : null
  }

  supportAreaUsers = new LazyResource<SupportAreaUser[]>((callback, error) => {
    ApiClient.users.getSupportAreaUsers(this.id)
      .then(response => callback(response.data.support_areas.map((p: {}) => new SupportAreaUser().init(modelToCamelCase(p)))))
      .catch(() => error('There was an error loading the support areas'))
  })
}
