import * as React from "react"
import { observer } from "mobx-react"
import { LinkContainer } from "react-router-bootstrap"
import * as H from "history"
import { Button } from "reactstrap"
import Notice from "../../models/Notice"
import BaseView from "../BaseView"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { ApiTableViewAdapter } from "../../components/table-view/ApiTableViewAdapter"
import { QueryWhereClause } from "../../api/ApiClient"
import { Link } from "react-router-dom"
import { ManagedChooseableColumn } from "../../components/table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "../../components/table-view/ManagedTableView";
import { safeNull } from "../../common/Util";

type Props = {
  location: H.Location<H.LocationState>,
}

@observer
export default class NoticesView extends BaseView<Props> {
  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>Notices</h1>
        <ul className="content-header-actions">
          <li>
            <LinkContainer to={route(Routes.system.notices.create)}>
              <Button color="primary" onClick={() => {
              }}><i className="fa fa-plus-circle"/> Add New Notice</Button>
            </LinkContainer>
          </li>
        </ul>
      </>
    )
  }

  private tableViewRef = React.createRef<ManagedTableView>()

  private availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'title',
      column: {
        accessor: 'title',
        title: 'Title',
        renderItem: (title: string, notice: Notice) => <Link to={route(Routes.system.notices.edit, { id: notice.id })}>{title}</Link>
      },
      fixed: true,
    },
    {
      id: 'createdAt',
      column: {
        accessor: 'createdAt',
        title: 'Created',
        renderItem: d => d.format('MM/DD/YYYY')
      },
      default: true,
    },
    {
      id: 'status',
      column: {
        accessor: 'status',
        title: 'Status',
      },
      fixed: true,
    },
  ]

  private noticesAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      if (!safeNull(() => this.tableViewRef.current!.tableViewFilters!.showInactive)) {
        where.push({ _scope: 'active' })
      }

      return {
        query: `
      notices {
        *
      }
    `,
        where: where,
      }
    },
    'notices',
    Notice
  )

  renderContentBody (): React.ReactNode {
    return this.renderTable()
  }

  private renderTable = () => {
    return <ManagedTableView
      stateKey="NoticesView"
      hideItemCount={true}
      allowShowInactive={true}
      ref={this.tableViewRef}
      adapter={this.noticesAdapter}
      availableColumns={this.availableColumns}
    />
  }
}