import BaseView from "../BaseView"
import { computed, observable } from 'mobx'
import { observer } from "mobx-react"
import * as React from "react"
import * as _ from "lodash"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import classNames from "classnames"
import { createBrowserHistory } from "history"
import LazyTabPane from "../../components/LazyTabPane"
import ChapterTipsReport from "../../components/system/reporting/ChapterTipsReport"
import MemberRosterReport from "../../components/system/reporting/MemberRosterReport"
import AttendanceReport from "../../components/system/reporting/AttendanceReport"
import GuestAttendanceReport from "../../components/system/reporting/GuestAttendanceReport"
import QuarterlyTipReport from "../../components/system/reporting/QuarterlyTipReport"
import WeeklyTipReport from "../../components/system/reporting/WeeklyTipReport"
import ChapterTipClosureReport from "../../components/system/reporting/ChapterTipClosureReport"
import ChapterTipInsideOutsideReport from "../../components/system/reporting/ChapterTipInsideOutsideReport"
import AuthStore from "../../stores/AuthStore"
import ChapterTipMasterOverviewReport from "../../components/system/reporting/ChapterTipMasterOverviewReport"
import ChapterTippersStackedReport from "../../components/system/reporting/ChapterTippersStackedReport"
import ChapterTipOpenReport from '../../components/system/reporting/ChapterTipOpenReport'
import BoardCurrentPositionsView from "./BoardCurrentPositionsView"
import BoardIncomingPositionsView from "./BoardIncomingPositionsView"

const TABS = {
  existing: 'existing',
  incoming: 'incoming',
}

@observer
export default class BoardPositionsView extends BaseView {
  @observable private activeTab = TABS.existing

  private history = createBrowserHistory()

  renderContentHeader () {
    return (
      <>
        <h1>Board Positions</h1>
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    this.activeTab = _.get(this.history.location.state, 'activeTab') || TABS.existing
  }

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(window.location.pathname, { ...this.history.location.state, activeTab: this.activeTab })
    }
  }

  render (): React.ReactNode {
    return <>
      <div className="card">
        <div className="card-header print-hide">
          <Nav tabs className="card-header-tabs">
            <NavItem className={classNames({ active: this.activeTab === TABS.existing })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.existing)
                }}
              >Existing</NavLink>
            </NavItem>
            <NavItem className={classNames({ active: this.activeTab === TABS.incoming })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.incoming)
                }}
              >Incoming</NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="card-body">
        <TabContent activeTab={this.activeTab}>
          <TabPane tabId={TABS.existing} activeTab={this.activeTab}>
            <BoardCurrentPositionsView/>
          </TabPane>
          <TabPane tabId={TABS.incoming} activeTab={this.activeTab}>
            <BoardIncomingPositionsView/>
          </TabPane>
        </TabContent>
        </div>
      </div>
    </>
  }
}
