import * as React from "react"
import BaseView from "../BaseView"
import { observable } from "mobx"
import { observer } from "mobx-react"
import ApiClient from "../../api/ApiClient"
import { createLazyResource } from "../../common/Util"
import { BarLoader } from "react-spinners"
import Member from "../../models/Member"
import { Nav, NavItem, NavLink, TabContent } from "reactstrap"
import classNames from 'classnames'
import { createBrowserHistory } from 'history'
import * as _ from 'lodash'
import LazyTabPane from "../../components/LazyTabPane"
import LazyResourcePanel from "../../components/LazyResourcePanel"
import AuthStore from "../../stores/AuthStore"
import MemberBilling from "../../components/MemberBilling"
import EventBus, { EventBusContext } from "../../common/EventBus"

const TABS = {
  membership: 'Membership',
}

@observer
export default class MemberBillingView extends BaseView {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  componentWillUnmount (): void {
    this.removeListeners(this.context.eventBus)
  }

  private addListeners = (eventBus: EventBus) => {
    eventBus.on('member-invalidated', this.onMemberInvalidated)
  }

  private removeListeners = (eventBus: EventBus) => {
    eventBus.remove(this.onMemberInvalidated)
  }

  private onMemberInvalidated = () => {
    this.member.invalidate()
  }

  @observable private member = createLazyResource<Member>(() => {
    if (!AuthStore.getUser()!.member) {
      return Promise.resolve({ data: { member: {} } })
    }

    return ApiClient.query(
      `
member {
  *
}
      `,
      {
        where: [{ id: AuthStore.getUser()!.member!.id }]
      }
    )
  }, response => new Member(response.data.member))

  @observable private activeTab = TABS.membership

  private history = createBrowserHistory()

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>Billing</h1>
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    this.addListeners(this.context.eventBus)

    this.activeTab = _.get(this.history.location.state, 'activeTab') || TABS.membership
    this.member.refresh().then(() => {
    })
  }

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(window.location.pathname, { ...this.history.location.state, activeTab: this.activeTab })
    }
  }

  render (): React.ReactNode {
    return <LazyResourcePanel resource={this.member} initialLoader={<BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>}>
      {member => <>
        <div className="card">
          <div className="card-header">
            <Nav tabs className="card-header-tabs">
              <NavItem className={classNames({ active: this.activeTab === TABS.membership })}>
                <NavLink
                  onClick={() => {
                    this.setTab(TABS.membership)
                  }}
                >LeTip International Billing</NavLink>
              </NavItem>
            </Nav>
          </div>
          <div className="card-body">
            <TabContent activeTab={this.activeTab}>
              <LazyTabPane tabId={TABS.membership} activeTab={this.activeTab}>
                <MemberBilling
                  memberId={member.id}
                  onMemberInvalidated={() => this.member.invalidate()}
                />
              </LazyTabPane>
            </TabContent>
          </div>
        </div>
      </>}
    </LazyResourcePanel>
  }
}