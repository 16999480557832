import * as React from "react"
import Member from "../models/Member"
import { observer } from "mobx-react"
import { observable } from "mobx"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import HoursOfOperationForm from "./HoursOfOperationForm"
import AvatarEditForm from "./AvatarEditForm"
import ApiClient from "../api/ApiClient"
import { can, Permission } from "./RequirePermission"
import { safeNull } from "../common/Util"

type Props = {
  member: Member,
  onMemberInvalidated: () => void
}

@observer
export default class MemberBusinessSidebar extends React.Component<Props, never> {
  @observable
  private showModal = false
  @observable
  private editModalContent?: React.ReactNode
  @observable
  private editModalSize = 'lg'
  @observable
  private editModalTitle = 'Edit'

  private showEditModal = (title: string, component: React.ReactNode, size: string = 'lg') => {
    this.editModalTitle = title
    this.editModalContent = component
    this.showModal = true
    this.editModalSize = size
  }

  private closeEditModal = () => {
    this.showModal = false
    this.editModalContent = undefined
  }

  render (): React.ReactNode {
    const business = this.props.member.business
    const member = this.props.member

    return business ? <div className="member-sidebar">
      <div className="member-sidebar-section member-sidebar-header">
        {
          can(Permission.EditMember, { memberId: member.id, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region.id) })
            ? <a className={'pull-right'} href="" onClick={ev => {
              ev.preventDefault()
              this.showEditModal('Edit Business Logo',
                <AvatarEditForm
                  existingImageUrl={business.logoUrl}
                  label={"Business Logo"}
                  modelId={this.props.member.id}
                  updateAvatar={ApiClient.members.updateBusinessLogo}
                  onCancel={() => this.closeEditModal()}
                  onSaved={() => {
                    this.props.onMemberInvalidated()
                    this.closeEditModal()
                  }}/>, 'md')
            }}>
              <div className="avatar-editor">
                <div className="ae-inner">
                  <img src={business.getLogoImageUrl()} width={130} height={130} style={{ backgroundColor: '#ccc' }}/>
                  <div className="ae-overlay">
                    <div className="ae-text">Edit Logo</div>
                  </div>
                </div>
              </div>
            </a>
            : <img src={business.getLogoImageUrl()} width={130} height={130} style={{ backgroundColor: '#ccc' }}/>
        }
        <br/>
        <h5>{business.name}</h5>
      </div>

      <div className="member-sidebar-section">
        <h6>Hours of Operation {
          can(Permission.EditMember, { memberId: member.id, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region.id) })
            ? <a href="" onClick={ev => {
              ev.preventDefault()
              this.showEditModal('Hours of Operation',
                <HoursOfOperationForm
                  days={business.hoursOfOperation}
                  member={this.props.member}
                  onCancel={() => this.closeEditModal()}
                  onSaved={() => {
                    this.closeEditModal()
                    this.props.onMemberInvalidated()
                  }}
                />, 'md')
            }}><i className="fa fa-pencil"/></a>
            : null
        }</h6>
        {business.hoursOfOperation ? business.hoursOfOperation.map(({ day, open, close }) => {
          return <p key={day}><span className="text-muted">{day}</span><br/>{open && close ? `${open} to ${close}` : 'Closed'}</p>
        }) : <p className="text-muted">Not provided</p>}
      </div>

      <Modal isOpen={this.showModal} size={this.editModalSize}>
        <ModalHeader>{this.editModalTitle}</ModalHeader>
        <ModalBody>{this.editModalContent}</ModalBody>
      </Modal>
    </div> : null
  }
}