import * as React from "react";
import { observer } from "mobx-react";
import { LinkContainer } from "react-router-bootstrap";
import * as H from "history";
import { Button } from "reactstrap";
import BaseView from "../views/BaseView";
import { route } from "../routes/routes";
import { Routes } from "../routes/AppRoutes";
import { ApiTableViewAdapter } from "./table-view/ApiTableViewAdapter";
import { QueryWhereClause } from "../api/ApiClient";
import { ManagedChooseableColumn } from "./table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "./table-view/ManagedTableView";
import ArchivedBoardMember from "../models/ArchivedBoardMember";
import Chapter from "../models/Chapter";
import { Link } from "react-router-dom";

type Props = {
  chapter: Chapter;
};

@observer
export default class ArchivedBoardMembersView extends BaseView<Props> {
  renderContentHeader(): React.ReactNode | null {
    return <></>;
  }

  private tableViewRef = React.createRef<ManagedTableView>();

  private availableColumns: ManagedChooseableColumn[] = [
    {
      id: "archivedAt",
      column: {
        accessor: "archivedAt",
        title: "Archived At",
        renderItem: d => d.format("MM/DD/YYYY"),
        sortable: true
      },
      fixed: true
    },
    {
      id: "fullName",
      column: {
        accessor: "member.fullName",
        title: "Member",
        sortable: true,
        renderItem: (value, item) => <Link type="link" to={{ pathname: route(Routes.system.members.show, { id: item.member.id }), state: { id: item.member.id } }}>
          {item.member.fullName}</Link>,
      },
      fixed: true
    },
    {
      id: "position",
      column: {
        accessor: "position.name",
        title: "Position",
        sortable: true
      },
      fixed: true
    }
  ];

  private archivedMembersAdapter = new ApiTableViewAdapter(
    () => {
      const where: QueryWhereClause[] = [
        {
          _scope: "getArchiveMemberPosition",
          value: this.props.chapter.id
        }
      ];

      return {
        query: `
        boardMembers  {
        *
          position  {
           *
          },
          member   {
           *
          },
      }
    `,
        where: where
      };
    },
    "boardMembers",
    ArchivedBoardMember
  );

  renderContentBody(): React.ReactNode {
    return this.renderTable();
  }

  private renderTable = () => {
    return <ManagedTableView stateKey="ArchivedBoardMembersView"
    hideItemCount={true}
     allowShowInactive={false}
     ref={this.tableViewRef}
     adapter={this.archivedMembersAdapter}
     availableColumns={this.availableColumns}
     showSearch={false} />;
  };
}
