import BaseView from "../BaseView"
import { computed, observable } from 'mobx'
import { observer } from "mobx-react"
import * as React from "react"
import * as _ from "lodash"
import { Nav, NavItem, NavLink, TabContent } from "reactstrap"
import classNames from "classnames"
import { createBrowserHistory } from "history"
import LazyTabPane from "../../components/LazyTabPane"
import CustomReportBuilder from "../../components/system/reporting/CustomReportBuilder"
import ChapterTipsReport from "../../components/system/reporting/ChapterTipsReport"
import MemberRosterReport from "../../components/system/reporting/MemberRosterReport"
import AttendanceReport from "../../components/system/reporting/AttendanceReport"
import GuestAttendanceReport from "../../components/system/reporting/GuestAttendanceReport"
import QuarterlyTipReport from "../../components/system/reporting/QuarterlyTipReport"
import WeeklyTipReport from "../../components/system/reporting/WeeklyTipReport"
import ChapterTipClosureReport from "../../components/system/reporting/ChapterTipClosureReport"
import ChapterTipInsideOutsideReport from "../../components/system/reporting/ChapterTipInsideOutsideReport"
import DailyRevenueReport from "../../components/system/reporting/DailyRevenueReport"
import ChapterTipMasterOverviewReport from "../../components/system/reporting/ChapterTipMasterOverviewReport"
import ChapterTippersStackedReport from "../../components/system/reporting/ChapterTippersStackedReport"
import { can, Permission } from "../../components/RequirePermission"
import ChapterTipOpenReport from '../../components/system/reporting/ChapterTipOpenReport'
import MemberLossReport from '../../components/system/reporting/MemberLossReport'
import ChapterRenewalPaymentReport from "../../components/system/reporting/ChapterRenewalPaymentReport"
import DailyRevenueReport2 from "../../components/system/reporting/DailyRevenueReport2"
import AuthStore from "../../stores/AuthStore"
import { UserRole } from "../../models/AuthUser"

const TABS = {
  reports: 'reports',
  custom: 'custom',
}

@observer
export default class ReportingView extends BaseView {
  @observable private activeTab = TABS.reports
  @observable private selectedReportId = ''

  @computed
  private get selectedReport () {
    return this.reports.find(r => r.id === this.selectedReportId)
  }

  private history = createBrowserHistory()

  private reports = [
    {
      id: 'chapter_tips_report',
      name: 'Chapter Tip Report',
      reportComponent: <ChapterTipsReport filterForAdmin={true}/>,
    },
    {
      id: 'quarterly_tip_report',
      name: '4 Quarter Tip Report',
      reportComponent: <QuarterlyTipReport filterForAdmin={true}/>,
    },
    {
      id: 'weekly_tip_report',
      name: 'Chapter Monthly Tip Report',
      reportComponent: <WeeklyTipReport filterForAdmin={true}/>,
    },
    {
      id: 'tip_closure_report',
      name: 'Tip Closure Report',
      reportComponent: <ChapterTipClosureReport filterForAdmin={true}/>,
    },
    {
      id: 'tip_inside_outside_report',
      name: 'Tip Inside vs. Outside Report',
      reportComponent: <ChapterTipInsideOutsideReport filterForAdmin={true}/>,
    },
    {
      id: 'member_roster_report',
      name: 'Member Roster Report',
      reportComponent: <MemberRosterReport filterForAdmin={true}/>,
    },
    {
      id: 'attendance_report',
      name: 'Attendance Report',
      reportComponent: <AttendanceReport filterForAdmin={true}/>
    },
    {
      id: 'guest_attendance_report',
      name: 'Guest Attendance Report',
      reportComponent: <GuestAttendanceReport filterForAdmin={true}/>
    },
    {
      id: 'daily_revenue_report',
      name: 'Daily Revenue Report',
      reportComponent: <DailyRevenueReport/>,
      permissionCheck: () => can(Permission.SeeDailyRevenueReport)
    },
    {
      id: 'daily_revenue_report2',
      name: 'Daily Revenue Report2',
      reportComponent: <DailyRevenueReport2/>,
      permissionCheck: () => AuthStore.getUser()!.hasRole(UserRole.Admin)
    },
    {
      id: 'member_loss_report',
      name: 'Member Loss Report',
      reportComponent: <MemberLossReport/>,
      permissionCheck: () => can(Permission.SeeMemberLossReport)
    },
    {
      id: 'tip_master_overview_report',
      name: 'Tip Master Overview Report',
      reportComponent: <ChapterTipMasterOverviewReport filterForAdmin={true}/>
    },
    {
      id: 'tippers_stacked_report',
      name: 'Tippers Stacked Report',
      reportComponent: <ChapterTippersStackedReport filterForAdmin={true}/>
    },
    {
      id: 'open_tips_report',
      name: 'Open Tips Report',
      reportComponent: <ChapterTipOpenReport filterForAdmin={true}/>
    },
    {
      id: 'renewal_payment_report',
      name: 'Renewal Payment Report',
      reportComponent: <ChapterRenewalPaymentReport filterForAdmin={true}/>
    },
  ].filter(r => !r.permissionCheck || r.permissionCheck())

  renderContentHeader () {
    return (
      <>
        <h1>Reporting</h1>
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    this.activeTab = _.get(this.history.location.state, 'activeTab') || TABS.reports
  }

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(window.location.pathname, { ...this.history.location.state, activeTab: this.activeTab })
    }
  }

  render (): React.ReactNode {
    return <>
      <div className="card">
        <div className="card-header print-hide">
          <Nav tabs className="card-header-tabs">
            <NavItem className={classNames({ active: this.activeTab === TABS.reports })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.reports)
                }}
              >Reports</NavLink>
            </NavItem>
            {
              can(Permission.AccessCustomReports)
                ? <NavItem className={classNames({ active: this.activeTab === TABS.custom })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.custom)
                    }}
                  >Custom Report</NavLink>
                </NavItem>
                : null
            }
          </Nav>
        </div>
        <div className="card-body">
          <TabContent activeTab={this.activeTab}>
            <LazyTabPane tabId={TABS.reports} activeTab={this.activeTab}>
              <div className="row print-hide">
                <div className="col-md-4">
                  <select
                    className="form-control"
                    value={this.selectedReportId}
                    onChange={ev => this.selectedReportId = ev.target.value}
                  >
                    <option value="">(select a report)</option>
                    {this.reports.map(report => <option key={report.id} value={report.id}>{report.name}</option>)}
                  </select>
                </div>
              </div>
              <br/>
              <div className="row">
                <div className="col-12">
                  {
                    this.selectedReport
                      ? this.selectedReport.reportComponent
                      : <p>Choose a report above</p>
                  }
                </div>
              </div>
            </LazyTabPane>

            {
              can(Permission.AccessCustomReports)
                ? <LazyTabPane tabId={TABS.custom} activeTab={this.activeTab}>
                  <CustomReportBuilder/>
                </LazyTabPane>
                : null
            }
          </TabContent>
        </div>
      </div>
    </>
  }

}
