import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import Util, { modelToSnakeCase, nl2br } from "../../../common/Util"
import LoadingPanel from "../../LoadingPanel"
import { EventBusContext } from "../../../common/EventBus"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { observable } from "mobx"
import MembershipRenewal from "../../../models/MembershipRenewal"
import AddRenewalExemptionModal from "../renewals/AddRenewalExemptionModal"
import moment from 'moment-timezone'
import AppStateStore from "../../../stores/AppStateStore"
import { route } from "../../../routes/routes"
import { toast } from "react-toastify"
import ButtonLoader from "../../ButtonLoader"
import FormState from "../../../common/FormState"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"

type Props = {
  memberId: number
}

@observer
export default class ManageMembershipRenewal extends React.Component<Props> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  @observable private activeRenewals?: MembershipRenewal[] = undefined
  @observable private loading = false
  @observable private error?: string
  @observable private showAddExemptionModal = false
  @observable private addExemptionId?: number = undefined
  @observable private showCancelExemptionModal = false
  @observable private cancelExemptionId?: number = undefined

  componentDidMount (): void {
    this.context.eventBus.on('member-invalidated', this.onMemberInvalidated)
    this.loadData()
  }

  private onMemberInvalidated = () => {
    this.loadData()
  }

  componentWillUnmount (): void {
    this.context.eventBus.remove(this.onMemberInvalidated)
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.memberId != prevProps.memberId) {
      this.loadData()
    }
  }

  private loadData = async () => {
    this.loading = true
    this.error = undefined

    try {
      let response = await ApiClient.query(`
    membershipRenewals {
      *
      
      invoice {
        *
      }
      
      renewalExemptions {
        *
        
        createdByUser {
          *
        }
      }
    }
    `, {
        where: [
          { _scope: 'member', value: this.props.memberId },
          { _scope: 'unpaid' },
        ],
      })

      this.activeRenewals = response.data.membershipRenewals.map((r: {}) => new MembershipRenewal().init(r))
    } catch (err) {
      this.error = (err && err.response) ? Util.extractErrorMessage(err.response) : undefined || 'An error has occurred'
    }

    this.loading = false
  }

  render () {
    return <>
      <LoadingPanel loading={this.loading} loaded={this.activeRenewals !== undefined} error={this.error}>
        {() => {
          const activeRenewals = this.activeRenewals!

          if (!activeRenewals.length) {
            return <p>This member does not have any unpaid renewals</p>
          }

          return activeRenewals.map(activeRenewal => <div key={activeRenewal.id}>
            <div className="row">
              <div className="col-4">
                <b>Renewal date</b><br/>
                {activeRenewal.renewalDate.format('MM/DD/YYYY')}
              </div>
              <div className="col-4">
                <b>Late Date</b><br/>
                {activeRenewal.lateDate.format('MM/DD/YYYY')}
              </div>
              <div className="col-4">
                <b>Termination Date</b><br/>
                {activeRenewal.terminationDate.format('MM/DD/YYYY')}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h6 className="text-muted mt-4">Exemptions</h6>
                <div>
                  {
                    activeRenewal.renewalExemptions.length
                      ? <table className="table">
                        <thead>
                        <tr>
                          <th>Exempt Until</th>
                          <th>Notes</th>
                          <th>Created</th>
                          <th>Canceled</th>
                          <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          activeRenewal.renewalExemptions.map(renewalExemption => <tr key={renewalExemption.id}>
                            <td>{renewalExemption.endsAt.format('MM/DD/YYYY')}</td>
                            <td>{nl2br(renewalExemption.note)}</td>
                            <td>
                              {renewalExemption.createdAt.format('MM/DD/YYYY')} by {renewalExemption.createdByUser ? renewalExemption.createdByUser.name : 'system'}
                            </td>
                            <td>
                              {
                                renewalExemption.canceledAt
                                  ? <>
                                    {renewalExemption.canceledAt.format('MM/DD/YYYY')} by {renewalExemption.canceledByUser ? renewalExemption.canceledByUser.name : 'system'} <a href=""
                                                                                                                                                                                 onClick={ev => {
                                                                                                                                                                                   ev.preventDefault()
                                                                                                                                                                                   AppStateStore.showAlertModal('Canceled Note', renewalExemption.canceledNote || 'No note entered')
                                                                                                                                                                                 }}
                                  ><i className="fa fa-question-circle"/></a>
                                  </>
                                  : '---'
                              }
                            </td>
                            <td>
                              {
                                renewalExemption.endsAt.isAfter(moment())
                                  ? <a
                                    href=""
                                    onClick={ev => {
                                      ev.preventDefault()
                                      this.cancelExemptionId = renewalExemption.id
                                      this.showCancelExemptionModal = true
                                    }}
                                  ><i className="fa fa-trash text-danger"/></a>
                                  : null
                              }
                            </td>
                          </tr>)
                        }
                        </tbody>
                      </table>
                      : 'There are no exemptions set up for this renewal'
                  }
                </div>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    this.addExemptionId = activeRenewal.id
                    this.showAddExemptionModal = true
                  }}>
                  Add New Exemption
                </Button>
              </div>
            </div>
          </div>)
        }}
      </LoadingPanel>
      {
        this.addExemptionId
          ? <AddRenewalExemptionModal
            membershipRenewalId={this.addExemptionId}
            isOpen={this.showAddExemptionModal}
            toggle={() => this.showAddExemptionModal = false}
            onClosed={() => this.addExemptionId = undefined}/>
          : null
      }
      {
        this.cancelExemptionId
          ? <CancelExemptionModal
            renewalExemptionId={this.cancelExemptionId}
            isOpen={this.showCancelExemptionModal}
            toggle={() => this.showCancelExemptionModal = false}
            onClosed={() => this.cancelExemptionId = undefined}/>
          : null
      }
    </>
  }
}

type CancelExemptionModalProps = {
  renewalExemptionId: number
  isOpen: boolean
  onClosed: () => void
  toggle: () => void
}

@observer
class CancelExemptionModal extends React.Component<CancelExemptionModalProps> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  @observable private formState = new FormState({
    note: '',
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable private submitting = false

  protected submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitting = true
    this.formErrors.clearErrors()

    ApiClient.getInstance().post(route(ApiRoutes.renewalExemptions.cancelExemption, { exemptionId: this.props.renewalExemptionId }), modelToSnakeCase(this.formHelper.toObject()))
      .then(response => {
        toast.success('The exemption has been cancelled')
        this.context.eventBus.dispatch('member-invalidated')
        this.props.toggle()
      })
      .catch(error => {
        Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      })
      .then(() => this.submitting = false)
  }

  render () {
    return <Modal isOpen={this.props.isOpen} onClosed={this.props.onClosed} toggle={this.props.toggle}>
      <ModalHeader toggle={this.props.toggle}>
        Cancel Renewal Exemption
      </ModalHeader>
      <ModalBody>
        <form onSubmit={this.submit}>
          <div className="form-row">
            <div className="col-12">
              {this.formHelper.renderTextAreaInput({
                name: 'note',
                label: 'Notes',
              })}
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <ButtonLoader
          loading={this.submitting}
          type="button"
          color="primary"
          onClick={this.submit}
        >Cancel Exemption</ButtonLoader>
        <Button
          type="button"
          color="secondary"
          onClick={this.props.toggle}
        >Cancel</Button>
      </ModalFooter>
    </Modal>
  }
}