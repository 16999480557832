import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import Util, { modelToCamelCase, modelToSnakeCase, publicPath } from "../../common/Util"
import Config from "../../common/Config"
import { BarLoader } from "react-spinners"
import ApiClient from '../../api/ApiClient'
import FormState from '../../common/FormState'
import ErrorBag from '../../common/ErrorBag'
import FormHelper from '../../forms/FormHelper'
import AppStateStore from '../../stores/AppStateStore'
import ButtonLoader from '../../components/ButtonLoader'

type Props = {
  match: {
    params: {
      slug: string
    }
  }
}

@observer
export default class JoinExtendedNetworkView extends React.Component<Props> {
  @observable private loading = false
  @observable private error?: string
  @observable private data?: {
    member: {
      fullName: string
    }
  }

  private originalViewportMetaContent: string | null | undefined = undefined

  private initialFormState = {
    name: '',
    destinationType: 'phone',
    destination: '',
  }

  @observable private formState = new FormState(this.initialFormState)

  @observable private formErrors = new ErrorBag()
  @observable private submitting = false
  @observable private showConfirmation = false

  private formHelper = new FormHelper(this.formState, this.formErrors)

  componentDidMount (): void {
    this.loadData().then()

    const meta = document.getElementsByTagName('META')
    let viewportTag: Element | undefined = undefined
    console.log(JSON.stringify(meta))
    for (let i = 0; i < meta.length; ++i) {
      const name = meta[i].getAttribute('name')
      if (name && name.toLowerCase() === 'viewport') {
        viewportTag = meta[i]
        break
      }
    }

    if (viewportTag) {
      this.originalViewportMetaContent = viewportTag.getAttribute('content')
      viewportTag.setAttribute('content', 'width=device-width, initial-scale=1')
    }
  }

  componentWillUnmount () {
    if (this.originalViewportMetaContent) {
      const meta = document.getElementsByTagName('META')
      let viewportTag: Element | undefined = undefined
      console.log(JSON.stringify(meta))
      for (let i = 0; i < meta.length; ++i) {
        const name = meta[i].getAttribute('name')
        if (name && name.toLowerCase() === 'viewport') {
          viewportTag = meta[i]
          break
        }
      }

      if (viewportTag) {
        viewportTag.setAttribute('content', this.originalViewportMetaContent)
      }
    }
  }

  private loadData = async () => {
    this.loading = true

    try {
      const response = await ApiClient.getInstance().get(`/join-extended-network/${this.props.match.params.slug}`)
      this.data = modelToCamelCase(response.data) as any
    } catch (err) {
      this.error = Util.extractErrorMessage(err)
    }

    this.loading = false
  }

  private renderError = () => {
    return <div className="alert alert-danger">{this.error}</div>
  }

  private submit = async (ev: SyntheticEvent) => {
    ev && ev.preventDefault()

    this.formErrors.clearErrors()

    this.submitting = true
    this.error = undefined
    AppStateStore.showModalSpinner()

    try {
      const response = await ApiClient.getInstance().post(`/join-extended-network/${this.props.match.params.slug}`, modelToSnakeCase(this.formHelper.toObject()))
      this.showConfirmation = true
    } catch (err) {
      Util.handleErrorResponse(err.response, this.formErrors)
    }

    AppStateStore.dismissModalSpinner()
    this.submitting = false
  }

  private renderConfirmation = () => {
    return <div className={'alert alert-info'}>
      <p>An invitation has been sent to the provided email address or phone number with instructions for joining the extended network.</p>
    </div>
  }

  render (): React.ReactNode {
    return <>
      <div className="header-container">
        <div className="brand">
          <img className="brand-image" alt="LeTip Wired" src={publicPath('img/wired-logo.png')}/>
        </div>
        <div className="header-nav"/>
      </div>
      <div className="body-container invitation-response">
        <div className="content-container">
          <div className="content">
            {this.showConfirmation
              ? this.renderConfirmation()
              : this.error
                ? this.renderError()
                : this.loading
                  ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
                  : this.data ?
                    <>
                      <p><b>{this.data.member.fullName}</b> has invited to you to join their extended network! Please enter either your phone number or email address below and you will be sent a link with instructions for joining the extended network.</p>
                      <hr/>
                      <form onSubmit={this.submit}>
                        <div className="form-row">
                          <div className="col-12">
                            {
                              this.formHelper.renderTextInput({
                                label: 'Name',
                                name: 'name',
                                disabled: this.submitting,
                              })
                            }
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-12">
                            {
                              this.formHelper.renderSelectInput({
                                label: 'Contact Method',
                                name: 'destinationType',
                                options: [
                                  { value: 'phone', text: 'Phone (Text Message)' },
                                  { value: 'email', text: 'Email Address' },
                                ],
                                disabled: this.submitting,
                              })
                            }
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-12">
                            {
                              this.formHelper.renderTextInput({
                                label: this.formState.get('destinationType') === 'email' ? 'Email Address' : 'Phone Number',
                                name: 'destination',
                                disabled: this.submitting,
                              })
                            }
                          </div>
                        </div>
                        <div>
                          <ButtonLoader
                            loading={this.submitting}
                            block={true}
                            type={'submit'}
                            color={'primary'}
                          >
                            Join Extended Network
                          </ButtonLoader>
                        </div>
                      </form>
                    </>
                    : null
            }
          </div>
          <div className="content-footer">
            Version {Config.VERSION || '???'}{Config.BUILD_NUMBER ? `-${Config.BUILD_NUMBER}` : null} {Config.ENV !== 'production' ? `(${Config.ENV})` : null} {Config.NODE_ENV !== 'production' ? `[${Config.NODE_ENV}]` : null}
          </div>
        </div>
      </div>
    </>
  }
}
