import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Member from "./Member"
import Chapter from "./Chapter"

export default class TipBatch extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  batchDate: Moment
  value: number
  note: string
  memberId: number
  chapterId: number

  member: Member
  chapter: Chapter

  setMember (a?: {}) {
    return a ? new Member().init(a) : null
  }

  setChapter (a?: {}) {
    return a ? new Chapter().init(a) : null
  }

  getCasts (): {} {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      batchDate: 'datetime',
      value: 'number',
      memberId: 'number',
      chapterId: 'number',
    }
  }
}
