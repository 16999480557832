import { observer } from "mobx-react"
import React from "react"
import BaseFilterComponent from './BaseFilterComponent'
import { DateFilterState } from './DateFilter'
import DateTime from 'react-datetime'
import moment from 'moment-timezone'

export type Props = {
  label: string
  state: DateFilterState
  onStartDateChanged: (value: string) => void
  onEndDateChanged: (value: string) => void
}

@observer
export default class DateFilterComponent extends BaseFilterComponent<Props> {
  private onStartDateChanged = (value: string) => {
    this.props.onStartDateChanged(value)
    this.props.eventBus.dispatch('changed')
  }

  private onEndDateChanged = (value: string) => {
    this.props.onEndDateChanged(value)
    this.props.eventBus.dispatch('changed')
  }

  render () {
    return <div className="form-group">
      {this.props.label && <label>{this.props.label}</label>}
      <div className="d-flex align-items-center">
        <div className="mr-2">Between</div>
        <div style={{ width: 180 }}>
          <DateTime
            dateFormat="YYYY-MM-DD"
            value={this.props.state.startDate}
            onChange={(v: any) => {
              this.onStartDateChanged(moment.isMoment(v) ? v.format('YYYY-MM-DD') : v)
            }}
            timeFormat={false}
            closeOnSelect={true}
          />
        </div>
        <div className="mx-2">and</div>
        <div style={{ width: 180 }}>
          <DateTime
            inputProps={{ style: { width: 'auto' } }}
            dateFormat="YYYY-MM-DD"
            value={this.props.state.endDate}
            onChange={(v: any) => {
              this.onEndDateChanged(moment.isMoment(v) ? v.format('YYYY-MM-DD') : v)
            }}
            timeFormat={false}
            closeOnSelect={true}
          />
        </div>
      </div>
    </div>
  }
}