import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import FormHelper from "../../forms/FormHelper"
import AppStateStore from "../../stores/AppStateStore"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import { toast } from "react-toastify"
import { submitFormRequest } from "../../api/ApiHelper"
import { route } from "../../routes/routes"
import Util, { modelToSnakeCase, safeNull } from "../../common/Util"
import ButtonLoader from "../ButtonLoader"
import moment from 'moment-timezone'
import AuthStore from '../../stores/AuthStore'
import RequirePermission, { Permission } from '../RequirePermission'
import { MemberPickerInput } from '../inputs/MemberPickerInput'
import { UserRole } from '../../models/AuthUser'
import * as _ from 'lodash'
import TipBatch from '../../models/TipBatch'
import Tip from '../../models/Tip'

type EditTipBatchModalProps = {
  isOpen: boolean
  toggle: () => void
  onSaved: () => void
  onClosed?: () => void
  tipBatchId: number
}

@observer
export class EditTipBatchModal extends React.Component<EditTipBatchModalProps> {
  private initialFormState = {
    memberId: safeNull(() => AuthStore.getUser()!.member!.id),
    date: moment().format('MM/DD/YYYY'),
    value: '',
    note: '',
  }

  @observable private tipBatch: TipBatch | undefined = undefined

  @observable private formState = new FormState(this.initialFormState)

  @observable private formErrors = new ErrorBag()
  @observable private submitting = false

  private formHelper = new FormHelper(this.formState, this.formErrors)

  componentDidMount (): void {
    this.loadTipBatch()
  }

  private loadTipBatch = () => {
    ApiClient.query(`
    tipBatch {
      *
    }
    `, {
      where: [{ id: this.props.tipBatchId }]
    }).then(response => {
      this.tipBatch = new TipBatch().init(response.data.tipBatch)

      this.formState.setAll({
        date: this.tipBatch.batchDate.format('MM/DD/YYYY'),
        value: this.tipBatch.value || '',
        note: this.tipBatch.note || '',
      })
    })
      .catch(error => {
        this.props.toggle()
        AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response))
      })
  }

  componentDidUpdate (prevProps: Readonly<EditTipBatchModalProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.tipBatchId !== this.props.tipBatchId) {
      this.tipBatch = undefined
      this.loadTipBatch()
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()

    this.submitting = true
    AppStateStore.showModalSpinner()

    submitFormRequest(
      ApiClient.getInstance()
        .post(route(ApiRoutes.tips.batches.update, {id: this.props.tipBatchId}), modelToSnakeCase(this.formState.toObject())),
      this.formState,
      this.formErrors,
      () => {
        toast.success('Tip Batch Updated')
        this.props.onSaved()
      }
    )
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private toggle = () => {
    !this.submitting && this.props.toggle()
  }

  render (): React.ReactNode {
    const { isOpen } = this.props

    return <Modal isOpen={isOpen} toggle={this.toggle} size="md" onClosed={this.props.onClosed}>
      <ModalHeader toggle={this.toggle}>
        Update Tip Batch
      </ModalHeader>
      <ModalBody>
        <form onSubmit={this.submit}>
          <RequirePermission permission={Permission.SendTipAsMember} renderIfDenied={false}>
            {() => <div className="form-row">
              <div className="col-12">
                <label>Member</label>
                <MemberPickerInput
                  filters={AuthStore.getUser()!.hasAnyRole([UserRole.Admin, UserRole.Employee]) ? {} : { chapter: AuthStore.getUser()!.chapter.id }}
                  placeholder="Choose Member"
                  onChange={member => this.formState.set('memberId', _.get(member, 'id'))}
                  value={this.formState.get('memberId')}
                  allowClear={false}
                />
              </div>
            </div>}
          </RequirePermission>
          <div className="form-row">
            <div className="col-md-6">
              {
                this.formHelper.renderDatePickerInput({
                  label: 'Batch Date',
                  name: 'date',
                  disabled: this.submitting,
                })
              }
            </div>
            <div className="col-md-6">
              {
                this.formHelper.renderTextInput({
                  label: 'Value',
                  name: 'value',
                  prepend: '$',
                  disabled: this.submitting,
                })
              }
            </div>
          </div>
          <div className="form-row">
            <div className="col-12">
              {
                this.formHelper.renderTextAreaInput({
                  label: 'Notes',
                  name: 'note',
                  disabled: this.submitting,
                })
              }
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={this.submitting} onClick={this.toggle}>Cancel</Button>
        <ButtonLoader type="button" color="primary" loading={this.submitting} onClick={this.submit}>Update Batch</ButtonLoader>
      </ModalFooter>
    </Modal>
  }
}
