import { textval } from "./Util"
import * as version from "./version.json"

class Config {
  NODE_ENV?: string = process.env.NODE_ENV
  ENV?: string = process.env.REACT_APP_ENV
  API_URL?: string = process.env.REACT_APP_API_URL
  SENTRY_DSN?: string = process.env.REACT_APP_SENTRY_DSN
  SENTRY_DISABLE_CAPTURE: boolean = !!textval(process.env.REACT_APP_SENTRY_DISABLE_CAPTURE)
  OLARK_DISABLE_WIDGET: boolean = !!textval(process.env.REACT_APP_OLARK_DISABLE_WIDGET)
  GOOGLE_API_KEY?: string = process.env.REACT_APP_GOOGLE_API_KEY
  GOOGLE_ANALYTICS_TRACKING_ID?: string = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
  OLARK_ID?: string = process.env.REACT_APP_OLARK_ID
  HIDE_EBOOK_LINK?: boolean = !!textval(process.env.REACT_APP_HIDE_EBOOK_LINK)
  API_VERSION: number = 1
  VERSION: string = version.version
  BUILD_NUMBER: number = version.buildNumber

  ENABLE_DEV_TOOLS: boolean = !!textval(process.env.REACT_APP_ENABLE_DEV_TOOLS)

  AUTH_NET_SANDBOX: boolean = !!textval(process.env.REACT_APP_AUTH_NET_SANDBOX)
  AUTH_NET_CLIENT_KEY: string = process.env.REACT_APP_AUTH_NET_CLIENT_KEY || ''
  AUTH_NET_API_LOGIN_ID: string = process.env.REACT_APP_AUTH_NET_API_LOGIN_ID || ''

  DEBOUNCE_TIME_MS = 750
  TABLE_VIEW_PAGE_SIZE = 50
  FETCH_NOTIFICATIONS_INTERVAL = 1000 * 60 * 5
  FETCH_BADGE_COUNTS_INTERVAL = 1000 * 60 * 5

  DISABLE_EXTENDED_NETWORK_MENU = !!textval(process.env.REACT_APP_DISABLE_EXTENDED_NETWORK_MENU)

  MEMBERSHIP_TYPE_OPTIONS = [
    'Personal',
    'Company',
  ]

  COUNTRY_OPTIONS = [
    'United States',
    'Canada'
  ]

  MEMBER_STATUS_OPTIONS = [
    'Pending',
    'New',
    'Active',
    'Resigned',
    'Terminated',
    'Chapter Disbanded',
    'Banned',
  ]

  MEMBER_TYPE_OPTIONS = [
    'New',
    'Returning',
    'Category Change',
    'Transfer',
    'Company Replacement',
  ]

  CHAPTER_STATUS_OPTIONS = [
    'Active',
    'Closed',
    'Disbanded',
    'Incubation',
    'Pre-Sign',
  ]

  NAME_PREFIX_OPTIONS = [
    'Mr.',
    'Mrs.',
    'Dr.',
    'Ms.',
    'M.',
  ]

  NAME_SUFFIX_OPTIONS = [
    'Jr.',
    'Sr.',
  ]

  GENDER_OPTIONS = [
    'Male',
    'Female',
    'Do Not Disclose',
  ]

  PHONE_NUMBER_LABEL_OPTIONS = [
    'Cell',
    'Home',
    'Office',
    'Fax',
    'Business',
    'Other',
    'Toll Free',
    'Alternate',
    'Phone 2',
    'Phone 3',
  ]

  EMAIL_ADDRESS_LABEL_OPTIONS = [
    'Home',
    'Business',
    'E-Mail',
    'Other'
  ]

  BADGE_COLORS = [
    'White',
    'Blue',
    'Green',
    'Silver',
    'Platinum',
    'Gold',
    'Purple',
  ]
}

const config = new Config()

export default config as Config
