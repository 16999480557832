import ReportDataSource from "../ReportDataSource"
import { ApiTableViewAdapter, ApiTableViewAdapterOptions } from "../../components/table-view/ApiTableViewAdapter"
import Member from "../../models/Member"
import { QueryWhereClause } from "../../api/ApiClient"
import { MemberResource } from "../resources/MemberResource"

export default class MemberReportDataSource extends ReportDataSource {
  constructor () {
    super(MemberResource)
  }

  createTableViewAdapter (withRelationships: string[], where: QueryWhereClause[], options?: ApiTableViewAdapterOptions<any>): ApiTableViewAdapter<any> {
    return new ApiTableViewAdapter(() => {
      return {
        query: `
        members {
          *
          primaryPositionName
          ${this.expandRelationships(withRelationships)}
        }`,
        where: where,
      }
    }, 'members', Member, options)
  }
}
