import React from 'react'

type Props = {
  checked: boolean
}

export default class StaticCheckbox extends React.Component<Props> {
  render () {
    return <div style={{ display: 'inline-block', border: 'solid 1px #000', padding: 2, width: 20, height: 20, fontWeight: 'bold', lineHeight: '100%', textAlign: 'center', fontSize: 16 }}>{this.props.checked ? 'x' : <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }}/>}</div>
  }
}
