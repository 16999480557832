import BaseModel from "./BaseModel"
import Event from "./Event"
import Member from "./Member"
import ProgramPosition from "./ProgramPosition"

export default class ProgramScheduleRecord extends BaseModel {
  event: Event
  member: Member
  position: ProgramPosition

  setEvent (e?: {}) {
    return e ? new Event().init(e) : null
  }

  setMember (m?: {}) {
    return m ? new Member().init(m) : null
  }

  setPosition (p?: {}) {
    return p ? new ProgramPosition().init(p) : null
  }
}