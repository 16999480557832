import BaseModel from "./BaseModel"

export enum MeetingType {
  Hybrid = 'hybrid',
  InPerson = 'in-person',
  Virtual = 'virtual'
}


export default class MeetingSlot extends BaseModel{
  id: number
  weekIndex: string
  meetingType: MeetingType
  label: string

  constructor (data?: {}) {
    super()
    data && this.init(data)
  }

}
