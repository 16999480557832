import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Event from "./Event"

export default class BoardTraining extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  cancelledAt: Moment
  date: Moment
  timezone: string
  zoomMeetingInfo: string
  numberOfMembers: number
  calendarEvent: Event

  getCasts () {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      cancelledAt: 'datetime',
      date: 'datetime',
    }
  }
}
