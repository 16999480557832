import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"

export default class BadgeShippingAddress extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  businessName: string | null
  name: string
  address: string
  address2: string | null
  city: string
  state: string
  zipCode: string
  country: string

  getCasts () {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
    }
  }
}
