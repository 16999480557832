import * as React from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import { ChooseableColumn, TableViewColumnChooser } from "../table-view/TableViewColumnChooser"
import { Link } from "react-router-dom"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { safeNull } from "../../common/Util"
import MemberCell from "../cells/MemberCell"
import TableView from "../table-view/TableView"
import { ApiTableViewAdapter } from "../table-view/ApiTableViewAdapter"
import Application, { ApplicationType } from "../../models/Application"
import Config from "../../common/Config"
import { TableViewFilters } from "../table-view/TableViewFilters"
import { TableViewPagination } from "../table-view/TableViewPagination"

type Props = {
  chapterId: number
}

@observer
export default class ChapterApplications extends React.Component<Props> {
  @observable private availableColumns: ChooseableColumn[] = [
    {
      column: {
        title: 'Received',
        accessor: 'submittedAt',
        renderItem: value => value ? value.format('MM/DD/YYYY') : '---',
        sortable: true,
      },
      fixed: true,
    },
    {
      column: {
        title: 'Applicant',
        sortable: false,
        renderItem: (value, item: Application) => {
          let url: any = route(Routes.system.applications.show, { id: item.id })

          if (item.applicationType === ApplicationType.NewMember && item.status === 'Pending') {
            url = { pathname: route(Routes.system.guests.show, { id: item.guest.id }), state: { id: item.guest.id } }
          }

          return item.applicationType == ApplicationType.ChapterTransfer
            ? <>
              <div><Link type="link" to={url}>{item.member.fullName}</Link></div>
              <div>{safeNull(() => item.member.category!.name)}</div>
              <div>{safeNull(() => item.member.business!.name)}</div>
            </>
            : <>
              <div><Link type="link" to={url}>{item.guest.fullName}</Link></div>
              <div>{safeNull(() => item.guest.category!.name)}</div>
              <div>{item.guest.companyName}</div>
            </>
        },
      },
      fixed: true,
    },
    {
      column: {
        title: 'Chapter',
        accessor: 'sponsor.chapter',
        renderItem: (value, item: Application) => <Link to={route(Routes.system.chapters.show, { id: item.targetChapter.id })}>{item.targetChapter.name}</Link>
      },
      default: true,
    },
    {
      column: {
        title: 'Invited By',
        accessor: 'sponsor',
        renderItem: value => value ? <MemberCell member={value}/> : '---',
      },
      default: true,
    },

    {
      column: {
        title: 'Sent',
        accessor: 'createdAt',
        renderItem: value => value.format('MM/DD/YYYY'),
        sortable: true,
      },
      default: true,
    },
    {
      column: {
        title: 'Status',
        accessor: 'status',
        sortable: true,
        renderItem: (status: string, application: Application) => application.getStatusString()
      },
      default: true,
    },
    {
      column: {
        renderItem: (value, item) => <div className="table-actions">
          <Link type="link" to={{ pathname: route(Routes.system.applications.show, { id: item.id }), state: { id: item.id } }}><i className="fa fa-search"/></Link>
        </div>
      },
      fixed: true,
      hidden: true,
    },
  ]

  @observable visibleColumns = TableViewColumnChooser.getInitialColumns(this.availableColumns)
  @observable private numberOfPages: number = 0
  @observable private currentPage: number = 1
  @observable private filters = {}
  private tableViewRef = React.createRef<TableView>()

  private setPage = (page: number) => {
    this.currentPage = page
  }

  private applicationsAdapter = new ApiTableViewAdapter(() => {
    return {
      query: `
    applications {
      id
      createdAt
      viewedAt
      submittedAt
      status
      applicationType
      applicationPdfUrl
      
      targetChapter {
        id
        name
      }

      sponsor {
        id
        fullName
        profileImageUrl
        
        category {
          name
        }
        
        chapter {
          id
          name
        }
      }
      
      guest {
        id
        fullName
        companyName
      }
      
      member {
        id
        fullName
        profileImageUrl
        
        chapter {
          id
          name
        }
        
        category {
          name
        }
        
        business {
          name
        }
      }
    }`,
      where: [
        { _scope: 'inQueue' },
        { _scope: 'chapter', value: this.props.chapterId },
      ]
    }
  }, 'applications', Application, {
    onFetched: result => {
      this.numberOfPages = Math.ceil(result.total / Config.TABLE_VIEW_PAGE_SIZE)
      if (this.currentPage < 1) {
        this.currentPage = 1
      }
      if (this.currentPage > this.numberOfPages) {
        this.currentPage = this.numberOfPages
      }
    }
  })

  render () {
    return <>
      <div>
        <TableViewFilters
          initialState={{ searchText: '' }}
          searchLabel="Find An Application"
          onChanged={filters => this.filters = { search: filters.search, filters: filters.filters.map(f => f.getWhereClause()) }}
        />
      </div>

      <TableView
        hideItemCount={true}
        ref={this.tableViewRef}
        adapter={this.applicationsAdapter}
        columns={this.visibleColumns}
        pagination={{
          offset: Math.max((this.currentPage - 1), 0) * Config.TABLE_VIEW_PAGE_SIZE,
          limit: Config.TABLE_VIEW_PAGE_SIZE,
        }}
        filters={this.filters}
      />
      {
        this.numberOfPages > 1
          ? <TableViewPagination numberOfPages={this.numberOfPages} currentPage={this.currentPage} setPage={page => this.setPage(page)}/>
          : null
      }
    </>
  }
}
