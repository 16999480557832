import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import FormState from "../../../common/FormState"
import { observable } from "mobx"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"
import { loadChapterOptions, loadChapterOptionsForAdmin } from "../../../api/AsyncHelpers"
import moment, { Moment } from 'moment-timezone'
import { Button } from "reactstrap"
import { BarLoader } from "react-spinners"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import { route } from "../../../routes/routes"
import Util, { formatCurrency, formatNumber, modelToCamelCase, modelToSnakeCase } from "../../../common/Util"
import ExportButton, { ExportButtonFetchCallback } from "../../ExportButton"
import ChapterTippersStackedCsvTransformer from "../../../models/renderers/ChapterTippersStackedCsvTransformer"
import _ from 'lodash';

export type TippersStackedReportData = {
  member: {
    id: number
    firstName: string
    lastName: string
    joinDate: string | null
    inactiveDate: string | null
  }

  tipData: {
    totalSent: number
    totalSentValue: number
  }
}

type Props = {
  chapterId?: number
  filterForAdmin?: boolean
}

@observer
export default class ChapterTippersStackedReport extends React.Component<Props> {
  @observable private loading = false
  @observable private error?: string
  @observable private formState = new FormState({
    chapterId: undefined,
    startDate: moment().startOf('month').format('MM/DD/YYYY'),
    endDate: moment().endOf('month').format('MM/DD/YYYY'),
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable chapterData?: { id: number, name: string }
  @observable reportData?: TippersStackedReportData[]
  @observable startDate?: Moment
  @observable endDate?: Moment

  componentDidMount (): void {
    if (this.props.chapterId) {
      this.formState.set('chapterId', this.props.chapterId)
    }
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.chapterId != this.props.chapterId) {
      this.formState.set('chapterId', this.props.chapterId)
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.error = undefined
    this.loading = true

    ApiClient.getInstance()
      .post(route(ApiRoutes.reporting.chapterTippersStackedReport, { chapterId: this.formState.get('chapterId') }), modelToSnakeCase(this.formState.toObject()))
      .then(response => {
        this.chapterData = modelToCamelCase(response.data.chapter) as any
        this.reportData = _.orderBy(response.data.report_data.map((rd: any) => modelToCamelCase(rd)), t => t.tipData.totalSent, 'desc')
        this.startDate = moment(response.data.start_date);
        this.endDate = moment(response.data.end_date)
      })
      .catch(error => this.error = Util.extractErrorMessage(error.response))
      .then(() => this.loading = false)
  }

  render () {
    return <>
      <div className="print-hide">
        <form action="" method="post" onSubmit={this.submit}>
          <div className="row align-items-end">
            {
              !this.props.chapterId
                ? <div className="col-md-4">
                  {this.formHelper.renderAsyncSelectInput({
                    label: 'Chapter',
                    name: 'chapterId',
                    loadOptions: this.props.filterForAdmin ? loadChapterOptionsForAdmin : loadChapterOptions,
                  })}
                </div>
                : null
            }
            <div className="col-md-3">
              {this.formHelper.renderDatePickerInput({
                label: 'Start Date',
                name: 'startDate',
              })}
            </div>
            <div className="col-md-3">
              {this.formHelper.renderDatePickerInput({
                label: 'End Date',
                name: 'endDate',
              })}
            </div>
            <div className="col-md-2 d-flex">
              <div className="form-group d-flex flex-column">
                <div className="flex-fill"/>
                <div>
                  <Button
                    disabled={this.loading || !this.formState.get('chapterId') || !this.formState.get('startDate').length || !this.formState.get('endDate').length}
                    style={{
                      height: 'calc(2.95rem + 2px)'
                    }}
                    type="submit"
                    color="success"
                  ><i className="fa fa-play"/> Run Report</Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {
        this.loading
          ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
          : this.error
          ? <div className="alert alert-danger">{this.error}</div>
          : this.reportData
            ? this.renderReport()
            : <p>Choose report parameters above</p>
      }
    </>
  }

  private fetchPrintData: ExportButtonFetchCallback<TippersStackedReportData> = (onFetched, onError, onFinished) => {
    if (this.reportData) {
      onFetched(this.reportData)
    }

    onFinished()
  }

  private renderReport () {
    const chapter = this.chapterData!
    const reportData = this.reportData!
    const startDate = this.startDate!
    const endDate = this.endDate!

    return <>
      <h1 className="text-center my-4">Tippers Stacked Report For {chapter.name}</h1>
      <h3 className="text-center mb-4">{startDate.format('MMMM D, YYYY')} - {endDate.format('MMMM D, YYYY')}</h3>
      <div className="form-buttons mb-4 print-hide">
        <ExportButton
          fetch={this.fetchPrintData}
          transformerClass={() => new ChapterTippersStackedCsvTransformer()}
        />
        <Button
          type="button"
          color="primary"
          onClick={() => window.print()}
        ><i className="fa fa-print"/> Print Report</Button>
      </div>
      <table className="chapter-tip-report-table">
        <thead>
        <tr>
          <th/>
          <th className="border-left border-top border-bottom text-left">First Name</th>
          <th className="border-top border-bottom text-left">Last Name</th>
          <th className="border-top border-bottom text-center">Join Date</th>
          <th className="border-top border-bottom text-center">Inactive Date</th>
          <th className="border-left border-top border-bottom">Tips Passed</th>
          <th className="border-top border-bottom text-right">Value Sum</th>
          <th className="border-top border-bottom text-right">Value Avg</th>
        </tr>
        </thead>
        <tbody>
        {
          reportData.map((memberTipData, index) => <tr key={memberTipData.member.id}>
            <td className="text-right">{index + 1}</td>
            <td style={{ whiteSpace: 'nowrap' }}>
              {memberTipData.member.firstName}
            </td>
            <td style={{ whiteSpace: 'nowrap' }}>
              {memberTipData.member.lastName}
            </td>
            <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
              {memberTipData.member.joinDate ? moment(memberTipData.member.joinDate).format('MM/DD/YYYY') : '---'}
            </td>
            <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
              {memberTipData.member.inactiveDate ? moment(memberTipData.member.inactiveDate).format('MM/DD/YYYY') : '---'}
            </td>
            <td className="border-left text-center">{formatNumber(memberTipData.tipData.totalSent)}</td>
            <td className="text-right">{formatCurrency(memberTipData.tipData.totalSentValue)}</td>
            <td className="text-right">{memberTipData.tipData.totalSent ? formatCurrency(memberTipData.tipData.totalSentValue / memberTipData.tipData.totalSent) : '---'}</td>
          </tr>)
        }
        </tbody>
      </table>
    </>
  }
}