import { observer } from "mobx-react"
import * as React from "react"
import { ChangeEvent } from "react"
import { EventType } from "../../models/Event"

@observer
export class MeetingTypeFilters extends React.Component<{
  selectedMeetingTypes: string[]
  onClicked: (ev: ChangeEvent<HTMLInputElement>, meetingTypeId: string) => void
}> {

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    const meetingTypeFilters = [
      {
        id: EventType.ChapterMeeting,
        label: 'Chapter Meeting',
      },
      {
        id: EventType.BoardMeeting,
        label: 'Board Meeting',
      },
      {
        id: EventType.BusinessMixer,
        label: 'Business Mixer',
      },
      {
        id: EventType.NTS,
        label: 'Network Training Seminar',
      },
      {
        id: EventType.WhiteBadgeTraining,
        label: 'White Badge Training Seminar',
      },

      {
        id: EventType.NationalLeadershipCall,
        label: 'LeTip National Leadership Call',
      },

      // {
      //   id: EventType.PresidentMonthlyCalls,
      //   label: 'National Monthly Special Topic Call: Pres & VP',
      // },
      // {
      //   id: EventType.BoardMonthlyCalls,
      //   label: 'National Monthly Special Topic Call: Membership & TipMaster',
      // },
      {
        id: EventType.BTS,
        label: 'Board Training Seminar',
      },
      {
        id: EventType.PowerEvents,
        label: 'Power Events',
      },
      {
        id: EventType.Other,
        label: 'Other',
      },
    ]

    return meetingTypeFilters.map(meetingType =>
      <div key={meetingType.id} className="form-check form-checkbox">
        <label>
          <input type="checkbox"
                 className="form-check-input"
                 checked={this.props.selectedMeetingTypes.indexOf(meetingType.id) > -1}
                 onChange={(ev) => this.props.onClicked(ev, meetingType.id)}
          />
          <span className="label-text">{meetingType.label}</span>
        </label>
      </div>
    )
  }
}
