import React from 'react';

const imageLinks = {
  ios: 'https://linkmaker.itunes.apple.com/images/badges/en-us/badge_appstore-lrg.svg',
  android: 'https://upload.wikimedia.org/wikipedia/commons/c/cd/Get_it_on_Google_play.svg',
}

type Props = {
  store: string,
  url: string,
  height: number,
  width: number,
  linkProps: any,
}

export default class MobileStoreButton extends React.Component<Props> {
  static defaultProps = {
    height: 75,
    width: 255,
    linkProps: {},
  }

  render () {
    const {
      store,
      url,
      height,
      width,
      linkProps,
      ...props
    } = this.props

    const linkStyles = {
      background: `url(${imageLinks[store]}) no-repeat`,
      backgroundSize: 'contain',
      display: 'inline-block',
      overflow: 'hidden',
      textDecoration: 'none',
      height: '100%',
      width: '100%',
      padding: '5px',
    }

    return (
      <div style={{ height, width, display: 'inline-block' }} {...props}>
        <a
          style={linkStyles}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          {...linkProps}
        >
          &nbsp;
        </a>
      </div>
    )
  }
}
