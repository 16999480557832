import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import Member from "../models/Member"
import { computed, observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import AppStateStore from "../stores/AppStateStore"
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, InputGroup } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import moment from 'moment-timezone'
import ApiClient from "../api/ApiClient"
import Util, { createLazyResource, formatCurrency, logException, modelToSnakeCase, safeNull, transformIf } from "../common/Util"
import { toast } from "react-toastify"
import LazyResourceView from "./LazyResourceView"
import EventBus, { EventBusContext } from "../common/EventBus"
import AddPaymentMethodModal from "./AddPaymentMethodModal"

type Props = {
  memberId: number
  onCancel: () => void
  onSaved: () => void
}

type EditFormState = {
  startDate: string
  endDate: string
  collectMembershipFee: boolean
  paymentMethod?: number | string
  checkInformation: string
  paymentAmount: number
}

@observer
export default class StartMembershipForm extends React.Component<Props> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  componentDidMount (): void {
    this.addListeners(this.context.eventBus)
  }

  componentWillUnmount (): void {
    this.removeListeners(this.context.eventBus)
  }

  private addListeners = (eventBus: EventBus) => {
    eventBus.on('member-invalidated', this.onMemberInvalidated)
  }

  private removeListeners = (eventBus: EventBus) => {
    eventBus.remove(this.onMemberInvalidated)
  }

  private onMemberInvalidated = () => {
    this.member.invalidate()
  }

  @computed
  private get paymentMethodOptions () {
    const options: { value: any, text: string }[] = safeNull(() => this.member.current!.paymentMethods.current!.map(pm => ({ value: String(pm.id), text: pm.optionDescription }))) || []

    return options.concat([
      {
        value: 'personal_check',
        text: 'Personal Check',
      },
      {
        value: 'company_check',
        text: 'Company Check',
      },
      {
        value: 'money_order',
        text: 'Cashier\'s Check or Money Order',
      },
    ])
  }

  @observable private member = createLazyResource<Member>(() => {
    return ApiClient.query(
      `
member {
  *
}
      `,
      {
        where: [{ id: this.props.memberId }]
      }
    )
  }, response => new Member(response.data.member))

  @observable
  private formState = new FormState<EditFormState>({
    startDate: moment().format('MM/DD/YYYY'),
    endDate: '',
    collectMembershipFee: true,
    paymentMethod: undefined,
    checkInformation: '',
    paymentAmount: 0,
  })

  @observable renderAddPaymentMethodModal = false
  @observable showAddPaymentMethodModal = false

  @observable
  private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  componentWillMount (): void {
    this.updateEndDate()

    this.member.get().then(member => {
      member.defaultPaymentMethod.get().then(pm => {
        pm && this.formState.set('paymentMethod', String(pm.id))
      })

      member.membershipFees.get().then(mf => {
        this.formState.set('paymentAmount', mf.membershipFee)
      })
    })
  }

  @observable
  private submitting = false

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    const msg = this.formState.get('collectMembershipFee')
      ? 'Are you sure you want to start this membership and charge the selected payment method?'
      : 'Are you sure you want to start this membership without any payment?'

    AppStateStore.showConfirmationModal('Start Membership', msg, (result, modal) => {
      if (result) {
        this.formErrors.clearErrors()
        this.submitting = true
        AppStateStore.showModalSpinner()

        ApiClient.members.createMembership(this.member.current!.id, modelToSnakeCase(this.formHelper.toObject()))
          .then(() => {
            toast.success('Membership created')
            this.context.eventBus.dispatch('member-invalidated')
            this.onSaved()
          }, error => {
            const errors = new ErrorBag()
            Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
              AppStateStore.showAlertModal('Error', message, m => {
                m.hide()
              })
              return true
            })

            this.formErrors.addErrors(errors.getErrorList())
          })
          .catch(ex => {
            logException(ex)
            this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
          })
          .then(() => {
            AppStateStore.dismissModalSpinner()
            this.submitting = false
          })
      }

      modal.hide()
    })
  }

  private onCancelClicked = () => {
    this.props.onCancel()
  }

  private onSaved = () => {
    this.props.onSaved()
  }

  private updateEndDate = () => {
    if (this.endDateType === 'year') {
      const startDate: string = this.formState.get('startDate')

      if (startDate.length) {
        const d = moment(startDate, 'MM/DD/YYYY')
        if (d.isValid()) {
          this.formState.set('endDate', d.add(1, 'year').format('MM/DD/YYYY'))
          return
        }
      }
      this.formState.set('endDate', '')
    } else if (this.endDateType === 'never') {
      this.formState.set('endDate', '')
    }
  }

  @observable
  private isOpen = false
  @observable
  private endDateType: 'year' | 'never' | 'custom' = 'year'
  private toggle = () => {
    this.isOpen = !this.isOpen
  }

  private getPaymentMethodOptions = () => {
    const options: { value: any, text: string }[] = (this.member.current!.paymentMethods.current || []).map(pm => ({ value: String(pm.id), text: pm.optionDescription }))

    return options.concat([
      {
        value: 'personal_check',
        text: 'Personal Check',
      },
      {
        value: 'company_check',
        text: 'Company Check',
      },
      {
        value: 'money_order',
        text: 'Cashier\'s Check or Money Order',
      },
    ])
  }

  render (): React.ReactNode {
    return <LazyResourceView resource={this.member}>
      {member => <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
        <div className="form-row">
          <div className="col-md-6">
            <div className="form-group">
              {this.formHelper.renderDatePickerInput({
                name: 'startDate',
                label: 'Start Date',
                onChange: this.updateEndDate,
              })}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>End Date</label>
              <InputGroup>
                {this.formHelper.renderDatePickerInput({
                  inputProps: { disabled: this.endDateType !== 'custom' },
                  name: 'endDate',
                  prepend: <ButtonDropdown isOpen={this.isOpen} toggle={this.toggle}>
                    <DropdownToggle caret outline color="secondary"/>
                    <DropdownMenu>
                      <DropdownItem onClick={() => {
                        this.endDateType = 'year'
                        this.updateEndDate()
                      }}>One Year</DropdownItem>
                      <DropdownItem onClick={() => {
                        this.endDateType = 'never'
                        this.updateEndDate()
                      }}>Never</DropdownItem>
                      <DropdownItem onClick={() => {
                        this.endDateType = 'custom'
                      }}>Custom</DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>,
                })}
              </InputGroup>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="form-check form-checkbox">
                <label>
                  <input type="checkbox"
                         className="form-check-input"
                         name="collectMembershipFee"
                         checked={!!this.formState.get('collectMembershipFee')}
                         onChange={this.formState.onChange}
                  />
                  <span className="label-text">Collect Membership Fee</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        {this.formState.get('collectMembershipFee') &&
        <>
          <div className="form-row">
            <div className="col-12">
              <label>Payment Method</label>
              {this.formHelper.renderSelectInput({
                name: 'paymentMethod',
                options: this.paymentMethodOptions,
                append: <Button
                  type="button"
                  color="primary"
                  onClick={() => {
                    this.showAddPaymentMethodModal = true
                    this.renderAddPaymentMethodModal = true
                  }}>Add Payment Method</Button>,
                appendContent: true,
              })}
            </div>
          </div>
          {['personal_check', 'company_check', 'money_order'].indexOf(this.formState.get('paymentMethod')) > -1 &&
          <div className="form-row">
            <div className="col-md-12">
              {this.formHelper.renderTextInput({
                type: 'text',
                name: 'checkInformation',
                label: 'Check Information',
              })}
            </div>
          </div>}
          <div className="row">
            <div className="col-md-6">
              {/*<div className="callout-box">*/}
              {/*  Promo*/}
              {/*  {*/}
              {/*    // TODO: promo code*/}
              {/*  }*/}
              {/*</div>*/}
            </div>
            <div className="col-md-6">
              <div className="callout-box text-right">
                <span style={{ fontSize: 16 }}>Total Due: {transformIf(member.membershipFees.current, c => formatCurrency(c.membershipFee)) || '---'}</span>
              </div>
            </div>
          </div>
        </>}

        <hr/>

        <div className="form-buttons">
          <Button type="button" color="" onClick={() => this.onCancelClicked()}>Cancel</Button>
          <ButtonLoader type="submit" color="success" loading={this.submitting}>Start Membership</ButtonLoader>
        </div>

        {
          (this.renderAddPaymentMethodModal)
            ? <AddPaymentMethodModal
              isOpen={this.showAddPaymentMethodModal}
              toggle={() => this.showAddPaymentMethodModal = false}
              onClosed={() => this.renderAddPaymentMethodModal = false}
              onSaved={paymentMethod => {
                this.formState.set('paymentMethod', String(paymentMethod.id))
                this.showAddPaymentMethodModal = false
                this.member.current!.paymentMethods.invalidate()
              }}
              memberId={this.props.memberId}
            />
            : null
        }
      </form>
      }
    </LazyResourceView>
  }
}