import * as React from "react"
import { SyntheticEvent } from "react"
import { Prompt, Redirect } from "react-router-dom"
import { observable } from "mobx"
import { observer } from "mobx-react"
import { Button } from "reactstrap"
import { LinkContainer } from "react-router-bootstrap"
import BaseView from "../BaseView"
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import FormHelper from "../../forms/FormHelper"
import BackLink from "../../components/BackLink"
import AppStateStore from "../../stores/AppStateStore"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import ApiClient from "../../api/ApiClient"
import Util, { formatNumber, modelToSnakeCase } from "../../common/Util"
import ButtonLoader from "../../components/ButtonLoader"
import { loadAreaOptions, loadChapterOptions, loadMemberOptions, loadRegionOptions } from '../../api/AsyncHelpers'
import { toast } from 'react-toastify'

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

@observer
export default class SendNotificationView extends BaseView<Props> {
  @observable private formState = new FormState({
    targetType: 'chapter',
    chapterId: undefined,
    memberId: undefined,
    areaId: undefined,
    regionId: undefined,
    boardMembers: false,
    message: '',
    url: '',
  })
  @observable private formErrors = new ErrorBag()
  private formHelper = new FormHelper(this.formState, this.formErrors)
  @observable private submitting = false
  @observable private redirect = false

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>Send New Notification</h1>
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()
  }

  private sendNotification = async (confirm = false) => {
    this.formErrors.clearErrors()

    this.submitting = true
    AppStateStore.showModalSpinner()

    try {
      const data = {
        ...this.formHelper.toObject(),
        confirm: confirm ? '1' : '0',
      }
      const response = await ApiClient.sentNotifications.create(modelToSnakeCase(data))

      if (confirm) {
        this.redirect = true
        toast.success(`Notification sent to ${response.data.count} members`)
      } else {
        AppStateStore.showConfirmationModal('Confirm', `Are you sure you want to send this notification to ${formatNumber(response.data.count)} members?`, (result, modal) => {
          modal.hide()
          if (result) {
            this.sendNotification(true)
          }
        })
      }
    } catch (err) {
      const errors = new ErrorBag()
      Util.handleErrorResponse(err.response, errors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      })

      this.formErrors.addErrors(errors.getErrorList())
    }

    AppStateStore.dismissModalSpinner()
    this.submitting = false
  }

  private submit = async (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.sendNotification(false).then()
  }

  renderContentBody (): React.ReactNode {
    return this.redirect
      ? this.renderRedirect()
      : this.renderForm()
  }

  private renderForm = () => (
    <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      {this.formState.isDirty && <Prompt message="Are you sure you want to leave this page? You will lose any unsaved changes!"/>}

      <div className="form-row">
        <div className="col-12">
          {this.formHelper.renderSelectInput({
            name: 'targetType',
            label: 'Send To',
            options: [
              { value: 'chapter', text: 'Chapter' },
              { value: 'all', text: 'All Active Members' },
              { value: 'member', text: 'Selected Member' },
              { value: 'area', text: 'Selected Area' },
              { value: 'region', text: 'Selected Region' },
            ]
          })}
        </div>
      </div>

      {
        this.formState.get('targetType') === 'chapter'
          ? <div className="form-row">
            <div className="col-12">
              {this.formHelper.renderAsyncSelectInput({
                label: 'Chapter',
                name: 'chapterId',
                loadOptions: loadChapterOptions,
              })}
            </div>
          </div>
          : null
      }

      {
        this.formState.get('targetType') === 'area'
          ? <div className="form-row">
            <div className="col-12">
              {this.formHelper.renderAsyncSelectInput({
                label: 'Area',
                name: 'areaId',
                loadOptions: loadAreaOptions,
              })}
            </div>
          </div>
          : null
      }

      {
        this.formState.get('targetType') === 'region'
          ? <div className="form-row">
            <div className="col-12">
              {this.formHelper.renderAsyncSelectInput({
                label: 'Region',
                name: 'regionId',
                loadOptions: loadRegionOptions,
              })}
            </div>
          </div>
          : null
      }

      {
        this.formState.get('targetType') === 'member'
          ? <div className="form-row">
            <div className="col-12">
              {this.formHelper.renderAsyncSelectInput({
                label: 'Member',
                name: 'memberId',
                loadOptions: loadMemberOptions(),
              })}
            </div>
          </div>
          : null
      }

      {
        this.formState.get('targetType') !== 'member'
          ? <div className="form-row">
            <div className="col-12">
              {this.formHelper.renderCheckboxInput({
                name: 'boardMembers',
                label: 'Only send to board members'
              })}
            </div>
          </div>
          : null
      }

      <div className="form-row">
        <div className="col-12">
          {this.formHelper.renderTextAreaInput({
            name: 'message',
            label: 'Message',
          })}
        </div>
      </div>

      <div className="form-row">
        <div className="col-12">
          {this.formHelper.renderTextInput({
            name: 'url',
            type: 'url',
            label: 'URL',
          })}
        </div>
      </div>

      <div className="form-buttons">
        <LinkContainer to={route(Routes.system.notices.index)}><Button type="button" color=""><i className="fa fa-ban"/> Cancel</Button></LinkContainer>
        <ButtonLoader type="submit" color="success" loading={this.submitting}><i className="fa fa-save"/> Send Notification</ButtonLoader>
      </div>
    </form>
  )

  private renderRedirect = () => <Redirect to={route(Routes.system.sentNotifications.index)}/>
}
