import * as React from "react"
import { observer } from "mobx-react"
import Member from "../models/Member"

@observer
export default class MemberProfileImage extends React.Component<{
  profileImageUrl: string | null | undefined,
  memberName?: string,
  size: number,
}> {

  render (): React.ReactNode {
    return <img
      style={{
        width: this.props.size,
        height: this.props.size,
        objectFit: 'cover',
        objectPosition: 'top center',
        backgroundColor: '#ccc',
      }}
      src={Member.getProfileImageUrl(this.props.profileImageUrl)}
      alt={this.props.memberName}
    />
  }
}