import BaseModel from "./BaseModel"
import User from "./User"
import SupportArea from "./SupportArea"

export default class SupportAreaUser extends BaseModel {
  id: number
  title: string
  user: User

  supportArea: SupportArea

  setUser (c: {}) {
    return c ? new User(c) : null
  }

  setSupportArea (a: {}) {
    return a ? new SupportArea().init(a) : null
  }
}