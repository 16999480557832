import RequirePermission, { Permission } from "./RequirePermission"
import ButtonLoader from "./ButtonLoader"
import AuthStore from "../stores/AuthStore"
import Button from "reactstrap/lib/Button"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import Util, { publicPath, safeNull } from "../common/Util"
import MemberNotifications from "./MemberNotifications"
import AppStateStore from "../stores/AppStateStore"
import { Link } from "react-router-dom"
import * as React from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { toast } from "react-toastify"
import Config from "../common/Config"

@observer
export default class HeaderNav extends React.Component {
  @observable private processRenewalsDate = ''
  @observable private processingRenewals = false

  private processRenewals = () => {
    this.processingRenewals = true

    ApiClient.getInstance()
      .post(route(ApiRoutes.devTools.runCommand), { command: 'process-renewals', date: this.processRenewalsDate })
      .then(() => toast.success('Renewals processed'))
      .catch(err => toast.error(Util.extractErrorMessage(err.response)))
      .then(() => this.processingRenewals = false)
  }

  @observable private showMemberNotifications = false

  private logout = () => {
    AppStateStore.showConfirmationModal('Confirm', 'Are you sure you want to log out?', (res, modal) => {
      modal.hide()

      if (res) {
        AuthStore.logout()
      }
    })
    return
  }

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    const user = AuthStore.getUser()!

    return <div className="header-nav">
      <ul className="header-nav-items">
        {
          Config.ENABLE_DEV_TOOLS
            ? <RequirePermission permission={Permission.AccessDevTools}>
              {() => <>
                <li className="header-nav-item">
                  <div>
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control"
                        value={this.processRenewalsDate}
                        onChange={ev => this.processRenewalsDate = ev.target.value}
                      />
                      <div className="input-group-append">
                        <ButtonLoader color="primary" onClick={() => this.processRenewals()} loading={this.processingRenewals}>Process Renewals</ButtonLoader>
                      </div>
                    </div>
                  </div>
                </li>
              </>}
            </RequirePermission>
            : null
        }
        {AuthStore.isImpersonating && <li className="header-nav-item">
          <Button
            color="warning"
            onClick={() => {
              AuthStore.stopImpersonating()
              window.location.href = route(Routes.index)
            }}
          >Back to {safeNull(() => AuthStore.getOriginalUsername()) || 'user'}</Button>
        </li>}
        <li className="header-nav-item">
          <MemberNotifications/>
        </li>
        <li className="header-nav-item">
          <Link to={route(Routes.member.profile)} className="header-user-container">
            <div className="header-user-info">
              <span className="header-user-name">{user.name}</span>
              <span className="header-last-login">Last Login {safeNull(() => user.lastLoginAt.format('MM/DD/YYYY')) || '---'}</span>
            </div>
            <img className="header-profile-image"
                 src={user.profileImageUrl || publicPath('/img/user-profile-placeholder.png')}
                 alt={user.name}/>
          </Link>
        </li>
        <li className="header-nav-item">
          <a href="" onClick={ev => {
            ev.preventDefault()
            this.logout()
          }}>
            <i className="flaticon-logout"/>
            <span>Log Out</span>
          </a>
        </li>
      </ul>
    </div>
  }
}