import Chapter from "../models/Chapter"
import * as React from "react"
import { nl2br, normalizeUrl, safeNull } from "../common/Util"
import AddressView from "./AddressView"
import { observable } from "mobx"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { observer } from "mobx-react"
import ChapterBasicInfoForm from "./ChapterBasicInfoForm"
import RequirePermission, { Permission } from "./RequirePermission"

type Props = {
  chapter: Chapter
  onChapterInvalidated: () => void
}

@observer
export default class ChapterGeneralView extends React.Component<Props> {
  @observable
  private showModal = false
  @observable
  private editModalContent?: React.ReactNode
  @observable
  private editModalSize = 'lg'
  @observable
  private editModalTitle = 'Edit'

  private showEditModal = (title: string, component: React.ReactNode, size: string = 'lg') => {
    this.editModalTitle = title
    this.editModalContent = component
    this.showModal = true
    this.editModalSize = size
  }

  private closeEditModal = () => {
    this.showModal = false
    this.editModalContent = undefined
  }

  render (): React.ReactNode {
    const chapter = this.props.chapter

    return <>
      <h3>
        {chapter.name} <RequirePermission permission={Permission.EditChapter} context={{ chapterId: this.props.chapter.id, regionId: safeNull(() => this.props.chapter.area!.region.id) }}>
        {() =>
          <a href="" onClick={ev => {
            ev.preventDefault()
            this.showEditModal('Basic Info',
              <ChapterBasicInfoForm
                chapterId={chapter.id}
                onCancel={() => this.closeEditModal()}
                onSaved={() => {
                  this.closeEditModal()
                  this.props.onChapterInvalidated()
                }}
              />)
          }}><i className="fa fa-pencil"/></a>
        }
      </RequirePermission>
      </h3>
      {chapter.websiteUrl && <div><a href={normalizeUrl(chapter.websiteUrl)} target="_blank">{chapter.websiteUrl}</a></div>}

      {chapter.bio && <>
        <br/>
        <h5 className="text-muted">Bio</h5>
        <div>
          {nl2br(chapter.bio)}
        </div>
      </>}

      {chapter.description &&
      <>
        <br/>
        <h5 className="text-muted">Description</h5>
        <div>
          {nl2br(chapter.description)}
        </div>
      </>}

      <br/>
      <h5 className="text-muted">General Meeting Information</h5>
      <div className="row">
        <div className="col-md-4">
          <b>Day</b><br/>
          {chapter.meetingDay}
        </div>
        <div className="col-md-4">
          <b>Time</b><br/>
          {chapter.meetingTime}
        </div>
      </div>
      <br/>
      <div>
        <b>{chapter.meetingLocationDescription}</b>
        {chapter.meetingLocationAddress && <AddressView address={chapter.meetingLocationAddress} showMapLink={true}/>}
      </div>
      <br/>

      <h5 className="text-muted">Social Media Profiles</h5>
      <div className="form-row">
        <div className="col-12">
          {this.renderSocialMediaLinks(chapter.socialLinks)}
        </div>
      </div>

      <Modal
        isOpen={this.showModal}
        size={this.editModalSize}
      >
        <ModalHeader>{this.editModalTitle}</ModalHeader>
        <ModalBody>{this.editModalContent}</ModalBody>
      </Modal>
    </>
  }

  private renderSocialMediaLinks (social: {}) {
    const sites = {
      'facebook': { name: 'Facebook', icon: 'fa fa-facebook-square' },
      'twitter': { name: 'Twitter', icon: 'fa fa-twitter-square' },
      'linkedin': { name: 'Linkedin', icon: 'fa fa-linkedin-square' },
      'instagram': { name: 'Instagram', icon: 'fa fa-instagram' },
      'youtube': { name: 'YouTube', icon: 'fa fa-youtube' },
      'pinterest': { name: 'Pinterest', icon: 'fa fa-pinterest' },
      'snapchat': { name: 'Snapchat', icon: 'fa fa-snapchat' },
      'vimeo': { name: 'Vimeo', icon: 'fa fa-vimeo' },
      'alignable': { name: 'Alignable', icon: 'fa fa-home' },
    }

    const links = [
      'facebook',
      'twitter',
      'linkedin',
      'instagram',
      'youtube',
      'pinterest',
      'snapchat',
      'vimeo',
      'alignable',
    ].filter(soc => !!social[soc])

    return links.length
      ? links.map(soc => <div key={soc} className="social-link-list-item">
        <a href={normalizeUrl(social[soc])} target="_blank"><i className={sites[soc].icon}/> {sites[soc].name}</a>
      </div>)
      : <p>Social media links have not been entered</p>
  }
}
