import { observer } from 'mobx-react'
import React from 'react'
import { observable, toJS } from 'mobx'
import Util, { SHORT_DATE_FORMAT } from '../../common/Util'
import ApiClient from '../../api/ApiClient'
import LoadingPanel from '../LoadingPanel'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { route } from '../../routes/routes'
import { Routes } from '../../routes/AppRoutes'
import moment from 'moment-timezone'
import TableView, { TableViewColumn } from '../table-view/TableView'
import { ArrayTableViewAdapter } from '../table-view/ArrayTableViewAdapter'

type ChapterData = {
  id: number
  name: string
  dateFounded: string
  status: string
  statusUpdatedAt: string
}

type ChapterListData = {
  chapters: ChapterData[]
}

type Props = {
  url: string
  title: string
}

@observer
export default class ChapterList extends React.Component<Props> {
  @observable
  private chapters?: ChapterListData
  @observable
  private error?: string
  @observable
  private loading = false

  componentDidMount () {
    this.loadData().then()
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (!_.isEqual(toJS(prevProps.url), toJS(this.props.url))) {
      this.loadData().then()
    }
  }

  private loadData = async () => {
    try {
      this.error = undefined
      this.loading = true

      const response = await ApiClient.getInstance().get(this.props.url)

      this.chapters = response.data

      this.loading = false
    } catch (err) {
      this.error = Util.extractErrorMessage(err.response)
    }
  }

  get columns (): TableViewColumn[] {
    return [
      {
        accessor: 'name',
        title: 'Name',
        sortable: true,
        renderItem: (name: string, chapter: ChapterData) => <Link to={route(Routes.system.chapters.show, { id: chapter.id })}>{chapter.name}</Link>,
      },
      {
        accessor: 'dateFounded',
        sortable: true,
        title: 'Founded',
        renderItem: dateFounded => moment(dateFounded).format(SHORT_DATE_FORMAT),
      },
      {
        accessor: 'status',
        sortable: true,
        title: 'Status',
      },
      {
        accessor: 'statusUpdatedAt',
        sortable: true,
        title: 'Status Date',
        renderItem: statusUpdatedAt => moment(statusUpdatedAt).format(SHORT_DATE_FORMAT),
      },
    ]
  }

  render () {
    return <div>
      <h2>{this.props.title}</h2>
      <div>
        <LoadingPanel loading={this.loading} loaded={!!this.chapters} error={this.error}>
          {
            this.chapters
              ? <TableView
                hideItemCount={true}
                hideRowNumbers={true}
                adapter={new ArrayTableViewAdapter(this.chapters.chapters, {
                  sort: {
                    dateFounded: (chapter: ChapterData) => moment(chapter.dateFounded).unix(),
                    statusUpdatedAt: (chapter: ChapterData) => moment(chapter.statusUpdatedAt).unix(),
                  }
                })}
                columns={this.columns}
              />
              : null
          }
        </LoadingPanel>
      </div>
    </div>
  }
}
