import { Moment } from "moment-timezone"
import BaseModel from "./BaseModel"

enum ActivityType {
  Note = 'Note',
}

export default class AuditLog extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  description: string
  activityType: ActivityType | string

  getCasts () {
    return {
      'createdAt': 'datetime',
      'updatedAt': 'datetime',
    }
  }
}