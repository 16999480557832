import * as React from "react"
import Member, { VideoData } from "../models/Member"
import { observer } from "mobx-react"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { observable } from "mobx"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import AvatarEditForm from "./AvatarEditForm"
import RequirePermission, { Permission } from "./RequirePermission"
import EditBadgeColorForm from "./EditBadgeColorForm"
import AppStateStore from "../stores/AppStateStore"
import MemberVideoForm from "./MemberVideoForm"
import { route } from "../routes/routes"
import Util from "../common/Util"
import { toast } from "react-toastify"
import ErrorBag from "../common/ErrorBag"
import VideoPlayer from "./VideoPlayer"

type Props = {
  member: Member,
  onMemberInvalidated: () => void
}

@observer
export default class MemberSidebar extends React.Component<Props, never> {
  @observable
  private showModal = false
  @observable
  private editModalContent?: React.ReactNode
  @observable
  private editModalSize = 'lg'
  @observable
  private editModalTitle = 'Edit'
  @observable
  private videoModalData?: VideoData = undefined

  private showEditModal = (title: string, component: React.ReactNode, size: string = 'lg') => {
    this.editModalTitle = title
    this.editModalContent = component
    this.showModal = true
    this.editModalSize = size
  }

  private closeEditModal = () => {
    this.showModal = false
    this.editModalContent = undefined
  }

  private removeVideo = () => {
    AppStateStore.showConfirmationModal('Remove Video', 'Are you sure you want to remove your video from your profile?', (result, modal) => {
      if (result) {
        AppStateStore.showModalSpinner()

        ApiClient.getInstance().delete(route(ApiRoutes.members.removeVideoUrl, { id: this.props.member.id }))
          .then(() => {
            toast.success('Video Removed')
            this.props.onMemberInvalidated()
          })
          .catch(error => {
            const errors = new ErrorBag()
            Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
              AppStateStore.showAlertModal('Error', message, m => {
                m.hide()
              })
              return true
            })
          })
          .then(() => {
            AppStateStore.dismissModalSpinner()
          })
      }

      modal.hide()
    })
  }

  private playVideo = (videoData: VideoData) => {
    this.videoModalData = videoData
  }

  render (): React.ReactNode {
    const { member } = this.props

    return <div className="member-sidebar">
      <div className="member-sidebar-section member-sidebar-header">
        <RequirePermission permission={Permission.EditMember} context={{ memberId: this.props.member.id, chapterId: this.props.member.chapterId }} renderIfDenied={true}>
          {allowed => allowed ? (
            <a className={'pull-right'} href="" onClick={ev => {
              ev.preventDefault()
              this.showEditModal('Edit Profile Photo',
                <AvatarEditForm
                  existingImageUrl={member.profileImageUrl}
                  label={"Profile Photo"}
                  modelId={this.props.member.id}
                  updateAvatar={ApiClient.members.updateProfileImage}
                  onCancel={() => this.closeEditModal()}
                  onSaved={() => {
                    this.props.onMemberInvalidated()
                    this.closeEditModal()
                  }}/>, 'md')
            }}>
              <div className="avatar-editor">
                <div className="ae-inner">
                  <img src={member.getProfileImageUrl()}
                       style={{
                         width: 130,
                         height: 130,
                         objectFit: 'cover',
                         objectPosition: 'top center',
                         backgroundColor: '#ccc',
                       }}/>
                  <div className="ae-overlay">
                    <div className="ae-text">Edit Profile Photo</div>
                  </div>
                </div>
              </div>
            </a>
          ) : (
            <img src={member.getProfileImageUrl()}
                 style={{
                   width: 130,
                   height: 130,
                   objectFit: 'cover',
                   objectPosition: 'top center',
                   backgroundColor: '#ccc',
                 }}/>
          )
          }
        </RequirePermission>

        <br/>
        <h5>{member.fullName}</h5>
      </div>
      {
        member.videoData
          ? <div className="member-sidebar-section">
            <h6>Video</h6>
            <div className="video-thumbnail" onClick={() => this.playVideo(member.videoData!)}>
              {
                member.videoData.thumbnailUrl
                  ? <img
                    style={{ maxWidth: '100%' }}
                    src={member.videoData.thumbnailUrl}/>
                  : null
              }
              <div className="video-thumbnail-play"><i className="fa fa-play"/></div>
            </div>
          </div>
          : null
      }
      <RequirePermission permission={Permission.EditMember} context={{ memberId: this.props.member.id }}>
        {() => <>
          <div className="member-sidebar-section">
            {
              member.videoData
                ? <Button
                  color="danger"
                  onClick={() => this.removeVideo()}
                  block
                >Remove Video</Button>
                : <>
                  <p className="text-center">Add a short video to showcase your business!</p>
                  <Button
                    color="primary"
                    onClick={() => this.showEditModal('Add Video', <MemberVideoForm
                      member={member}
                      onCancel={() => this.closeEditModal()}
                      onSaved={() => {
                        this.closeEditModal()
                        this.props.onMemberInvalidated()
                      }}
                    />)}
                    block
                  >Add Video</Button>
                </>
            }
          </div>
        </>}
      </RequirePermission>
      <div className="member-sidebar-section">
        <h6>
          Badge Color <RequirePermission permission={Permission.EditMemberBadgeColor} context={{ memberId: member.id }}>
          {() =>
            <a href="" onClick={ev => {
              ev.preventDefault()
              this.showEditModal('Badge Color',
                <EditBadgeColorForm
                  member={member}
                  onCancel={() => this.closeEditModal()}
                  onSaved={() => {
                    this.closeEditModal()
                    this.props.onMemberInvalidated()
                  }}
                />)
            }}><i className="fa fa-pencil"/></a>
          }
        </RequirePermission>
        </h6>
        <div>{member.badgeColor || '---'}</div>
      </div>
      <div className="member-sidebar-section">
        <h6>Member Since</h6>
        <div>Joined {member.joinDate ? member.joinDate.format('MM/DD/YYYY') : '---'}</div>
        <div>Input {member.inputDate ? member.inputDate.format('MM/DD/YYYY') : '---'}</div>
      </div>
      <div className="member-sidebar-section">
        <h6>Membership Type</h6>
        <div>{member.membershipType}</div>
      </div>

      <Modal isOpen={this.showModal} size={this.editModalSize}>
        <ModalHeader>{this.editModalTitle}</ModalHeader>
        <ModalBody>{this.editModalContent}</ModalBody>
      </Modal>

      <Modal isOpen={!!this.videoModalData} size="lg" toggle={() => this.videoModalData = undefined}>
        <ModalHeader toggle={() => this.videoModalData = undefined}>
          {this.props.member.business ? this.props.member.business.name : 'Video Player'}
        </ModalHeader>
        <ModalBody>
          <VideoPlayer videoData={this.videoModalData!}/>
        </ModalBody>
      </Modal>
    </div>
  }
}
