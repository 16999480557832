import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import FormState from "../../../common/FormState"
import { computed, observable } from "mobx"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"
import { loadChapterOptions, loadChapterOptionsForAdmin } from "../../../api/AsyncHelpers"
import moment, { Moment } from 'moment-timezone'
import { Button } from "reactstrap"
import { BarLoader } from "react-spinners"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import { route } from "../../../routes/routes"
import Util, { modelToCamelCase, modelToSnakeCase, SHORT_DATE_FORMAT } from "../../../common/Util"
import ExportButton, { ExportButtonFetchCallback } from "../../ExportButton"
import ChapterTipOpenCsvTransformer from '../../../models/renderers/ChapterTipOpenCsvTransformer'
import _ from 'lodash'

export type ChapterTipOpenReportData = {
  recipient: {
    fullName: string
  }

  sender: {
    fullName: string
  }

  tip: {
    id: number
    type: string
    datePassed: string
    description: string
    status: string | null
    value: number | null
  }
}

type Props = {
  chapterId?: number
  filterForAdmin?: boolean
}

@observer
export default class ChapterTipOpenReport extends React.Component<Props> {
  @observable private loading = false
  @observable private error?: string
  @observable private formState = new FormState({
    chapterId: undefined,
    startDate: moment().startOf('month').format('MM/DD/YYYY'),
    endDate: moment().endOf('month').format('MM/DD/YYYY'),
    groupByMember: false,
    includeValue: false,
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable chapterData?: {id: number, name: string}
  @observable reportData?: ChapterTipOpenReportData[]
  @observable startDate?: Moment
  @observable endDate?: Moment

  @computed get filteredReportData () {
    return this.reportData
      ? this.reportData.filter(r => this.formState.get('includeValue') || !r.tip.value)
      : []
  }

  componentDidMount (): void {
    if (this.props.chapterId) {
      this.formState.set('chapterId', this.props.chapterId)
    }
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.chapterId != this.props.chapterId) {
      this.formState.set('chapterId', this.props.chapterId)
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.error = undefined
    this.loading = true

    ApiClient.getInstance()
      .post(route(ApiRoutes.reporting.chapterTipOpenReport, { chapterId: this.formState.get('chapterId') }), modelToSnakeCase(this.formState.toObject()))
      .then(response => {
        this.chapterData = modelToCamelCase(response.data.chapter) as any
        this.reportData = response.data.report_data.map((rd: any) => modelToCamelCase(rd))
        this.startDate = moment(response.data.start_date);
        this.endDate = moment(response.data.end_date)
      })
      .catch(error => this.error = Util.extractErrorMessage(error.response))
      .then(() => this.loading = false)
  }

  render () {
    return <>
      <div className="print-hide">
        <form action="" method="post" onSubmit={this.submit}>
          <div className="row align-items-end">
            {
              !this.props.chapterId
                ? <div className="col-md-4">
                  {this.formHelper.renderAsyncSelectInput({
                    label: 'Chapter',
                    name: 'chapterId',
                    loadOptions: this.props.filterForAdmin ? loadChapterOptionsForAdmin : loadChapterOptions,
                  })}
                </div>
                : null
            }
            <div className="col-md-3">
              {this.formHelper.renderDatePickerInput({
                label: 'Start Date',
                name: 'startDate',
              })}
            </div>
            <div className="col-md-3">
              {this.formHelper.renderDatePickerInput({
                label: 'End Date',
                name: 'endDate',
              })}
            </div>
            <div className="col-md-2 d-flex">
              <div className="form-group d-flex flex-column">
                <div className="flex-fill"/>
                <div>
                  <Button
                    disabled={this.loading || !this.formState.get('chapterId') || !this.formState.get('startDate').length || !this.formState.get('endDate').length}
                    style={{
                      height: 'calc(2.95rem + 2px)'
                    }}
                    type="submit"
                    color="success"
                  ><i className="fa fa-play"/> Run Report</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-end">
            <div className="col-md-4"/>
            <div className="col-md-3">
              {this.formHelper.renderCheckboxInput({
                label: 'Group by Member',
                name: 'groupByMember',
              })}
            </div>
            <div className="col-md-3">
              {this.formHelper.renderCheckboxInput({
                label: 'Include Tips With Value',
                name: 'includeValue',
              })}
            </div>
            <div className="col-md-2 d-flex">
            </div>
          </div>
        </form>
      </div>
      {
        this.loading
          ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
          : this.error
          ? <div className="alert alert-danger">{this.error}</div>
          : this.reportData
            ? (this.formState.get('groupByMember') ? this.renderGroupedReport() : this.renderReport())
            : <p>Choose report parameters above</p>
      }
    </>
  }

  private fetchPrintData: ExportButtonFetchCallback<ChapterTipOpenReportData> = (onFetched, onError, onFinished) => {
    if (this.reportData) {
      onFetched(this.reportData)
    }

    onFinished()
  }

  private renderGroupedReport() {
    const chapter = this.chapterData!
    const reportData = this.filteredReportData
    const startDate = this.startDate!
    const endDate = this.endDate!

    const groups = Object.values(_.groupBy(this.reportData, rd => rd.recipient.fullName))

    return <>
      <h1 className="text-center my-4">Open Tips Report For {chapter.name}</h1>
      <h3 className="text-center mb-4">{startDate.format('MMMM D, YYYY')} - {endDate.format('MMMM D, YYYY')}</h3>
      <div className="form-buttons mb-4 print-hide">
        <ExportButton
          fetch={this.fetchPrintData}
          transformerClass={() => new ChapterTipOpenCsvTransformer()}
        />
        <Button
          type="button"
          color="primary"
          onClick={() => window.print()}
        ><i className="fa fa-print"/> Print Report</Button>
      </div>
      {
        groups.map((group, idx) => <div key={idx} style={{pageBreakAfter: 'always'}}>
          <h3 style={{textAlign: 'center'}}>{group[0].recipient.fullName}</h3>
          <table className="chapter-tip-report-table">
            <thead>
            <tr>
              <th/>
              <th className="border-left border-top border-bottom">Date Passed</th>
              <th className="border-top border-bottom">Tipper</th>
              <th className="border-top border-bottom text-center">Tip Type</th>
              <th className="border-top border-bottom">Tip Description</th>
              <th className="border-top border-bottom text-center">Tip Status</th>
              <th className="border-top border-bottom border-right text-right">Tip Amount</th>
            </tr>
            </thead>
            <tbody>
            {
              group.map((data, index) => <tr key={data.tip.id}>
                <td>{index + 1}</td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {moment(data.tip.datePassed).format(SHORT_DATE_FORMAT)}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {`${data.sender.fullName}`}
                </td>
                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                  {`${data.tip.type}`}
                </td>
                <td>
                  {`${data.tip.description}`}
                </td>
                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                  {`${data.tip.status}`}
                </td>
                <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                  {`${data.tip.value}`}
                </td>
              </tr>)
            }
            </tbody>
          </table>
        </div>)
      }
    </>
  }

  private renderReport () {
    const chapter = this.chapterData!
    const reportData = this.filteredReportData
    const startDate = this.startDate!
    const endDate = this.endDate!

    return <>
      <h1 className="text-center my-4">Open Tips Report For {chapter.name}</h1>
      <h3 className="text-center mb-4">{startDate.format('MMMM D, YYYY')} - {endDate.format('MMMM D, YYYY')}</h3>
      <div className="form-buttons mb-4 print-hide">
        <ExportButton
          fetch={this.fetchPrintData}
          transformerClass={() => new ChapterTipOpenCsvTransformer()}
        />
        <Button
          type="button"
          color="primary"
          onClick={() => window.print()}
        ><i className="fa fa-print"/> Print Report</Button>
      </div>
      <table className="chapter-tip-report-table">
        <thead>
        <tr>
          <th/>
          <th className="border-left border-top border-bottom">Date Passed</th>
          <th className="border-top border-bottom">Tip Recipient</th>
          <th className="border-top border-bottom">Tipper</th>
          <th className="border-top border-bottom text-center">Tip Type</th>
          <th className="border-top border-bottom">Tip Description</th>
          <th className="border-top border-bottom text-center">Tip Status</th>
          <th className="border-top border-bottom border-right text-right">Tip Amount</th>
        </tr>
        </thead>
        <tbody>
        {
          reportData.map((data, index) => <tr key={data.tip.id}>
            <td>{index + 1}</td>
            <td style={{ whiteSpace: 'nowrap' }}>
              {moment(data.tip.datePassed).format(SHORT_DATE_FORMAT)}
            </td>
            <td style={{ whiteSpace: 'nowrap' }}>
              {`${data.recipient.fullName}`}
            </td>
            <td style={{ whiteSpace: 'nowrap' }}>
              {`${data.sender.fullName}`}
            </td>
            <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
              {`${data.tip.type}`}
            </td>
            <td>
              {`${data.tip.description}`}
            </td>
            <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
              {`${data.tip.status}`}
            </td>
            <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
              {`${data.tip.value}`}
            </td>
          </tr>)
        }
        </tbody>
      </table>
    </>
  }
}
