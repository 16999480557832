import { LoadOptionsCallback } from "../components/inputs/SelectInput"
import ApiClient from "./ApiClient"
import { logException } from "../common/Util"
import axios, { AxiosPromise, CancelTokenSource } from "axios"
import Config from "../common/Config"
import * as _ from 'lodash'

const asyncHelperMethod = (apiMethod: (inputValue: string, requestOptions: {}) => AxiosPromise, filterLocally: boolean = false) => {
  if (filterLocally) {
    return (() => {
      const filterOptions = (inputValue: string, options: {value: string, label: string}[]) => {
        return options.filter(o => _.lowerCase(o.value).indexOf(_.lowerCase(inputValue)) > -1)
      }

      let cachedOptions: {value: string, label: string}[] | undefined = undefined

      return (inputValue: string, callback: LoadOptionsCallback) => {
        if (cachedOptions) {
          callback(filterOptions(inputValue, cachedOptions))
        } else {
          apiMethod(inputValue, {})
            .then(response => {
              cachedOptions = response.data.options
              callback(filterOptions(inputValue, cachedOptions!))
            }, () => {
            })
            .catch(ex =>
              logException(ex)
            )
        }
      }
    })()
  } else {
    let cancelToken: CancelTokenSource | undefined

    return _.debounce((inputValue: string, callback: LoadOptionsCallback) => {
      if (cancelToken) {
        cancelToken.cancel()
        cancelToken = undefined
      }
      cancelToken = axios.CancelToken.source()

      apiMethod(inputValue, {
        cancelTokenSource: cancelToken,
      })
        .then(response => {
          callback(response.data.options)
        }, () => {
        })
        .catch(ex =>
          logException(ex)
        )
    }, Config.DEBOUNCE_TIME_MS)
  }
}

export const loadRegionOptions = asyncHelperMethod(ApiClient.getRegionOptions)
export const loadPositionOptions = asyncHelperMethod(ApiClient.getPositionOptions)
export const loadRegionOptionsForAdmin = asyncHelperMethod(ApiClient.getRegionOptionsForAdmin)
export const loadAreaOptions = asyncHelperMethod(ApiClient.getAreaOptions)
export const loadAreaOptionsForAdmin = asyncHelperMethod(ApiClient.getAreaOptionsForAdmin)
export const loadChapterOptions = asyncHelperMethod(ApiClient.getChapterOptions)
export const loadChapterOptionsFiltered = (options: {areaId?: number, state?: string}) => asyncHelperMethod(ApiClient.getChapterOptionsFiltered(options))
export const loadChapterOptionsForAdmin = asyncHelperMethod(ApiClient.getChapterOptionsForAdmin)
export const loadCategoryOptions = (chapterId?: number) => asyncHelperMethod(ApiClient.getCategoryOptions(chapterId), false)
export const loadMemberOptions = (chapterId?: number) => asyncHelperMethod(ApiClient.getMemberOptions(chapterId), false)
export const loadGuestOptions = (chapterId?: number, categoryId?: number) => asyncHelperMethod(ApiClient.getGuestOptions(chapterId, categoryId), false)
export const loadMeetingDayOptions = asyncHelperMethod(ApiClient.getMeetingDayOptions, true)
export const loadMeetingTimeOptions = asyncHelperMethod(ApiClient.getMeetingTimeOptions, true)
export const loadChapterStateOptions = asyncHelperMethod(ApiClient.getChapterStateOptions, true)
export const loadChapterStateOptionsFiltered = (options: {areaId?: number}) => asyncHelperMethod(ApiClient.getChapterStateOptionsFiltered(options), true)
export const loadRecurringMonthOptions = (date?: string) => asyncHelperMethod(ApiClient.getRecurringMonthOptions(date), false)

