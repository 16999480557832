import BaseModel from "./BaseModel"

export interface IVirtualMeet {
  chapterName: string
  webConferenceDetails: string
  timezone: string
  meetingDate: string
  meetingStartTime: string
  meetingEndTime: string
}

export default class VirtualMeet extends BaseModel implements IVirtualMeet {
  chapterName: string
  webConferenceDetails: string
  timezone: string
  meetingDate: string
  meetingStartTime: string
  meetingEndTime: string

  constructor (data = {}) {
    super();
    this.init(data);
  }
}
