import * as React from "react"
import { observer } from "mobx-react"
import LazyResourcePanel from "./LazyResourcePanel"
import { Button } from "reactstrap"
import PaymentMethod from "../models/PaymentMethod"
import ApiClient from "../api/ApiClient"
import AppStateStore from "../stores/AppStateStore"
import Util, { modelToCamelCase } from "../common/Util"
import FlipMove from "react-flip-move"
import { EventBusContext } from "../common/EventBus"
import LazyResource from "../models/LazyResource"

type Props = {
  memberId?: number
  chapterId?: number
}

@observer
export default class PaymentMethodList extends React.Component<Props, never> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  componentDidMount (): void {
    this.context.eventBus.on('member-invalidated', this.onMemberInvalidated)
    this.context.eventBus.on('chapter-invalidated', this.onMemberInvalidated)
  }

  private onMemberInvalidated = () => {
    this.paymentMethods.invalidate()
  }

  private paymentMethods = new LazyResource<PaymentMethod[]>((callback, error) => {
    if (this.props.memberId) {
      ApiClient.members.getPaymentMethods(this.props.memberId)
        .then(response => callback(response.data.payment_methods.map((p: {}) => new PaymentMethod(modelToCamelCase(p)))))
        .catch(err => error((err.response && err.response.status === 403) ? 'You are not allowed to view payment types for this member' : 'There was an error loading the payment types'))
    } else if (this.props.chapterId) {
      ApiClient.query(`
      paymentMethods {
        *
      }`, {
        where: [{ chapterId: this.props.chapterId }],
        order: [{ id: 'isDefault', desc: true }, 'id'],
      })
        .then(response => callback(response.data.paymentMethods.map((p: {}) => new PaymentMethod(p))))
        .catch(err => error((err.response && err.response.status === 403) ? 'You are not allowed to view payment types for this member' : 'There was an error loading the payment types'))
    } else {
      return
    }
  })

  private makeDefault = (paymentMethod: PaymentMethod) => {
    if (this.props.memberId) {
      AppStateStore.showModalSpinner()
      ApiClient.members.setDefaultPaymentMethod(this.props.memberId, paymentMethod.id)
        .then(response => {
          this.context.eventBus.dispatch('member-invalidated')
        }, error => {
          Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
            AppStateStore.showAlertModal('Error', message, m => {
              m.hide()
            })
            return true
          })
        })
        .then(() => {
          AppStateStore.dismissModalSpinner()
        })
    } else if (this.props.chapterId) {
      AppStateStore.showModalSpinner()
      ApiClient.chapters.setDefaultPaymentMethod(this.props.chapterId, paymentMethod.id)
        .then(response => {
          this.context.eventBus.dispatch('chapter-invalidated')
        }, error => {
          Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
            AppStateStore.showAlertModal('Error', message, m => {
              m.hide()
            })
            return true
          })
        })
        .then(() => {
          AppStateStore.dismissModalSpinner()
        })
    }
  }

  private deletePaymentMethod = (paymentMethod: PaymentMethod) => {
    AppStateStore.showConfirmationModal('Delete Payment Method', 'Are you sure you want to remove this payment method from your account?', (result, modal) => {
      if (result) {
        if (this.props.memberId) {
          AppStateStore.showModalSpinner()
          ApiClient.members.removePaymentMethod(this.props.memberId, paymentMethod.id)
            .then(response => {
              this.context.eventBus.dispatch('member-invalidated')
            }, error => {
              Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
                AppStateStore.showAlertModal('Error', message, m => {
                  m.hide()
                })
                return true
              })
            })
            .then(() => {
              AppStateStore.dismissModalSpinner()
            })
        } else if (this.props.chapterId) {
          AppStateStore.showModalSpinner()
          ApiClient.chapters.removePaymentMethod(this.props.chapterId, paymentMethod.id)
            .then(response => {
              this.context.eventBus.dispatch('chapter-invalidated')
            }, error => {
              Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
                AppStateStore.showAlertModal('Error', message, m => {
                  m.hide()
                })
                return true
              })
            })
            .then(() => {
              AppStateStore.dismissModalSpinner()
            })
        }
      }

      modal.hide()
    }, {
      type: 'danger',
    })
  }

  render (): React.ReactNode {
    return <LazyResourcePanel
      resource={this.paymentMethods}
      emptyMessage="You have not added any payment methods"
    >
      {paymentMethods => {
        return <ul className="stacked-item-list payment-method-list">
          <FlipMove>
            {paymentMethods.map(paymentMethod => <li key={paymentMethod.id}>
              <div className="payment-method-description">
                {paymentMethod.description}
                {paymentMethod.paymentMethodType === 'Card' && <div className="payment-method-expiration">Exp. {paymentMethod.expirationMonth}/{paymentMethod.expirationYear}</div>}
              </div>
              <div className="payment-method-default">
                {paymentMethod.isDefault ? <span><i className="fa fa-check-circle text-success" style={{ fontSize: '110%' }}/> Default</span> : <Button type="button" color="success" size="sm" onClick={() => this.makeDefault(paymentMethod)}>Make Default</Button>}
              </div>
              <div>
                <a className="text-danger" href="" onClick={(ev) => {
                  ev.preventDefault();
                  this.deletePaymentMethod(paymentMethod)
                }}><i className="fa fa-trash"/></a>
              </div>
            </li>)}
          </FlipMove>
        </ul>
      }}
    </LazyResourcePanel>
  }
}
