import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone/moment-timezone"
import User from './User'

export default class SentNotification extends BaseModel {
  id: number
  createdAt: Moment
  userId: number
  to: string
  message: string
  url: string | null

  user: User

  constructor (data = {}) {
    super();
    this.init(data);
  }

  setUser (u?: any) {
    return u ? new User().init(u) : null
  }

  getCasts (): {} {
    return {
      id: 'number',
      createdAt: 'datetime',
    }
  }
}
