import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import FormState from "../../../common/FormState"
import { computed, observable } from "mobx"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"
import { loadChapterOptions, loadChapterOptionsForAdmin } from "../../../api/AsyncHelpers"
import moment from 'moment-timezone'
import { Button } from "reactstrap"
import { BarLoader } from "react-spinners"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import { route } from "../../../routes/routes"
import Util, { formatCurrency, formatNumber, modelToCamelCase, modelToSnakeCase } from "../../../common/Util"
import * as _ from "lodash"

type QuarterlyTipData = {
  inside: {
    sent: number
    received: number
    receivedValue: number
  },
  outside: {
    sent: number
    received: number
    receivedValue: number
  },
  socialMedia:{
    sent: number
    received: number
    receivedValue:number
  },
  interChapter: {
    sent: number
    received: number
    receivedValue: number
  },
  batches: {
    count: number,
    receivedValue: number,
  }
}

type ReportData = {
  member: {
    id: number
    fullName: string
    status: string
    categoryName: string
  }

  tipData: QuarterlyTipData[]
}

type Props = {
  chapterId?: number
  filterForAdmin?: boolean
}

@observer
export default class QuarterlyTipReport extends React.Component<Props> {
  @observable private loading = false
  @observable private error?: string
  @observable private formState = new FormState({
    chapterId: undefined,
    year: moment().year().toString(),
  })

  @observable private formErrors = new ErrorBag()

  @computed get yearOptions () {
    return _.range(2010, moment().year() + 1)
  }

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable chapterData?: { id: number, name: string }
  @observable reportData?: ReportData[]
  @observable year?: number

  componentDidMount (): void {
    if (this.props.chapterId) {
      this.formState.set('chapterId', this.props.chapterId)
    }
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.chapterId != this.props.chapterId) {
      this.formState.set('chapterId', this.props.chapterId)
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.error = undefined
    this.loading = true

    ApiClient.getInstance()
      .post(route(ApiRoutes.reporting.quarterlyTipReport, { chapterId: this.formState.get('chapterId') }), modelToSnakeCase(this.formState.toObject()))
      .then(response => {
        this.chapterData = modelToCamelCase(response.data.chapter) as any
        this.reportData = response.data.report_data.map((rd: any) => modelToCamelCase(rd))
        this.year = Number(response.data.year)
      })
      .catch(error => this.error = Util.extractErrorMessage(error.response))
      .then(() => this.loading = false)
  }

  render () {
    return <>
      <div className="print-hide">
        <form action="" method="post" onSubmit={this.submit}>
          <div className="row align-items-end">
            {
              !this.props.chapterId
                ? <div className="col-md-4">
                  {this.formHelper.renderAsyncSelectInput({
                    label: 'Chapter',
                    name: 'chapterId',
                    loadOptions: this.props.filterForAdmin ? loadChapterOptionsForAdmin : loadChapterOptions,
                  })}
                </div>
                : null
            }
            <div className="col-md-3">
              {this.formHelper.renderSelectInput({
                label: 'Year',
                name: 'year',
                options: this.yearOptions.map(y => ({ value: y.toString(), text: y.toString() })),
              })}
            </div>
            <div className="col-md-2 d-flex">
              <div className="form-group d-flex flex-column">
                <div className="flex-fill"/>
                <div>
                  <Button
                    disabled={this.loading || !this.formState.get('chapterId') || !this.formState.get('year')}
                    style={{
                      height: 'calc(2.95rem + 2px)'
                    }}
                    type="submit"
                    color="success"
                  ><i className="fa fa-play"/> Run Report</Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {
        this.loading
          ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
          : this.error
          ? <div className="alert alert-danger">{this.error}</div>
          : this.reportData
            ? this.renderReport()
            : <p>Choose report parameters above</p>
      }
    </>
  }

  private renderMemberQuarterTips (tipData: ReportData, quarter: number) {
    const quarterTipData = tipData.tipData[quarter]

    return <>
      <td className="border-left text-center">{formatNumber(quarterTipData.inside.sent + quarterTipData.outside.sent + quarterTipData.socialMedia.sent)}</td>
      <td className="text-center">{formatNumber(quarterTipData.inside.received + quarterTipData.outside.received + quarterTipData.socialMedia.received)}</td>
      <td className="text-right">{formatCurrency(quarterTipData.inside.receivedValue + quarterTipData.outside.receivedValue + quarterTipData.batches.receivedValue + quarterTipData.socialMedia.receivedValue)}</td>
    </>
  }

  private renderTotalQuarterTips (tipData: ReportData[], quarter: number) {
    return <>
      <td className="text-center">{formatNumber(_.sumBy(tipData, t => t.tipData[quarter].inside.sent + t.tipData[quarter].outside.sent + t.tipData[quarter].socialMedia.sent))}</td>
      <td className="text-center">{formatNumber(_.sumBy(tipData, t => t.tipData[quarter].inside.received + t.tipData[quarter].outside.received + t.tipData[quarter].socialMedia.received))}</td>
      <td className="text-right">{formatCurrency(_.sumBy(tipData, t => t.tipData[quarter].inside.receivedValue + t.tipData[quarter].outside.receivedValue + t.tipData[quarter].batches.receivedValue + t.tipData[quarter].socialMedia.receivedValue))}</td>
    </>
  }

  private renderReport () {
    const chapter = this.chapterData!
    const reportData = this.reportData!
    const year = this.year || 0
    const shortYear = year % 100


    const activeMembers = reportData.filter(rd => rd.member.status === 'Active')
    const inactiveMembers = reportData.filter(rd => rd.member.status !== 'Active')

    return <>
      <h1 className="text-center my-4"> Chapter Tip Report For {chapter.name}</h1>
      <h3 className="text-center mb-4">4 Quarter Report Q1'{shortYear} - Q4'{shortYear}</h3>
      <h6 className="text-center mb-4">Total Tip is sum of Inside Tips, Outside Tips, Social Media Tips and Batch Tips values per quarter </h6>
      <div className="form-buttons mb-4 print-hide">
        <Button
          type="button"
          color="primary"
          onClick={() => window.print()}
        ><i className="fa fa-print"/> Print Report</Button>
      </div>
      <table className="chapter-tip-report-table">
        <thead>
        <tr>
          <th colSpan={3}>
          </th>
          <th colSpan={3} className="border-left border-top border-bottom">Q1</th>
          <th colSpan={3} className="border-left border-top border-bottom">Q2</th>
          <th colSpan={3} className="border-left border-top border-bottom">Q3</th>
          <th colSpan={3} className="border-left border-top border-bottom">Q4</th>
          <th colSpan={3} className="border">Total Tips</th>
        </tr>
        <tr>
          <th/>
          <th className="border-left border-top border-bottom">Member Name</th>
          <th className="border-top border-bottom text-right">Category</th>
          <th className="border-left border-top border-bottom">Pass</th>
          <th className="border-top border-bottom">Recvd</th>
          <th className="border-top border-bottom border-right text-right">$ Recvd</th>
          <th className="border-left border-top border-bottom">Pass</th>
          <th className="border-top border-bottom">Recvd</th>
          <th className="border-top border-bottom border-right text-right">$ Recvd</th>
          <th className="border-left border-top border-bottom">Pass</th>
          <th className="border-top border-bottom">Recvd</th>
          <th className="border-top border-bottom border-right text-right">$ Recvd</th>
          <th className="border-left border-top border-bottom">Pass</th>
          <th className="border-top border-bottom">Recvd</th>
          <th className="border-top border-bottom border-right text-right">$ Recvd</th>
          <th className="border-left border-top border-bottom">Tips Given</th>
          <th className="border-top border-bottom">Tips Recvd</th>
          <th className="border-top border-bottom border-right text-right">$ Received</th>
        </tr>
        </thead>
        <tbody>
        {
          activeMembers.map((memberTipData, index) => <tr key={memberTipData.member.id}>
            <td className="text-right">{index + 1}</td>
            <td style={{ whiteSpace: 'nowrap' }}>
              {memberTipData.member.fullName}
            </td>
            <td className="text-right" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{memberTipData.member.categoryName}</td>
            {this.renderMemberQuarterTips(memberTipData, 0)}
            {this.renderMemberQuarterTips(memberTipData, 1)}
            {this.renderMemberQuarterTips(memberTipData, 2)}
            {this.renderMemberQuarterTips(memberTipData, 3)}
            <td className="border-left text-center">{formatNumber(_.sumBy(memberTipData.tipData, q => q.inside.sent + q.outside.sent + q.socialMedia.sent))}</td>
            <td className="text-center">{formatNumber(_.sumBy(memberTipData.tipData, q => q.inside.received + q.outside.received + q.socialMedia.received))}</td>
            <td className="text-right border-right">{formatCurrency(_.sumBy(memberTipData.tipData, q => q.inside.receivedValue + q.outside.receivedValue + q.batches.receivedValue + q.socialMedia.receivedValue
))}</td>
          </tr>)
        }
        <tr className="border-top">
          <td/>
          <td colSpan={2}>Total Active Member Tips</td>
          {this.renderTotalQuarterTips(activeMembers, 0)}
          {this.renderTotalQuarterTips(activeMembers, 1)}
          {this.renderTotalQuarterTips(activeMembers, 2)}
          {this.renderTotalQuarterTips(activeMembers, 3)}
          <td className="text-center">{formatNumber(_.sumBy(activeMembers, m => _.sumBy(m.tipData, t => t.outside.sent) + _.sumBy(m.tipData, t => t.inside.sent) + _.sumBy(m.tipData, t => t.socialMedia.sent)))}</td>
          <td className="text-center">{formatNumber(_.sumBy(activeMembers, m => _.sumBy(m.tipData, t => t.outside.received) + _.sumBy(m.tipData, t => t.inside.received) + _.sumBy(m.tipData, t => t.socialMedia.received)))}</td>
          <td className="text-right">{formatCurrency(_.sumBy(activeMembers, m => _.sumBy(m.tipData, t => t.outside.receivedValue) + _.sumBy(m.tipData, t => t.inside.receivedValue) + _.sumBy(m.tipData, t => t.batches.receivedValue) + _.sumBy(m.tipData, t =>t.socialMedia.receivedValue)))}</td>
        </tr>
        {
          inactiveMembers.length
            ? <>
              <tr>
                <td colSpan={18}>&nbsp;</td>
              </tr>
              <tr className="border-bottom">
                <td colSpan={18}>Non-Active Members</td>
              </tr>
              {
                inactiveMembers.map((memberTipData, index) => <tr key={memberTipData.member.id}>
                  <td className="text-right">{index + 1}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {memberTipData.member.fullName}
                  </td>
                  <td className="text-right" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{memberTipData.member.categoryName}</td>
                  {this.renderMemberQuarterTips(memberTipData, 0)}
                  {this.renderMemberQuarterTips(memberTipData, 1)}
                  {this.renderMemberQuarterTips(memberTipData, 2)}
                  {this.renderMemberQuarterTips(memberTipData, 3)}
                  <td className="border-left text-center">{formatNumber(_.sumBy(memberTipData.tipData, q => q.inside.sent + q.outside.sent + q.socialMedia.sent))}</td>
                  <td className="text-center">{formatNumber(_.sumBy(memberTipData.tipData, q => q.inside.received + q.outside.received + q.socialMedia.received))}</td>
                  <td className="text-right border-right">{formatCurrency(_.sumBy(memberTipData.tipData, q => q.inside.receivedValue + q.outside.receivedValue + q.batches.receivedValue + q.socialMedia.receivedValue))}</td>
                </tr>)
              }
              <tr className="border-top">
                <td/>
                <td colSpan={2}>Total Inactive Member Tips</td>
                {this.renderTotalQuarterTips(inactiveMembers, 0)}
                {this.renderTotalQuarterTips(inactiveMembers, 1)}
                {this.renderTotalQuarterTips(inactiveMembers, 2)}
                {this.renderTotalQuarterTips(inactiveMembers, 3)}
                <td className="text-center">{formatNumber(_.sumBy(inactiveMembers, m => _.sumBy(m.tipData, t => t.outside.sent) + _.sumBy(m.tipData, t => t.inside.sent) + _.sumBy(m.tipData, t => t.socialMedia.sent)))}</td>
                <td className="text-center">{formatNumber(_.sumBy(inactiveMembers, m => _.sumBy(m.tipData, t => t.outside.received) + _.sumBy(m.tipData, t => t.inside.received) + _.sumBy(m.tipData, t => t.socialMedia.received)))}</td>
                <td className="text-right">{formatCurrency(_.sumBy(inactiveMembers, m => _.sumBy(m.tipData, t => t.outside.receivedValue) + _.sumBy(m.tipData, t => t.inside.receivedValue) + _.sumBy(m.tipData, t => t.batches.receivedValue) + _.sumBy(m.tipData, t => t.socialMedia.receivedValue)))}</td>
              </tr>
            </>
            : null
        }
        <tr>
          <td colSpan={18}>&nbsp;</td>
        </tr>
        <tr className="border-top border-bottom">
          <td/>
          <td colSpan={2}>Total Tips for Chapter</td>
          {this.renderTotalQuarterTips(reportData, 0)}
          {this.renderTotalQuarterTips(reportData, 1)}
          {this.renderTotalQuarterTips(reportData, 2)}
          {this.renderTotalQuarterTips(reportData, 3)}
          <td className="text-center">{formatNumber(_.sumBy(reportData, m => _.sumBy(m.tipData, t => t.outside.sent) + _.sumBy(m.tipData, t => t.inside.sent) + _.sumBy(m.tipData, t => t.socialMedia.sent)))}</td>
          <td className="text-center">{formatNumber(_.sumBy(reportData, m => _.sumBy(m.tipData, t => t.outside.received) + _.sumBy(m.tipData, t => t.inside.received) + _.sumBy(m.tipData, t => t.socialMedia.received)))}</td>
          <td className="text-right border-right">{formatCurrency(_.sumBy(reportData, m => _.sumBy(m.tipData, t => t.outside.receivedValue) + _.sumBy(m.tipData, t => t.inside.receivedValue) + _.sumBy(m.tipData, t => t.batches.receivedValue) + _.sumBy(m.tipData, t => t.socialMedia.receivedValue)))}</td>
        </tr>
        </tbody>
      </table>
    </>
  }
}
