import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import FormState from "../../../common/FormState"
import { computed, observable } from "mobx"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"
import { loadChapterOptions, loadChapterOptionsForAdmin } from "../../../api/AsyncHelpers"
import { Button } from "reactstrap"
import { BarLoader } from "react-spinners"
import ApiClient from "../../../api/ApiClient"
import Util, { normalizeUrl } from "../../../common/Util"
import Chapter from "../../../models/Chapter"
import AddressView from "../../AddressView"
import * as _ from "lodash"
import { RouteComponentProps } from "react-router"

type Props = {
  chapterId?: number
  filterForAdmin?: boolean
  match?: {
    params: {
      chapter?: number
    }
  }
}

@observer
export default class MemberRosterReport extends React.Component<Props> {
  @observable private loading = false
  @observable private error?: string
  @observable private formState = new FormState({
    chapterId: undefined,
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable chapter?: Chapter

  @computed get forceChapterId() {
    return (this.props.match ? this.props.match.params.chapter : undefined) || this.props.chapterId
  }

  componentDidMount (): void {
    if (this.forceChapterId) {
      this.formState.set('chapterId', this.forceChapterId)
      this.submit()
    }
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.chapterId != this.props.chapterId) {
      this.formState.set('chapterId', this.forceChapterId)
      this.submit()
    }
  }

  private submit = (ev?: SyntheticEvent) => {
    ev && ev.preventDefault()

    this.error = undefined
    this.loading = true

    ApiClient.query(`
    chapter {
      *
      meetingLocationAddress {
        *
      }
      activeMembers {
        *
        category {
          *
        }
        business {
          *
          address {
            *
          }
        }
        positions {
          *
        }
      }
    }`, {
        where: [
          { id: this.formState.get('chapterId') },
        ],
      }
    )
      .then(response => {
        this.chapter = new Chapter().init(response.data.chapter)
      })
      .catch(error => this.error = Util.extractErrorMessage(error.response))
      .then(() => this.loading = false)
  }

  render () {
    return <>
      <div className="print-hide">
        <form action="" method="post" onSubmit={this.submit}>
          {
            !this.forceChapterId
              ? <div className="row align-items-end">
                <div className="col-md-4">
                  {this.formHelper.renderAsyncSelectInput({
                    label: 'Chapter',
                    name: 'chapterId',
                    loadOptions: this.props.filterForAdmin ? loadChapterOptionsForAdmin : loadChapterOptions,
                  })}
                </div>
                <div className="col-md-2 d-flex">
                  <div className="form-group d-flex flex-column">
                    <div className="flex-fill"/>
                    <div>
                      <Button
                        disabled={this.loading || !this.formState.get('chapterId')}
                        style={{
                          height: 'calc(2.95rem + 2px)'
                        }}
                        type="submit"
                        color="success"
                      ><i className="fa fa-play"/> Run Report</Button>
                    </div>
                  </div>
                </div>
              </div>
              : null
          }
        </form>
      </div>
      {
        this.loading
          ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
          : this.error
          ? <div className="alert alert-danger">{this.error}</div>
          : this.chapter
            ? this.renderReport(this.chapter)
            : <p>Choose report parameters above</p>
      }
    </>
  }

  private renderReport (chapter: Chapter) {
    const members = _.sortBy(chapter.activeMembers, ['lastName', 'firstName'])
    return <>
      <div>
        <h2 className="text-center"> {chapter.name}</h2>
        <h5 className="text-center">
          {
            chapter.meetingLocationDescription && <div>{chapter.meetingLocationDescription}</div>
          }
          {
            chapter.meetingLocationAddress && <AddressView address={chapter.meetingLocationAddress}/>
          }
        </h5>

        <div className="form-buttons mb-4 print-hide">
          <Button
            type="button"
            color="primary"
            onClick={() => window.print()}
          ><i className="fa fa-print"/> Print Report</Button>
        </div>
      </div>

      <div>
        {
          members.map((member, idx) => <>
            {
              idx > 0 && idx % 15 === 0
                ? <div style={{ pageBreakBefore: 'always' }}>&nbsp;</div>
                : null
            }
            <div style={{
              display: 'inline-block',
              margin: 10,
              marginTop: 30,
              marginBottom: 30,
              padding: 0,
              width: 280,
              minHeight: 150,
              position: 'relative',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}>
              <div>{member.fullName}</div>
              {!!member.positions.length && <div>{member.positions.map(p => p.name).join(', ')}</div>}
              {member.category && <div>{member.category.name}</div>}
              {
                member.business
                  ? <>
                    <div>{member.business.name}</div>
                    {member.business.address && <div><AddressView address={member.business.address}/></div>}
                  </>
                  : null
              }
              {member.phoneNumber && <div>{member.phoneNumber}</div>}
              {member.emailAddress && <div>{member.emailAddress}</div>}
              {
                member.business
                  ? <>
                    {member.business.websiteUrl && <div><a target="_blank" href={normalizeUrl(member.business.websiteUrl)}>{member.business.websiteUrl}</a></div>}
                  </>
                  : null
              }
            </div>
          </>)
        }
      </div>
    </>
  }
}
