import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import App from './App'
import * as Sentry from '@sentry/browser'
import Config from "./common/Config"
import { setDefaultTimezone } from "./common/Util"

if (Config.SENTRY_DSN && !Config.SENTRY_DISABLE_CAPTURE) {
  Sentry.init({
    dsn: Config.SENTRY_DSN,
  })
}

setDefaultTimezone('America/Phoenix')

ReactDOM.render(
  <App/>,
  document.getElementById('root') as HTMLElement,
)

// TODO: figure out how to invalidate the cache if a new version is available
// registerServiceWorker()
