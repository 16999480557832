import * as React from "react"
import { observer } from "mobx-react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { DocumentLibraryDescriptor } from "../../models/DocumentLibraryItem"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import { route } from "../../routes/routes"
import { observable, toJS } from "mobx"
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import FormHelper from "../../forms/FormHelper"
import Util, { modelToSnakeCase } from "../../common/Util"
import AppStateStore from "../../stores/AppStateStore"
import ButtonLoader from "../ButtonLoader"

type AddVideoModalProps = {
  toggle: () => void
  isOpen: boolean
  libraryDescriptor: DocumentLibraryDescriptor
  onVideoAdded: () => void
  parentDocumentLibraryItemId?: string
  availableTags: string[]
}

@observer
export default class AddVideoModal extends React.Component<AddVideoModalProps> {
  private initialFormState = {
    name: '',
    videoUrl: '',
  }

  @observable private formState = new FormState(this.initialFormState)
  @observable private formErrors = new ErrorBag()
  @observable private submitting = false
  @observable private tags: string[] = []
  @observable private newTag = ''

  private inputRef = React.createRef<HTMLInputElement>()

  private toggle = () => {
    this.formErrors.clearErrors()
    this.formState.setAll(this.initialFormState)

    if (!this.submitting) {
      this.props.toggle()
    }
  }

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private submit = () => {
    this.formErrors.clearErrors()
    this.submitting = true

    ApiClient.getInstance().post(route(ApiRoutes.documentLibrary.addVideo),
      modelToSnakeCase({
        ...this.formState.toObject(),
        ...this.props.libraryDescriptor,
        tags: toJS(this.tags),
        parentId: this.props.parentDocumentLibraryItemId,
      }))
      .then(() => {
        this.formState.setAll(this.initialFormState)
        this.props.onVideoAdded()
      })
      .catch(error => {
        Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      })
      .then(() => this.submitting = false)
  }

  addTag = () => {
    const tag = this.newTag.trim()

    if (tag.length) {
      if (!this.props.availableTags.find(t => t.toLowerCase() === tag.toLowerCase())) {
        this.props.availableTags.push(tag)
      }

      if (!this.tags.find(t => t.toLowerCase() === tag.toLowerCase())) {
        this.tags.push(tag)
      }

      this.newTag = ''
    }
  }

  toggleTag = (tag: string, checked: boolean) => {
    if (checked) {
      if (!this.tags.filter(t => t.toLowerCase() === tag.toLowerCase()).length) {
        this.tags.push(tag)
      }
    } else {
      this.tags = this.tags.filter(t => t.toLowerCase() !== tag.toLowerCase())
    }
  }

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <Modal
      isOpen={this.props.isOpen}
      toggle={this.toggle}
      onOpened={() => {
        this.inputRef.current && this.inputRef.current.focus()
      }}
    >
      <ModalHeader toggle={this.toggle}>
        Add Video
      </ModalHeader>
      <ModalBody>
        <form onSubmit={ev => {
          ev.preventDefault()
          this.submit()
        }}>
          <p>
            You must first save your video to YouTube or Vimeo and then enter the
            URL to your YouTube video below. You can find this URL using the share link on YouTube. If you
            need more assistance in saving a video to YouTube, you can watch one of these
            videos…https://www.youtube.com/results?search_query=upload+a+video+on+youtube
          </p>
          <p>
            The URL will look like this: <code>https://youtu.be/abc123</code>
          </p>
          <div className="form-row">
            <div className="col-sm-12">
              {
                this.formHelper.renderTextInput({
                  ref: this.inputRef,
                  label: 'Video Name',
                  name: 'name',
                  disabled: this.submitting,
                })
              }
            </div>
          </div>
          <div className="form-row">
            <div className="col-sm-12">
              {this.formHelper.renderTextInput({
                name: 'videoUrl',
                type: 'text',
                label: 'YouTube Video URL',
                placeholder: 'https://youtu.be/videoid',
                disabled: this.submitting,
              })}
            </div>
          </div>
          {
            this.props.availableTags.map(availableTag => <div key={availableTag} className="form-checkbox">
              <label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={!!this.tags.find(t => t.toLowerCase() === availableTag.toLowerCase())}
                  onChange={ev => (this.toggleTag(availableTag, ev.target.checked))}
                />
                <span className="label-text">{availableTag}</span>
              </label>
            </div>)
          }
          <div className="input-group">
            <input
              type={'text'}
              className={'form-control'}
              value={this.newTag}
              onChange={e => this.newTag = e.target.value}
            />
            <div className={'input-group-append'}>
              <Button color={'primary'} onClick={this.addTag}>Add Tag</Button>
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="secondary"
          onClick={this.toggle}
          disabled={this.submitting}
        >Cancel</Button>
        <ButtonLoader
          type="button"
          color="primary"
          onClick={() => this.submit()}
          loading={this.submitting}
        >Add Video</ButtonLoader>
      </ModalFooter>
    </Modal>
  }
}
