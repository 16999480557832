import Member from "../models/Member"
import * as React from "react"
import { SyntheticEvent } from "react"
import Config from "../common/Config"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { observer } from "mobx-react"
import { Button } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import Util, { logException, modelToSnakeCase } from "../common/Util"
import { toast } from "react-toastify"
import { can, Permission } from "./RequirePermission"
import { EventBusContext } from "../common/EventBus"
import { ManageNewsletterSubscription } from "./MemberNotificationSettings"

type Props = {
  member: Member
  onCancel: () => void
  onSaved: () => void
}

type EditFormState = {
  prefix?: string
  firstName: string
  lastName: string
  preferredName: string
  suffix?: string,
  gender?: string
  birthDate?: string
  websiteUrl: string
  phoneNumber: string
  emailAddress: string
}

@observer
export default class MemberBasicInfoForm extends React.Component<Props, never> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>  

@observable
  private formState = new FormState<EditFormState>({
    prefix: this.props.member.prefix || undefined,
    firstName: this.props.member.firstName || '',
    lastName: this.props.member.lastName || '',
    preferredName: this.props.member.preferredName || '',
    phoneNumber: this.props.member.phoneNumber || '',
    emailAddress: this.props.member.emailAddress || '',
    suffix: this.props.member.suffix || '',
    gender: this.props.member.gender || undefined,
    birthDate: this.props.member.birthDate ? this.props.member.birthDate.format('MM/DD/YYYY') : '',
    websiteUrl: this.props.member.websiteUrl || '',
  })

  @observable
  private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable
  private submitting = false

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.members.update(this.props.member.id, modelToSnakeCase(this.formHelper.toObject()))
      .then(() => {
        toast.success('Member Info Updated')
        this.onSaved()
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        let subscribe = new ManageNewsletterSubscription({ memberId: this.props.member.id, emailId: this.formHelper.toObject().emailAddress});
        subscribe.callSubscribeToNewsletter()
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private onCancelClicked = () => {
    this.props.onCancel()
  }

  private onSaved = () => {
    this.props.onSaved()
  }

  render (): React.ReactNode {
    return <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      <div className="form-row">
        <div className="col-sm-2">
          {this.formHelper.renderSelectInput({
            placeholder: '(choose)',
            name: 'prefix',
            label: 'Prefix',
            options: Config.NAME_PREFIX_OPTIONS.map(o => ({ value: o, text: o })),
          })}
        </div>
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'firstName',
            type: 'text',
            label: 'First Name',
            disabled: !can(Permission.ChangeMemberName, { memberId: this.props.member.id }),
          })}
        </div>
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'lastName',
            type: 'text',
            label: 'Last Name',
            disabled: !can(Permission.ChangeMemberName, { memberId: this.props.member.id }),
          })}
        </div>
        <div className="col-sm-2">
          {this.formHelper.renderSelectInput({
            placeholder: '(choose)',
            name: 'suffix',
            label: 'Suffix',
            options: Config.NAME_SUFFIX_OPTIONS.map(o => ({ value: o, text: o })),
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'preferredName',
            type: 'text',
            label: 'Preferred Name',
            disabled: !can(Permission.ChangeMemberName, { memberId: this.props.member.id }),
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          {this.formHelper.renderSelectInput({
            placeholder: '(choose)',
            name: 'gender',
            label: 'Gender',
            options: Config.GENDER_OPTIONS.map(o => ({ value: o, text: o })),
          })}
        </div>
        <div className="col-sm-6">
          {this.formHelper.renderDatePickerInput({
            name: 'birthDate',
            label: 'Birth Date',
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'phoneNumber',
            type: 'text',
            label: 'Contact Phone (Cell)',
          })}
        </div>
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'emailAddress',
            type: 'text',
            label: 'Contact Email',
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'websiteUrl',
            type: 'text',
            label: 'Website',
          })}
        </div>
      </div>
      <div className="form-buttons">
        <Button type="button" color="" onClick={() => this.onCancelClicked()}>Cancel</Button>
        <ButtonLoader type="submit" color="success" loading={this.submitting}>Save Changes</ButtonLoader>
      </div>
    </form>
  }
}
