import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import FormState from "../../../common/FormState"
import { observable } from "mobx"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"
import moment from 'moment-timezone'
import { Button } from "reactstrap"
import { BarLoader } from "react-spinners"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import Util, { formatCurrency, modelToCamelCase, modelToSnakeCase, safeNull } from "../../../common/Util"
import { Moment } from "moment"
import { route } from "../../../routes/routes"
import _ from 'lodash';
import ExportButton, { ExportButtonFetchCallback } from "../../ExportButton"
import DailyRevenueReportCsvTransformer, { DailyRevenueReportCsvRow } from "../../../models/renderers/DailyRevenueReportCsvTransformer"

type AreaData = {
  name?: string
  transactions: TransactionData[]
}

type TransactionData = {
  area?: {
    id: number
    name: string
  }

  chapter?: {
    id: number
    name: string
  }

  invoice: {
    id: number
    total: string
  }

  member?: {
    id: number
    fullName: string
    joinDate: string
  }

  transaction: {
    id: number
    createdAt: string
    provider: string
    externalTransactionId: string
    amount: string
  }

  paymentType: string
  note: string
}

type ReportData = {
  applications: AreaData[]
  renewals: AreaData[]
  misc: AreaData[]
  membershipChanges: AreaData[]

}

@observer
export default class DailyRevenueReport extends React.Component {
  @observable private loading = false
  @observable private error?: string
  @observable private formState = new FormState({
    startDate: moment().startOf('month').format('MM/DD/YYYY'),
    endDate: moment().endOf('month').format('MM/DD/YYYY'),
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable reportData?: ReportData

  @observable startDate?: Moment
  @observable endDate?: Moment

  private buildReportData = (transactions: TransactionData[]) => {
    const areaGroups = _.groupBy(transactions, function (t: TransactionData) {
      return t.area ? t.area.id : 0
    })

    const areaData: AreaData[] = []

    for (let areaId in areaGroups) {
      const transactions = areaGroups[areaId]
      areaData.push({
        name: transactions[0].area ? transactions[0].area.name : undefined,
        transactions: transactions,
      })
    }

    return _.sortBy(areaData, ad => ad.name)
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.error = undefined
    this.loading = true

    ApiClient.getInstance()
      .post(route(ApiRoutes.reporting.dailyRevenueReport), modelToSnakeCase(this.formState.toObject()))
      .then(response => {
        const data: any = modelToCamelCase(response.data.report_data)

        const applications = this.buildReportData(data.applications)
        const renewals = this.buildReportData(data.renewals)
        const misc = this.buildReportData(data.misc)
        const membershipChanges = this.buildReportData(data.membershipChanges)

        this.reportData = {
          applications,
          renewals,
          misc,
          membershipChanges
        }

        this.startDate = moment(response.data.start_date);
        this.endDate = moment(response.data.end_date)
      })
      .catch(error => this.error = Util.extractErrorMessage(error.response))
      .then(() => this.loading = false)
  }

  render () {
    return <>
      <div className="print-hide">
        <form action="" method="post" onSubmit={this.submit}>
          <div className="row align-items-end">
            <div className="col-md-3">
              {this.formHelper.renderDatePickerInput({
                label: 'Start Date',
                name: 'startDate',
              })}
            </div>
            <div className="col-md-3">
              {this.formHelper.renderDatePickerInput({
                label: 'End Date',
                name: 'endDate',
              })}
            </div>
            <div className="col-md-6 d-flex">
              <div className="form-group d-flex flex-column">
                <div className="flex-fill"/>
                <div>
                  <Button
                    disabled={this.loading || !this.formState.get('startDate').length || !this.formState.get('endDate').length}
                    style={{
                      height: 'calc(2.95rem + 2px)'
                    }}
                    type="submit"
                    color="success"
                  ><i className="fa fa-play"/> Run Report</Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {
        this.loading
          ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
          : this.error
          ? <div className="alert alert-danger">{this.error}</div>
          : this.reportData
            ? this.renderReport()
            : <p>Choose report parameters above</p>
      }
    </>
  }

  private renderSection = (name: string, areaDatas: AreaData[]) => {
    return <div>
      <h4 className="text-center">{name}</h4>
      {
        areaDatas.length
          ? <table className="daily-revenue-report-table">
            {areaDatas.map((areaData, idx) => <>
              <tbody key={idx}>
              <tr>
                <th colSpan={8} className="border-left border-top border-bottom border-right text-left">
                  Region: {areaData.name || 'Unknown Region'}
                </th>
              </tr>
              <tr>
                <th className="text-left border-left border-top border-right border-bottom">Chapter Name</th>
                <th className="text-left border-left border-top border-right border-bottom">Contact</th>
                <th className="text-left border-left border-top border-right border-bottom">Join Date</th>
                <th className="text-right border-left border-top border-right border-bottom">Amount Due</th>
                <th className="text-right border-left border-top border-right border-bottom">Amount Paid</th>
                <th className="text-left border-left border-top border-right border-bottom">Date Paid</th>
                <th className="text-left border-left border-top border-right border-bottom">Payment Type</th>
                <th className="text-left border-left border-top border-right border-bottom">Note</th>
              </tr>
              {
                areaData.transactions.map(transaction => <tr key={transaction.transaction.id}>
                  <td className="text-left border-left border-bottom border-right">
                    {
                      safeNull(() => transaction.chapter!.name) || '---'
                    }
                  </td>
                  <td className="text-left border-bottom border-right">
                    {
                      safeNull(() => transaction.member!.fullName) || '---'
                    }
                  </td>
                  <td className="text-left border-bottom border-right">
                    {
                      safeNull(() => transaction.member!.joinDate ? moment(transaction.member!.joinDate).format('MM/DD/YYYY') : undefined) || '---'
                    }
                  </td>
                  <td className="text-right border-bottom border-right">
                    {
                      formatCurrency(Number(transaction.invoice.total))
                    }
                  </td>
                  <td className="text-right border-bottom border-right">
                    {
                      formatCurrency(Number(transaction.transaction.amount))
                    }
                  </td>
                  <td className="text-left border-bottom border-right">
                    {
                      moment(transaction.transaction.createdAt).format('MM/DD/YYYY')
                    }
                  </td>
                  <td className="text-left border-bottom border-right">
                    {
                      transaction.paymentType
                    }
                  </td>
                  <td className="text-left border-bottom border-right">
                    {
                      transaction.note
                    }
                  </td>
                </tr>)
              }
              <tr>
                <td className="text-left border-left border-bottom border-right"/>
                <td className="text-left border-bottom border-right"/>
                <td className="text-left border-bottom border-right"/>
                <td className="text-right border-bottom border-right" style={{ verticalAlign: 'top' }}>
                  S: {formatCurrency(_.sumBy(areaData.transactions, t => Number(t.invoice.total)))}
                </td>
                <td className="text-right border-bottom border-right" style={{ verticalAlign: 'top' }}>
                  S: {formatCurrency(_.sumBy(areaData.transactions, t => Number(t.transaction.amount)))}<br/>
                  C: {areaData.transactions.length}
                </td>
                <td className="text-left border-bottom border-right"/>
                <td className="text-left border-bottom border-right"/>
                <td className="text-left border-bottom border-right"/>
              </tr>
              </tbody>
            </>)}
          </table>
          : <p className="text-center">No transactions</p>
      }
    </div>
  }

  private buildCsvData: (reportData: ReportData) => ExportButtonFetchCallback<DailyRevenueReportCsvRow> = (reportData: ReportData) => {
    return (onFetched, onError, onFinished) => {
      const rows = _.flatMap(reportData.applications, (rr: AreaData) => {
        return rr.transactions.map(t => {
          return {
            type: 'Application',
            ...t,
          }
        })
      })
        .concat(
          _.flatMap(reportData.renewals, (rr: AreaData) => {
            return rr.transactions.map(t => {
              return {
                type: 'Renewals',
                ...t,
              }
            })
          })
        )
        .concat(
          _.flatMap(reportData.misc, (rr: AreaData) => {
            return rr.transactions.map(t => {
              return {
                type: 'Misc',
                ...t,
              }
            })
          })
        )
        .concat(
          _.flatMap(reportData.membershipChanges, (rr: AreaData) => {
            return rr.transactions.map(t => {
              return {
                type: 'Membership Charges',
                ...t,
              }
            })
          })
        )

      onFetched(rows)
      onFinished()
    }
  }

  private renderReport () {
    const reportData = this.reportData!
    const startDate = this.startDate!
    const endDate = this.endDate!

    return <>
      <h1 className="text-center my-4"> Daily Revenue Report</h1>
      <h3 className="text-center mb-4">{startDate.format('MMMM D, YYYY')} - {endDate.format('MMMM D, YYYY')}</h3>
      <div className="form-buttons mb-4 print-hide">
        <ExportButton
          fetch={this.buildCsvData(reportData)}
          transformerClass={() => new DailyRevenueReportCsvTransformer()}
        />
        <Button
          type="button"
          color="primary"
          onClick={() => window.print()}
        ><i className="fa fa-print"/> Print Report</Button>
      </div>
      {this.renderSection('Applications', reportData.applications)}
      <div className="mb-4"/>
      <hr/>
      <div className="mt-4"/>
      {this.renderSection('Renewals', reportData.renewals)}
      <div className="mb-4"/>
      <hr/>
      <div className="mt-4"/>
      {this.renderSection('Misc', reportData.misc)}
      <div className="mb-4"/>
      <hr/>
      {this.renderSection('Membership Changes', reportData.membershipChanges)}
      <div>
      </div>
    </>
  }
}
