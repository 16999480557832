import Member from "../models/Member"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { observer } from "mobx-react"
import { Button } from "reactstrap"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import Util, { logException, modelToSnakeCase } from "../common/Util"
import * as _ from "lodash"
import ButtonLoader from "./ButtonLoader"
import { toast } from "react-toastify"


type Props = {
  member: Member,
  onFinished: (dirty: boolean) => void
}

type EditFormState = {
  bio: string
}

@observer
export default class MemberBiographyForm extends React.Component<Props, never> {
  @observable
  private formState = new FormState<EditFormState>({
    bio: _.get(this.props.member, 'bio', '')
  })

  private isDirty = false

  @observable
  private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable
  private submitting = false

  private finish = () => {
    this.props.onFinished(this.isDirty)
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.members.updateBiography(this.props.member.id, modelToSnakeCase(this.formHelper.toObject()))
      .then(response => {
        toast.success('Biography Updated')
        this.isDirty = true
        this.finish()
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  render (): React.ReactNode {
    return <>
      <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
        <div className="form-row">
          <div className="col-sm-12">
            {this.formHelper.renderTextAreaInput({
              name: 'bio',
              placeholder: 'Enter Biography..',
            })}
          </div>
        </div>

        <div className="form-buttons">
          <Button type="button" color="" onClick={() => this.finish()}>Cancel</Button>
          <ButtonLoader type="submit" color="success" loading={this.submitting}>Save Changes</ButtonLoader>
        </div>
      </form>
    </>
  }
}