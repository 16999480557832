import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import Member from "../models/Member"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import AppStateStore from "../stores/AppStateStore"
import { Button } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import Util, { logException, modelToCamelCase, modelToSnakeCase } from "../common/Util"
import { toast } from "react-toastify"
import { route } from "../routes/routes"
import Config from "../common/Config"
import _ from 'lodash';

type Props = {
  member: Member
  onCancel: () => void
  onSaved: () => void
}

type EditFormState = {
  badgeColor: string
  createShippingRequest: boolean
  shippingOption?: string
  name: string
  businessName: string
  address: string
  address2: string
  city: string
  state: string
  zipCode: string
  country: string
  shippingNotes: string
}

type ShippingOption = {
  value: string
  text: string

  address?: {
    name: string
    businessName: string
    address: string
    address2: string
    city: string
    state: string
    zipCode: string
    country: string
  }
}

@observer
export default class EditBadgeColorForm extends React.Component<Props> {
  @observable
  private formState = new FormState<EditFormState>({
    badgeColor: this.props.member.badgeColor,
    createShippingRequest: true,
    shippingOption: undefined,
    name: '',
    businessName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    shippingNotes: this.props.member.shippingNotes || '',
  })

  @observable
  private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable
  private submitting = false

  @observable private shippingOptions: ShippingOption[] = [
    {
      value: 'other',
      text: 'Other',
    },
  ]

  @observable selectedShippingOption?: string = 'other'

  componentDidMount (): void {
    ApiClient.getInstance().get(route(ApiRoutes.members.shippingRecipients, { id: this.props.member.id }))
      .then(response => {
        const recipients: ShippingOption[] = response.data.shipping_recipients.map((r: any) => modelToCamelCase(r))

        this.shippingOptions = recipients.concat({
          value: 'other',
          text: 'Other',
        })
      })
  }

  private handleShippingOptionChanged = (value: string) => {
    const option = _.find(this.shippingOptions, o => o.value === value)

    if (option && option.address) {
      this.formState.setAll(option.address)
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.getInstance().post(route(ApiRoutes.members.updateBadgeColor, { id: this.props.member.id }), modelToSnakeCase(this.formHelper.toObject()))
      .then(() => {
        toast.success('Badge Color Updated')
        this.onSaved()
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private onCancelClicked = () => {
    this.props.onCancel()
  }

  private onSaved = () => {
    this.props.onSaved()
  }

  render (): React.ReactNode {
    return <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      <div className="form-row">
        <div className="col-md-12">
          {this.formHelper.renderSelectInput({
            label: 'Badge Color',
            name: 'badgeColor',
            options: Config.BADGE_COLORS.map(b => ({ text: b, value: b })),
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-12">
          <div className="form-group">
            <div className="form-check form-checkbox">
              <label>
                <input type="checkbox"
                       className="form-check-input"
                       name="createShippingRequest"
                       checked={!!this.formState.get('createShippingRequest')}
                       onChange={this.formState.onChange}
                />
                <span className="label-text">Create Shipping Request</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      {this.formState.get('createShippingRequest') &&
      <>
        <div className="form-row">
          <div className="col-sm-12">
            {this.formHelper.renderSelectInput({
              name: 'shippingOption',
              options: this.shippingOptions,
              label: 'Ship To',
              onChange: this.handleShippingOptionChanged,
            })}
          </div>
        </div>
        {
          this.formState.get('shippingOption')
            ? <>
              <div className="form-row">
                <div className="col-sm-12">
                  {this.formHelper.renderTextInput({
                    name: 'name',
                    type: 'text',
                    label: 'Ship To Name',
                    disabled: this.formState.get('shippingOption') !== 'other'
                  })}
                </div>
              </div>
              <div className="form-row">
                <div className="col-sm-12">
                  {this.formHelper.renderTextInput({
                    name: 'businessName',
                    type: 'text',
                    label: 'Business Name',
                    disabled: this.formState.get('shippingOption') !== 'other'
                  })}
                </div>
              </div>
              <div className="form-row">
                <div className="col-sm-8">
                  {this.formHelper.renderTextInput({
                    name: 'address',
                    type: 'text',
                    label: 'Street',
                    disabled: this.formState.get('shippingOption') !== 'other'
                  })}
                </div>
                <div className="col-sm-4">
                  {this.formHelper.renderTextInput({
                    name: 'address2',
                    type: 'text',
                    label: 'Suite / Room / Apt #',
                    disabled: this.formState.get('shippingOption') !== 'other'
                  })}
                </div>
              </div>

              <div className="form-row">
                <div className="col-sm-3">
                  {this.formHelper.renderTextInput({
                    name: 'city',
                    type: 'text',
                    label: 'City',
                    disabled: this.formState.get('shippingOption') !== 'other'
                  })}
                </div>
                <div className="col-sm-3">
                  {this.formHelper.renderTextInput({
                    name: 'state',
                    type: 'text',
                    label: 'State',
                    disabled: this.formState.get('shippingOption') !== 'other'
                  })}
                </div>
                <div className="col-sm-3">
                  {this.formHelper.renderTextInput({
                    name: 'zipCode',
                    type: 'text',
                    label: 'Zip Code',
                    disabled: this.formState.get('shippingOption') !== 'other'
                  })}
                </div>
                <div className="col-sm-3">
                  {this.formHelper.renderSelectInput({
                    name: 'country',
                    options: Config.COUNTRY_OPTIONS.map(c => ({ value: c, text: c })),
                    label: 'Country',
                    disabled: this.formState.get('shippingOption') !== 'other'
                  })}
                </div>
              </div>
            </>
            : null
        }
        <div className="form-row">
          <div className="col-md-12">
            {this.formHelper.renderTextAreaInput({
              label: 'Shipping Notes',
              name: 'shippingNotes',
            })}
          </div>
        </div>
      </>}

      <hr/>

      <div className="form-buttons">
        <Button type="button" color="" onClick={() => this.onCancelClicked()}>Cancel</Button>
        <ButtonLoader type="submit" color="success" loading={this.submitting}>Update Badge Color</ButtonLoader>
      </div>
    </form>
  }
}
