import { observer } from "mobx-react"
import React from "react"
import BaseFilterComponent from './BaseFilterComponent'
import SelectInput, { LoadOptionsMethod, SelectOption } from '../../inputs/SelectInput'
import { computed } from 'mobx'
import { AsyncSelectFilterState } from './AsyncSelectFilter'

export type Props = {
  label: string
  state: AsyncSelectFilterState
  loadOptions: LoadOptionsMethod
  onChanged: (option: SelectOption) => void
}

@observer
export default class AsyncSelectFilterComponent extends BaseFilterComponent<Props> {
  @computed
  private get selectedOption () {
    return this.props.state.value !== ''
      ? {
        value: this.props.state.value,
        label: this.props.state.label,
      }
      : undefined
  }

  private onChanged = (option: SelectOption) => {
    this.props.onChanged(option)
    this.props.eventBus.dispatch('changed')
  }

  render () {
    return <div className="form-group">
      <label>{this.props.label}</label>
      <div className="input-group">
        <SelectInput
          key={this.props.state.value}
          loadOptions={this.props.loadOptions}
          defaultValue={this.selectedOption}
          onChange={option => {
            this.onChanged(option)
          }}
          className="async-select-input"
          classNamePrefix="async-select-input"
        />
      </div>
    </div>
  }
}