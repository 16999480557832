import { observer } from "mobx-react"
import * as React from "react"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import { observable, toJS } from "mobx"
import User from "../../models/User"
import ApiClient from "../../api/ApiClient"
import { modelToCamelCase } from "../../common/Util"
import SearchableUserList, { SearchableUserListFilterType } from "../SearchableUserList"

type Props = {
  value?: number
  onChange: (user: User | undefined) => void
  filters?: SearchableUserListFilterType
  loading: boolean
  initialUserCache: User[]
  placeholder?: string
}

@observer
export class UserPickerInput extends React.Component<Props> {
  static defaultProps = {
    onChange: () => {
    },
    loading: false,
    initialUserCache: [],
    placeholder: undefined,
  }

  private usersCache = {}

  @observable
  private isOpen = false
  @observable
  private user?: User
  @observable
  private loading = false
  @observable
  private error?: string

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    this.warmCache()

    if (this.props.value !== prevProps.value) {
      this.updateUser()
    }
  }

  private updateUser = () => {
    if (this.props.value) {
      if (!this.user || this.user.id !== this.props.value) {
        this.loading = true

        if (this.usersCache[this.props.value]) {
          this.user = this.usersCache[this.props.value]
          this.loading = false
        } else {
          ApiClient.users.show(this.props.value)
            .then(response => {
              this.user = new User(modelToCamelCase(response.data.user))
              this.usersCache[this.user.id] = this.user
            }, error => {
              this.error = 'Error loading data'
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    } else {
      this.user = undefined
    }
  }

  private warmCache = () => {
    this.props.initialUserCache.forEach(m => this.usersCache[m.id] = toJS(m))
  }

  componentWillMount (): void {
    this.warmCache()
    this.updateUser()
  }

  private showModal = () => {
    this.isOpen = true
  }

  private closeModal = () => {
    this.isOpen = false
  }

  private renderUser = () => {
    return this.user ? this.user.name : `User #${this.props.value}`
  }

  private clear = () => {
    this.props.onChange(undefined)
  }

  render (): React.ReactNode {
    return <>
      <div className="item-picker-input">
        <div className="item-picker-selection">
          {this.loading || this.props.loading
            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
            : (this.props.value ? this.renderUser() : <div className="item-picker-empty">{this.props.placeholder || 'None'}</div>)}
        </div>
        {this.props.value &&
        <Button
          type="button"
          title="Clear Selection"
          color="danger"
          onClick={this.clear}
          className="item-picker-button"
        ><i className="fa fa-times"/></Button>}
        <Button
          title="Search Users"
          type="button"
          color="primary"
          onClick={this.showModal}
          className="item-picker-button"
        ><i className="fa fa-search"/></Button>
      </div>
      <Modal
        isOpen={this.isOpen}
        size="lg"
      >
        <ModalHeader toggle={this.closeModal}>
          Browse Users
        </ModalHeader>
        <ModalBody>
          <SearchableUserList
            onChoose={(user) => {
              this.usersCache[user.id] = user
              this.closeModal()
              this.props.onChange(user)
            }}
            filters={this.props.filters}
            style={{
              maxHeight: "400px",
            }}
          />
        </ModalBody>
      </Modal>
    </>
  }
}