import Member from "../models/Member"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { observer } from "mobx-react"
import { Button } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import AppStateStore from "../stores/AppStateStore"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import Util, { logException, modelToSnakeCase } from "../common/Util"
import { toast } from "react-toastify"
import { route } from "../routes/routes"

type Props = {
  member: Member
  onCancel: () => void
  onSaved: () => void
}

type EditFormState = {
  videoUrl: string
}

@observer
export default class MemberVideoForm extends React.Component<Props, never> {
  @observable
  private formState = new FormState<EditFormState>({
    videoUrl: this.props.member.videoData ? this.props.member.videoData.videoUrl : ''
  })

  @observable
  private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable
  private submitting = false

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.getInstance().post(route(ApiRoutes.members.updateVideoUrl, { id: this.props.member.id }), modelToSnakeCase(this.formHelper.toObject()))
      .then(() => {
        toast.success('Video Added')
        this.onSaved()
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private onCancelClicked = () => {
    this.props.onCancel()
  }

  private onSaved = () => {
    this.props.onSaved()
  }

  render (): React.ReactNode {
    return <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      <p>
        You must first save your video to YouTube or Vimeo and then enter the
        URL to your YouTube video below. You can find this URL using the share link on YouTube. If you
        need more assistance in saving a video to YouTube, you can watch one of these
        videos…https://www.youtube.com/results?search_query=upload+a+video+on+youtube
      </p>
      <p>
        The URL will look like this: <code>https://youtu.be/abc123</code>
      </p>
      <div className="form-row">
        <div className="col-sm-12">
          {this.formHelper.renderTextInput({
            name: 'videoUrl',
            type: 'text',
            label: 'YouTube Video URL',
            placeholder: 'https://youtu.be/videoid',
          })}
        </div>
      </div>
      <div className="form-buttons">
        <Button type="button" color="" onClick={() => this.onCancelClicked()}>Cancel</Button>
        <ButtonLoader type="submit" color="success" loading={this.submitting}>Add Video</ButtonLoader>
      </div>
    </form>
  }
}
