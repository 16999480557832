import * as React from "react"
import { observer } from "mobx-react"
import { Bar } from "react-chartjs-2"
import { computed, observable } from "mobx"
import LazyResourcePanel from "./LazyResourcePanel"
import { createLazyResource, formatNumber, modelToCamelCase } from "../common/Util"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { route } from "../routes/routes"
import { Moment } from "moment-timezone"
import * as _ from "lodash"

type TipStatsData = {
  tipTotals: MemberTipTotal[]
  tipBatches: BatchTotal[]
}

type BatchTotal = {
  month: number
  year: number
  value: number
}

type MemberTipTotal = {
  type: string
  month: number
  year: number
  totalSent: number
  totalReceived: number
  totalValue: number
}

type MemberTipsChartProps = {
  memberId?: number | string,
  dateRange: { start: Moment, end: Moment }
}

@observer
export default class MemberTipsChart extends React.Component<MemberTipsChartProps> {
  @observable private tipStats = createLazyResource<TipStatsData>(() => {
    return ApiClient.getInstance().get(route(ApiRoutes.tips.stats, {}, {
      type: 'member',
      member: this.props.memberId,
      start_date: this.props.dateRange.start.format('YYYY-MM-DD'),
      end_date: this.props.dateRange.end.format('YYYY-MM-DD'),
    }))
  }, response => {
    return {
      tipTotals: response.data.tip_data.map((t: {}) => modelToCamelCase(t)),
      tipBatches: response.data.batch_data.map((t: {}) => modelToCamelCase(t)),
    }
  })

  @computed get chartData () {
    const data = {
      'Received': new Array(this.chartMonths.length).fill(0),
      'Sent': new Array(this.chartMonths.length).fill(0),
      'Value': new Array(this.chartMonths.length).fill(0),
    }

    this.chartMonths.forEach((date, idx) => {
      if (this.tipStats.current) {
        this.tipStats.current.tipTotals.filter(td => td.year === date.year() && td.month === date.month() + 1)
          .forEach(td => {
            if (td.type === 'Received') {
              data['Received'][idx] += Number(td.totalReceived)
              data['Value'][idx] += Number(td.totalValue)
            } else {
              data['Sent'][idx] += Number(td.totalSent)
            }
          })

        this.tipStats.current.tipBatches.filter(td => td.year === date.year() && td.month === date.month() + 1)
          .forEach(td => {
            data['Value'][idx] += Number(td.value)
          })
      }
    })

    return data
  }

  @computed get chartMonths () {
    let cur = this.props.dateRange.start.clone().startOf('month')
    const months: Moment[] = []

    if (this.props.dateRange.start.isAfter(this.props.dateRange.end)) {
      return []
    }

    while (cur.isSameOrBefore(this.props.dateRange.end)) {
      months.push(cur.clone())
      cur.add(1, 'month')
    }

    return months
  }

  componentDidUpdate (prevProps: Readonly<MemberTipsChartProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.memberId !== this.props.memberId || !_.isEqual(prevProps.dateRange, this.props.dateRange)) {
      this.tipStats.invalidate()
    }
  }

  @observable private chartWidth: string | number = "100%"
  @observable private chartHeight: string | number = "auto"
  private chartElements: HTMLCollectionOf<Element>

  private retries = 0
  private resizeCharts = () => {
    if (!this.chartElements.length && this.retries < 20) {
      setTimeout(() => this.resizeCharts(), 100)
      ++this.retries
    }
    for (let i = 0; i < this.chartElements.length; ++i) {
      const el = this.chartElements.item(i)
      if (el) {
        const rect = el.getBoundingClientRect()

        this.chartWidth = window.innerWidth - rect.left - 80
        this.chartHeight = Math.max(Math.min(window.innerHeight - rect.top - 100, 600), 200)
      }
    }
  }

  private windowResized = () => {
    this.resizeCharts()
  }

  componentDidMount (): void {
    this.chartElements = document.getElementsByClassName('chart-container');
    this.resizeCharts()
    window.addEventListener('resize', this.windowResized)
  }

  componentWillUnmount (): void {
    window.removeEventListener('resize', this.windowResized)
  }

  render (): React.ReactNode {
    return <>
      <LazyResourcePanel resource={this.tipStats} emptyMessage={'There is no tip data to display'}>
        {() => <>
          <div className="text-center">
            <small>click box to remove from graph</small>
          </div>
          <div className="chart-container" style={{ position: 'relative', width: this.chartWidth, height: this.chartHeight }}>
            <Bar
              data={{
                labels: this.chartMonths.map(m => m.format('MMM YY')),
                datasets: [
                  {
                    label: 'Sent',
                    data: this.chartData['Sent'],
                    backgroundColor: '#36a3f7',
                    borderColor: '#36a3f7',
                    fill: false,
                    lineTension: 0,
                    yAxisID: 'Count',
                  },
                  {
                    label: 'Received',
                    data: this.chartData['Received'],
                    backgroundColor: '#ffb822',
                    borderColor: '#ffb822',
                    fill: false,
                    lineTension: 0,
                    yAxisID: 'Count',
                  },
                  {
                    label: 'Value',
                    data: this.chartData['Value'],
                    backgroundColor: '#5ec18e',
                    borderColor: '#5ec18e',
                    fill: false,
                    lineTension: 0,
                    yAxisID: 'Value',
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  yAxes: [
                    {
                      id: 'Count',
                      ticks: {
                        beginAtZero: true,
                        callback: value => value % 1 === 0 ? value : undefined,
                      },
                    },
                    {
                      id: 'Value',
                      position: 'right',
                      ticks: {
                        beginAtZero: true,
                        callback: value => value % 1 === 0 ? `$${formatNumber(value, 2)}` : '',
                      },
                    },
                  ],
                },
                tooltips: {
                  callbacks: {
                    label: (tooltipItem, data) => {
                      const label = data.datasets![tooltipItem.datasetIndex!].label
                      const value = Number(data.datasets![tooltipItem.datasetIndex!].data![tooltipItem.index!] || 0)
                      if (tooltipItem.datasetIndex === 2) {
                        return `${label}: $${formatNumber(value, 2)}`
                      } else {
                        return `${label}: ${value.toString()}`
                      }
                    },
                  }
                }
              }}
            />
          </div>
        </>}
      </LazyResourcePanel>
    </>
  }
}