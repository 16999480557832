import * as React from "react"
import { observer } from "mobx-react"
import AuthStore from "../stores/AuthStore"
import { UserRole } from "../models/AuthUser"
import * as _ from "lodash"

type MemberRender = (allowed: boolean) => React.ReactNode

type Props = {
  renderIfDenied?: boolean
  children: MemberRender
}

@observer
export default class RequireMember extends React.Component<Props, never> {
  static defaultProps = {
    renderIfDenied: false,
  }

  render (): React.ReactNode {
    const user = AuthStore.getUser()


    const allow = !!(user && user.member)

    return (allow || this.props.renderIfDenied) ? this.props.children(allow) : null
  }
}