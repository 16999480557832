import React from 'react'
import EventBus from '../../common/EventBus'
import TableView from '../table-view/TableView'
import { ApiTableViewAdapter } from '../table-view/ApiTableViewAdapter'
import AuthStore from '../../stores/AuthStore'
import ExtendedNetworkMember from '../../models/ExtendedNetworkMember'
import { Button } from 'reactstrap'
import AppStateStore from '../../stores/AppStateStore'
import ApiClient, { ApiRoutes } from '../../api/ApiClient'
import { route } from '../../routes/routes'
import Util, { formatNumber } from '../../common/Util'
import { toast } from 'react-toastify'

type Props = {
  eventBus: EventBus,
}

export default class ExtendedNetworkMembers extends React.Component<Props> {
  private tableViewRef = React.createRef<TableView>()

  private columns = [
    {
      title: 'Name',
      accessor: 'name',
    },
    {
      title: 'Phone',
      accessor: 'phone',
    },
    {
      title: 'Email',
      accessor: 'email',
    },
    {
      title: '# Tips',
      accessor: 'numberOfTipsPassed',
      headerClassName: 'justify-content-end',
      renderItem: (n: number) => <div className="text-right">{formatNumber(n)}</div>
    },
    {
      title: 'Status',
      accessor: 'status',
    },
    {
      title: 'Actions',
      headerClassName: 'justify-content-center',
      renderItem: (_: any, member: ExtendedNetworkMember) => this.renderMemberActions(member)
    },
  ]

  private renderMemberActions = (member: ExtendedNetworkMember) => {
    if (member.status !== 'Revoked') {
      return <div className="text-center">
        <Button type="button" color="primary" size="sm" title="Resend Invitation" onClick={() => this.sendInvitation(member)}>Resend Invitation</Button>
        <Button type="button" color="danger" size="sm" title="Revoke Invitation" onClick={() => this.revokeInvitation(member)}>Revoke</Button>
      </div>
    } else if (member.status === 'Revoked') {
      return <div className="text-center">
        <Button type="button" color="primary" size="sm" title="Activate" onClick={() => this.sendInvitation(member)}>Activate</Button>
      </div>
    } else {
      return null
    }
  }

  private sendInvitation = (member: ExtendedNetworkMember) => {
    AppStateStore.showConfirmationModal('Send Invitation', 'Are you sure you want to resend the invitation link to this extended network contact?', (result, modal) => {
      if (result) {
        AppStateStore.showModalSpinner()

        ApiClient.getInstance().post(route(ApiRoutes.extendedNetwork.resendLink, { id: member.id }))
          .then(() => {
            this.tableViewRef.current && this.tableViewRef.current.fetchData()
            toast.success('Link sent')
          })
          .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
          .then(() => AppStateStore.dismissModalSpinner())
      }

      modal.hide()
    })
  }

  private revokeInvitation = (member: ExtendedNetworkMember) => {
    AppStateStore.showConfirmationModal('Revoke Invitation', 'Are you sure you want to revoke the invitation to this extended network contact? They will no longer be able to access their personalized dashboard or submit tips on your behalf.', (result, modal) => {
      if (result) {
        AppStateStore.showModalSpinner()

        ApiClient.getInstance().post(route(ApiRoutes.extendedNetwork.revokeInvitation, { id: member.id }))
          .then(() => {
            this.tableViewRef.current && this.tableViewRef.current.fetchData()
            toast.success('Invitation revoked')
          })
          .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
          .then(() => AppStateStore.dismissModalSpinner())
      }

      modal.hide()
    })
  }

  private membersAdapter = new ApiTableViewAdapter(
    () => {
      return {
        query: `
    extendedNetworkMembers {
      *
      numberOfTipsPassed
    }`,
        where: [
          { _scope: 'member', value: AuthStore.getUser()!.member!.id },
        ],
      }
    },
    'extendedNetworkMembers',
    ExtendedNetworkMember
  )

  componentDidMount (): void {
    this.props.eventBus.on('invalidate', this.invalidateMembers)
  }

  componentWillUnmount (): void {
    this.props.eventBus.remove(this.invalidateMembers)
  }

  private invalidateMembers = () => {
    this.tableViewRef.current && this.tableViewRef.current.fetchData()
  }

  render () {
    return <TableView
      ref={this.tableViewRef}
      adapter={this.membersAdapter}
      columns={this.columns}
      defaultSort={{ sortKey: 'name' }}
      hideItemCount={true}
    />
  }
}
