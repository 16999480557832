import BaseView from "../BaseView"
import * as React from "react"
import { observer } from "mobx-react"
import DocumentLibrary from "../../components/DocumentLibrary"
import { LibraryType } from "../../models/DocumentLibraryItem"

@observer
export default class NetworkDocumentLibraryView extends BaseView {
  renderContentHeader (): React.ReactNode | null {
    return <>
      <h1>LeTip International Document Library</h1>
    </>
  }

  renderContentBody (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <DocumentLibrary libraryDescriptor={{
      libraryType: LibraryType.NetworkDocumentLibrary,
    }}/>
  }
}