import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import ManagedTableView from '../table-view/ManagedTableView'
import { ApiTableViewAdapter } from '../table-view/ApiTableViewAdapter'
import ApiClient, { ApiRoutes, QueryWhereClause } from '../../api/ApiClient'
import Chapter from '../../models/Chapter'
import { ManagedChooseableColumn } from '../table-view/ManagedTableViewColumnChooser'
import moment from 'moment-timezone'
import AppStateStore from '../../stores/AppStateStore'
import { route } from '../../routes/routes'
import Util, { safeNull } from '../../common/Util'
import { toast } from 'react-toastify'
import Member from '../../models/Member'

type Props = {
  onClosed: () => void
  memberId: number
}

@observer
export default class CopySponsorsModal extends React.Component<Props> {
  @observable private isOpen = true
  @observable private loading = false
  @observable private submitting = false

  private tableViewRef = React.createRef<ManagedTableView>()

  private availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'fullName',
      column: {
        accessor: 'fullName',
        title: 'Member',
        renderItem: (fullName, member: Member) => <div>
          {member.fullName}<br/>
          {safeNull(() => member.chapter!.name)}<br/>
          {safeNull(() => member.category!.name)}
          </div>
      },
      fixed: true,
    },
    {
      id: 'numberOfSponsoredMembers',
      column: {
        accessor: 'numberOfSponsoredMembers',
        title: '# Sponsored',
      },
      fixed: true,
    },
    {
      id: 'joinDate',
      column: {
        accessor: 'joinDate',
        title: 'Join Date',
        renderItem: d => d ? moment(d).format('MM/DD/YYYY') : '',
      },
      fixed: true,
    },
    {
      id: 'status',
      column: {
        accessor: 'status',
        title: 'Status',
      },
      fixed: true,
    },
    {
      id: 'actions',
      column: {
        accessor: 'id',
        renderItem: id => <Button color="primary" onClick={() => this.copySponsors(id)}>Choose</Button>,
      },
      fixed: true,
    },
  ]

  private membersAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      where.push({
        id: 'full_name',
        op: '!=',
        value: '',
      })

      where.push({
        id: 'full_name',
        op: 'notnull',
      })

      where.push({
        id: 'id',
        op: '!=',
        value: this.props.memberId,
      })

      return {
        query: `
      members {
        id
        fullName
        joinDate
        status
        numberOfSponsoredMembers
        
        chapter {
          name
        }
        category {
          name
        }
      }
    `,
        where: where,
      }
    },
    'members',
    Chapter
  )

  private toggle = () => {
    if (!this.submitting) {
      this.isOpen = false
    }
  }

  private copySponsors = (sourceMemberId: number) => {
    AppStateStore.showConfirmationModal('Copy Sponsors', 'Are you sure you want to copy all sponsors from the selected member?', (result, modal) => {
      if (result) {
        AppStateStore.showModalSpinner()
        this.submitting = true

        ApiClient.getInstance().post(route(ApiRoutes.members.copySponsors, { id: this.props.memberId }), {source_member_id: sourceMemberId})
          .then(() => {
            toast.success('Sponsors have been copied')
            this.isOpen = false
          })
          .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
          .then(() => {
            AppStateStore.dismissModalSpinner()
            this.submitting = false
          })
      }

      modal.hide();
    })
  }

  render () {
    return <Modal isOpen={this.isOpen} toggle={this.toggle} size="lg" onClosed={this.props.onClosed}>
      <ModalHeader toggle={this.toggle}>
        Copy Sponsors
      </ModalHeader>
      <ModalBody>
        <ManagedTableView
          ref={this.tableViewRef}
          hideItemCount={true}
          adapter={this.membersAdapter}
          availableColumns={this.availableColumns}
          searchLabel="Find A Member"
          defaultSort={{ sortKey: 'full_name' }}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={this.submitting} onClick={this.toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  }
}
