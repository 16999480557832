import React from 'react'
import { publicPath } from '../common/Util'
import HelpTooltip from './HelpTooltip'
import { computed, observable } from 'mobx'
import { observer } from 'mobx-react'

type Props = {
  url?: string | null,
}

@observer
export default class BankCodeLink extends React.Component<Props> {
  @observable private loaded = false

  @computed
  private get url () {
    // return this.props.url || 'https://my.bankcode.com/letip'
    return this.props.url || 'https://codebreakerglobal.com/crackyourcode?code=letip'
  }

  render () {
    return <div style={{ display: 'flex' }}>
      <a href={this.url} target="_blank">
        <img width="140" src={publicPath('img/bank/Click_Here_To_Crack_My_Code.JPG')} alt="Crack My Code"/>
      </a>
      <div style={{ marginLeft: 8 }}>
        <HelpTooltip
          content="B.A.N.K.® is a personality profile that helps PREDICT BUYING BEHAVIOR IN LESS THAN 90 SECONDS. B.A.N.K.® is the world’s only sales methodology scientifically validated to accurately predict buying behavior in real time. Get ready to increase your sales up to 300%. LeTip has chosen BANK to add to our members knowledge of how to educate your LeTip salesforce, give commercials, invite and close new members."
          placement="right"
        />
      </div>
    </div>
  }
}
