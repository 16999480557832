import BaseFilter from "./BaseFilter"
import { escapeLike } from "../../../common/Util"
import { computed, observable } from 'mobx'
import React from 'react'
import EventBus from '../../../common/EventBus'
import LetterFilterComponent from './LetterFilterComponent'

export enum LetterFilterTargetType {
  Field,
  Scope,
}

export type LetterFilterState = {
  value: string
}

export default class LetterFilter extends BaseFilter<LetterFilterState> {
  @observable value: string = ''

  @computed get state (): LetterFilterState {
    return {
      value: this.value,
    }
  }

  @computed get isActive (): boolean {
    return this.value.trim().length > 0
  }

  @computed get description (): string {
    return this.value.trim().length
      ? `${this.label} starts with ${this.value}`
      : ''
  }

  constructor (public id: string, public targetType: LetterFilterTargetType, public target: string, public label: string) {
    super()
  }

  getWhereClause = () => {
    return this.value.trim().length
      ? this.targetType === LetterFilterTargetType.Scope
        ? [{
          _scope: this.target,
          value: this.value,
        }]
        : [{
          id: this.target,
          op: 'like',
          value: `${escapeLike(this.value)}%`,
        }]
      : []
  }

  restoreState = (state: LetterFilterState) => {
    this.value = state.value
  }

  private onChanged = (value: string) => {
    this.value = value
  }

  render = (eventBus: EventBus) => {
    return React.createElement(LetterFilterComponent, {
      label: this.label,
      state: this.state,
      onChanged: this.onChanged,
      eventBus: eventBus,
    })
  }
}