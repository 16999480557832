import * as React from "react"
import { createLazyResource, formatCurrency, modelToCamelCase } from "../common/Util"
import LazyResourcePanel from "./LazyResourcePanel"
import { observable } from "mobx"
import Invoice from "../models/Invoice"
import ApiClient from "../api/ApiClient"
import { InvoiceDetailModal } from "./InvoiceDetailModal"
import { observer } from "mobx-react"
import EventBus, { EventBusContext } from "../common/EventBus"
import { Button } from "reactstrap"
import EditInvoiceModal from "./EditInvoiceModal"
import RequirePermission, { Permission } from "./RequirePermission"

@observer
export default class MemberBillingHistory extends React.Component<{
  memberId: number
  emptyMessage?: React.ReactNode
}> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  @observable private showEditInvoiceModal = false
  @observable private renderEditInvoiceModal = false

  componentDidMount (): void {
    this.addListeners(this.context.eventBus)
  }

  componentWillUnmount (): void {
    this.removeListeners(this.context.eventBus)
  }

  private addListeners = (eventBus: EventBus) => {
    eventBus.on('member-invalidated', this.onMemberInvalidated)
    eventBus.on('invoice-invalidated', this.onInvoiceInvalidated)
  }

  private removeListeners = (eventBus: EventBus) => {
    eventBus.remove(this.onMemberInvalidated)
    eventBus.remove(this.onInvoiceInvalidated)
  }

  private onInvoiceInvalidated = () => {
    this.invoices.invalidate()
  }

  private onMemberInvalidated = () => {
    this.invoices.invalidate()
  }

  @observable private invoices = createLazyResource<Invoice[]>(() => {
    return ApiClient.query(`
invoices {
  *
}
    `, {
      where: [
        { memberId: this.props.memberId },
      ],
      order: [
        { id: 'createdAt', desc: true },
        { id: 'id', desc: true },
      ],
    })
  }, response => response.data.invoices.map((t: {}) => new Invoice().init(modelToCamelCase(t))))

  @observable private showDetailInvoice = false
  @observable private showDetailInvoiceId?: number

  render (): React.ReactNode {
    return <>
      <RequirePermission permission={Permission.CreateMemberInvoice} context={{ memberId: this.props.memberId }}>
        {() => <div className="text-right mb-4">
          <Button
            type="button"
            color="primary"
            onClick={() => {
              this.renderEditInvoiceModal = true
              this.showEditInvoiceModal = true
            }}
          ><i className="fa fa-plus-circle"/> Create Invoice</Button>
        </div>}
      </RequirePermission>
      <LazyResourcePanel resource={this.invoices} emptyMessage={this.props.emptyMessage || "There is no billing history for this member"} hideWhenEmpty={false}>
        {invoices => <table className="table table-striped">
          <thead>
          <tr>
            <th>Invoice #</th>
            <th>Date</th>
            <th>Due</th>
            <th>Description</th>
            <th className="text-right">Total $</th>
            <th className="text-right">Amount Due</th>
            <th>Date Paid</th>
          </tr>
          </thead>
          <tbody>
          {invoices.map(i => <tr key={i.id}>
            <td>{i.id}</td>
            <td><a onClick={ev => {
              ev.preventDefault()
              this.showDetailInvoiceId = i.id
              this.showDetailInvoice = true
            }} href="">{i.createdAt.format('MM/DD/YYYY')}</a></td>
            <td>{i.dueDate ? i.dueDate.format('MM/DD/YYYY') : '---'}</td>
            <td>{i.description}</td>
            <td className="text-right">{formatCurrency(i.total, true)}</td>
            <td className="text-right">{formatCurrency(i.amountDue, true)}</td>
            <td>{i.paidAt ? i.paidAt.format('MM/DD/YYYY') : '---'}</td>
          </tr>)}
          </tbody>
        </table>}
      </LazyResourcePanel>
      {
        this.showDetailInvoiceId
          ? <InvoiceDetailModal
            isOpen={this.showDetailInvoice}
            toggle={() => this.showDetailInvoice = false}
            invoiceId={this.showDetailInvoiceId}
            onClosed={() => this.showDetailInvoiceId = undefined}
          />
          : null
      }
      {
        this.renderEditInvoiceModal
          ? <EditInvoiceModal
            memberId={this.props.memberId}
            isOpen={this.showEditInvoiceModal}
            toggle={() => this.showEditInvoiceModal = false}
            onClosed={() => this.renderEditInvoiceModal = false}
          />
          : null
      }
    </>
  }
}
