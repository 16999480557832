import * as React from "react"
import * as _ from 'lodash'
import { Button, Modal, ModalHeader } from "reactstrap"
import ModalBody from "reactstrap/lib/ModalBody"
import ModalFooter from "reactstrap/lib/ModalFooter"
import { action, observable } from "mobx"
import { observer } from "mobx-react"

type ButtonType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'link'

type ModalButtonProps = {
  label: string
  type: ButtonType | string
  onClick: (modal: ConfirmationModal) => void
}

type ConfirmationModalProps = {
  title: string
  message: React.ReactNode
  size?: 'lg' | 'sm'
  buttons: ModalButtonProps[]
  show: boolean
  type: 'default' | 'danger'
}

@observer
export default class ConfirmationModal extends React.Component<ConfirmationModalProps> {
  @observable
  private visible = false
  @observable
  private shouldRender = false

  static defaultProps = {
    title: '',
    message: '',
    buttons: [],
    show: false,
    type: 'default',
  }

  componentWillMount (): void {
    this.shouldRender = this.visible = this.props.show
  }

  @action
  hide = () => {
    this.visible = false
  }

  @action
  show = () => {
    this.visible = true
    this.shouldRender = true
  }

  onClosed = () => {
    setTimeout(() => this.shouldRender = false, 0)
  }

  render (): React.ReactNode {
    const { title, message, buttons, type } = this.props
    return this.shouldRender
      ? (
        <Modal isOpen={this.visible} onClosed={this.onClosed}>
          <ModalHeader>{type === 'danger' && <i className="fa fa-exclamation-circle text-danger" style={{ paddingRight: 8 }}/>}{title}</ModalHeader>
          <ModalBody>{message}</ModalBody>
          <ModalFooter>
            {_.map(buttons, (b, i) => (
              <Button key={i} color={b.type} onClick={() => b.onClick(this)}>{b.label}</Button>
            ))}
          </ModalFooter>
        </Modal>
      )
      : null
  }
}