import * as React from "react"
import BaseView from "../BaseView"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { observable } from "mobx"
import { observer } from "mobx-react"
import ApiClient from "../../api/ApiClient"
import { createLazyResource, safeNull } from "../../common/Util"
import { BarLoader } from "react-spinners"
import Member from "../../models/Member"
import { Nav, NavItem, NavLink, TabContent } from "reactstrap"
import classNames from 'classnames'
import { createBrowserHistory } from 'history'
import MemberSidebar from "../../components/MemberSidebar"
import MemberProfile from "../../components/MemberProfile"
import LazyTabPane from "../../components/LazyTabPane"
import LazyResourcePanel from "../../components/LazyResourcePanel"
import MemberBusinessSidebar from "../../components/MemberBusinessSidebar"
import MemberBusiness from "../../components/MemberBusiness"
import AuthStore from "../../stores/AuthStore"
import { RouteComponentProps } from "react-router"

type Props = {
  match: {
    params: {
      id: number
      tab?: string
    }
  }
} & RouteComponentProps

export const TABS = {
  profile: 'profile',
  business: 'business',
}

@observer
export default class EditMemberProfileView extends BaseView<Props> {
  @observable private member = createLazyResource<Member>(() => {
    if (!AuthStore.getUser()!.member) {
      return Promise.resolve({ data: { member: {} } })
    }

    return ApiClient.query(
      `
member {
  *
  crackMyCodeLandingUrl
  codebreakerEbookUrl
  address {
    *
  }
  shippingAddress {
    *
  }
  chapter {
    *
  }
  business {
    *
    address {
      *
    }
  }
}
      `,
      {
        where: [{ id: this.props.match.params.id }]
      }
    )
  }, response => new Member(response.data.member))

  @observable private activeTab = TABS.profile

  private history = createBrowserHistory()

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>Member Profiles - {safeNull(() => this.member.current!.fullName)}</h1>
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    this.activeTab = this.props.match.params.tab || TABS.profile
    this.member.refresh().then(() => {
    })
  }

  private onMemberInvalidated = () => {
    this.member.invalidate().then(() => {
    })
  }

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(route(Routes.board.editMemberProfile, { id: this.props.match.params.id, tab: this.activeTab }))
    }
  }

  render (): React.ReactNode {
    return <LazyResourcePanel resource={this.member} initialLoader={<BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>}>
      {member => <>
        <div className="card">
          <div className="card-header">
            <Nav tabs className="card-header-tabs">
              <NavItem className={classNames({ active: this.activeTab === TABS.profile })}>
                <NavLink
                  onClick={() => {
                    this.setTab(TABS.profile)
                  }}
                >Profile Information</NavLink>
              </NavItem>
              <NavItem className={classNames({ active: this.activeTab === TABS.business })}>
                <NavLink
                  onClick={() => {
                    this.setTab(TABS.business)
                  }}
                >Business Information</NavLink>
              </NavItem>
            </Nav>
          </div>
          <div className="card-body">
            <TabContent activeTab={this.activeTab}>
              <LazyTabPane tabId={TABS.profile} activeTab={this.activeTab}>
                <div className="member-detail-pane">
                  <MemberSidebar member={member} onMemberInvalidated={this.onMemberInvalidated}/>
                  <div className="member-detail-content">
                    <MemberProfile
                      member={member}
                      onMemberInvalidated={this.onMemberInvalidated}
                    />
                  </div>
                </div>
              </LazyTabPane>
              <LazyTabPane tabId={TABS.business} activeTab={this.activeTab}>
                <div className="member-detail-pane">
                  <MemberBusinessSidebar
                    member={member}
                    onMemberInvalidated={this.onMemberInvalidated}
                  />
                  <div className="member-detail-content">
                    <MemberBusiness
                      member={member}
                      onMemberInvalidated={this.onMemberInvalidated}
                    />
                  </div>
                </div>
              </LazyTabPane>
            </TabContent>
          </div>
        </div>
      </>}
    </LazyResourcePanel>
  }
}
