import * as React from "react"
import { SyntheticEvent } from "react"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { observer } from "mobx-react"
import { Button } from "reactstrap"
import AppStateStore from "../stores/AppStateStore"
import Util, { logException, modelToCamelCase, modelToSnakeCase, safeNull } from "../common/Util"
import ButtonLoader from "./ButtonLoader"
import { toast } from "react-toastify"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { route } from "../routes/routes"
import Business from "../models/Business"
import { BarLoader } from "react-spinners"
import Config from "../common/Config"

type Props = {
  memberId: number
  businessId: number
  onSaved: () => void
  onCancel: () => void
}

@observer
export default class BusinessAddressForm extends React.Component<Props, never> {
  @observable private initialFormState = {
    address: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    isAddressPrivate: false,
  }

  @observable private formState = new FormState(this.initialFormState)
  @observable private formErrors = new ErrorBag()
  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable private loading = false
  @observable private submitting = false

  componentDidMount (): void {
    this.loading = true

    ApiClient.query(`
    business {
      *
      address {
        *
      }
    }`, {
      where: [{ id: this.props.businessId }],
    })
      .then(response => {
        const business = new Business(modelToCamelCase(response.data.business))

        this.formState.setAll({
          address: safeNull(() => business.address!.address) || '',
          address2: safeNull(() => business.address!.address2) || '',
          city: safeNull(() => business.address!.city) || '',
          state: safeNull(() => business.address!.state) || '',
          zipCode: safeNull(() => business.address!.zipCode) || '',
          country: safeNull(() => business.address!.country) || '',
          isAddressPrivate: business.isAddressPrivate,
        })
      })
      .catch(() => this.props.onCancel())
      .then(() => this.loading = false)
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.getInstance().post(route(ApiRoutes.members.updateBusinessAddress, { id: this.props.memberId }), modelToSnakeCase(this.formHelper.toObject()))
      .then(response => {
        toast.success('Business Address Updated')
        this.props.onSaved()
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  render (): React.ReactNode {
    return this.loading
      ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
      : <>
        <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
          <div className="form-row">
            <div className="col-sm-8">
              {this.formHelper.renderTextInput({
                name: 'address',
                type: 'text',
                label: 'Street',
              })}
            </div>
            <div className="col-sm-4">
              {this.formHelper.renderTextInput({
                name: 'address2',
                type: 'text',
                label: 'Suite / Room / Apt #',
              })}
            </div>
          </div>

          <div className="form-row">
            <div className="col-sm-3">
              {this.formHelper.renderTextInput({
                name: 'city',
                type: 'text',
                label: 'City',
              })}
            </div>
            <div className="col-sm-3">
              {this.formHelper.renderTextInput({
                name: 'state',
                type: 'text',
                label: 'State',
              })}
            </div>
            <div className="col-sm-3">
              {this.formHelper.renderTextInput({
                name: 'zipCode',
                type: 'text',
                label: 'Zip Code',
              })}
            </div>
            <div className="col-sm-3">
              {this.formHelper.renderSelectInput({
                name: 'country',
                options: Config.COUNTRY_OPTIONS.map(c => ({ value: c, text: c })),
                label: 'Country',
              })}
            </div>
          </div>
          <div className="form-row">
            <div className="col-12">
              {this.formHelper.renderCheckboxInput({
                name: 'isAddressPrivate',
                label: 'Do not show address on website',
              })}
            </div>
          </div>
          <div className="form-buttons">
            <Button type="button" color="secondary" onClick={() => this.props.onCancel()}>Cancel</Button>
            <ButtonLoader type="submit" color="success" loading={this.submitting}>Save Changes</ButtonLoader>
          </div>
        </form>
      </>
  }
}
