import * as React from "react"
import { observer } from "mobx-react"
import moment from 'moment-timezone'
import { computed, observable } from "mobx"
import MemberTipsChart from "./MemberTipsChart"

@observer
export default class TipsDashboard extends React.Component {
  @observable private chartPeriod: string = 'past_12_months'

  @computed get tipChartRange () {
    if (this.chartPeriod === 'this_year') {
      return { start: moment().startOf('year'), end: moment().endOf('year') }
    } else if (this.chartPeriod === 'last_year') {
      return { start: moment().subtract(1, 'year').startOf('year'), end: moment().subtract(1, 'year').endOf('year') }
    } else {
      return { start: moment().startOf('month').subtract(11, 'month'), end: moment().endOf('month') }
    }
  }

  @computed get tipCharTitle () {
    if (this.chartPeriod === 'this_year') {
      return 'This Year'
    } else if (this.chartPeriod === 'last_year') {
      return 'Last Year'
    } else {
      return 'Past 12 Months'
    }
  }

  render (): React.ReactNode {
    return <>
      <div className="d-flex">
        <div className="flex-fill">
          <h2>Tips for {this.tipCharTitle}</h2>
        </div>
        <div>
          <select
            className="form-control"
            value={this.chartPeriod}
            onChange={ev => this.chartPeriod = ev.target.value}
          >
            <option value={'past_12_months'}>Past 12 Months</option>
            <option value={'this_year'}>This Year</option>
            <option value={'last_year'}>Last Year</option>
          </select>
        </div>
      </div>
      <MemberTipsChart
        dateRange={this.tipChartRange}
      />
    </>
  }
}