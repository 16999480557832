import BaseModel from './BaseModel'

export default class ExtendedNetworkCategoryMember extends BaseModel {
  isHidden: boolean
  isOutsideChapter: boolean
  category: {
    id: number
    name: string
  }
  member: {
    id: number
    fullName: string
    profileImageUrl: string | null
    videoData: {
      type: string
      id: string
    } | null
  }
  business: {
    name: string
    description: string | null
    logoUrl: string | null
  }
}
