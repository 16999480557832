import * as React from "react"
import { SyntheticEvent } from "react"
import BaseView from "./BaseView"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { Prompt, Redirect } from "react-router-dom"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { observer } from "mobx-react"
import ButtonLoader from "../components/ButtonLoader"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import Util, { logException, modelToCamelCase, modelToSnakeCase } from "../common/Util"
import { BarLoader } from "react-spinners"
import { Button } from "reactstrap"
import { LinkContainer } from "react-router-bootstrap"
import Area from "../models/Area"
import * as AsyncHelpers from "../api/AsyncHelpers"
import Chapter, { ChapterStatuses } from "../models/Chapter"
import * as _ from 'lodash'
import { toast } from "react-toastify"
import Config from "../common/Config"
import BackLink from "../components/BackLink"

type EditChapterFormState = {
  areaId?: number
  billingRegion: string
  name: string
  websiteUrl: string
  bio: string
  description: string
  meetingDay: string
  meetingTime: string
  meetingLocationDescription: string
  meetingLocationAddress: {
    address: string
    address2: string
    city: string
    state: string
    zipCode: string
    country: string
  }
  status: string

  socialLinks: {
    facebook: string
    twitter: string
    linkedin: string
    instagram: string
    youtube: string
    pinterest: string
    snapchat: string
    vimeo: string,
    alignable: string,
  }
}

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

@observer
export default class EditChapterView extends BaseView<Props> {
  @observable private formState = new FormState<EditChapterFormState>({
    areaId: undefined,
    billingRegion: 'United States',
    name: '',
    websiteUrl: '',
    bio: '',
    description: '',
    meetingDay: '',
    meetingTime: '',
    meetingLocationDescription: '',
    meetingLocationAddress: {
      address: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
    },
    status: 'Active',

    socialLinks: {
      facebook: '',
      twitter: '',
      linkedin: '',
      instagram: '',
      youtube: '',
      pinterest: '',
      snapchat: '',
      vimeo: '',
      alignable: ''
    },
  })
  @observable private formErrors = new ErrorBag()
  private formHelper = new FormHelper(this.formState, this.formErrors)
  @observable private submitting = false
  @observable private loading = false
  @observable private redirect = false
  @observable private editChapter?: Chapter

  private get isEdit () {
    return !!this.props.match.params.id
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>{this.isEdit ? 'Update Existing Chapter' : 'Add New Chapter'}</h1>
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    if (this.props.match.params.id) {
      this.loading = true

      ApiClient.chapters.show(this.props.match.params.id)
        .then(response => {
          const chapterData = modelToCamelCase(response.data.chapter)
          this.editChapter = new Chapter(chapterData)
          this.formState.setAll(chapterData as EditChapterFormState)
          this.formState.set('areaId', _.get(this.editChapter, 'area.id'))
          this.formState.clearDirty()
          this.loading = false
        }, () => {
          AppStateStore.showAlertModal('Error', 'The chapter was not found', m => {
            m.hide()
            this.redirect = true
          })
        })
        .catch(ex => {
          logException(ex)
          AppStateStore.showAlertModal('Error', 'The chapter was not found', m => {
            m.hide()
            this.redirect = true
          })
        })
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    const apiCall = this.editChapter
      ? () => ApiClient.chapters.update(this.editChapter!.id, modelToSnakeCase(this.formHelper.toObject()))
      : () => ApiClient.chapters.create(modelToSnakeCase(this.formHelper.toObject()))

    apiCall().then(() => {
      toast.success(this.isEdit ? 'Chapter updated' : 'Chapter created')
      this.redirect = true
    }, error => {
      const errors = new ErrorBag()
      Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      })

      this.formErrors.addErrors(errors.getErrorList())
    })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  renderContentBody (): React.ReactNode {
    return this.redirect
      ? this.renderRedirect()
      : this.loading ? this.renderLoading() : this.renderForm()
  }

  private renderLoading = () => <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>

  private renderForm = () => (
    <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      {this.formState.isDirty && <Prompt message="Are you sure you want to leave this page? You will lose any unsaved changes!"/>}
      <h5 className="text-muted">Select An Area</h5>
      <div className="form-row">
        <div className="col-sm-6">
          {this.formHelper.renderAsyncSelectInput({
            name: 'areaId',
            label: 'Area',
            loadOptions: AsyncHelpers.loadAreaOptions,
            defaultValue: this.editChapter ? { value: _.get(this.editChapter, 'area.id'), label: _.get(this.editChapter, 'area.name') } : undefined,
          })}
        </div>
        <div className="col-sm-6">
          {this.formHelper.renderSelectInput({
            name: 'billingRegion',
            label: 'Billing Region',
            options: ['United States', 'Canada'].map(t => ({ value: t, text: t })),
            placeholder: '(choose)',
          })}
        </div>
      </div>
      <hr/>

      <h5 className="text-muted">Chapter Information</h5>
      <div className="form-row">
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'name',
            type: 'text',
            label: 'Chapter Name',
          })}
        </div>
        <div className="col-sm-6">
          {this.formHelper.renderSelectInput({
            name: 'status',
            label: 'Status',
            options: ChapterStatuses.map(s => ({ value: s, text: s })),
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'websiteUrl',
            type: 'text',
            label: 'Website',
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-12">
          {this.formHelper.renderTextAreaInput({
            name: 'bio',
            label: 'Bio',
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-12">
          {this.formHelper.renderTextAreaInput({
            name: 'description',
            label: 'Description',
          })}
        </div>
      </div>
      <hr/>

      <h5 className="text-muted">General Meeting Information</h5>
      <div className="form-row">
        <div className="col-sm-6">
          {this.formHelper.renderAsyncSelectInput({
            name: 'meetingDay',
            label: 'Meeting Day',
            loadOptions: AsyncHelpers.loadMeetingDayOptions,
            defaultValue: this.editChapter ? { value: this.editChapter.meetingDay, label: this.editChapter.meetingDay } : undefined,
          })}
        </div>
        <div className="col-sm-6">
          {this.formHelper.renderAsyncSelectInput({
            name: 'meetingTime',
            label: 'Meeting Time',
            loadOptions: AsyncHelpers.loadMeetingTimeOptions,
            defaultValue: this.editChapter ? { value: this.editChapter.meetingTime, label: this.editChapter.meetingTime } : undefined,
          })}
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-12">
          {this.formHelper.renderTextInput({
            name: 'meetingLocationDescription',
            type: 'text',
            label: 'General Meeting Location',
          })}
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-8">
          {this.formHelper.renderTextInput({
            name: 'meetingLocationAddress.address',
            type: 'text',
            label: 'Street Address',
          })}
        </div>
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'meetingLocationAddress.address2',
            type: 'text',
            label: 'Suite / Room / Apt #',
          })}
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-3">
          {this.formHelper.renderSelectInput({
            placeholder: '(choose)',
            name: 'meetingLocationAddress.country',
            options: Config.COUNTRY_OPTIONS.map(o => ({ value: o, text: o })),
            label: 'Country',
          })}
        </div>
        <div className="col-sm-3">
          {this.formHelper.renderTextInput({
            name: 'meetingLocationAddress.state',
            type: 'text',
            label: 'State',
          })}
        </div>
        <div className="col-sm-3">
          {this.formHelper.renderTextInput({
            name: 'meetingLocationAddress.city',
            type: 'text',
            label: 'City',
          })}
        </div>
        <div className="col-sm-3">
          {this.formHelper.renderTextInput({
            name: 'meetingLocationAddress.zipCode',
            type: 'text',
            label: 'Zip Code',
          })}
        </div>
      </div>
      <hr/>

      <h5 className="text-muted">Social Media Profiles</h5>
      <div className="form-row">
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.facebook',
            type: 'text',
            placeholder: 'Facebook',
            prepend: <i className="fa fa-facebook-square"/>,
          })}
        </div>
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.twitter',
            type: 'text',
            placeholder: 'Twitter',
            prepend: <i className="fa fa-twitter-square"/>,
          })}
        </div>
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.linkedin',
            type: 'text',
            placeholder: 'Linkedin',
            prepend: <i className="fa fa-linkedin-square"/>,
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.instagram',
            type: 'text',
            placeholder: 'Instagram',
            prepend: <i className="fa fa-instagram"/>,
          })}
        </div>
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.youtube',
            type: 'text',
            placeholder: 'YouTube',
            prepend: <i className="fa fa-youtube"/>,
          })}
        </div>
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.alignable',
            type: 'text',
            placeholder: 'Alignable',
            prepend: <i className="fa fa-home"/>,
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.pinterest',
            type: 'text',
            placeholder: 'Pinterest',
            prepend: <i className="fa fa-pinterest"/>,
          })}
        </div>
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.snapchat',
            type: 'text',
            placeholder: 'Snapchat',
            prepend: <i className="fa fa-snapchat"/>,
          })}
        </div>
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.vimeo',
            type: 'text',
            placeholder: 'Vimeo',
            prepend: <i className="fa fa-vimeo"/>,
          })}
        </div>
      </div>

      <div className="form-buttons">
        <LinkContainer to={route(Routes.system.chapters.index)}><Button type="button" color=""><i className="fa fa-ban"/> Cancel</Button></LinkContainer>
        <ButtonLoader type="submit" color="success" loading={this.submitting}><i className="fa fa-save"/> {this.isEdit ? 'Update Chapter' : 'Save New Chapter'}</ButtonLoader>
      </div>
    </form>
  )

  private renderRedirect = () => <Redirect to={route(Routes.system.chapters.index)}/>
}
