import * as React from "react"
import { SyntheticEvent } from "react"
import ApiClient, { QueryWhereClause } from "../api/ApiClient"
import { createLazyResource, formatNumber, safeNull } from "../common/Util"
import { observer } from "mobx-react"
import { computed, observable } from "mobx"
import * as _ from 'lodash'
import { Alert, Button } from "reactstrap"
import BaseView from "./BaseView"
import { LinkContainer } from "react-router-bootstrap"
import { Routes } from "../routes/AppRoutes"
import { route } from "../routes/routes"
import { Link } from "react-router-dom"
import classNames from 'classnames'
import Member from "../models/Member"
import RequirePermission, { Permission } from "../components/RequirePermission"
import { ApiTableViewAdapter } from "../components/table-view/ApiTableViewAdapter"
import { createBrowserHistory } from "history"
import MembersCsvTransformer from "../models/renderers/MembersCsvTransformer"
import ManagedTableView from "../components/table-view/ManagedTableView";
import { ManagedChooseableColumn } from "../components/table-view/ManagedTableViewColumnChooser";
import TextFilter, { TextFilterTargetType } from '../components/table-view/filters/TextFilter'
import AsyncSelectFilter, { AsyncSelectFilterTargetType } from '../components/table-view/filters/AsyncSelectFilter'
import { loadCategoryOptions, loadChapterOptionsForAdmin, loadMemberOptions } from '../api/AsyncHelpers'
import Guest from '../models/Guest'
import BankCode from '../components/BankCode'

@observer
export default class MembersView extends BaseView {
  private history = createBrowserHistory()

  @observable private numberOfActiveMembers = createLazyResource<number>(() => {
    return ApiClient.query(`members { * }`, { returnTotal: true, limit: 0, where: [{ _scope: 'active' }, { _scope: 'userIsAdmin' }] })
  }, response => response.data._meta.total);

  @observable private filteredArea?: {id: number, name: string}
  @observable private filteredRegion?: {id: number, name: string}
  @observable private filteredChapter?: {id: number, name: string}
  @observable private filteredCategory?: {id: number, name: string}

  @computed get tableViewStateKey () {
    let parts = ['MembersView']

    if (this.filteredArea) {
      parts.push(`Area-${this.filteredArea.id}`)
    }

    if (this.filteredRegion) {
      parts.push(`Region-${this.filteredRegion.id}`)
    }

    if (this.filteredChapter) {
      parts.push(`Chapter-${this.filteredChapter.id}`)
    }

    if (this.filteredCategory) {
      parts.push(`Category-${this.filteredCategory.id}`)
    }

    return parts.join('-')
  }

  componentWillMount (): void {
    this.filteredArea = _.get(this, 'props.location.state.area')
    this.filteredRegion = _.get(this, 'props.location.state.region')
    this.filteredChapter = _.get(this, 'props.location.state.chapter')
    this.filteredCategory = _.get(this, 'props.location.state.category')
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>Members</h1>
        <ul className="content-header-actions">
          <RequirePermission permission={Permission.CreateMember}>{() =>
            <li>
              <LinkContainer to={route(Routes.system.members.create)}>
                <Button color="primary" onClick={() => {
                }}><i className="fa fa-plus-circle"/> Add New Member</Button>
              </LinkContainer>
            </li>
          }</RequirePermission>
        </ul>
      </>
    )
  }

  @observable availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'fullName',
      column: {
        title: 'Member',
        accessor: 'fullName',
        sortKey: 'lastName',
        renderItem: (value, item) => <Link type="link" to={{ pathname: route(Routes.system.members.show, { id: item.id }), state: { id: item.id } }}>
          <div className={classNames({ 'text-muted': item.isDisabled() })}>
            {value}{item.isDisabled() && ` (${item.status})`}
          </div>
        </Link>,
        sortable: true,
      },
      fixed: true,
    },
    {
      id: 'chapter',
      column: {
        title: 'Chapter',
        accessor: 'chapter.name',
        sortable: true,
        renderItem: (value, item) => item.chapter ? <Link type="link" to={{ pathname: route(Routes.system.chapters.show, { id: item.chapter.id }), state: { id: item.chapter.id } }}>
          {value}
        </Link> : null
      },
      default: true,
    },
    {
      id: 'category',
      column: {
        title: 'Category',
        accessor: 'category.name',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'company',
      column: {
        title: 'Company',
        accessor: 'business.name',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'joinDate',
      column: {
        title: 'Join Date',
        accessor: 'joinDate',
        sortable: true,
        renderItem: value => value ? value.format('MM/DD/YYYY') : '---'
      },
      default: false,
    },
    {
      id: 'inputDate',
      column: {
        title: 'Input Date',
        accessor: 'inputDate',
        sortable: true,
        renderItem: value => value ? value.format('MM/DD/YYYY') : '---'
      },
      default: false,
    },
    {
      id: 'position',
      column: {
        title: 'Position',
        accessor: 'primaryMemberPosition.position.name',
        sortable: true,
      },
      default: false,
    },
    {
      id: 'badgeColor',
      column: {
        title: 'Badge Color',
        accessor: 'badgeColor',
        sortable: true,
      },
      default: false,
    },
    {
      id: 'ntsTraining',
      column: {
        title: 'NTS Training',
        accessor: 'ntsTrainingCompletedAt',
        sortable: true,
        renderItem: value => value ? 'Yes' : 'No'
      },
      default: false,
    },
    {
      id: 'email',
      column: {
        title: 'Email',
        accessor: 'emailAddress',
        sortable: true,
      },
      default: false,
    },
    {
      id: 'phone',
      column: {
        title: 'Phone',
        accessor: 'phoneNumber',
        sortable: true,
      },
      default: false,
    },
    {
      id: 'numberSponsored',
      column: {
        title: '# Sponsored',
        accessor: 'numberOfSponsoredMembers',
        sortable: true,
      },
      default: false,
    },
    {
      id: 'membershipType',
      column: {
        title: 'Membership Type',
        accessor: 'membershipType',
        sortable: true,
      },
      default: false,
    },
    {
      id: 'bankCode',
      column: {
        title: 'BANKCODE',
        accessor: 'bankCode',
        sortable: true,
        renderItem: (bankCode: string, item: Member) => bankCode ? <BankCode bankCode={bankCode} documentUrl={item.bankCodeDocumentUrl!}/> : null,
      }
    },
  ]

  private tableViewRef = React.createRef<ManagedTableView>()

  private membersAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      if (this.filteredArea) {
        where.push({
          _scope: 'area',
          value: this.filteredArea.id,
        })
      }

      if (this.filteredRegion) {
        where.push({
          _scope: 'region',
          value: this.filteredRegion.id,
        })
      }

      if (this.filteredCategory) {
        where.push({
          _scope: 'category',
          value: this.filteredCategory.id,
        })
      }

      if (this.filteredChapter) {
        where.push({
          _scope: 'chapter',
          value: this.filteredChapter.id,
        })
      }

      if (!safeNull(() => this.tableViewRef.current!.tableViewFilters!.showInactive)) {
        where.push({ _scope: 'active' })
      }

      where.push({ _scope: 'userIsAdmin' })

      return {
        query: `
      members {
        *

        chapter {
          *
        }

        business {
          *
        }

        category {
          *
        }

        primaryMemberPosition {
          *

          position {
            *
          }
        }
      }
    `,
        where: where,
      }
    },
    'members',
    Member
  )

  private removeAreaFilter = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.history.replace(window.location.pathname, { ...this.history.location.state, area: undefined })
    this.filteredArea = undefined
    this.tableViewRef.current!.fetchData()
  }

  private removeRegionFilter = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.history.replace(window.location.pathname, { ...this.history.location.state, region: undefined })
    this.filteredRegion = undefined
    this.tableViewRef.current!.fetchData()
  }

  private removeChapterFilter = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.history.replace(window.location.pathname, { ...this.history.location.state, chapter: undefined })
    this.filteredChapter = undefined
    this.tableViewRef.current!.fetchData()
  }

  private removeCategoryFilter = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.history.replace(window.location.pathname, { ...this.history.location.state, category: undefined })
    this.filteredCategory = undefined
    this.tableViewRef.current!.fetchData()
  }

  renderContentBody (): React.ReactNode {
    return this.renderTable()
  }

  private renderTableViewHeader = () => {
    return <>
      <div className="list-view-info-icon">
        <i className="flaticon-users"/>
      </div>
      <div className="list-view-info-text">
        <span>{this.numberOfActiveMembers.loading ? <i className="fa fa-spinner fa-spin"/> : formatNumber(this.numberOfActiveMembers.current!)}</span> Active Members
      </div>
    </>
  }

  private renderFilters = () => {
    return <>
      {this.filteredArea && (
        <Alert
          color="info"
        >
          You are currently only viewing members in the area: <b>{this.filteredArea.name}</b><br/>
          <a onClick={this.removeAreaFilter.bind(this)} href="">Click here to view all areas</a>
        </Alert>
      )}
      {this.filteredRegion && (
        <Alert
          color="info"
        >
          You are currently only viewing members in the region: <b>{this.filteredRegion.name}</b><br/>
          <a onClick={this.removeRegionFilter.bind(this)} href="">Click here to view all regions</a>
        </Alert>
      )}
      {this.filteredChapter && (
        <Alert
          color="info"
        >
          You are currently only viewing members in the chapter: <b>{this.filteredChapter.name}</b><br/>
          <a onClick={this.removeChapterFilter.bind(this)} href="">Click here to view all chapters</a>
        </Alert>
      )}
      {this.filteredCategory && (
        <Alert
          color="info"
        >
          You are currently only viewing members in the category: <b>{this.filteredCategory.name}</b><br/>
          <a onClick={this.removeCategoryFilter.bind(this)} href="">Click here to view all categories</a>
        </Alert>
      )}
    </>
  }

  private filters = [
    new TextFilter('member', TextFilterTargetType.Field, 'fullName', 'Member Name'),
    new AsyncSelectFilter('chapter', AsyncSelectFilterTargetType.Field, 'chapter.id', 'Chapter Name', loadChapterOptionsForAdmin),
    new TextFilter('company', TextFilterTargetType.Field, 'business.name', 'Company Name'),
    new AsyncSelectFilter('category', AsyncSelectFilterTargetType.Field, 'category.id', 'Category', loadCategoryOptions()),
    new TextFilter('phone', TextFilterTargetType.Scope, 'phone', 'Phone'),
    new TextFilter('email', TextFilterTargetType.Scope, 'email', 'Email'),
  ]

  private renderTable = () => {
    return <ManagedTableView
      ref={this.tableViewRef}
      stateKey={this.tableViewStateKey}
      availableColumns={this.availableColumns}
      defaultSort={{ sortKey: 'lastName' }}
      adapter={this.membersAdapter}
      searchLabel="Find A Member By Name Or Business"
      exportTransformer={MembersCsvTransformer}
      allowShowInactive={true}
      header={this.renderTableViewHeader()}
      tableHeader={this.renderFilters()}
      hideItemCount={true}
      filters={this.filters}
    />
  }
}
