import * as React from "react";
import Chapter from "../models/Chapter";
import LazyResourcePanel from "./LazyResourcePanel";
import Position, { BOARD_MEMBER_TYPE, CHAIR_MEMBER_TYPE } from "../models/Position";
import { observer } from "mobx-react";
import * as _ from "lodash";
import { MemberPickerInput } from "./inputs/MemberPickerInput";
import Util, { transformIf } from "../common/Util";
import MemberPosition from "../models/MemberPosition";
import AppStateStore from "../stores/AppStateStore";
import ApiClient from "../api/ApiClient";
import { action, computed, observable } from "mobx";
import Member from "../models/Member";
import ButtonLoader from "./ButtonLoader";
import { toast } from "react-toastify";

type Props = {
  chapter: Chapter;
  onChapterInvalidated: () => void;
};

@observer
export default class ChapterUpcomingPositionsForm extends React.Component<Props> {
  private positions = Position.createLazyResourceMany();

  @observable private submitting = false;

  componentWillMount() {
    this.positions.refresh().then(() => {});
  }

  @computed get chairMemberPositions() {
    return this.positions.current ? _.orderBy(this.positions.current.filter(p => (p.displayType || p.type) === CHAIR_MEMBER_TYPE), "displayOrder") : [];
  }

  @computed get boardMemberPositions() {
    return this.positions.current ? _.orderBy(this.positions.current.filter(p => (p.displayType || p.type) === BOARD_MEMBER_TYPE), "displayOrder") : [];
  }

  @action
  private setMemberPosition(positionId: number, memberId: number, memberPosition: MemberPosition | undefined) {
    AppStateStore.showModalSpinner();

    return ApiClient.chapters
      .setIncomingMemberPosition(this.props.chapter.id, positionId, { member_id: memberId, member_position_id: memberPosition ? memberPosition.id : undefined })
      .then(
        response => {},
        error => {
          Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
            AppStateStore.showAlertModal("Error", message, m => {
              m.hide();
            });
            return true;
          });
        }
      )
      .then(() => {
        AppStateStore.dismissModalSpinner();
        return this.props.chapter.incomingMemberPositions.refresh(true);
      });
  }

  @action
  private clearMemberPosition = (memberPosition: MemberPosition) => {
    AppStateStore.showModalSpinner();

    return ApiClient.chapters
      .clearIncomingMemberPosition(this.props.chapter.id, memberPosition.id)
      .then(
        response => {},
        error => {
          Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
            AppStateStore.showAlertModal("Error", message, m => {
              m.hide();
            });
            return true;
          });
        }
      )
      .then(() => {
        AppStateStore.dismissModalSpinner();
        return this.props.chapter.incomingMemberPositions.refresh(true);
      });
  };

  private onSubmit = () => {
    AppStateStore.showModalSpinner();

    return ApiClient.chapters
      .updateIncomingMemberPosition(this.props.chapter.id)
      .then(
        response => {
          toast.success("Incoming Board Members Updated");
        },
        error => {
          Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
            AppStateStore.showAlertModal("Error", message, m => {
              m.hide();
            });
            return true;
          });
        }
      )
      .then(() => {
        AppStateStore.dismissModalSpinner();
        this.submitting = false;
        return this.props.chapter.incomingMemberPositions.refresh(true);
      });
  };

  render(): React.ReactNode {
    return (
      <>
        <div>
          <div className="row">
            <div className="col-md-6">
              <h5>Board Members</h5>
              <LazyResourcePanel loading={this.props.chapter.boardMembers.loading} resource={this.positions} emptyMessage="There are no board positions configured">
                {() => {
                  return (
                    this.props.chapter.incomingBoardMembers.current && (
                      <PositionGroupElement
                        positions={this.boardMemberPositions}
                        memberPositions={this.props.chapter.incomingBoardMembers.current}
                        chapter={this.props.chapter}
                        onChange={(member, position, memberPosition) => {
                          if (member) {
                            return this.setMemberPosition(position.id, member.id, memberPosition);
                          } else {
                            if (memberPosition) {
                              return this.clearMemberPosition(memberPosition);
                            }
                          }

                          return Promise.resolve();
                        }}
                      />
                    )
                  );
                }}
              </LazyResourcePanel>
            </div>
          </div>
          <div className="row mt-4 ml-auto">
            <ButtonLoader color="primary" loading={this.submitting} onClick={() => this.onSubmit()}>
              Update Board
            </ButtonLoader>
            <div className="col-md-8 border border-dark ml-1">
              Pressing this Button moves incoming Board Members onto the Existing List and removes the existing Board Members. Make sure your Chapter is ready.
            </div>
          </div>


        </div>
      </>
    );
  }
}

type PositionGroupElementProps = {
  positions: Position[];
  memberPositions: MemberPosition[];
  chapter: Chapter;
  onChange: (member: Member | undefined, position: Position, memberPosition: MemberPosition | undefined) => Promise<any>;
};

@observer
class PositionGroupElement extends React.Component<PositionGroupElementProps> {
  render(): React.ReactNode {
    const { positions, memberPositions } = this.props;
    return (
      <div>
        {_.orderBy(positions, "displayOrder").map(position => {
          const members = transformIf(memberPositions, c => c.filter((m: MemberPosition) => m.position.id === position.id)) || [];
          const count = Math.min(members.length + 1, Math.max(position.maxAllowed, members.length));

          return _.map(Array(count), (v, idx) => {
            const memberPosition: MemberPosition | undefined = members.length > idx ? members[idx] : undefined;

            return <PositionListElement key={`${position.id}-${idx}`} chapter={this.props.chapter} memberPosition={memberPosition} position={position} onChange={member => this.props.onChange(member, position, memberPosition)} />;
          });
        })}
      </div>
    );
  }
}

type PositionListElementProps = {
  chapter: Chapter;
  position: Position;
  memberPosition?: MemberPosition;
  onChange: (member: Member | undefined) => Promise<any>;
};

@observer
class PositionListElement extends React.Component<PositionListElementProps> {
  static defaultProps = {
    memberPosition: undefined
  };

  @observable
  private loading = false;

  render(): React.ReactNode {
    const { position, memberPosition, chapter } = this.props;

    return (
      <div className="form-group">
        <label>{position.name}</label>
        <MemberPickerInput
          onChange={member => {
            this.loading = true;
            this.props.onChange(member).then(() => (this.loading = false));
          }}
          loading={this.loading}
          value={memberPosition ? memberPosition.member.id : undefined}
          filters={{ chapter: chapter.id }}
          initialMemberCache={memberPosition ? [memberPosition.member] : []}
        />
      </div>
    );
  }
}
