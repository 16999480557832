import * as React from "react"
import Address, { IAddress } from "../models/Address"
import { observer } from "mobx-react"

type Props = {
  address: IAddress,
  showMapLink: boolean,
}

@observer
export default class AddressView extends React.Component<Props, never> {
  static defaultProps = {
    showMapLink: false,
  }

  render (): React.ReactNode {
    const { address } = this.props

    if (!address) {
      return '---'
    }

    const lines = [
      address.businessName,
      address.address
        ? `${address.address} ${address.address2 || ''}`.trim()
        : '',
      [`${address.city} ${address.state}`.trim(), address.zipCode, address.country].filter(v => v && v.length).join(', '),
    ].filter(v => v && v.length)

    return <>
      {lines.map((l, k) => <div key={k}>{l}</div>)}
      {this.props.showMapLink && <div><a target="_blank" href={Address.getGoogleMapsUrl(address)}>view map</a></div>}
    </>
  }
}
