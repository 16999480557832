import CsvTransformer from "./CsvTransformer"
import { safeNull } from "../../common/Util"
import Chapter from "../Chapter"
import moment from "moment-timezone"
import { BOARD_MEMBER_TYPE } from '../Position'

export default class OpenCategoryReportCsvTransformer extends CsvTransformer<Chapter> {
  get filename () {
    return `open-category-report-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'Chapter',
      valueTransformer: row => String(row.name),
    })

    this.addColumn({
      header: 'President',
      valueTransformer: row => {
        const president = row.getMemberForPosition('President', BOARD_MEMBER_TYPE)
        if (president) {
          return president.member.fullName
        } else {
          return '---'
        }
      },
    })

    this.addColumn({
      header: 'Email',
      valueTransformer: row => {
        const president = row.getMemberForPosition('President', BOARD_MEMBER_TYPE)
        return safeNull(() => president!.member.emailAddress) || '---'
      },
    })

    this.addColumn({
      header: 'Phone',
      valueTransformer: row => {
        const president = row.getMemberForPosition('President', BOARD_MEMBER_TYPE)
        return safeNull(() => president!.member.phoneNumber) || '---'
      },
    })

    this.addColumn({
      header: 'Meeting Day',
      valueTransformer: row => safeNull(() => row.meetingDay) || '',
    })

    this.addColumn({
      header: 'Meeting Time',
      valueTransformer: row => safeNull(() => row.meetingTime) || '',
    })

    this.addColumn({
      header: 'Meeting Location',
      valueTransformer: row => safeNull(() => row.meetingLocationDescription) || '',
    })

    this.addColumn({
      header: 'Meeting Address',
      valueTransformer: row => safeNull(() => row.meetingLocationAddress!.getFullAddress()) || '',
    })
  }
}
