import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import FormState from "../../../common/FormState"
import { observable } from "mobx"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"
import { loadChapterOptions, loadChapterOptionsForAdmin } from "../../../api/AsyncHelpers"
import moment, { Moment } from 'moment-timezone'
import { Button } from "reactstrap"
import { BarLoader } from "react-spinners"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import { route } from "../../../routes/routes"
import Util, { formatNumber, modelToCamelCase, modelToSnakeCase } from "../../../common/Util"
import * as _ from "lodash"

type ReportData = {
  member: {
    id: number
    fullName: string
    status: string
    categoryName: string
  }

  tipData: {
    sent: number,
    inside: number,
    outside: number,
    socialmedia: number,
    interchapter: number,
  }
}

type Props = {
  chapterId?: number
  filterForAdmin?: boolean
}

@observer
export default class ChapterTipInsideOutsideReport extends React.Component<Props> {
  @observable private loading = false
  @observable private error?: string
  @observable private formState = new FormState({
    chapterId: undefined,
    startDate: moment().startOf('month').format('MM/DD/YYYY'),
    endDate: moment().endOf('month').format('MM/DD/YYYY'),
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable chapterData?: { id: number, name: string }
  @observable reportData?: ReportData[]
  @observable startDate?: Moment
  @observable endDate?: Moment

  componentDidMount (): void {
    if (this.props.chapterId) {
      this.formState.set('chapterId', this.props.chapterId)
    }
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.chapterId != this.props.chapterId) {
      this.formState.set('chapterId', this.props.chapterId)
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.error = undefined
    this.loading = true

    ApiClient.getInstance()
      .post(route(ApiRoutes.reporting.chapterTipInsideOutsideReport, { chapterId: this.formState.get('chapterId') }), modelToSnakeCase(this.formState.toObject()))
      .then(response => {
        this.chapterData = modelToCamelCase(response.data.chapter) as any
        this.reportData = response.data.report_data.map((rd: any) => modelToCamelCase(rd))
        this.startDate = moment(response.data.start_date);
        this.endDate = moment(response.data.end_date)
      })
      .catch(error => this.error = Util.extractErrorMessage(error.response))
      .then(() => this.loading = false)
  }

  render () {
    return <>
      <div className="print-hide">
        <form action="" method="post" onSubmit={this.submit}>
          <div className="row align-items-end">
            {
              !this.props.chapterId
                ? <div className="col-md-4">
                  {this.formHelper.renderAsyncSelectInput({
                    label: 'Chapter',
                    name: 'chapterId',
                    loadOptions: this.props.filterForAdmin ? loadChapterOptionsForAdmin : loadChapterOptions,
                  })}
                </div>
                : null
            }
            <div className="col-md-3">
              {this.formHelper.renderDatePickerInput({
                label: 'Start Date',
                name: 'startDate',
              })}
            </div>
            <div className="col-md-3">
              {this.formHelper.renderDatePickerInput({
                label: 'End Date',
                name: 'endDate',
              })}
            </div>
            <div className="col-md-2 d-flex">
              <div className="form-group d-flex flex-column">
                <div className="flex-fill"/>
                <div>
                  <Button
                    disabled={this.loading || !this.formState.get('chapterId') || !this.formState.get('startDate').length || !this.formState.get('endDate').length}
                    style={{
                      height: 'calc(2.95rem + 2px)'
                    }}
                    type="submit"
                    color="success"
                  ><i className="fa fa-play"/> Run Report</Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {
        this.loading
          ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
          : this.error
          ? <div className="alert alert-danger">{this.error}</div>
          : this.reportData
            ? this.renderReport()
            : <p>Choose report parameters above</p>
      }
    </>
  }

  private renderReport () {
    const chapter = this.chapterData!
    const reportData = this.reportData!
    const startDate = this.startDate!
    const endDate = this.endDate!

    const activeMembers = reportData.filter(rd => rd.member.status === 'Active')
    const inactiveMembers = reportData.filter(rd => rd.member.status !== 'Active')

    return <>
      <h1 className="text-center my-4"> Chapter Tip Report For {chapter.name}</h1>
      <h3 className="text-center mb-4">Tip Inside/Outside - {startDate.format('MMMM D, YYYY')} - {endDate.format('MMMM D, YYYY')}</h3>
      <div className="form-buttons mb-4 print-hide">
        <Button
          type="button"
          color="primary"
          onClick={() => window.print()}
        ><i className="fa fa-print"/> Print Report</Button>
      </div>
      <table className="chapter-tip-report-table">
        <thead>
        <tr>
          <th/>
          <th className="border-left border-top border-bottom">Member Name</th>
          <th className="border-top border-bottom text-right">Category</th>
          <th className="border-left border-top border-bottom">Passed</th>
          <th className="border-top border-bottom">Inside</th>
          <th className="border-top border-bottom">Outside</th>
          <th className="border-top border-bottom">Inter Chapter</th>
          <th className="border-top border-bottom">Social Media</th>
          <th className="border-top border-bottom">% Outside</th>
        </tr>
        </thead>
        <tbody>
        {
          activeMembers.map((memberTipData, index) => <tr key={memberTipData.member.id}>
            <td className="text-right" style={{ width: 1, whiteSpace: 'nowrap' }}>{index + 1}</td>
            <td style={{ width: 1, whiteSpace: 'nowrap' }}>
              {memberTipData.member.fullName}
            </td>
            <td className="text-right" style={{ width: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{memberTipData.member.categoryName}</td>
            <td className="text-center">{formatNumber(memberTipData.tipData.inside + memberTipData.tipData.outside + memberTipData.tipData.socialmedia + memberTipData.tipData.interchapter)}</td>
            <td className="text-center">{formatNumber(memberTipData.tipData.inside)}</td>
            <td className="text-center">{formatNumber(memberTipData.tipData.outside)}</td>
            <td className="text-center">{formatNumber(memberTipData.tipData.interchapter)}</td>
            <td className="text-center">{formatNumber(memberTipData.tipData.socialmedia)}</td>
            <td className="text-center">{formatNumber((memberTipData.tipData.outside + memberTipData.tipData.socialmedia + memberTipData.tipData.interchapter) / (memberTipData.tipData.inside + memberTipData.tipData.outside + memberTipData.tipData.socialmedia + memberTipData.tipData.interchapter) * 100)}%</td>
          </tr>)
        }
        <tr className="border-top">
          <td/>
          <td colSpan={2}>Total Active Member Tips</td>
          <td className="text-center">{formatNumber((_.sumBy(activeMembers, m => m.tipData.inside) + _.sumBy(activeMembers, m => m.tipData.outside) + _.sumBy(activeMembers, m => m.tipData.socialmedia) + _.sumBy(activeMembers, m => m.tipData.interchapter)))}</td>
          <td className="text-center">{formatNumber(_.sumBy(activeMembers, m => m.tipData.inside))}</td>
          <td className="text-center">{formatNumber((_.sumBy(activeMembers, m => m.tipData.outside)))}</td>
          <td className="text-center">{formatNumber((_.sumBy(activeMembers, m => m.tipData.interchapter)))}</td>
          <td className="text-center">{formatNumber(_.sumBy(activeMembers, m => m.tipData.socialmedia))}</td>
          <td className="text-center">{formatNumber((_.sumBy(activeMembers, m => m.tipData.outside) + _.sumBy(activeMembers, m => m.tipData.socialmedia) + _.sumBy(activeMembers, m => m.tipData.interchapter)) / ((_.sumBy(activeMembers, m => m.tipData.inside) + _.sumBy(activeMembers, m => m.tipData.outside) + _.sumBy(activeMembers, m => m.tipData.socialmedia) + _.sumBy(activeMembers, m => m.tipData.interchapter))) * 100)}%</td>
        </tr>
        {
          inactiveMembers.length
            ? <>
              <tr>
                <td colSpan={14}>&nbsp;</td>
              </tr>
              <tr className="border-bottom">
                <td colSpan={14}>Non-Active Members</td>
              </tr>
              {
                inactiveMembers.map((memberTipData, index) => <tr key={memberTipData.member.id}>
                  <td className="text-right" style={{ width: 1, whiteSpace: 'nowrap' }}>{index + 1}</td>
                  <td style={{ width: 1, whiteSpace: 'nowrap' }}>
                    {memberTipData.member.fullName}
                  </td>
                  <td className="text-right" style={{ width: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{memberTipData.member.categoryName}</td>
                  <td className="text-center">{formatNumber(memberTipData.tipData.inside + memberTipData.tipData.outside + memberTipData.tipData.socialmedia + memberTipData.tipData.interchapter)}</td>
                  <td className="text-center">{formatNumber(memberTipData.tipData.inside)}</td>
                  <td className="text-center">{formatNumber((memberTipData.tipData.outside))}</td>
                  <td className="text-center">{formatNumber((memberTipData.tipData.interchapter))}</td>
                  <td className="text-center">{formatNumber(memberTipData.tipData.socialmedia)}</td>
                  <td className="text-center">{formatNumber((memberTipData.tipData.outside + memberTipData.tipData.socialmedia + memberTipData.tipData.interchapter) / (memberTipData.tipData.inside + memberTipData.tipData.outside + memberTipData.tipData.socialmedia + memberTipData.tipData.interchapter) * 100)}%</td>
                </tr>)
              }
              <tr className="border-top">
                <td/>
                <td colSpan={2}>Total Inactive Member Tips</td>
                <td className="text-center">{formatNumber((_.sumBy(inactiveMembers, m => m.tipData.inside) + _.sumBy(inactiveMembers, m => m.tipData.outside) + _.sumBy(inactiveMembers, m => m.tipData.socialmedia) + _.sumBy(inactiveMembers, m => m.tipData.interchapter)))}</td>
                <td className="text-center">{formatNumber(_.sumBy(inactiveMembers, m => m.tipData.inside))}</td>
                <td className="text-center">{formatNumber((_.sumBy(inactiveMembers, m => m.tipData.outside)))}</td>
                <td className="text-center">{formatNumber((_.sumBy(inactiveMembers, m => m.tipData.interchapter)))}</td>
                <td className="text-center">{formatNumber(_.sumBy(inactiveMembers, m => m.tipData.socialmedia))}</td>
                <td className="text-center">{formatNumber((_.sumBy(inactiveMembers, m => m.tipData.outside) + _.sumBy(inactiveMembers, m => m.tipData.socialmedia) +  _.sumBy(inactiveMembers, m => m.tipData.interchapter)) /((_.sumBy(inactiveMembers, m => m.tipData.inside) + _.sumBy(inactiveMembers, m => m.tipData.outside) + _.sumBy(inactiveMembers, m => m.tipData.socialmedia) + _.sumBy(activeMembers, m => m.tipData.interchapter)))*100)}%</td>
              </tr>
            </>
            : null
        }
        </tbody>
      </table>
    </>
  }
}