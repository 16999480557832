import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { BarLoader } from "react-spinners"
import { observable } from "mobx"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import AppStateStore from "../stores/AppStateStore"
import Util, { logException, modelToCamelCase, modelToSnakeCase, safeNull } from "../common/Util"
import BadgeShippingRequest from '../models/BadgeShippingRequest'
import FormState from '../common/FormState'
import ErrorBag from '../common/ErrorBag'
import FormHelper from '../forms/FormHelper'
import { route } from '../routes/routes'
import _ from 'lodash'
import Config from '../common/Config'
import ButtonLoader from './ButtonLoader'
import { toast } from 'react-toastify'

type EditShippingRequestModalProps = {
  badgeShippingRequestId: number
  onClosed: () => void
  onSaved: () => void
}

type ShippingOption = {
  value: string
  text: string

  address?: {
    name: string
    address: string
    address2: string
    city: string
    state: string
    zipCode: string
    country: string
  }
}

@observer
export class EditShippingRequestModal extends React.Component<EditShippingRequestModalProps> {
  @observable private badgeShippingRequest?: BadgeShippingRequest

  @observable isOpen = true

  @observable
  private formState = new FormState({
    badgeColor: '',
    shippingOption: 'other',
    businessName: '',
    name: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    shippingNotes: '',
  })

  @observable
  private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable
  private submitting = false

  @observable private shippingOptions: ShippingOption[] = [
    {
      value: 'other',
      text: 'Other',
    },
  ]

  @observable selectedShippingOption?: string = 'other'

  componentDidMount (): void {
    this.loadShippingRequest()
  }

  private loadShippingOptions = (memberId: number) => {
    ApiClient.getInstance().get(route(ApiRoutes.members.shippingRecipients, { id: memberId }))
      .then(response => {
        const recipients: ShippingOption[] = response.data.shipping_recipients.map((r: any) => modelToCamelCase(r))

        this.shippingOptions = recipients.concat({
          value: 'other',
          text: 'Other',
        })
      })
  }

  private loadShippingRequest = () => {
    if (!this.props.badgeShippingRequestId) {
      return
    }

    ApiClient.query(`
    badgeShippingRequest {
      *
      
      badgeShippingAddress {
        *
      }
    }
    `, {
      where: [{ id: this.props.badgeShippingRequestId }]
    }).then(response => {
      this.badgeShippingRequest = new BadgeShippingRequest().init(response.data.badgeShippingRequest)
      this.loadShippingOptions(this.badgeShippingRequest.memberId)

      this.formState.setAll({
        name: safeNull(() => this.badgeShippingRequest!.badgeShippingAddress!.name),
        businessName: safeNull(() => this.badgeShippingRequest!.badgeShippingAddress!.businessName),
        address: safeNull(() => this.badgeShippingRequest!.badgeShippingAddress!.address),
        address2: safeNull(() => this.badgeShippingRequest!.badgeShippingAddress!.address2),
        city: safeNull(() => this.badgeShippingRequest!.badgeShippingAddress!.city),
        state: safeNull(() => this.badgeShippingRequest!.badgeShippingAddress!.state),
        zipCode: safeNull(() => this.badgeShippingRequest!.badgeShippingAddress!.zipCode),
        country: safeNull(() => this.badgeShippingRequest!.badgeShippingAddress!.country),
        shippingNotes: this.badgeShippingRequest.shippingNotes
      })
    })
      .catch(error => {
        AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response))
      })
  }

  private toggle = () => {
    this.isOpen = false
  }

  private handleShippingOptionChanged = (value: string) => {
    const option = _.find(this.shippingOptions, o => o.value === value)

    if (option && option.address) {
      this.formState.setAll(option.address)
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.getInstance().post(route(ApiRoutes.shippingRequests.update, { id: this.badgeShippingRequest!.id }), modelToSnakeCase(this.formHelper.toObject()))
      .then(() => {
        toast.success('Shipping Request Updated')
        this.toggle()
        this.props.onSaved()
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private renderForm = () => {
    return <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      <div className="form-row">
        <div className="col-sm-12">
          {this.formHelper.renderSelectInput({
            name: 'shippingOption',
            options: this.shippingOptions,
            label: 'Ship To',
            onChange: this.handleShippingOptionChanged,
          })}
        </div>
      </div>
      {
        this.formState.get('shippingOption')
          ? <>
            <div className="form-row">
              <div className="col-sm-12">
                {this.formHelper.renderTextInput({
                  name: 'name',
                  type: 'text',
                  label: 'Ship To Name',
                  disabled: this.formState.get('shippingOption') !== 'other'
                })}
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-12">
                {this.formHelper.renderTextInput({
                  name: 'businessName',
                  type: 'text',
                  label: 'Business Name',
                  disabled: this.formState.get('shippingOption') !== 'other'
                })}
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-8">
                {this.formHelper.renderTextInput({
                  name: 'address',
                  type: 'text',
                  label: 'Street',
                  disabled: this.formState.get('shippingOption') !== 'other'
                })}
              </div>
              <div className="col-sm-4">
                {this.formHelper.renderTextInput({
                  name: 'address2',
                  type: 'text',
                  label: 'Suite / Room / Apt #',
                  disabled: this.formState.get('shippingOption') !== 'other'
                })}
              </div>
            </div>

            <div className="form-row">
              <div className="col-sm-3">
                {this.formHelper.renderTextInput({
                  name: 'city',
                  type: 'text',
                  label: 'City',
                  disabled: this.formState.get('shippingOption') !== 'other'
                })}
              </div>
              <div className="col-sm-3">
                {this.formHelper.renderTextInput({
                  name: 'state',
                  type: 'text',
                  label: 'State',
                  disabled: this.formState.get('shippingOption') !== 'other'
                })}
              </div>
              <div className="col-sm-3">
                {this.formHelper.renderTextInput({
                  name: 'zipCode',
                  type: 'text',
                  label: 'Zip Code',
                  disabled: this.formState.get('shippingOption') !== 'other'
                })}
              </div>
              <div className="col-sm-3">
                {this.formHelper.renderSelectInput({
                  name: 'country',
                  options: Config.COUNTRY_OPTIONS.map(c => ({ value: c, text: c })),
                  label: 'Country',
                  disabled: this.formState.get('shippingOption') !== 'other'
                })}
              </div>
            </div>
          </>
          : null
      }
      <div className="form-row">
        <div className="col-md-12">
          {this.formHelper.renderTextAreaInput({
            label: 'Shipping Notes',
            name: 'shippingNotes',
          })}
        </div>
      </div>
    </form>
  }

  render (): React.ReactNode {
    return <Modal
      isOpen={this.isOpen}
      toggle={this.toggle}
      onClosed={this.props.onClosed}
      size="lg"
    >
      <ModalHeader toggle={this.toggle}>
        Edit Shipping Request
      </ModalHeader>
      <ModalBody>
        {
          this.badgeShippingRequest
            ? this.renderForm()
            : <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
        }
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="secondary"
          onClick={this.toggle}
        >Cancel</Button>
        <ButtonLoader
          loading={this.submitting}
          type="button"
          color="success"
          onClick={this.submit}
        >Save Changes</ButtonLoader>
      </ModalFooter>
    </Modal>
  }
}
