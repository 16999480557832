import BaseView from "../BaseView"
import { RouteComponentProps, withRouter } from "react-router"
import qs from 'query-string'
import { observable } from "mobx"
import { observer } from "mobx-react"
import { BarLoader } from "react-spinners"
import * as React from "react"
import * as _ from "lodash"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import { route } from "../../routes/routes"
import Util from "../../common/Util"
import { Routes } from "../../routes/AppRoutes"
import { Link } from "react-router-dom"

@observer
class LegacyUrlHandlerView extends BaseView<RouteComponentProps> {
  @observable private error?: React.ReactNode

  componentDidMount (): void {
    const query = qs.parse(this.props.location.search)

    let matched = false

    if (query.redirect === '/m/person/renewal') {
      const key = query.key

      if (key && _.isString(key)) {
        this.handleLegacyInvoicePayment(key as string)
        matched = true
      }
    }

    if (!matched) {
      this.error = 'Page Not Found'
    }
  }

  private handleLegacyInvoicePayment (key: string) {
    ApiClient.getInstance()
      .get(route(ApiRoutes.legacy.payLink, {}, {
        key: key
      }))
      .then(response => {
        if (response.data.status === 'redirect') {
          window.location.href = response.data.redirect_url
        }
      })
      .catch(error => this.error = error.response.status === 404 ? <div className="text-center"><h4>It doesn't look like you have any unpaid invoices!</h4><p>Please <Link to={route(Routes.login)}>click here to log in</Link> and review your account.</p></div> : <div className="alert alert-danger">{Util.extractErrorMessage(error.response)}</div>)
  }

  renderContentBody (): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return this.error
      ? this.error
      : <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
  }
}

export default withRouter(LegacyUrlHandlerView)