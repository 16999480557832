import { observer } from 'mobx-react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import React from 'react'
import { observable, toJS } from 'mobx'
import Util from '../../common/Util'
import ApiClient, { ApiRoutes } from '../../api/ApiClient'
import { route } from '../../routes/routes'
import LoadingPanel from '../LoadingPanel'
import _ from 'lodash'

type TotalMembersData = {
  totalMembersCount: {
    day: number
    month: number
    year: number
  }
}

type Props = {
  filters: {
    state?: string
    areaId?: number
    chapterId?: number
  }
  onShowMemberList: (url: string, title: string) => void
}

@observer
export default class TotalMembersDashboard extends React.Component<Props> {
  @observable
  private totalMembers?: TotalMembersData
  @observable
  private error?: string
  @observable
  private loading = false

  componentDidMount () {
    this.loadData().then()
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (!_.isEqual(toJS(prevProps.filters), toJS(this.props.filters))) {
      this.loadData().then()
    }
  }

  private loadData = async () => {
    try {
      this.error = undefined
      this.loading = true

      const response = await ApiClient.getInstance().get(route(ApiRoutes.dashboard.totalMembers, {}, this.props.filters))

      this.totalMembers = response.data

      this.loading = false
    } catch (err) {
      this.error = Util.extractErrorMessage(err.response)
    }
  }

  render () {
    return <Card>
      <CardHeader>
        <b>Total Members</b>
      </CardHeader>
      <CardBody>
        <LoadingPanel loading={this.loading} loaded={!!this.totalMembers} error={this.error}>
          {
            this.totalMembers
              ? <>
                <div className="d-flex">
                  <div className="flex-fill">
                    Today
                  </div>
                  <div className="text-right">
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.props.onShowMemberList(route(ApiRoutes.dashboard.totalMembers, {}, {
                        ...this.props.filters,
                        list: 'day',
                      }), 'Total Members Today')
                    }}>
                      {Util.formatNumber(this.totalMembers!.totalMembersCount.day)}
                    </a>
                  </div>
                </div>
                {/*<div className="d-flex">*/}
                {/*  <div className="flex-fill">*/}
                {/*    End of Last Month*/}
                {/*  </div>*/}
                {/*  <div className="text-right">*/}
                {/*    <a href="" onClick={ev => {*/}
                {/*      ev.preventDefault()*/}
                {/*      this.props.onShowMemberList(route(ApiRoutes.dashboard.totalMembers, {}, {*/}
                {/*        ...this.props.filters,*/}
                {/*        list: 'month',*/}
                {/*      }), 'Total Members End of Last Month')*/}
                {/*    }}>*/}
                {/*      {Util.formatNumber(this.totalMembers!.totalMembersCount.month)}*/}
                {/*    </a>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="d-flex">
                  <div className="flex-fill">
                    End of Last Year
                  </div>
                  <div className="text-right">
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.props.onShowMemberList(route(ApiRoutes.dashboard.totalMembers, {}, {
                        ...this.props.filters,
                        list: 'year',
                      }), 'Total Members End of Last Year')
                    }}>
                      {Util.formatNumber(this.totalMembers!.totalMembersCount.year)}
                    </a>
                  </div>
                </div>
              </>
              : null
          }
        </LoadingPanel>
      </CardBody>
    </Card>
  }
}
