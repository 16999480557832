import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import SystemMessageTemplate from "./SystemMessageTemplate"
import SentSystemMessageEvent from "./SentSystemMessageEvent"
import Guest from "./Guest"
import Member from "./Member"
import Invoice from "./Invoice"

export default class SentSystemMessage extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  channel: string
  platform: string
  messageId: string
  subject: string
  recipient: string
  data: any
  meta: any
  status: string
  systemMessageTemplateId: number
  renderedOutput?: string
  scheduledAt: Moment | null

  systemMessageTemplate: SystemMessageTemplate
  sentSystemMessageEvents: SentSystemMessageEvent[]
  members: Member[]
  guests: Guest[]
  invoices: Invoice[]

  constructor (data?: {}) {
    super()

    data && this.init(data)

    if (!this.sentSystemMessageEvents) {
      this.sentSystemMessageEvents = []
    }

    if (!this.members) {
      this.members = []
    }

    if (!this.guests) {
      this.guests = []
    }

    if (!this.invoices) {
      this.invoices = []
    }
  }

  setSystemMessageTemplate (t?: {}) {
    return t ? new SystemMessageTemplate().init(t) : null
  }

  setSentSystemMessageEvents (t?: []) {
    return t ? t.map(tt => new SentSystemMessageEvent().init(tt)) : []
  }

  setMembers (t?: []) {
    return t ? t.map(tt => new Member().init(tt)) : []
  }

  setGuests (t?: []) {
    return t ? t.map(tt => new Guest().init(tt)) : []
  }

  setInvoices (t?: []) {
    return t ? t.map(tt => new Invoice().init(tt)) : []
  }

  getCasts () {
    return {
      createdAt: 'datetime',
      updatedAt: 'datetime',
      scheduledAt: 'datetime',
      systemMessageTemplateId: 'number',
    }
  }

  extractTo = () => {
    const to: string[] = []

    if (this.channel === 'sms') {
      to.push(this.meta.to)
    } else if (this.channel === 'email') {
      this.meta.to.forEach((t: any) => {
        to.push(t.name ? `${t.name} <${t.email}>` : t.email)
      })
    }

    return to
  }

  extractFrom = () => {
    if (this.channel === 'sms') {
      return this.platform
    } else if (this.channel === 'email') {
      return this.meta.from
        ? (this.meta.from.name ? `${this.meta.from.name} <${this.meta.from.email}>` : this.meta.from.email)
        : null
    }
  }
}
