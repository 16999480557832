import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { computed, observable } from "mobx"
import Transaction, { TransactionType } from "../../../models/Transaction"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import Util, { formatCurrency } from "../../../common/Util"
import { BarLoader } from "react-spinners"
import ButtonLoader from "../../ButtonLoader"
import AppStateStore from "../../../stores/AppStateStore"
import { route } from "../../../routes/routes"
import { toast } from "react-toastify"
import FormState from "../../../common/FormState"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"

type TransactionDetailModalProps = {
  isOpen: boolean
  toggle: () => void
  transactionId: number
  onClosed: () => void
  onUpdated: () => void
}

@observer
export default class TransactionDetailModal extends React.Component<TransactionDetailModalProps> {
  @observable private loading = false
  @observable private error?: string
  @observable private transaction?: Transaction
  @observable private relatedTransactions: Transaction[] = []

  @computed get refundableAmount () {
    if (!this.transaction || !this.transaction.isRefundable) {
      return 0
    }

    const transaction = this.transaction

    let amount = this.transaction.amount

    this.relatedTransactions.forEach(t => {
      if (t.parentTransactionId === transaction.id && [TransactionType.Refund, TransactionType.Void].indexOf(t.transactionType) > -1) {
        amount -= t.amount
      }
    })

    return amount
  }

  componentDidMount (): void {
    this.loadTransaction()
  }

  componentDidUpdate (prevProps: Readonly<TransactionDetailModalProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.transactionId !== this.props.transactionId) {
      this.loadTransaction()
    }
  }

  private loadTransaction = async () => {
    this.loading = true
    this.error = undefined

    try {
      const transaction = await ApiClient.query(`
    transaction {
      *
      member {
        *
      }
      invoice {
        *
      }
      paymentMethod {
        *
      }
    }
    `, {
        where: [{ id: this.props.transactionId }],
      }).then(response => new Transaction().init(response.data.transaction))

      const relatedTransactions = await ApiClient.query(`
    transactions {
      *
      invoice {
        *
      }
      
      paymentMethod {
        *
      }
    }
    `, {
        where: [{ _scope: 'related', value: transaction.parentTransactionId || transaction.id }],
        order: ['createdAt'],
      }).then(response => response.data.transactions.map((t: any) => new Transaction().init(t)))

      this.transaction = transaction
      this.relatedTransactions = relatedTransactions
    } catch (err) {
      this.error = Util.extractErrorMessage(err.response)
    }

    this.loading = false
  }

  private renderDetail = (transaction: Transaction, relatedTransactions: Transaction[]) => {
    return <>
      <div className="row">
        <div className="col-6">
          <b>Transaction ID</b><br/>
          {transaction.id}
        </div>
        <div className="col-6">
          <b>Date</b><br/>
          {transaction.createdAt.format('MM/DD/YYYY hh:mm a')}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <b>Type</b><br/>
          {transaction.transactionType}
        </div>
        <div className="col-6">
          <b>Transaction Ref</b><br/>
          {transaction.provider} {transaction.externalTransactionId}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <b>Payment Type</b><br/>
          {transaction.paymentType}
        </div>
        <div className="col-6">
          <b>Payment Method</b><br/>
          {transaction.paymentMethod ? transaction.paymentMethod.description : '---'}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <b>Memo</b><br/>
          {transaction.memo}
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <b>Status</b><br/>
          {transaction.status}
        </div>
        <div className="col-6">
          <b>Amount</b><br/>
          {formatCurrency(transaction.amount, true)}
        </div>
      </div>
      {
        relatedTransactions.length
          ? <>
            <h6 className="text-muted mt-4">Related Transactions</h6>
            <table className="table table-striped">
              <thead>
              <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Description</th>
                <th className="text-right">Amount</th>
                <th>Type</th>
                <th>Status</th>
                <th>Ref</th>
              </tr>
              </thead>
              <tbody>
              {
                relatedTransactions.map(relatedTransaction => <tr key={relatedTransaction.id}>
                  <td>{relatedTransaction.id}</td>
                  <td>{relatedTransaction.createdAt.format('MM/DD/YYYY hh:mm a')}</td>
                  <td>{relatedTransaction.description}</td>
                  <td className="text-right">{formatCurrency(relatedTransaction.amount, true)}</td>
                  <td>{relatedTransaction.transactionType}</td>
                  <td>{relatedTransaction.status}</td>
                  <td>{relatedTransaction.provider} {relatedTransaction.externalTransactionId}</td>
                </tr>)
              }
              </tbody>
            </table>
          </>
          : null
      }
    </>
  }

  private renderFooter = () => {
    const buttons: React.ReactNode[] = []

    if (this.refundableAmount > 0) {
      // can still refund some of the transaction
      buttons.push(<Button key={'refund'} type="button" color="primary" onClick={this.refundTransaction}>Issue Refund</Button>)
    }

    buttons.push(<Button key={'close'} type="button" color="secondary" onClick={this.props.toggle}>Close</Button>)

    return buttons
  }

  private refundTransaction = () => {
    this.refundFormState.set('amount', '')
    this.showRefundModal = true
  }

  @observable private refundFormState = new FormState({
    amount: '',
  })
  @observable private refundFormErrors = new ErrorBag()
  private refundFormHelper = new FormHelper(this.refundFormState, this.refundFormErrors)

  @observable private showRefundModal = false
  @observable private submittingRefund = false

  private submitRefund = (voidTransaction?: boolean) => {
    this.submittingRefund = true
    this.refundFormErrors.clearErrors()

    AppStateStore.showModalSpinner()
    ApiClient.getInstance().post(route(ApiRoutes.transactions.issueRefund, { transactionId: this.transaction!.id }), { amount: this.refundFormState.get('amount'), void: !!voidTransaction })
      .then(() => {
        toast.success('Refund issued')
        this.loadTransaction()
        this.props.onUpdated()
        this.showRefundModal = false
      })
      .catch(error => {
        if (error.response.status === 444) {
          this.showRefundModal = false
          AppStateStore.showConfirmationModal('Refund Failed', 'This transaction has not settled so it cannot be refunded. It can only be voided in full. Would you like to void this transaction?', (result, modal) => {
            modal.hide()
            if (result) {
              this.submitRefund(true)
            }
          })
        } else {
          Util.handleErrorResponse(error.response, this.refundFormErrors, undefined, (response, message) => {
            AppStateStore.showAlertModal('Error', message, m => {
              m.hide()
            })
            return true
          })
        }
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submittingRefund = false
      })
  }

  render () {
    return <>
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} onClosed={this.props.onClosed} size="lg">
        <ModalHeader toggle={this.props.toggle}>
          Transaction Detail
        </ModalHeader>
        <ModalBody>
          {
            this.error
              ? <div className="alert alert-danger">{this.error}</div>
              : (this.loading || !this.transaction)
              ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
              : this.renderDetail(this.transaction, this.relatedTransactions)
          }
        </ModalBody>
        <ModalFooter>
          {this.renderFooter()}
        </ModalFooter>
      </Modal>

      <Modal isOpen={this.showRefundModal} toggle={() => this.showRefundModal = false}>
        <ModalHeader toggle={() => this.showRefundModal = false}>Issue Refund</ModalHeader>
        <ModalBody>
          <form onSubmit={ev => {
            ev.preventDefault()
            this.submitRefund()
          }}>
            {
              this.refundFormHelper.renderTextInput({
                name: 'amount',
                label: 'Refund Amount',
                prepend: '$',
              })
            }
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.showRefundModal = false}>Cancel</Button>
          <ButtonLoader type="button" color="primary" loading={this.submittingRefund} onClick={(ev: SyntheticEvent) => {
            ev.preventDefault()
            this.submitRefund()
          }}>Submit</ButtonLoader>
        </ModalFooter>
      </Modal>
    </>
  }
}