import * as React from "react"
import { SyntheticEvent } from "react"
import AuthStore from "../stores/AuthStore"
import { observable } from "mobx"
import { RouteComponentProps } from "react-router"
import { observer } from "mobx-react"
import * as _ from 'lodash'
import FormState from "../common/FormState"
import ApiClient from "../api/ApiClient"
import ErrorBag from "../common/ErrorBag"
import Util, { logException, modelToCamelCase, publicPath } from "../common/Util"
import classNames from "classnames"
import AppStateStore from "../stores/AppStateStore"
import AuthUser from "../models/AuthUser"
import ButtonLoader from "../components/ButtonLoader"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import * as path from 'path'
import { Link } from "react-router-dom"
import OlarkWidget from "../olark/OlarkWidget"
import Config from "../common/Config"

type LoginFormProps = {
  username: string
  password: string
  remember: boolean
}

@observer
export default class LoginView extends React.Component<RouteComponentProps> {
  @observable
  private formData = new FormState<LoginFormProps>({
    username: '',
    password: '',
    remember: false,
  })

  @observable
  private submitting = false
  @observable
  private formErrors = new ErrorBag()

  private usernameFieldRef = React.createRef<HTMLInputElement>()

  componentDidMount (): void {
    document.body.classList.add('login')
    if (this.usernameFieldRef.current) {
      this.usernameFieldRef.current.focus()
    }
  }

  componentWillUnmount (): void {
    document.body.classList.remove('login')
  }

  public render () {
    const formData = this.formData

    return <>
      <div>
        <div className="login-container">
          <div className="logo">
            <img src={publicPath('img/logo-login.png')} alt="LeTip Wired"/>
          </div>
          <div className="login-box">
            <div className="login-header">
              <h2>Secure Member Sign In</h2>
            </div>
            <div className="login-body">
              <p className="text-center">Welcome To LeTipWIRED™</p>
              <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
                {renderInput('username', 'text', 'Username', 'la-user', this.formData, this.formErrors, this.usernameFieldRef)}
                {renderInput('password', 'password', 'Password', 'la-lock', this.formData, this.formErrors)}
                <div className="form-group">
                  <div className="form-check form-checkbox">
                    <label>
                      <input type="checkbox"
                             className="form-check-input"
                             name="remember"
                             checked={formData.get('remember')}
                             onChange={formData.onChange}
                      />
                      <span className="label-text">Remember me</span>
                    </label>
                  </div>
                </div>
                <ButtonLoader type="submit" color="primary" block={true} loading={this.submitting}>Sign
                  In</ButtonLoader>
                <div className="text-center login-action" style={{ marginTop: 20 }}>
                  <Link to={route(Routes.requestPasswordReset)}>I forgot my password</Link>
                </div>
              </form>
            </div>
          </div>
          <div className="letip-logo text-center">
            <img src={publicPath('img/letip-logo.png')} alt="LeTip"/>
          </div>
          <div className="login-footer text-center">&copy; {new Date().getFullYear()} LeTip International, Inc. All
            Rights Reserved
            <br/>
            <a target="_blank" href="https://letip.com/terms-of-use/">Terms of Use</a> | <a target="_blank" href="https://letip.com/privacy-policy/">Privacy Policy</a>
          </div>
        </div>
      </div>
      {
        Config.OLARK_ID
          ? <div className="login-help-widget">
            <OlarkWidget
              siteId={Config.OLARK_ID}
            />
          </div>
          : null
      }

    </>
  }

  private getReferer = () => {
    if (AuthStore.didLogOut) {
      AuthStore.clearDidLogOut()
      return Routes.index
    } else {
      const u = _.get(this.props, 'location.state.from.pathname', route(Routes.index))
      return path.normalize(u)
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    if (this.submitting) {
      return
    }

    this.formErrors.clearErrors()
    AppStateStore.showModalSpinner()
    this.submitting = true

    ApiClient.authenticate(this.formData.get('username'), this.formData.get('password'))
      .then(response => {
        AuthStore.login(response.data.auth_key, new AuthUser(modelToCamelCase(response.data.user)), this.formData.get('remember'))
        window.scrollTo(0, 0)
        this.props.history.push(this.getReferer())
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, response => {
          if (response.status === 401) {
            errors.addError('username', response.data.message || 'The login you have entered is invalid')
            return true
          }

          return false
        }, (response, message) => {
          errors.addError('username', message)
          return true
        })

        this.formErrors = errors
      })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('username', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }
}

function renderInput (name: keyof LoginFormProps, type: string, placeholder: string, icon: string, formState: FormState<LoginFormProps>, formErrors: ErrorBag, ref?: React.RefObject<HTMLInputElement>) {
  return (
    <div className="form-group">
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className={classNames('la', icon)}/>
          </span>
        </div>
        <input type={type}
               className={classNames('form-control', { 'is-invalid': formErrors.hasErrors(name) })}
               placeholder={placeholder}
               name={name}
               value={formState.get(name)}
               onChange={formState.onChange}
               ref={ref}
        />
        {formErrors.hasErrors(name) && <div className="invalid-feedback">{formErrors.getError(name)}</div>}
      </div>
    </div>
  )
}
