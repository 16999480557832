import { Button, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import * as React from "react"
import { observer } from "mobx-react"
import { computed, observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import AppStateStore from "../stores/AppStateStore"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { toast } from "react-toastify"
import { route } from "../routes/routes"
import Util, { formatCurrency, joinStringsAnd, modelToCamelCase, modelToSnakeCase } from "../common/Util"
import Application from "../models/Application"
import { can, Permission } from './RequirePermission'
import classNames from 'classnames'
import CreditCardInput, { CardInfo } from './inputs/CreditCardInput'
import FormError from './FormError'
import { AcceptJsClient, BankAccountDataType, loadAcceptJs } from '../common/AuthNet'
import * as _ from 'lodash'

type ApproveApplicationModalProps = {
  isOpen: boolean
  toggle: () => void
} & ApproveApplicationFormProps

type PaymentPlanData = {
  initialAmount: number
  fee: number
  intervals: number[]
}

@observer
export class ApproveApplicationModal extends React.Component<ApproveApplicationModalProps> {
  render (): React.ReactNode {
    const { isOpen, toggle, ...rest } = this.props

    return <Modal isOpen={isOpen} onClosed={this.props.onCancel} size="lg">
      <ModalHeader toggle={toggle}>
        Approve Application
      </ModalHeader>
      <ModalBody>
        <ApproveApplicationForm
          {...rest}
        />
      </ModalBody>
    </Modal>
  }
}

type ApproveApplicationFormProps = {
  onCancel: () => void
  onSaved: (memberId: number) => void
  application: Application
}

@observer
export class ApproveApplicationForm extends React.Component<ApproveApplicationFormProps> {
  private initialFormState = {
    message: '',
    overridePayment: false,
    paymentAmount: String(this.props.application.getMembershipFeeTotal()),
    invoiceDescription: 'LeTip International, Inc. Annual Membership',
    changePaymentMethod: false,
    payment: {
      paymentMethodType: 'CreditCard',
    },
  }

  @observable private formState = new FormState(this.initialFormState)

  @observable private formErrors = new ErrorBag()
  @observable private submitting = false

  private formHelper = new FormHelper(this.formState, this.formErrors)
  @observable private paymentPlanData: PaymentPlanData

  private paymentInfo: {cardInfo?: CardInfo, bankInfo: BankAccountDataType} = {
    cardInfo: undefined,
    bankInfo: {
      accountType: '',
      routingNumber: '',
      accountNumber: '',
      nameOnAccount: '',
    }
  }

  componentDidMount () {
    if (this.props.application.usePaymentPlan) {
      this.loadPaymentPlanData()
    }
  }

  private loadPaymentPlanData = async () => {
    try {
      let response = await ApiClient.getInstance().get(route(ApiRoutes.applications.getPaymentPlanData, { id: this.props.application.id }))
      this.paymentPlanData = modelToCamelCase(response.data.payment_plan) as PaymentPlanData
    } catch (err) {
      AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
    }
  }

  private submit = async () => {
    this.formErrors.clearErrors()

    let membershipPaymentMethodType: string | undefined

    if (this.formState.get('changePaymentMethod')) {
      membershipPaymentMethodType = this.formState.get('payment.paymentMethodType')

      // validate membership payment data
      if (membershipPaymentMethodType === 'CreditCard') {
        if (!this.paymentInfo.cardInfo || !this.paymentInfo.cardInfo.isValid) {
          this.formErrors.addError('payment.cardError', 'You must enter a valid card')
        }
      } else if (membershipPaymentMethodType === 'BankAccount') {
        if (
          !this.paymentInfo.bankInfo.accountNumber.trim().length
          || !this.paymentInfo.bankInfo.nameOnAccount.trim().length
          || !this.paymentInfo.bankInfo.routingNumber.trim().length
          || !this.paymentInfo.bankInfo.accountType.trim().length
        ) {
          this.formErrors.addError('payment.bankError', 'You must enter valid bank account info')
        } else {
          if (this.paymentInfo.bankInfo.nameOnAccount.length > 22) {
            this.formErrors.addError('payment.bankError', 'Name on account must be less than 22 characters')
          }

          if (!/^[a-zA-Z0-9 ]+$/.test(this.paymentInfo.bankInfo.nameOnAccount)) {
            this.formErrors.addError('payment.bankError', 'Name on account can only contain letters, numbers, and spaces')
          }
        }
      }
    }

    if (this.formErrors.hasErrors()) {
      return
    }

    this.submitting = true
    AppStateStore.showModalSpinner()

    let acceptJs: AcceptJsClient

    try {
      acceptJs = await loadAcceptJs()
    } catch (err) {
      AppStateStore.showAlertModal('Error', 'Unable to connect to payment processor')
      AppStateStore.dismissModalSpinner()
      this.submitting = false
      return
    }

    let membershipPaymentOpaqueData

    if (this.formState.get('changePaymentMethod')) {
      if (membershipPaymentMethodType === 'BankAccount') {
        // Paya tokenization
        const postData = {
          ...modelToSnakeCase(this.paymentInfo.bankInfo),
          provider: 'Paya',
        }

        try {
          const response = await ApiClient.getInstance().post(route(ApiRoutes.public.paya.addPaymentMethod), postData)
          membershipPaymentOpaqueData = {
            data_descriptor: 'tokenized_uuid',
            data_value: response.data.uuid,
          }
        } catch (error) {
          Util.handleErrorResponse(error.response, undefined, undefined, (response, message) => {
            let errors = _.flatten<string>(Object.values(response.data.data.errors))
            if (errors.length) {
              errors.forEach(err => this.formErrors.addError('payment.bankError', err))
            } else {
              this.formErrors.addError('payment.bankError', message)
            }
            return true
          })

          AppStateStore.dismissModalSpinner()
          this.submitting = false

          return
        }
      } else {
        // AuthNet tokenization
        try {

          const paymentMethod = membershipPaymentMethodType === 'CreditCard'
            ? {
              cardData: {
                cardNumber: this.paymentInfo.cardInfo!.cardNumber.replace(/ /g, ''),
                cardCode: this.paymentInfo.cardInfo!.cardCode,
                month: this.paymentInfo.cardInfo!.expMonth,
                year: this.paymentInfo.cardInfo!.expYear,
              }
            }
            : { bankData: this.paymentInfo.bankInfo }
          const tokenizeResponse: AcceptJsResponseDataType = await acceptJs.tokenizePaymentMethod(paymentMethod)

          membershipPaymentOpaqueData = tokenizeResponse.opaqueData
        } catch (response) {
          if (response.messages) {
            (response as AcceptJsResponseDataType).messages.message.forEach(message => {
              this.formErrors.addError(membershipPaymentMethodType === 'CreditCard' ? 'payment.cardError' : 'payment.bankError', message.text)
            })
          } else {
            this.formErrors.addError(membershipPaymentMethodType === 'CreditCard' ? 'payment.cardError' : 'payment.bankError', 'Error contacting server')
          }

          AppStateStore.dismissModalSpinner()
          this.submitting = false

          return
        }
      }
    }

    const submitData = {
      ...this.formHelper.toObject(),
      membershipPaymentOpaqueData,
    }

    ApiClient.getInstance()
      .post(route(ApiRoutes.applications.approve, { id: this.props.application.id }), modelToSnakeCase(submitData))
      .then(response => {
        toast.success('Application Approved')
        this.props.onSaved(response.data.member_id)
      })
      .catch(error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  @computed
  private get paymentPlanTotalAmount () {
    return this.paymentPlanData ? this.props.application.membershipFee + this.paymentPlanData.fee : 0
  }

  @computed
  private get paymentPlanDescription () {
    if (this.paymentPlanData) {
      const intervalAmount = (this.props.application.membershipFee - this.paymentPlanData.initialAmount + this.paymentPlanData.fee) / this.paymentPlanData.intervals.length
      const intervalDescriptions = this.paymentPlanData.intervals.map(interval => `${formatCurrency(intervalAmount)} in ${interval} days`)
      return `Their membership will be activated and their payment method will be charged ${formatCurrency(this.paymentPlanData.initialAmount)} now, ${joinStringsAnd(intervalDescriptions)} for a total of ${formatCurrency(this.paymentPlanTotalAmount)} USD (plus tax).`
    }

    return ''
  }

  render (): React.ReactNode {
    return <>
      <form onSubmit={ev => {
        ev.preventDefault()
        this.submit().then()
      }}>
        <div className="form-row">
          <div className="col-sm-12">
            <div className="form-group">
              {
                this.props.application.usePaymentPlan
                  ? <p>
                    Are you sure you want to approve this application? <b>The member has requested a payment plan.</b> {this.paymentPlanDescription} The member will receive an email notification.
                  </p>
                  : <p>
                    Are you sure you want to approve this application?
                    Their membership will be activated and their payment method will be charged {formatCurrency(this.formState.get('paymentAmount'))}.
                    The member will receive an email notification.
                  </p>
              }
            </div>
          </div>
        </div>

        {
          can(Permission.ApproveApplicationWithoutPayment)
            ? <>
              <div className="form-row">
                <div className="col-sm-12">
                  {this.formHelper.renderCheckboxInput({
                    name: 'overridePayment',
                    label: 'Override Payment',
                  })}
                </div>
              </div>
              {
                this.formState.get('overridePayment')
                  ? <>
                    <div className="form-row">
                      <div className="col-sm-12">
                        {this.formHelper.renderTextInput({
                          name: 'paymentAmount',
                          label: 'Payment Amount',
                          prepend: '$',
                        })}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm-12">
                        {this.formHelper.renderTextInput({
                          name: 'invoiceDescription',
                          label: 'Invoice Description',
                        })}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-sm-12">
                        {this.formHelper.renderCheckboxInput({
                          name: 'changePaymentMethod',
                          label: 'Change payment method',
                        })}
                      </div>
                    </div>
                    {
                      this.formState.get('changePaymentMethod')
                        ? <PaymentMethodForm
                          formState={this.formState}
                          formErrors={this.formErrors}
                          formHelper={this.formHelper}
                          cardFormErrorFieldName="payment.cardError"
                          bankFormErrorFieldName="payment.bankError"
                          onCardInfoChanged={cardInfo => this.paymentInfo.cardInfo = cardInfo}
                          onBankInfoChanged={bankInfo => this.paymentInfo.bankInfo = bankInfo}
                        />
                        : null
                    }
                  </>
                  : null
              }
            </>
            : null
        }

        <div className="form-row">
          <div className="col-sm-12">
            {this.formHelper.renderTextAreaInput({
              name: 'message',
              label: 'Personal Note / Message',
            })}
          </div>
        </div>

        <div className="form-buttons">
          <Button color="secondary" onClick={() => this.props.onCancel()}>Cancel</Button>
          <ButtonLoader type="submit" color="primary" loading={this.submitting}>Approve Application</ButtonLoader>
        </div>
      </form>
    </>
  }
}

type BankInfo = {
  routingNumber: '',
  accountNumber: '',
  nameOnAccount: '',
  accountType: 'checking'
}

@observer
class PaymentMethodForm extends React.Component<{
  formState: FormState<any>
  formHelper: FormHelper<any>
  formErrors: ErrorBag
  bankFormErrorFieldName: string
  cardFormErrorFieldName: string
  onCardInfoChanged: (cardInfo: CardInfo) => void
  onBankInfoChanged: (bankInfo: BankInfo) => void
}> {
  @observable
  private bankAccountFormState = new FormState<BankInfo>({
    routingNumber: '',
    accountNumber: '',
    nameOnAccount: '',
    accountType: 'checking'
  })

  @observable
  private bankAccountFormErrors = new ErrorBag()

  private bankAccountFormHelper = new FormHelper(this.bankAccountFormState, this.bankAccountFormErrors)

  @observable
  private cardInfo?: CardInfo

  @observable
  private creditCardFormErrors = new ErrorBag()

  render (): React.ReactNode {
    return <>
      <Nav tabs>
        <NavItem
          className={classNames({ active: this.props.formState.get('payment.paymentMethodType') === 'CreditCard' })}
        >
          <NavLink
            onClick={() => {
              this.props.formState.set('payment.paymentMethodType', 'CreditCard')
            }}
          >Credit/Debit Card</NavLink>
        </NavItem>
        <NavItem
          className={classNames({ active: this.props.formState.get('payment.paymentMethodType') == 'BankAccount' })}
        >
          <NavLink
            onClick={() => {
              this.props.formState.set('payment.paymentMethodType', 'BankAccount')
            }}
          >Bank Account</NavLink>
        </NavItem>
      </Nav>

      <div className="tab-content-body">
        <br/>
        <TabContent activeTab={this.props.formState.get('payment.paymentMethodType')}>
          <TabPane tabId={'CreditCard'}>
            <label>Card Info</label>
            <form method="post" action="#" acceptCharset="UTF-8" onSubmit={ev => ev.preventDefault()}>
              <div className="form-row">
                <div className="col-md-12">
                  <CreditCardInput
                    onChange={(cardInfo) => {
                      this.cardInfo = cardInfo
                      this.props.onCardInfoChanged(cardInfo)
                    }}
                  />
                  <FormError errors={this.props.formErrors} fieldName={this.props.cardFormErrorFieldName}/>
                </div>
              </div>
            </form>
          </TabPane>
          <TabPane tabId={'BankAccount'}>
            <form method="post" action="#" acceptCharset="UTF-8" onSubmit={ev => ev.preventDefault()}>
              <div className="form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    {this.bankAccountFormHelper.renderSelectInput({
                      label: 'Account Type',
                      options: [
                        { value: 'checking', text: 'Checking Account' },
                        { value: 'savings', text: 'Savings Account' },
                      ],
                      name: 'accountType',
                      onChange: () => this.props.onBankInfoChanged(this.bankAccountFormState.toObject()),
                    })}
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    {this.bankAccountFormHelper.renderTextInput({
                      label: 'Routing Number',
                      type: 'text',
                      name: 'routingNumber',
                      onChange: () => this.props.onBankInfoChanged(this.bankAccountFormState.toObject()),
                    })}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    {this.bankAccountFormHelper.renderTextInput({
                      label: 'Account Number',
                      type: 'text',
                      name: 'accountNumber',
                      onChange: () => this.props.onBankInfoChanged(this.bankAccountFormState.toObject()),
                    })}
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12">
                  <div className="form-group">
                    {this.bankAccountFormHelper.renderTextInput({
                      label: 'Name On Account',
                      type: 'text',
                      name: 'nameOnAccount',
                      onChange: () => this.props.onBankInfoChanged(this.bankAccountFormState.toObject()),
                    })}
                  </div>
                </div>
              </div>
              <FormError errors={this.props.formErrors} fieldName={this.props.bankFormErrorFieldName}/>
            </form>
          </TabPane>
        </TabContent>
        <FormError errors={this.props.formErrors} fieldName="membershipPaymentData"/>
      </div>
    </>
  }
}
