import { CrudMethods } from "./ApiClient"
import Util, { logException, modelToSnakeCase } from "../common/Util"
import ErrorBag from "../common/ErrorBag"
import AppStateStore from "../stores/AppStateStore"
import { AxiosPromise, AxiosResponse } from "axios"
import FormState from "../common/FormState"

export function submitFormCreate (apiResource: CrudMethods, formState: FormState<any>, formErrors: ErrorBag, onSaved: (response: AxiosResponse) => void) {
  return apiResource.create(modelToSnakeCase(formState.toObject())).then(response => {
    onSaved(response)
  }, error => {
    const errors = new ErrorBag()
    Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
      AppStateStore.showAlertModal('Error', message, m => {
        m.hide()
      })
      return true
    })

    formErrors.addErrors(errors.getErrorList())
  })
    .catch(ex => {
      logException(ex)
      formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
    })
}

export function submitFormRequest (apiCall: AxiosPromise, formState: FormState<any>, formErrors: ErrorBag, onSaved: (response: AxiosResponse) => void) {
  return apiCall.then(response => {
    onSaved(response)
  }, error => {
    const errors = new ErrorBag()
    Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
      AppStateStore.showAlertModal('Error', message, m => {
        m.hide()
      })
      return true
    })

    formErrors.addErrors(errors.getErrorList())
  })
    .catch(ex => {
      logException(ex)
      formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
    })
}