type CsvColumn<T> = {
  header: string
  valueTransformer: (row: T) => any
  footer?: (rows: T[]) => any
}

export default abstract class CsvTransformer<T> {
  private columns: CsvColumn<T>[] = []
  private rows: T[] = []

  abstract get filename (): string

  addColumn (column: CsvColumn<T>) {
    this.columns.push(column)
  }

  addRow (row: T) {
    this.rows.push(row)
  }

  addRows (rows: T[]) {
    rows.forEach(row => this.addRow(row))
  }

  toString () {
    const output: string[] = []

    output.push(this.columns.map(col =>
      `"${col.header.replace('"', '""')}"`).join(',')
    )

    this.rows.forEach(row => {
        output.push(
          this.columns.map(col =>
            this.safeValue(col.valueTransformer(row))
          ).join(',')
        )
      }
    )

    if (this.columns.filter(c => !!c.footer).length) {
      output.push(
        this.columns.map(col => col.footer ? this.safeValue(col.footer(this.rows)) : '').join(',')
      )
    }

    return output.join("\n")
  }

  safeValue (value: any) {
    if (value === undefined) {
      return ''
    } else if (value === null) {
      return ''
    } else if (value instanceof Number) {
      return value
    } else {
      return `"${String(value).replace('"', '""').replace(/[\r\n]/g, ' ')}"`
    }
  }
}