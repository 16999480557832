import { observer } from 'mobx-react'
import React from 'react'
import { observable, toJS } from 'mobx'
import Util from '../../common/Util'
import ApiClient, { ApiRoutes } from '../../api/ApiClient'
import { route } from '../../routes/routes'
import LoadingPanel from '../LoadingPanel'
import _ from 'lodash'
import { Bar } from 'react-chartjs-2'
import moment from 'moment'

type MembershipsData = {
  memberships: {
    date: string,
    data: {
      new: number
      resigned: number
      total: number
    },
  }[]
}

type Props = {
  filters: {
    state?: string
    areaId?: number
    chapterId?: number
  }
}

@observer
export default class MembershipsGraph extends React.Component<Props> {
  @observable
  private memberships?: MembershipsData
  @observable
  private error?: string
  @observable
  private loading = false

  componentDidMount () {
    this.loadData().then()
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (!_.isEqual(toJS(prevProps.filters), toJS(this.props.filters))) {
      this.loadData().then()
    }
  }

  private loadData = async () => {
    try {
      this.error = undefined
      this.loading = true

      const response = await ApiClient.getInstance().get(route(ApiRoutes.dashboard.membershipsGraph, {}, this.props.filters))

      this.memberships = response.data

      this.loading = false
    } catch (err) {
      this.error = Util.extractErrorMessage(err.response)
    }
  }

  render () {
    return <div>
      <h2>13 Month Memberships</h2>
      <div>
        <LoadingPanel loading={this.loading} loaded={!!this.memberships} error={this.error}>
          {
            this.memberships
              ? <Bar
                data={{
                  labels: this.memberships.memberships.map(m => moment(m.date).format('MMM YY')),
                  datasets: [
                    {
                      label: 'New Members',
                      data: this.memberships.memberships.map(m => m.data.new),
                      backgroundColor: '#36a3f7',
                      borderColor: '#36a3f7',
                      fill: false,
                      lineTension: 0,
                    },
                    {
                      label: 'Resigned Members',
                      data: this.memberships.memberships.map(m => m.data.resigned),
                      backgroundColor: '#ffb822',
                      borderColor: '#ffb822',
                      fill: false,
                      lineTension: 0,

                    },
                  ],
                }}
                options={{
                  legend: {
                    position: 'bottom',
                  },
                  scales: {
                    yAxes: [{
                      ticks: {
                        beginAtZero: true,
                        callback: value => value % 1 === 0 ? value : undefined,
                      },
                    }, {
                      id: 'right',
                      display: false,
                      ticks: {
                        beginAtZero: true,
                        callback: value => value % 1 === 0 ? value : undefined,
                      },
                    }],
                  },
                }}
              />
              : null
          }
        </LoadingPanel>
      </div>
    </div>
  }
}
