import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Invoice from "./Invoice"
import Member from "./Member"
import MembershipRenewal from "./MembershipRenewal"

export default class Membership extends BaseModel {
  id: number
  memberId: number
  startsAt: Moment
  endsAt?: Moment
  lastRenewedAt?: Moment
  isAutoRenewal: boolean
  status: string
  isActive: boolean
  renewalFee: number

  invoices: Invoice[]
  member: Member
  membershipRenewals: MembershipRenewal[]

  constructor (data = {}) {
    super()
    this.init(data)

    if (!this.invoices) {
      this.invoices = []
    }
  }

  setInvoices (i?: {}[]) {
    return i ? i.map(ii => new Invoice().init(ii)) : []
  }

  setMember (m?: {}) {
    return m ? new Member().init(m) : null
  }

  setMembershipRenewals (m?: []) {
    return m ? m.map(mm => new MembershipRenewal().init(mm)) : []
  }

  getCasts (): {} {
    return {
      startsAt: 'datetime',
      endsAt: 'datetime',
      lastRenewedAt: 'datetime',
      isAutoRenewal: 'boolean',
      isActive: 'boolean',
      renewalFee: 'number',
    }
  }
}