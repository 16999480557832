import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import AppStateStore from "../stores/AppStateStore"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { toast } from "react-toastify"
import { submitFormRequest } from "../api/ApiHelper"
import { route } from "../routes/routes"
import { modelToSnakeCase } from "../common/Util"

type AddChannelDestinationModalProps = {
  isOpen: boolean
  toggle: () => void

  memberId: number
  channel: string

  onSaved?: () => void
  onCanceled?: () => void
}

@observer
export class AddChannelDestinationModal extends React.Component<AddChannelDestinationModalProps> {
  private initialFormState = {
    destination: '',
  }

  @observable private formState = new FormState(this.initialFormState)

  @observable private formErrors = new ErrorBag()
  @observable private submitting = false

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()

    this.submitting = true
    AppStateStore.showModalSpinner()

    submitFormRequest(
      ApiClient.getInstance()
        .post(route(ApiRoutes.members.addNotificationChannelDestination, { id: this.props.memberId }),
          {
            ...modelToSnakeCase(this.formState.toObject()),
            channel: this.props.channel,
          }
        ),
      this.formState,
      this.formErrors,
      () => {
        toast.success(`${this.props.channel === 'sms' ? 'Phone Number' : 'Email Address'} added`)
        this.formState.setAll(this.initialFormState)
        this.props.onSaved && this.props.onSaved()
      }
    )
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private close = () => {
    this.props.toggle()
    this.props.onCanceled && this.props.onCanceled()

    this.formState.setAll(this.initialFormState)
  }

  render (): React.ReactNode {
    const { isOpen, toggle, channel } = this.props

    return <Modal isOpen={isOpen} size="md">
      <ModalHeader toggle={toggle}>
        Add {channel === 'sms' ? 'a Phone Number' : 'an Email Address'}
      </ModalHeader>
      <ModalBody toggle={toggle}>
        <form onSubmit={this.submit}>
          <div className="form-row">
            <div className="col-sm-12">
              {
                this.formHelper.renderTextInput({
                  label: channel === 'sms' ? 'Phone Number' : 'Email Address',
                  name: 'destination',
                })
              }
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => this.close()}>Cancel</Button>
        <ButtonLoader type="button" color="primary" loading={this.submitting} onClick={this.submit}>Submit</ButtonLoader>
      </ModalFooter>
    </Modal>
  }
}