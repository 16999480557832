import CsvTransformer from "./CsvTransformer"
import moment from "moment-timezone"
import { TipMasterOverviewReportData } from "../../components/system/reporting/ChapterTipMasterOverviewReport"
import _ from 'lodash';

export default class ChapterTipMasterOverviewCsvTransformer extends CsvTransformer<TipMasterOverviewReportData> {
  get filename () {
    return `tip-master-overview-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'First Name',
      valueTransformer: row => row.member.firstName,
      footer: () => 'Total',
    })

    this.addColumn({
      header: 'Last Name',
      valueTransformer: row => row.member.lastName,
    })

    this.addColumn({
      header: 'Sent Inside',
      valueTransformer: row => row.tipData.inside.sent,
      footer: (rows) => _.sumBy(rows, r => r.tipData.inside.sent),
    })

    this.addColumn({
      header: 'Sent Outside',
      valueTransformer: row => row.tipData.outside.sent,
      footer: (rows) => _.sumBy(rows, r => r.tipData.outside.sent),
    })

    this.addColumn({
      header: 'Sent Inter Chapter',
      valueTransformer: row => row.tipData.interChapter.sent,
      footer: (rows) => _.sumBy(rows, r => r.tipData.interChapter.sent),
    })

    this.addColumn({
      header: 'Sent Total Tips',
      valueTransformer: row => row.tipData.inside.sent + row.tipData.outside.sent + row.tipData.interChapter.sent,
      footer: (rows) => _.sumBy(rows, row => row.tipData.inside.sent + row.tipData.outside.sent + row.tipData.interChapter.sent),
    })

    this.addColumn({
      header: 'Sent Total Value',
      valueTransformer: row => row.tipData.inside.sentValue + row.tipData.outside.sentValue + row.tipData.interChapter.sentValue,
      footer: (rows) => _.sumBy(rows, row => row.tipData.inside.sentValue + row.tipData.outside.sentValue + row.tipData.interChapter.sentValue),
    })

    this.addColumn({
      header: 'Received Inside',
      valueTransformer: row => row.tipData.inside.received,
      footer: (rows) => _.sumBy(rows, r => r.tipData.inside.received),
    })

    this.addColumn({
      header: 'Received Outside',
      valueTransformer: row => row.tipData.outside.received,
      footer: (rows) => _.sumBy(rows, r => r.tipData.outside.sent),
    })

    this.addColumn({
      header: 'Received Inter Chapter',
      valueTransformer: row => row.tipData.interChapter.received,
      footer: (rows) => _.sumBy(rows, r => r.tipData.interChapter.received),
    })

    this.addColumn({
      header: 'Received Total Tips',
      valueTransformer: row => row.tipData.inside.received + row.tipData.outside.received + row.tipData.interChapter.received,
      footer: (rows) => _.sumBy(rows, row => row.tipData.inside.received + row.tipData.outside.received + row.tipData.interChapter.received),
    })

    this.addColumn({
      header: 'Received Total Value',
      valueTransformer: row => row.tipData.inside.receivedValue + row.tipData.outside.receivedValue + row.tipData.interChapter.receivedValue + row.tipData.batches.receivedValue,
      footer: (rows) => _.sumBy(rows, row => row.tipData.inside.receivedValue + row.tipData.outside.receivedValue + row.tipData.interChapter.receivedValue + row.tipData.batches.receivedValue),
    })
  }
}
