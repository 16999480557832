import { observer } from "mobx-react"
import React from "react"
import BaseFilterComponent from './BaseFilterComponent'
import { LetterFilterState } from './LetterFilter'
import classNames from 'classnames'

export type Props = {
  label: string
  state: LetterFilterState
  onChanged: (value: string) => void
}

@observer
export default class LetterFilterComponent extends BaseFilterComponent<Props> {
  get pageOptions () {
    const options = [
      {
        label: 'All',
        value: '',
      }
    ]

    for (let x = 0; x < 26; ++x) {
      options.push({
        label: String.fromCharCode(65 + x),
        value: String.fromCharCode(65 + x),
      })
    }

    return options
  }

  private handleLetterClick = (letter: string) => {
    this.props.onChanged(letter)
    this.props.eventBus.dispatch('changed')
    this.props.eventBus.dispatch('shouldSubmit')
  }

  render () {
    return <div>
      {this.props.label && <label>{this.props.label}</label>}

      <nav>
        <ul className="pagination">
          {
            this.pageOptions.map(o => <li key={o.value} className={classNames('page-item', { active: this.props.state.value === o.value })}>
              <a className="page-link" href="" onClick={ev => {
                ev.preventDefault()
                this.handleLetterClick(o.value)
              }}>{o.label}</a>
            </li>)
          }
        </ul>
      </nav>
    </div>
  }
}