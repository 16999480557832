import React from 'react'
import { publicPath } from '../common/Util'

type Props = {
  bankCode: string
  documentUrl: string
}

export default class BankCode extends React.Component<Props> {
  render () {
    return <a href={this.props.documentUrl} target="_blank">
      <div className="bank-code">
        {this.props.bankCode.split('').map((letter, index) => <div key={index}><img style={{ width: 30, height: 30 }} src={publicPath(`img/bank/${letter.toUpperCase()}.png`)} alt={letter}/></div>)}
      </div>
    </a>
  }
}
