import BaseModel from "./BaseModel"
import { Moment } from "moment"
import MembershipRenewal from "./MembershipRenewal"
import User from "./User"

export default class RenewalExemption extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  endsAt: Moment
  note: string
  membershipRenewalId: number
  createdByUserId: number
  canceledAt: Moment | null
  canceledNote: string | null
  canceledByUserId: number | null

  membershipRenewal: MembershipRenewal
  createdByUser: User
  canceledByUser: User | null

  setMembershipRenewal (o: {}) {
    return o ? new MembershipRenewal().init(o) : null
  }

  setCreatedByUser (o: {}) {
    return o ? new User().init(o) : null
  }

  setCanceledByUser (o: {}) {
    return o ? new User().init(o) : null
  }

  getCasts (): {} {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      endsAt: 'datetime',
      membershipRenewalId: 'number',
      createdByUserId: 'number',
      canceledAt: 'datetime',
      canceledByUserId: 'number',
    }
  }
}