import React, { SyntheticEvent } from 'react'
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import ButtonLoader from '../ButtonLoader'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { BarLoader } from 'react-spinners'
import FormState from '../../common/FormState'
import ErrorBag from '../../common/ErrorBag'
import FormHelper from '../../forms/FormHelper'
import ApiClient, { ApiRoutes } from '../../api/ApiClient'
import Util, { modelToSnakeCase, safeNull } from '../../common/Util'
import AppStateStore from '../../stores/AppStateStore'
import { route } from '../../routes/routes'
import Member from '../../models/Member'

type Props = {
  onClosed: () => void
  chapterId: number
  memberId: number
}

@observer
export default class RequestCompanyReplacementModal extends React.Component<Props> {
  @observable private isOpen = true
  @observable private loading = false
  @observable private submitting = false
  @observable private showConfirmation = false

  @observable private member?: Member

  @observable private formState = new FormState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  })
  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  componentDidMount (): void {
    this.loadData()
  }

  private loadData = () => {
    this.loading = true

    ApiClient.query(`
    member {
      fullName
      business {
        name
      }
      
      chapter {
        name
      }
      
      category {
        name
      }
    }`, {
      where: [{ id: this.props.memberId }],
    })
      .then(response => this.member = new Member().init(response.data.member))
      .catch(err => {
        this.isOpen = false
        AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
      })
      .then(() => this.loading = false)
  }

  private toggle = () => {
    if (!this.submitting) {
      this.isOpen = false
    }
  }

  protected submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitting = true
    AppStateStore.showModalSpinner()
    this.formErrors.clearErrors()

    ApiClient.getInstance().post(route(ApiRoutes.members.requestCompanyReplacement, { id: this.props.memberId }), modelToSnakeCase(this.formHelper.toObject()))
      .then(() => {
        this.showConfirmation = true
      })
      .catch(error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .then(() => {
        this.submitting = false
        AppStateStore.dismissModalSpinner()
      })
  }

  protected renderApplication = (member: Member) => {
    const formHelper = this.formHelper

    return <>
      <Alert color="info">
        To request a company replacement, enter the contact information for your replacement below and they will be sent a membership application.
      </Alert>

      <form onSubmit={this.submit}>
        <div className="form-row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>Chapter</label>
              <div><b>{member.chapter!.name}</b></div>
            </div>
          </div>
          <div className="col-sm-6">
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>Current Member</label>
              <div><b>{member.fullName}</b></div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Category</label>
              <div><b>{member.category!.name}</b></div>
            </div>
          </div>
        </div>
        <hr/>
        <h6>Replacement Contact Information</h6>
        <div className="form-row">
          <div className="col-sm-6">
            {formHelper.renderTextInput({
              name: 'firstName',
              label: 'First Name',
            })}
          </div>
          <div className="col-sm-6">
            {formHelper.renderTextInput({
              name: 'lastName',
              label: 'Last Name',
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-6">
            {formHelper.renderTextInput({
              name: 'phone',
              label: 'Guest Phone Number',
              type: 'tel',
            })}
          </div>
          <div className="col-sm-6">
            {formHelper.renderTextInput({
              name: 'email',
              label: 'Email Address',
              type: 'email',
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-12">
            <div className="form-group">
              <label>Company Name</label>
              <div>{safeNull(() => this.member!.business!.name) || '---'}</div>
            </div>
          </div>
        </div>
      </form>
    </>
  }

  private renderConfirmation = () => {
    return <div>
      <p>
        Your company replacement will be sent a membership application.<br/><br/>
        Your chapter board have received an email letting them know of your request, but you should also reach out to your chapter president to explain the change.
      </p>
    </div>
  }

  render () {
    return <Modal isOpen={this.isOpen} toggle={this.toggle} size="lg" onClosed={this.props.onClosed}>
      <ModalHeader toggle={this.toggle}>
        Request Company Replacement
      </ModalHeader>
      <ModalBody>
        {
          this.showConfirmation
            ? this.renderConfirmation()
            : this.loading || !this.member
            ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
            : this.renderApplication(this.member)
        }
      </ModalBody>
      <ModalFooter>
        {
          this.showConfirmation
            ? <>
              <Button color="secondary" onClick={this.toggle}>OK</Button>
            </>
            : <>
              <Button color="secondary" disabled={this.submitting} onClick={this.toggle}>Cancel</Button>
              <ButtonLoader type="button" color="primary" loading={this.submitting} onClick={this.submit}>Submit Request</ButtonLoader>
            </>
        }
      </ModalFooter>
    </Modal>
  }
}
