import * as React from "react"
import { observer } from "mobx-react"
import { autorun, computed, observable } from "mobx"
import ApiClient from "../../api/ApiClient"
import ProgramPosition from "../../models/ProgramPosition"
import Util, { transformIf } from "../../common/Util"
import { Button } from "reactstrap"
import MemberProfileImage from "../../components/MemberProfileImage"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { BarLoader } from "react-spinners"
import ProgramScheduleRecord from "../../models/ProgramScheduleRecord"
import * as _ from "lodash"
import Member from "../../models/Member"

type ProgramScheduleProps = {
  chapterId: number
}

@observer
export default class ProgramSchedule extends React.Component<ProgramScheduleProps> {
  @observable private programSchedule: ProgramScheduleRecord[] = []
  @observable private programPositions: ProgramPosition[] = []

  @observable private error?: string
  @observable private loading = false

  @computed
  private get scheduledMembers () {
    const scheduledMembers: Member[] = []

    this.programSchedule.forEach(ps => {
      if (!_.find(scheduledMembers, sm => sm.id == ps.member.id)) {
        scheduledMembers.push(ps.member)
      }
    })

    return scheduledMembers
  }

  private fetchSchedule = async () => {
    this.loading = true
    this.error = undefined

    try {
      let response = await ApiClient.query(
        `
chapter {
  *

  programSchedule
}
`,
        {
          where: [
            { id: this.props.chapterId },
          ]
        }
      )

      const programSchedule = response.data.chapter.programSchedule.map((m: {}) => new ProgramScheduleRecord().init(m))

      response = await ApiClient.query(
        `
programPositions {
  *
}
`,
        {
          order: [
            'displayOrder',
          ]
        }
      )

      const programPositions = response.data.programPositions.map((m: {}) => new ProgramPosition().init(m))

      this.programSchedule = programSchedule
      this.programPositions = programPositions
    } catch (err) {
      this.error = ((err && err.response) ? Util.extractErrorMessage(err.response) : undefined) || 'An error has occurred'
    }

    this.loading = false
  }

  componentDidMount (): void {
    autorun(() => this.fetchSchedule())
  }

  private renderProgram = () => {
    return (this.programSchedule && this.programSchedule.length)
      ? <>
        <div className="form-buttons">
          <Button
            type="button"
            color="primary"
            onClick={() => window.print()}
          ><i className="fa fa-print"/> Print Schedule</Button>
        </div>
        <table className="table table-striped table-condensed program-table">
          <thead>
          <tr>
            <th/>
            {this.programPositions.map((p, idx) => <th key={idx}>{p.title}</th>)}
          </tr>
          </thead>
          <tbody>
          {this.scheduledMembers.map(scheduledMember =>
            <tr key={scheduledMember.id}>
              <td>
                <div className="d-flex align-items-center">
                  <div>
                    <MemberProfileImage
                      size={45}
                      profileImageUrl={scheduledMember.profileImageUrl}
                      memberName={scheduledMember.fullName}
                    />
                  </div>
                  <div className="flex-fill ml-2 mr-2 text-left">
                    <a href={route(Routes.network.member, { memberId: scheduledMember.id })}>{scheduledMember.fullName}</a><br/>
                    {scheduledMember.category && scheduledMember.category.name}
                  </div>
                </div>
              </td>
              {
                this.programPositions.map(pp => <td key={pp.id}>
                  {
                    transformIf(_.find(this.programSchedule, ps => ps.position.id == pp.id && ps.member.id == scheduledMember.id), (ps: ProgramScheduleRecord) => ps.event.startsAt.tz(ps.event.timezone).format('MMM D')) || '---'
                  }
                </td>)
              }
            </tr>)}
          </tbody>
        </table>
      </>
      : <p>The program schedule has not been set up yet</p>
  }

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return this.error
      ? this.error
      : this.loading
        ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
        : this.renderProgram()
  }
}
