import BaseModel from "./BaseModel"

export default class Category extends BaseModel {
  id: number
  name: string
  description: string
  status: string | 'Active' | 'Inactive'

  numberOfActiveMembers: number

  constructor (data = {}) {
    super();
    this.init(data);
  }
}