import { FetchParams, TableViewAdapter } from "./TableViewAdapter"
import EventBus from "../../common/EventBus";
import _ from 'lodash'

type ArrayTableViewAdapterOptions = {
  sort?: {[key: string]: <T>(o: any) => any}
}

export class ArrayTableViewAdapter implements TableViewAdapter {
  readonly eventBus = new EventBus()

  constructor (private items: any[], private options?: ArrayTableViewAdapterOptions) {
  }

  fetchData (fetchParams: FetchParams, callback: (items: any[], total: number) => void, error: (message: string) => void, finished: () => void): void {
    let items = this.items

    if (fetchParams.sortDescriptor) {
      if (this.options && this.options.sort && this.options.sort[fetchParams.sortDescriptor.sortKey]) {
        items = _.orderBy(this.items, this.options.sort[fetchParams.sortDescriptor.sortKey], [fetchParams.sortDescriptor.descending ? 'desc' : 'asc'])
      } else {
        items = _.orderBy(this.items, o => o[fetchParams.sortDescriptor!.sortKey], [fetchParams.sortDescriptor.descending ? 'desc' : 'asc'])
      }
    }

    callback(items, items.length)

    this.eventBus.dispatch('fetched', {
      items: items,
      total: items.length,
    })

    finished()
  }
}
