import { observer } from "mobx-react"
import * as React from "react"
import { Button, ButtonProps } from "reactstrap"

type ButtonLoaderProps = {
  loading: boolean

}

@observer
export default class ButtonLoader extends React.Component<ButtonLoaderProps & ButtonProps> {
  static defaultProps = {
    loading: false,
  }

  private renderSpinner () {
    return this.props.loading
      ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>)
      : null
  }

  render (): React.ReactNode {
    const { children, disabled, loading, ...rest } = this.props
    return (<Button disabled={disabled || loading} {...rest}>{this.renderSpinner()}{children}</Button>)
  }
}
