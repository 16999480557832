import * as React from "react"
import { SyntheticEvent } from "react"
import Dropzone, { DropFilesEventHandler } from "react-dropzone"
import AvatarEditor from "react-avatar-editor"
import { observable } from "mobx"
import classNames from "classnames"
import { observer } from "mobx-react"
import FormHelper from "../forms/FormHelper"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import { Button } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import { AxiosPromise } from "axios"
import AppStateStore from "../stores/AppStateStore"
import Util, { logException } from "../common/Util"
import { toast } from "react-toastify"
import * as _ from "lodash"
import FormError from "./FormError"
import ApiClient from "../api/ApiClient"

type AvatarEditFormProps = {
  onCancel: () => void
  onSaved: () => void
  modelId: number
  updateAvatar: (id: number, data: {}) => AxiosPromise
  label: string
  existingImageUrl?: string
}

type AvatarEditorConfiguration = {
  position: {
    x: number,
    y: number
  },
  scale: number,
  rotate: number,
  width: number,
  height: number
}


@observer
export default class AvatarEditForm extends React.Component<AvatarEditFormProps, never> {
  @observable
  private image: File | string | null = null;
  private editor: AvatarEditor | null = null;

  @observable
  private submitting: boolean = false;

  @observable private formErrors = new ErrorBag();
  @observable private formState = new FormState<AvatarEditorConfiguration>({
    position: {
      x: 0.5,
      y: 0.5
    },
    scale: 1,
    rotate: 0,
    width: 200,
    height: 200,
  })

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private handleDrop: DropFilesEventHandler = (acceptedFiles) => {
    this.image = acceptedFiles[0] || null;
  }

  componentDidMount (): void {
    if (this.props.existingImageUrl) {
      ApiClient.getImageBlob(this.props.existingImageUrl).then(response => {
        try {
          if (response.data && response.data.size) {
            this.image = new File([response.data], 'avatar', { type: response.data.type || response.headers['content-type'] || 'image/png' })
          }
        } catch (e) {
          throw new Error();
        }
      }).catch(e => {
        AppStateStore.showAlertModal('Error', 'Unable to load existing image', m => {
          m.hide()
        })
      })
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    const editor = this.editor!;
    const canvas = editor.getImage()

    this.submitting = true
    AppStateStore.showModalSpinner()

    this.props.updateAvatar(this.props.modelId, { 'data_url': canvas.toDataURL() }).then(() => {
      toast.success(`${_.startCase(_.toLower(this.props.label))} Updated`)
      this.props.onSaved()
    }, error => {
      const errors = new ErrorBag()
      Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      })

      this.formErrors.addErrors(errors.getErrorList())
    })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  render (): React.ReactNode {
    return (
      <form method="post" action="" onSubmit={this.submit}>
        <Dropzone multiple={false} onDrop={this.handleDrop} disableClick={!!this.image} accept={'image/jpeg, image/png, image/gif'}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })}>
                <input {...getInputProps()} />
                {!this.image && (
                  <div className={'text-center'} style={{padding: '3rem'}}>
                    {
                      isDragActive ?
                        <p>Drop image here</p> :
                        <p>Drag an image here, or click to select an image to upload.</p>
                    }
                  </div>
                )}
              </div>
            )
          }}
        </Dropzone>
        <FormError errors={this.formErrors} fieldName={'data_url'}/>

        {this.image ? (
          <>
            <div className={'text-center'}>
              <AvatarEditor
                ref={editor => this.editor = editor}
                scale={parseFloat(this.formState.get('scale'))}
                width={parseInt(this.formState.get('width'))}
                height={parseInt(this.formState.get('height'))}
                position={this.formState.get('position')}
                // @ts-ignore
                onPositionChange={position => this.formState.set('position', position)}
                rotate={this.formState.get('rotate')}
                image={this.image}
                className="editor-canvas"
              />
            </div>
            <br/>

            {this.formHelper.renderTextInput({
              name: 'scale',
              label: 'Zoom',
              type: 'range',
              additionalInputProps: {
                min: '0.1',
                max: '3',
                step: '0.01'
              }
            })}
            <div className={'text-center'}>
              <Button type={'button'} color="info" onClick={() => {
                this.formState.set('rotate', this.formState.get('rotate') - 90)
              }}>
                <i className="fa fa-rotate-left"/> Rotate Left
              </Button>
              &nbsp;
              <Button type={'button'} color="info" onClick={() => {
                this.formState.set('rotate', this.formState.get('rotate') + 90)
              }}>
                <i className="fa fa-rotate-right"/> Rotate Right
              </Button>
            </div>

            <br />

            <div className="form-buttons">
              {this.image && (
                <Button type={'button'} onClick={() => {
                  this.image = null;
                }}>
                  <i className="fa fa-upload"/> Replace
                </Button>
              )}
              <div style={{flex: '1 0 auto'}}/>
              <Button type="button" color="secondary" onClick={this.props.onCancel}><i className="fa fa-ban"/> Cancel</Button>
              <ButtonLoader disabled={!this.image} loading={this.submitting} type={'submit'} color={'success'}><i className="fa fa-save"/> Save</ButtonLoader>
            </div>
          </>
        ) : (
          <div className="form-buttons">
            <Button type="button" color="secondary" onClick={this.props.onCancel}>Cancel</Button>
          </div>
        )}
      </form>
    )
  }
}