import * as React from "react"
import { observer } from "mobx-react"
import AddressView from "../../AddressView"
import BadgeShippingRequest from "../../../models/BadgeShippingRequest"
import { safeNull } from "../../../common/Util"
import { route } from "../../../routes/routes"
import TableView from "../../table-view/TableView"
import { observable } from "mobx"
import { ApiTableViewAdapter } from "../../table-view/ApiTableViewAdapter"
import { Link } from "react-router-dom"
import { Routes } from "../../../routes/AppRoutes"
import EventBus from "../../../common/EventBus"
import ShippingHistoryCsvTransformer from "../../../models/renderers/ShippingHistoryCsvTransformer"
import ManagedTableView from '../../table-view/ManagedTableView'
import { ManagedChooseableColumn } from '../../table-view/ManagedTableViewColumnChooser'
import DateFilter from '../../table-view/filters/DateFilter'
import AsyncSelectFilter, { AsyncSelectFilterTargetType } from '../../table-view/filters/AsyncSelectFilter'
import { loadChapterOptionsForAdmin, loadMemberOptions } from '../../../api/AsyncHelpers'
import SelectFilter, { SelectFilterTargetType } from '../../table-view/filters/SelectFilter'
import Config from '../../../common/Config'

type Props = {
  eventBus: EventBus
}

@observer
export default class ShipmentHistory extends React.Component<Props> {
  @observable selectedItems: number[] = []

  private tableViewRef = React.createRef<TableView>()

  componentDidMount (): void {
    this.props.eventBus.on('invalidate', this.invalidateData)
  }

  componentWillUnmount (): void {
    this.props.eventBus.remove(this.invalidateData)
  }

  private invalidateData = () => {
    this.tableViewRef.current && this.tableViewRef.current.fetchData()
  }

  private shippingRequestsAdapter = new ApiTableViewAdapter(
    () => {
      return {
        query: `
    badgeShippingRequests {
      *

      badgeShippingAddress {
        *
      }

      member {
        *

        category {
          *
        }

        chapter {
          *

          area {
            *
          }
        }
      }
    }`,
        where: [
          { id: 'completedAt', op: 'notnull' },
        ],
      }
    }, 'badgeShippingRequests', BadgeShippingRequest
  )

  @observable private availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'createdAt',
      column: {
        title: 'Date',
        accessor: 'createdAt',
        renderItem: value => value.format('MM/DD/YYYY'),
        sortable: true,
      },
      fixed: true,
    },
    {
      id: 'completedAt',
      column: {
        title: 'Shipped',
        accessor: 'completedAt',
        renderItem: value => value.format('MM/DD/YYYY'),
        sortable: true,
      },
      fixed: true,
    },
    {
      id: 'prefix',
      column: {
        title: 'Prefix',
        accessor: 'member.prefix',
      },
      default: true,

    },
    {
      id: 'member',
      column: {
        title: 'Member',
        accessor: 'member',
        renderItem: value => <>
          <i>{safeNull(() => value.joinDate!.format('MM/DD/YYYY')) || '---'}</i>
          <br/>
          <Link to={route(Routes.system.members.show, { id: value.id })}>{safeNull(() => value.preferredName || value.fullName) || '---'}</Link>
        </>,
      },
      fixed: true,
    },
    {
      id: 'chapter',
      column: {
        title: 'Chapter',
        accessor: 'member.chapter',
        renderItem: value => <>
          {
            value
              ? <Link to={route(Routes.system.chapters.show, { id: value.id })}>{value.name}</Link>
              : '---'
          }
        </>,
      },
      fixed: true,
    },
    {
      id: 'category',
      column: {
        title: 'Category',
        accessor: 'member.category.name',
      },
      default: true,
    },
    {
      id: 'badgeColor',
      column: {
        title: 'Badge Color',
        accessor: 'badgeColor',
        renderItem: (value, item) => <div className={`badge-color-${safeNull(() => value.toLowerCase())}`}>
          {value}<br/>
          ({item.previousBadgeColor || 'None'})
        </div>

      },
      default: true,
    },
    {
      id: 'addressTo',
      column: {
        title: 'Address To',
        accessor: 'badgeShippingAddress.name',
      },
      default: true,
    },
    {
      id: 'address',
      column: {
        title: 'Address',
        accessor: 'badgeShippingAddress',
        renderItem: value => value
          ? <AddressView address={value} showMapLink={true}/>
          : null
      },
      default: true,
    },
    {
      id: 'notes',
      column: {
        title: 'Notes',
        accessor: 'shippingNotes',
      },
      default: true,
    },
  ]

  private filters = [
    new DateFilter('createdAt', 'createdAt', 'Date Sent'),
    new AsyncSelectFilter('member', AsyncSelectFilterTargetType.Field, 'memberId', 'Member', loadMemberOptions()),
    new AsyncSelectFilter('chapter', AsyncSelectFilterTargetType.Field, 'member.chapterId', 'Chapter Name', loadChapterOptionsForAdmin),
    new SelectFilter('badgeColor', SelectFilterTargetType.Field, 'badgeColor', 'Badge Color', Config.BADGE_COLORS.map(c => ({
      value: c,
      label: c,
    }))),
  ]

  render (): React.ReactNode {
    return <ManagedTableView
      stateKey="ShipmentHistory"
      showSearch={false}
      availableColumns={this.availableColumns}
      adapter={this.shippingRequestsAdapter}
      exportTransformer={ShippingHistoryCsvTransformer}
      defaultSort={{ sortKey: 'completedAt', descending: true }}
      filters={this.filters}
    />
  }
}
