import BaseView from "../BaseView"
import { observer } from "mobx-react"
import * as React from "react"
import SentSystemMessages from "../../components/system/SentSystemMessages"
import { observable } from "mobx"
import { Nav, NavItem, NavLink, TabContent } from "reactstrap"
import classNames from "classnames"
import LazyTabPane from "../../components/LazyTabPane"
import SystemMessageTemplates from "../../components/system/SystemMessageTemplates"
import MemberSystemMessageTemplates from "../../components/system/MemberEmailTemplates"

@observer
export default class MemberSystemMessagesView extends BaseView {

  renderContentHeader () {
    return <>
      <h1>Email Templates</h1>
    </>
  }

  render () {
    return <div >
        <MemberSystemMessageTemplates/>
    </div>
  }
}
