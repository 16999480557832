import BaseView from "../BaseView"
import { observer } from "mobx-react"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import * as React from "react"
import { observable } from "mobx"
import { Link } from "react-router-dom"
import { ApiTableViewAdapter } from "../../components/table-view/ApiTableViewAdapter"
import { QueryWhereClause } from "../../api/ApiClient"
import Member from "../../models/Member"
import { formatCurrency, formatDateLocal, safeNull } from "../../common/Util"
import Transaction, { TransactionType } from "../../models/Transaction"
import TransactionDetailModal from "../../components/system/transactions/TransactionDetailModal"
import CreateTransactionModal from "../../components/system/transactions/CreateTransactionModal"
import { Button } from "reactstrap"
import { ManagedChooseableColumn } from "../../components/table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "../../components/table-view/ManagedTableView";
import TransactionsCsvTransformer from "../../models/renderers/TransactionsCsvTransformer";
import DateFilter from '../../components/table-view/filters/DateFilter'
import SelectFilter, { SelectFilterTargetType } from '../../components/table-view/filters/SelectFilter'
import AsyncSelectFilter, { AsyncSelectFilterTargetType } from '../../components/table-view/filters/AsyncSelectFilter'
import { loadChapterOptionsForAdmin, loadMemberOptions } from '../../api/AsyncHelpers'
import TextFilter, { TextFilterOperator, TextFilterTargetType } from '../../components/table-view/filters/TextFilter'

@observer
export default class TransactionsView extends BaseView {
  @observable private showTransactionDetailModal = false
  @observable private detailTransactionId?: number
  @observable private renderCreateTransactionModal = false
  @observable private showCreateTransactionModal = false

  renderContentHeader () {
    return <>
      <h1>Transactions</h1>
      <ul className="content-header-actions">
        <li>
          <Button
            type="button"
            color="success"
            onClick={() => {
              this.renderCreateTransactionModal = true
              this.showCreateTransactionModal = true
            }}><i className="fa fa-plus-circle"/> Manual Transaction</Button>
        </li>
      </ul>
    </>
  }

  private availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'id',
      column: {
        title: 'ID',
        accessor: 'id',
        sortable: true,
      },
      fixed: true,
    },
    {
      id: 'createdAt',
      column: {
        title: 'Date',
        accessor: 'createdAt',
        sortable: true,
        renderItem: (value, item) => <a href="" onClick={ev => {
          ev.preventDefault()
          this.detailTransactionId = item.id
          this.showTransactionDetailModal = true
        }}>{formatDateLocal(value, 'MM/DD/YYYY hh:mm a')}</a>
      },
      default: true,
    },
    {
      id: 'description',
      column: {
        title: 'Description',
        accessor: 'description',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'transactionType',
      column: {
        title: 'Type',
        accessor: 'transactionType',
        sortable: true,
        renderItem: (value, item: Transaction) => item.transactionType === TransactionType.Payment ? `${item.transactionType} (${item.paymentType})` : item.transactionType
      },
      default: true,
    },
    {
      id: 'amount',
      column: {
        title: 'Amount',
        accessor: 'amount',
        sortable: true,
        renderItem: value => formatCurrency(value, true),
        headerClassName: 'justify-content-end',
        itemClassName: 'text-right',
      },
      default: true,
    },
    {
      id: 'status',
      column: {
        title: 'Status',
        accessor: 'status',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'provider',
      column: {
        title: 'Provider',
        accessor: 'provider',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'externalTransactionId',
      column: {
        title: 'Transaction Ref',
        accessor: 'externalTransactionId',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'parentTransactionId',
      column: {
        title: 'Original Transaction Id',
        accessor: 'parentTransactionId',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'member',
      column: {
        title: 'Member',
        accessor: 'member',
        sortKey: 'invoice.member.fullName',
        sortable: true,
        renderItem: value => value ? <Link to={route(Routes.system.members.show, { id: value.id })}>{value.fullName}</Link> : '---'
      },
      default: true,
    },
    {
      id: 'chapter',
      column: {
        title: 'Chapter',
        accessor: 'chapter',
        sortKey: 'invoice.chapter.name',
        sortable: true,
        renderItem: value => value ? <Link to={route(Routes.system.chapters.show, { id: value.id })}>{value.name}</Link> : '---'
      },
      default: true,
    },
    {
      id: 'area',
      column: {
        title: 'Area',
        renderItem: (value, transaction: Transaction) => transaction.member
          ? safeNull(() => transaction.member!.chapter!.area!.name)
          : transaction.chapter
            ? safeNull(() => transaction.chapter!.area!.name)
            : '---'
      },
      default: false,
    },
    {
      id: 'invoice',
      column: {
        title: 'Invoice',
        accessor: 'invoice.id',
        sortable: true,
        renderItem: (value, item) => item.invoice ? `#${item.invoice.invoiceNumber} ${item.invoice.description}` : '---',
      },
      default: true,
    },
  ]

  private tableViewRef = React.createRef<ManagedTableView>()

  private transactionsAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      return {
        query: `
      transactions {
        *
        
        member {
          *
          
          chapter {
            area {
              *
            }
          }
        }
        
        chapter {
          *
          area {
            *
          }
        }
        
        invoice {
          *
        }
      }
    `,
        where: where,
      }
    },
    'transactions',
    Transaction
  )

  private filters = [
    new DateFilter('createdAt', 'createdAt', 'Transaction Date'),
    new SelectFilter('transactionType', SelectFilterTargetType.Field, 'transactionType', 'Type', [
      'Payment',
      'Refund',
      'Void',
    ].map(s => ({ value: s, label: s }))),
    new AsyncSelectFilter('chapter', AsyncSelectFilterTargetType.Field, 'chapterId', 'Chapter', loadChapterOptionsForAdmin),
    new AsyncSelectFilter('member', AsyncSelectFilterTargetType.Field, 'memberId', 'Member', loadMemberOptions()),
    new TextFilter('description', TextFilterTargetType.Field, 'description', 'Description', TextFilterOperator.Contains),
  ]

  renderContentBody () {
    return (
      <>
        <ManagedTableView
          stateKey="TransactionsView"
          hideItemCount={true}
          adapter={this.transactionsAdapter}
          availableColumns={this.availableColumns}
          defaultSort={{ sortKey: 'createdAt', descending: true }}
          exportTransformer={TransactionsCsvTransformer}
          showSearch={false}
          filters={this.filters}
        />
        {
          this.detailTransactionId
            ? <TransactionDetailModal
              isOpen={this.showTransactionDetailModal}
              toggle={() => this.showTransactionDetailModal = false}
              transactionId={this.detailTransactionId}
              onClosed={() => this.detailTransactionId = undefined}
              onUpdated={() => this.tableViewRef.current && this.tableViewRef.current.fetchData()}
            />
            : null
        }
        {
          this.renderCreateTransactionModal
            ? <CreateTransactionModal
              onUpdated={() => this.tableViewRef.current && this.tableViewRef.current.fetchData()}
              toggle={() => this.showCreateTransactionModal = false}
              onClosed={() => this.renderCreateTransactionModal = false}
              isOpen={this.showCreateTransactionModal}
            />
            : null
        }
      </>
    )
  }
}
