import * as React from "react"
import BaseView from "./BaseView"
import { RouteComponentProps } from 'react-router'
import { observable } from 'mobx'
import { createBrowserHistory } from 'history'
import { route } from '../routes/routes'
import { Routes } from '../routes/AppRoutes'
import { Badge, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classNames from 'classnames'
import MembershipApplicationsList from '../components/MembershipApplicationsList'
import { observer } from 'mobx-react'
import CategoryChangeRequestsList from '../components/CategoryChangeRequestsList'
import ChapterTransferRequestsList from '../components/ChapterTransferRequestsList'

type Props = {
  match: {
    params: {
      tab?: string
    }
  }
} & RouteComponentProps

export const TABS = {
  membership: 'membership',
  categoryChange: 'category-change',
  chapterTransfer: 'chapter-transfer',
}

@observer
export default class ApplicationsView extends BaseView<Props> {
  @observable private activeTab = TABS.membership
  @observable private numberOfMembershipApplications = 0
  @observable private numberOfCategoryChangeRequests = 0
  @observable private numberOfChapterTransferRequests = 0

  private history = createBrowserHistory()

  componentDidMount (): void {
    super.componentDidMount()

    this.activeTab = this.props.match.params.tab || TABS.membership
  }

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(route(Routes.system.applications.index, { tab: this.activeTab }))
    }
  }

  renderContentHeader () {
    return (
      <>
        <h1>Applications</h1>
      </>
    )
  }

  render (): React.ReactNode {
    return <div className="card">
      <div className="card-header">
        <Nav tabs className="card-header-tabs">
          <NavItem className={classNames({ active: this.activeTab === TABS.membership })}>
            <NavLink
              onClick={() => {
                this.setTab(TABS.membership)
              }}
            >Membership Applications {this.numberOfMembershipApplications > 0 ? <Badge color="danger" className="nav-tab-badge">{this.numberOfMembershipApplications}</Badge> : null}</NavLink>
          </NavItem>
          <NavItem className={classNames({ active: this.activeTab === TABS.categoryChange })}>
            <NavLink
              onClick={() => {
                this.setTab(TABS.categoryChange)
              }}
            >Category Change Requests {this.numberOfCategoryChangeRequests > 0 ? <Badge color="danger" className="nav-tab-badge">{this.numberOfCategoryChangeRequests}</Badge> : null}</NavLink>
          </NavItem>
          <NavItem className={classNames({ active: this.activeTab === TABS.chapterTransfer })}>
            <NavLink
              onClick={() => {
                this.setTab(TABS.chapterTransfer)
              }}
            >Chapter Transfer Requests {this.numberOfChapterTransferRequests > 0 ? <Badge color="danger" className="nav-tab-badge">{this.numberOfChapterTransferRequests}</Badge> : null}</NavLink>
          </NavItem>
        </Nav>
      </div>
      <div className="card-body">
        <TabContent activeTab={this.activeTab}>
          <TabPane tabId={TABS.membership} activeTab={this.activeTab}>
            <MembershipApplicationsList
              onBadgeCountChanged={count => this.numberOfMembershipApplications = count}
            />
          </TabPane>
          <TabPane tabId={TABS.categoryChange} activeTab={this.activeTab}>
            <CategoryChangeRequestsList
              onBadgeCountChanged={count => this.numberOfCategoryChangeRequests = count}
            />
          </TabPane>
          <TabPane tabId={TABS.chapterTransfer} activeTab={this.activeTab}>
            <ChapterTransferRequestsList
              onBadgeCountChanged={count => this.numberOfChapterTransferRequests = count}
            />
          </TabPane>
        </TabContent>
      </div>
    </div>
  }
}
