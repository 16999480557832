import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import AppStateStore from "../../stores/AppStateStore"
import ButtonLoader from "../ButtonLoader"
import ApiClient, { ApiRoutes } from '../../api/ApiClient'
import Util, { formatDateForCalendar } from '../../common/Util'
import AuthStore from '../../stores/AuthStore'
import { route } from '../../routes/routes'
import { toast } from 'react-toastify'
import BoardTraining from "../../models/BoardTraining"

type Props = {
  onClosed: () => void
  memberId: number
}

@observer
export class ScheduleBoardTrainingModal extends React.Component<Props> {
  @observable private isOpen = true
  @observable private submitting = false
  @observable private selectedBoardTrainingEvent: BoardTraining | undefined = undefined
  @observable private boardTrainingEvents: BoardTraining[] | undefined = undefined
  @observable private scheduledEvents: BoardTraining[] = []

  componentDidMount () {
    this.loadData().then()
  }

  private loadData = async () => {
    try {
      let response = await ApiClient.query(`
  boardTrainings {
  *
    calendarEvent{
      *
    }
}`, {
        where: [{ _scope: 'canSchedule' }, { _scope: 'notAttending', value: this.props.memberId }],
        order: ['date'],
      })

      this.boardTrainingEvents = response.data.boardTrainings.map((m: any) => new BoardTraining().init(m))

      response = await ApiClient.query(`
  boardTrainings {
  *
  calendarEvent{
      *
  }
}`, {
        where: [{ _scope: 'isAttending', value: this.props.memberId }],
        order: ['date'],
      })

      this.scheduledEvents = response.data.boardTrainings.map((m: any) => new BoardTraining().init(m))
    } catch (err) {
      AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response), modal => {
        modal.hide()
        this.toggle()
      })
    }
  }

  private unschedule = async (boardTrainingEventId: number) => {
    this.submitting = true
    AppStateStore.showModalSpinner()

    try {
      await ApiClient.getInstance().post(route(ApiRoutes.boardTrainings.unschedule), {
        member_id: this.props.memberId,
        board_training_id: boardTrainingEventId,
      })

      toast.success('You have been unscheduled from Board Training')
      this.loadData().then()
    } catch (err) {
      AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response), modal => {
        modal.hide()
      })
    }

    this.submitting = false
    AppStateStore.dismissModalSpinner()
  }

  private submit = async (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitting = true
    AppStateStore.showModalSpinner()

    try {
      await ApiClient.getInstance().post(route(ApiRoutes.boardTrainings.schedule), {
        member_id: this.props.memberId,
        board_training_id: this.selectedBoardTrainingEvent!.id,
      })

      toast.success('You have been scheduled for Board Training')
      this.isOpen = false
    } catch (err) {
      AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response), modal => {
        modal.hide()
      })
    }

    this.submitting = false
    AppStateStore.dismissModalSpinner()
  }

  private toggle = () => {
    if (!this.submitting) {
      this.isOpen = false
    }
  }

  render (): React.ReactNode {
    return <Modal isOpen={this.isOpen} toggle={this.toggle} size="md" onClosed={this.props.onClosed}>
      <ModalHeader toggle={this.toggle}>
        Schedule Board Training
      </ModalHeader>
      <ModalBody>
        <p>
          Select an available date below to sign up for your Board Training
        </p>
        {
          this.boardTrainingEvents
            ? <div>
              <select
                className="form-control"
                onChange={ev => this.selectedBoardTrainingEvent = this.boardTrainingEvents!.find(e => String(e.id) === ev.target.value)}
              >
                <option value="">(select a date)</option>
                {
                  this.boardTrainingEvents.map(e => <option key={e.id} value={String(e.id)}>{e.calendarEvent.title}, {e.date.clone().tz(AuthStore.getUser()!.timezone || 'UTC').format(`dddd, MMMM Do @ h:mma z`)}</option>)
                }
              </select>
            </div>
            : <Spinner/>
        }
        {
          this.scheduledEvents.length
            ? <div>
              <hr/>
              <p>You are scheduled to attend the following events:</p>
              {
                this.scheduledEvents.map(scheduledEvent => <div key={scheduledEvent.id}>
                  <Button size="sm" color="danger" onClick={() => this.unschedule(scheduledEvent.id)}>Unsubscribe</Button>{scheduledEvent.calendarEvent.title}, {scheduledEvent.date.clone().tz(AuthStore.getUser()!.timezone || 'UTC').format(`dddd, MMMM Do @ h:mma z`)}
                </div>)
              }
            </div>
            : null
        }
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={this.submitting} onClick={this.toggle}>Cancel</Button>
        <ButtonLoader type="button" color="primary" disabled={!this.selectedBoardTrainingEvent} loading={this.submitting} onClick={this.submit}>Submit</ButtonLoader>
      </ModalFooter>
    </Modal>
  }
}
