import { action, computed, observable } from "mobx"
import AuthUser from "../models/AuthUser"
import Cookies from "universal-cookie"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { modelToCamelCase } from "../common/Util"
import sha1 from "sha1"

const cookies = new Cookies()

class AuthStore {
  private AUTH_TOKEN_STORAGE_KEY = 'letip_wired:auth_token'
  private ORIGINAL_AUTH_TOKEN_STORAGE_KEY = 'letip_wired:original_auth_token'
  private ORIGINAL_USERNAME_STORAGE_KEY = 'letip_wired:original_username'

  private authToken?: string
  @observable private user?: AuthUser
  @observable private _didLogOut = false

  private originalAuthToken?: string

  @computed get didLogOut (): boolean {
    return this._didLogOut
  }

  @computed get hasAuthToken (): boolean {
    return !!this.authToken
  }

  @computed get isAuthenticated (): boolean {
    return !!this.user
  }

  @computed get isImpersonating (): boolean {
    return !!this.originalAuthToken
  }

  public impersonate = (authToken: string, authUser: AuthUser) => {
    const originalUser = this.user

    this.originalAuthToken = this.authToken

    this.authToken = authToken
    this.user = authUser

    cookies.set(this.ORIGINAL_AUTH_TOKEN_STORAGE_KEY, this.originalAuthToken, { path: '/' })
    cookies.set(this.ORIGINAL_USERNAME_STORAGE_KEY, originalUser!.username, { path: '/' })
    cookies.set(this.AUTH_TOKEN_STORAGE_KEY, this.authToken, { path: '/' })
  }

  public stopImpersonating = () => {
    this.user = undefined
    this.authToken = this.originalAuthToken
    this.originalAuthToken = undefined

    cookies.set(this.AUTH_TOKEN_STORAGE_KEY, this.authToken, { path: '/' })
    cookies.remove(this.ORIGINAL_AUTH_TOKEN_STORAGE_KEY, { path: '/' })
    cookies.remove(this.ORIGINAL_USERNAME_STORAGE_KEY, { path: '/' })
  }

  public getAuthTokenId (): string | null {
    return this.getAuthToken()
      ? this.getAuthToken()!.split(':')[0]
      : null
  }

  public getAuthToken (): string | undefined {
    return this.authToken
  }

  @action
  public clearAuthToken () {
    this.authToken = undefined
    this.originalAuthToken = undefined
    cookies.remove(this.AUTH_TOKEN_STORAGE_KEY, { path: '/' })
    localStorage.removeItem(this.AUTH_TOKEN_STORAGE_KEY)

    cookies.remove(this.ORIGINAL_AUTH_TOKEN_STORAGE_KEY, { path: '/' })
    cookies.remove(this.ORIGINAL_USERNAME_STORAGE_KEY, { path: '/' })
  }

  @action
  public setAuthToken (authToken: string, remember: boolean) {
    this.authToken = authToken
    cookies.set(this.AUTH_TOKEN_STORAGE_KEY, authToken, { path: '/' })
    cookies.remove(this.ORIGINAL_USERNAME_STORAGE_KEY, { path: '/' })
    cookies.remove(this.ORIGINAL_AUTH_TOKEN_STORAGE_KEY, { path: '/' })
    this.originalAuthToken = undefined

    if (remember) {
      localStorage.setItem(this.AUTH_TOKEN_STORAGE_KEY, authToken)
    } else {
      localStorage.removeItem(this.AUTH_TOKEN_STORAGE_KEY)
    }
  }

  @action
  public restoreAuthToken () {
    if (!this.authToken) {
      this.authToken = cookies.get(this.AUTH_TOKEN_STORAGE_KEY) || localStorage.getItem(this.AUTH_TOKEN_STORAGE_KEY) || undefined
      this.originalAuthToken = cookies.get(this.ORIGINAL_AUTH_TOKEN_STORAGE_KEY) || undefined
    }
  }

  public getUser (): AuthUser | undefined {
    return this.user
  }

  @action
  public setUser (user: AuthUser) {
    this.user = user
  }

  public getOriginalUsername (): string | undefined {
    return cookies.get(this.ORIGINAL_USERNAME_STORAGE_KEY)
  }

  @action
  public login (authToken: string, user: AuthUser, remember: boolean) {
    this.user = user
    this.setAuthToken(authToken, remember)
  }

  @action
  public logout () {
    this.user = undefined
    this.clearAuthToken()
    this._didLogOut = true
  }

  @action
  public clearDidLogOut () {
    this._didLogOut = false
  }

  public requestImpersonate = (data: { user_id: number } | { member_id: number }) => {
    return ApiClient.getInstance()
      .post(ApiRoutes.impersonate, data)
      .then(response => {
        this.impersonate(response.data.auth_key, new AuthUser(modelToCamelCase(response.data.user)))
      })
  }

  public generateTempAuthToken = () => {
    const ts = Math.floor(new Date().getTime() / 1000)

    return btoa(JSON.stringify({
      i: this.getAuthTokenId(),
      t: ts,
      k: sha1(this.getAuthToken()! + ts)
    }))
  }
}

const auth = new AuthStore()

export default auth as AuthStore
