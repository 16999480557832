import BaseView from '../BaseView'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { route } from '../../routes/routes'
import { Routes } from '../../routes/AppRoutes'
import { observable } from 'mobx'
import { createBrowserHistory } from 'history'
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, TabContent } from 'reactstrap'
import classNames from 'classnames'
import LazyTabPane from '../../components/LazyTabPane'
import { observer } from 'mobx-react'
import { InviteExtendedNetworkMemberModal } from '../../components/member/InviteExtendedNetworkMemberModal'
import EventBus from '../../common/EventBus'
import ExtendedNetworkMembers from '../../components/member/ExtendedNetworkMembers'
import ExtendedNetworkCategories from '../../components/member/ExtendedNetworkCategories'
import ApiClient from '../../api/ApiClient'
import Util, { copyToClipboard } from '../../common/Util'
import { toast } from 'react-toastify'

type Props = {
  match: {
    params: {
      tab?: string
    }
  }
} & RouteComponentProps

export const TABS = {
  invitedMembers: 'members',
  categories: 'categories',
}

@observer
export default class ExtendedNetworkView extends BaseView<Props> {
  @observable private activeTab = TABS.invitedMembers
  @observable private renderInviteModal = false
  @observable private renderInviteUrlModal = false
  private eventBus = new EventBus()

  private history = createBrowserHistory()

  componentDidMount (): void {
    super.componentDidMount()

    this.activeTab = this.props.match.params.tab || TABS.invitedMembers
  }

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(route(Routes.member.extendedNetwork, { tab: this.activeTab }))
    }
  }

  private showInviteModal = () => {
    this.renderInviteModal = true
  }

  private showInviteUrlModal = () => {
    this.renderInviteUrlModal = true
  }

  renderContentHeader (): React.ReactNode | null {
    return <>
      <h1>My Extended Network</h1>
      <ul className="content-header-actions">
        <li>
          <Button type="button" color="primary" onClick={this.showInviteUrlModal}><i className="fa fa-share"/> Share Public Invitation Link</Button>
        </li>
        <li>
          <Button type="button" color="primary" onClick={this.showInviteModal}><i className="fa fa-user"/> Send Invitation to Your Employee, family, friend, clients</Button>
        </li>
      </ul>
    </>
  }

  render (): React.ReactNode {
    return <>
      <div className="card">
        <div className="card-header">
          <Nav tabs className="card-header-tabs">
            <NavItem className={classNames({ active: this.activeTab === TABS.invitedMembers })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.invitedMembers)
                }}
              >Invited Contacts</NavLink>
            </NavItem>
            <NavItem className={classNames({ active: this.activeTab === TABS.categories })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.categories)
                }}
              >Business Categories</NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="card-body">
          <TabContent activeTab={this.activeTab}>
            <LazyTabPane tabId={TABS.invitedMembers} activeTab={this.activeTab}>
              <ExtendedNetworkMembers eventBus={this.eventBus}/>
            </LazyTabPane>
            <LazyTabPane tabId={TABS.categories} activeTab={this.activeTab}>
              <ExtendedNetworkCategories/>
            </LazyTabPane>
          </TabContent>
        </div>
      </div>
      {
        this.renderInviteUrlModal
          ? <InviteUrlModal
            onClosed={() => this.renderInviteUrlModal = false}
          />
          : null
      }
      {
        this.renderInviteModal
          ? <InviteExtendedNetworkMemberModal
            onClosed={() => this.renderInviteModal = false}
            eventBus={this.eventBus}
          />
          : null
      }
    </>
  }
}


type InviteUrlModalProps = {
  onClosed: () => void
}

@observer
class InviteUrlModal extends React.Component<InviteUrlModalProps> {
  @observable loading = false
  @observable loaded = false
  @observable show = true
  @observable error?: string
  @observable extendedNetworkJoinUrl?: string

  componentDidMount () {
    this.loadUrl().then()
  }

  private loadUrl = async () => {
    this.error = undefined
    this.loading = true

    try {
      const response = await ApiClient.getInstance().get(`/extended-network/join-url`)
      this.extendedNetworkJoinUrl = response.data.url
    } catch (err) {
      this.error = Util.extractErrorMessage(err)
    }

    this.loading = false
    this.loaded = true
  }

  private toggle = () => {
    this.show = false
  }

  render () {
    return <Modal size={'md'} toggle={this.toggle} onClosed={this.props.onClosed} isOpen={this.show}>
      <ModalHeader toggle={this.toggle}>
        Join Extended Network URL
      </ModalHeader>
      <ModalBody>
        {
          this.error
            ? <Alert color={'danger'}>{this.error}</Alert>
            : null
        }
        <p>
          You can share the following URL with your employees, family, friends, and clients to let them sign up to be a part of your extended network.
        </p>
        {
          this.extendedNetworkJoinUrl
            ? <div className={'d-flex'} style={{ alignItems: 'center' }}>
              <div className={'flex-fill'}>
                <input className="form-control" value={this.extendedNetworkJoinUrl} readOnly/>
              </div>
              <div>
                <Button color={'primary'} onClick={() => {
                  copyToClipboard(this.extendedNetworkJoinUrl || '')
                  toast.success('Link copied to clipboard')
                }}>Copy</Button>
              </div>
            </div>
            : null
        }
      </ModalBody>
      <ModalFooter>
        <Button color={'secondary'} onClick={this.toggle}>Close Window</Button>
      </ModalFooter>
    </Modal>
  }
}
