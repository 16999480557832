import React from "react"
import { action, computed, observable } from "mobx"
import { observer } from "mobx-react"
import { Button } from "reactstrap"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import { route } from "../../routes/routes"
import { toast } from "react-toastify"
import Util from "../../common/Util"
import AppStateStore from "../../stores/AppStateStore"
import FormState from '../../common/FormState'
import ErrorBag from '../../common/ErrorBag'
import FormHelper from '../../forms/FormHelper'
import TextEditor from "../editor/TextEditor"
import { StringDecoder } from "string_decoder"

@observer
export default class AdminEmailTemplates extends React.Component {
  private iframeRef = React.createRef<HTMLIFrameElement>()

  @observable private phoneNumber = ''
  @observable private emailAddress = ''

  @observable private emailTemplates = [
    'emails.guest.invitation'
  ]

  @observable private smsTemplates = [
    'sms.calendar-event-canceled-notification'
  ]

  @observable private formState = new FormState({})
  @observable private formErrors = new ErrorBag()
  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable private selectedTemplate = ''
  @observable private selectedOption = '';
  @observable private templatePreview?: {subject: string, preview: string}
  @observable private rendered = false
  @observable private updatedTemplate:any;

  @computed get isSmsTemplate () {
    return this.smsTemplates.indexOf(this.selectedTemplate) > -1
  }


onChangeTemplate = (updatedTemplate: string) => {
  this.updatedTemplate = updatedTemplate;
}

  componentDidUpdate (prevProps: Readonly<any>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.templatePreview && this.iframeRef.current && !this.rendered) {
      this.rendered = true
      this.iframeRef.current.src = 'javascript:void((function(){var script = document.createElement(\'script\');' +
      'script.innerHTML = "(function() {' +
      'document.open();document.domain=\'' + document.domain +
      '\';document.close();})();";' +
      'document.write(' + JSON.stringify(this.templatePreview.preview) + ');})())'
    }
  }


  private saveTemplate = (e:any) => {
    e.preventDefault();
    AppStateStore.showModalSpinner()
    const qs = {
      type: this.isSmsTemplate ? 'sms' : 'email',
    }
    ApiClient.getInstance().post(route(ApiRoutes.emailTemplates.saveTemplate, { templateName: this.selectedTemplate }, qs), {
      html: this.updatedTemplate
    })
    .then(response => toast.success('Template Updated'))
    .catch(error => toast.error(Util.extractErrorMessage(error)))
    .then(() => AppStateStore.dismissModalSpinner())
  }

  private loadPreview = (e:any) => {
    this.iframeRef.current && (this.iframeRef.current.src = 'about:blank')
    this.rendered = false
    const qs = {
      data: this.formHelper.toObject(),
      type: this.isSmsTemplate ? 'sms' : 'email',
    }
    ApiClient.getInstance().get(route(ApiRoutes.emailTemplates.previewTemplate, { templateName: this.selectedTemplate }, qs))
      .then(response => {
        this.templatePreview = response.data;
        this.updatedTemplate = this.templatePreview!.preview;
      })
      .catch(error => toast.error(Util.extractErrorMessage(error)))
  }

  private restoreToDefault = (e:any) => {
    this.templatePreview={subject:'', preview:''};
    AppStateStore.showModalSpinner()
    this.rendered = false
    const qs = {
      data: this.formHelper.toObject(),
      type: this.isSmsTemplate ? 'sms' : 'email',
    }
    ApiClient.getInstance().get(route(ApiRoutes.emailTemplates.restoreToDefault, { templateName: this.selectedTemplate }, qs))
      .then(response => {
        this.templatePreview = response.data;
        this.updatedTemplate = this.templatePreview!.preview;
        AppStateStore.dismissModalSpinner();
      })
      .catch(error => toast.error(Util.extractErrorMessage(error)))
  }

  private sendPreview = () => {
    AppStateStore.showModalSpinner()

    const qs = {
      data: this.formHelper.toObject(),
      type: this.isSmsTemplate ? 'sms' : 'email',
    }

    this.rendered = false
    ApiClient.getInstance().post(route(ApiRoutes.emailTemplates.sendPreview,
      { templateName: this.selectedTemplate }, qs),
      { email_address: this.emailAddress, phone_number: this.phoneNumber, preview: this.updatedTemplate})
      .then(response => toast.success('Preview sent'))
      .catch(error => toast.error(Util.extractErrorMessage(error)))
      .then(() => AppStateStore.dismissModalSpinner())
  }

  private showForm = () => {
    return <>
      {
        this.isSmsTemplate
          ? <input
            type="text"
            className="form-control"
            placeholder="Enter Phone Number"
            value={this.phoneNumber}
            onChange={ev => this.phoneNumber = ev.target.value}
          />
          : <input
            type="email"
            className="form-control"
            placeholder="Enter Email Address"
            value={this.emailAddress}
            onChange={ev => this.emailAddress = ev.target.value}
          />
      }
      <Button
        type="button"
        color="primary"
        style={{width:"250px"}}
        onClick={this.sendPreview}
      >Send Preview</Button>
    </>
  }

  private renderTemplateForm = () => {
    if (this.selectedTemplate === 'emails.member.renewal-invoice') {
      return <>
        <div>
          {this.formHelper.renderSelectInput({
            name: 'type',
            label: 'Type',
            options: [
              { value: 'initial', text: 'Initial Invoice' },
              { value: 'reminder', text: '14 Day Reminder' },
              { value: 'late', text: 'Late Notice' },
            ],
          })}
        </div>
        <div>
          {this.formHelper.renderCheckboxInput({
            name: 'isAutoRenewal',
            label: 'Is Auto Renewal',
          })}
        </div>
        <div>
          {this.formHelper.renderCheckboxInput({
            name: 'autoRenewDiscount',
            label: 'Auto Renew Discount',
          })}
        </div>
      </>
    } else {
      return null
    }
  }

  private setTemplate = action((templateName: string) => {
    if (templateName === 'emails.member.renewal-invoice') {
      this.formState = new FormState({
        type: 'initial',
        isAutoRenewal: false,
        autoRenewDiscount: false,
      })
    } else {
      this.formState = new FormState({})
    }

    this.selectedTemplate = templateName
    this.formErrors.clearErrors()
    this.formHelper = new FormHelper(this.formState, this.formErrors)
  })

  render () {
    return <div>
      <div style={{ display: 'flex', gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))', gridGap: '15px' }}>
        <select
          className="form-control"
          style={{width: 300}}
          value={this.selectedTemplate}
          onChange={ev => this.setTemplate(ev.target.value)}
        >
          <option value="">(select a template)</option>
          {/* <optgroup label="SMS">
            {this.smsTemplates.map(s => <option key={s} value={s}>{s}</option>)}
          </optgroup> */}
          <optgroup label="Email">
            {this.emailTemplates.map(s => <option key={s} value={s}>{s}</option>)}
          </optgroup>
        </select>

        {
          this.templatePreview
            ? this.showForm()
            : null
        }

      </div>
      <div>
        {this.renderTemplateForm()}
      </div>
      <div>
        {
          this.selectedTemplate
            ? <Button
              type="button"
              color="primary"
              style={{marginTop: '10px'}}
              onClick={this.loadPreview}
            >Preview</Button>
            : <span>Choose a template to preview</span>
        }
        {
          this.templatePreview
            ? <Button
              type="button"
              color="primary"
              style={{marginLeft: '10px', marginTop: "10px"}}
              onClick={this.saveTemplate}>Save</Button>
            :null
        }
        {
          this.templatePreview
            ? <Button
              type="button"
              color="primary"
              style={{marginLeft: '10px', marginTop: "10px"}}
              onClick={this.restoreToDefault}>Restore To Default</Button>
            :null
        }
      </div>




      {/* {
        this.templatePreview
          ? <div className="mt-4" key={this.selectedTemplate}>
            <h5>SUBJECT: {this.templatePreview.subject}</h5>
            <iframe ref={this.iframeRef} style={{ width: '100%', height: '100%', minHeight: 600 }} className="mt-4"/>
          </div>
          : null
      } */}

      {
        this.templatePreview?
        <div style={{marginTop: "20px"}}>
          <h5>SUBJECT: {this.templatePreview.subject}</h5>
          <TextEditor text={this.templatePreview.preview} onChangeTemplate={this.onChangeTemplate} isSmsTemplate={this.isSmsTemplate}/>
      </div>
          : null
      }

    </div>
  }
}
