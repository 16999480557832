import * as React from "react"
import { QueryWhereClause } from "../api/ApiClient"
import { observer } from "mobx-react"
import { observable } from "mobx"
import BaseView from "./BaseView"
import { Routes } from "../routes/AppRoutes"
import { route } from "../routes/routes"
import { Link } from "react-router-dom"
import classNames from 'classnames'
import Member from "../models/Member"
import { ApiTableViewAdapter } from "../components/table-view/ApiTableViewAdapter"
import { ManagedChooseableColumn } from "../components/table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "../components/table-view/ManagedTableView";
import PendingMembersCsvTransformer from "../models/renderers/PendingMembersCsvTransformer";
import SelectFilter, { SelectFilterTargetType } from '../components/table-view/filters/SelectFilter'
import AsyncSelectFilter, { AsyncSelectFilterTargetType } from '../components/table-view/filters/AsyncSelectFilter'
import { loadChapterOptionsForAdmin, loadChapterStateOptions, loadMemberOptions } from '../api/AsyncHelpers'
import TextFilter, { TextFilterTargetType } from '../components/table-view/filters/TextFilter'

@observer
export default class PendingMembersView extends BaseView {
  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>Members</h1>
      </>
    )
  }

  @observable availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'member',
      column: {
        title: 'Member',
        accessor: 'fullName',
        sortKey: 'lastName',
        renderItem: (value, item) => <Link type="link" to={{ pathname: route(Routes.system.members.show, { id: item.id }), state: { id: item.id } }}>
          <div className={classNames({ 'text-muted': item.isDisabled() })}>
            {value}{item.isDisabled() && ` (${item.status})`}
          </div>
        </Link>,
        sortable: true,
      },
      fixed: true,
    },
    {
      id: 'chapter',
      column: {
        title: 'Chapter',
        accessor: 'chapter.name',
        sortable: true,
        renderItem: (value, item) => item.chapter ? <Link type="link" to={{ pathname: route(Routes.system.chapters.show, { id: item.chapter.id }), state: { id: item.chapter.id } }}>
          {value}
        </Link> : null
      },
      default: true,
    },
    {
      id: 'category',
      column: {
        title: 'Category',
        accessor: 'category.name',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'company',
      column: {
        title: 'Company',
        accessor: 'business.name',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'applicationStatus',
      column: {
        title: 'Application Status',
        accessor: 'applicationStatus',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'onlineApp',
      column: {
        title: 'Online App',
        accessor: 'applicationNewMemberData.application',
        renderItem: app => app ? app.status : '---'
      },
      default: true,
    },
    {
      id: 'joinDate',
      column: {
        title: 'Join Date',
        accessor: 'joinDate',
        sortable: true,
        renderItem: value => value ? value.format('MM/DD/YYYY') : '---'
      },
      default: false,
    },
    {
      id: 'position',
      column: {
        title: 'Position',
        accessor: 'primaryMemberPosition.position.name',
        sortable: true,
      },
      default: false,
    },
    {
      id: 'badgeColor',
      column: {
        title: 'Badge Color',
        accessor: 'badgeColor',
        sortable: true,
      },
      default: false,
    },
    {
      id: 'ntsTraining',
      column: {
        title: 'NTS Training',
        accessor: 'ntsTrainingCompletedAt',
        sortable: true,
        renderItem: value => value ? 'Yes' : 'No'
      },
      default: false,
    },
    {
      id: 'email',
      column: {
        title: 'Email',
        accessor: 'emailAddress',
        sortable: true,
      },
      default: false,
    },
    {
      id: 'phone',
      column: {
        title: 'Phone',
        accessor: 'phoneNumber',
        sortable: true,
      },
      default: false,
    },
    {
      id: 'numberSponsored',
      column: {
        title: '# Sponsored',
        accessor: 'numberOfSponsoredMembers',
        sortable: true,
      },
      default: false,
    },
  ]

  private membersAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      where.push(
        { _scope: 'userIsAdmin' },
        { status: 'Pending' },
      )

      return {
        query: `
      members {
        *
        
        chapter {
          *
        }
        
        business {
          *
        }
        
        category {
          *
        }
        
        primaryMemberPosition {
          *
          
          position {
            *
          }
        }        
      }
    `,
        where: where,
      }
    },
    'members',
    Member
  )

  renderContentBody (): React.ReactNode {
    return this.renderTable()
  }

  private filters = [
    new TextFilter('member', TextFilterTargetType.Field, 'fullName', 'Member Name'),
    new AsyncSelectFilter('chapter', AsyncSelectFilterTargetType.Field, 'chapter.id', 'Chapter', loadChapterOptionsForAdmin),
    new AsyncSelectFilter('state', AsyncSelectFilterTargetType.Field, 'chapter.meetingLocationAddress.state', 'State', loadChapterStateOptions),
    new SelectFilter('status', SelectFilterTargetType.Field, 'applicationStatus', 'Status', [
      'No Payment Provided',
      'Declined/Invalid Credit/Debit Card',
      'Company Replacement Application - No Payment',
      'Chapter Transfer Application - No Payment',
      'Application has no email',
    ].map(s => ({ value: s, label: s }))),
  ]

  private renderTable = () => {
    return <ManagedTableView
      stateKey="PendingMembers"
      availableColumns={this.availableColumns}
      adapter={this.membersAdapter}
      searchLabel="Find A Member"
      exportTransformer={PendingMembersCsvTransformer}
      hideItemCount={true}
      defaultSort={{ sortKey: 'lastName' }}
      filters={this.filters}
    />
  }
}
