import * as React from "react"
import { CircleLoader } from "react-spinners"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"

type Props = {
  loading: boolean
}

@observer
export default class LoadingOverlay extends React.Component<Props> {
  render () {
    return this.props.loading ? this.renderOverlay() : null
  }

  private renderOverlay = () => {
    return (
      <div className="loading-overlay">
        <div className="loading-overlay-message">
          <div>
            <CircleLoader className="app-loading-spinner" color="#12497d"/>
          </div>
          <div className="loading-overlay-text">
            <a href="" onClick={ev => ev.preventDefault()} ref={input => input && input.focus()} onBlur={ev => ev.currentTarget.focus()}>Please wait...</a>
          </div>
        </div>
      </div>
    )
  }
}