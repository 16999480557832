import BaseView from './BaseView'
import { observer } from 'mobx-react'
import React from 'react'
import NewMembershipsDashboard from '../components/dashboard/NewMembershipsDashboard'
import { renderAsyncSelectInput } from '../forms/FormInputs'
import * as AsyncHelpers from '../api/AsyncHelpers'
import { computed, observable } from 'mobx'
import FormState from '../common/FormState'
import ResignedMembershipsDashboard from '../components/dashboard/ResignedMembershipsDashboard'
import TotalMembersDashboard from '../components/dashboard/TotalMembersDashboard'
import GuestInvitationsDashboard from '../components/dashboard/GuestInvitationsDashboard'
import ErrorBag from '../common/ErrorBag'
import ApplicationsDashboard from '../components/dashboard/ApplicationsDashboard'
import RenewalsDashboard from '../components/dashboard/RenewalsDashboard'
import NewChaptersDashboard from '../components/dashboard/NewChaptersDashboard'
import ClosedChaptersDashboard from '../components/dashboard/ClosedChaptersDashboard'
import ChaptersStartedDashboard from '../components/dashboard/ChaptersStartedDashboard'
import MembershipsGraph from '../components/dashboard/MembershipsGraph'
import MemberList from '../components/dashboard/MemberList'
import GuestList from '../components/dashboard/GuestList'
import ApplicationList from '../components/dashboard/ApplicationList'
import RenewalsList from '../components/dashboard/RenewalsList'
import ChapterList from '../components/dashboard/ChapterList'

type State = {
  areaId?: number
  state?: string
  chapterId?: number
  regionId?: string
}

@observer
export default class AdminDashboardView extends BaseView {
  @observable private searchFormState = new FormState<State>({
    areaId: undefined,
    state: undefined,
    chapterId: undefined,
    regionId: undefined
  })

  private formErrors = new ErrorBag()

  @observable stateKey = 1
  @observable areaKey = 1
  @observable chapterKey = 1
  @observable regionKey =1


  @observable view?: string
  @observable viewOptions?: any
  @observable listUrl?: string
  @observable viewTitle?: string

  private showMemberList = (url: string, title: string, options?: any) => {
    this.showView('memberList', url, title, options)
  }

  private showGuestList = (url: string, title: string) => {
    this.showView('guestList', url, title)
  }

  private showApplicationList = (url: string, title: string) => {
    this.showView('applicationList', url, title)
  }

  private showRenewalsList = (url: string, title: string) => {
    this.showView('renewalsList', url, title)
  }

  private showChaptersList = (url: string, title: string) => {
    this.showView('chaptersList', url, title)
  }

  private showView = (view?: string, url?: string, title?: string, options?: any) => {
    this.view = view
    this.viewOptions = options
    this.listUrl = url
    this.viewTitle = title
    window.scrollTo(0, 0)
  }

  @computed get filters () {
    return this.searchFormState.toObject()
  }

  renderContentHeader (): React.ReactNode | null {
    return <h1>{'Dashboard'}</h1>
  }

  renderContentBody (): React.ReactNode | null {

    return <div className="admin-dashboard">
      <div style={{ display: this.view ? 'none' : 'block' }}>
        <div className="row">
          <div className="col-md-3 col-sm-12" key={`state-${this.stateKey}`}>
            <label className="member-card-search-search-label">
              View By State
            </label>
            {renderAsyncSelectInput(this.searchFormState, undefined, {
              name: 'state',
              onChange: () => {
                this.chapterKey = 1 - this.chapterKey
                this.searchFormState.set('chapterId', undefined)
                this.areaKey = 1 - this.areaKey
                this.searchFormState.set('areaId', undefined)
                this.regionKey = 1 - this.regionKey
                this.searchFormState.set('regionId', undefined)
              },
              loadOptions: AsyncHelpers.loadChapterStateOptions,
            })}
          </div>
          <div className="col-md-3 col-sm-12" key={`area-${this.areaKey}`}>
            <label className="member-card-search-search-label">
              View By Area
            </label>
            {renderAsyncSelectInput(this.searchFormState, undefined, {
              name: 'areaId',
              onChange: () => {
                this.stateKey = 1 - this.stateKey
                this.searchFormState.set('state', undefined)
                this.chapterKey = 1 - this.chapterKey
                this.searchFormState.set('chapterId', undefined)
                this.regionKey = 1 - this.regionKey
                this.searchFormState.set('regionId', undefined)
              },
              loadOptions: AsyncHelpers.loadAreaOptions,
            })}
          </div>
          <div className="col-md-3 col-sm-12" key={`chapter-${this.chapterKey}`}>
            <label className="member-card-search-search-label">
              View By Chapter
            </label>
            {renderAsyncSelectInput(this.searchFormState, undefined, {
              name: 'chapterId',
              onChange: () => {
                this.stateKey = 1 - this.stateKey
                this.searchFormState.set('state', undefined)
                this.areaKey = 1 - this.areaKey
                this.searchFormState.set('areaId', undefined)
                this.regionKey = 1 - this.regionKey
                this.searchFormState.set('regionId', undefined)
              },
              loadOptions: AsyncHelpers.loadChapterOptions,
            })}
          </div>
          <div className="col-md-3 col-sm-12" key={`region-${this.regionKey}`}>
            <label className="member-card-search-search-label">
              View By Region
            </label>
            {renderAsyncSelectInput(this.searchFormState, undefined, {
              name: 'regionId',
              onChange: () => {
                this.stateKey = 1 - this.stateKey
                this.searchFormState.set('state', undefined)
                this.areaKey = 1 - this.areaKey
                this.searchFormState.set('areaId', undefined)
                this.chapterKey = 1 - this.chapterKey
                this.searchFormState.set('chapterId', undefined)
              },
              loadOptions: AsyncHelpers.loadRegionOptions,
            })}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-xl-4 col-lg-6">
            <NewMembershipsDashboard
              filters={this.filters}
              onShowGraphClicked={() => this.showView('newMemberships')}
              onShowMemberList={this.showMemberList}
            />
          </div>
          <div className="col-12 col-xl-4 col-lg-6">
            <ResignedMembershipsDashboard
              filters={this.filters}
              onShowMemberList={this.showMemberList}
            />
          </div>
          <div className="col-12 col-xl-4 col-lg-6">
            <TotalMembersDashboard
              filters={this.filters}
              onShowMemberList={this.showMemberList}
            />
          </div>
          <div className="col-12 col-xl-4 col-lg-6">
            <GuestInvitationsDashboard
              filters={this.filters}
              onShowGuestList={this.showGuestList}
            />
          </div>
          <div className="col-12 col-xl-4 col-lg-6">
            <ApplicationsDashboard
              filters={this.filters}
              onShowApplicationList={this.showApplicationList}
            />
          </div>
          <div className="col-12 col-xl-4 col-lg-6">
            <RenewalsDashboard
              filters={this.filters}
              onShowRenewalsList={this.showRenewalsList}
            />
          </div>
          <div className="col-12 col-xl-4 col-lg-6">
            <NewChaptersDashboard
              filters={this.filters}
              onShowChaptersList={this.showChaptersList}
            />
          </div>
          <div className="col-12 col-xl-4 col-lg-6">
            <ClosedChaptersDashboard
              filters={this.filters}
              onShowChaptersList={this.showChaptersList}
            />
          </div>
        </div>
        <hr/>
        <ChaptersStartedDashboard filters={this.filters}/>
      </div>
      {
        this.view
          ? this.renderView(this.view, this.filters, this.listUrl, this.viewTitle, this.viewOptions)
          : null
      }
    </div>
  }

  private renderView = (graph: string, filters: any, listUrl?: string, viewTitle?: string, viewOptions?: any) => {
    return <>
      <a href="" onClick={ev => {
        ev.preventDefault()
        this.showView(undefined)
      }
      }>Return</a>
      {
        graph === 'newMemberships'
          ? <MembershipsGraph filters={filters}/>
          : null
      }
      {
        graph === 'memberList'
          ? <MemberList url={listUrl!} title={viewTitle!} {...viewOptions}/>
          : null
      }
      {
        graph === 'guestList'
          ? <GuestList url={listUrl!} title={viewTitle!}/>
          : null
      }
      {
        graph === 'applicationList'
          ? <ApplicationList url={listUrl!} title={viewTitle!}/>
          : null
      }
      {
        graph === 'renewalsList'
          ? <RenewalsList url={listUrl!} title={viewTitle!}/>
          : null
      }
      {
        graph === 'chaptersList'
          ? <ChapterList url={listUrl!} title={viewTitle!}/>
          : null
      }
    </>
  }
}
