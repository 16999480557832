import { observer } from "mobx-react"
import * as React from "react"
import { SyntheticEvent } from "react"
import AuthStore from "../stores/AuthStore"
import { computed, observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import AppStateStore from "../stores/AppStateStore"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { toast } from "react-toastify"
import Util, { modelToCamelCase, modelToSnakeCase } from "../common/Util"
import FormError from "./FormError"
import Event from "../models/Event"
import { Button } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import { route } from "../routes/routes"
import moment from 'moment-timezone'
import * as AsyncHelpers from "../api/AsyncHelpers"
import { loadMemberOptions } from "../api/AsyncHelpers"
import * as _ from "lodash"
import HelpTooltip from './HelpTooltip'

export type InviteGuestToMeetingFormProps = {
  guestId: number
  onCancel: () => void
  onSaved: () => void
}

@observer
export default class InviteGuestToMeetingForm extends React.Component<InviteGuestToMeetingFormProps> {
  private initialFormState = {
    targetChapterId: AuthStore.getUser()!.chapter ? AuthStore.getUser()!.chapter.id : undefined,
    targetEventId: undefined,
    phoneConfirmationMemberId: undefined,
    emailConfirmationMemberId: undefined,
    message: '',
    sendEmailToSourceMember: false,
  }

  @observable private formState = new FormState(this.initialFormState)

  @observable private formErrors = new ErrorBag()
  @observable private submitting = false
  @observable private upcomingEvents: Event[] = []

  private fetchUpcomingEvents = () => {
    this.upcomingEvents = []

    if (this.formState.get('targetChapterId')) {
      ApiClient.query(`
events {
  *
}
    `, {
        where: [
          { _scope: 'chapter', value: this.formState.get('targetChapterId') },
          { _scope: 'canInviteGuests' },
          { id: 'startsAt', op: '>=', value: moment().subtract(2, 'week').toISOString() },
        ],
        order: [
          { id: 'startsAt' },
        ],
      })
        .then(response => {
          this.upcomingEvents = response.data.events.map((t: {}) => new Event().init(modelToCamelCase(t)))
        })
    }
  }

  @computed
  private get selectedEvent () {
    return this.formState.get('targetEventId') ? _.find(this.upcomingEvents, e => e.id == this.formState.get('targetEventId')) : undefined
  }

  @computed
  private get submitButtonLabel () {
    return (this.selectedEvent && this.selectedEvent.startsAt.isBefore(moment()))
      ? 'Add Guest'
      : 'Send Invitation'
  }

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private onSubmit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()

    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.getInstance().post(route(ApiRoutes.guests.inviteToMeeting, { id: this.props.guestId }), modelToSnakeCase(this.formHelper.toObject()))
      .then(response => {
        toast.success('Guest Invitation Sent')
        this.props.onSaved()
      })
      .catch(error => Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      }))
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  componentDidMount (): void {
    this.fetchUpcomingEvents()
  }

  render (): React.ReactNode {
    return <>
      <form onSubmit={this.onSubmit}>
        <div className="form-row">
          <div className="col-12">
            {this.formHelper.renderAsyncSelectInput({
              name: 'targetChapterId',
              label: 'Chapter',
              defaultValue: AuthStore.getUser()!.chapter ? { value: AuthStore.getUser()!.chapter.id, label: AuthStore.getUser()!.chapter.name } : undefined,
              loadOptions: AsyncHelpers.loadChapterOptions,
              onChange: () => {
                this.fetchUpcomingEvents()
                this.formState.setAll({
                  phoneConfirmationMemberId: undefined,
                  emailConfirmationMemberId: undefined,
                })
              },
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label>Event</label>
              {
                this.formState.get('targetChapterId')
                  ? <select
                    className="form-control"
                    name="targetEventId"
                    value={this.formState.get('targetEventId')}
                    onChange={this.formState.onChange}
                  >
                    <option value={undefined}>(choose event)</option>
                    {this.upcomingEvents.map(event => <option key={event.id} value={event.id}>{event.startsAt.format('MM/DD/YY')} - {event.title}</option>)}
                  </select>

                  : <div>Select a chapter to see upcoming events</div>
              }
              <FormError errors={this.formErrors} fieldName="targetEventId"/>
            </div>
          </div>
        </div>
        {
          this.formState.get('targetChapterId')
            ? <>
              <div className="form-row" key={this.formState.get('targetChapterId')}>
                <div className="col-6">
                  {
                    this.formHelper.renderAsyncSelectInput({
                      label: 'Phone Confirmation',
                      name: 'phoneConfirmationMemberId',
                      loadOptions: loadMemberOptions(this.formState.get('targetChapterId')),
                    })
                  }
                </div>
                <div className="col-6">
                  {
                    this.formHelper.renderAsyncSelectInput({
                      label: 'Email Confirmation',
                      name: 'emailConfirmationMemberId',
                      loadOptions: loadMemberOptions(this.formState.get('targetChapterId')),
                    })
                  }
                </div>
              </div>
            </>
            : null
        }
        <div className="form-row">
          <div className="col-sm-12">
            {this.formHelper.renderTextAreaInput({
              name: 'message',
              label: 'Personal Note / Message',
              labelAccessory: <HelpTooltip
                content="Review the new email format in the Document Library to see where your personal note shows in the email."
                placement="right"
              />,
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="form-check form-checkbox">
                <label>
                  <input type="checkbox"
                         className="form-check-input"
                         name="sendEmailToSourceMember"
                         onChange={this.formState.onChange}
                  />
                  <span className="label-text">Send a copy to the inviting email</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="form-buttons">
          <Button color="secondary" onClick={() => this.props.onCancel()}>Cancel</Button>
          <ButtonLoader type="submit" color="primary" loading={this.submitting}>{this.submitButtonLabel}</ButtonLoader>
        </div>
      </form>
    </>
  }
}
