import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import ButtonLoader from "../../ButtonLoader"
import { autorun, computed, observable } from "mobx"
import SelectInput, { SelectOption } from "../../inputs/SelectInput"
import { loadCategoryOptions } from "../../../api/AsyncHelpers"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import { route } from "../../../routes/routes"
import AppStateStore from "../../../stores/AppStateStore"
import Util from "../../../common/Util"
import { toast } from "react-toastify"
import Member from "../../../models/Member"

type UpdateMemberCategoryModalProps = {
  isOpen: boolean
  toggle: () => void
  onClosed: () => void
  onSaved: () => void
  memberId: number
}

@observer
export default class UpdateMemberCategoryModal extends React.Component<UpdateMemberCategoryModalProps> {
  @observable private submitting = false
  @observable private selectedCategory?: SelectOption
  @observable private member?: Member

  private loadMember = () => {
    ApiClient.query(
      `
member {
  *
}
      `,
      {
        where: [{ id: this.props.memberId }]
      }
    )
      .then(response => this.member = new Member().init(response.data.member))
      .catch(() => {
      })
  }

  componentDidMount (): void {
    this.loadMember()

    autorun(() => this.checkCategoryAvailability())
  }

  componentDidUpdate (prevProps: Readonly<UpdateMemberCategoryModalProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.memberId !== this.props.memberId) {
      this.loadMember()
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    if (this.selectedCategory) {
      this.submitting = true
      ApiClient.getInstance()
        .post(route(ApiRoutes.members.assignToCategory, { id: this.props.memberId }), { category_id: this.selectedCategory.value })
        .then(() => {
          toast.success('Member assigned to new category')
          this.props.onSaved()
        })
        .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
        .then(() => this.submitting = false)
    }
  }

  private toggle = () => {
    !this.submitting && this.props.toggle()
  }

  @observable private showCategoryAvailabilityWarning = false

  @computed
  private get showSameCategoryWarning () {
    return this.member && this.selectedCategory && this.selectedCategory.value == this.member.categoryId
  }

  private checkCategoryAvailability = () => {
    if (this.selectedCategory && this.member && this.member.chapterId) {
      ApiClient.getInstance()
        .get(route(ApiRoutes.chapters.checkCategoryAvailability, { id: this.member.chapterId, categoryId: this.selectedCategory.value }))
        .then(response => this.showCategoryAvailabilityWarning = !response.data.available)
        .catch(() => this.showCategoryAvailabilityWarning = false)
    }
  }

  render () {
    return <>
      <Modal isOpen={this.props.isOpen} toggle={this.toggle} onClosed={this.props.onClosed} size="md">
        <ModalHeader toggle={this.toggle}>
          Change Category
        </ModalHeader>
        <ModalBody>
          <p className="text-center">
            Select the category you would like to assign this member to.
          </p>
          <div className="form-group mt-4">
            <SelectInput
              loadOptions={loadCategoryOptions()}
              onChange={(option: SelectOption) => this.selectedCategory = option}/>
          </div>
          {
            this.showSameCategoryWarning
              ? <div className="alert alert-warning">This is the same category the member already belongs to</div>
              : this.showCategoryAvailabilityWarning
              ? <div className="alert alert-warning">The category you have selected is already filled for this chapter. Are you sure you want to choose this category?</div>
              : null
          }
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={this.toggle}
          >Cancel</Button>
          <ButtonLoader
            type="button"
            color="primary"
            disabled={!this.selectedCategory}
            onClick={this.submit}
            loading={this.submitting}
          >Assign Member to Category</ButtonLoader>
        </ModalFooter>
      </Modal>
    </>
  }
}