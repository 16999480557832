import { QueryWhereClause } from "../../../api/ApiClient"
import React from 'react'
import EventBus from '../../../common/EventBus'
import { observable } from 'mobx'

export default abstract class BaseFilter<T> {
  abstract id: string

  abstract get state (): any

  abstract get isActive (): boolean

  abstract get description (): string

  abstract restoreState (state: T): void

  abstract getWhereClause (): QueryWhereClause[]

  abstract render (eventBus: EventBus): React.ReactNode

  @observable public isExternal: boolean = false
}