import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import FormState from "../../../common/FormState"
import { observable } from "mobx"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"
import moment from 'moment-timezone'
import { Button } from "reactstrap"
import { BarLoader } from "react-spinners"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import Util, { modelToCamelCase, modelToSnakeCase } from "../../../common/Util"
import { Moment } from "moment"
import { route } from "../../../routes/routes"
import ExportButton, { ExportButtonFetchCallback } from '../../ExportButton'
import ChapterTipOpenCsvTransformer from '../../../models/renderers/ChapterTipOpenCsvTransformer'
import { ChapterTipOpenReportData } from './ChapterTipOpenReport'
import MemberLossCsvTransformer from '../../../models/renderers/MemberLossCsvTransformer'

export type MemberLossReportData = {
  member: {
    id: number
    fullName: string
    joinDate: string | null
    status: string
    badgeColor: string | null
  }
  membership: {
    id: number
    endsAt: string
  }
  category: {
    id: number
    name: string
  } | null
  chapter: {
    id: number
    name: string
  } | null
}

type ReportData = {
  members: MemberLossReportData[]
}

@observer
export default class MemberLossReport extends React.Component {
  @observable private loading = false
  @observable private error?: string
  @observable private formState = new FormState({
    startDate: moment().startOf('month').format('MM/DD/YYYY'),
    endDate: moment().endOf('month').format('MM/DD/YYYY'),
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable reportData?: ReportData

  @observable startDate?: Moment
  @observable endDate?: Moment

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.error = undefined
    this.loading = true

    ApiClient.getInstance()
      .post(route(ApiRoutes.reporting.memberLossReport), modelToSnakeCase(this.formState.toObject()))
      .then(response => {
        const data: any = modelToCamelCase(response.data.report_data)

        this.reportData = {
          members: data.members,
        }

        this.startDate = moment(response.data.start_date);
        this.endDate = moment(response.data.end_date)
      })
      .catch(error => this.error = Util.extractErrorMessage(error.response))
      .then(() => this.loading = false)
  }

  render () {
    return <>
      <div className="print-hide">
        <form action="" method="post" onSubmit={this.submit}>
          <div className="row align-items-end">
            <div className="col-md-3">
              {this.formHelper.renderDatePickerInput({
                label: 'Start Date',
                name: 'startDate',
              })}
            </div>
            <div className="col-md-3">
              {this.formHelper.renderDatePickerInput({
                label: 'End Date',
                name: 'endDate',
              })}
            </div>
            <div className="col-md-6 d-flex">
              <div className="form-group d-flex flex-column">
                <div className="flex-fill"/>
                <div>
                  <Button
                    disabled={this.loading || !this.formState.get('startDate').length || !this.formState.get('endDate').length}
                    style={{
                      height: 'calc(2.95rem + 2px)'
                    }}
                    type="submit"
                    color="success"
                  ><i className="fa fa-play"/> Run Report</Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {
        this.loading
          ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
          : this.error
            ? <div className="alert alert-danger">{this.error}</div>
            : this.reportData
              ? this.renderReport()
              : <p>Choose report parameters above</p>
      }
    </>
  }

  private fetchPrintData: ExportButtonFetchCallback<MemberLossReportData> = (onFetched, onError, onFinished) => {
    if (this.reportData) {
      onFetched(this.reportData.members)
    }

    onFinished()
  }

  private renderReport () {
    const reportData = this.reportData!
    const startDate = this.startDate!
    const endDate = this.endDate!

    return <>
      <h1 className="text-center my-4"> Member Loss Report</h1>
      <h3 className="text-center mb-4">{startDate.format('MMMM D, YYYY')} - {endDate.format('MMMM D, YYYY')}</h3>
      <div className="form-buttons mb-4 print-hide">
        <ExportButton
          fetch={this.fetchPrintData}
          transformerClass={() => new MemberLossCsvTransformer()}
        />
        <Button
          type="button"
          color="primary"
          onClick={() => window.print()}
        ><i className="fa fa-print"/> Print Report</Button>
      </div>
      <table className="chapter-tip-report-table">
        <thead>
        <tr>
          <th/>
          <th className="border-left border-top border-bottom text-left">Member Name</th>
          <th className="border-top border-bottom text-left">Category</th>
          <th className="border-top border-bottom text-left">Chapter</th>
          <th className="border-top border-bottom text-center">Badge Color</th>
          <th className="border-left border-top border-bottom text-right">Join Date</th>
          <th className="border-top border-bottom border-right text-right">Termination Date</th>
          <th className="border-left border-top border-bottom border-right text-center">Status</th>
        </tr>
        </thead>
        <tbody>
        {
          reportData.members.map((member, index) => <tr key={member.membership.id}>
            <td className="text-right">{index + 1}</td>
            <td style={{ whiteSpace: 'nowrap' }}>
              {member.member.fullName}
            </td>
            <td style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: 'left' }}>{member.category ? member.category.name : '---'}</td>
            <td style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: 'left' }}>{member.chapter ? member.chapter.name : '---'}</td>
            <td style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: 'center' }}>{member.member.badgeColor ? member.member.badgeColor : '---'}</td>
            <td style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: 'right' }}>{member.member.joinDate ? moment(member.member.joinDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : '---'}</td>
            <td style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: 'right' }}>{member.membership.endsAt ? moment(member.membership.endsAt, 'YYYY-MM-DD').format('MM/DD/YYYY') : '---'}</td>
            <td style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: 'center' }}>{member.member.status}</td>
          </tr>)
        }
        </tbody>
      </table>
    </>
  }
}
