import BaseView from "../BaseView"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observable } from "mobx"
import { Link } from "react-router-dom"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { ApiTableViewAdapter } from "../../components/table-view/ApiTableViewAdapter"
import { QueryWhereClause } from "../../api/ApiClient"
import { Alert } from "reactstrap"
import { createBrowserHistory } from "history"
import * as _ from "lodash"
import Chapter from "../../models/Chapter"
import { observer } from "mobx-react"
import { ManagedChooseableColumn } from "../../components/table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "../../components/table-view/ManagedTableView";
import AsyncSelectFilter, { AsyncSelectFilterTargetType } from '../../components/table-view/filters/AsyncSelectFilter'
import { loadAreaOptions, loadChapterStateOptions } from '../../api/AsyncHelpers'

@observer
export default class NetworkChapterSearchView extends BaseView {
  private history = createBrowserHistory()

  renderContentHeader () {
    return <>
      <h1>LeTip International Chapter Search</h1>
    </>
  }

  @observable private filteredArea?: {id: number, name: string}
  @observable private filteredRegion?: {id: number, name: string}

  componentWillMount (): void {
    this.filteredArea = _.get(this, 'props.location.state.area')
    this.filteredRegion = _.get(this, 'props.location.state.region')
  }

  private availableColumns: ManagedChooseableColumn[] = [
    {
      id: '',
      column: {
        title: 'Chapter',
        accessor: 'name',
        renderItem: (value, item) => <Link type="link" to={{ pathname: route(Routes.network.chapter, { chapterId: item.id }) }}>
          {value}
        </Link>,
        sortable: true,
      },
      fixed: true,
    },
    {
      id: '',
      column: {
        title: 'Area',
        accessor: 'area.name',
        sortable: true,
      },
      default: true,
    },
    {
      id: '',
      column: {
        title: 'State',
        accessor: 'meetingLocationAddress.state',
        sortable: true,
      },
      default: true,
    },
    {
      id: '',
      column: {
        title: 'Members',
        accessor: 'numberOfActiveMembers',
        sortable: true,
        headerClassName: "justify-content-center",
        itemClassName: "text-center",
        renderItem: (value, item) => <Link type="link" to={{ pathname: route(Routes.network.members), state: { chapter: { id: item.id, name: item.name } } }}>
          {value}
        </Link>,
      },
      default: true,
    },
  ]

  private tableViewRef = React.createRef<ManagedTableView>()

  private chaptersAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      if (this.filteredArea) {
        where.push({
          _scope: 'area',
          value: this.filteredArea.id,
        })
      }

      if (this.filteredRegion) {
        where.push({
          _scope: 'region',
          value: this.filteredRegion.id,
        })
      }

      where.push({ _scope: 'active' })

      return {
        query: `
      chapters {
        *
        area {
          *
          region {
            *
          }
        }
        
        meetingLocationAddress {
          *
        }
      }
    `,
        where: where,
      }
    },
    'chapters',
    Chapter
  )

  private removeAreaFilter = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.history.replace(window.location.pathname, { ...this.history.location.state, area: undefined })
    this.filteredArea = undefined
    this.tableViewRef.current!.fetchData()
  }

  private removeRegionFilter = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.history.replace(window.location.pathname, { ...this.history.location.state, region: undefined })
    this.filteredRegion = undefined
    this.tableViewRef.current!.fetchData()
  }

  renderContentBody (): React.ReactNode {
    return this.renderTable()
  }

  private renderTableHeader = () => {
    return <>
      {this.filteredArea && (
        <Alert
          color="info"
        >
          You are currently only viewing chapters in the area: <b>{this.filteredArea.name}</b><br/>
          <a onClick={this.removeAreaFilter.bind(this)} href="">Click here to view all areas</a>
        </Alert>
      )}
      {this.filteredRegion && (
        <Alert
          color="info"
        >
          You are currently only viewing chapters in the region: <b>{this.filteredRegion.name}</b><br/>
          <a onClick={this.removeRegionFilter.bind(this)} href="">Click here to view all regions</a>
        </Alert>
      )}
    </>
  }

  private filters = [
    new AsyncSelectFilter('area', AsyncSelectFilterTargetType.Field, 'area.id', 'Area', loadAreaOptions),
    new AsyncSelectFilter('state', AsyncSelectFilterTargetType.Field, 'meetingLocationAddress.state', 'State', loadChapterStateOptions),
  ]

  private renderTable = () => {
    return <ManagedTableView
      stateKey="NetworkChapterSearchView"
      ref={this.tableViewRef}
      hideItemCount={true}
      adapter={this.chaptersAdapter}
      availableColumns={this.availableColumns}
      searchLabel="Search By Name"
      tableHeader={this.renderTableHeader()}
      filters={this.filters}
    />
  }
}