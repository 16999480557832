import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { Alert } from "reactstrap"
import BaseView from "../BaseView"
import { route } from "../../routes/routes"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import Util, { formatCurrency, logException, modelToSnakeCase } from "../../common/Util";
import FormState from '../../common/FormState'
import { observable } from 'mobx'
import ErrorBag from '../../common/ErrorBag'
import FormHelper from '../../forms/FormHelper'
import AppStateStore from '../../stores/AppStateStore'
import ButtonLoader from '../../components/ButtonLoader'

type Props = {}

@observer
export default class RenewalFeeCalculatorView extends BaseView<Props> {
  @observable private formState = new FormState({
    joinDate: '',
    now: '',
    isProfileComplete: false,
    hasSponsoredMember: false,
    isDiscountedRenewal: false,
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable private renewalFee?: number
  @observable private submitting = false

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    AppStateStore.showModalSpinner()
    this.submitting = true

    ApiClient.getInstance().post(route(ApiRoutes.devTools.calculateRenewalFee), modelToSnakeCase(this.formHelper.toObject()))
      .then(response => {
        this.renewalFee = Number(response.data.renewal_fee)
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>Renewal Fee Calculator</h1>
      </>
    )
  }

  renderContentBody (): React.ReactNode {
    return <>
      <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
        <div className="form-row">
          <div className="col-sm-6">
            {this.formHelper.renderDatePickerInput({
              name: 'joinDate',
              label: 'Join Date',
            })}
          </div>
          <div className="col-sm-6">
            {this.formHelper.renderDatePickerInput({
              name: 'now',
              label: 'Renewal Date',
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-12">
            {this.formHelper.renderCheckboxInput({
              name: 'isProfileComplete',
              label: 'Member has completed their profile',
              helpContent: 'Check this box if the member has completed their profile, and uploaded a video and photo',
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-12">
            {this.formHelper.renderCheckboxInput({
              name: 'hasSponsoredMember',
              label: 'Member has sponsored a member',
              helpContent: 'Check this box if the member has sponsored a member in their first year',
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-12">
            {this.formHelper.renderCheckboxInput({
              name: 'isDiscountedRenewal',
              label: 'Member is on discounted renewal track',
              helpContent: 'Check this box if the member has sponsored a member in each year up to this renewal',
            })}
          </div>
        </div>
        <div className="form-buttons">
          <ButtonLoader type="submit" color="success" loading={this.submitting}>Calculate Renewal Fee</ButtonLoader>
        </div>
      </form>

      {
        this.renewalFee
          ? <Alert color="success" style={{ marginTop: 20 }}>
            Renewal fee is {formatCurrency(this.renewalFee)}
          </Alert>
          : null
      }
    </>
  }
}