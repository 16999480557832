import { observable } from "mobx"
import ILazyResource from "./ILazyResource"

export type LazyResourceLoaderType<T> = (callback: (resource: T) => void, error: (message: string, errorNumber?: number) => void) => void

const uuid4 = require('uuid/v4')

export default class LazyResource<T> implements ILazyResource<T> {
  @observable
  loaded: boolean = false
  @observable
  loading: boolean = false
  @observable
  error?: string
  @observable
  errorCode?: number
  @observable
  private _current?: T
  private promise?: Promise<T>

  private uuid = uuid4()

  get current (): T | undefined {
    if (!this.loaded) {
      setTimeout(() => this.refresh().then(() => {
      }))
    }
    return this._current
  }

  set current (c: T | undefined) {
    this._current = c

    this.loaded = true
    this.loading = false
    this.error = undefined
    this.invalid = false
  }

  @observable
  private invalid = true
  private readonly loader: LazyResourceLoaderType<T>

  get isInvalid () {
    return this.invalid
  }

  constructor (load: LazyResourceLoaderType<T>) {
    this.loader = load
  }

  invalidate = () => {
    this.invalid = true
    return this.refresh()
  }

  refresh: (force?: boolean) => Promise<T> = (force: boolean = false) => {
    if (this.promise) {
      return this.promise
    }

    this.loading = true

    this.promise = new Promise<T>(resolve => {
      if (this.invalid || force) {
        this.error = undefined
        this.invalid = false
        this.loader((resource: T) => {
          this.promise = undefined
          this.loaded = true
          this._current = resource
          this.loading = false
          resolve(resource)

          if (this.invalid) {
            this.refresh().then(() => {
            })
          }
        }, (errorMessage: string, errorCode?: number) => {
          this.promise = undefined
          this.loaded = true
          this.current = undefined
          this.error = errorMessage
          this.errorCode = errorCode
          this.loading = false
          this.invalid = true
          resolve()
        })
      } else {
        this.promise = undefined
        this.loading = false
        resolve()
      }
    })

    return this.promise
  }

  get (): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.refresh().then(resource => {
        if (this.error) {
          reject(this.error)
        } else {
          resolve(this._current)
        }
      })
    })
  }
}