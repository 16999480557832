import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Invoice from "./Invoice"
import Membership from "./Membership"
import RenewalExemption from "./RenewalExemption"

export default class MembershipRenewal extends BaseModel {
  id: number
  slug: string
  lateFeeAppliedAt: Moment
  renewalFee: number
  lateFee: number
  renewalDate: Moment
  dueDate: Moment
  lateDate: Moment
  terminationDate: Moment
  exemptUntil: Moment | null

  invoice: Invoice
  membership: Membership
  renewalExemptions: RenewalExemption[]

  setInvoice (i: {}) {
    return i ? new Invoice().init(i) : null
  }

  setMembership (m: {}) {
    return m ? new Membership().init(m) : null
  }

  setRenewalExemptions (r: []) {
    return r ? r.map(rr => new RenewalExemption().init(rr)) : []
  }

  getCasts (): {} {
    return {
      lateFeeAppliedAt: 'datetime',
      renewalFee: 'number',
      lateFee: 'number',
      renewalDate: 'datetime',
      dueDate: 'datetime',
      lateDate: 'datetime',
      terminationDate: 'datetime',
      exemptUntil: 'datetime',
    }
  }
}