import BaseView from "../BaseView"
import { observer } from "mobx-react"
import * as React from "react"
import TableView from "../../components/table-view/TableView"
import { ApiTableViewAdapter } from "../../components/table-view/ApiTableViewAdapter"
import MembershipRenewal from "../../models/MembershipRenewal"
import { Moment } from "moment-timezone/moment-timezone"
import { formatCurrency, safeNull } from "../../common/Util"
import Member from "../../models/Member"
import { Link } from "react-router-dom"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import Chapter from "../../models/Chapter"

@observer
export default class RenewalsView extends BaseView {
  renderContentHeader () {
    return <>
      <h1>Pending Renewals</h1>
    </>
  }

  private terminationRenewalsAdapter = new ApiTableViewAdapter<MembershipRenewal>(() => {
      return {
        query: `
    membershipRenewals {
      *      
      exemptUntil
      
      invoice {
        *
      }
      
      membership {
        *
        member {
          *
          chapter {
            *
            
            president {
              *
              member {
                *
              }
            }
          }
        }
      },
    }`,
        where: [
          { _scope: 'pastTerminationDate' }
        ],
      }
    },
    'membershipRenewals',
    MembershipRenewal
  )

  private lateRenewalsAdapter = new ApiTableViewAdapter<MembershipRenewal>(() => {
      return {
        query: `
    membershipRenewals {
      *
      exemptUntil

      invoice {
        *
      }

      membership {
        *
        member {
          *
          chapter {
            *
          }
        }
      },
    }`,
        where: [
          { _scope: 'late' }
        ],
      }
    },
    'membershipRenewals',
    MembershipRenewal
  )

  private dueRenewalsAdapter = new ApiTableViewAdapter<MembershipRenewal>(() => {
      return {
        query: `
    membershipRenewals {
      *
      exemptUntil

      invoice {
        *
      }

      membership {
        *
        member {
          *
          chapter {
            *
          }
        }
      },
    }`,
        where: [
          { _scope: 'due' }
        ],
      }
    },
    'membershipRenewals',
    MembershipRenewal
  )

  renderContentBody () {
    return <>
      <h5 style={{ textAlign: 'center' }}>Members Past Termination Date</h5>
      <TableView
        adapter={this.terminationRenewalsAdapter}
        defaultSort={{ sortKey: 'renewalDate' }}
        columns={
          [
            {
              title: 'Member Name',
              accessor: 'membership.member',
              renderItem: (member: Member) => <Link to={route(Routes.system.members.show, { id: member.id })}>{member.fullName}</Link>
            },
            {
              title: 'Join Date',
              accessor: 'membership.member.joinDate',
              renderItem: (joinDate: Moment | null) => safeNull(() => joinDate!.format('MM/DD/YYYY')) || '---',
            },
            {
              title: 'Renewal Date',
              accessor: 'renewalDate',
              renderItem: (renewalDate: Moment) => renewalDate.format('MM/DD/YYYY'),
            },
            {
              accessor: 'exemptUntil',
              title: 'Exemption Till',
              renderItem: (exemptUntil: Moment | null) => exemptUntil ? exemptUntil.format('MM/DD/YYYY') : '---',
            },
            {
              title: 'Renewal Amount',
              accessor: 'renewalFee',
              renderItem: (renewalFee: number) => formatCurrency(renewalFee),
            },
            {
              title: 'Late Fee Applied',
              accessor: 'invoice.lateFee',
              renderItem: (lateFee: number, item: MembershipRenewal) => item.invoice.lateFeeAppliedAt ? formatCurrency(item.invoice.lateFee!) : '---',
            },
            {
              title: 'Total Renewal Amount',
              accessor: 'renewalFee',
              renderItem: (renewalFee: number, item: MembershipRenewal) => formatCurrency(item.invoice.total),
            },
            {
              title: 'Chapter',
              accessor: 'membership.member.chapter',
              renderItem: (chapter: Chapter) => <Link to={route(Routes.system.chapters.show, { id: chapter.id })}>{chapter.name}</Link>,
            },
            {
              title: 'Chapter President',
              accessor: 'membership.member.chapter.president.member.fullName',
            },
            {
              title: 'Chapter President Email',
              accessor: 'membership.member.chapter.president.member.emailAddress',
            },
            {
              title: 'Chapter President Phone',
              accessor: 'membership.member.chapter.president.member.phoneNumber',
            },
          ]
        }/>

      <hr/>

      <h5 style={{ textAlign: 'center' }}>Members Approaching Termination Date</h5>
      <TableView
        adapter={this.lateRenewalsAdapter}
        defaultSort={{ sortKey: 'renewalDate' }}
        columns={
          [
            {
              title: 'Member Name',
              accessor: 'membership.member',
              renderItem: (member: Member) => <Link to={route(Routes.system.members.show, { id: member.id })}>{member.fullName}</Link>
            },
            {
              title: 'Join Date',
              accessor: 'membership.member.joinDate',
              renderItem: (joinDate: Moment | null) => safeNull(() => joinDate!.format('MM/DD/YYYY')) || '---',
            },
            {
              title: 'Renewal Date',
              accessor: 'renewalDate',
              renderItem: (renewalDate: Moment) => renewalDate.format('MM/DD/YYYY'),
            },
            {
              title: 'Renewal Amount',
              accessor: 'renewalFee',
              renderItem: (renewalFee: number) => formatCurrency(renewalFee),
            },
            {
              title: 'Late Fee Applied',
              accessor: 'invoice.lateFee',
              renderItem: (lateFee: number, item: MembershipRenewal) => item.invoice.lateFeeAppliedAt ? formatCurrency(item.invoice.lateFee!) : '---',
            },
            {
              title: 'Total Renewal Amount',
              accessor: 'renewalFee',
              renderItem: (renewalFee: number, item: MembershipRenewal) => formatCurrency(item.invoice.total),
            },
            {
              title: 'Chapter',
              accessor: 'membership.member.chapter',
              renderItem: (chapter: Chapter) => chapter ? <Link to={route(Routes.system.chapters.show, { id: chapter.id })}>{chapter.name}</Link> : '---',
            },
            {
              accessor: 'exemptUntil',
              title: 'Exemption Till',
              renderItem: (exemptUntil: Moment | null) => exemptUntil ? exemptUntil.format('MM/DD/YYYY') : '---',
            },
            {
              title: 'Member Email',
              accessor: 'membership.member.emailAddress',
            },
            {
              title: 'Member Phone Number',
              accessor: 'membership.member.phoneNumber',
            },
          ]
        }/>

      <hr/>

      <h5 style={{ textAlign: 'center' }}>Members In Current Renewal Month</h5>
      <TableView
        adapter={this.dueRenewalsAdapter}
        defaultSort={{ sortKey: 'renewalDate' }}
        columns={
          [
            {
              title: 'Member Name',
              accessor: 'membership.member',
              renderItem: (member: Member) => <Link to={route(Routes.system.members.show, { id: member.id })}>{member.fullName}</Link>
            },
            {
              title: 'Join Date',
              accessor: 'membership.member.joinDate',
              renderItem: (joinDate: Moment | null) => safeNull(() => joinDate!.format('MM/DD/YYYY')) || '---',
            },
            {
              title: 'Renewal Date',
              accessor: 'renewalDate',
              renderItem: (renewalDate: Moment) => renewalDate.format('MM/DD/YYYY'),
            },
            {
              title: 'Renewal Amount',
              accessor: 'renewalFee',
              renderItem: (renewalFee: number) => formatCurrency(renewalFee),
            },
            {
              title: 'Late Fee Applied',
              accessor: 'invoice.lateFee',
              renderItem: (lateFee: number, item: MembershipRenewal) => item.invoice.lateFeeAppliedAt ? formatCurrency(item.invoice.lateFee!) : '---',
            },
            {
              title: 'Total Renewal Amount',
              accessor: 'renewalFee',
              renderItem: (renewalFee: number, item: MembershipRenewal) => formatCurrency(item.invoice.total),
            },
            {
              title: 'Chapter',
              accessor: 'membership.member.chapter',
              renderItem: (chapter: Chapter) => <Link to={route(Routes.system.chapters.show, { id: chapter.id })}>{chapter.name}</Link>,
            },
            {
              accessor: 'exemptUntil',
              title: 'Exemption Till',
              renderItem: (exemptUntil: Moment | null) => exemptUntil ? exemptUntil.format('MM/DD/YYYY') : '---',
            },
            {
              title: 'Member Email',
              accessor: 'membership.member.emailAddress',
            },
            {
              title: 'Member Phone Number',
              accessor: 'membership.member.phoneNumber',
            },
          ]
        }/>
    </>
  }
}
