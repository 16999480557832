import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone/moment-timezone"
import Message from "./Message"
import ConversationParticipant from "./ConversationParticipant"

export default class ReceivedMessage extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  readAt: Moment | null

  recipientParticipantId: number
  messageId: number

  recipient: ConversationParticipant
  message: Message

  getCasts (): {} {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      readAt: 'datetime',

      recipientParticipantId: 'number',
      messageId: 'number',
    }
  }

  setRecipient (r?: {}) {
    return r ? new ConversationParticipant().init(r) : null
  }

  setMessage (m?: {}) {
    return m ? new Message().init(m) : null
  }
}