import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone/moment-timezone"

export default class Notice extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  status: string | 'Active' | 'Inactive'
  title: string
  message: string
  type: string | 'Normal' | 'Sticky'

  constructor (data = {}) {
    super();
    this.init(data);
  }

  getCasts (): {} {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      archivedAt: 'datetime',
    }
  }
}