import * as React from "react"
import ApiClient, { QueryWhereClause } from "../api/ApiClient"
import { createLazyResource, formatNumber, safeNull } from "../common/Util"
import { observer } from "mobx-react"
import { observable } from "mobx"
import BaseView from "./BaseView"
import { LinkContainer } from "react-router-bootstrap"
import { Routes } from "../routes/AppRoutes"
import { route } from "../routes/routes"
import { Link } from "react-router-dom"
import classNames from 'classnames'
import * as H from "history"
import User from "../models/User"
import { Button } from "reactstrap"
import { ApiTableViewAdapter } from "../components/table-view/ApiTableViewAdapter"
import RequirePermission, { Permission } from "../components/RequirePermission"
import { UserRole } from "../models/AuthUser"
import RequireRole from "../components/RequireRole"
import { ManagedChooseableColumn } from "../components/table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "../components/table-view/ManagedTableView";
import UsersCsvTransformer from "../models/renderers/UsersCsvTransformer";
import SelectFilter, { SelectFilterTargetType } from '../components/table-view/filters/SelectFilter'
import AsyncSelectFilter, { AsyncSelectFilterTargetType } from '../components/table-view/filters/AsyncSelectFilter'
import { loadAreaOptionsForAdmin, loadChapterStateOptions } from '../api/AsyncHelpers'

type Props = {
  location: H.Location<H.LocationState>,
}

@observer
export default class UsersView extends BaseView<Props> {
  @observable private numberOfActiveUsers = createLazyResource<number>(() => {
    return ApiClient.query(`users { * }`, { returnTotal: true, limit: 0, where: [{ _scope: 'active' }] })
  }, response => response.data._meta.total);

  renderContentHeader (): React.ReactNode | null {

    return (
      <>
        <h1>Users</h1>
        <ul className="content-header-actions">
          <RequirePermission permission={Permission.CreateUser}>{() =>
            <li>
              <LinkContainer to={route(Routes.system.users.create)}>
                <Button color="primary" onClick={() => {
                }}><i className="fa fa-plus-circle"/> Add New User</Button>
              </LinkContainer>
            </li>
          }</RequirePermission>
        </ul>
      </>
    )
  }

  @observable private availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'name',
      column: {
        title: 'Name',
        accessor: 'user',
        sortKey: 'name',
        sortable: true,
        renderItem: (value, item) => (
          <Link type="link" to={{ pathname: route(Routes.system.users.edit, { id: item.id }), state: { id: item.id } }}>
            <div className={classNames({ 'text-muted': item.status === 'Inactive' })}>{item.name}{item.status === 'Inactive' && ' (Inactive)'}</div>
          </Link>
        )
      },
      default: true
    },
    {
      id: 'username',
      column: {
        title: 'Username',
        accessor: 'username',
        sortable: true,
      },
      default: true
    },
    {
      id: 'email',
      column: {
        title: 'Email',
        accessor: 'email',
        sortable: true,
      },
      default: true
    },
    {
      id: 'roles',
      column: {
        title: 'Roles',
        accessor: 'roles',
        renderItem: (value, item) => (
          <div>{item.roles.map((r: any) => r.role).join(', ')}</div>
        )
      },
      default: true
    }
  ]

  private tableViewRef = React.createRef<ManagedTableView>()

  private usersAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      if (!safeNull(() => this.tableViewRef.current!.tableViewFilters!.showInactive)) {
        where.push({ _scope: 'active' })
      }

      return {
        query: `
      users {
        *
        roles {
          *
        }
      }
    `,
        where: where,
      }
    },
    'users',
    User
  )

  renderContentBody (): React.ReactNode {
    return this.renderTable()
  }

  private renderTableViewHeader = () => {
    return <RequireRole role={UserRole.Admin}>
      {() => <>
        <div className="list-view-info-icon">
          <i className="flaticon-lock"/>
        </div>
        <div className="list-view-info-text">
          <span>{this.numberOfActiveUsers.loading ? <i className="fa fa-spinner fa-spin"/> : formatNumber(this.numberOfActiveUsers.current!)}</span> Active Users
        </div>
      </>}
    </RequireRole>
  }

  private filters = [
    new SelectFilter('status', SelectFilterTargetType.Field, 'status', 'Status', [
      'Active',
      'Inactive',
    ].map(s => ({ value: s, label: s }))),
    new AsyncSelectFilter('state', AsyncSelectFilterTargetType.Field, 'member.chapter.meetingLocationAddress.state', 'State', loadChapterStateOptions),
    new AsyncSelectFilter('area', AsyncSelectFilterTargetType.Field, 'member.chapter.area.id', 'Area', loadAreaOptionsForAdmin),
  ]

  private renderTable = () => {
    return <ManagedTableView
      stateKey="UsersView"
      ref={this.tableViewRef}
      hideItemCount={true}
      adapter={this.usersAdapter}
      availableColumns={this.availableColumns}
      searchLabel="Find A User"
      allowShowInactive={true}
      header={this.renderTableViewHeader()}
      exportTransformer={UsersCsvTransformer}
      filters={this.filters}
    />
  }
}
