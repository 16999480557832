import * as React from "react"
import { observer } from "mobx-react"
import TipBatch from "../../models/TipBatch"
import TableView, { TableViewColumn } from "../table-view/TableView"
import { ApiTableViewAdapter } from "../table-view/ApiTableViewAdapter"
import { observable } from "mobx"
import { Moment } from "moment-timezone"
import Util, { formatCurrency } from "../../common/Util"
import { CreateTipBatchModal } from "./CreateTipBatchModal"
import { Button } from "reactstrap"
import HelpTooltip from '../HelpTooltip'
import AppStateStore from '../../stores/AppStateStore'
import ApiClient, { ApiRoutes } from '../../api/ApiClient'
import { route } from '../../routes/routes'
import { EditTipBatchModal } from './EditTipBatchModal'

type TipBatchesProps = {
  memberId: number
}

@observer
export default class TipBatches extends React.Component<TipBatchesProps> {
  private tipBatchesAdapter = new ApiTableViewAdapter<TipBatch>(() => ({
    query: `
tipBatches {
  *
}
      `,
    where: [
      { memberId: this.props.memberId },
    ],
  }), 'tipBatches', TipBatch, {})

  @observable private visibleColumns: TableViewColumn[] = [
    {
      title: 'Batch Date',
      accessor: 'batchDate',
      renderItem: (value: Moment) => value.format('MM/DD/YYYY'),
      sortable: true,
    },
    {
      title: 'Value',
      accessor: 'value',
      renderItem: (value: number) => formatCurrency(value),
      sortable: true,
    },
    {
      title: 'Notes',
      accessor: 'note',
    },
    {
      title: 'Actions',
      renderItem: (_, tipBatch: TipBatch) => <>
        <Button type="button" color="primary" title="Edit" onClick={() => this.editTipBatch(tipBatch)}><i className="fa fa-pencil"/></Button>
        <Button className="ml-2" type="button" color="danger" title="Delete" onClick={() => this.deleteTipBatch(tipBatch)}><i className="fa fa-trash"/></Button>
      </>
    },
  ]

  @observable private renderCreateTipBatchModal = false
  @observable private showCreateBatchModal = false
  @observable private editBatchId: number | undefined = undefined
  @observable private showEditBatchModal = false

  private tableViewRef = React.createRef<TableView>()

  private editTipBatch = (tipBatch: TipBatch) => {
    this.editBatchId = tipBatch.id
    this.showEditBatchModal = true
  }

  private deleteTipBatch = (tipBatch: TipBatch) => {
    AppStateStore.showConfirmationModal('Confirm', 'Are you sure you want to delete this tip batch?', (result, modal) => {
      if (result) {
        AppStateStore.showModalSpinner()

        ApiClient.getInstance().delete(route(ApiRoutes.tips.batches.destroy, { id: tipBatch.id }))
          .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
          .then(() => {
            AppStateStore.dismissModalSpinner()
            this.tableViewRef.current && this.tableViewRef.current.fetchData()
          })
      }

      modal.hide()
    })
  }

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <>
      <div className={'form-buttons'}>
        <div style={{ marginRight: 20, alignSelf: 'center' }}>
          <HelpTooltip
            content="Monthly batches are made available so that members can add their Tip Values in summary versus on each specific Tip. This enables members who are not allowed to enter customer specific information to still track their Tip Values."
          />
        </div>
        <Button
          type={'button'}
          color={'primary'}
          onClick={() => {
            this.showCreateBatchModal = true
            this.renderCreateTipBatchModal = true
          }}
        ><i className={'fa fa-plus-circle'}/> Create New Batch</Button>
      </div>
      <TableView
        ref={this.tableViewRef}
        adapter={this.tipBatchesAdapter}
        columns={this.visibleColumns}
        defaultSort={{
          sortKey: 'batchDate',
          descending: true,
        }}
      />
      {
        this.renderCreateTipBatchModal
          ? <CreateTipBatchModal
            isOpen={this.showCreateBatchModal}
            toggle={() => this.showCreateBatchModal = false}
            onClosed={() => this.renderCreateTipBatchModal = false}
            onSaved={() => {
              this.showCreateBatchModal = false
              this.tableViewRef.current && this.tableViewRef.current.fetchData()
            }}
          />
          : null
      }
      {
        this.editBatchId
          ? <EditTipBatchModal
            isOpen={this.showEditBatchModal}
            toggle={() => this.showEditBatchModal = false}
            onClosed={() => this.editBatchId = undefined}
            onSaved={() => {
              this.showEditBatchModal = false
              this.tableViewRef.current && this.tableViewRef.current.fetchData()
            }}
            tipBatchId={this.editBatchId}
          />
          : null
      }
    </>
  }
}
