import * as React from "react"
import { SyntheticEvent } from "react"
import { Prompt, Redirect } from "react-router-dom"
import { observable } from "mobx"
import { observer } from "mobx-react"
import { BarLoader } from "react-spinners"
import { Alert, Button } from "reactstrap"
import { LinkContainer } from "react-router-bootstrap"
import { toast } from "react-toastify"
import BaseView from "../BaseView"
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import FormHelper from "../../forms/FormHelper"
import BackLink from "../../components/BackLink"
import AppStateStore from "../../stores/AppStateStore"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import Util from "../../common/Util"
import ButtonLoader from "../../components/ButtonLoader"
import FormError from '../../components/FormError'
import Position from '../../models/Position'
import * as _ from 'lodash'
import SelectInput from '../../components/inputs/SelectInput'

@observer
export default class CreateBoardDocumentView extends BaseView {
  @observable private formState = new FormState({
    title: '',
  })
  @observable private formErrors = new ErrorBag()
  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable private loading = true
  @observable private submitting = false
  @observable private redirect = false
  @observable private error?: string
  @observable private boardPositions: Position[] = []
  @observable private selectedBoardPositions: string[] = []

  private fileInputRef = React.createRef<HTMLInputElement>()

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>Add New Board Document</h1>
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    this.loadData().then()
  }

  private loadData = async () => {
    this.loading = true

    try {
      const response = await ApiClient.query(`
positions {
  *
}
`, {}
      )

      this.boardPositions = _.orderBy<Position>(response.data.positions.filter((p: Position) => p.type === 'board'), p => p.displayOrder)
    } catch (err) {
      this.error = Util.extractErrorMessage(err.response)
    }

    this.loading = false
  }

  private submit = async (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()

    this.submitting = true
    AppStateStore.showModalSpinner()

    try {
      const formData = new FormData()
      if (this.fileInputRef.current && this.fileInputRef.current.files && this.fileInputRef.current.files.length) {
        formData.append('file', this.fileInputRef.current.files[0])
      }
      formData.append('title', this.formState.get('title'))
      formData.append('allowed_board_positions', this.selectedBoardPositions.join(','))

      await ApiClient.getInstance().post(route(ApiRoutes.boardDocuments.create), formData)
      toast.success('Board document created')
      this.redirect = true
    } catch (err) {
      const errors = new ErrorBag()
      Util.handleErrorResponse(err.response, errors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      })

      this.formErrors.addErrors(errors.getErrorList())
    }

    AppStateStore.dismissModalSpinner()
    this.submitting = false
  }

  renderContentBody (): React.ReactNode {
    return this.redirect
      ? this.renderRedirect()
      : this.error
        ? <Alert color="danger">{this.error}</Alert>
        : this.loading ? this.renderLoading() : this.renderForm()
  }

  private renderLoading = () => <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>

  private renderForm = () => (
    <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      {this.formState.isDirty && <Prompt message="Are you sure you want to leave this page? You will lose any unsaved changes!"/>}
      <div className="form-row">
        <div className="col-12">
          {this.formHelper.renderTextInput({
            name: 'title',
            type: 'text',
            label: 'Document Title',
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-12">
          <div className="form-group">
            <label>Document PDF</label>
            <div>
              <input type="file" ref={this.fileInputRef}/>
            </div>
            <FormError errors={this.formErrors} fieldName="file"/>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-12">
          <div className="form-group">
            <label>Who can sign this document?</label>
            <div>
              <SelectInput
                isMulti={true}
                loadOptions={(inputValue: any, callback: any) => {
                  callback(
                    this.boardPositions.filter(v => !inputValue || inputValue.length == 0 || v.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
                      .map(v => ({
                        label: v.name,
                        value: v.id,
                      }))
                  )
                }}
                onChange={option => this.selectedBoardPositions = _.isArray(option) ? option.map(o => o.value) : [option.value]}
              />
            </div>
            <FormError errors={this.formErrors} fieldName="allowedBoardPositions"/>
          </div>
        </div>
      </div>

      <div className="form-buttons">
        <LinkContainer to={route(Routes.system.notices.index)}><Button type="button" color=""><i className="fa fa-ban"/> Cancel</Button></LinkContainer>
        <ButtonLoader type="submit" color="success" loading={this.submitting}><i className="fa fa-save"/> Save</ButtonLoader>
      </div>
    </form>
  )

  private renderRedirect = () => <Redirect to={route(Routes.system.boardDocuments.index)}/>
}
