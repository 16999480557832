import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Guest from './Guest'
import Category from './Category'
import PaymentMethod from './PaymentMethod'
import Member from './Member'
import Chapter from './Chapter'
import ChapterTransferRequest from './ChapterTransferRequest'
import CompanyReplacementRequest from './CompanyReplacementRequest'

export enum ApplicationType {
  NewMember = 'New Member',
  ChapterTransfer = 'Chapter Transfer',
  CompanyReplacement = 'Company Replacement',
}

export default class Application extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  viewedAt: Moment
  submittedAt: Moment
  processedAt: Moment | null
  archivedAt: Moment | null
  applicationUrl: string
  status: string
  applicationType: ApplicationType
  isCompleted: boolean
  isProcessed: boolean
  applicationPdfUrl: string | null

  membershipFee: number
  renewalFee: number
  taxRate: number
  membershipType: string
  usePaymentPlan: boolean
  isVeteran: boolean | null

  prefix: string | null
  firstName: string
  lastName: string
  suffix: string | null
  gender: string | null
  email: string
  phone: string
  fax: string | null
  address: string | null
  address2: string | null
  city: string | null
  state: string | null
  zipCode: string | null
  country: string | null
  businessName: string | null
  businessPhone: string | null
  businessFax: string | null
  businessEmail: string | null
  businessWebsite: string | null
  businessAddress: string | null
  businessAddress2: string | null
  businessCity: string | null
  businessState: string | null
  businessZipCode: string | null
  businessCountry: string | null

  renewalMethod: string
  applicationSignatureImageUrl: string
  renewalSignatureImageUrl: string

  boardInterestOption: string
  boardInterestText: string

  categoryid: number
  guestId: number
  memberId: number | null
  membershipPaymentMethodId: number
  renewalPaymentMethodId: number
  sponsorId: number
  targetChapterId: number

  member: Member
  guest: Guest
  category: Category
  membershipPaymentMethod: PaymentMethod
  renewalPaymentMethod: PaymentMethod
  sponsor: Member
  targetChapter: Chapter
  chapterTransferRequest: ChapterTransferRequest
  companyReplacementRequest: CompanyReplacementRequest

  getStatusString () {
    const parts: string[] = [this.status]

    if (this.applicationType === ApplicationType.ChapterTransfer) {
      parts.push('Chapter Transfer')
    } else if (this.applicationType === ApplicationType.CompanyReplacement) {
      parts.push('Company Replacement')
    }

    if (this.archivedAt) {
      parts.push('(Archived');
    }

    return parts.join(' ')
  }

  getMembershipFeeTotal () {
    return Math.round((Number(this.membershipFee) + Math.round(this.membershipFee * this.taxRate * 100) / 100) * 100) / 100
  }

  setMember (m?: {}) {
    return m ? new Member().init(m) : null
  }

  setChapterTransferRequest (m?: {}) {
    return m ? new ChapterTransferRequest().init(m) : null
  }

  setCompanyReplacementRequest (m?: {}) {
    return m ? new CompanyReplacementRequest().init(m) : null
  }

  setGuest (g?: {}) {
    return g ? new Guest().init(g) : null
  }

  setTargetChapter (c?: {}) {
    return c ? new Chapter().init(c) : null
  }

  setCategory (c?: {}) {
    return c ? new Category().init(c) : null
  }

  setMembershipPaymentMethod (p?: {}) {
    return p ? new PaymentMethod(p) : null
  }

  setRenewalPaymentMethod (p?: {}) {
    return p ? new PaymentMethod(p) : null
  }

  setSponsor (s?: {}) {
    return s ? new Member().init(s) : null
  }

  getCasts () {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      processedAt: 'datetime',
      archivedAt: 'datetime',
      viewedAt: 'datetime',
      submittedAt: 'datetime',
      guestId: 'number',
      paymentMethodId: 'number',
      sponsorId: 'number',
      membershipFee: 'number',
      taxRate: 'number',
      usePaymentPlan: 'boolean',
      isVeteran: 'boolean',
    }
  }
}
