import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Member from "./Member"
import BadgeShippingAddress from './BadgeShippingAddress'

export enum BadgeShippingRequestStatus {
  Pending = 'Pending',
  Shipped = 'Shipped',
  Cancelled = 'Cancelled',
}

export default class BadgeShippingRequest extends BaseModel {
  id: number
  createdAt: Moment
  shippingNotes: string
  completedAt: Moment | null
  status: BadgeShippingRequestStatus | string
  badgeColor: string
  previousBadgeColor: string | null

  memberId: number

  member: Member
  badgeShippingAddress: BadgeShippingAddress

  setMember = (m?: {}) => {
    return m ? new Member(m) : null
  }

  setBadgeShippingAddress = (a?: {}) => {
    return a ? new BadgeShippingAddress().init(a) : null
  }

  getCasts () {
    return {
      memberId: 'number',
      createdAt: 'datetime',
      completedAt: 'datetime',
    }
  }
}