import { observer } from 'mobx-react'
import React from 'react'
import { observable } from 'mobx'
import { Tooltip } from 'reactstrap'
import Popper from 'popper.js'

type Props = {
  content: React.ReactNode
  placement: Popper.Placement
  icon?: (ref: React.RefObject<any>) => React.ReactNode
}

@observer
export default class HelpTooltip extends React.Component<Props> {
  static defaultProps = {
    placement: 'left'
  }
  @observable private isOpen = false
  @observable private iconRef = React.createRef<any>()

  private toggle = () => {
    this.isOpen = !this.isOpen
  }

  render () {
    return <div>
      {
        this.props.icon
          ? this.props.icon(this.iconRef)
          : <i
            style={{ fontSize: 22 }}
            ref={this.iconRef}
            className="fa fa-question-circle"
          />
      }
      {
        this.iconRef.current
          ? <Tooltip
            target={this.iconRef.current}
            isOpen={this.isOpen}
            toggle={this.toggle}
            placement={this.props.placement}
          ><p style={{ fontSize: 14 }}>{this.props.content}</p></Tooltip>
          : null
      }
    </div>
  }
}
