import CsvTransformer from "./CsvTransformer"
import { safeNull } from "../../common/Util"
import moment from "moment-timezone"
import Category from "../Category"

export default class CategoriesCsvTransformer extends CsvTransformer<Category> {
  get filename () {
    return `categories-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'id',
      valueTransformer: row => String(row.id),
    })

    this.addColumn({
      header: 'Category',
      valueTransformer: row => safeNull(() => row.name) || '',
    })

    this.addColumn({
      header: 'Members',
      valueTransformer: row => safeNull(() => row.numberOfActiveMembers) || '',
    })
  }
}