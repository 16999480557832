import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Member from "./Member"
import FileUpload from "./FileUpload"
import Chapter from "./Chapter"

export enum ItemType {
  File = 'File',
  Folder = 'Folder',
  Video = 'Video',
}

export type VideoData = {
  id: string
  type: 'youtube' | 'vimeo'
  thumbnailUrl: string
  videoUrl: string
}

export enum LibraryType {
  ChapterDocumentLibrary = 'Chapter Document Library',
  MemberDocumentLibrary = 'Member Document Library',
  CorporateDocumentLibrary = 'Corporate Document Library',
  NetworkDocumentLibrary = 'Network Document Library',
  NetworkVideoLibrary = 'Network Video Library',
}

export enum LibraryOwnerType {
  Chapter = 'chapter',
  Member = 'member',
}

export type DocumentLibraryDescriptor = {
  libraryOwnerType?: string
  libraryOwnerId?: number
  libraryType: string
}

export default class DocumentLibraryItem extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  deletedAt: Moment | null
  createdById: number | null
  updatedById: number | null
  deletedById: number | null
  parentId: number | null

  libraryType: LibraryType | string
  libraryOwnerType: LibraryOwnerType | string
  libraryOwnerId: number
  libraryOwner: any

  itemType: ItemType | string
  name: string
  tags: string[]

  parent: DocumentLibraryItem | null
  createdBy: Member | null
  updatedBy: Member | null
  deletedBy: Member | null
  fileUpload: FileUpload | null
  videoData: VideoData | null

  setCreatedBy (a?: {}) {
    return a ? new Member().init(a) : null
  }

  setUpdatedBy (a?: {}) {
    return a ? new Member().init(a) : null
  }

  setDeletedBy (a?: {}) {
    return a ? new Member().init(a) : null
  }

  setFileUpload (a?: {}) {
    return a ? new FileUpload().init(a) : null
  }

  setParent (a?: {}) {
    return a ? new DocumentLibraryItem().init(a) : null
  }

  get chapterLibraryOwner () {
    return this.libraryOwnerType === 'chapter'
      ? new Chapter().init(this.libraryOwner)
      : null
  }

  getCasts (): {} {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      deletedAt: 'datetime',
      createdById: 'number',
      updatedById: 'number',
      deletedById: 'number',
      parentId: 'number',
      libraryOwnerId: 'number',
    }
  }
}
