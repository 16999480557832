import * as React from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import Util, { modelToCamelCase, publicPath } from "../../common/Util"
import Config from "../../common/Config"
import { BarLoader } from "react-spinners"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import { route } from "../../routes/routes"
import Event from '../../models/Event'
import Address from '../../models/Address'
import AddressView from '../../components/AddressView'
import VirtualMeet from "../../models/VirtualMeet"
import VirtualMeetView from "../../components/VirtualMeetView"

type Props = {
  match: {
    params: {
      slug: number
    }
  },
  accept: boolean,
}

@observer
export default class InvitationResponseView extends React.Component<Props> {
  @observable private loading = false
  @observable private error?: string
  @observable private event?: Event
  @observable private staticMapUrl?: string
  @observable private googleMapsUrl?: string
  @observable private locationName?: string
  @observable private address?: Address
  @observable private virtualMeet?: VirtualMeet

  componentDidMount (): void {
    this.loading = true

    ApiClient.getInstance()
      .post(route(
        this.props.accept ? ApiRoutes.public.acceptEventInvitation : ApiRoutes.public.declineEventInvitation,
        { slug: this.props.match.params.slug }
      ))
      .then(response => {
        this.loading = false
        console.log("response", JSON.stringify(response.data))
        this.event = new Event().init(response.data.event)
        console.log("Event", JSON.stringify(response.data.event))
        if (this.props.accept){
          if(response.data.address) {
            this.locationName = response.data.location_name
            this.address = new Address().init(modelToCamelCase(response.data.address))
            this.staticMapUrl = response.data.static_map_url
            this.googleMapsUrl = response.data.google_maps_url
          }

          if(response.data.virtual_meeting_desc && response.data.virtual_meeting_desc.web_conference_details ){
              this.virtualMeet = new VirtualMeet().init(modelToCamelCase(response.data.virtual_meeting_desc))
          }
        }

      })
      .catch(err => this.error = Util.extractErrorMessage(err.response))
  }

  private renderAccept = () => {
    return <div>
      We are excited to meet you. If you are interested in learning more about LeTip, please visit <a target="_blank" href="https://www.letip.com/">www.letip.com</a>.
      <div>
        <label>The meeting you are attending will be a
          {
            this.address && this.virtualMeet!=null? <><b> Hybrid Meeting </b> so please add meeting information to your calendar so its easy to find:</>
            : this.address!=null? <><b> In-Person Meeting </b></>
            : this.virtualMeet!=null? <><b> Virtual Meeting </b> so please add this information to your calendar so its easy to find:</>
            : null
          }
         </label>
      </div>
      <br />

      {
        this.staticMapUrl
          ? <div style={{ marginTop: 20 }}>
            <b>{this.locationName}</b><br/>
            {this.address ? <AddressView address={this.address}/> : null}
            <br/><br/>
            <a href={this.googleMapsUrl}><img style={{ maxWidth: 800 }} src={this.staticMapUrl}/></a>
          </div>
          : null
      }
      {
        this.virtualMeet?
        <div style={{ marginTop: 20 }}>
            <VirtualMeetView virtualMeet={this.virtualMeet}/>
          </div>
        :null
      }
    </div>
  }

  private renderDecline = () => {
    return <div>
      We hope that you will join us at a future event. If you are interested in learning more about LeTip, please visit <a target="_blank" href="https://www.letip.com/">www.letip.com</a>.
    </div>
  }

  private renderError = () => {
    return <div className="alert alert-danger">{this.error}</div>
  }

  render (): React.ReactNode {
    return <>
      <div className="header-container">
        <div className="brand">
          <img className="brand-image" alt="LeTip Wired" src={publicPath('img/wired-logo.png')}/>
        </div>
        <div className="header-nav"/>
      </div>
      <div className="body-container invitation-response">
        <div className="left-nav"/>
        <div className="content-container">
          <div className="content">
            {this.error
              ? this.renderError()
              : this.loading
                ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
                : this.props.accept
                  ? this.renderAccept()
                  : this.renderDecline()
            }
          </div>
          <div className="content-footer">
            Version {Config.VERSION || '???'}{Config.BUILD_NUMBER ? `-${Config.BUILD_NUMBER}` : null} {Config.ENV !== 'production' ? `(${Config.ENV})` : null} {Config.NODE_ENV !== 'production' ? `[${Config.NODE_ENV}]` : null}
          </div>
        </div>
      </div>
    </>
  }
}
