import React from 'react'
import { observer } from 'mobx-react'
import { autorun, computed, observable } from 'mobx'
import Util, { createLazyResource, formatNumber, safeNull } from '../common/Util'
import ApiClient, { ApiRoutes } from '../api/ApiClient'
import { ManagedChooseableColumn } from './table-view/ManagedTableViewColumnChooser'
import { Moment } from 'moment-timezone/moment-timezone'
import { route } from '../routes/routes'
import { Routes } from '../routes/AppRoutes'
import { can, Permission } from './RequirePermission'
import AuthStore from '../stores/AuthStore'
import { Button } from 'reactstrap'
import ManagedTableView from './table-view/ManagedTableView'
import AppStateStore from '../stores/AppStateStore'
import { ApiTableViewAdapter } from './table-view/ApiTableViewAdapter'
import RequireRole from './RequireRole'
import { UserRole } from '../models/AuthUser'
import SelectFilter, { SelectFilterTargetType } from './table-view/filters/SelectFilter'
import DateFilter from './table-view/filters/DateFilter'
import AsyncSelectFilter, { AsyncSelectFilterTargetType } from './table-view/filters/AsyncSelectFilter'
import { loadCategoryOptions } from '../api/AsyncHelpers'
import CategoryChangeRequest from '../models/CategoryChangeRequest'
import ChapterTransferRequest from '../models/ChapterTransferRequest'
import MemberCell from './cells/MemberCell'

type Props = {
  onBadgeCountChanged?: (count: number) => void
}

@observer
export default class ChapterTransferRequestsList extends React.Component<Props> {
  @observable private numberOfRequests = createLazyResource<number>(() => {
    return ApiClient.query(`chapterTransferRequests { * }`, { returnTotal: true, limit: 0, where: [{ _scope: 'inQueue' }, { _scope: 'notArchived' }] })
  }, response => response.data._meta.total);

  componentDidMount (): void {
    autorun(() => {
      this.props.onBadgeCountChanged && this.props.onBadgeCountChanged(this.numberOfRequests.current || 0)
    })
  }

  @computed
  private get availableColumns (): ManagedChooseableColumn[] {
    const columns: ManagedChooseableColumn[] = [
      {
        id: 'receivedDate',
        column: {
          title: 'Received Date',
          accessor: 'submittedAt',
          renderItem: (value: Moment) => value ? value.format('MM/DD/YYYY') : '---',
          sortable: true,
        },
        fixed: true,
      },
      {
        id: 'sentDate',
        column: {
          title: 'Sent Date',
          accessor: 'createdAt',
          renderItem: (value: Moment) => value.format('MM/DD/YYYY'),
          sortable: true,
        },
        default: true,
      },
      {
        id: 'status',
        column: {
          title: 'Status',
          accessor: 'status',
          sortable: true,
          renderItem: (status: string, categoryChangeRequest: CategoryChangeRequest) => `${status}${categoryChangeRequest.archivedAt ? ' (Archived)' : ''}`,
        },
        default: true,
      },
      {
        id: 'member',
        column: {
          title: 'Member',
          sortable: true,
          accessor: 'member.fullName',
          renderItem: (value, item: CategoryChangeRequest) => <MemberCell
            member={item.member}
            linkTo={{ pathname: route(Routes.system.applications.showChapterTransferRequest, { id: item.id }), state: { id: item.id } }}
            showBusiness={true}
            showCategory={false}
          />,
        },
        fixed: true,
      },
      {
        id: 'oldCategory',
        column: {
          title: 'Category',
          accessor: 'oldCategory.name',
          sortable: true,
        },
        default: true,
      },
      {
        id: 'oldChapter',
        column: {
          title: 'Old Chapter',
          accessor: 'oldChapter.name',
          sortable: true,
        },
        default: true,
      },
      {
        id: 'newChapter',
        column: {
          title: 'New Chapter',
          accessor: 'newChapter.name',
          sortable: true,
        },
        default: true,
      },
    ]

    if (can(Permission.DownloadApplicationPdf)) {
      columns.push({
        id: 'applicationPdf',
        column: {
          accessor: 'applicationPdfUrl',
          title: 'PDF',
          renderItem: (url: string) => url ? <a href={`${url}?token=${AuthStore.generateTempAuthToken()}`} target="_blank">Download</a> : '---'
        },
        default: true,
      })
    }

    if (can(Permission.ArchiveApplication)) {
      columns.push({
        id: 'archiveApplication',
        column: {
          title: 'Actions',
          renderItem: (_, chapterTransferRequest: ChapterTransferRequest) => (!chapterTransferRequest.archivedAt && can(Permission.ArchiveMemberApplication, { chapterId: chapterTransferRequest.oldChapter.id })) ?
            <Button type="button" color="primary" title="Archive Application" onClick={() => this.archiveChapterTransferRequest(chapterTransferRequest)}><i className="fa fa-archive"/></Button> : null,
        },
        fixed: true,
      })
    }

    return columns
  }

  private tableViewRef = React.createRef<ManagedTableView>()

  private archiveChapterTransferRequest = (chapterTransferRequest: ChapterTransferRequest) => {
    AppStateStore.showConfirmationModal('Archive Application', <>
      <p>Archiving applications removes them from this list, but they can still be viewed later if necessary by clicking on the “Show Archived” filter below the “Find an Application” search bar.</p>
      <p>Are you sure you want to archive this application?</p>
    </>, (result, modal) => {
      if (result) {
        AppStateStore.showModalSpinner()

        ApiClient.getInstance().post(route(ApiRoutes.chapterTransferRequests.archive, { id: chapterTransferRequest.id }))
          .then(() => {
            this.tableViewRef.current && this.tableViewRef.current.fetchData()
            this.numberOfRequests.invalidate().then()
          })
          .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
          .then(() => AppStateStore.dismissModalSpinner())
      }

      modal.hide()
    })
  }

  private chapterTransferRequestsAdapter = new ApiTableViewAdapter(() => {
      const where = [{ _scope: 'inQueue' }]

      if (!safeNull(() => this.tableViewRef.current!.tableViewFilters!.showInactive)) {
        where.push({ _scope: 'notArchived' })
      }

      return {
        query: `
    chapterTransferRequests {
      id
      createdAt
      viewedAt
      submittedAt
      archivedAt
      status
      applicationPdfUrl
      
      member {
        id
        fullName
        profileImageUrl

        business {
          name
        }
      }
      
      oldChapter {
        id
        name
      }

      newChapter {
        id
        name
      }
      
      oldCategory {
        id
        name
      }
    }`,
        where: where,
      }
    },
    'chapterTransferRequests',
    ChapterTransferRequest
  )

  private renderTableViewHeader = () => {
    return <RequireRole role={UserRole.Admin}>
      {() =>
        <>
          <div className="list-view-info-icon">
            <i className="flaticon-suitcase"/>
          </div>
          <div className="list-view-info-text">
            <span>{this.numberOfRequests.loading ? <i className="fa fa-spinner fa-spin"/> : formatNumber(this.numberOfRequests.current!)}</span> Requests
          </div>
        </>
      }
    </RequireRole>
  }

  private filters = [
    new SelectFilter('status', SelectFilterTargetType.Field, 'status', 'Status', [
      'Submitted',
      'Approved',
      'Denied',
    ].map(s => ({ value: s, label: s }))),
    new DateFilter('createdAt', 'createdAt', 'Date Sent'),
    new AsyncSelectFilter('category', AsyncSelectFilterTargetType.Field, 'oldCategoryId', 'Category', loadCategoryOptions()),
  ]

  render (): React.ReactNode {
    return <ManagedTableView
      ref={this.tableViewRef}
      stateKey="ChapterTransferRequestsView"
      searchLabel="Find A Chapter Transfer Request"
      availableColumns={this.availableColumns}
      adapter={this.chapterTransferRequestsAdapter}
      header={this.renderTableViewHeader()}
      filters={this.filters}
      allowShowInactive={true}
      showInactiveLabel="Show Archived"
    />
  }
}
