import BaseView from "../BaseView"
import * as React from "react"
import { observer } from "mobx-react"
import DocumentLibrary from "../../components/DocumentLibrary"
import { ItemType, LibraryType } from "../../models/DocumentLibraryItem"

@observer
export default class NetworkVideoLibraryView extends BaseView {
  renderContentHeader (): React.ReactNode | null {
    return <>
      <h1>LeTip International Video Library</h1>
    </>
  }

  renderContentBody (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <DocumentLibrary
      itemType={ItemType.Video}
      libraryDescriptor={{
        libraryType: LibraryType.NetworkVideoLibrary,
      }}
    />
  }
}
