import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import ButtonLoader from "../../ButtonLoader"
import { autorun, computed, observable } from "mobx"
import SelectInput, { SelectOption } from "../../inputs/SelectInput"
import { loadChapterOptions } from "../../../api/AsyncHelpers"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import { route } from "../../../routes/routes"
import AppStateStore from "../../../stores/AppStateStore"
import Util from "../../../common/Util"
import { toast } from "react-toastify"
import Member from "../../../models/Member"

type UpdateMemberChapterModalProps = {
  isOpen: boolean
  toggle: () => void
  onClosed: () => void
  onSaved: () => void
  memberId: number
}

@observer
export default class UpdateMemberChapterModal extends React.Component<UpdateMemberChapterModalProps> {
  @observable private submitting = false
  @observable private selectedChapter?: SelectOption
  @observable private member?: Member

  private loadMember = () => {
    ApiClient.query(
      `
member {
  *
}
      `,
      {
        where: [{ id: this.props.memberId }]
      }
    )
      .then(response => this.member = new Member().init(response.data.member))
      .catch(() => {
      })
  }

  componentDidMount (): void {
    this.loadMember()

    autorun(() => this.checkCategoryAvailability())
  }

  componentDidUpdate (prevProps: Readonly<UpdateMemberChapterModalProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.memberId !== this.props.memberId) {
      this.loadMember()
    }
  }

  @observable private showCategoryAvailabilityWarning = false

  @computed
  private get showSameChapterWarning () {
    return this.member && this.selectedChapter && this.selectedChapter.value == this.member.chapterId
  }

  private checkCategoryAvailability = () => {
    if (this.selectedChapter && this.member && this.member.categoryId) {
      ApiClient.getInstance()
        .get(route(ApiRoutes.chapters.checkCategoryAvailability, { id: this.selectedChapter.value, categoryId: this.member.categoryId }))
        .then(response => this.showCategoryAvailabilityWarning = !response.data.available)
        .catch(() => this.showCategoryAvailabilityWarning = false)
    } else {
      this.showCategoryAvailabilityWarning = false
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    if (this.selectedChapter) {
      this.submitting = true
      ApiClient.getInstance()
        .post(route(ApiRoutes.members.assignToChapter, { id: this.props.memberId }), { chapter_id: this.selectedChapter.value })
        .then(() => {
          toast.success('Member assigned to new chapter')
          this.props.onSaved()
        })
        .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
        .then(() => this.submitting = false)
    }
  }

  private toggle = () => {
    !this.submitting && this.props.toggle()
  }

  render () {
    return <>
      <Modal isOpen={this.props.isOpen} toggle={this.toggle} onClosed={this.props.onClosed} size="md">
        <ModalHeader toggle={this.toggle}>
          Change Chapter
        </ModalHeader>
        <ModalBody>
          <p className="text-center">
            Select the chapter you would like to move this member to.
            They will be removed from any position they held in their old chapter and will be removed
            from their old chapter's program schedule.
          </p>
          <div className="form-group mt-4">
            <SelectInput
              loadOptions={loadChapterOptions}
              onChange={(option: SelectOption) => this.selectedChapter = option}/>
          </div>
          {
            this.showSameChapterWarning
              ? <div className="alert alert-warning">This is the same chapter the member already belongs to</div>
              : this.showCategoryAvailabilityWarning
              ? <div className="alert alert-warning">The category this member belongs to is already filled for this chapter. Are you sure you want to choose this chapter?</div>
              : null
          }
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={this.toggle}
          >Cancel</Button>
          <ButtonLoader
            type="button"
            color="primary"
            disabled={!this.selectedChapter}
            onClick={this.submit}
            loading={this.submitting}
          >Move Member To Chapter</ButtonLoader>
        </ModalFooter>
      </Modal>
    </>
  }
}