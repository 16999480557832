import BaseView from "../BaseView"
import * as React from "react"
import { observer } from "mobx-react"
import MemberCardSearch from "../../components/MemberCardSearch"
import { observable } from "mobx"
import * as _ from "lodash"
import { Button } from "reactstrap"
import Member from "../../models/Member"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { createBrowserHistory } from "history"

@observer

export default class NetworkMemberSearchView extends BaseView {
  private history = createBrowserHistory()

  @observable private filteredChapter?: { id: number, name: string }
  @observable private filteredCategory?: { id: number, name: string }
  @observable private filteredArea?: { id: number, name: string }
  @observable private filteredRegion?: { id: number, name: string }


  componentWillMount (): void {
    this.filteredChapter = _.get(this, 'props.location.state.chapter')
    this.filteredCategory = _.get(this, 'props.location.state.category')
    this.filteredArea = _.get(this, 'props.location.state.area')
    this.filteredRegion = _.get(this, 'props.location.state.region')
    this.history.replace(window.location.pathname, { ...this.history.location.state, chapter: undefined, category: undefined, area: undefined, region: undefined })
  }

  renderContentHeader () {
    return <>
      <h1>LeTip International Member Search</h1>
    </>
  }

  private showMemberDetail = (member: Member) => {
    this.props.history.push(route(Routes.network.member, { memberId: member.id }))
  }

  renderContentBody (): React.ReactNode {
    return <>
      <MemberCardSearch
        defaultCategory={this.filteredCategory}
        defaultChapter={this.filteredChapter}
        defaultArea={this.filteredArea}
        defaultRegion={this.filteredRegion}
        onCardClick={member => this.showMemberDetail(member)}
        cardActionsComponent={member => <Button
          onClick={() => this.showMemberDetail(member)}
          className="member-card-button"
          type="button"
          color="primary"
          block
        >View Member Details</Button>}
      />
    </>
  }
}
