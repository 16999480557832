import CsvTransformer from "./CsvTransformer"
import moment from "moment-timezone"
import Tip from "../Tip"
import { safeNull } from "../../common/Util"

export default class TipsCsvTransformer extends CsvTransformer<Tip> {
  get filename () {
    return `tips-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'id',
      valueTransformer: row => String(row.id),
    })

    this.addColumn({
      header: 'Passed',
      valueTransformer: row => row.datePassed.format('MM/DD/YYYY'),
    })

    this.addColumn({
      header: 'For Member',
      valueTransformer: row => safeNull(() => row.targetMember!.fullName) || '',
    })

    this.addColumn({
      header: 'For Chapter',
      valueTransformer: row => safeNull(() => row.targetMember!.chapter!.name) || '',
    })

    this.addColumn({
      header: 'For Category',
      valueTransformer: row => safeNull(() => row.targetMember ? row.targetMember.category!.name : row.targetCategory!.name) || '',
    })

    this.addColumn({
      header: 'From Member',
      valueTransformer: row => safeNull(() => row.sourceMember!.fullName) || '',
    })

    this.addColumn({
      header: 'From Chapter',
      valueTransformer: row => safeNull(() => row.sourceMember!.chapter!.name) || '',
    })

    this.addColumn({
      header: 'From Category',
      valueTransformer: row => safeNull(() => row.sourceMember!.category!.name) || '',
    })

    this.addColumn({
      header: 'Contact Name',
      valueTransformer: row => row.contactName || '',
    })

    this.addColumn({
      header: 'Contact Company',
      valueTransformer: row => row.contactCompany || '',
    })

    this.addColumn({
      header: 'Contact Phone',
      valueTransformer: row => row.contactPhone || '',
    })

    this.addColumn({
      header: 'Contact Email',
      valueTransformer: row => row.contactEmail || '',
    })

    this.addColumn({
      header: 'Tip Details',
      valueTransformer: row => row.tipDetails || '',
    })

    this.addColumn({
      header: 'Tip Type',
      valueTransformer: row => row.tipType || '',
    })

    this.addColumn({
      header: 'Status',
      valueTransformer: row => row.status || '',
    })

    this.addColumn({
      header: 'Value',
      valueTransformer: row => row.value || '',
    })
  }
}