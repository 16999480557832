import * as React from "react"
import { observer } from "mobx-react"
import { computed, observable } from "mobx"
import Util, { formatCurrency, joinStringsAnd, modelToCamelCase, modelToSnakeCase, publicPath, safeNull } from "../../common/Util"
import Config from "../../common/Config"
import { BarLoader } from "react-spinners"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import { route } from "../../routes/routes"
import Event from '../../models/Event'
import ButtonLoader from "../../components/ButtonLoader"
import PaymentMethod, { PaymentMethodType } from "../../models/PaymentMethod"
import FormHelper from "../../forms/FormHelper"
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import * as _ from "lodash"
import AddRenewalPaymentMethodForm from "../../components/AddRenewalPaymentMethodForm"
import AppStateStore from "../../stores/AppStateStore"
import Invoice from "../../models/Invoice"
import MembershipRenewal from "../../models/MembershipRenewal"
import { Alert } from 'reactstrap'
import AchAuthorizationVerbiage from '../../components/AchAuthorizationVerbiage'
import FormError from '../../components/FormError'

const uuid4 = require('uuid/v4')

type Props = {
  match: {
    params: {
      slug: string
    }
  },
}

type PaymentPlanData = {
  renewalFee: number
  taxRate: number
  paymentPlanData: {
    initialAmount: number
    fee: number
    intervals: number[]
  }
}

type DiscountData = {
  description: string
  percentage: number
}

@observer
export default class PayInvoiceView extends React.Component<Props> {
  @observable private loading = false
  @observable private error?: string
  @observable private event?: Event
  @observable private submitting = false
  @observable private showThankYou = false

  @observable private invoice: Invoice
  @observable private paymentMethods: PaymentMethod[]
  @observable private membershipRenewal: MembershipRenewal | null
  @observable private enableAutoRenew = false
  @observable private shouldShowPaymentPlan = true

  @observable private formState = new FormState({
    paymentMethod: undefined,
    usePaymentPlan: false,
  })

  @observable newPaymentMethods: {id: string, dataDescriptor: string, dataValue: string, description: string, paymentMethodType: string}[] = []

  @observable private formErrors = new ErrorBag()

  @observable private paymentPlanData: PaymentPlanData | null = null
  @observable private discountData: DiscountData | null = null

  @computed get selectedPaymentMethod () {
    return _.find(this.paymentMethods, pp => pp.id == this.formState.get('paymentMethod'))
  }

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @computed
  private get paymentPlanTotalAmount () {
    if (this.paymentPlanData) {
      let amount = this.paymentPlanData.renewalFee

      if (this.discountData && this.discountData.percentage > 0) {
        amount -= amount * (this.discountData.percentage / 100)
      }

      amount += this.paymentPlanData.paymentPlanData.fee

      if (this.invoice.lateFee && this.invoice.lateFeeAppliedAt) {
        amount += this.invoice.lateFee
      }
      return amount
    } else {
      return 0
    }
  }

  @computed
  private get paymentPlanInvoiceAmount () {
    return this.paymentPlanData ? this.paymentPlanData.paymentPlanData.initialAmount : 0
  }

  @computed
  private get paymentPlanInvoiceTaxAmount () {
    if (this.paymentPlanData) {
      return Math.round(this.paymentPlanData.paymentPlanData.initialAmount * this.paymentPlanData.taxRate * 100) / 100
    } else {
      return 0
    }
  }

  @computed
  private get paymentPlanInvoiceTotalAmount () {
    let amount = this.paymentPlanInvoiceAmount + this.paymentPlanInvoiceTaxAmount
    if (this.invoice.lateFee && this.invoice.lateFeeAppliedAt) {
      amount += this.invoice.lateFee
    }

    return amount
  }

  @computed
  private get paymentPlanDescription () {
    if (this.paymentPlanData) {
      let renewalFee = this.paymentPlanData.renewalFee
      if (this.discountData && this.discountData.percentage > 0) {
        renewalFee -= renewalFee * (this.discountData.percentage / 100)
      }

      const intervalAmount = (renewalFee - this.paymentPlanData.paymentPlanData.initialAmount + this.paymentPlanData.paymentPlanData.fee) / this.paymentPlanData.paymentPlanData.intervals.length
      const intervalDescriptions = this.paymentPlanData.paymentPlanData.intervals.map(interval => `${formatCurrency(intervalAmount)} in ${interval} days`)

      let initialAmount = this.paymentPlanData.paymentPlanData.initialAmount
      if (this.invoice.lateFee && this.invoice.lateFeeAppliedAt) {
        initialAmount += this.invoice.lateFee
      }

      return `I would like to choose the ${this.paymentPlanData.paymentPlanData.intervals.length + 1} part payment plan option${this.paymentPlanData.paymentPlanData.fee ? ` (for an additional ${formatCurrency(this.paymentPlanData.paymentPlanData.fee)} fee)` : ''} with ${formatCurrency(initialAmount)} paid now, ${joinStringsAnd(intervalDescriptions)} for a total of ${formatCurrency(this.paymentPlanTotalAmount)} USD${this.paymentPlanData.taxRate ? ' (plus tax)' : ''}`
    }

    return ''
  }

  componentDidMount (): void {
    this.loading = true

    ApiClient.getInstance()
      .get(route(ApiRoutes.public.invoice.show, { slug: this.props.match.params.slug }))
      .then(response => {
        this.loading = false
        this.invoice = new Invoice().init(modelToCamelCase(response.data.invoice))
        this.paymentMethods = response.data.payment_methods.map((p: {}) => new PaymentMethod().init(modelToCamelCase(p)))
        this.membershipRenewal = response.data.membership_renewal ? new MembershipRenewal().init(modelToCamelCase(response.data.membership_renewal)) : null
        this.paymentPlanData = response.data.payment_plan_data ? modelToCamelCase(response.data.payment_plan_data) as PaymentPlanData : null
        this.discountData = response.data.discount_data ? modelToCamelCase(response.data.discount_data) as DiscountData : null
        this.shouldShowPaymentPlan = (response.data.shouldShowPaymentPlan!=null)? response.data.shouldShowPaymentPlan: this.shouldShowPaymentPlan

        //console.log(this.discountData)
        const defaultPaymentMethod = _.find(this.paymentMethods, p => p.isDefault)

        if (defaultPaymentMethod) {
          this.formState.set('paymentMethod', String(defaultPaymentMethod.id))
        }
      })
      .catch(err => this.error = (err.response.status && err.response.status === 404) ? 'The page you are looking for does not seem to exist. Please check the link you clicked on to make sure it is correct.' : Util.extractErrorMessage(err.response))
      .then(() => this.loading = false)
  }

  private submit = () => {
    this.submitting = true

    this.formErrors.clearErrors()

    const newPaymentMethod = _.find(this.newPaymentMethods, p => p.id === this.formState.get('paymentMethod'))

    const paymentMethodId = newPaymentMethod
      ? {
        dataDescriptor: newPaymentMethod.dataDescriptor,
        dataValue: newPaymentMethod.dataValue,
        description: newPaymentMethod.description,
        paymentMethodType: newPaymentMethod.paymentMethodType,
      }
      : this.formState.get('paymentMethod')
    const paymentAmount = this.invoice.amountDue

    const postData = {
      payment_method: paymentMethodId,
      payment_amount: paymentAmount,
      enable_auto_renew: this.enableAutoRenew,
      use_payment_plan: this.formState.get('usePaymentPlan'),
    }

    ApiClient.getInstance()
      .post(route(ApiRoutes.public.invoice.pay, { slug: this.props.match.params.slug }), modelToSnakeCase(postData))
      .then(() => {
        this.showThankYou = true
      })
      .catch(error => Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      }))
      .then(() => this.submitting = false)
  }

  private renderError = () => {
    return <div className="alert alert-danger">{this.error}</div>
  }

  private renderInvoice = () => {
    const options: {text: string, value: any}[] = (this.paymentMethods || []).map(pm => ({ value: String(pm.id), text: pm.optionDescription }))
    this.newPaymentMethods.forEach(pm => options.push({
      value: pm.id,
      text: pm.description,
    }))
    options.push({
      value: '',
      text: 'Add New Payment Method',
    })

    return this.invoice
      ? <div className="card">
        <div className="card-header">
          <h5>Invoice: {this.invoice.description}</h5>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-end">
            <div>
              <table style={{ border: 'solid 1px #000' }}>
                <tr style={{ borderBottom: 'solid 1px #000' }}>
                  <td className="p-2 text-center">Date</td>
                  <td className="p-2 text-center" style={{ borderLeft: 'solid 1px #000' }}>Invoice #</td>
                  <td className="p-2 text-center" style={{ borderLeft: 'solid 1px #000' }}>Due Date</td>
                </tr>
                <tr>
                  <td className="p-2 text-center">{this.invoice.createdAt.format('MM/DD/YYYY')}</td>
                  <td className="p-2 text-center" style={{ borderLeft: 'solid 1px #000' }}>{this.invoice.invoiceNumber}</td>
                  <td className="p-2 text-center" style={{ borderLeft: 'solid 1px #000' }}>{this.invoice.dueDate ? this.invoice.dueDate.format('MM/DD/YYYY') : this.invoice.createdAt.clone().add(1, 'month').format('MM/DD/YYYY')}</td>
                </tr>
              </table>
            </div>
          </div>
          {
            this.membershipRenewal
              ? <>
                <p className="text-center">
                  <b>Member:</b> {this.membershipRenewal.membership.member.fullName}<br/>
                  <b>Chapter:</b> {safeNull(() => this.membershipRenewal!.membership.member.chapter!.name)}<br/>
                </p>
              </>
              : null
          }
          <div className="mb-4 row">
            <div className="col-4">
              <b>Bill To</b><br/>
              {this.invoice.billingContactName}<br/>
              {this.invoice.billingContactBusinessName}<br/>
              {this.invoice.billingContactAddress} {this.invoice.billingContactAddress2}<br/>
              {this.invoice.billingContactCity}, {this.invoice.billingContactState} {this.invoice.billingContactZipCode}<br/>
              {this.invoice.billingContactCountry}
            </div>
            <div className="col-4">
              <b>Ship To</b><br/>
              {this.invoice.shippingContactName}<br/>
              {this.invoice.shippingContactBusinessName}<br/>
              {this.invoice.shippingContactAddress} {this.invoice.shippingContactAddress2}<br/>
              {this.invoice.shippingContactCity}, {this.invoice.shippingContactState} {this.invoice.shippingContactZipCode}<br/>
              {this.invoice.shippingContactCountry}
            </div>
          </div>
          {
            this.formState.get('usePaymentPlan')
              ? <table className="invoice-table">
                <thead>
                <tr>
                  <th>Item</th>
                  <th className="text-right">Amount</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>LeTip International, Inc. Annual Membership - Payment 1 of {this.paymentPlanData!.paymentPlanData.intervals.length + 1}</td>
                  <td className="text-right">
                    {formatCurrency(this.paymentPlanInvoiceAmount)}
                  </td>
                </tr>
                {
                  this.paymentPlanInvoiceTaxAmount
                    ? <tr>
                      <td>Tax</td>
                      <td className="text-right">
                        {formatCurrency(this.paymentPlanInvoiceTaxAmount)}
                      </td>
                    </tr>
                    : null
                }
                {
                  this.invoice.lateFeeAppliedAt && this.invoice.lateFee
                    ? <tr>
                      <td>Late Fee</td>
                      <td className="text-right">
                        {formatCurrency(this.invoice.lateFee)}
                      </td>
                    </tr>
                    : null
                }
                </tbody>
                <tfoot>
                <tr>
                  <td/>
                  <td className="text-right">
                    {formatCurrency(this.paymentPlanInvoiceTotalAmount)}
                  </td>
                </tr>
                </tfoot>
              </table>
              : <table className="invoice-table">
                <thead>
                <tr>
                  <th>Item</th>
                  <th className="text-right">Amount</th>
                </tr>
                </thead>
                <tbody>
                {this.invoice.invoiceLineItems.map(lineItem =>
                  <tr key={lineItem.id}>
                    <td>{lineItem.description}</td>
                    <td className="text-right">
                      {formatCurrency(lineItem.amount)}
                    </td>
                  </tr>)}
                </tbody>
                <tfoot>
                <tr>
                  <td/>
                  <td className="text-right">
                    {formatCurrency(this.invoice.total)}
                  </td>
                </tr>
                </tfoot>
              </table>
          }
          {
            this.invoice.transactions
              ? <>
                <h6>Payments Made</h6>
                <table className="invoice-table">
                  <thead>
                  <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Payment Method</th>
                    <th className="text-right">Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.invoice.transactions.map(transaction =>
                    <tr key={transaction.id}>
                      <td>{transaction.createdAt.format('MM/DD/YYYY')}</td>
                      <td>{transaction.description}</td>
                      <td>{transaction.paymentMethod ? transaction.paymentMethod.description : null}</td>
                      <td className="text-right">
                        {formatCurrency(transaction.amount)}
                      </td>
                    </tr>)}
                  </tbody>
                </table>
              </>
              : null
          }
          <p className="text-center mt-4">
            <h3>Amount Due</h3>
            <h4>{
              this.formState.get('usePaymentPlan')
                ? formatCurrency(this.paymentPlanInvoiceTotalAmount)
                : formatCurrency(this.invoice.amountDue)
            }</h4>
          </p>
          {
            this.invoice.amountDue > 0
              ? <>
                {
                  (this.paymentPlanData && this.shouldShowPaymentPlan == true)
                    ? <div className="application-checkbox-container">
                      <div className="form-check form-checkbox">
                        <label>
                          <input type="checkbox"
                                 className="form-check-input"
                                 name="usePaymentPlan"
                                 checked={this.formState.get('usePaymentPlan')}
                                 onChange={this.formState.onChange}
                          />
                          <span className="label-text">{this.paymentPlanDescription}</span>
                        </label>
                      </div>
                      <FormError errors={this.formErrors} fieldName="usePaymentPlan"/>
                    </div>
                    : null
                }
                <div>
                  <div className="row" style={{ marginTop: 20 }}>
                    <div className="col-md-6 col-12">
                      <label>Payment Method</label>
                      {this.formHelper.renderSelectInput({
                        name: 'paymentMethod',
                        options: options,
                      })}
                    </div>
                  </div>

                  {
                    this.formState.get('paymentMethod') === ''
                      ? <div className="row">
                        <div className="col-md-6 col-12">
                          <AddRenewalPaymentMethodForm
                            onPaymentMethodAdded={(dataDescriptor: string, dataValue: string, description: string, paymentMethodType: string) => {
                              const newId = uuid4()
                              this.newPaymentMethods.push({
                                id: newId,
                                dataDescriptor: dataDescriptor,
                                dataValue: dataValue,
                                description: description,
                                paymentMethodType: paymentMethodType,
                              })

                              this.formState.set('paymentMethod', newId)
                            }}
                            onStoredPaymentMethodAdded={(uuid: string, description: string, paymentMethodType: string) => {
                              this.newPaymentMethods.push({
                                id: uuid,
                                dataDescriptor: 'tokenized_uuid',
                                dataValue: uuid,
                                description: description,
                                paymentMethodType: paymentMethodType,
                              })

                              this.formState.set('paymentMethod', uuid)
                            }}
                          />
                        </div>
                      </div>
                      : <>
                        {
                          (this.membershipRenewal && !this.membershipRenewal.membership.isAutoRenewal)
                            ? <div className="row">
                                <div className="col-md-6 col-12">
                                  <div className="application-checkbox-container">
                                    <div className="form-check form-checkbox">
                                      <label>
                                        <input type="checkbox"
                                               className="form-check-input"
                                               name="remember"
                                               checked={this.enableAutoRenew}
                                               onChange={ev => this.enableAutoRenew = ev.target.checked}
                                        />
                                        <span className="label-text">Enable auto renewal</span>
                                      </label>
                                  </div>
                                  {
                                    this.enableAutoRenew
                                      ? <p>
                                        I agree to have my LeTip membership automatically renew at the current rate.
                                        My membership will renew annually on the month that my membership application is approved by LeTip International, Inc.
                                        I acknowledge that LeTip International, Inc. reserves the right to increase membership dues; I understand that I will be given not less than 30 day notice of any such increase.
                                        <b>If I cancel the automatic renewal option for my membership, I will enter my request into LeTip Wired&trade; or I must send a written notice, not less than 30 days, before cancellation to: LeTip International, Inc., 7895 W Sunset Road, Suite 101, Las Vegas, NV 89113.</b>
                                      </p>
                                      : null
                                  }
                                </div>
                              </div>
                            </div>
                            : null
                        }

                        {
                          (this.selectedPaymentMethod && this.selectedPaymentMethod.paymentMethodType === PaymentMethodType.BankAccount)
                            ? <Alert color="warning">
                              <AchAuthorizationVerbiage/>
                            </Alert>
                            : null
                        }

                        <div className="form-buttons">
                          <ButtonLoader
                            color="primary"
                            onClick={() => this.submit()}
                            loading={this.submitting}>
                            Pay {this.formState.get('usePaymentPlan') ? formatCurrency(this.paymentPlanInvoiceTotalAmount) : formatCurrency(this.invoice.amountDue)} Now
                          </ButtonLoader>
                        </div>
                      </>
                  }
                </div>
              </>
              : null
          }
        </div>
      </div>
      : null
  }

  private renderThankYou = () => {
    return 'Thank you for your payment!'
  }

  render (): React.ReactNode {
    return <>
      <div className="header-container">
        <div className="brand">
          <img className="brand-image" alt="LeTip Wired" src={publicPath('img/wired-logo.png')}/>
        </div>
        <div className="header-nav"/>
      </div>
      <div className="body-container invitation-response">
        <div className="left-nav"/>
        <div className="content-container">
          <div className="content">
            {this.error
              ? this.renderError()
              : this.loading
                ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
                : this.showThankYou
                  ? this.renderThankYou()
                  : this.renderInvoice()
            }
          </div>
          <div className="content-footer">
            Version {Config.VERSION || '???'}{Config.BUILD_NUMBER ? `-${Config.BUILD_NUMBER}` : null} {Config.ENV !== 'production' ? `(${Config.ENV})` : null} {Config.NODE_ENV !== 'production' ? `[${Config.NODE_ENV}]` : null}
          </div>
        </div>
      </div>
    </>
  }
}
