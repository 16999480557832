import { Modal, ModalBody, ModalHeader } from "reactstrap"
import * as React from "react"
import { observer } from "mobx-react"
import EditEventForm from "./EditEventForm"
import { BarLoader } from "react-spinners"
import { observable } from "mobx"
import ApiClient from "../api/ApiClient"
import AppStateStore from "../stores/AppStateStore"
import Util from "../common/Util"
import Event from '../models/Event'

type EditEventModalProps = {
  isOpen: boolean
  toggle: () => void
  eventId: number
  onCancel: () => void
  onSaved: () => void
}

@observer
export class EditEventModal extends React.Component<EditEventModalProps> {
  static defaultProps = {
    clearAfterSave: true,
  }

  @observable private event?: Event

  componentDidMount (): void {
    this.loadEvent()
  }

  private loadEvent = () => {
    if (!this.props.eventId) {
      return
    }

    ApiClient.query(`
    event {
      *
      
      address {
        *
      }
    }
    `, {
      where: [{ id: this.props.eventId }]
    }).then(response => this.event = new Event().init(response.data.event))
      .catch(error => {
        this.props.toggle()
        AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response))
      })
  }

  componentDidUpdate (prevProps: Readonly<EditEventModalProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.eventId !== this.props.eventId) {
      this.event = undefined
      this.loadEvent()
    }
  }

  render (): React.ReactNode {
    const { isOpen, toggle } = this.props

    return <Modal isOpen={isOpen} onClosed={this.props.onCancel} size="lg">
      <ModalHeader toggle={toggle}>
        Edit Event
      </ModalHeader>
      <ModalBody>
        {
          this.event
            ? <EditEventForm
              onCancel={this.props.onCancel}
              onSaved={this.props.onSaved}
              event={this.event}
            />
            : <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
        }
      </ModalBody>
    </Modal>
  }
}