import * as React from "react"
import { observer } from "mobx-react"
import AuthStore from "../stores/AuthStore"

type ChapterRender = (allowed: boolean) => React.ReactNode

type Props = {
  renderIfDenied?: boolean
  children: ChapterRender
}

@observer
export default class RequireChapter extends React.Component<Props, never> {
  static defaultProps = {
    renderIfDenied: false,
  }

  render (): React.ReactNode {
    const user = AuthStore.getUser()


    const allow = !!(user && user.chapter)

    return (allow || this.props.renderIfDenied) ? this.props.children(allow) : null
  }
}