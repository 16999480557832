import * as React from "react"
import Chapter from "../../models/Chapter"
import AddressView from "../AddressView"
import MapView from "../MapView"
import classNames from 'classnames'
import { nl2br, normalizeUrl } from "../../common/Util"
import { computed, observable } from "mobx"
import Member from "../../models/Member"
import MemberProfileImage from "../MemberProfileImage"
import RequirePermission, { Permission } from "../RequirePermission"
import ChapterBasicInfoForm from "../ChapterBasicInfoForm"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { EventBusContext } from "../../common/EventBus"
import { observer } from "mobx-react"

type SupportAreaRep = {
  id: number
  title: string
  name: string
  profileImageUrl: string
}

@observer
export default class ChapterOverview extends React.Component<{
  chapter: Chapter
}> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  @observable
  private showModal = false
  @observable
  private editModalContent?: React.ReactNode
  @observable
  private editModalSize = 'lg'
  @observable
  private editModalTitle = 'Edit'

  private showEditModal = (title: string, component: React.ReactNode, size: string = 'lg') => {
    this.editModalTitle = title
    this.editModalContent = component
    this.showModal = true
    this.editModalSize = size
  }

  private closeEditModal = () => {
    this.showModal = false
    this.editModalContent = undefined
  }

  @computed get supportAreaReps () {
    const reps: SupportAreaRep[] = []

    this.props.chapter.supportAreas.forEach(supportArea => {
      supportArea.supportAreaUsers.current ? supportArea.supportAreaUsers.current.forEach(supportAreaUser => {
        return reps.push({
          id: supportAreaUser.id,
          title: supportAreaUser.title,
          name: supportAreaUser.user.member ? supportAreaUser.user.member.fullName : supportAreaUser.user.name,
          profileImageUrl: supportAreaUser.user.member ? supportAreaUser.user.member.getProfileImageUrl() : Member.getProfileImageUrl(null),
        })
      }) : []
    })

    return reps
  }

  private invalidateChapter = () => {
    this.context.eventBus.dispatch('chapter-invalidated')
  }

  private renderSocialMediaLinks (social: {}) {
    const sites = {
      'facebook': { name: 'Facebook', icon: 'fa fa-facebook-square' },
      'twitter': { name: 'Twitter', icon: 'fa fa-twitter-square' },
      'linkedin': { name: 'Linkedin', icon: 'fa fa-linkedin-square' },
      'instagram': { name: 'Instagram', icon: 'fa fa-instagram' },
      'youtube': { name: 'YouTube', icon: 'fa fa-youtube' },
      'pinterest': { name: 'Pinterest', icon: 'fa fa-pinterest' },
      'snapchat': { name: 'Snapchat', icon: 'fa fa-snapchat' },
      'vimeo': { name: 'Vimeo', icon: 'fa fa-vimeo' },
      'alignable': { name: 'Vimeo', icon: 'fa fa-home' },
    }

    const links = [
      'facebook',
      'twitter',
      'linkedin',
      'instagram',
      'youtube',
      'pinterest',
      'snapchat',
      'vimeo',
      'alignable',
    ].filter(soc => !!social[soc])

    return links.length
      ? links.map(soc => <div key={soc}>
        <a href={normalizeUrl(social[soc])} target="_blank"><i className={sites[soc].icon}/> {sites[soc].name}</a>
      </div>)
      : <p>Social media links have not been entered</p>
  }

  private hasSocialMediaLinks (social: {}) {
    return [
      'facebook',
      'twitter',
      'linkedin',
      'instagram',
      'youtube',
      'pinterest',
      'snapchat',
      'vimeo',
      'alignable',
    ].filter(soc => !!social[soc]).length > 0
  }

  render (): React.ReactNode {
    const chapter = this.props.chapter

    return <>
      <div className="row">
        <div className="col-5">
          <h6 className="text-muted mb-3">
            My Chapter <RequirePermission permission={Permission.EditChapter} context={{ chapterId: chapter.id }}>
            {() => <a href="" onClick={ev => {
              ev.preventDefault()
              this.showEditModal('Basic Info',
                <ChapterBasicInfoForm
                  chapterId={chapter.id}
                  onCancel={() => this.closeEditModal()}
                  onSaved={() => {
                    this.closeEditModal()
                    this.invalidateChapter()
                  }}
                />)
            }}><i className="fa fa-pencil"/></a>}
          </RequirePermission>
          </h6>
          <h5>{chapter.name}</h5>
          <div className={classNames('mt-3', 'mb-4', 'chapter-status-badge', 'badge', `badge-${chapter.getStatusColor()}`)}>
            {chapter.status}
          </div>
          <div className="stacked-labeled-list">
            <div>
              <label>Founded:</label>
              <span>{chapter.dateFounded ? chapter.dateFounded.format('MMMM D, YYYY') : '---'}</span>
            </div>
            <div>
              <label>Area:</label>
              <span>{chapter.area ? chapter.area.name : '---'}</span>
            </div>
            <div>
              <label>Website:</label>
              <span>{chapter.websiteUrl ? <a href={normalizeUrl(chapter.websiteUrl)} target="_blank">{chapter.websiteUrl}</a> : '---'}</span>
            </div>
          </div>
          {
            this.hasSocialMediaLinks(chapter.socialLinks)
              ? <div className="d-flex">
                <div style={{ width: 105 }}><b>Social Media:</b></div>
                <div>{this.renderSocialMediaLinks(chapter.socialLinks)}</div>
              </div>
              : null
          }

          <hr/>

          {this.supportAreaReps.length > 0 ? <>
            <h6 className="text-muted mb-3 mt-3">Area Support</h6>
            {this.supportAreaReps.map(rep => <div className="d-flex align-items-center mt-4" key={rep.id}>
              <div>
                <MemberProfileImage
                  size={45}
                  profileImageUrl={rep.profileImageUrl}
                  memberName={rep.name}
                />
              </div>
              <div className="flex-fill ml-2 mr-2">
                <div className="text-muted">{rep.title}</div>
                {rep.name}
              </div>
            </div>)}
          </> : null}
        </div>

        <div className="col-3">
          <h6 className="text-muted mb-3">Meeting Time &amp; Location</h6>
          <div><b>{chapter.meetingDay}</b></div>
          <div>{chapter.meetingTime}</div>
          <br/>
          <div><b>{chapter.meetingLocationDescription}</b></div>
          {chapter.meetingLocationAddress && <div><AddressView address={chapter.meetingLocationAddress} showMapLink={false}/></div>}
          <h6 className="text-muted mb-3 mt-3">Chapter Description</h6>
          <p>{chapter.bio ? nl2br(chapter.bio) : '---'}</p>
        </div>

        <div className="col-4">
          <h6 className="text-muted mb-3">Meeting Location Map</h6>
          {
            chapter.meetingLocationAddress
              ? <MapView address={chapter.meetingLocationAddress}/>
              : 'Not Available'
          }
        </div>
      </div>

      <Modal
        isOpen={this.showModal}
        size={this.editModalSize}
        toggle={() => this.showModal = false}
      >
        <ModalHeader toggle={() => this.showModal = false}>{this.editModalTitle}</ModalHeader>
        <ModalBody>{this.editModalContent}</ModalBody>
      </Modal>
    </>
  }
}
