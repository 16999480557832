import { Modal, ModalBody, ModalHeader } from "reactstrap"
import * as React from "react"
import { observer } from "mobx-react"
import  CreateRecurringEventForm, { CreateRecurringEventFormProps } from "./CreateRecurringEventForm"

type CreateRecurringEventModalProps = {
  isOpen: boolean
  toggle: () => void
} & CreateRecurringEventFormProps

@observer
export class CreateRecurringEventModal extends React.Component<CreateRecurringEventModalProps> {
  static defaultProps = {
    showCancelButton: true,
    clearAfterSave: true,
  }

  render (): React.ReactNode {
    const { isOpen, toggle, ...rest } = this.props

    return <Modal isOpen={isOpen} onClosed={this.props.onCancel} size="lg">
      <ModalHeader toggle={toggle}>
        Create Event
      </ModalHeader>
      <ModalBody>
        <CreateRecurringEventForm
          {...rest}
        />
      </ModalBody>
    </Modal>
  }
}
