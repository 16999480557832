import * as React from "react"
import Member from "../models/Member"
import AddressView from "./AddressView"
import { observable } from "mobx"
import { observer } from "mobx-react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import MemberBasicInfoForm from "./MemberBasicInfoForm"
import MemberShippingInformationForm from "./MemberShippingInformationForm"
import MemberBiographyForm from "./MemberBiographyForm"
import { can, Permission } from "./RequirePermission"
import { normalizeUrl, safeNull } from "../common/Util"
import BankCode from './BankCode'
import BankCodeLink from './BankCodeLink'
import BankEbookLink from './BankEbookLink'
import Config from '../common/Config'

type Props = {
  member: Member
  onMemberInvalidated: () => void
}

@observer
export default class MemberProfile extends React.Component<Props, never> {
  @observable
  private showModal = false
  @observable
  private editModalContent?: React.ReactNode
  @observable
  private editModalSize = 'lg'
  @observable
  private editModalTitle = 'Edit'

  private showEditModal = (title: string, component: React.ReactNode, size: string = 'lg') => {
    this.editModalTitle = title
    this.editModalContent = component
    this.showModal = true
    this.editModalSize = size
  }

  private closeEditModal = () => {
    this.showModal = false
    this.editModalContent = undefined
  }

  render (): React.ReactNode {
    const { member } = this.props
    return (
      <>
        <div className="detail-pane">
          <h5 className="text-muted">Basic Info {
            can(Permission.EditMember, { memberId: member.id, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region.id) })
              ? <a href="" onClick={ev => {
                ev.preventDefault()
                this.showEditModal('Basic Info',
                  <MemberBasicInfoForm
                    member={member}
                    onCancel={() => this.closeEditModal()}
                    onSaved={() => {
                      this.closeEditModal()
                      this.props.onMemberInvalidated()
                    }}
                  />)
              }}><i className="fa fa-pencil"/></a>
              : null
          }</h5>
          <div className="row">
            <div className="col-sm-2">
              <label>Prefix</label>
              {member.prefix || '---'}
            </div>
            <div className="col-sm-4">
              <label>First Name</label>
              {member.firstName || '---'}
            </div>
            <div className="col-sm-4">
              <label>Last Name</label>
              {member.lastName || '---'}
            </div>
            <div className="col-sm-2">
              <label>Suffix</label>
              {member.suffix || '---'}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-2">
              <label>Preferred Name</label>
              {member.preferredName || '---'}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <label>Contact Phone</label>
              {member.phoneNumber || '---'}
            </div>
            <div className="col-sm-4">
              <label>Contact Email</label>
              {member.emailAddress ? <a target="_blank" href={`mailto:${member.emailAddress}`}>{member.emailAddress}</a> : '---'}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-2">
              <label>Birth Date</label>
              {member.birthDate ? member.birthDate.format('MM/DD/YYYY') : '---'}
            </div>
            <div className="col-sm-5">
              <label>Gender</label>
              {member.gender || '---'}
            </div>
            <div className="col-sm-5">
              <label>Website</label>
              {member.websiteUrl ? <a href={normalizeUrl(member.websiteUrl)} target="_blank">{member.websiteUrl}</a> : '---'}
            </div>
          </div>

          <div className="detail-pane-spacer"/>
          <div className="row">
            <div className="col-sm-6">
              <h5 className="text-muted">Shipping Information {
                can(Permission.EditMember, { memberId: member.id, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region.id) })
                  ? <a href="" onClick={ev => {
                    ev.preventDefault()
                    this.showEditModal('Shipping Information',
                      <MemberShippingInformationForm
                        member={member}
                        onFinished={(dirty) => {
                          if (dirty) {
                            this.props.onMemberInvalidated()
                          }

                          this.closeEditModal()
                        }}
                      />, 'md')
                  }}><i className="fa fa-pencil"/></a>
                  : null
              }</h5>
              {
                member.shippingAddress
                  ? <AddressView address={member.shippingAddress}/>
                  : 'Use my business address as my shipping address'
              }
            </div>
            <div className="col-sm-6">
              {
                member.bankCode
                  ? <div>
                    <h5 className="text-muted"><b>BANK</b>CODE</h5>
                    <BankCode bankCode={member.bankCode} documentUrl={member.bankCodeDocumentUrl!}/>
                    {

                      !Config.HIDE_EBOOK_LINK
                        ? <BankEbookLink url={member.codebreakerEbookUrl}/>
                        : null
                    }
                  </div>
                  : <BankCodeLink url={member.crackMyCodeLandingUrl}/>
              }
            </div>
          </div>
          <div className="detail-pane-spacer"/>
          <div className="row">
            <div className="col-sm-12">
              <h5 className="text-muted">Biography {
                can(Permission.EditMember, { memberId: member.id, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region.id) })
                  ? <a href="" onClick={ev => {
                    ev.preventDefault()
                    this.showEditModal('Biography',
                      <MemberBiographyForm
                        member={member}
                        onFinished={(dirty) => {
                          if (dirty) {
                            this.props.onMemberInvalidated()
                          }

                          this.closeEditModal()
                        }}
                      />, 'md')
                  }}><i className="fa fa-pencil"/></a>
                  : null
              }</h5>
              {
                member.bio
                  ? <div className="display-linebreaks">{member.bio}</div>
                  : <div className="text-muted">You have not entered a bio yet</div>
              }
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.showModal}
          size={this.editModalSize}
        >
          <ModalHeader>{this.editModalTitle}</ModalHeader>
          <ModalBody>{this.editModalContent}</ModalBody>
        </Modal>
      </>
    )
  }
}
