import * as React from "react"
import { observer } from "mobx-react"
import Member from "../models/Member"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { loadChapterOptions } from "../api/AsyncHelpers"
import { observable } from "mobx"
import SelectInput, { SelectOption } from "./inputs/SelectInput"
import classNames from "classnames"
import ApiClient, { QueryWhereClause } from "../api/ApiClient"
import LazyResourcePanel from "./LazyResourcePanel"
import LazyResource from "../models/LazyResource"
import { AxiosError, AxiosResponse } from "axios"
import MemberProfileImage from "./MemberProfileImage"
import { safeNull } from "../common/Util"

type ChooseMemberModalProps = {
  isOpen: boolean
  toggle: () => void
  onChoose: (member: Member) => void
  onCancel: () => void
  title: string
  excludeMemberIds?: number[]
}

@observer
export default class ChooseMemberModal extends React.Component<ChooseMemberModalProps> {
  static defaultProps = {
    title: "Choose a Member",
  }

  @observable private selectedChapterId?: number = undefined

  @observable private members = new LazyResource<Member[]>((callback, error) => {
    const where: QueryWhereClause[] = [
      { chapterId: this.selectedChapterId },
      { _scope: 'active' },
    ]

    if (this.props.excludeMemberIds && this.props.excludeMemberIds.length) {
      where.push({
        id: 'id', op: 'notIn', value: this.props.excludeMemberIds
      })
    }

    return this.selectedChapterId
      ? ApiClient.query(
        `
members {
  *
    category {
      *
    }
  }
}
      `,
        {
          where: where,
          order: [
            { id: 'fullName' },
          ]
        }
      )
        .then((response: AxiosResponse) => {
          callback(response.data.members.map((m: {}) => new Member(m)))
        })
        .catch((ex: AxiosError) => error('There was an error loading the data', ex.response ? ex.response.status : undefined))
      : callback([])
  })

  render (): React.ReactNode {
    const { isOpen, toggle } = this.props

    return <Modal isOpen={isOpen} onClosed={this.props.onCancel} size="lg">
      <ModalHeader toggle={toggle}>
        {this.props.title}
      </ModalHeader>
      <ModalBody>
        <div className="form-row">
          <label>Sponsor's Chapter</label>
          <div className="input-group">
            <SelectInput
              loadOptions={loadChapterOptions}
              onChange={(option: SelectOption) => {
                this.selectedChapterId = option ? option.value : undefined
                this.members.invalidate().then(() => {
                })
              }}
              className={classNames('async-select-input')}
              classNamePrefix="async-select-input"
            />
          </div>
        </div>
        {
          this.selectedChapterId
            ?
            <div className="mt-4" key={`chapter-${this.selectedChapterId}`}>
              <label>Select a Sponsor</label>
              <div>
                <LazyResourcePanel resource={this.members} emptyMessage={"The selected chapter does not have any members"}>
                  {
                    members => <div className="striped-list-items">
                      {members.map(
                        member =>
                          <div className="d-flex align-items-center p-3" key={member.id}>
                            <MemberProfileImage profileImageUrl={member.profileImageUrl} size={60}/>
                            <div className="flex-fill pl-2">
                              {member.fullName}
                              <div className="text-muted">{safeNull(() => member.category!.name) || '---'}</div>
                            </div>
                            <div>
                              <Button
                                color="primary"
                                onClick={() => this.props.onChoose(member)}
                              >choose</Button>
                            </div>
                          </div>
                      )}
                    </div>
                  }
                </LazyResourcePanel>
              </div>
            </div>
            : null
        }
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => this.props.onCancel()}
        >Cancel</Button>
      </ModalFooter>
    </Modal>
  }
}
