import BaseModel from "./BaseModel"
import Region from "./Region"

export default class Area extends BaseModel {
  id: number
  name: string
  status: string | 'Active' | 'Inactive'
  region: Region

  numberOfChapters?: number
  numberOfMembers?: number

  constructor (data = {}) {
    super();
    this.init(data);
  }

  setRegion (r: {}): Region {
    return new Region(r)
  }
}