import * as React from "react"
import { autorun, computed, observable, toJS } from 'mobx'
import { observer } from "mobx-react"
import moment, { Moment } from "moment-timezone"
import { CreateEventModal } from "../CreateEventModal"
import ApiClient, { QueryWhereClause } from "../../api/ApiClient"
import Event from "../../models/Event"
import axios, { CancelTokenSource } from "axios"
import { EventDetailModal } from "../EventDetailModal"
import * as _ from "lodash"
import classNames from 'classnames'
import { Button, ButtonGroup } from "reactstrap"
import AuthStore from "../../stores/AuthStore"
import { EditEventModal } from "../EditEventModal"
import { formatDateForCalendar } from '../../common/Util'
import CreateRecurringEventForm from "../CreateRecurringEventForm"

type CalendarViewProps = {
  visibleAreas: number[]
  visibleMeetingTypes: string[]
}

const MAX_EVENTS_PER_DAY = 2

@observer
export default class Calendar extends React.Component<CalendarViewProps> {
  @observable private events: Event[] = []
  @observable private isCreateEventModalVisible = false
  @observable private detailEventId?: number
  @observable private editEventId?: number
  @observable private loading = false
  @observable private viewMonth = moment().startOf('month')
  @observable private showMoreDate?: Moment

  @computed
  private get firstVisibleDate () {
    const date = this.viewMonth.clone().startOf('month')
    date.subtract(date.day(), 'day')
    return date
  }

  @computed
  private get lastVisibleDate () {
    const date = this.viewMonth.clone().endOf('month')
    date.add(6 - date.day(), 'day')
    return date
  }

  showCreateEventModal = () => {
    this.isCreateEventModalVisible = true
  }


  private fetchDataCancelToken?: CancelTokenSource

  private eventTypeClassName = (eventType: string) => {
    switch(eventType) {
      case 'Chapter Meeting':
        return 'event-type-chapter-meeting'
      case 'Board Meeting':
        return 'event-type-board-meeting'
      case 'Power Events':
        return 'event-type-power-events'
      case 'Network Training Seminar':
        return 'event-type-nts'
      case 'White Badge Training Seminar':
        return 'event-type-white-badge-training-seminar'
      case 'National Monthly Special Topic Call: Pres & VP':
        return 'event-type-monthly-pres-vp-call'
      case 'National Monthly Special Topic Call: Membership & TipMaster':
        return 'event-type-monthly-membership-tipmaster-call'
      case 'Board Training Seminar':
        return 'event-type-board-training-seminar'
      case 'Business Mixer':
        return 'event-type-business-mixer'
      case 'LeTip National Leadership Call':
        return 'event-type-national-leadership-call'
      case 'Other':
        return 'event-type-other'
      default:
        return undefined
    }
  }

  @computed
  private get visibleStartDate () {
    return this.viewMonth.clone().startOf('month').subtract(7, 'day')
  }

  @computed
  private get visibleEndDate () {
    return this.viewMonth.clone().endOf('month').add(7, 'day')
  }

  componentDidMount (): void {
    // need to fetch the initial range of events
    autorun(() => this.fetchEvents())
  }

  private fetchEvents = () => {
    this.loading = true
    if (this.fetchDataCancelToken) {
      this.fetchDataCancelToken.cancel()
      this.fetchDataCancelToken = undefined
    }
    this.fetchDataCancelToken = axios.CancelToken.source()

    this.events = []

    const from = this.visibleStartDate
    const to = this.visibleEndDate

    const filters: QueryWhereClause[] = [
      { id: 'startsAt', op: '<', value: to.clone().add(1, 'day').toISOString() },
      { id: 'endsAt', op: '>=', value: from.toISOString() },
      { _scope: 'active' },
      { _scope: 'visible' },
    ]

    if (!this.props.visibleMeetingTypes.length && !this.props.visibleAreas.length) {
      if (AuthStore.getUser()!.member) {
        filters.push({ _scope: 'member', value: AuthStore.getUser()!.member!.id })
      } else {
        filters.push({ _scope: 'admin' })
      }
    } else {
      if (this.props.visibleMeetingTypes.length) {
        filters.push({ id: 'eventType', op: 'in', value: toJS(this.props.visibleMeetingTypes) })
      }

      if (this.props.visibleAreas.length) {
        filters.push(this.props.visibleAreas.length ? { _scope: 'areas', value: toJS(this.props.visibleAreas) } : undefined)
      }
    }

    return ApiClient.query(
      `
    events {
      *

      chapter {
        *
      }
    }
    `,
      {
        where: [
          ...filters,
        ],
        limit: 5000,
      },
      {
        cancelToken: this.fetchDataCancelToken.token,
      }
    )
      .then(response => {
        this.events = response.data.events.map((e: {}) => new Event().init(e))
        this.loading = false
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          this.loading = false
        }
      })
  }

  private showEventDetail = (event: Event) => {
    this.detailEventId = event.id
  }

  private renderEvents = (day: Moment) => {
    const todayEvents = _.sortBy<Event>(
      this.events.filter(e => e.startsAt.isSameOrBefore(day.clone().endOf('day')) && e.endsAt.isSameOrAfter(day.clone().startOf('day'))),
      e => e.startsAt.clone().tz(e.timezone).format('HH:mm')
    )

    const visibleEvents = todayEvents.slice(0, MAX_EVENTS_PER_DAY)
    const localTzOffset = moment().tz(AuthStore.getUser()!.timezone || 'UTC').format('Z')

    return <>
      {
        visibleEvents.map(event => <div key={event.id} className={classNames('event-wrapper', `event-status-${event.status.toLowerCase()}`, this.eventTypeClassName(event.eventType))} onClick={() => this.showEventDetail(event)}>
          <div><b>{formatDateForCalendar(event.startsAt, event.timezone, AuthStore.getUser()!.timezone || 'UTC', event.showInLocalTimezone)}</b> {event.title}</div>
          {
            event.chapter
              ? <div>{event.chapter.name}</div>
              : null
          }
        </div>)
      }
      {

        visibleEvents.length < todayEvents.length
          ? <>
            <div className="more-events"><a href="" onClick={ev => {
              ev.preventDefault()
              this.showMoreDate = day.clone()
            }}>{todayEvents.length - visibleEvents.length} more</a></div>
            {
              this.showMoreDate && this.showMoreDate.isSame(day)
                ? <div className="more-events-list">
                  <div className="text-right">
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.showMoreDate = undefined
                    }}><i className="fa fa-times"/></a>
                  </div>
                  {
                    todayEvents.map(event =>
                    <div key={event.id} className="event-wrapper" onClick={() => this.showEventDetail(event)}>

                      <div><b>{event.startsAt.clone().tz(event.timezone).format(`h:mma${event.startsAt.clone().tz(event.timezone).format('Z') !== localTzOffset ? ' z' : ''}`)}</b> {event.title}</div>
                      {
                        // event.chapter
                        //   ? <div>{event.chapter.name}</div>
                        //   : null
                      }
                    </div>)
                  }
                </div>
                : null
            }
          </>
          : null
      }
    </>
  }

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    const dates = []
    const cur = this.firstVisibleDate.clone()
    while (cur.isSameOrBefore(this.lastVisibleDate)) {
      dates.push(cur.clone())
      cur.add(1, 'day')
    }

    const weeks = _.chunk(dates, 7)

    return <>
      <div className="calendar-wrapper">
        <div className="calendar-header">
          <div className="calendar-nav">
            <ButtonGroup>
              <Button
                type="button"
                color="primary"
                onClick={() => {
                  this.viewMonth = this.viewMonth.clone().subtract(1, 'month')
                  this.showMoreDate = undefined
                }}
              ><i className="fa fa-chevron-left"/></Button>
              <Button
                type="button"
                color="primary"
                onClick={() => {
                  this.viewMonth = this.viewMonth.clone().add(1, 'month')
                  this.showMoreDate = undefined
                }}
              ><i className="fa fa-chevron-right"/></Button>
            </ButtonGroup>
            <Button
              type="button"
              color="primary"
              className="today-button"
              onClick={() => {
                this.viewMonth = moment().startOf('month')
                this.showMoreDate = undefined
              }}
            >Today</Button>
          </div>
          <div className="calendar-title">
            {this.viewMonth.format('MMMM YYYY')}
          </div>
        </div>
        <div className="calendar-view-wrapper">
          <table className="calendar">
            <thead>
            <tr>
              <th>Sunday</th>
              <th>Monday</th>
              <th>Tuesday</th>
              <th>Wednesday</th>
              <th>Thursday</th>
              <th>Friday</th>
              <th>Saturday</th>
            </tr>
            </thead>
            <tbody>
            {
              weeks.map((week, idx) => <tr key={idx}>
                {week.map(day => <td key={day.toISOString()} className={classNames({ 'today': day.clone().startOf('day').isSame(moment().startOf('day')) })}>
                  <div className="calendar-day-wrapper">
                    <div className={classNames('calendar-date', { 'out-of-month': day.month() !== this.viewMonth.month() })}>{day.date()}</div>
                    {this.renderEvents(day)}
                  </div>
                </td>)}
              </tr>)
            }
            </tbody>
          </table>
          {
            this.showMoreDate
              ? <div className={'calendar-loading-overlay'} onClick={() => {
                this.showMoreDate = undefined
              }}/>
              : null
          }
          {
            this.loading
              ? <div className={'calendar-loading-overlay'}>
                <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"/>
              </div>
              : null
          }
        </div>
      </div>
      <CreateEventModal
        isOpen={this.isCreateEventModalVisible}
        toggle={() => this.isCreateEventModalVisible = !this.isCreateEventModalVisible}
        onCancel={() => this.isCreateEventModalVisible = false}
        onSaved={() => {
          this.isCreateEventModalVisible = false
          this.fetchEvents()
        }}
      />

      <EditEventModal
        eventId={this.editEventId || 0}
        isOpen={!!this.editEventId}
        toggle={() => this.editEventId = undefined}
        onCancel={() => this.editEventId = undefined}
        onSaved={() => {
          this.editEventId = undefined
          this.fetchEvents()
        }}/>

      <EventDetailModal
        isOpen={!!this.detailEventId}
        toggle={() => this.detailEventId = undefined}
        eventId={this.detailEventId!}
        onEventDeleted={() => {
          this.detailEventId = undefined
          this.fetchEvents()
        }}
        onEventUpdated={() => {
          this.detailEventId = undefined
          this.fetchEvents()
        }}
        onEditEvent={event => {
          this.detailEventId = undefined
          this.editEventId = event.id
        }}
      />
    </>
  }
}
