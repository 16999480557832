import { observer } from "mobx-react"
import * as React from "react"
import { IObservableArray, observable } from "mobx"
import { Button, Modal, ModalFooter, ModalHeader } from "reactstrap"
import ModalBody from "reactstrap/lib/ModalBody"
import { TableViewColumn } from "./TableView"

export type ChooseableColumn = {
  column: TableViewColumn
  fixed?: boolean
  hidden?: boolean
  default?: boolean
}

@observer
export class TableViewColumnChooser extends React.Component<{
  columns: ChooseableColumn[],
  onChange: (visibleColumns: TableViewColumn[]) => void
}> {
  @observable private isOpen = false
  @observable private selectedColumnIndexes: IObservableArray<number> = observable.array([])

  static getInitialColumns (columns: ChooseableColumn[]) {
    return columns.filter(col => col.fixed || col.default).map(col => col.column)
  }

  componentDidMount (): void {
    this.props.columns.forEach((col, idx) => col.default && this.selectedColumnIndexes.push(idx))
  }

  private toggle = () => {
    this.isOpen = !this.isOpen
  }

  private toggleColumn = (idx: number) => {
    const i = this.selectedColumnIndexes.indexOf(idx)
    if (i === -1) {
      this.selectedColumnIndexes.push(idx)
    } else {
      this.selectedColumnIndexes.remove(idx)
    }
  }

  private getVisibleColumns = () => {
    return this.props.columns
      .filter((col, idx) => col.fixed || this.selectedColumnIndexes.indexOf(idx) > -1)
      .map(c => c.column)
  }

  render (): React.ReactNode {
    return <>
      <Button
        color="primary"
        onClick={this.toggle}>
        <i className="fa fa-list"/> Choose Columns
      </Button>
      <Modal toggle={this.toggle} isOpen={this.isOpen} onClosed={() => this.props.onChange(this.getVisibleColumns())}>
        <ModalHeader toggle={this.toggle}>Choose Visible Columns</ModalHeader>
        <ModalBody>
          {this.props.columns.map((col, idx) => col.hidden ? null : <div
            key={idx}
            style={{ cursor: 'pointer' }}
            onClick={() => !col.fixed && this.toggleColumn(idx)}
            className="d-flex"
          >
            <div style={{ width: 30 }}>
              {
                col.fixed
                  ? <i className="fa fa-check text-muted"/>
                  :
                  this.selectedColumnIndexes.indexOf(idx) > -1
                    ? <i className="fa fa-check text-success"/>
                    : null
              }
            </div>
            {col.column.title}
          </div>)}

        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={this.toggle}
          >Close</Button>
        </ModalFooter>
      </Modal>
    </>
  }
}