import { TabPane, TabPaneProps } from "reactstrap"
import * as React from "react"
import { observer } from "mobx-react"

export type LazyTabPaneProps = {
  activeTab: number | string
}

@observer
export default class LazyTabPane extends React.Component<TabPaneProps & LazyTabPaneProps> {
  private hasRendered = false

  private _render = (childProps: any) => {
    this.hasRendered = true
    return <TabPane {...childProps}>{this.props.children}</TabPane>
  }

  render (): React.ReactNode {
    const { activeTab, ...childProps } = this.props
    return (this.hasRendered || this.props.tabId === activeTab) ? this._render(childProps) : null
  }
}