import BaseView from "../BaseView"
import { observer } from "mobx-react"
import * as React from "react"
import SentSystemMessages from "../../components/system/SentSystemMessages"
import { observable } from "mobx"
import { Nav, NavItem, NavLink, TabContent } from "reactstrap"
import classNames from "classnames"
import LazyTabPane from "../../components/LazyTabPane"
import SystemMessageTemplates from "../../components/system/SystemMessageTemplates"

const TABS = {
  logs: 'logs',
  templates: 'templates',
}

@observer
export default class TransactionsView extends BaseView {
  @observable private activeTab = TABS.logs

  private setTab = (tab: string) => {
    this.activeTab = tab
  }

  renderContentHeader () {
    return <>
      <h1>System Messages</h1>
    </>
  }

  render () {
    return <div className="card">
      <div className="card-header">
        <Nav tabs className="card-header-tabs">
          <NavItem className={classNames({ active: this.activeTab === TABS.logs })}>
            <NavLink
              onClick={() => {
                this.setTab(TABS.logs)
              }}
            >Logs</NavLink>
          </NavItem>
          <NavItem className={classNames({ active: this.activeTab === TABS.templates })}>
            <NavLink
              onClick={() => {
                this.setTab(TABS.templates)
              }}
            >Templates</NavLink>
          </NavItem>
        </Nav>
      </div>
      <div className="card-body">
        <div className="mt-4">
          <TabContent activeTab={this.activeTab}>
            <LazyTabPane tabId={TABS.logs} activeTab={this.activeTab}>
              <SentSystemMessages
                stateKey="SystemMessagesView"
              />
            </LazyTabPane>
            <LazyTabPane tabId={TABS.templates} activeTab={this.activeTab}>
              <SystemMessageTemplates/>
            </LazyTabPane>
          </TabContent>
        </div>
      </div>
    </div>
  }
}
