import * as React from "react"
import { observer } from "mobx-react"
import { observable, toJS } from "mobx"
import { ITableViewFilterValue } from "./TableViewFilters"
import { TableViewFilter } from "./TableViewFilter"

type TableViewFilterGroupRender = (filterGroup: TableViewFilterGroup) => React.ReactNode

@observer
export class TableViewFilterGroup extends React.Component<{
  onChanged: (filters: ITableViewFilterValue[]) => void
  children: TableViewFilterGroupRender
}> {
  @observable private filters = {}

  render (): React.ReactNode {
    return this.props.children(this)
  }

  onFilterChanged = (filter: TableViewFilter<any>) => {
    const filters = toJS(this.filters)
    filters[filter.props.id] = filter.getFilterValue()
    this.filters = filters
    this.props.onChanged(Object.values(this.filters).filter(v => !!v) as ITableViewFilterValue[])
  }
}