import CsvTransformer from "./CsvTransformer"
import { safeNull } from "../../common/Util"
import Chapter from "../Chapter"
import moment from "moment-timezone"

export default class ChaptersCsvTransformer extends CsvTransformer<Chapter> {
  get filename () {
    return `chapters-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'id',
      valueTransformer: row => String(row.id),
    })

    this.addColumn({
      header: 'name',
      valueTransformer: row => String(row.name),
    })

    this.addColumn({
      header: 'Active Members',
      valueTransformer: row => safeNull(() => row.numberOfActiveMembers) || '',
    })

    this.addColumn({
      header: 'Region',
      valueTransformer: row => safeNull(() => row.area!.region.name) || '',
    })

    this.addColumn({
      header: 'Area',
      valueTransformer: row => safeNull(() => row.area!.name) || '',
    })

    this.addColumn({
      header: 'Meeting Location',
      valueTransformer: row => safeNull(() => row.meetingLocationDescription) || '',
    })

    this.addColumn({
      header: 'Meeting Day',
      valueTransformer: row => safeNull(() => row.meetingDay) || '',
    })

    this.addColumn({
      header: 'Meeting Time',
      valueTransformer: row => safeNull(() => row.meetingTime) || '',
    })

    this.addColumn({
      header: 'Status',
      valueTransformer: row => safeNull(() => row.status) || '',
    })

    this.addColumn({
      header: 'Address',
      valueTransformer: row => safeNull(() => row.meetingLocationAddress!.address) || '',
    })

    this.addColumn({
      header: 'Address 2',
      valueTransformer: row => safeNull(() => row.meetingLocationAddress!.address2) || '',
    })

    this.addColumn({
      header: 'City',
      valueTransformer: row => safeNull(() => row.meetingLocationAddress!.city) || '',
    })

    this.addColumn({
      header: 'State',
      valueTransformer: row => safeNull(() => row.meetingLocationAddress!.state) || '',
    })

    this.addColumn({
      header: 'Zip',
      valueTransformer: row => safeNull(() => row.meetingLocationAddress!.zipCode) || '',
    })

    this.addColumn({
      header: 'President Name',
      valueTransformer: row => safeNull(() => row.president!.member.getPreferredFullName())
    })

    this.addColumn({
      header: 'President Phone Number',
      valueTransformer: row => safeNull(() => row.president!.member.phoneNumber)
    })

    this.addColumn({
      header: 'President Email',
      valueTransformer: row => safeNull(() => row.president!.member.emailAddress)
    })
  }
}
