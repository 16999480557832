import * as React from "react"
import { observer } from "mobx-react"
import { LinkContainer } from "react-router-bootstrap"
import * as H from "history"
import { Button } from "reactstrap"
import BaseView from "../BaseView"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { ApiTableViewAdapter } from "../../components/table-view/ApiTableViewAdapter"
import { QueryWhereClause } from "../../api/ApiClient"
import { ManagedChooseableColumn } from "../../components/table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "../../components/table-view/ManagedTableView";
import DateFilter from '../../components/table-view/filters/DateFilter'
import NtsEvent from '../../models/NtsEvent'
import { Link } from 'react-router-dom'
import { formatDateForCalendar } from '../../common/Util'
import AuthStore from '../../stores/AuthStore'

type Props = {
  location: H.Location<H.LocationState>,
}

@observer
export default class NtsEventsView extends BaseView<Props> {
  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>NTS Events</h1>
        <ul className="content-header-actions">
          <li>
            <LinkContainer to={route(Routes.system.ntsEvents.create)}>
              <Button color="primary" onClick={() => {
              }}><i className="fa fa-plus-circle"/> Add New NTS Event</Button>
            </LinkContainer>
          </li>
        </ul>
      </>
    )
  }

  private tableViewRef = React.createRef<ManagedTableView>()

  private availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'date',
      column: {
        accessor: 'date',
        title: 'Date',
        renderItem: (d, ntsEvent) => <Link to={route(Routes.system.ntsEvents.detail, {id: ntsEvent.id})}>{d.clone().tz(ntsEvent.timezone).format('dddd, MMMM Do')} @ {formatDateForCalendar(d, ntsEvent.timezone, AuthStore.getUser()!.timezone || 'UTC', false)}{ntsEvent.cancelledAt ? ` (Cancelled)` : null}</Link>,
        sortable: true,
      },
      default: true,
    },
    {
      id: 'numberOfMembers',
      column: {
        accessor: 'numberOfMembers',
        title: 'Members',
        sortable: true,
      },
      fixed: true,
    },
  ]

  private ntsEventsAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      return {
        query: `
      ntsEvents {
        *
      }
    `,
        where: where,
      }
    },
    'ntsEvents',
    NtsEvent
  )

  renderContentBody (): React.ReactNode {
    return this.renderTable()
  }

  private filters = [
    new DateFilter('date', 'date', 'Date Scheduled'),
  ]

  private renderTable = () => {
    return <ManagedTableView
      stateKey="NtsEventsView"
      hideItemCount={true}
      showSearch={false}
      alwaysShowFilters={true}
      filters={this.filters}
      allowShowInactive={false}
      ref={this.tableViewRef}
      adapter={this.ntsEventsAdapter}
      availableColumns={this.availableColumns}
      defaultSort={{sortKey: 'date', descending: true}}
    />
  }
}
