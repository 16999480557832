import CsvTransformer from "./CsvTransformer"
import { safeNull } from "../../common/Util"
import moment from "moment-timezone"
import BoardTrainingMember from "../BoardTrainingMember"

export default class BoardTrainingMembersCsvTransformer extends CsvTransformer<BoardTrainingMember> {
  get filename () {
    return `board-training-members-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'First',
      valueTransformer: row => safeNull(() => row.member.firstName) || '',
    })

    this.addColumn({
      header: 'Last',
      valueTransformer: row => safeNull(() => row.member.lastName) || '',
    })

    this.addColumn({
      header: 'Category',
      valueTransformer: row => safeNull(() => row.member.category!.name) || '',
    })

    this.addColumn({
      header: 'Chapter Name',
      valueTransformer: row => safeNull(() => row.member.chapter!.name) || '',
    })

    this.addColumn({
      header: 'Join Date',
      valueTransformer: row => safeNull(() => row.member.joinDate!.format('MM/DD/YYYY')) || '',
    })

    this.addColumn({
      header: 'Badge Color',
      valueTransformer: row => safeNull(() => row.member.badgeColor) || '',
    })

    this.addColumn({
      header: 'Email',
      valueTransformer: row => safeNull(() => row.member.emailAddress) || '',
    })

  }
}
