import ReportDataSource from "../ReportDataSource"
import { ApiTableViewAdapter, ApiTableViewAdapterOptions } from "../../components/table-view/ApiTableViewAdapter"
import Chapter from "../../models/Chapter"
import { QueryWhereClause } from "../../api/ApiClient"
import { ChapterResource } from "../resources/ChapterResource"

export default class ChapterReportDataSource extends ReportDataSource {
  constructor () {
    super(ChapterResource)
  }

  createTableViewAdapter (withRelationships: string[], where: QueryWhereClause[], options?: ApiTableViewAdapterOptions<any>): ApiTableViewAdapter<any> {
    return new ApiTableViewAdapter(() => {
      return {
        query: `
        chapters {
          *
          ${this.expandRelationships(withRelationships)}
        }`,
        where: where,
      }
    }, 'chapters', Chapter, options)
  }
}