import Member from "../models/Member"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observable, toJS } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { observer } from "mobx-react"
import { Button } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import Util, { logException, modelToSnakeCase } from "../common/Util"
import { toast } from "react-toastify"
import { days, HoursOfOperationDay } from "../models/Business"

type Props = {
  member: Member
  days?: HoursOfOperationDay[]
  onCancel: () => void
  onSaved: () => void
}

type EditFormState = {
  days?: HoursOfOperationDay[]
}

@observer
export default class HoursOfOperationForm extends React.Component<Props, never> {
  @observable
  private formState = new FormState<EditFormState>({
    days: toJS(days.reduce((ds, day) => {
      if (!ds.find(d => d.day == day)) {
        ds.push({ day, open: '', close: '' })
      }

      return ds;
    }, this.props.days || []))
  })

  @observable
  private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable
  private submitting = false

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.members.updateBusinessHours(this.props.member.id, modelToSnakeCase(this.formHelper.toObject()))
      .then(() => {
        toast.success('Hours of Operation Updated')
        this.onSaved()
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private onCancelClicked = () => {
    this.props.onCancel()
  }

  private onSaved = () => {
    this.props.onSaved()
  }

  render (): React.ReactNode {
    return <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      {this.formState.get('days').map((day: HoursOfOperationDay, idx: number) => (
        <div key={day.day} className="form-row">
          <div className="col-sm-4">
            <p>{day.day}</p>
          </div>
          <div className="col-sm-4">
            {this.formHelper.renderTextInput({
              type: 'time',
              name: `days.${idx}.open`,
              placeholder: 'Open Time',
            })}
          </div>
          <div className="col-sm-4">
            {this.formHelper.renderTextInput({
              type: 'time',
              name: `days.${idx}.close`,
              placeholder: 'Close Time',
            })}
          </div>
        </div>
      ))}

      <div className="form-buttons">
        <Button type="button" color="" onClick={() => this.onCancelClicked()}>Cancel</Button>
        <ButtonLoader type="submit" color="success" loading={this.submitting}>Save Changes</ButtonLoader>
      </div>
    </form>
  }
}