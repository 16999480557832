import * as React from "react"
import { observer } from "mobx-react"
import Notice from "../models/Notice"
import { observable } from "mobx"
import { Alert } from "reactstrap"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import Util, { nl2br } from "../common/Util"
import AuthStore from "../stores/AuthStore"
import { route } from "../routes/routes"
import AppStateStore from "../stores/AppStateStore"

@observer
export default class NoticeList extends React.Component {
  @observable private notices: Notice[] = []

  private loadNotices = () => {
    ApiClient.query(`
    notices {
      *
    }`, {
      where: [{ _scope: 'visibleToUser', value: AuthStore.getUser()!.id }],
      order: ['createdAt'],
    }).then(response => {
      this.notices = response.data.notices.map((n: any) => new Notice().init(n))
    }).catch(() => {
      this.notices = []
    })
  }

  private loadInterval?: number = undefined

  private dismissNotice = (notice: Notice) => {
    this.notices = this.notices.filter(n => n.id !== notice.id)
    ApiClient.getInstance().post(route(ApiRoutes.notices.dismiss, { id: notice.id }))
      .then(() => {
      })
      .catch(err => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err)))
      .then(() => this.loadNotices())
  }

  componentDidMount (): void {
    this.loadNotices()

    this.loadInterval = window.setInterval(() => this.loadNotices(), 1000 * 60 * 5)
  }

  componentWillUnmount (): void {
    window.clearInterval(this.loadInterval)
  }

  render () {
    return this.notices.length
      ? <div className="notice-list">
        {this.notices.map(notice => <Alert key={notice.id} color="danger" {...(notice.type === 'Normal' ? { toggle: () => this.dismissNotice(notice) } : {})}>
          <b>{notice.title}</b>
          <p>{nl2br(notice.message)}</p>
        </Alert>)}
      </div>
      : null
  }
}
