import * as React from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import Util from "../../common/Util"
import { BarLoader } from "react-spinners"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import { route } from "../../routes/routes"
import AuthStore from '../../stores/AuthStore'
import BackLink from '../../components/BackLink'
import BaseView from '../BaseView'
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import ButtonLoader from '../../components/ButtonLoader'
import moment from 'moment-timezone'
import AppStateStore from '../../stores/AppStateStore'

type Props = {
  match: {
    params: {
      slug: string
    }
  },
}

type SignBoardDocumentData = {
  boardDocument: {
    title: string
    url: string
  }
  signedAt: string | null
  signatureId: number
}

@observer
export default class SignBoardDocumentView extends BaseView<Props> {
  @observable private loading = false
  @observable private error?: string
  @observable private boardDocument?: SignBoardDocumentData
  @observable private renderSignModal = false

  componentDidMount (): void {
    this.loadDocument().then()
  }

  private loadDocument = async () => {
    this.loading = true

    try {
      const response = await ApiClient.getInstance().get(route(ApiRoutes.board.boardDocuments.show, { slug: this.props.match.params.slug }))

      this.loading = false
      this.boardDocument = response.data
    } catch (err) {
      this.error = Util.extractErrorMessage(err.response)
    }
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>Sign Board Document</h1>
      </>
    )
  }

  private renderError = () => {
    return <div className="alert alert-danger">{this.error}</div>
  }

  private signDocument = () => {
    this.renderSignModal = true
  }

  private renderDocument = () => {
    return this.boardDocument
      ? <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <p>Your signature is required on a board document. Please read and review the document below, and then click the Sign Document button to proceed.</p>
          </div>
          <h2>
            {this.boardDocument.boardDocument.title}
          </h2>
          <div className="text-right mb-4">
            {
              this.boardDocument.signedAt
                ? <Alert color="success" className="text-center">
                  This document has already been signed
                </Alert>
                : <Button color="primary" onClick={this.signDocument}>Sign Document</Button>
            }
          </div>
          <div style={{ flex: 1 }}>
            <iframe src={`${this.boardDocument.boardDocument.url}?token=${AuthStore.generateTempAuthToken()}`} style={{ minHeight: 800, width: '100%', height: '100%' }}/>
          </div>
          <div className="text-right mt-4">
            {
              this.boardDocument.signedAt
                ? <Alert color="success" className="text-center">
                  This document has already been signed
                </Alert>
                : <Button color="primary" onClick={this.signDocument}>Sign Document</Button>
            }
          </div>
        </div>
        {
          this.renderSignModal
            ? <SignDocumentModal
              slug={this.props.match.params.slug}
              onClosed={() => {
                this.renderSignModal = false
                this.loadDocument().then()
              }}
            />
            : null
        }
      </>
      : null
  }

  renderContentBody (): React.ReactNode {
    return this.error
      ? this.renderError()
      : this.loading
        ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
        : this.renderDocument()
  }
}

type ModalProps = {
  slug: string
  onClosed: () => void
}

@observer
class SignDocumentModal extends React.Component<ModalProps> {
  @observable private show = true
  @observable private submitting = false
  @observable private agreed = false

  private toggle = () => {
    this.show = false
  }

  private submit = async () => {
    this.submitting = true
    AppStateStore.showModalSpinner()

    try {
      await ApiClient.getInstance().post(route(ApiRoutes.board.boardDocuments.sign, {slug: this.props.slug}))
      this.toggle()
    } catch(err) {
      AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
    }

    this.submitting = false
    AppStateStore.dismissModalSpinner()
  }

  render () {
    return <Modal isOpen={this.show} toggle={this.toggle} onClosed={this.props.onClosed}>
      <ModalHeader toggle={this.toggle}>
        Sign Document
      </ModalHeader>
      <ModalBody>
        <p>By clicking "Sign Document" below, you are confirming that you have read and agree to any terms in the displayed document.</p>
        <div className="mb-2">
          <b>Name</b><br/>
          {
            AuthStore.getUser()!.member
              ? AuthStore.getUser()!.member!.fullName
              : AuthStore.getUser()!.username
          }
        </div>
        <div className="mb-2">
          <b>Date</b><br/>
          {
            moment().format('MM/DD/YYYY')
          }
        </div>
        <hr/>
        <div>
          <label><input type="checkbox" onChange={v => this.agreed = v.target.checked}/> I understand that my digital signature is legally binding</label>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={this.submitting}
          type="button"
          color="secondary"
          onClick={this.toggle}>Cancel</Button>
        <ButtonLoader
          disabled={!this.agreed}
          loading={this.submitting}
          type={'button'}
          onClick={this.submit}
          color={'success'}><i className="fa fa-upload"/> Sign Document</ButtonLoader>
      </ModalFooter>
    </Modal>
  }
}
