import { AxiosError, AxiosResponse } from 'axios'
import ErrorBag from './ErrorBag'
import * as _ from 'lodash'
import * as path from 'path'
import moment from 'moment-timezone'
import * as Sentry from '@sentry/browser'
import * as React from "react"
import LazyResource from "../models/LazyResource"
import * as qs from "qs"
import { Moment } from 'moment-timezone/moment-timezone'

export const SHORT_DATE_FORMAT = 'M/D/YYYY'
export const ISO8601_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

export default class Util {

  static handleErrorResponse = (response: AxiosResponse, errorBag: ErrorBag | null = null, handler: (response: AxiosResponse) => boolean = () => false, modalErrorHandler: (response: AxiosResponse, message: string) => boolean = () => false) => {
    if (response && response.status === 422) {
      if (response.data && response.data.errors) {
        if (errorBag) {
          errorBag.addErrors(response.data.errors)
        } else {
          if (!modalErrorHandler(response, new ErrorBag().addErrors(response.data.errors).getErrors().join(', '))) {
            // TODO: show alert?
          }
        }
      } else {
        if (!modalErrorHandler(response, (response.data ? (response.data.error || response.data.message) : undefined) || 'A server error has occurred')) {
          // TODO: show alert?
        }
      }
    } else if (response && response.status == 429) {
      if (!handler(response) && !modalErrorHandler(response, 'You are making too many requests. Please wait a few minutes and try again.')) {
        // TODO: show alert?
      }
    } else if (response && response.status == 403) {
      if (!handler(response) && !modalErrorHandler(response, 'You do not have permission to perform this action')) {
        // TODO: show alert?
      }
    } else {
      if (!handler(response) && !modalErrorHandler(response, 'A server error has occurred')) {
        // TODO: show alert?
      }
    }
  }

  static extractErrorMessage = (response: AxiosResponse) => {
    return ((response && response.data)
      ? (response.data.error || response.data.message)
      : undefined)
      || 'A server error has occurred'
  }

  static formatNumber = (c: number, decPlaces: number = 0, thouSeparator: string | undefined = undefined, decSeparator: string | undefined = undefined, forceDecimal: boolean = false) => {
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces
    decSeparator = decSeparator === undefined ? '.' : decSeparator
    thouSeparator = thouSeparator === undefined ? ',' : thouSeparator
    let n: number = c
    let sign = n < 0 ? '-' : ''
    let s = Math.abs(+n || 0)
      .toFixed(decPlaces)
    n = Number(s)
    let i = parseInt(s) + ''
    let j = (i.length > 3) ? (i.length % 3) : 0

    let str = sign + (j ? i.substr(0, j) + thouSeparator : '') + i.substr(j)
      .replace(/(\d{3})(?=\d)/g, '$1' + thouSeparator)
    if (n % 1 !== 0 || forceDecimal) {
      return str + (decPlaces ? decSeparator + Math.abs(n - Number(i))
        .toFixed(decPlaces)
        .slice(2) : '')
    } else {
      return str
    }
  }

  static normalizeUrl = (url: string) => {
    if (url.indexOf('://') === -1) {
      url = `http://${url}`
    }

    return url
  }

  static allExcept = (obj: any, except: string[]): any => {
    const out = {} as any
    for (const p in obj) {
      if (obj.hasOwnProperty(p) && except.indexOf(p) == -1) {
        out[p] = obj[p]
      }
    }
    return out
  }

  static formatBytes = (bytes: number, decimalPlaces: number = 2) => {
    if (0 == bytes) {
      return "0 Bytes";
    }

    const c = 1024;
    const e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const f = Math.floor(Math.log(bytes) / Math.log(c));

    return parseFloat((bytes / Math.pow(c, f)).toFixed(decimalPlaces)) + " " + e[f];
  }
}

export function modelToSnakeCase (d: {}) {
  const out = {}
  Object.getOwnPropertyNames(d).forEach(k => {
    if (_.isArray(d[k])) {
      out[_.snakeCase(k)] = _.map(d[k], v => _.isObject(v) ? modelToSnakeCase(v) : v)
    } else if (_.isObject(d[k])) {
      out[_.snakeCase(k)] = modelToSnakeCase(d[k])
    } else {
      out[_.snakeCase(k)] = d[k]
    }
  })
  return out
}

export function modelToCamelCase (d: {}) {
  const out = {}
  Object.getOwnPropertyNames(d).forEach(k => {
    if (_.isArray(d[k])) {
      out[_.camelCase(k)] = _.map(d[k], v => _.isObject(v) ? modelToCamelCase(v) : v)
    } else if (_.isObject(d[k])) {
      out[_.camelCase(k)] = modelToCamelCase(d[k])
    } else {
      out[_.camelCase(k)] = d[k]
    }
  })
  return out
}

export function publicPath (relPath: string): string {
  return path.join('/', relPath)
}

export function getAnd (o: any, k: string, t: (a: any) => any, d?: any) {
  const rv = _.get(o, k, undefined)
  return rv === undefined ? d : t(rv)
}

export function transformIf (v: any, t: (a: any) => any) {
  return v ? t(v) : v
}

export function logException (ex: any) {
  try {
    Sentry.captureException(ex)
  } catch {
  }

  console && console.log(ex)
}

export function formatNumber (c: number, decPlaces: number = 0, thouSeparator: string | undefined = undefined, decSeparator: string | undefined = undefined, forceDecimal: boolean = false): string {
  decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces
  decSeparator = decSeparator === undefined ? '.' : decSeparator
  thouSeparator = thouSeparator === undefined ? ',' : thouSeparator
  let n: number = c
  let sign = n < 0 ? '-' : ''
  let s = Math.abs(+n || 0)
    .toFixed(decPlaces)
  n = Number(s)
  let i = parseInt(s) + ''
  let j = (i.length > 3) ? (i.length % 3) : 0

  let str = sign + (j ? i.substr(0, j) + thouSeparator : '') + i.substr(j)
    .replace(/(\d{3})(?=\d)/g, '$1' + thouSeparator)
  if (n % 1 !== 0 || forceDecimal) {
    return str + (decPlaces ? decSeparator + Math.abs(n - Number(i))
      .toFixed(decPlaces)
      .slice(2) : '')
  } else {
    return str
  }
}

export function formatCurrency (c: number, forceDecimal: boolean = false) {
  const formatted = formatNumber(Math.abs(c), 2, undefined, undefined, forceDecimal)

  return c < 0 ? `($${formatted})` : `$${formatted}`
}

export function textval (v: any) {
  switch (v) {
    case 'true':
      return true
    case 'false':
      return false
    case 'null':
      return null
    default:
      return v
  }
}

let defaultTimezone = 'UTC'
let userTimezone: string | undefined = undefined

export function setDefaultTimezone (tz: string) {
  defaultTimezone = tz
}

export function setUserTimezone (tz: string | undefined) {
  userTimezone = tz
}

export function getDefaultTimezone () {
  return userTimezone || defaultTimezone
}

export function parseDate (date: string) {
  return date ? moment(date) : date
}

export function parseDateLocal (date: string, tz?: string) {
  if (!tz) {
    tz = getDefaultTimezone()
  }

  return moment.tz(date, tz)
}

export function parseModelDate (data: {}, key: string) {
  return getAnd(data, key, d => parseDate(d), null)
}

export function formatDateLocal (date: string, dateFormat: string) {
  return formatDate(date, dateFormat, getDefaultTimezone())
}

export function formatDate (date: string, dateFormat?: string, timezone?: string) {
  if (!dateFormat) {
    dateFormat = SHORT_DATE_FORMAT
  }

  let d = moment(date)

  if (timezone) {
    d = d.tz(timezone)
  }

  return d.format(dateFormat)
}

export function localNow () {
  return moment().tz(getDefaultTimezone())
}

export const promiseTimeout = function (ms: number, promise: Promise<any>) {
  let timeoutId: any;
  // Create a promise that rejects in <ms> milliseconds
  let timeout = new Promise((resolve, reject) => {
    timeoutId = setTimeout(() => {
      clearTimeout(timeoutId)
      reject('Timed out in ' + ms + 'ms.')
    }, ms)
  })

  promise.then(p => {
    clearTimeout(timeoutId)
  }, () => {
    clearTimeout(timeoutId)
  })

  // Returns a race between our timeout and the passed in promise
  return Promise.race([
    promise,
    timeout
  ])
}

export function normalizeUrl (url: string) {
  return url.indexOf('://') > -1 ? url : `http://${url}`
}

export function nl2br (str: string) {
  return str.trim().split('\n').map((item, key) => {
    return React.createElement('span', { key }, [React.createElement(React.Fragment, { key: 0 }, [item]), React.createElement('br', { key: 1 })])
  })
}

export function createLazyResource<T> (apiCall: any, handler: (response: AxiosResponse) => T, errorMessage?: string) {
  return new LazyResource<T>((callback, error) => {
    apiCall()
      .then((response: AxiosResponse) => {
        callback(handler(response))
      })
      .catch((ex: AxiosError) => error(errorMessage || 'There was an error loading the data', ex.response ? ex.response.status : undefined))
  })
}

export function safeNull (resolve: () => any) {
  try {
    return resolve()
  } catch (err) {
    if (err instanceof TypeError) {
      return undefined
    } else {
      throw err
    }
  }
}

export function ordinal (num: number): string {
  num = Math.round(num);
  let numString = num.toString();

  // If the ten's place is 1, the suffix is always "th"
  // (10th, 11th, 12th, 13th, 14th, 111th, 112th, etc.)
  if (Math.floor(num / 10) % 10 === 1) {
    return numString + "th";
  }

  // Otherwise, the suffix depends on the one's place as follows
  // (1st, 2nd, 3rd, 4th, 21st, 22nd, etc.)
  switch (num % 10) {
    case 1:
      return numString + "st";
    case 2:
      return numString + "nd";
    case 3:
      return numString + "rd";
    default:
      return numString + "th";
  }
}

export function formatTime (str: string): string {
  const regex = /(\d+):?(\d+)?\ ?(am|a)?(pm|p)?/gmi

  const match = regex.exec(str.trim())

  if (match && match[0]) {
    let hour = match[1]
    const minute = match[2] || '0'
    let am = !!match[3]
    let pm = !!match[4]

    if (hour === '0') {
      am = true
      pm = false
      hour = '12'
    }

    if (hour === '12' && !am && !pm) {
      pm = true
    }

    if (Number(hour) > 12) {
      pm = true
      am = false
      hour = (Number(hour) - 12).toString()
    }

    if (!am && !pm) {
      am = true
    }

    str = `${hour}:${minute.padStart(2, '0')} ${am ? 'AM' : 'PM'}`
  }

  return str
}

export function escapeLike (str: string): string {
  return str.replace('%', '\\%').replace('_', '\\_')
}

export function trimLeft (str: string, chars: string[]): string {
  while (str.length && chars.indexOf(str.charAt(0)) > -1) {
    str = str.substr(1)
  }

  return str
}

export function trimRight (str: string, chars: string[]): string {
  while (str.length && chars.indexOf(str.charAt(str.length - 1)) > -1) {
    str = str.substr(0, str.length - 1)
  }

  return str
}

export function humanizeTimePeriod (originalSeconds: number, maxComponents?: number, showSeconds = false) {
  let seconds = originalSeconds
  const days = Math.floor(seconds / 60 / 60 / 24)
  seconds -= days * 60 * 60 * 24
  const hours = Math.floor(seconds / 60 / 60)
  seconds -= hours * 60 * 60
  const minutes = Math.floor(seconds / 60)
  seconds -= minutes * 60

  let parts = []

  if (days > 0) {
    parts.push(`${days} day${days === 1 ? '' : 's'}`)
  }

  if (hours > 0) {
    parts.push(`${hours} hour${hours === 1 ? '' : 's'}`)
  }

  if (minutes > 0) {
    parts.push(`${minutes} minute${minutes === 1 ? '' : 's'}`)
  }

  if (seconds > 0) {
    if (showSeconds) {
      parts.push(`${seconds} second${seconds === 1 ? '' : 's'}`)
    } else {
      if (originalSeconds === seconds) {
        parts.push('less than a minute')
      }
    }
  }

  if (maxComponents !== undefined) {
    parts = parts.slice(0, maxComponents)
  }

  return parts.join(', ')
}

export function formatByteSize (bytes: number) {
  const sizes = [
    { min: 0, label: 'B' },
    { min: 1024, label: 'KB' },
    { min: 1024 * 1024, label: 'MB' },
    { min: 1024 * 1024 * 1024, label: 'GB' },
  ]

  const size = _.find(sizes, s => s.min >= bytes)

  if (!size) {
    return bytes
  } else {
    return `${Math.floor(bytes / size.min * 10) / 10}${size.label}`
  }
}

export function makeUrl (url: string, query: {} = {}) {
  let t: string = url

  if (Object.getOwnPropertyNames(query).length > 0) {
    t = `${t}${t.indexOf('?') === -1 ? '?' : '&'}${qs.stringify(query)}`
  }

  return t
}

export function formatDateForCalendar (date: Moment, tz: string, localTz: string, showInLocalTimezone: boolean, showTimezone: boolean = true) {
  const displayTz = showInLocalTimezone ? localTz : tz
  return date.clone().tz(showInLocalTimezone ? localTz : displayTz).format(`h:mma${(showTimezone && displayTz !== localTz) ? ' z' : ''}`)
}

export function joinStringsAnd (strings: string[]) {
  if (strings.length === 0) {
    return ''
  } else if (strings.length == 1) {
    return strings[0]
  } else {
    const last = strings[strings.length - 1]
    const rest = strings.slice(0, strings.length - 1)

    return `${rest.join(', ')} and ${last}`
  }
}

export const copyToClipboard = (text: string) => {
  const el = document.createElement('textarea')
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}
