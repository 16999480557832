import { observer } from "mobx-react"
import * as React from "react"
import Conversation from "../../models/Conversation"
import ReceivedMessage from "../../models/ReceivedMessage"
import { observable } from "mobx"
import LazyResource from "../../models/LazyResource"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import { modelToCamelCase, nl2br } from "../../common/Util"
import _ from 'lodash';
import LazyResourcePanel from "../LazyResourcePanel"
import classNames from 'classnames'
import AuthStore from "../../stores/AuthStore"
import { EventBusContext } from "../../common/EventBus"
import MemberProfileImage from "../MemberProfileImage"
import { route } from "../../routes/routes"
import AppStateStore from "../../stores/AppStateStore"

type Props = {
  conversation: Conversation
}

@observer
export default class ConversationMessageList extends React.Component<Props> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  @observable private receivedMessages = new LazyResource<ReceivedMessage[]>((callback, error) => {
    ApiClient.query(`
      receivedMessages {
        *
        
        message {
          *
          
          sender {
            *
            
            user {
              *
              
              member {
                *
              }
            }
          }
        }
      `, {
      where: [
        { _scope: 'conversation', value: this.props.conversation.id },
        { _scope: 'recipientUser', value: AuthStore.getUser()!.id },
      ],
      order: [{ id: 'createdAt', desc: true }],
    })
      .then(response => {
        callback(_.map(response.data.receivedMessages, m => (new ReceivedMessage()).init(modelToCamelCase(m))))

        // mark all messages read
        ApiClient.getInstance().post(route(ApiRoutes.messaging.markConversationRead, { conversationId: this.props.conversation.id }))
          .then(() => AppStateStore.fetchBadgeCounts())
      })
      .catch(() => error('There was an error loading the messages'))
  })


  private onMessagesInvalidated = () => {
    this.receivedMessages.invalidate()
  }

  componentDidMount (): void {
    this.context.eventBus.on('messages-invalidated', () => this.onMessagesInvalidated())
  }

  componentWillUnmount (): void {
    this.context.eventBus.remove(this.onMessagesInvalidated)
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.conversation.id != prevProps.conversation.id) {
      this.receivedMessages.invalidate()
    }
  }

  render () {
    return <LazyResourcePanel resource={this.receivedMessages}>
      {
        receivedMessages => receivedMessages.map(message => <MessageListItem
          key={message.id}
          message={message}
        />)
      }
    </LazyResourcePanel>
  }
}

type ItemProps = {
  message: ReceivedMessage
}

@observer
class MessageListItem extends React.Component<ItemProps> {
  render () {
    const { message } = this.props
    const isSelf = message.message.sender.user.id == AuthStore.getUser()!.id

    return <div className="messages-message-wrapper">
      <div className="messages-message-content">
        <div style={{ marginRight: 10 }}>
          <MemberProfileImage profileImageUrl={message.message.getSenderProfileImageUrl()} size={45}/>
        </div>
        <div className="conversation-message-inner">
          <div className={classNames('messages-message', isSelf ? 'messages-sent' : 'messages-received')}>
            {nl2br(message.message.message)}
          </div>
          <div className="messages-message-meta">
            {message.message.getSenderName()} @ {message.message.createdAt.format('M/D/YY h:mma')}
          </div>
        </div>
        {
          !message.readAt
            ? <div>
              <div className="badge badge-success ml-2">New</div>
            </div>
            : null
        }
      </div>
    </div>
  }
}