import * as React from "react"
import LazyResourcePanel from "./LazyResourcePanel"
import { observer } from "mobx-react"
import { modelToCamelCase, transformIf } from "../common/Util"
import LazyResource from "../models/LazyResource"
import ApiClient from "../api/ApiClient"
import Membership from "../models/Membership"
import EventBus, { EventBusContext } from "../common/EventBus"

type Props = {
  memberId: number
}

@observer
export default class MembershipsList extends React.Component<Props> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  componentWillUnmount (): void {
    this.removeListeners(this.context.eventBus)
  }

  private addListeners = (eventBus: EventBus) => {
    eventBus.on('member-invalidated', this.onMemberInvalidated)
  }

  private removeListeners = (eventBus: EventBus) => {
    eventBus.remove(this.onMemberInvalidated)
  }

  private onMemberInvalidated = () => {
    this.pastMemberships.invalidate()
  }

  private pastMemberships = new LazyResource<Membership[]>((callback, error) => {
    ApiClient.members.getMemberships(this.props.memberId)
      .then(response =>
        callback(
          response.data.memberships.map((m: {}) => new Membership(modelToCamelCase(m)))
            .filter((m: Membership) => !m.isActive)
        )
      )
      .catch(() => error('There was an error loading the membership data'))
  })

  render (): React.ReactNode {
    return <LazyResourcePanel
      resource={this.pastMemberships}
      emptyMessage={null}
      hideWhenEmpty={true}
    >
      {(memberships) => {
        return <>
          <hr/>
          <h5 className="text-muted">Membership History</h5>
          <ul>
            {memberships.map(membership => <li key={membership.id}>{membership.startsAt.format('MM/DD/YYYY')} - {transformIf(membership.endsAt, d => d.format('MM/DD/YYYY')) || '???'}</li>)}
          </ul>
        </>
      }}
    </LazyResourcePanel>
  }
}