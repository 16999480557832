import * as React from 'react'
import { observer } from 'mobx-react'
import { BrowserRouter as Router, Link, Redirect, Route, Switch } from 'react-router-dom'
import { route } from "./routes/routes"
import NotFoundView from "./views/NotFoundView"
import LeftNav from "./components/LeftNav"
import AuthStore from './stores/AuthStore'
import LoginView from "./views/LoginView"
import AppStateStore from "./stores/AppStateStore"
import LoadingOverlay from "./components/LoadingOverlay"
import { action, autorun, observable } from "mobx"
import ApiClient from "./api/ApiClient"
import AuthUser from "./models/AuthUser"
import { logException, modelToCamelCase, publicPath, safeNull, setUserTimezone } from "./common/Util"
import LoadingView from "./views/LoadingView"
import AppModals from "./components/AppModals"
import Config from "./common/Config"
import { AppRoutes, Routes } from "./routes/AppRoutes"
import ContentHeader from "./components/ContentHeader"
import { ToastContainer } from "react-toastify"
import { hot } from "react-hot-loader"
import EditApplicationView from "./views/public/EditApplicationView"
import InvitationResponseView from "./views/public/InvitationResponseView"
import VerifyNotificationDestinationView from "./views/public/VerifyNotificationDestinationView"
import HeaderNav from "./components/HeaderNav"
import RequestPasswordResetView from "./views/RequestPasswordResetView"
import ResetPasswordView from "./views/ResetPasswordView"
import LegacyUrlHandlerView from "./views/public/LegacyUrlHandlerView"
import LegacyMemberOnboardingView from "./views/public/LegacyMemberOnboardingView"
import ErrorBoundary from "./components/ErrorBoundary"
import MemberRosterReport from "./components/system/reporting/MemberRosterReport"
import PayInvoiceView from "./views/public/PayInvoiceView"
import GAListener from "./common/GAListener"
import NoticeList from "./components/NoticeList"
import BadgeCountUpdater from "./components/BadgeCountUpdater"
import JoinExtendedNetworkView from './views/public/JoinExtendedNetworkView'

@observer
class App extends React.Component {
  @observable
  private loading = true

  @observable
  private showModal = false

  componentDidMount (): void {
    AuthStore.restoreAuthToken()

    autorun(() => {
      const user = AuthStore.getUser()
      if (user) {
        setUserTimezone(user.timezone || undefined)
      } else {
        setUserTimezone(undefined)
      }
    })

    if (AuthStore.hasAuthToken && !AuthStore.isAuthenticated) {
      // we have an auth token but no user, try to load the user
      ApiClient.getAuthenticatedUser()
        .then(response => {
          AuthStore.setUser(new AuthUser(modelToCamelCase(response.data.user)))
        }, () => {
        })
        .catch(ex => {
          logException(ex)
        })
        .then(() => this.loading = false)
    } else {
      this.loading = false
    }
  }

  @action
  private toggleModal = () => {
    this.showModal = !this.showModal
  }

  public render () {
    return this.loading ? <LoadingView/> : this.renderApp()
  }

  private renderApp = () => {
    const isAuthenticated = AuthStore.isAuthenticated

    return (
      <>
        <ErrorBoundary>
          <Router>
            <GAListener>
              <Switch>
                <Route
                  path={Routes.legacyUrl}
                  exact={false}
                  strict={false}
                  render={props => <LegacyUrlHandlerView/>}/>
                <Route
                  path={Routes.public.editApplication}
                  exact={true}
                  render={props => <EditApplicationView
                    match={props.match}
                  />}/>
                <Route
                  path={Routes.public.acceptEventInvitation}
                  exact={true}
                  render={props => <InvitationResponseView
                    accept={true}
                    match={props.match}
                  />}/>
                <Route
                  path={Routes.public.declineEventInvitation}
                  exact={true}
                  render={props => <InvitationResponseView
                    accept={false}
                    match={props.match}
                  />}/>
                <Route
                  path={Routes.public.joinExtendedNetwork}
                  exact={true}
                  render={props => <JoinExtendedNetworkView
                    match={props.match}
                  />}/>
                <Route
                  path={Routes.public.payInvoice}
                  exact={true}
                  render={props => <PayInvoiceView
                    match={props.match}
                  />}/>
                <Route
                  path={Routes.legacy.memberOnboarding}
                  exact={true}
                  render={props => <LegacyMemberOnboardingView
                    match={props.match}
                  />}/>
                <Route
                  path={Routes.public.verifyNotificationDestination}
                  exact={true}
                  render={props => <VerifyNotificationDestinationView
                    match={props.match}
                  />}/>
                <Route
                  path={Routes.printableChapterRoster}
                  exact={true}
                  render={props => <MemberRosterReport
                    chapterId={safeNull(() => AuthStore.getUser()!.chapter.id)}
                    match={props.match}
                  />}/>
                <Route
                  path={Routes.requestPasswordReset}
                  exact={true}
                  render={props => <RequestPasswordResetView {...props} />}/>
                <Route
                  path={Routes.resetPassword}
                  exact={true}
                  render={props => <ResetPasswordView {...props} />}/>
                <Route
                  path={Routes.login}
                  exact={true}
                  render={props => !isAuthenticated ? <LoginView {...props}/> : <Redirect to={{ pathname: route(Routes.index) }}/>}/>
                <Route
                  path={Routes.index}
                  render={props => isAuthenticated ? this.renderAuthenticated() : <Redirect to={{ pathname: route(Routes.login), state: { from: props.location } }}/>}/>
              </Switch>
            </GAListener>
          </Router>
          <AppModals/>
          <ToastContainer
            pauseOnFocusLoss={false}
          />
          <LoadingOverlay loading={AppStateStore.isModalSpinnerVisible}/>
        </ErrorBoundary>
      </>
    )
  }

  private renderAuthenticated = () => {
    return (
      <>
        <div className="header-container">
          <div className="brand">
            <Link to={route(Routes.index)}><img className="brand-image" alt="LeTip Wired" src={publicPath('img/wired-logo.png')}/></Link>
          </div>
          <HeaderNav/>
        </div>
        <div className="print-header">
          <img className="brand-image" alt="LeTip Wired" src={publicPath('img/letip-logo.png')}/>
        </div>
        <div className="body-container">
          <LeftNav/>
          <div className="content-container">
            <div className="content">
              <NoticeList/>

              <div className="content-header">
                <ContentHeader/>
              </div>

              <Switch>
                {AppRoutes.map(r => {
                  const { component, ...rest } = r
                  return (
                    <Route
                      key={r.path}
                      component={component}
                      {...rest}
                    />
                  )
                })}
                <Route component={NotFoundView}/>
              </Switch>

            </div>
            <div className="content-footer print-hide">
              Version {Config.VERSION || '???'}{Config.BUILD_NUMBER ? `-${Config.BUILD_NUMBER}` : null} {Config.ENV !== 'production' ? `(${Config.ENV})` : null} {Config.NODE_ENV !== 'production' ? `[${Config.NODE_ENV}]` : null}
            </div>
          </div>
        </div>
        <BadgeCountUpdater/>
      </>
    )
  }
}

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;
