import { observer } from "mobx-react"
import * as React from "react"
import { ChangeEvent } from "react"
import { observable, toJS } from "mobx"
import { safeNull } from "../../common/Util"
import * as _ from "lodash"
import Config from "../../common/Config"
import { QueryWhereClause } from "../../api/ApiClient"
import { TableViewFilterGroup } from "./TableViewFilterGroup"

export interface ITableViewFilterValue {
  id: string

  getWhereClause (): QueryWhereClause | QueryWhereClause[]

  getState (): any
}

export type TableViewFiltersType = {
  search: string
  filters: ITableViewFilterValue[]
}

export type TableViewFilterStateGetter = (id: string) => ITableViewFilterValue | undefined

type Props = {
  searchLabel?: string
  initialState?: {
    searchText?: string
    filters?: ITableViewFilterValue[]
  }
  onChanged: (filters: TableViewFiltersType) => void
  filters?: (filterGroup: TableViewFilterGroup, state: TableViewFilterStateGetter) => React.ReactNode
  showSearch: boolean
}

@observer
export class TableViewFilters extends React.Component<Props> {
  static defaultProps = {
    initialState: {},
    showSearch: true,
  }

  @observable private searchText: string = safeNull(() => this.props.initialState!.searchText) || ''
  @observable private filterValues: ITableViewFilterValue[] = []
  @observable private filterStates: any[] = []

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<Props>, snapshot?: any): void {
    if (safeNull(() => prevProps.initialState!.searchText) !== safeNull(() => this.props.initialState!.searchText)) {
      this.searchText = safeNull(() => this.props.initialState!.searchText) || ''
    }

    if (!_.isEqual(toJS(safeNull(() => prevProps.initialState!.filters)), toJS(safeNull(() => this.props.initialState!.filters)))) {
      this.filterStates = this.props.initialState ? this.props.initialState.filters || [] : []
    }
  }

  private onSearchTextChanged = (ev: ChangeEvent<HTMLInputElement>) => {
    this.searchText = ev.target.value
    this.onChanged()
  }

  private buildFilters = () => {
    return {
      search: this.searchText,
      filters: this.filterValues,
    }
  }

  private onChanged = _.debounce(() => {
    this.props.onChanged(this.buildFilters())
  }, Config.DEBOUNCE_TIME_MS)

  private onFiltersChanged = (filters: ITableViewFilterValue[]) => {
    this.filterValues = filters
    this.filterStates = filters.map(f => f.getState())
    this.props.onChanged(this.buildFilters())
  }

  private getState = (id: string) => {
    return _.find(this.filterStates || [], v => v.id === id)
  }

  render (): React.ReactNode {
    return <div className="table-view-filters">
      {
        this.props.showSearch
          ? <div className="table-view-search">
            <form onSubmit={ev => ev.preventDefault()}>
              {this.props.searchLabel && <label className="table-view-search-label">{this.props.searchLabel}</label>}
              <div className="table-view-search-input">
                <i className="fa fa-search"/>
                <input className="form-control" type="text" value={this.searchText} onChange={this.onSearchTextChanged.bind(this)} onKeyDown={ev => ev.key === 'Enter' && this.onChanged.flush()}/>
              </div>
            </form>
          </div>
          : null
      }
      {
        this.props.filters
          ? <TableViewFilterGroup
            onChanged={this.onFiltersChanged}
          >
            {filterGroup => this.props.filters!(filterGroup, this.getState)}
          </TableViewFilterGroup>
          : null
      }
    </div>
  }
}