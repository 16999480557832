import CsvTransformer from "./CsvTransformer"
import BadgeShippingRequest from "../BadgeShippingRequest"
import { safeNull } from "../../common/Util"
import moment from "moment-timezone"

export default class ShippingHistoryCsvTransformer extends CsvTransformer<BadgeShippingRequest> {
  get filename () {
    return `badge-shipping-history-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'id',
      valueTransformer: row => String(row.id),
    })

    this.addColumn({
      header: 'Created',
      valueTransformer: row => row.createdAt.format('MM/DD/YYYY'),
    })

    this.addColumn({
      header: 'Shipped',
      valueTransformer: row => safeNull(() => row.completedAt!.format('MM/DD/YYYY')),
    })

    this.addColumn({
      header: 'Prefix',
      valueTransformer: row => row.member.prefix || '',
    })

    this.addColumn({
      header: 'Member',
      valueTransformer: row => row.member.getPreferredFullName(),
    })

    this.addColumn({
      header: 'Chapter',
      valueTransformer: row => safeNull(() => row.member.chapter!.name),
    })

    this.addColumn({
      header: 'Join Date',
      valueTransformer: row => safeNull(() => row.member.joinDate!.format('MM/DD/YYYY')),
    })

    this.addColumn({
      header: 'Category',
      valueTransformer: row => safeNull(() => row.member.category!.name),
    })

    this.addColumn({
      header: 'Chapter',
      valueTransformer: row => safeNull(() => row.member.chapter!.name),
    })

    this.addColumn({
      header: 'Badge Color',
      valueTransformer: row => `${row.badgeColor} (${row.previousBadgeColor})`,
    })

    this.addColumn({
      header: 'Address To',
      valueTransformer: row => safeNull(() => row.badgeShippingAddress.name),
    })

    this.addColumn({
      header: 'Business Name',
      valueTransformer: row => safeNull(() => row.badgeShippingAddress.businessName),
    })

    this.addColumn({
      header: 'Address',
      valueTransformer: row => safeNull(() => row.badgeShippingAddress.address),
    })

    this.addColumn({
      header: 'Address 2',
      valueTransformer: row => safeNull(() => row.badgeShippingAddress.address2),
    })

    this.addColumn({
      header: 'City',
      valueTransformer: row => safeNull(() => row.badgeShippingAddress.city),
    })

    this.addColumn({
      header: 'State',
      valueTransformer: row => safeNull(() => row.badgeShippingAddress.state),
    })

    this.addColumn({
      header: 'Zip Code',
      valueTransformer: row => safeNull(() => row.badgeShippingAddress.zipCode),
    })

    this.addColumn({
      header: 'Country',
      valueTransformer: row => safeNull(() => row.badgeShippingAddress.country),
    })

    this.addColumn({
      header: 'Shipping Notes',
      valueTransformer: row => safeNull(() => row.shippingNotes),
    })
  }
}
