import Member from "../models/Member"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { observer } from "mobx-react"
import { Button } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import Util, { logException, modelToSnakeCase } from "../common/Util"
import { toast } from "react-toastify"
import Business from "../models/Business"
import { can, Permission } from "./RequirePermission"
import HelpTooltip from './HelpTooltip'

type Props = {
  member: Member
  onCancel: () => void
  onSaved: () => void
}

type EditFormState = Partial<Business>

@observer
export default class MemberBusinessInfoForm extends React.Component<Props, never> {
  @observable
  private formState = new FormState<EditFormState>({
    name: this.props.member.business!.name,
    websiteUrl: this.props.member.business!.websiteUrl,
    socialLinks: this.props.member.business!.socialLinks,
    phoneNumber: this.props.member.business!.phoneNumber,
    emailAddress: this.props.member.business!.emailAddress,
    isEmailPrivate: this.props.member.business!.isEmailPrivate
  })

  @observable
  private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable
  private submitting = false

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.members.updateBusinessInfo(this.props.member.id, modelToSnakeCase(this.formHelper.toObject()))
      .then(() => {
        toast.success('Business Info Updated')
        this.onSaved()
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private onCancelClicked = () => {
    this.props.onCancel()
  }

  private onSaved = () => {
    this.props.onSaved()
  }

  render (): React.ReactNode {
    return <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      <div className="form-row">
        <div className="col-sm-5">
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              {this.formHelper.renderTextInput({
                name: 'name',
                type: 'text',
                label: 'Name',
                disabled: !can(Permission.ChangeBusinessName, { memberId: this.props.member.id }),
              })}
            </div>
            {
              !can(Permission.ChangeBusinessName, { memberId: this.props.member.id })
                ? <div style={{ alignSelf: 'flex-end', marginBottom: 20 }}>
                  <HelpTooltip
                    content="To update business name please call corporate office 800-255-3847. To update the name on a Company Membership, please provide a letter signed by the business owner."
                  />
                </div>
                : null
            }
          </div>
        </div>
        <div className="col-sm-5">
          {this.formHelper.renderTextInput({
            name: 'websiteUrl',
            type: 'text',
            label: 'Website',
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'phoneNumber',
            type: 'text',
            label: 'Contact Phone',
          })}
        </div>
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'emailAddress',
            type: 'text',
            label: 'Contact Email',
          })}
        </div>
      </div>

      <h5 className="text-muted">Social Media Profiles</h5>
      <div className="form-row">
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.facebook',
            type: 'text',
            placeholder: 'Facebook',
            prepend: <i className="fa fa-facebook-square"/>,
          })}
        </div>
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.twitter',
            type: 'text',
            placeholder: 'Twitter',
            prepend: <i className="fa fa-twitter-square"/>,
          })}
        </div>
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.linkedin',
            type: 'text',
            placeholder: 'Linkedin',
            prepend: <i className="fa fa-linkedin-square"/>,
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.instagram',
            type: 'text',
            placeholder: 'Instagram',
            prepend: <i className="fa fa-instagram"/>,
          })}
        </div>
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.youtube',
            type: 'text',
            placeholder: 'YouTube',
            prepend: <i className="fa fa-youtube"/>,
          })}
        </div>
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.alignable',
            type: 'text',
            placeholder: 'Alignable',
            prepend: <i className="fa fa-google-plus"/>,
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.pinterest',
            type: 'text',
            placeholder: 'Pinterest',
            prepend: <i className="fa fa-pinterest"/>,
          })}
        </div>
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.snapchat',
            type: 'text',
            placeholder: 'Snapchat',
            prepend: <i className="fa fa-snapchat"/>,
          })}
        </div>
        <div className="col-sm-4">
          {this.formHelper.renderTextInput({
            name: 'socialLinks.vimeo',
            type: 'text',
            placeholder: 'Vimeo',
            prepend: <i className="fa fa-vimeo"/>,
          })}
        </div>
      </div>

      <div className="form-row">
            <div className="col-12">
              {this.formHelper.renderCheckboxInput({
                name: 'isEmailPrivate',
                label: 'Do not show email on website',
              })}
            </div>
      </div>

      <div className="form-buttons">
        <Button type="button" color="" onClick={() => this.onCancelClicked()}>Cancel</Button>
        <ButtonLoader type="submit" color="success" loading={this.submitting}>Save Changes</ButtonLoader>
      </div>
    </form>
  }
}
