import BaseView from "../BaseView"
import * as React from "react"
import { Link } from "react-router-dom"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { ApiTableViewAdapter } from "../../components/table-view/ApiTableViewAdapter"
import { QueryWhereClause } from "../../api/ApiClient"
import Category from "../../models/Category"
import { observer } from "mobx-react"
import { ManagedChooseableColumn } from "../../components/table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "../../components/table-view/ManagedTableView";
import LetterFilter, { LetterFilterTargetType } from '../../components/table-view/filters/LetterFilter'
import EventBus from '../../common/EventBus'

@observer
export default class NetworkChapterSearchView extends BaseView {
  private readonly letterFilter: LetterFilter

  constructor (props: any) {
    super(props)

    this.letterFilter = new LetterFilter('nameStartsWith', LetterFilterTargetType.Field, 'name', '')
    this.letterFilter.isExternal = true
  }

  renderContentHeader () {
    return <>
      <h1>LeTip International Category Search</h1>
    </>
  }

  private availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'name',
      column: {
        title: 'Category',
        accessor: 'name',
        sortable: true,
        renderItem: (value, item: Category) => <>
          {item.name}<br/>
          <span className="text-muted">{item.description}</span>
        </>
      },
      fixed: true,
    },
    {
      id: 'name',
      column: {
        title: 'Members',
        accessor: 'numberOfActiveMembers',
        sortable: true,
        headerClassName: "justify-content-center",
        itemClassName: "text-center",
        renderItem: (value, item) => <Link type="link" to={{ pathname: route(Routes.network.members), state: { category: { id: item.id, name: item.name } } }}>
          {value}
        </Link>,
      },
      default: true,
    },
    {
      id: 'openCategoryReport',
      column: {
        renderItem: (value, item) => <Link
          to={route(Routes.network.openCategoryReport, { categoryId: item.id })}
          className="btn btn-primary"
          style={{ whiteSpace: 'nowrap' }}
        >Open Category Report</Link>
      },
      fixed: true,
    }
  ]

  private categoriesAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      where.push({ _scope: 'active' })

      return {
        query: `
      categories {
        *
      }
    `,
        where: where,
      }
    },
    'categories',
    Category
  )

  renderContentBody (): React.ReactNode {
    return this.renderTable()
  }

  private renderLetterFilter = (eventBus: EventBus) => {
    return this.letterFilter.render(eventBus)
  }

  private renderTable = () => {
    return <>
      <ManagedTableView
        stateKey="NetworkCategorySearchView"
        hideItemCount={true}
        adapter={this.categoriesAdapter}
        defaultSort={{ sortKey: 'name' }}
        availableColumns={this.availableColumns}
        searchLabel="Find A Category"
        filters={[
          this.letterFilter,
        ]}
        customFilters={this.renderLetterFilter}
      />
    </>
  }
}