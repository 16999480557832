import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Member from "./Member"
import Invoice from "./Invoice"
import PaymentMethod from "./PaymentMethod"
import Chapter from './Chapter'

export enum TransactionType {
  Payment = 'Payment',
  Refund = 'Refund',
  Void = 'Void',
}

export enum TransactionStatus {
  Completed = 'Completed',
  Refunded = 'Refunded',
  PartiallyRefunded = 'Partially Refunded',
  Error = 'Error',
  Declined = 'Declined',
}

export enum PaymentType {
  Web = 'Web',
  PersonalCheck = 'Personal',
  CompanyCheck = 'Company ',
  CashierCheck = 'Cashier',
  Legacy = 'Legacy',
}

export default class Transaction extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  transactionType: TransactionType
  paymentType: PaymentType
  status: TransactionStatus
  amount: number
  description: string
  memo: string
  provider: string | null
  externalTransactionId: number | null

  memberId: number | null
  paymentMethodId: number | null
  invoiceId: number | null
  parentTransactionId: number | null

  isRefundable: boolean

  member: Member | null
  chapter: Chapter | null
  paymentMethod: PaymentMethod | null
  invoice: Invoice | null
  parentTransaction: Transaction | null

  getCasts () {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      amount: 'number',
      memberId: 'number',
      paymentMethodId: 'number',
      invoiceId: 'number',
      parentTransactionId: 'number',
      isRefundable: 'boolean',
    }
  }

  setMember (m?: {}) {
    return m ? new Member(m) : null
  }

  setChapter (c?: {}) {
    return c ? new Chapter(c) : null
  }

  setPaymentMethod (m?: {}) {
    return m ? new PaymentMethod().init(m) : null
  }

  setInvoice (i?: {}) {
    return i ? new Invoice().init(i) : null
  }

  setParentTransaction (p?: {}) {
    return p ? new Transaction().init(p) : null
  }
}
