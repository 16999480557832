import * as React from "react"
import { ChangeEvent } from "react"
import { computed, observable, toJS } from "mobx"
import Util, { modelToCamelCase, safeNull } from "../common/Util"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import * as _ from "lodash"
import { observer } from "mobx-react"
import { route } from "../routes/routes"
import AppStateStore from "../stores/AppStateStore"
import { AddChannelDestinationModal } from "./AddChannelDestinationModal"
import { Alert, Button, Input, InputGroup, InputGroupAddon } from "reactstrap"
import FlipMove from 'react-flip-move'
import { toast } from "react-toastify"
import AuthStore from "../stores/AuthStore"
import LoadingPanel from './LoadingPanel'

type NotificationType = {
  id: string
  label?: string
  description: string
  hideSms?: boolean
  forceEmail?: boolean
  visible?: () => boolean
}

type NotificationSettingData = {
  id: number
  notificationType: string
  isSmsEnabled: boolean
  isEmailEnabled: boolean
  destinations: {
    id: number
  }[]
}

type NotificationDestinationData = {
  id: number
  channel: string
  destination: string
  isActive: boolean
  isVerified: boolean
}

type NotificationSettingsData = {
  phoneNumber: string
  emailAddress: string
  settings: NotificationSettingData[]
  destinations: NotificationDestinationData[]
}

@observer
export default class MemberNotificationSettings extends React.Component<{
  memberId: number
}> {
  @observable private loading = false
  @observable private error?: string
  @observable private data: NotificationSettingsData | undefined = undefined

  @computed
  private get emailDestinations () {
    return this.data
      ? this.data.destinations.filter(d => d.channel === 'email')
      : []
  }

  @computed
  private get smsDestinations () {
    return this.data
      ? this.data.destinations.filter(d => d.channel === 'sms')
      : []
  }

  private loadNotificationSettingsData = async () => {
    this.loading = true
    this.error = undefined

    try {
      const response = await ApiClient.getInstance().get(`/members/${this.props.memberId}/notification-settings`)
      this.data = modelToCamelCase(response.data) as any
    } catch (err) {
      this.error = Util.extractErrorMessage(err)
    }

    this.loading = false
  }

  private notificationTypes: NotificationType[] = [
    {
      id: 'Tip Received',
      label: 'Tip Notifications',
      description: 'Notify me when I receive tips.',
    },
    {
      id: 'Chapter Tips Summary',
      label: 'Chapter Tips Daily Summary',
      description: 'Send me a daily summary of all tips received by my chapter',
      hideSms: true,
      visible: () => AuthStore.getUser()!.isBoardPosition('tip_master'),
    },
    {
      id: 'Corporate Information',
      label: 'Corporate Notifications',
      description: 'Notify me when important information is sent by corporate HQ.',
    },
    {
      id: 'Chapter Information',
      label: 'Chapter Notifications',
      description: 'Notify me when important information is sent by my chapter.',
    },
    {
      id: 'Billing',
      label: 'Billing Notifications',
      description: 'Notify me when it is time to renew and send me payment reminders.',
      forceEmail: true,
    },
    {
      id: 'System',
      label: 'System Notifications',
      description: 'Notify me when I achieve goals and pass milestones.',
    },
    {
      id: 'Message Received',
      label: 'Message Notifications',
      description: 'Notify me when I receive a message inside LeTip Wired.',
    },
    {
      id: 'Calendar Reminder',
      label: 'Calendar Reminders',
      description: 'Send me reminders for events on my chapter\'s calendar.',
    },
  ]

  @observable private isAddChannelDestinationModalVisible = false
  @observable private addChannelDestinationChannel = ''

  componentDidMount (): void {
    this.loadNotificationSettingsData().then()
  }

  private showAddChannelDestinationModal = (channel: string) => {
    this.addChannelDestinationChannel = channel
    this.isAddChannelDestinationModalVisible = true
  }

  private removeNotificationDestination = (destination: NotificationDestinationData) => {
    const desc = destination.channel === 'sms' ? 'phone number' : 'email address'
    AppStateStore.showConfirmationModal(`Remove ${_.startCase(desc)}`, `Are you sure you want to remove this ${desc}?`, (result, modal) => {
      modal.hide()

      if (result) {
        AppStateStore.showModalSpinner()

        ApiClient.getInstance().delete(route(ApiRoutes.members.removeNotificationChannelDestination, { id: this.props.memberId, destinationId: destination.id }))
          .then(() => {
            this.loadNotificationSettingsData().then()
          })
          .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
          .then(() => AppStateStore.dismissModalSpinner())
      }
    })
  }

  private resendVerificationMessage = (destination: NotificationDestinationData) => {
    const desc = destination.channel === 'sms' ? 'phone number' : 'email address'
    const t = destination.channel === 'sms' ? 'SMS' : 'email'
    AppStateStore.showConfirmationModal('Pending Verification', <p>You cannot receive notifications at this {desc} until it has been verified. You should have received an {t} with a link to finish verification.<br/><br/>Would you like to be sent another verification {t}?</p>, (result, modal) => {
      modal.hide()

      if (result) {
        AppStateStore.showModalSpinner()

        ApiClient.getInstance().post(route(ApiRoutes.members.resendNotificationChannelDestinationVerification, { id: this.props.memberId, destinationId: destination.id }))
          .then(() => {
            toast.success('Verification message sent')
          })
          .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
          .then(() => {
            this.loadNotificationSettingsData().then()
            AppStateStore.dismissModalSpinner()
          })
      }
    })
  }

  render (): React.ReactNode {
    const notificationSettings: NotificationSettingData[] = this.data ? this.data.settings : []
    const notificationDestinations: NotificationDestinationData[] = this.data ? this.data.destinations : []

    return <>
      <h6 className="text-muted mb-4">Notification Settings</h6>
      {
        this.error
          ? <Alert color="danger">{this.error}</Alert>
          : null
      }

      <LoadingPanel loading={this.loading} loaded={!!this.data}>
        {
          this.data
            ? <>
              <div className="secondary-panel">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <p><b>Email Notifications</b></p>
                    <div>
                      {
                        this.emailDestinations.length
                          ? <>
                            <p className="text-muted">You can receive email notifications at the following email addresses:</p>
                            <FlipMove>
                              {this.emailDestinations.map(email => <div key={email.id}>
                                {email.destination} {
                                email.isVerified
                                  ? null
                                  : <a href="" onClick={(ev) => {
                                    ev.preventDefault()
                                    this.resendVerificationMessage(email)
                                  }}>(Pending Verification)</a>
                              } <a className="text-danger" href="" onClick={(ev) => {
                                ev.preventDefault();
                                this.removeNotificationDestination(email)
                              }}><i className="fa fa-trash"/></a>
                              </div>)}
                            </FlipMove>
                          </>
                          : <p className="text-muted">You can register additional email addresses to receive email notifications by clicking the "Add Email Address" button below</p>
                      }
                    </div>
                    <div className="mt-4">
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => this.showAddChannelDestinationModal('email')}
                      ><i className="fa fa-plus-circle"/> Add Email Address</Button>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <p><b>SMS Notifications</b></p>
                    <div>
                      {
                        this.smsDestinations.length
                          ? <>
                            <p className="text-muted">You can receive SMS notifications at the following phone numbers:</p>
                            <FlipMove>
                              {this.smsDestinations.map(phoneNumber => <div key={phoneNumber.id}>
                                {phoneNumber.destination} {
                                phoneNumber.isVerified
                                  ? null
                                  : <a href="" onClick={(ev) => {
                                    ev.preventDefault()
                                    this.resendVerificationMessage(phoneNumber)
                                  }}>(Pending Verification)</a>
                              } <a className="text-danger" href="" onClick={(ev) => {
                                ev.preventDefault();
                                this.removeNotificationDestination(phoneNumber)
                              }}><i className="fa fa-trash"/></a>
                              </div>)}
                            </FlipMove>
                          </>
                          : <p className="text-muted">You can register additional phone numbers to receive SMS notifications by clicking the "Add Phone Number" button below</p>
                      }
                    </div>
                    <div className="mt-4">
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => this.showAddChannelDestinationModal('sms')}
                      ><i className="fa fa-plus-circle"/> Add Phone Number</Button>
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              {
                notificationSettings
                  ? <>
                    {
                      this.notificationTypes.filter(notificationType => !notificationType.visible || notificationType.visible()).map(notificationType => {
                        const setting: NotificationSettingData | undefined = _.find(notificationSettings, s => s.notificationType === notificationType.id)

                        return <div key={notificationType.id} className="notification-setting-list-item">
                          <NotificationSettingInput
                            memberId={this.props.memberId}
                            notificationType={notificationType}
                            notificationSetting={setting}
                            emailAddress={safeNull(() => this.data!.emailAddress) || ''}
                            phoneNumber={safeNull(() => this.data!.phoneNumber) || ''}
                            destinations={notificationDestinations}
                            onNotificationSettingUpdated={() => this.loadNotificationSettingsData().then()}
                          />
                        </div>
                      })
                    }
                  </>
                  : null
              }
              <ManageNewsletterSubscription
                memberId={this.props.memberId}
                emailId={''}
              />
            </>
            : null
        }
      </LoadingPanel>
      <br/>


      <AddChannelDestinationModal
        isOpen={this.isAddChannelDestinationModalVisible}
        toggle={() => this.isAddChannelDestinationModalVisible = false}
        memberId={this.props.memberId}
        channel={this.addChannelDestinationChannel}
        onSaved={() => {
          this.isAddChannelDestinationModalVisible = false
          this.loadNotificationSettingsData().then()
        }}
        onCanceled={() => {
          this.isAddChannelDestinationModalVisible = false
        }}
      />
    </>
  }
}

type NotificationSettingInputProps = {
  memberId: number
  notificationType: NotificationType
  notificationSetting?: NotificationSettingData
  emailAddress: string
  phoneNumber: string
  destinations: NotificationDestinationData[]
  onNotificationSettingUpdated: () => void
}

@observer
class NotificationSettingInput extends React.Component<NotificationSettingInputProps> {
  @observable private isSmsEnabled = this.props.notificationSetting ? this.props.notificationSetting.isSmsEnabled : false
  @observable private isEmailEnabled = this.props.notificationType.forceEmail ? true : (this.props.notificationSetting ? this.props.notificationSetting.isEmailEnabled : false)

  componentDidUpdate (prevProps: Readonly<NotificationSettingInputProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const o = {
      sms: prevProps.notificationSetting ? prevProps.notificationSetting.isSmsEnabled : false,
      email: prevProps.notificationSetting ? prevProps.notificationSetting.isEmailEnabled : false,
    }

    const n = {
      sms: this.props.notificationSetting ? this.props.notificationSetting.isSmsEnabled : false,
      email: this.props.notificationSetting ? this.props.notificationSetting.isEmailEnabled : false,
    }

    if (!_.isEqual(toJS(o), toJS(n))) {
      this.isSmsEnabled = this.props.notificationSetting ? this.props.notificationSetting.isSmsEnabled : false
      this.isEmailEnabled = this.props.notificationSetting ? this.props.notificationSetting.isEmailEnabled : false
    }
  }

  private updateSetting = (ev: ChangeEvent<HTMLInputElement>) => {
    const channel = ev.target.name
    const enable = ev.target.checked

    if (channel === 'email' && this.props.notificationType.forceEmail && !enable) {
      AppStateStore.showAlertModal('Email Notifications', `You cannot disable email notifications for ${this.props.notificationType.label}`)
      return
    }

    let destination: NotificationDestinationData | undefined = undefined

    if (_.startsWith(channel, 'destination-')) {
      destination = this.props.destinations.find(d => d.id === Number(channel.substring(12)))
    }

    let previousValue = false
    if (channel === 'sms') {
      previousValue = this.isSmsEnabled
      this.isSmsEnabled = enable
    } else if (channel === 'email') {
      previousValue = this.isEmailEnabled
      this.isEmailEnabled = enable
    } else if (destination) {
      previousValue = Boolean(this.props.notificationSetting && this.props.notificationSetting.destinations.find(d => d.id === destination!.id))
    }

    ApiClient.getInstance()
      .post(route(ApiRoutes.members.updateNotificationSetting, { id: this.props.memberId }), {
        notification_type: this.props.notificationType.id,
        channel: channel,
        enable: enable,
      })
      .then(() => {
        this.props.onNotificationSettingUpdated()
      })
      .catch(error => {
        AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response))

        if (channel === 'sms') {
          this.isSmsEnabled = previousValue
        } else if (channel === 'email') {
          this.isEmailEnabled = previousValue
        } else {
          this.props.onNotificationSettingUpdated()
        }
      })
  }

  render (): React.ReactNode {
    const notificationType = this.props.notificationType

    const destinations = _.orderBy(this.props.destinations, d => `${d.isVerified ? '0' : '1'}-${d.channel === 'sms' ? '0' : '1'}-${d.destination}`)

    return <div className="d-flex">
      <div className="flex-fill">
        <b>{notificationType.label}</b><br/>
        <p>{notificationType.description}</p>
      </div>
      <div>
        <div>
          {
            notificationType.hideSms
              ? null
              : <div className="form-check form-checkbox">
                <label>
                  <input type="checkbox"
                         name="sms"
                         className="form-check-input"
                         checked={this.isSmsEnabled}
                         onChange={this.updateSetting}
                  />
                  <span className="label-text">{this.props.phoneNumber || 'SMS'}</span>
                </label>
              </div>
          }

          <div className="form-check form-checkbox">
            <label>
              <input type="checkbox"
                     name="email"
                     className="form-check-input"
                     checked={this.isEmailEnabled}
                     onChange={this.updateSetting}
              />
              <span className="label-text">{this.props.emailAddress || 'Email'}</span>
            </label>
          </div>

          {
            destinations.map(destination => <div key={`destination-${destination.id}`}>
              <div className="form-check form-checkbox">
                <label>
                  <input type="checkbox"
                         name={`destination-${destination.id}`}
                         className="form-check-input"
                         checked={Boolean(this.props.notificationSetting && this.props.notificationSetting.destinations.find(d => d.id === destination.id))}
                         onChange={this.updateSetting}
                  />
                  <span className="label-text">{destination.destination}</span>
                </label>
              </div>
            </div>)
          }
        </div>
      </div>
    </div>
  }
}

type ManageNewsletterSubscriptionProps = {
  memberId: number
  emailId:any
}

@observer
export class ManageNewsletterSubscription extends React.Component<ManageNewsletterSubscriptionProps> {
  @observable loading = false
  @observable loaded = false
  @observable error: string | undefined = undefined
  @observable newsletterEmailAddress: string | undefined = undefined

  @observable inputText = ''

  private loadNewsletterSubscriptionStatus = async () => {
    this.loading = true

    try {
      const response = await ApiClient.getInstance().get(route(ApiRoutes.members.newsletter.getSubscriptionStatus, { id: this.props.memberId }))
      if (response.data.subscription_status === 'subscribed') {
        this.newsletterEmailAddress = response.data.primary_email_address
      } else {
        this.inputText = response.data.primary_email_address
        this.newsletterEmailAddress = undefined
      }
    } catch (err) {
    }

    this.loading = false
    this.loaded = true
  }

  private subscribeToNewsletter = async () => {
    this.loading = true
    this.error = undefined

    try {
      await ApiClient.getInstance().post(route(ApiRoutes.members.newsletter.subscribeToNewsletter, { id: this.props.memberId }), { email: this.inputText })
      this.inputText = ''
      this.loadNewsletterSubscriptionStatus().then()
    } catch (err) {
      this.error = Util.extractErrorMessage(err)
    }

    this.loading = false
  }

  callSubscribeToNewsletter = () => {
    this.inputText = this.props.emailId
    this.subscribeToNewsletter()
  }

  private unsubscribeFromNewsletter = async () => {
    this.loading = true
    this.error = undefined

    try {
      await ApiClient.getInstance().delete(route(ApiRoutes.members.newsletter.unsubscribeFromNewsletter, { id: this.props.memberId }))
      this.loadNewsletterSubscriptionStatus().then()
    } catch (err) {
      this.error = Util.extractErrorMessage(err)
    }

    this.loading = false
  }

  componentDidMount () {
    this.loadNewsletterSubscriptionStatus().then()
  }

  render () {
    return <div className="row">
      <div className="col-sm-12">
        <b>Mailchimp Subscription</b><br/>
        <p>Corporate Email communication including Newsletters, Contest and webinar announcements.</p>
        {
          this.error
            ? <Alert color={'danger'}>{this.error}</Alert>
            : null
        }
        <LoadingPanel loading={this.loading} loaded={this.loaded}>
          {
            this.newsletterEmailAddress
              ? <div>
                You are currently subscribed to MailChimp with the following email address: <b>{this.newsletterEmailAddress}</b>
                <br/>
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => this.unsubscribeFromNewsletter()}
                >Unsubscribe</Button>
              </div>
              : <div className="text-muted">
                <div style={{ maxWidth: 500 }}>
                  <InputGroup>
                    <Input onChange={v => this.inputText = v.target.value} value={this.inputText} placeholder="Enter email address"/>
                    <InputGroupAddon addonType="append">
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => this.subscribeToNewsletter()}
                      >Subscribe</Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
          }
        </LoadingPanel>
      </div>
    </div>
  }
}
