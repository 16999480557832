import { observer } from "mobx-react"
import React, { ChangeEvent } from "react"
import { TextFilterState } from "./TextFilter"
import BaseFilterComponent from './BaseFilterComponent'

export type Props = {
  label: string
  state: TextFilterState
  onChanged: (value: string) => void
}

@observer
export default class TextFilterComponent extends BaseFilterComponent<Props> {
  private onSearchTextChanged = (ev: ChangeEvent<HTMLInputElement>) => {
    this.props.onChanged(ev.target.value)
    this.props.eventBus.dispatch('changed')
  }

  private onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      this.props.eventBus.dispatch('shouldSubmit')
    }
  }

  render () {
    return <div className="form-group">
      {this.props.label && <label>{this.props.label}</label>}
      <input
        type="text"
        className="form-control"
        value={this.props.state.value}
        onChange={this.onSearchTextChanged}
        onKeyDown={this.onKeyDown}
      />
    </div>
  }
}