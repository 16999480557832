import { observer } from "mobx-react"
import * as React from "react"
import Conversation from "../../models/Conversation"
import { computed } from "mobx"
import AuthStore from "../../stores/AuthStore"

type Props = {
  conversation: Conversation
}

@observer
export default class ConversationParticipantsList extends React.Component<Props> {
  @computed get otherParticipants () {
    return this.props.conversation.participantsCache.filter(p => p.user.id != AuthStore.getUser()!.id)
  }

  render () {
    return <div className="messages-conversation-header">
      {this.props.conversation.groupName || this.otherParticipants.map(p => p.member ? p.member.preferredFullName : p.user.name).join(', ')}
    </div>
  }
}
