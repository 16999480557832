import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { computed, observable } from "mobx"
import FormState from "../../../common/FormState"
import ButtonLoader from "../../ButtonLoader"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"
import { loadMemberOptions } from "../../../api/AsyncHelpers"
import PaymentMethod from "../../../models/PaymentMethod"
import AddPaymentMethodModal from "../../AddPaymentMethodModal"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import AppStateStore from "../../../stores/AppStateStore"
import { route } from "../../../routes/routes"
import Util, { modelToSnakeCase } from "../../../common/Util"
import { toast } from "react-toastify"

type CreateTransactionModalProps = {
  isOpen: boolean
  toggle: () => void
  onClosed: () => void
  onUpdated: () => void
}

@observer
export default class CreateTransactionModal extends React.Component<CreateTransactionModalProps> {
  @observable private submitting = false
  @observable private error?: string
  @observable private showAddPaymentMethodModal = false
  @observable private renderAddPaymentMethodModal = false

  @observable private formState = new FormState({
    memberId: undefined,
    paymentMethod: undefined,
    checkInformation: '',
    amount: '',
    description: '',
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable private paymentMethods: PaymentMethod[] = []

  @computed
  private get memberId () {
    return this.formState.get('memberId')
  }

  @computed
  private get paymentMethodOptions () {
    const options: { value: any, text: string }[] = this.paymentMethods.map(pm => ({ value: String(pm.id), text: pm.optionDescription }))

    return options.concat([
      {
        value: 'personal_check',
        text: 'Personal Check',
      },
      {
        value: 'company_check',
        text: 'Company Check',
      },
      {
        value: 'money_order',
        text: 'Cashier\'s Check or Money Order',
      },
    ])
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.getInstance()
      .post(route(ApiRoutes.transactions.store), modelToSnakeCase(this.formHelper.toObject()))
      .then(() => {
        toast.success('Manual payment processed')
        this.props.toggle()
        this.props.onUpdated()
      })
      .catch(error => Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      }))
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private toggle = () => {
    if (!this.submitting) {
      this.props.toggle()
    }
  }

  private loadPaymentMethods = () => {
    this.paymentMethods = []

    if (this.formState.get('memberId')) {
      ApiClient.query(`
    paymentMethods {
      *
    }
    `, {
        where: [
          { memberId: this.formState.get('memberId') }
        ],
        order: [
          { id: 'is_default', desc: true },
        ],
      }).then(response => this.paymentMethods = response.data.paymentMethods.map((p: any) => new PaymentMethod().init(p)))
    }
  }

  render () {
    return <>
      <Modal isOpen={this.props.isOpen} toggle={this.toggle} onClosed={this.props.onClosed} size="lg">
        <ModalHeader toggle={this.toggle}>
          Manual Transaction
        </ModalHeader>
        <ModalBody>
          <form action="" method="post" onSubmit={this.submit}>
            <div className="form-row">
              <div className="col-12">
                {
                  this.formHelper.renderAsyncSelectInput({
                    label: 'Member',
                    name: 'memberId',
                    loadOptions: loadMemberOptions(),
                    onChange: () => {
                      this.loadPaymentMethods()
                      this.formState.set('paymentMethod', undefined)
                    },
                  })
                }
              </div>
            </div>
            {
              this.formState.get('memberId')
                ? <div className="form-row">
                  <div className="col-12">
                    <label>Payment Method</label>
                    {this.formHelper.renderSelectInput({
                      name: 'paymentMethod',
                      options: this.paymentMethodOptions,
                      append: <Button
                        type="button"
                        color="primary"
                        onClick={() => {
                          this.showAddPaymentMethodModal = true
                          this.renderAddPaymentMethodModal = true
                        }}>Add Payment Method</Button>,
                      appendContent: true,
                    })}
                  </div>
                </div>
                : null
            }
            {['personal_check', 'company_check', 'money_order'].indexOf(this.formState.get('paymentMethod')) > -1 &&
            <div className="form-row">
              <div className="col-md-12">
                {this.formHelper.renderTextInput({
                  type: 'text',
                  name: 'checkInformation',
                  label: 'Check Information',
                })}
              </div>
            </div>}
            {
              (this.formState.get('memberId') && this.formState.get('paymentMethod'))
                ? <>
                  <div className="form-row">
                    <div className="col-12">
                      {
                        this.formHelper.renderTextInput({
                          label: 'Amount',
                          name: 'amount',
                          prepend: '$',
                        })
                      }
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-12">
                      {
                        this.formHelper.renderTextInput({
                          label: 'Description',
                          name: 'description',
                        })
                      }
                    </div>
                  </div>
                </>
                : null
            }
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.toggle()}>Cancel</Button>
          <ButtonLoader
            type="button"
            color="primary"
            loading={this.submitting}
            onClick={this.submit}
            disabled={!this.formState.get('memberId') || !this.formState.get('paymentMethod')}
          >Process Payment</ButtonLoader>
        </ModalFooter>
      </Modal>
      {
        (this.renderAddPaymentMethodModal && this.memberId)
          ? <AddPaymentMethodModal
            isOpen={this.showAddPaymentMethodModal}
            toggle={() => this.showAddPaymentMethodModal = false}
            onClosed={() => this.renderAddPaymentMethodModal = false}
            onSaved={() => {
              this.showAddPaymentMethodModal = false
              this.loadPaymentMethods()
            }}
            memberId={this.memberId}
          />
          : null
      }
    </>
  }
}