import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import { observable } from "mobx"

export enum NotificationType {
  TipReceived = 'Tip Received',
  MessageReceived = 'Message Received',
  NotificationReceived = 'Notification Received',
}

export default class MemberNotification extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  type: NotificationType | string
  message: string
  meta: any
  @observable
  readAt: Moment

  get iconClass () {
    if (this.type === NotificationType.TipReceived) {
      return 'fa fa-money text-success'
    } else {
      return 'fa fa-exclamation-circle'
    }
  }

  getCasts (): {} {
    return {
      createdAt: 'datetime',
      updatedAt: 'datetime',
      readAt: 'datetime',
    }
  }
}
