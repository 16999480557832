import * as React from "react"
import BaseView from "../BaseView"
import BackLink from "../../components/BackLink"
import { Button, Nav, NavItem, NavLink } from "reactstrap"
import { computed, observable } from "mobx"
import { InviteGuestModal } from "../../components/InviteGuestModal"
import { Link } from "react-router-dom"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import Guest from "../../models/Guest"
import * as _ from "lodash"
import classNames from "classnames"
import { createBrowserHistory } from "history"
import { observer } from "mobx-react"
import AuthStore from "../../stores/AuthStore"
import { UserRole } from "../../models/AuthUser"
import { Moment } from "moment-timezone"
import { ApiTableViewAdapter } from "../../components/table-view/ApiTableViewAdapter"
import EventGuest from "../../models/EventGuest"
import { ManagedChooseableColumn } from "../../components/table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "../../components/table-view/ManagedTableView";
import SelectFilter, { SelectFilterTargetType } from '../../components/table-view/filters/SelectFilter'
import DateFilter from '../../components/table-view/filters/DateFilter'
import ChapterGuestsCsvTransformer from '../../models/renderers/ChapterGuestsCsvTransformer'
import HelpTooltip from '../../components/HelpTooltip'
import BankCode from '../../components/BankCode'
import AppStateStore from '../../stores/AppStateStore'
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import Util, { createLazyResource, safeNull } from "../../common/Util"


const TABS = {
  invited: 'invited',
  chapter: 'chapter',
  all: 'all',
}

@observer
export default class ChapterGuestsView extends BaseView {
  @observable private showInviteGuestModal = false

  @observable private activeTab = TABS.invited
  @observable private allowedTabs: string[] = []

  private history = createBrowserHistory()

  @computed
  private get tableViewStateKey () {
    return `ChapterGuestsView-${this.activeTab}-20210514`
  }

  @observable private numberOfGuests = createLazyResource<number>(() => {
    return ApiClient.query(`guests { * }`, { returnTotal: true, limit: 0, where: [{ _scope: 'inQueue' }, { _scope: 'notArchived' }] })
  }, response => response.data._meta.total);


  private guestsAdapter = new ApiTableViewAdapter<Guest>(() => {
      const where = []

      if (this.activeTab === TABS.invited && AuthStore.getUser()!.member) {
        where.push({ _scope: 'invitedByMember', value: AuthStore.getUser()!.member!.id })
      }

      if (this.activeTab === TABS.chapter) {
        where.push({ _scope: 'invitedToChapter', value: AuthStore.getUser()!.chapter.id })
      }

      if (!safeNull(() => this.tableViewRef.current!.tableViewFilters!.showInactive)) {
        where.push({ _scope: 'notArchived' })
      }

      return {
        query: `
    guests {
      *

      category {
        *
      }
      guestEvents {
        *
        targetChapter {
          *
        }
      }
      createdByMember {
        *
      }
    }`,
        where: where,
      }
    },
    'guests',
    Guest
  )

  @computed
  private get availableColumns (): ManagedChooseableColumn[] {
  const availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'createdAt',
      column: {
        title: 'Date',
        accessor: 'createdAt',
        renderItem: (value: Moment) => value.format('MM/DD/YYYY'),
        sortable: true,
      },
      fixed: true,
    },
    {
      id: 'guest',
      column: {
        title: 'Guest',
        accessor: 'fullName',
        sortKey: 'lastName',
        sortable: true,
        renderItem: (value, item) => (
          <Link type="link" to={{ pathname: route(Routes.system.guests.show, { id: item.id }), state: { id: item.id } }}>{value}</Link>
        )
      },
      fixed: true,
    },
    {
      id: 'category',
      column: {
        title: 'Category',
        accessor: 'category.name',
        sortable: true,
      },
      default: false
    },
    {
      id: 'companyName',
      column: {
        title: 'Company',
        accessor: 'companyName',
        sortable: true,
      },
      default: false
    },
    {
      id: 'email',
      column: {
        title: 'Email',
        accessor: 'email',
        sortable: true,
      },
      fixed: false,
      default: true,
    },
    {
      id: 'phone',
      column: {
        title: 'Phone Number',
        accessor: 'phone',
        sortable: true,
      },
      fixed: false,
      default: true,
    },
    {
      id: 'status',
      column: {
        title: 'Status',
        accessor: 'status',
        sortable: true,
      },
      fixed: false,
      default: true,
    },
    {
      id: 'invitedTo',
      column: {
        title: 'Invited To',
        accessor: 'guestEvents',
        renderItem: (value: EventGuest[]) => value.length ? value[0].targetChapter.name : '---',
      },
      fixed: false,
    },
    {
      id: 'invitedBy',
      column: {
        title: 'Invited By',
        accessor: 'createdByMember.fullName',
      },
      fixed: false,
    },
    {
      id: 'bankCode',
      column: {
        title: 'BANKCODE',
        accessor: 'bankCode',
        sortable: true,
        renderItem: (bankCode: string, item: Guest) => bankCode ? <BankCode bankCode={bankCode} documentUrl={item.bankCodeDocumentUrl!}/> : null,
      },
      default: true,
    }
  ]

  if (AuthStore.getUser()!.isBoardMember) {
    availableColumns.push({
      id: 'archiveGuest',
      column: {
        title: 'Actions',
        renderItem: (_, guest: Guest) => !guest.archivedAt ?
          <Button type="button" color="primary" title="Archive Guest" onClick={() => this.archiveGuest(guest)}><i className="fa fa-archive"/></Button> : null,
      },
      fixed: true,
    })
  }
  return availableColumns
}

private archiveGuest = (guest: Guest) => {
  AppStateStore.showConfirmationModal('Archive Guest', <>
    <p>Archiving guest removes them from this list, but they can still be viewed later if necessary by clicking on the “Show Archived” filter below the “Find a Guest” search bar.</p>
    <p>Are you sure you want to archive this guest?</p>
  </>, (result, modal) => {
    if (result) {
      AppStateStore.showModalSpinner()

      ApiClient.getInstance().post(route(ApiRoutes.guests.archive, { id: guest.id }))
        .then(() => {
          this.tableViewRef.current && this.tableViewRef.current.fetchData()
          this.numberOfGuests.invalidate().then()

        })
        .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
        .then(() => AppStateStore.dismissModalSpinner())
    }

    modal.hide()
  })
}

  private tableViewRef = React.createRef<ManagedTableView>()

  componentDidMount (): void {
    super.componentDidMount()

    const user = AuthStore.getUser()!

    if (user.member) {
      this.allowedTabs.push(TABS.invited)
      this.allowedTabs.push(TABS.chapter)
    }

    if (user.hasRole(UserRole.Admin)) {
      this.allowedTabs.push(TABS.all)
    }

    this.activeTab = _.get(this.history.location.state, 'activeTab') || TABS.invited

    if (this.allowedTabs.indexOf(this.activeTab) === -1) {
      this.activeTab = this.allowedTabs[0]
    }

    if (this.tableViewRef.current) {
      this.tableViewRef.current.fetchData()
    }
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>Guests</h1>
        <ul className="content-header-actions">
          <li>
            <HelpTooltip
              content="Create a guest profile to invite a guest to a meeting or send a guest an application."
            />
          </li>
          <li>
            <Button color="primary" onClick={() => this.showInviteGuestModal = true}><i className="fa fa-user"/> Invite A Guest</Button>
          </li>
        </ul>
      </>
    )
  }

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(window.location.pathname, { ...this.history.location.state, activeTab: this.activeTab })

      this.tableViewRef.current && this.tableViewRef.current.fetchData()
    }
  }

  render (): React.ReactNode {
    return this.renderContent()
  }

  private filters = [
    new DateFilter('createdAt', 'createdAt', 'Date'),
    new SelectFilter('status', SelectFilterTargetType.Field, 'status', 'Status', [
      'New',
      'Invited',
      'Attended',
      'Application Sent',
      'Application Submitted',
      'Member',
    ].map(s => ({ value: s, label: s }))),
  ]

  renderContent (): React.ReactNode {
    return <>
      <div className="card">
        <div className="card-header">
          <Nav tabs className="card-header-tabs">
            {
              this.allowedTabs.indexOf(TABS.invited) > -1
                ? <NavItem className={classNames({ active: this.activeTab === TABS.invited })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.invited)
                    }}
                  >People I've Invited</NavLink>
                </NavItem>
                : null
            }
            {
              this.allowedTabs.indexOf(TABS.chapter) > -1
                ? <NavItem className={classNames({ active: this.activeTab === TABS.chapter })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.chapter)
                    }}
                  >My Chapter Guests</NavLink>
                </NavItem>
                : null
            }
            {
              this.allowedTabs.indexOf(TABS.all) > -1
                ? <NavItem className={classNames({ active: this.activeTab === TABS.all })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.all)
                    }}
                  >All Guests</NavLink>
                </NavItem>
                : null
            }
          </Nav>
        </div>
        <div className="card-body">
          <ManagedTableView
            stateKey={this.tableViewStateKey}
            searchLabel="Find A Guest"
            ref={this.tableViewRef}
            availableColumns={this.availableColumns}
            adapter={this.guestsAdapter}
            defaultSort={{ sortKey: 'createdAt', descending: true }}
            filters={this.filters}
            exportTransformer={ChapterGuestsCsvTransformer}
            allowShowInactive={true}
            showInactiveLabel="Show Archived"
          />
        </div>
      </div>
      <InviteGuestModal
        isOpen={this.showInviteGuestModal}
        toggle={() => this.showInviteGuestModal = !this.showInviteGuestModal}
        onCancel={() => this.showInviteGuestModal = false}
        onSaved={() => {
          this.tableViewRef.current && this.tableViewRef.current.fetchData()
          this.showInviteGuestModal = false
        }}
      />
    </>
  }
}
