import { observer } from "mobx-react"
import * as React from "react"
import { SyntheticEvent } from "react"
import AuthStore from "../stores/AuthStore"
import { computed, observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import Member from "../models/Member"
import FormHelper from "../forms/FormHelper"
import AppStateStore from "../stores/AppStateStore"
import { submitFormCreate } from "../api/ApiHelper"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { toast } from "react-toastify"
import Util, { modelToCamelCase } from "../common/Util"
import { BarLoader } from "react-spinners"
import { MemberPickerInput } from "./inputs/MemberPickerInput"
import * as _ from "lodash"
import FormError from "./FormError"
import * as AsyncHelpers from "../api/AsyncHelpers"
import { loadMemberOptions } from "../api/AsyncHelpers"
import { UserRole } from "../models/AuthUser"
import Event from "../models/Event"
import { Button } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import { route } from "../routes/routes"
import moment from 'moment-timezone'
import HelpTooltip from './HelpTooltip'

export type InviteGuestFormProps = {
  onCancel: () => void
  onSaved: () => void
  showCancelButton: boolean
}

@observer
export default class InviteGuestForm extends React.Component<InviteGuestFormProps> {
  static defaultProps = {
    clearAfterSave: true,
    showCancelButton: true,
  }

  private initialFormState = {
    sourceMemberId: AuthStore.getUser()!.member ? AuthStore.getUser()!.member!.id : undefined,
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    companyName: '',
    targetChapterId: AuthStore.getUser()!.chapter ? AuthStore.getUser()!.chapter.id : undefined,
    inviteToMeeting: true,
    targetEventId: undefined,
    phoneConfirmationMemberId: undefined,
    emailConfirmationMemberId: undefined,
    categoryId: undefined,
    message: '',
    sendEmailToSourceMember: false,
  }

  @observable private formState = new FormState(this.initialFormState)

  @observable private formErrors = new ErrorBag()
  @observable private submitting = false
  @observable private forceMember?: Member
  @observable private loading = false
  @observable private error?: string
  @observable private upcomingEvents: Event[] = []
  @observable private showCategoryWarning = false

  @computed
  private get selectedEvent () {
    return this.formState.get('targetEventId') ? _.find(this.upcomingEvents, e => e.id == this.formState.get('targetEventId')) : undefined
  }

  @computed
  private get isPastEvent () {
    return this.selectedEvent && this.selectedEvent.startsAt.isBefore(moment())
  }

  @computed
  private get submitButtonLabel () {
    if (this.formState.get('inviteToMeeting')) {

      return this.isPastEvent
        ? 'Add Guest'
        : 'Send Invitation'
    } else {
      return 'Add Guest'
    }
  }

  private fetchUpcomingEvents = () => {
    this.upcomingEvents = []

    if (this.formState.get('targetChapterId')) {
      ApiClient.query(`
events {
  *
}
    `, {
        where: [
          { _scope: 'chapter', value: this.formState.get('targetChapterId') },
          { _scope: 'canInviteGuests' },
          { _scope: 'visible' },
          { _scope: 'active' },
          { id: 'startsAt', op: '>=', value: moment().subtract(2, 'week').toISOString() },
        ],
        order: [
          { id: 'startsAt' },
        ],
      })
        .then(response => {
          this.upcomingEvents = response.data.events.map((t: {}) => new Event().init(modelToCamelCase(t)))
        })
    }
  }

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private onSubmit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()

    this.submitting = true
    AppStateStore.showModalSpinner()

    submitFormCreate(ApiClient.guests, this.formState, this.formErrors, () => {
      toast.success('Guest Invitation Sent')
      this.props.onSaved()
    })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  componentDidMount (): void {
    if (!AuthStore.getUser()!.hasAnyRole(['Admin', 'Employee']) && !AuthStore.getUser()!.isBoardMember) {
      this.loading = true
      ApiClient.query(`
member {
  *
  chapter {
    *
  }
}
      `, {
        where: [{ id: AuthStore.getUser()!.member!.id }]
      })
        .then(response => {
          this.forceMember = new Member(response.data.member)
        })
        .catch(error => {
          this.error = Util.extractErrorMessage(error.response)
        })
        .then(() => this.loading = false)
    }

    this.fetchUpcomingEvents()
  }

  private checkCategoryAvailability = () => {
    if (this.formState.get('targetChapterId') && this.formState.get('categoryId')) {
      ApiClient.getInstance()
        .get(route(ApiRoutes.chapters.checkCategoryAvailability, { id: this.formState.get('targetChapterId'), categoryId: this.formState.get('categoryId') }))
        .then(response => this.showCategoryWarning = !response.data.available)
        .catch(() => this.showCategoryWarning = false)
    } else {
      this.showCategoryWarning = false
    }
  }

  render (): React.ReactNode {
    return this.loading
      ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
      : this.error
        ? <div className="alert alert-error">{this.error}</div>
        : <>
          <form onSubmit={this.onSubmit}>
            <div className="form-row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Inviting Member</label>
                  {this.forceMember
                    ? <>
                      <div className="guest-form-header">
                        <div className="guest-form-member">
                          <img className="profile-image" src={this.forceMember.getProfileImageUrl()} alt={this.forceMember.fullName}/>
                          <div className="member-info">
                            <div className="member-name">{this.forceMember.fullName}</div>
                            {this.forceMember.business ? <div className="business-name">{this.forceMember.business.name}</div> : null}
                            {this.forceMember.category ? <div className="text-muted category-name">{this.forceMember.category.name}</div> : null}
                            {this.forceMember.chapter ? <div className="chapter-name">{this.forceMember.chapter.name}</div> : null}
                          </div>
                        </div>
                      </div>
                    </>
                    : <div>
                      <MemberPickerInput
                        placeholder="Choose the inviting member"
                        onChange={member => this.formState.set('sourceMemberId', _.get(member, 'id'))}
                        filters={
                          !AuthStore.getUser()!.hasAnyRole([UserRole.Admin, UserRole.Employee])
                            ? { chapter: AuthStore.getUser()!.chapter.id, allowEntireNetwork: true }
                            : {}
                        }
                        value={this.formState.get('sourceMemberId')}/>
                    </div>}
                  <FormError errors={this.formErrors} fieldName={'sourceMemberId'}/>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-6">
                {this.formHelper.renderTextInput({
                  name: 'firstName',
                  label: 'Guest First Name',
                })}
              </div>
              <div className="col-sm-6">
                {this.formHelper.renderTextInput({
                  name: 'lastName',
                  label: 'Guest Last Name',
                })}
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-6">
                {this.formHelper.renderTextInput({
                  name: 'phone',
                  label: 'Guest Phone Number',
                  type: 'tel',
                })}
              </div>
              <div className="col-sm-6">
                {this.formHelper.renderTextInput({
                  name: 'email',
                  label: 'Guest Email Address',
                  type: 'email',
                })}
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-12">
                {this.formHelper.renderTextInput({
                  name: 'companyName',
                  label: 'Guest Company Name',
                  type: 'text',
                })}
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-6">
                {this.formHelper.renderAsyncSelectInput({
                  name: 'targetChapterId',
                  label: 'Chapter',
                  defaultValue: AuthStore.getUser()!.chapter ? { value: AuthStore.getUser()!.chapter.id, label: AuthStore.getUser()!.chapter.name } : undefined,
                  loadOptions: AsyncHelpers.loadChapterOptions,
                  onChange: () => {
                    this.fetchUpcomingEvents()
                    this.checkCategoryAvailability()
                    this.formState.setAll({
                      phoneConfirmationMemberId: undefined,
                      emailConfirmationMemberId: undefined,
                    })
                  },
                })}
              </div>
              <div className="col-sm-6">
                {this.formHelper.renderAsyncSelectInput({
                  name: 'categoryId',
                  label: 'Category',
                  loadOptions: AsyncHelpers.loadCategoryOptions(),
                  onChange: () => this.checkCategoryAvailability(),
                })}
              </div>
            </div>
            {
              this.showCategoryWarning
                ? <div className="alert alert-warning">The category you have selected is already filled for this chapter. Are you sure you want to choose this category?</div>
                : null
            }
            <div className="form-row">
              <div className="col-12">
                <div className="form-group">
                  <div className="form-group">
                    <div className="form-check form-checkbox">
                      <label>
                        <input type="checkbox"
                               className="form-check-input"
                               name="inviteToMeeting"
                               checked={!!this.formState.get('inviteToMeeting')}
                               onChange={this.formState.onChange}
                        />
                        <span className="label-text">
                          Invite guest to a meeting
                          <div style={{ display: 'inline-block', marginLeft: 8 }}>
                          <HelpTooltip
                            placement="right"
                            content="Unclick the checkbox if the guest has already attended meeting and you do not want to send another meeting invitation to them."/>
                            </div>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              this.formState.get('inviteToMeeting')
                ? <>
                  <div className="form-row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>Event</label>
                        {
                          this.formState.get('targetChapterId')
                            ? <select
                              className="form-control"
                              name="targetEventId"
                              value={this.formState.get('targetEventId')}
                              onChange={this.formState.onChange}
                            >
                              <option value={undefined}>(choose event)</option>
                              {this.upcomingEvents.map(event => <option key={event.id} value={event.id}>{event.startsAt.format('MM/DD/YY')} - {event.title}</option>)}
                            </select>

                            : <div>Select a chapter to see upcoming events</div>
                        }
                        <FormError errors={this.formErrors} fieldName="targetEventId"/>
                        {
                          this.isPastEvent
                            ? <div className="alert alert-warning mt-4">
                              Invitations are only sent for future meetings. Past meetings only enter the guest in the database.
                            </div>
                            : null
                        }
                      </div>
                    </div>
                  </div>
                  {
                    this.formState.get('targetChapterId')
                      ? <>
                        <div className="form-row" key={this.formState.get('targetChapterId')}>
                          <div className="col-6">
                            {
                              this.formHelper.renderAsyncSelectInput({
                                label: 'Phone Confirmation',
                                name: 'phoneConfirmationMemberId',
                                loadOptions: loadMemberOptions(this.formState.get('targetChapterId')),
                              })
                            }
                          </div>
                          <div className="col-6">
                            {
                              this.formHelper.renderAsyncSelectInput({
                                label: 'Email Confirmation',
                                name: 'emailConfirmationMemberId',
                                loadOptions: loadMemberOptions(this.formState.get('targetChapterId')),
                              })
                            }
                          </div>
                        </div>
                      </>
                      : null
                  }
                </>
                : null
            }
            <div className="form-row">
              <div className="col-sm-12">
                {this.formHelper.renderTextAreaInput({
                  name: 'message',
                  label: 'Personal Note / Message',
                  labelAccessory: <HelpTooltip
                    content="Review the new email format in the Document Library to see where your personal note shows in the email."
                    placement="right"
                  />,
                })}
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="form-check form-checkbox">
                    <label>
                      <input type="checkbox"
                             className="form-check-input"
                             name="sendEmailToSourceMember"
                             onChange={this.formState.onChange}
                      />
                      <span className="label-text">Send a copy to the inviting email</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-buttons">
              {this.props.showCancelButton && <Button color="secondary" onClick={() => this.props.onCancel()}>Cancel</Button>}
              <ButtonLoader type="submit" color="primary" loading={this.submitting}>{this.submitButtonLabel}</ButtonLoader>
            </div>
          </form>
        </>
  }
}
