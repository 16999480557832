import * as React from "react"
import { SyntheticEvent } from "react"
import { observable } from "mobx"
import { RouteComponentProps } from "react-router"
import { observer } from "mobx-react"
import FormState from "../common/FormState"
import ApiClient from "../api/ApiClient"
import ErrorBag from "../common/ErrorBag"
import Util, { logException, modelToCamelCase, publicPath } from "../common/Util"
import classNames from "classnames"
import AppStateStore from "../stores/AppStateStore"
import ButtonLoader from "../components/ButtonLoader"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import AuthStore from "../stores/AuthStore"
import AuthUser from "../models/AuthUser"

type Props = {
  match: {
    params: {
      token: string,
      username: string
    }
  }
}

type ResetPasswordViewFormProps = {
  username: string,
  password: string,
  passwordConfirmation: string
}

@observer
export default class ResetPasswordView extends React.Component<Props & RouteComponentProps> {
  @observable
  private formData = new FormState<ResetPasswordViewFormProps>({
    username: this.props.match.params.username,
    password: '',
    passwordConfirmation: ''
  })

  @observable
  private submitting = false
  @observable
  private formErrors = new ErrorBag()

  componentDidMount (): void {
    document.body.classList.add('reset-password')
  }

  componentWillUnmount (): void {
    document.body.classList.remove('reset-password')
  }

  public render () {
    return (
      <div>
        <div className="login-container">
          <div className="logo">
            <img src={publicPath('img/logo-login.png')} alt="LeTip Wired"/>
          </div>
          <div className="login-box">
            <div className="login-header">
              <h2>Reset Your Password</h2>
            </div>
            <div className="login-body">
              <p className="text-center">Enter your new password</p>
              <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
                {renderInput('username', 'text', 'Email or Username', 'la-user', this.formData, this.formErrors, {readOnly: true})}
                {renderInput('password', 'password', 'Password', 'la-lock', this.formData, this.formErrors)}
                {renderInput('passwordConfirmation', 'password', 'Retype Password', 'la-lock', this.formData, this.formErrors)}
                <ButtonLoader type="submit" color="primary" block={true} loading={this.submitting}>Reset Password</ButtonLoader>
                <div className="text-center login-action" style={{ marginTop: 20 }}>
                  <Link to={route(Routes.login)}>Return to login</Link>
                </div>
              </form>
            </div>
          </div>
          <div className="letip-logo text-center">
            <img src={publicPath('img/letip-logo.png')} alt="LeTip"/>
          </div>
          <div className="login-footer text-center">&copy; {new Date().getFullYear()} LeTip International, Inc. All
            Rights Reserved
            <br />
            <a target="_blank" href="https://letip.com/terms-of-use/">Terms of Use</a> | <a target="_blank" href="https://letip.com/privacy-policy/">Privacy Policy</a>
          </div>
        </div>
      </div>
    )
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    if (this.submitting) {
      return
    }

    this.formErrors.clearErrors()
    AppStateStore.showModalSpinner()
    this.submitting = true

    ApiClient.resetPassword(
      this.props.match.params.token,
      this.formData.get('username'),
      this.formData.get('password'),
      this.formData.get('passwordConfirmation')
    )
      .then(response => {
        toast.success('Password reset successfully')
        AuthStore.login(response.data.auth_key, new AuthUser(modelToCamelCase(response.data.user)), false)
        window.scrollTo(0, 0)
        this.props.history.replace(route(Routes.index));
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, response => {
          return false
        }, (response, message) => {
          errors.addError('username', message);
          return true;
        })

        this.formErrors = errors
      })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('username', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }
}

function renderInput (name: keyof ResetPasswordViewFormProps, type: string, placeholder: string, icon: string, formState: FormState<ResetPasswordViewFormProps>, formErrors: ErrorBag, addlInputAttrs = {}) {
  return (
    <div className="form-group">
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className={classNames('la', icon)}/>
          </span>
        </div>
        <input {...addlInputAttrs}
               type={type}
               className={classNames('form-control', { 'is-invalid': formErrors.hasErrors(name) })}
               placeholder={placeholder}
               name={name}
               value={formState.get(name)}
               onChange={formState.onChange}
        />
        {formErrors.hasErrors(name) && <div className="invalid-feedback">{formErrors.getError(name)}</div>}
      </div>
    </div>
  )
}