import CsvTransformer from "./CsvTransformer"
import moment from "moment-timezone"
import Invoice from "../Invoice"

export default class InvoicesCsvTransformer extends CsvTransformer<Invoice> {
  get filename () {
    return `invoices-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'id',
      valueTransformer: row => String(row.invoiceNumber),
    })

    this.addColumn({
      header: 'Date',
      valueTransformer: row => row.createdAt.format('MM/DD/YYYY HH:mm'),
    })

    this.addColumn({
      header: 'Description',
      valueTransformer: row => row.description,
    })

    this.addColumn({
      header: 'Total',
      valueTransformer: row => row.total,
    })

    this.addColumn({
      header: 'Amount Due',
      valueTransformer: row => row.amountDue,
    })

    this.addColumn({
      header: 'Status',
      valueTransformer: row => row.status,
    })

    this.addColumn({
      header: 'Member ID',
      valueTransformer: row => row.memberId || '',
    })

    this.addColumn({
      header: 'Member Name',
      valueTransformer: row => row.member ? row.member.fullName : '',
    })

    this.addColumn({
      header: 'Chapter ID',
      valueTransformer: row => row.chapterId || '',
    })

    this.addColumn({
      header: 'Chapter Name',
      valueTransformer: row => row.chapter ? row.chapter.name : '',
    })
  }
}