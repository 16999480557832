import React, { SyntheticEvent } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import ApiClient, { ApiRoutes } from '../../api/ApiClient'
import ChapterFee from '../../models/ChapterFee'
import FormState from '../../common/FormState'
import ErrorBag from '../../common/ErrorBag'
import FormHelper from '../../forms/FormHelper'
import _ from 'lodash'
import Util, { transformIf } from '../../common/Util'
import ButtonLoader from '../ButtonLoader'
import { route } from '../../routes/routes'
import AppStateStore from '../../stores/AppStateStore'
import LoadingPanel from '../LoadingPanel'

type Props = {
  chapterId: number
  onChapterInvalidated: () => void
}

@observer
export default class ManageChapterFees extends React.Component<Props> {
  @observable private formState = new FormState({
    initialDues: '',
    quarterlyDues: '',
    enableOtherFee: false,
    otherFeeLabel: '',
    otherFee: '',
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable private chapterFees: ChapterFee[] = []

  @observable private submitting = false

  @observable private loading = false

  componentDidMount (): void {
    this.loadChapterFees()
  }

  private loadChapterFees = () => {
    this.loading = true

    ApiClient.query(
      `
chapterFees {
  *
}
      `,
      {
        where: [{ chapterId: this.props.chapterId }]
      }
    )
      .then(response => {
        this.chapterFees = response.data.chapterFees.map((c: any) => new ChapterFee().init(c))

        this.formState.set('initialDues', transformIf(_.find(this.chapterFees, c => c.code === 'Initial Chapter Dues'), f => f.amount) || '')
        this.formState.set('quarterlyDues', transformIf(_.find(this.chapterFees, c => c.code === 'Quarterly Chapter Dues'), f => f.amount) || '')

        const otherFee = _.find(this.chapterFees, c => c.code === 'Other')

        if (otherFee) {
          this.formState.set('enableOtherFee', true)
          this.formState.set('otherFeeLabel', otherFee.label)
          this.formState.set('otherFee', otherFee.amount)
        } else {
          this.formState.set('enableOtherFee', false)
          this.formState.set('otherFeeLabel', '')
          this.formState.set('otherFee', '')
        }

        this.loading = false
      })
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitting = true
    this.formErrors.clearErrors()

    ApiClient.getInstance().post(route(ApiRoutes.chapters.updateChapterFees, { id: this.props.chapterId }), this.formHelper.toObject())
      .then(() => {
        this.loadChapterFees()
      })
      .catch(error =>
        Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      )
      .then(() => this.submitting = false)
  }

  render () {
    return <LoadingPanel loading={this.loading} loaded={true}>
      {() =>
        <form onSubmit={this.submit}>
          <div className="row">
            <div className="col-sm-2">
              <div className="form-row">
                {
                  this.formHelper.renderTextInput({
                    label: 'Initial Chapter Dues',
                    name: 'initialDues',
                    prepend: '$',
                    inputGroupProps: { style: { width: 120 } },
                  })
                }
              </div>
              <div className="form-row">
                {
                  this.formHelper.renderTextInput({
                    label: 'Quarterly Chapter Dues',
                    name: 'quarterlyDues',
                    prepend: '$',
                    inputGroupProps: { style: { width: 120 } },
                  })
                }
              </div>
            </div>
            <div className="col-sm-10">
              <p>
                Entering this information will place your Chapter Fees on the Application, but it will not
                create charges for the fees. This is for informational purposes only for the LeTip Wired Electronic
                Applications.
              </p>
            </div>
          </div>
          <div className="form-row">
            {
              this.formHelper.renderCheckboxInput({
                label: 'Other Fee',
                name: 'enableOtherFee',
              })
            }
          </div>
          {
            this.formState.get('enableOtherFee')
              ? <>
                <div className="form-row">
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: 20 }}>
                      {
                        this.formHelper.renderTextInput({
                          label: 'Other Fee Name',
                          name: 'otherFeeLabel',
                          inputGroupProps: { style: { width: 250 } },
                        })
                      }
                    </div>
                    <div>
                      {
                        this.formHelper.renderTextInput({
                          label: 'Amount',
                          name: 'otherFee',
                          prepend: '$',
                          inputGroupProps: { style: { width: 120 } },
                        })
                      }
                    </div>
                  </div>
                </div>
              </>
              : null
          }
          <div>
            <ButtonLoader type="submit" color="success" loading={this.submitting}>Save Changes</ButtonLoader>
          </div>
        </form>
      }
    </LoadingPanel>
  }
}
