import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import Member from "../models/Member"
import { computed, observable } from "mobx"
import AppStateStore from "../stores/AppStateStore"
import { Button } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import Util, { createLazyResource, formatCurrency, transformIf } from "../common/Util"
import LazyResourceView from "./LazyResourceView"
import moment from 'moment'
import * as _ from "lodash"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { toast } from "react-toastify"
import EventBus, { EventBusContext } from "../common/EventBus"
import { route } from '../routes/routes'

type Props = {
  memberId: number
  onCancel: () => void
  onSaved: () => void
}

@observer
export default class RenewMembershipForm extends React.Component<Props> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  componentDidMount (): void {
    this.addListeners(this.context.eventBus)
  }

  componentWillUnmount (): void {
    this.removeListeners(this.context.eventBus)
  }

  private addListeners = (eventBus: EventBus) => {
    eventBus.on('member-invalidated', this.onMemberInvalidated)
  }

  private removeListeners = (eventBus: EventBus) => {
    eventBus.remove(this.onMemberInvalidated)
  }

  private onMemberInvalidated = () => {
    this.member.invalidate()
  }

  @observable private member = createLazyResource<Member>(() => {
    return ApiClient.query(
      `
member {
  *
}
      `,
      {
        where: [{ id: this.props.memberId }]
      }
    )
  }, response => new Member(response.data.member))

  @observable
  private submitting = false

  @computed get renewalFee () {
    if (this.member.current) {
      if (this.member.current.membershipFees.current) {
        return this.member.current.membershipFees.current.getCurrentRenewalFee()
      }
    }

    return undefined
  }

  @computed get taxRate () {
    if (this.member.current) {
      if (this.member.current.membershipFees.current) {
        return this.member.current.membershipFees.current.taxRate
      }
    }

    return 0
  }

  @computed get tax () {
    const renewalFee = this.renewalFee || 0
    return Math.round(renewalFee * this.taxRate * 100) / 100
  }

  @computed get totalRenewalAmount () {
    const renewalFee = this.renewalFee || 0

    return Math.round((renewalFee + renewalFee * this.taxRate) * 100) / 100
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitting = true
    AppStateStore.showModalSpinner()

    try {
      ApiClient.getInstance()
        .post(route(ApiRoutes.members.generateRenewalInvoice, { id: this.props.memberId }))
        .then(response => {
          toast.success('Renewal invoice has been generated')
          this.context.eventBus.dispatch('member-invalidated')
          this.props.onSaved()

          const link = document.createElement('a')
          link.setAttribute('target', '_blank')
          link.setAttribute('href', response.data.pay_invoice_url)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
        .then(() => {
          this.submitting = false
          AppStateStore.dismissModalSpinner()
        })
    } catch (err) {
      AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
    }
  }

  private onCancelClicked = () => {
    this.props.onCancel()
  }

  render (): React.ReactNode {
    return <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      <LazyResourceView resource={this.member}>
        {member => <>
          <div className="form-row divider-bottom mb-4">
            <div className="col-md-6 divider-right p-3">
              <div className="d-flex flex-row align-items-center">
                <div style={{ paddingRight: 20 }}>
                  <img alt="Member Profile Image" src={member.getProfileImageUrl()}
                       style={{
                         width: 130,
                         height: 130,
                         objectFit: 'cover',
                         objectPosition: 'top center',
                         backgroundColor: '#ccc',
                       }}/>
                </div>
                <div className="flex-fill">
                  <h5>{member.fullName}</h5>
                  <div>{_.get(this.member.current, 'business.name')}</div>
                  {member.joinDate && <div>Member Since {member.joinDate!.format('MM/DD/YYYY')}</div>}
                  {member.badgeColor && <div>{member.badgeColor} Badge</div>}
                </div>
              </div>
            </div>
            <div className="col-md-6 p-3">
              <div className="stacked-items">
                <div className="text-muted">Renewal Information</div>
                <div className="d-flex d-flex-row">
                  <div className="flex-fill text-medium">Your Renewal Due Date</div>
                  <div>
                    <LazyResourceView resource={member.activeMembership}>
                      {(activeMembership) => {
                        return transformIf(activeMembership.endsAt, d => {
                          return <>{d.format('MM/DD/YYYY')} <span className="text-muted">({d.diff(moment(), 'days')} days)</span></>
                        }) || '---'
                      }}
                    </LazyResourceView>
                  </div>
                </div>
                <div className="d-flex d-flex-row">
                  <div className="flex-fill text-medium">Your Next Renewal Date After Payment</div>
                  <div>
                    <LazyResourceView resource={member.activeMembership}>
                      {(activeMembership) => {
                        return transformIf(activeMembership.endsAt, d => {
                          const next = moment(d).add(1, 'year')
                          return <>{next.format('MM/DD/YYYY')} <span className="text-muted">({next.diff(moment(), 'days')} days)</span></>
                        }) || '---'
                      }}
                    </LazyResourceView>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <table className="invoice-table">
            <thead>
            <tr>
              <th>Item</th>
              <th className="text-right">Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>LeTip International, Inc. Annual Membership Renewal</td>
              <td className="text-right">
                {this.renewalFee ? formatCurrency(this.renewalFee) : '---'}
              </td>
            </tr>
            {
              this.taxRate
                ? <tr>
                  <td>Tax</td>
                  <td className="text-right">
                    {this.renewalFee ? formatCurrency(this.tax) : '---'}
                  </td>
                </tr>
                : null
            }
            </tbody>
            <tfoot>
            <tr>
              <td/>
              <td className="text-right">{
                this.renewalFee ? formatCurrency(this.totalRenewalAmount) : '---'}
              </td>
            </tr>
            </tfoot>
          </table>

          <hr/>

          <p className="text-center">A renewal invoice will be generated and you will be redirected to the payment page automatically.</p>

          <div className="form-buttons">
            <Button type="button" color="secondary" onClick={() => this.onCancelClicked()}>Cancel</Button>
            <ButtonLoader type="submit" color="success" loading={this.submitting}>Renew Membership</ButtonLoader>
          </div>
        </>}
      </LazyResourceView>
    </form>
  }
}
