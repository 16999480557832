import * as React from "react"
import { observer } from "mobx-react"
import { Moment } from "moment-timezone"
import FileBrowserRow from "./FileBrowserRow"
import AuthStore from "../../stores/AuthStore"
import { computed, observable } from "mobx"
import * as _ from "lodash"
import path from 'path'
import { makeUrl } from "../../common/Util"
import { ItemType, VideoData } from '../../models/DocumentLibraryItem'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import VideoPlayer from '../VideoPlayer'

export enum NodeType {
  File = 'File',
  Video = 'Video',
  Folder = 'Folder',
}

export interface IFileBrowserNode {
  id: string
  name: string
  size?: number
  type: NodeType
  created?: Moment
  parent?: IFileBrowserNode
  url?: string
  videoData?: VideoData
  tags: string[]
}

type FileBrowserProps = {
  files: IFileBrowserNode[]
  onNavigateFolder: (folder: IFileBrowserNode) => void
  onDeleteClicked: (node: IFileBrowserNode) => void
  onEditClicked: (node: IFileBrowserNode) => void
  empty?: React.ReactNode
  showFullFolderPath?: boolean
  itemType?: ItemType
  canManage?: boolean
}

@observer
export default class FileBrowser extends React.Component<FileBrowserProps> {
  private openLinkRef = React.createRef<HTMLAnchorElement>()

  @observable modalVideo: IFileBrowserNode | undefined = undefined

  @computed get sortedItems () {
    return _.orderBy(this.props.files, [(d: IFileBrowserNode) => d.type === NodeType.Folder ? 0 : 1, (d: IFileBrowserNode) => d.name])
  }

  private onFileClicked = (node: IFileBrowserNode) => {
    if (node.type === NodeType.File) {
      if (this.openLinkRef.current && node.url) {
        this.openLinkRef.current.href = makeUrl(node.url, { token: AuthStore.generateTempAuthToken() })
        this.openLinkRef.current.click()
      }
    } else if (node.type === NodeType.Folder) {
      this.props.onNavigateFolder(node)
    }
  }

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    if (this.props.itemType === ItemType.Video) {
      const videos = this.sortedItems.filter(i => i.type === NodeType.Video)
      const folders = this.sortedItems.filter(i => i.type === NodeType.Folder)

      return <div className="file-browser-container">
        <div className="row">
          {
            videos.map(video => <div key={video.id} className="col-lg-3 col-md-6 col-12">
              <div className="card">
                <div className="card-body text-center">
                  <div className="video-thumbnail" onClick={() => this.modalVideo = video}>
                    {
                      video.videoData!.thumbnailUrl
                        ? <img
                          style={{ maxWidth: '100%' }}
                          src={video.videoData!.thumbnailUrl}/>
                        : null
                    }
                    <div className="video-thumbnail-play"><i className="fa fa-play"/></div>
                  </div>
                  <h4 className="mt-3 mb-0">{video.name}</h4>
                  {
                    this.props.canManage
                      ? <div className="text-right mt-2">
                        <a className="mr-2" href="" onClick={(ev) => {
                          ev.preventDefault()
                          this.props.onEditClicked(video)
                        }}><i className="fa fa-edit"/></a>
                        <a className="text-danger" href="" onClick={(ev) => {
                          ev.preventDefault()
                          this.props.onDeleteClicked(video)
                        }}><i className="fa fa-trash"/></a>
                      </div>
                      : null
                  }
                </div>
              </div>
            </div>)
          }
        </div>
        {
          this.modalVideo
            ? <VideoPlayerModal
              video={this.modalVideo}
              onClosed={() => this.modalVideo = undefined}
            />
            : null
        }
      </div>
    } else {
      return <div className="file-browser-container">
        {
          this.sortedItems.length
            ? <table className="table-view">
              <thead>
              <tr>
                <th/>
                <th className="name-header">Name</th>
                <th className="text-right pr-2">Size</th>
              </tr>
              </thead>
              <tbody>
              {
                this.sortedItems.map(file => <FileBrowserRow
                  showFullFolderPath={this.props.showFullFolderPath}
                  key={file.id}
                  node={file}
                  onClick={this.onFileClicked}
                  onDeleteClicked={this.props.onDeleteClicked}
                  onEditClicked={this.props.onEditClicked}
                />)
              }
              </tbody>
            </table>
            : this.props.empty || <div className="alert alert-info text-center">There are no documents here</div>
        }
        <div style={{ display: 'none' }}>
          <a href="" target="_blank" ref={this.openLinkRef}/>
        </div>
      </div>
    }
  }
}

type VideoPlayerModalProps = {
  title?: string
  video: IFileBrowserNode
  onClosed: () => void
}

@observer
class VideoPlayerModal extends React.Component<VideoPlayerModalProps> {
  @observable isOpen = true

  render () {
    return <Modal isOpen={this.isOpen} size="lg" toggle={() => this.isOpen = false} onClosed={this.props.onClosed}>
      <ModalHeader toggle={() => this.isOpen = false}>
        {this.props.video.name || 'Video Player'}
      </ModalHeader>
      <ModalBody>
        <VideoPlayer videoData={this.props.video.videoData!}/>
      </ModalBody>
    </Modal>
  }
}
