import * as React from "react"
import { ChangeEvent } from "react"
import { observer } from "mobx-react"
import { computed, observable } from "mobx"
import DocumentLibraryItem, { DocumentLibraryDescriptor, ItemType, VideoData } from "../models/DocumentLibraryItem"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import LoadingPanel from "./LoadingPanel"
import * as _ from "lodash"
import Util from "../common/Util"
import FileBrowser, { IFileBrowserNode, NodeType } from "./file-browser/FileBrowser"
import { Moment } from "moment-timezone"
import CreateFolderModal from "./file-browser/CreateFolderModal"
import UploadDocumentModal from "./file-browser/UploadDocumentModal"
import { route } from "../routes/routes"
import { toast } from "react-toastify"
import AppStateStore from "../stores/AppStateStore"
import RenameItemModal from "./file-browser/RenameItemModal"
import RequirePermission, { can, Permission } from "./RequirePermission"
import AddVideoModal from './file-browser/AddVideoModal'

export type DocumentLibraryProps = {
  itemType?: ItemType,
  libraryDescriptor: DocumentLibraryDescriptor,
}

class DocumentLibraryNode implements IFileBrowserNode {
  constructor (
    public documentLibraryItem: DocumentLibraryItem,
    public parent?: DocumentLibraryNode,
  ) {
  }

  get id (): string {
    return String(this.documentLibraryItem.id)
  }

  get created (): Moment | undefined {
    return this.documentLibraryItem.createdAt
  }

  get name (): string {
    return this.documentLibraryItem.name
  }

  get size (): number | undefined {
    return this.documentLibraryItem.fileUpload ? this.documentLibraryItem.fileUpload.fileSizeBytes : undefined
  }

  get type (): NodeType {
    return this.documentLibraryItem.itemType === ItemType.File
      ? NodeType.File
      : this.documentLibraryItem.itemType === ItemType.Video
        ? NodeType.Video
        : NodeType.Folder
  }

  get videoData (): VideoData | undefined {
    return this.documentLibraryItem.videoData || undefined
  }

  get url (): string | undefined {
    return this.documentLibraryItem.fileUpload ? this.documentLibraryItem.fileUpload.url : undefined
  }

  get tags (): string[] {
    return this.documentLibraryItem.tags
  }
}

@observer
export default class DocumentLibrary extends React.Component<DocumentLibraryProps> {
  @observable private documentLibraryNodes: DocumentLibraryNode[] = []
  @observable private parentDocumentLibraryNodeId?: string
  @observable private loading = false
  @observable private loaded = false
  @observable private error?: React.ReactNode
  @observable private showCreateFolderModal = false
  @observable private showUploadDocumentModal = false
  @observable private showAddVideoModal = false
  @observable private showRenameItemModal = false
  @observable private renameItemTarget?: IFileBrowserNode
  @observable private availableTags: string[] = []
  @observable private tags: string[] = []

  @observable private searchText = ''

  @computed get visibleDocumentLibraryNodes () {
    let items = this.documentLibraryNodes
    if (this.searchText.length) {
      items = items.filter(n => n.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1)
    } else {
      items = this.parentDocumentLibraryNode
        ? items.filter(n => n.parent && n.parent.id === this.parentDocumentLibraryNodeId)
        : items.filter(n => !n.parent)
    }

    if (this.tags.length) {
      items = items.filter(i => i.tags.find(t => this.tags.find(tt => tt.toLowerCase() === t.toLowerCase())))
    }

    return items
  }

  @computed get parentDocumentLibraryNode () {
    return _.find<DocumentLibraryNode>(this.documentLibraryNodes, (n: DocumentLibraryNode) => n.id === this.parentDocumentLibraryNodeId)
  }

  private fetchDocumentLibraryItems = () => {
    this.loading = true

    ApiClient.query(`
documentLibraryItems {
  *
  tags
  
  createdBy {
    *
  }
  
  updatedBy {
    *
  }
  
  fileUpload {
    *
  }
}
`, {
      where: [
        { libraryType: this.props.libraryDescriptor.libraryType },
        this.props.libraryDescriptor.libraryOwnerType ? { libraryOwnerType: this.props.libraryDescriptor.libraryOwnerType } : { id: 'libraryOwnerType', op: 'null' },
        this.props.libraryDescriptor.libraryOwnerId ? { libraryOwnerId: this.props.libraryDescriptor.libraryOwnerId } : { id: 'libraryOwnerId', op: 'null' },
        { _scope: 'visible' },
      ]
    })
      .then(response => {
        // order items alphabetically
        const items = _.orderBy(response.data.documentLibraryItems.map((d: {}) => new DocumentLibraryItem().init(d)), (d: DocumentLibraryItem) => d.name)
        const nodes: DocumentLibraryNode[] = []

        // iterate over items and build tree structure
        const buildChildren = (parentNode: DocumentLibraryNode | null) => {
          // find all children of this parent
          items.filter((d: DocumentLibraryItem) => d.parentId === (parentNode ? parentNode.documentLibraryItem.id : null))
            .forEach(childItem => {
              const childNode = new DocumentLibraryNode(childItem, parentNode || undefined)
              nodes.push(childNode)
              buildChildren(childNode)
            })
        }

        buildChildren(null)

        this.documentLibraryNodes = nodes
        this.loaded = true
      })
      .catch(error => {
        this.error = <div className="alert alert-danger">{Util.extractErrorMessage(error.response)}</div>
      })
      .then(() => this.loading = false)

    this.loadAvailableTags().then()
  }

  private loadAvailableTags = async () => {
    try {
      const response = await ApiClient.getInstance().get('/document-library/available-tags')
      this.availableTags = response.data.tags.sort()
    } catch (err) {
    }
  }

  private deleteLibraryItem = (node: IFileBrowserNode) => {
    AppStateStore.showConfirmationModal(
      `Delete ${node.type}`,
      <div className="text-center">
        <p>
          <i className="fa fa-warning text-danger" style={{ fontSize: 50 }}/>
        </p>
        <p>
          Are you sure you want to delete the {node.type.toLowerCase()} <b>{node.name}</b>
          {
            node.type === NodeType.Folder
              ? ' and all of the documents/folders inside of it?'
              : null
          }
        </p>
      </div>,
      (result, modal) => {
        modal.hide()

        if (result) {
          AppStateStore.showModalSpinner()

          ApiClient.getInstance()
            .delete(route(ApiRoutes.documentLibrary.deleteLibraryItem, { id: node.id }))
            .then(() => {
              toast.success(`${node.type} deleted`)
              this.fetchDocumentLibraryItems()
            })
            .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
            .then(() => {
              AppStateStore.dismissModalSpinner()
            })
        }
      })
  }

  private renderBreadcrumbs = () => {
    let n = this.parentDocumentLibraryNode

    let crumbs: DocumentLibraryNode[] = []

    while (n) {
      crumbs.push(n)
      n = n.parent
    }

    crumbs = crumbs.reverse()

    return <>
      <a href="" onClick={ev => {
        ev.preventDefault()
        this.parentDocumentLibraryNodeId = undefined
      }}>{this.props.itemType === ItemType.Video ? 'Video Library' : 'Document Library'}</a>
      {
        crumbs.map((crumb, idx) => <span key={crumb.id}>
          <i className="fa fa-angle-right crumb-separator"/>
          {
            idx < crumbs.length - 1
              ? <a href="" onClick={ev => {
                ev.preventDefault()
                this.parentDocumentLibraryNodeId = crumb.id
              }}>{crumb.name}</a>
              : <span>{crumb.name}</span>
          }
        </span>)
      }
    </>
  }

  private onSearchTextChanged = (ev: ChangeEvent<HTMLInputElement>) => {
    this.searchText = ev.target.value.trimLeft()
  }

  componentDidMount (): void {
    this.fetchDocumentLibraryItems()
  }

  private toggleTag = (tag: string, checked: boolean) => {
    if (checked) {
      if (!this.tags.filter(t => t.toLowerCase() === tag.toLowerCase()).length) {
        this.tags.push(tag)
      }
    } else {
      this.tags = this.tags.filter(t => t.toLowerCase() !== tag.toLowerCase())
    }
  }

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <LoadingPanel
      loaded={this.loaded}
      loading={this.loading}
      error={this.error}
    >
      {() => {
        return <div className="document-library">
          <div className="document-library-header">
            <div className="row">
              <div className="col-md-6">
                <div className="document-library-search">
                  <div className="table-view-search">
                    <form onSubmit={ev => ev.preventDefault()}>
                      <div className="table-view-search-input">
                        <i className="fa fa-search"/>
                        <input className="form-control" placeholder={this.props.itemType === ItemType.Video ? 'Video Library Search' : 'Document Library Search'} type="text" value={this.searchText} onChange={this.onSearchTextChanged.bind(this)}/>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <RequirePermission permission={Permission.ManageDocumentLibrary} context={{ documentLibraryDescriptor: this.props.libraryDescriptor }}>
                  {() =>
                    <div className="document-library-actions">
                      <a
                        href=""
                        onClick={ev => {
                          ev.preventDefault()
                          if (this.props.itemType === ItemType.Video) {
                            this.showAddVideoModal = true
                          } else {
                            this.showUploadDocumentModal = true
                          }
                        }}
                      ><i className="fa fa-upload"/> {this.props.itemType === ItemType.Video ? 'Add Video' : 'Upload File'}</a>
                      {
                        this.props.itemType !== ItemType.Video
                          ? <a
                            href=""
                            onClick={ev => {
                              ev.preventDefault()
                              this.showCreateFolderModal = true
                            }}
                          ><i className="fa fa-folder"/> Create New Folder</a>
                          : null
                      }
                    </div>
                  }
                </RequirePermission>
              </div>
            </div>
            {
              (this.props.itemType === ItemType.Video && this.availableTags.length)
                ? <div className={'row'}>
                  <div className={'col-12'}>
                    <div style={{ display: 'flex' }}>
                      <div style={{backgroundColor:'#eee', padding: 4, borderRadius: 4}}>
                        <div className="form-checkbox">
                          <label style={{margin:0}}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={this.tags.length === 0}
                              onChange={ev => this.tags = []}
                            />
                            <span className="label-text">Show All</span>
                          </label>
                        </div>
                      </div>
                      {
                        this.availableTags.map(tag => <div key={tag} style={{backgroundColor:'#eee', padding: 4, borderRadius: 4, marginLeft: 8}}>
                          <div className="form-checkbox">
                            <label style={{margin:0}}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={!!this.tags.find(t => t.toLowerCase() === tag.toLowerCase())}
                                onChange={ev => (this.toggleTag(tag, ev.target.checked))}
                              />
                              <span className="label-text">{tag}</span>
                            </label>
                          </div>
                        </div>)
                      }
                    </div>
                  </div>
                </div>
                : null
            }
          </div>
          {
            this.props.itemType !== ItemType.Video
              ? <div className="document-library-breadcrumbs">
                {this.searchText.length ? 'Search Results' : this.renderBreadcrumbs()}
              </div>
              : null
          }
          <FileBrowser
            itemType={this.props.itemType}
            files={this.visibleDocumentLibraryNodes}
            canManage={can(Permission.ManageDocumentLibrary, { documentLibraryDescriptor: this.props.libraryDescriptor })}
            onNavigateFolder={(folder: IFileBrowserNode) => {
              this.parentDocumentLibraryNodeId = folder.id
              this.searchText = ''
            }}
            onDeleteClicked={this.deleteLibraryItem}
            onEditClicked={node => {
              this.showRenameItemModal = true
              this.renameItemTarget = node
            }}
            empty={this.searchText.length ? <div className="alert alert-info text-center">There are no {this.props.itemType === ItemType.Video ? 'videos' : 'documents'} that match your search</div> : undefined}
            showFullFolderPath={this.searchText.length > 0}
          />
          <CreateFolderModal
            parentDocumentLibraryItemId={this.parentDocumentLibraryNodeId}
            isOpen={this.showCreateFolderModal}
            toggle={() => this.showCreateFolderModal = false}
            libraryDescriptor={this.props.libraryDescriptor}
            onFolderCreated={() => {
              this.fetchDocumentLibraryItems()
              this.showCreateFolderModal = false
            }}
          />
          <RenameItemModal
            node={this.renameItemTarget}
            isOpen={this.showRenameItemModal}
            toggle={() => {
              this.showRenameItemModal = false
              this.renameItemTarget = undefined
            }}
            onItemSaved={() => {
              this.fetchDocumentLibraryItems()
              this.showRenameItemModal = false
              this.renameItemTarget = undefined
            }}
            availableTags={this.availableTags.slice()}
          />
          <UploadDocumentModal
            parentDocumentLibraryItemId={this.parentDocumentLibraryNodeId}
            isOpen={this.showUploadDocumentModal}
            toggle={() => this.showUploadDocumentModal = false}
            libraryDescriptor={this.props.libraryDescriptor}
            onDocumentUploaded={() => {
              this.fetchDocumentLibraryItems()
              this.showUploadDocumentModal = false
            }}
          />
          <AddVideoModal
            parentDocumentLibraryItemId={this.parentDocumentLibraryNodeId}
            isOpen={this.showAddVideoModal}
            toggle={() => this.showAddVideoModal = false}
            libraryDescriptor={this.props.libraryDescriptor}
            onVideoAdded={() => {
              this.fetchDocumentLibraryItems()
              this.showAddVideoModal = false
            }}
            availableTags={this.availableTags.slice()}
          />
        </div>
      }
      }
    </LoadingPanel>
  }
}
