import * as React from "react"
import { observer } from "mobx-react"
import { renderAsyncSelectInput } from "../forms/FormInputs"
import TipCardList from "./TipCardList"
import { loadCategoryOptions } from "../api/AsyncHelpers"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"

type Props = {
  chapterId: number
}

@observer
export default class EscrowTipList extends React.Component<Props> {
  @observable private searchFormState = new FormState({
    categoryId: undefined,
  })
  @observable private searchFormErrors = new ErrorBag()

  render () {
    return <>
      <form onSubmit={ev => ev.preventDefault()}>
        <div className="member-card-search-header row">
          <div className="col-md-4 col-sm-12">
            <label className="member-card-search-search-label">
              Search By Category
            </label>
            {renderAsyncSelectInput(this.searchFormState, this.searchFormErrors, {
              name: 'categoryId',
              value: this.searchFormState.get('categoryId'),
              loadOptions: loadCategoryOptions(),
            })}
          </div>
        </div>
      </form>
      <TipCardList
        allowPrint={true}
        escrowTipsView={true}
        targetChapterId={this.props.chapterId}
        categoryId={this.searchFormState.get('categoryId')}
      />
    </>
  }
}