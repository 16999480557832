import * as React from "react"
import { ChangeEvent } from "react"
import BaseView from "../BaseView"
import { IObservableArray, observable } from 'mobx'
import { observer } from "mobx-react"
import RequirePermission, { Permission } from "../../components/RequirePermission"
import Calendar from "../../components/calendar/Calendar"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, TabContent } from "reactstrap"
import { MeetingTypeFilters } from "../../components/calendar/MeetingTypeFilters"
import { AreaFilters } from "../../components/calendar/AreaFilters"
import { createBrowserHistory } from "history"
import RequireRole from "../../components/RequireRole"
import { UserRole } from "../../models/AuthUser"
import LazyTabPane from "../../components/LazyTabPane"
import * as _ from "lodash"
import RecurringEventsView from "./RecurringEventsView"



const TABS = {
  calendar: 'calendar',
  recurringEvent: 'recurring event'
}

@observer
export default class SystemCalendarView extends BaseView {
  @observable private activeTab = TABS.calendar
  @observable private selectedMeetingTypes: string[] = []
  @observable private selectedAreas: number[] = []

  private history = createBrowserHistory()

  private calendarRef = React.createRef<Calendar>()

  private recurringEventRef = React.createRef<RecurringEventsView>()


  private toggleMeetingTypeFilter = (ev: ChangeEvent<HTMLInputElement>, meetingTypeId: string) => {
    if (!ev.target.checked) {
      (this.selectedMeetingTypes as IObservableArray).remove(meetingTypeId)
    } else {
      if (this.selectedMeetingTypes.indexOf(meetingTypeId) === -1) {
        this.selectedMeetingTypes.push(meetingTypeId)
      }
    }
  }

  private toggleAreaFilter = (ev: ChangeEvent<HTMLInputElement>, areaId: number) => {
    if (!ev.target.checked) {
      (this.selectedAreas as IObservableArray).remove(areaId)
    } else {
      if (this.selectedAreas.indexOf(areaId) === -1) {
        this.selectedAreas.push(areaId)
      }
    }
  }

  private setTab(tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(window.location.pathname, { ...this.history.location.state, activeTab: this.activeTab })
    }
  }

  componentDidMount(): void {
    super.componentDidMount()

    this.activeTab = _.get(this.history.location.state, 'activeTab') || TABS.calendar
  }

  renderContentHeader(): React.ReactNode | null {
    return (
      <>
        <h1>Calendar</h1>
        {
          this.activeTab === TABS.calendar
            ? <RequirePermission permission={Permission.ManageCalendar}>
              {() => <ul className="content-header-actions">
                <li>
                  <Button color="primary" onClick={() => this.calendarRef.current && this.calendarRef.current.showCreateEventModal()}><i className="fa fa-plus-circle" /> Add New Event</Button>
                </li>
              </ul>}
            </RequirePermission>
            : null
        }
        {
          this.activeTab === TABS.recurringEvent
            ? <ul className="content-header-actions">

              <RequireRole role={UserRole.Admin}>
                {() =>
                  <li>
                    <Button color="primary" onClick={() => this.recurringEventRef.current && this.recurringEventRef.current.showCreateEventModal()}><i className="fa fa-plus-circle" /> Add New Event</Button>
                  </li>
                }
              </RequireRole>
            </ul>
            : null
        }
      </>

    )
  }

  render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <>
      <div className="card">
        <div className="card-header tabs">
          <Nav tabs className="card-header-tabs">
            <NavItem active={this.activeTab === TABS.calendar}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.calendar)
                }}
              >Calendar</NavLink>
            </NavItem>

            <RequireRole role={UserRole.Admin}>
              {() => <>
                <NavItem active={this.activeTab === TABS.recurringEvent}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.recurringEvent)
                    }}
                  >Recurring Event</NavLink>
                </NavItem>
              </>}
            </RequireRole>
          </Nav>
        </div>
        <div className="card-body">
          <TabContent activeTab={this.activeTab}>
            <LazyTabPane tabId={TABS.calendar} activeTab={this.activeTab}>
              <div className={'calendar-container'}>
                <div className={'calendar-filters'}>
                  <h5 className={'text-muted'}>Meeting Types</h5>
                  <MeetingTypeFilters selectedMeetingTypes={this.selectedMeetingTypes} onClicked={this.toggleMeetingTypeFilter} />
                  <br />
                  <h5 className={'text-muted'}>Areas</h5>
                  <AreaFilters selectedAreas={this.selectedAreas} onClicked={this.toggleAreaFilter} />
                </div>

                <div className={'calendar-view'}>
                  <div className={'card'}>
                    <div className={'card-body'}>
                      <Calendar
                        ref={this.calendarRef}
                        visibleAreas={this.selectedAreas}
                        visibleMeetingTypes={this.selectedMeetingTypes}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </LazyTabPane>
            <LazyTabPane tabId={TABS.recurringEvent} activeTab={this.activeTab}>
              <RecurringEventsView ref={this.recurringEventRef}/>
            </LazyTabPane>
          </TabContent>
        </div>
      </div>
    </>
  }
}
