import * as React from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import { createLazyResource } from "../../common/Util"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import MemberPosition from "../../models/MemberPosition"
import * as _ from "lodash"
import LazyResourcePanel from "../LazyResourcePanel"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { Link } from "react-router-dom"
import { logException, modelToCamelCase, publicPath, safeNull, setUserTimezone } from "../../common/Util"


type Props = {
  chapterId: number
}

type RosterData = {
  boardMembers: MemberPosition[]
  chairMembers: MemberPosition[]
}

@observer
export default class ChapterRoster extends React.Component<Props> {
  @observable private rosterData = createLazyResource<RosterData>(() => {
//     return ApiClient.query(
//       `
// chapter {
//   memberPositions {
//     id
//     position {
//       name
//       displayOrder
//       displayType
//     }
//     member {
//       id
//       fullName
//       profileImageUrl
//     }
//   }
// }
//       `,
//       {
//         where: [{ id: this.props.chapterId }]
//       }
//     )
return ApiClient.getInstance()
.get(route(ApiRoutes.chapters.getMemberPositions, { id: this.props.chapterId }))
  }, response => (
  {
    boardMembers: _.orderBy(_.filter(response.data.member_positions, mp => (mp.position.display_type || mp.position.type) === 'board').map((b: {}) => new MemberPosition().init(modelToCamelCase(b))), 'position.displayOrder'),
    chairMembers: _.orderBy(_.filter(response.data.member_positions, mp => (mp.position.display_type || mp.position.type) === 'chair').map((b: {}) => new MemberPosition().init(modelToCamelCase(b))), 'position.displayOrder'), 
  }))

  render (): React.ReactNode {
    return <>
      <LazyResourcePanel resource={this.rosterData} hideWhenEmpty={false}>
        {rosterData => <>
          <div className="row">
            <div className="col-md-6">
              <h5>Board Members</h5>
              {
                rosterData.boardMembers.length
                  ? rosterData.boardMembers.map(bm => <p key={`bm-${bm.id}`}><b>{bm.position.name}</b><br/><Link to={route(Routes.network.member, { memberId: bm.member.id })}>{bm.member.fullName}</Link></p>)
                  : 'There are no board members assigned to this chapter'
              }
            </div>

            <div className="col-md-6">
              <h5>Chair Members</h5>
              {
                rosterData.chairMembers.length
                  ? rosterData.chairMembers.map(bm => <p key={`cm-${bm.id}`}><b>{bm.position.name}</b><br/><Link to={route(Routes.network.member, { memberId: bm.member.id })}>{bm.member.fullName}</Link></p>)
                  : 'There are no chair members assigned to this chapter'
              }
            </div>
          </div>
        </>}
      </LazyResourcePanel>
    </>
  }
}
