import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Member from "./Member"
import ProgramPosition from "./ProgramPosition"
import Event from "./Event"

export default class ProgramPositionMember extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment

  memberId: number
  eventId: number
  programPositionId: number

  member: Member
  event: Event
  programPosition: ProgramPosition

  setMember (m?: {}) {
    return m ? new Member().init(m) : null
  }

  setEvent (e?: {}) {
    return e ? new Event().init(e) : null
  }

  setProgramPosition (pp?: {}) {
    return pp ? new ProgramPosition().init(pp) : null
  }

  getCasts () {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      memberId: 'number',
      eventId: 'number',
      programPositionId: 'number',
    }
  }
}