import BaseModel from "./BaseModel"
import Chapter from "./Chapter"

export default class SupportAreaChapter extends BaseModel {
  id: number
  chapter: Chapter

  setChapter (c: {}) {
    return c ? new Chapter(c) : null
  }
}