import * as React from "react"
import { observer } from "mobx-react"
import { LinkContainer } from "react-router-bootstrap"
import * as H from "history"
import { Button } from "reactstrap"
import BaseView from "../BaseView"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { ApiTableViewAdapter } from "../../components/table-view/ApiTableViewAdapter"
import ApiClient, { QueryWhereClause } from "../../api/ApiClient"
import { ManagedChooseableColumn } from "../../components/table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "../../components/table-view/ManagedTableView";
import DateFilter from '../../components/table-view/filters/DateFilter'
import RecurringEvent from "../../models/RecurringEvent"
import axios, { CancelTokenSource } from "axios"
import { autorun, observable } from "mobx"
import { EditRecurringEventModal } from "../../components/EditRecurringEventModal"
import { CreateRecurringEventModal } from "../../components/CreateRecurringEventModal"

type Props = {
}

@observer
export default class RecurringEventsView extends BaseView<Props> {

  @observable private showEditForm = false
  @observable private selectedEvent: RecurringEvent
  @observable private events: RecurringEvent[]
  @observable private loading = false
  @observable private editEventId?: number
  @observable private isCreateRecurringEventModalVisible = false


  componentDidMount(): void {
    // need to fetch the initial range of events
    autorun(() => this.fetchEvents())
  }

  showCreateEventModal = () => {
    this.isCreateRecurringEventModalVisible = true
  }

  private fetchEvents = () => {
    this.loading = true
    this.events = []

    return ApiClient.query(
      `\n      recurringMeetings {\n        *\n      }\n `,
      {
        where: [],
        offset: 0,
        limit: 50,
        returnTotal: true,
        order: [{ "id": "recurring_starts_on", "desc": true }]
      }
    )
      .then(response => {
        this.events = response.data.recurringMeetings.map((e: {}) => new RecurringEvent().init(e))
        this.loading = false
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          this.loading = false
        }
      })
  }



  renderContentHeader(): React.ReactNode | null {
    return (
      <>
        <h1>Recurring Events</h1>
      </>
    )
  }

  renderContentBody(): React.ReactNode {
    return this.renderTable()
  }

  private renderTable = () => {

    return <> <table className="table table-striped">
      <tbody>
        {this.events ?
          this.events.map(recurringEvent => <tr key={recurringEvent.id}>
            <td style={{ verticalAlign: 'middle' }}>
              <div className="d-flex align-items-center">
                <div>
                  <a onClick={ev => {
                    ev.preventDefault()
                    this.showEditForm = true
                    this.selectedEvent = recurringEvent
                    this.editEventId = recurringEvent.id
                  }} href="">{recurringEvent.title}</a>
                </div>
              </div>
            </td>
          </tr>) : null
        }
      </tbody>
    </table>

      {
        this.loading
          ? <div className={'calendar-loading-overlay'} style={{display: 'table', margin: '0 auto'}}>
            <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true" />
          </div>
          : null
      }

      <CreateRecurringEventModal
        isOpen={this.isCreateRecurringEventModalVisible}
        toggle={() => this.isCreateRecurringEventModalVisible = !this.isCreateRecurringEventModalVisible}
        onCancel={() => this.isCreateRecurringEventModalVisible = false}
        onSaved={() => {
          this.isCreateRecurringEventModalVisible = false
          this.fetchEvents()
        }}
      />

      <EditRecurringEventModal
        eventId={this.editEventId || 0}
        isOpen={!!this.editEventId}
        toggle={() => this.editEventId = undefined}
        onCancel={() => this.editEventId = undefined}
        onSaved={() => {
          this.editEventId = undefined
          this.fetchEvents()
        }}
        onDelete={()=>{
          this.editEventId = undefined
          this.fetchEvents()
        }}
        />
    </>
  }

}
