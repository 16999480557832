import BaseModel from "./BaseModel"
import Member from "./Member"
import Position from './Position'

export default class MemberPosition extends BaseModel {
  id: number
  member: Member
  position: Position

  constructor (data?: {}) {
    super()

    data && this.init(data)
  }

  getCasts (): {} {
    return {
      id: 'number',
    }
  }

  setMember (member: {}) {
    return new Member(member)
  }

  setPosition (position: {}) {
    return (new Position().init(position))
  }
}