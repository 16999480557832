import { FieldType, ResourceSpec } from "../ReportDataSource"
import { ChapterResource } from "./ChapterResource"
import { ContactMethodResource } from "./ContactMethodResource"
import { PhoneNumberResource } from "./PhoneNumberResource"
import Business from "../../models/Business"

export const BusinessResource: ResourceSpec = {
  collectionResourceName: "businesses",
  modelClass: Business,

  fields: [
    {
      name: 'id',
      type: FieldType.Number,
    },
    {
      name: 'name',
      type: FieldType.String,
    },
    {
      name: 'phoneNumber',
      type: FieldType.String,
    },
    {
      name: 'emailAddress',
      type: FieldType.String,
    },
  ],
  relationships: [
  ]
}
