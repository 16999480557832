import BaseModel from "./BaseModel"
import { Moment } from 'moment-timezone'
import Member from './Member'

export default class SponsorCredit extends BaseModel {
  id: number
  createdAt: Moment
  memberId: number
  sponsoredMemberId: number
  appliedAt: Moment | null

  member: Member
  sponsoredMember: Member

  getCasts (): {} {
    return {
      createdAt: 'datetime',
      appliedAt: 'datetime',
      id: 'number',
      memberId: 'number',
      sponsoredMemberId: 'number',
    }
  }

  setMember (m?: {}) {
    return m ? new Member().init(m) : null
  }

  setSponsoredMember (m?: {}) {
    return m ? new Member().init(m) : null
  }
}
