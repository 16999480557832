import BaseView from "../BaseView"
import * as React from "react"
import { observer } from "mobx-react"
import MobileStoreButton from "../../components/MobileStoreButton"
import { publicPath } from '../../common/Util'

@observer
export default class NetworkSupportView extends BaseView {
  componentDidMount (): void {
    super.componentDidMount()
  }

  renderContentHeader () {
    return (
      <>
        <h1>Support</h1>
      </>
    )
  }

  render () {
    return <>
      <div className="card">
        <div className="card-header">
          <h6 style={{ margin: 0 }}>Contact Us</h6>
        </div>
        <div className="card-body">
          Corporate Office Toll Free: <a href="tel:800-255-3847">800-255-3847</a>
          <br/>
          Corporate Office Local: <a href="tel:480-264-4600">480-264-4600</a>
          <br/>
          Fax: 480-264-4644
          <br/>
          <br/>
          LeTip International, Inc.
          <br/>
          7895 W Sunset Road
          <br/>
          Suite 101
          <br/>
          Las Vegas, NV 89113
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <h6 style={{ margin: 0 }}>Helpful Links</h6>
        </div>
        <div className="card-body">
          <a target="_blank" href="https://letip.com/contact/">General Inquiries</a>
          <br/>
          <a target="_blank" href="https://letip.com/executive-staff/">LeTip Executive Staff</a>
          <br/>
          <a target="_blank" href="https://letip.com/corporate-staff/">LeTip Corporate Staff</a>
          <br/>
          <a target="_blank" href="https://letip.com/local-support-team/">Nationwide Area Support Team Members</a>
          <br/>
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.letipwired.mobile">LeTip Wired&trade; Mobile App on Google Play</a>
          <br/>
          <a target="_blank" href="https://letip.com/blog/category/blogs/">Letip’s Blog</a>
          <br/>
          <a target="_blank" href="https://letipwebstore.mybigcommerce.com/">Webstore</a>

          <div className="mt-4 text-center">
            <h2>Get LeTip Wired Mobile</h2>
            <MobileStoreButton
              url="https://play.google.com/store/apps/details?id=com.letipwired.mobile&hl=en_US"
              store="android"
            />
            &nbsp;
            &nbsp;
            &nbsp;
            <MobileStoreButton
              url="https://itunes.apple.com/us/app/letip-wired/id1434770432?mt=8"
              store="ios"
            />
          </div>
        </div>
      </div>
    </>
  }
}
