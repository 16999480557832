import CsvTransformer from "./CsvTransformer"
import moment from "moment-timezone"
import SentSystemMessage from "../SentSystemMessage"

export default class SentSystemMessagesCsvTransformer extends CsvTransformer<SentSystemMessage> {
  get filename () {
    return `system-messages-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'id',
      valueTransformer: row => String(row.id),
    })

    this.addColumn({
      header: 'Created',
      valueTransformer: row => row.createdAt.format('MM/DD/YYYY HH:mm'),
    })

    this.addColumn({
      header: 'Updated',
      valueTransformer: row => row.updatedAt.format('MM/DD/YYYY HH:mm'),
    })

    this.addColumn({
      header: 'Recipient',
      valueTransformer: row => row.recipient,
    })

    this.addColumn({
      header: 'Subject',
      valueTransformer: row => row.subject,
    })

    this.addColumn({
      header: 'Status',
      valueTransformer: row => row.status,
    })

    this.addColumn({
      header: 'Member',
      valueTransformer: row => row.members.length ? row.members[0].fullName : '',
    })

    this.addColumn({
      header: 'Channel',
      valueTransformer: row => row.channel,
    })

    this.addColumn({
      header: 'Platform',
      valueTransformer: row => row.platform,
    })

    this.addColumn({
      header: 'Template',
      valueTransformer: row => row.systemMessageTemplate.templateName,
    })
  }
}
