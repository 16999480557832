import LazyResource from "./LazyResource"
import ILazyResource from "./ILazyResource"

export default class DerivedLazyResource<U, T> implements ILazyResource<U> {
  private readonly lazyResource: LazyResource<T>
  private readonly computer: (resource: T) => U | undefined

  get error () {
    return this.lazyResource.error
  }

  get loaded () {
    return this.lazyResource.loaded
  }

  get loading () {
    return this.lazyResource.loading
  }

  get current (): U | undefined {
    return this.lazyResource.current ? this.computer(this.lazyResource.current) : undefined
  }

  get invalid () {
    return this.lazyResource.isInvalid
  }

  constructor (lazyResource: LazyResource<T>, computer: (resource: T) => U | undefined) {
    this.lazyResource = lazyResource
    this.computer = computer
  }

  invalidate = () => {
    this.lazyResource.invalidate()
  }

  refresh = (force: boolean = false): Promise<T> => {
    return this.lazyResource.refresh(force)
  }

  get (): Promise<U> {
    return new Promise<U>((resolve, reject) => {
      this.refresh().then(resource => {
        if (this.error) {
          reject(this.error)
        } else {
          resolve(this.current)
        }
      })
    })
  }
}