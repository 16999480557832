import { observer } from "mobx-react"
import * as React from "react"
import { IFileBrowserNode, NodeType } from "./FileBrowser"
import Util from "../../common/Util"

type FileBrowserRowProps = {
  node: IFileBrowserNode
  onClick: (node: IFileBrowserNode) => void
  onDeleteClicked: (node: IFileBrowserNode) => void
  onEditClicked: (node: IFileBrowserNode) => void
  showFullFolderPath?: boolean
}

@observer
export default class FileBrowserRow extends React.Component<FileBrowserRowProps> {
  private getIcon = (node: IFileBrowserNode) => {
    return node.type === NodeType.File
      ? 'fa fa-file-o'
      : 'fa fa-folder-o'
  }

  private formatFolderPath = (node: IFileBrowserNode) => {
    if (this.props.showFullFolderPath) {
      const path: string[] = []
      let cur: IFileBrowserNode | undefined = node
      while (cur) {
        path.push(cur.name)
        cur = cur.parent
      }

      return path.reverse().join('/')
    } else {
      return node.name
    }
  }

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    const node = this.props.node

    return <tr>
      <td style={{
        width: 1,
        whiteSpace: 'nowrap',
      }}>
        <div className="file-browser-file-actions">
          <a href="" onClick={(ev) => {
            ev.preventDefault()
            this.props.onEditClicked(node)
          }}><i className="fa fa-edit"/></a>
          <a className="text-danger" href="" onClick={(ev) => {
            ev.preventDefault()
            this.props.onDeleteClicked(node)
          }}><i className="fa fa-trash"/></a>
        </div>
      </td>
      <td className="file-browser-name">
        {
          node.type === NodeType.File
            ? <a
              className="file-browser-file"
              href=""
              onClick={ev => {
                ev.preventDefault()
                this.props.onClick(this.props.node)
              }}>
              <div className="file-browser-item-left-accessory"/>
              <i className={this.getIcon(node)}/>
              <div className="file-browser-item-name">
                {node.name}
              </div>
            </a>
            : <a
              className="file-browser-folder"
              href=""
              onClick={ev => {
                ev.preventDefault()
                this.props.onClick(this.props.node)
              }}>
              <div className="file-browser-item-left-accessory"><i className="fa fa-angle-right"/></div>
              <i className={this.getIcon(node)}/>
              <div className="file-browser-item-name">
                {this.formatFolderPath(node)}
              </div>
            </a>
        }

        <div className="flex-fill">
        </div>
      </td>
      <td className="text-right pr-2" style={{ whiteSpace: 'nowrap' }}>
        {node.size ? Util.formatBytes(node.size) : '---'}
      </td>
    </tr>
  }
}