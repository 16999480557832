import CsvTransformer from "./CsvTransformer"
import moment from "moment-timezone"
import { MemberLossReportData } from '../../components/system/reporting/MemberLossReport'
import { safeNull } from '../../common/Util'

export default class MemberLossCsvTransformer extends CsvTransformer<MemberLossReportData> {
  get filename () {
    return `member-loss-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    //member: {
    //     id: number
    //     fullName: string
    //     joinDate: string | null
    //     status: string
    //     badgeColor: string | null
    //   }
    //   membership: {
    //     id: number
    //     endsAt: string
    //   }
    //   category: {
    //     id: number
    //     name: string
    //   } | null
    //   chapter: {
    //     id: number
    //     name: string
    //   } | null


    this.addColumn({
      header: 'Member ID',
      valueTransformer: row => row.member.id,
    })

    this.addColumn({
      header: 'Member Name',
      valueTransformer: row => row.member.fullName,
    })

    this.addColumn({
      header: 'Category',
      valueTransformer: row => safeNull(() => row.category!.name) || '',
    })

    this.addColumn({
      header: 'Chapter',
      valueTransformer: row => safeNull(() => row.chapter!.name) || '',
    })

    this.addColumn({
      header: 'Badge Color',
      valueTransformer: row => row.member.badgeColor,
    })

    this.addColumn({
      header: 'Join Date',
      valueTransformer: row => row.member.joinDate,
    })

    this.addColumn({
      header: 'Termination Date',
      valueTransformer: row => moment(row.membership.endsAt, 'YYYY-MM-DD').format('MM/DD/YYYY'),
    })

    this.addColumn({
      header: 'Status',
      valueTransformer: row => row.member.status,
    })
  }
}
