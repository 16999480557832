import BaseModel from "./BaseModel"

export default class MembershipFees extends BaseModel {
  membershipFee: number
  renewalFee: number
  lateFee: number
  taxRate: number
  isLate: boolean

  getCurrentRenewalFee = () => {
    return this.renewalFee + (this.isLate ? this.lateFee : 0)
  }

  getCasts() {
    return {
      membershipFee: 'number',
      renewalFee: 'number',
      lateFee: 'number',
      taxRate: 'number',
    }
  }

  constructor (data = {}) {
    super();
    this.init(data);
  }
}