import BaseView from "../BaseView"
import { observer } from "mobx-react"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import * as React from "react"
import { observable } from "mobx"
import { Link } from "react-router-dom"
import { ApiTableViewAdapter } from "../../components/table-view/ApiTableViewAdapter"
import { QueryWhereClause } from "../../api/ApiClient"
import Member from "../../models/Member"
import { formatCurrency, formatDateLocal } from "../../common/Util"
import Invoice from "../../models/Invoice"
import { InvoiceDetailModal } from "../../components/InvoiceDetailModal"
import { ManagedChooseableColumn } from "../../components/table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "../../components/table-view/ManagedTableView";
import InvoicesCsvTransformer from "../../models/renderers/InvoicesCsvTransformer";
import DateFilter from '../../components/table-view/filters/DateFilter'
import SelectFilter, { SelectFilterTargetType } from '../../components/table-view/filters/SelectFilter'
import AsyncSelectFilter, { AsyncSelectFilterTargetType } from '../../components/table-view/filters/AsyncSelectFilter'
import { loadChapterOptionsForAdmin, loadMemberOptions } from '../../api/AsyncHelpers'
import TextFilter, { TextFilterOperator, TextFilterTargetType } from '../../components/table-view/filters/TextFilter'

@observer
export default class InvoicesView extends BaseView {
  @observable private showInvoiceDetailModal = false
  @observable private detailInvoiceId?: number

  renderContentHeader () {
    return <>
      <h1>Invoices</h1>
    </>
  }

  private availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'id',
      column: {
        title: 'ID',
        accessor: 'id',
        sortable: true,
        renderItem: (value, item: Invoice) => `#${item.invoiceNumber}`,
      },
      fixed: true,
    },
    {
      id: 'createdAt',
      column: {
        title: 'Date',
        accessor: 'createdAt',
        sortable: true,
        renderItem: (value, item) => <a href="" onClick={ev => {
          ev.preventDefault()
          this.detailInvoiceId = item.id
          this.showInvoiceDetailModal = true
        }}>{formatDateLocal(value, 'MM/DD/YYYY hh:mm a')}</a>
      },
      default: true,
    },
    {
      id: 'description',
      column: {
        title: 'Description',
        accessor: 'description',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'total',
      column: {
        title: 'Total',
        accessor: 'total',
        sortable: true,
        renderItem: value => formatCurrency(value, true),
        headerClassName: 'justify-content-end',
        itemClassName: 'text-right',
      },
      default: true,
    },
    {
      id: 'amountDue',
      column: {
        title: 'Amount Due',
        accessor: 'amountDue',
        sortable: true,
        renderItem: value => formatCurrency(value, true),
        headerClassName: 'justify-content-end',
        itemClassName: 'text-right',
      },
      default: true,
    },
    {
      id: 'status',
      column: {
        title: 'Status',
        accessor: 'status',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'member',
      column: {
        title: 'Member',
        accessor: 'member',
        sortKey: 'member.fullName',
        sortable: true,
        renderItem: value => value ? <Link to={route(Routes.system.members.show, { id: value.id })}>{value.fullName}</Link> : '---'
      },
      default: true,
    },
    {
      id: 'chapter',
      column: {
        title: 'Chapter',
        accessor: 'chapter',
        sortKey: 'chapter.name',
        sortable: true,
        renderItem: value => value ? <Link to={route(Routes.system.chapters.show, { id: value.id })}>{value.name}</Link> : '---'
      },
      default: true,
    },
  ]

  private invoicesAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      return {
        query: `
      invoices {
        *

        member {
          *
        }

        chapter {
          *
        }
      }
    `,
        where: where,
      }
    },
    'invoices',
    Invoice
  )

  private tableViewRef = React.createRef<ManagedTableView>()
  private filters = [
    new DateFilter('createdAt', 'createdAt', 'Invoice Date'),
    new SelectFilter('invoiceType', SelectFilterTargetType.Field, 'invoiceType', 'Type', [
      'Membership',
      'Renewal',
    ].map(s => ({ value: s, label: s }))),
    new AsyncSelectFilter('chapter', AsyncSelectFilterTargetType.Field, 'chapterId', 'Chapter', loadChapterOptionsForAdmin),
    new AsyncSelectFilter('member', AsyncSelectFilterTargetType.Field, 'memberId', 'Member', loadMemberOptions()),
    new TextFilter('description', TextFilterTargetType.Field, 'description', 'Description', TextFilterOperator.Contains),
  ]

  renderContentBody () {
    return (
      <>
        <ManagedTableView
          stateKey="InvoicesView"
          hideItemCount={true}
          ref={this.tableViewRef}
          adapter={this.invoicesAdapter}
          availableColumns={this.availableColumns}
          showSearch={true}
          searchLabel="Find An Invoice"
          defaultSort={{ sortKey: 'createdAt', descending: true }}
          exportTransformer={InvoicesCsvTransformer}
          filters={this.filters}
        />
        {
          this.detailInvoiceId
            ? <InvoiceDetailModal
              isOpen={this.showInvoiceDetailModal}
              toggle={() => this.showInvoiceDetailModal = false}
              invoiceId={this.detailInvoiceId}
              onClosed={() => {
                this.detailInvoiceId = undefined
                this.tableViewRef.current && this.tableViewRef.current.fetchData()
              }}
            />
            : null
        }
      </>
    )
  }
}
