import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"

export default class FileUpload extends BaseModel {
  static CODE_MEMBER_DOCUMENT = 'member_document';
  static CODE_CHAPTER_DOCUMENT = 'chapter_document';

  id: number
  createdAt: Moment
  updatedAt: Moment
  mimeType: string
  filename: string
  fileSizeBytes: number
  url: string

  constructor (data = {}) {
    super();
    this.init(data);
  }

  getCasts () {
    return {
      createdAt: 'datetime',
      updatedAt: 'datetime'
    }
  }
}