import * as React from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import Member from "../models/Member"
import axios, { CancelTokenSource } from "axios"
import * as _ from "lodash"
import ApiClient from "../api/ApiClient"
import Util, { modelToCamelCase, formatDate } from "../common/Util"
import Config from "../common/Config"
import VisibilitySensor from 'react-visibility-sensor'
import { CircleLoader } from "react-spinners"
import * as AsyncHelpers from "../api/AsyncHelpers"
import { renderAsyncSelectInput } from "../forms/FormInputs"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import classNames from 'classnames'
import { createBrowserHistory } from "history"
import BadgeView from "./badge/badgeview"


const FETCH_SIZE = 100

type MemberCardSearchFilters = {
  chapterId?: number,
  categoryId?: number,
  area?: { id: number, name: string }
  region?: { id: number, name: string }
}

type Props = {
  defaultChapter?: { id: number, name: string }
  defaultCategory?: { id: number, name: string }
  defaultArea?: {id:number, name: string}
  defaultRegion?: {id:number, name:string}
  defaultPosition?: {id:number, name:string}
  showInactive: boolean
  cardActionsComponent?: (member: Member) => React.ReactNode
  onCardClick?: (member: Member) => void
  filters?: MemberCardSearchFilters
}

@observer
export default class MemberCardSearch extends React.Component<Props> {
  static defaultProps = {
    showInactive: false,
  }

  @observable private members: Member[] = []
  @observable private loading = false
  @observable private loaded = false
  @observable private error = false

  @observable private searchFormState = new FormState({
    search: '',
    zipCode:'',
    state:'',
    city:'',
    position:'',
    badge:'',
    chapterId: this.props.defaultChapter ? this.props.defaultChapter.id : undefined,
    categoryId: this.props.defaultCategory ? this.props.defaultCategory.id : undefined,
    areaId: this.props.defaultArea ? this.props.defaultArea.id : undefined,
    regionId: this.props.defaultRegion ? this.props.defaultRegion.id : undefined,


  })
  @observable private searchFormErrors = new ErrorBag()

  private fetchDataCancelToken?: CancelTokenSource
  private canSeeBottom = false
  private totalRecords = 0
  private currentPage = 0
  private fetchPage = 0

  @observable private filteredArea?: { id: number, name: string }
  @observable private filteredRegion?: { id: number, name: string }
  @observable private filteredChapter?: { id: number, name: string }
  @observable private filteredCategory?: { id: number, name: string }

  private history = createBrowserHistory()

  componentDidMount(): void {
    this.fetchData()
    this.fetchData.flush()
  }

  private fetchData = _.debounce(() => {
    if (this.fetchDataCancelToken) {
      this.fetchDataCancelToken.cancel()
    }
    this.fetchDataCancelToken = axios.CancelToken.source()

    if (this.searchFormState.isDirty) {
      this.searchFormState.clearDirty()
      this.currentPage = 0
      this.fetchPage = 0
    }

    this.loading = true

    const where = []

    if (this.searchFormState.get('chapterId')) {
      where.push({ chapterId: this.searchFormState.get('chapterId') })
    }

    if (this.searchFormState.get('categoryId')) {
      where.push({ categoryId: this.searchFormState.get('categoryId') })
    }

    if (this.searchFormState.get('areaId')) {
      where.push({
        _scope: 'area',
        value: this.searchFormState.get('areaId'),
      })
    }

    if (this.searchFormState.get('regionId')) {
      where.push({
        _scope: 'region',
        value: this.searchFormState.get('regionId'),
      })
    }

    if (this.filteredArea) {
      where.push({
        _scope: 'area',
        value: this.filteredArea.id,
      })
    }

    if (this.filteredRegion) {
      where.push({
        _scope: 'region',
        value: this.filteredRegion.id,
      })
    }

    if (!this.props.showInactive) {
      where.push({ _scope: 'active' })
    }

    if (this.searchFormState.get('search') && this.searchFormState.get('search').length) {
      where.push({ _scope: 'search', value: this.searchFormState.get('search') })
    }

    if (this.searchFormState.get('zipCode') && this.searchFormState.get('zipCode').length) {
      where.push({ _scope: 'businessZipCode', value: this.searchFormState.get('zipCode') })
    }

    if (this.searchFormState.get('state') && this.searchFormState.get('state').length) {
      where.push({ _scope: 'businessState', value: this.searchFormState.get('state') })
    }

    if (this.searchFormState.get('city') && this.searchFormState.get('city').length) {
      where.push({ _scope: 'businessCity', value: this.searchFormState.get('city') })
    }

    if (this.searchFormState.get('badge') && this.searchFormState.get('badge').length) {
      where.push({ _scope: 'badge', value: this.searchFormState.get('badge') })
    }

    if (this.searchFormState.get('position')) {
      where.push({ _scope: 'position', value: this.searchFormState.get('position') })
    }


    ApiClient.query(`
members {
  *
  chapter {
    *
  }
  category {
    *
  }
  business {
    *
  }
}
    `,
      {
        where: where,
        order: ['lastName'],
        offset: this.fetchPage * FETCH_SIZE,
        limit: FETCH_SIZE,
        returnTotal: true,
      })
      .then(response => {
        const receivedPage = response.data._meta.offset / FETCH_SIZE

        if (receivedPage === this.fetchPage) {
          if (receivedPage === 0) {
            this.members = response.data.members.map((r: {}) => new Member(modelToCamelCase(r)))
          } else {
            this.members = this.members.concat(response.data.members.map((r: {}) => new Member(modelToCamelCase(r))))
          }

          this.currentPage = receivedPage
          this.fetchPage = this.currentPage + 1
        }

        this.loaded = true
        this.totalRecords = response.data._meta.total

        setTimeout(() => {
          if (this.canSeeBottom && this.members.length < this.totalRecords) {
            this.fetchData()
            this.fetchData.flush()
          }
        }, 200)
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          this.error = true
        }
      })
      .then(() => this.loading = false)
  }, Config.DEBOUNCE_TIME_MS)

  private handleVisibilityChange = (isVisible: boolean) => {
    this.canSeeBottom = isVisible

    if (this.canSeeBottom) {
      if (this.members.length < this.totalRecords) {
        this.fetchData()
        this.fetchData.flush()
      }
    }
  }

  render(): React.ReactNode {
    return <>
      <form onSubmit={ev => ev.preventDefault()}>
        <div className="member-card-search-header row">
          <div className="member-card-search-search col-md-4 col-sm-12">
            <label className="member-card-search-search-label">Search By Name or Company</label>
            <div className="member-card-search-search-input">
              <i className="fa fa-search" />
              <input
                className="form-control"
                placeholder="Enter search"
                type="text"
                name="search"
                value={this.searchFormState.get('search')}
                onChange={ev => {
                  this.fetchData()
                  this.searchFormState.onChange(ev)
                }}
                onKeyDown={ev => ev.key === 'Enter' && this.fetchData.flush()} />
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <label className="member-card-search-search-label">
              Search By Chapter
            </label>
            {renderAsyncSelectInput(this.searchFormState, this.searchFormErrors, {
              name: 'chapterId',
              defaultValue: this.props.defaultChapter ? { value: this.props.defaultChapter.id, label: this.props.defaultChapter.name } : undefined,
              onChange: () => {
                this.fetchData()
                this.fetchData.flush()
              },
              loadOptions: AsyncHelpers.loadChapterOptions,
            })}
          </div>
          <div className="col-md-4 col-sm-12">
            <label className="member-card-search-search-label">
              Search By Category
            </label>
            {renderAsyncSelectInput(this.searchFormState, this.searchFormErrors, {
              name: 'categoryId',
              defaultValue: this.props.defaultCategory ? { value: this.props.defaultCategory.id, label: this.props.defaultCategory.name } : undefined,
              value: this.searchFormState.get('categoryId'),
              onChange: () => {
                this.fetchData()
                this.fetchData.flush()
              },
              loadOptions: AsyncHelpers.loadCategoryOptions(),
            })}
          </div>
        </div>
        <div className="member-card-search-header row">
          <div className="col-md-4 col-sm-12">
            <label className="member-card-search-search-label">
              Search By Area
            </label>
            {renderAsyncSelectInput(this.searchFormState, this.searchFormErrors, {
              name: 'areaId',
              defaultValue: this.props.defaultArea ? { value: this.props.defaultArea.id, label: this.props.defaultArea.name } : undefined,
              value: this.searchFormState.get('areaId'),
              onChange: () => {
                this.fetchData()
                this.fetchData.flush()
              },
              loadOptions: AsyncHelpers.loadAreaOptions,
            })}
          </div>

          <div className="col-md-4 col-sm-12">
            <label className="member-card-search-search-label">
              Search By Region
            </label>
            {renderAsyncSelectInput(this.searchFormState, this.searchFormErrors, {
              name: 'regionId',
              defaultValue: this.props.defaultRegion ? { value: this.props.defaultRegion.id, label: this.props.defaultRegion.name } : undefined,
              value: this.searchFormState.get('regionId'),
              onChange: () => {
                this.fetchData()
                this.fetchData.flush()
              },
              loadOptions: AsyncHelpers.loadRegionOptions,
            })}
          </div>

          <div className="col-md-4 col-sm-12">
            <label className="member-card-search-search-label">
              Search By Position
            </label>
            {renderAsyncSelectInput(this.searchFormState, this.searchFormErrors, {
              name: 'position',
              defaultValue: this.props.defaultPosition ? { value: this.props.defaultPosition.id, label: this.props.defaultPosition.name } : undefined,
              value: this.searchFormState.get('position'),
              onChange: () => {
                this.fetchData()
                this.fetchData.flush()
              },
              loadOptions: AsyncHelpers.loadPositionOptions,
            })}
          </div>

        </div>

        <div className="member-card-search-header row">
          <div className="member-card-search-search col-md-4 col-sm-12">
            <label className="member-card-search-search-label">Search By State</label>
            <div className="member-card-search-search-input">
              <i className="fa fa-search" />
              <input
                className="form-control"
                placeholder="Enter State"
                type="text"
                name="state"
                value={this.searchFormState.get('state')}
                onChange={ev => {
                  this.fetchData()
                  this.searchFormState.onChange(ev)
                }}
                onKeyDown={ev => ev.key === 'Enter' && this.fetchData.flush()} />
            </div>
          </div>

          <div className="member-card-search-search col-md-4 col-sm-12">
            <label className="member-card-search-search-label">Search By City</label>
            <div className="member-card-search-search-input">
              <i className="fa fa-search" />
              <input
                className="form-control"
                placeholder="Enter City"
                type="text"
                name="city"
                value={this.searchFormState.get('city')}
                onChange={ev => {
                  this.fetchData()
                  this.searchFormState.onChange(ev)
                }}
                onKeyDown={ev => ev.key === 'Enter' && this.fetchData.flush()} />
            </div>
          </div>

          <div className="member-card-search-search col-md-4 col-sm-12">
            <label className="member-card-search-search-label">Search By ZipCode</label>
            <div className="member-card-search-search-input">
              <i className="fa fa-search" />
              <input
                className="form-control"
                placeholder="Enter ZipCode"
                type="text"
                name="zipCode"
                value={this.searchFormState.get('zipCode')}
                onChange={ev => {
                  this.fetchData()
                  this.searchFormState.onChange(ev)
                }}
                onKeyDown={ev => ev.key === 'Enter' && this.fetchData.flush()} />
            </div>
          </div>
        </div>

        <div className="member-card-search-header row">
        <div className="member-card-search-search col-md-4 col-sm-12">
            <label className="member-card-search-search-label">Search By Badge</label>
            <div className="member-card-search-search-input">
              <i className="fa fa-search" />
              <input
                className="form-control"
                placeholder="Enter Badge"
                type="text"
                name="badge"
                value={this.searchFormState.get('badge')}
                onChange={ev => {
                  this.fetchData()
                  this.searchFormState.onChange(ev)
                }}
                onKeyDown={ev => ev.key === 'Enter' && this.fetchData.flush()} />
            </div>
          </div>
        </div>

      </form>

      {this.error && <div className="alert alert-danger">There was an error loading the member data</div>}

      <div className={classNames(['member-cards', { loading: this.loading }])}>
        {this.members.map(member => <MemberCard key={member.id} member={member} cardActionsComponent={this.props.cardActionsComponent} onClick={member => this.props.onCardClick && this.props.onCardClick(member)} />)}
      </div>

      {this.loading && <div className="infinite-scroll-container">
        <CircleLoader className="app-loading-spinner" color="#12497d" /> <span>Loading more records...</span>
      </div>}

      {this.loaded && this.totalRecords == 0 && <div className="infinite-scroll-empty">There are no members that match your search</div>}

      <VisibilitySensor
        partialVisibility={true}
        onChange={this.handleVisibilityChange}
        scrollCheck={true}
      >
        <div style={{ height: 30 }} />
      </VisibilitySensor>
    </>
  }
}

type MemberCarProps = {
  member: Member
  cardActionsComponent?: (member: Member) => React.ReactNode
  onClick: (member: Member) => void
}

@observer
class MemberCard extends React.Component<MemberCarProps> {
  static defaultProps = {
    onClick: () => {
    }
  }

  render(): React.ReactNode {
    const { member, onClick } = this.props

    return <div className="member-card" onClick={() => onClick(member)}>
      <div className="member-card-content">
        <img className="profile-image" src={member.getProfileImageUrl()} alt={member.fullName} />
        <div className="member-info">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className="member-name" style={{ alignSelf: 'flex-end', marginBottom: '6px' }} >{member.fullName}</div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {member.badgeColor ? <BadgeView badgeColor={member.badgeColor}></BadgeView> : null}
              {member.joinDate ? <label style={{ fontStyle: 'italic' }}>joined {formatDate(member.joinDate.toString(), 'MM/DD/YYYY')}</label> : null}
            </div>
          </div>
          {member.business ? <div className="business-name">{member.business.name}</div> : null}
          {member.category ? <div className="text-muted category-name">{member.category.name}</div> : null}
          {member.chapter ? <div className="chapter-name">{member.chapter.name}</div> : null}
        </div>
      </div>
      <div>
        {this.props.cardActionsComponent ? this.props.cardActionsComponent(member) : null}
      </div>
    </div>
  }
}
