import * as React from "react"
import BaseView from "./BaseView"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { Redirect, RouteComponentProps } from "react-router-dom"
import { observable } from "mobx"
import { observer } from "mobx-react"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import Util, { modelToCamelCase } from "../common/Util"
import { BarLoader } from "react-spinners"
import { Button, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent } from "reactstrap"
import classNames from 'classnames'
import { createBrowserHistory } from 'history'
import LazyResource from "../models/LazyResource"
import BackLink from "../components/BackLink"
import SupportArea from "../models/SupportArea"
import SupportAreaBasicInfoForm from "../components/SupportAreaBasicInfoForm"
import * as _ from "lodash"
import LazyTabPane from "../components/LazyTabPane"
import LazyResourcePanel from "../components/LazyResourcePanel"
import { toast } from "react-toastify"
import SearchableChapterList from "../components/SearchableChapterList"
import Chapter from "../models/Chapter"
import FlipMove from "react-flip-move"
import User from "../models/User"
import FormState from "../common/FormState"
import { UserPickerInput } from "../components/inputs/UserPickerInput"
import RequirePermission, { Permission } from "../components/RequirePermission"

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

const TABS = {
  general: 'general',
}

@observer
export default class SupportAreaDetailView extends BaseView<Props & RouteComponentProps> {
  @observable
  private showModal = false
  @observable
  private editModalContent?: React.ReactNode
  @observable
  private editModalSize = 'lg'
  @observable
  private editModalTitle = 'Edit'

  @observable
  private isSearchChaptersOpen = false

  @observable
  private activeTab = TABS.general

  @observable
  private formState = new FormState({
    title: '',
    userId: undefined,
  })

  private history = createBrowserHistory()

  private closeSearchChaptersModal = () => {
    this.isSearchChaptersOpen = false
  }

  private showEditModal = (title: string, component: React.ReactNode, size: string = 'lg') => {
    this.editModalTitle = title
    this.editModalContent = component
    this.showModal = true
    this.editModalSize = size
  }

  private closeEditModal = () => {
    this.showModal = false
    this.editModalContent = undefined
  }

  @observable private redirect = false
  @observable private supportArea = new LazyResource<SupportArea>((callback, error) => {
    ApiClient.supportAreas.show(this.props.match.params.id)
      .then(response => callback(new SupportArea().init(modelToCamelCase(response.data.support_area))))
      .catch(() => {
        error('There was an error loading the support area data')

        AppStateStore.showAlertModal('Error', 'There was an error loading the support area data', m => {
          m.hide()
          this.redirect = true
        })
      })
  })

  private deleteSupportArea = () => {
    AppStateStore.showConfirmationModal('Delete Support Area', 'Are you sure you want to delete this support area from the system?', (result, modal) => {
      if (result) {
        AppStateStore.showModalSpinner()

        ApiClient.supportAreas.destroy(this.supportArea.current!.id)
          .then(() => {
            toast.success('The Support Area has been deleted')
            this.redirect = true
          }, error => {
            Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
              AppStateStore.showAlertModal('Error', message, m => {
                m.hide()
              })
              return true
            })
          })
          .then(() => {
            AppStateStore.dismissModalSpinner()
          })
      }

      modal.hide()
    }, {
      type: 'danger',
    })
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1 className="flex-fill">Support Area Detail</h1>
        {this.supportArea.current && <Button
          type="button"
          color="danger"
          onClick={this.deleteSupportArea}>
          Delete
        </Button>}
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    this.activeTab = _.get(this.history.location.state, 'activeTab') || TABS.general

    if (!this.props.match.params.id) {
      this.redirect = true
    }
  }

  private addChapter = (chapter: Chapter) => {
    AppStateStore.showModalSpinner()
    ApiClient.supportAreas.addChapter(this.supportArea.current!.id, chapter.id)
      .then(() => {
        toast.success('Chapter added to support area')
      }, error => {
        Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.supportArea.current!.supportAreaChapters.invalidate()
      })
  }

  private removeChapter = (chapter: Chapter) => {
    AppStateStore.showModalSpinner()
    ApiClient.supportAreas.removeChapter(this.supportArea.current!.id, chapter.id)
      .then(() => {
        toast.success('Chapter removed from support area')
      }, error => {
        Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.supportArea.current!.supportAreaChapters.invalidate()
      })
  }

  private addUser = (userId: number, title: string) => {
    AppStateStore.showModalSpinner()
    ApiClient.supportAreas.addUser(this.supportArea.current!.id, userId, { title })
      .then(() => {
        toast.success('User added to support area')
        this.formState.setAll({
          title: '',
          userId: undefined,
        })
      }, error => {
        Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.supportArea.current!.supportAreaUsers.invalidate()
      })
  }

  private removeUser = (user: User) => {
    AppStateStore.showModalSpinner()
    ApiClient.supportAreas.removeUser(this.supportArea.current!.id, user.id)
      .then(() => {
        toast.success('User removed from support area')
        this.formState.setAll({
          title: '',
          userId: undefined,
        })
      }, error => {
        Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.supportArea.current!.supportAreaUsers.invalidate()
      })
  }

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(window.location.pathname, { ...this.history.location.state, activeTab: this.activeTab })
    }
  }

  render (): React.ReactNode {
    return this.redirect
      ? this.renderRedirect()
      : !this.supportArea.current || !this.supportArea.loaded ? this.renderLoading() : this.renderContent()
  }

  private renderLoading = () => <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>

  private renderContent = () => {
    const supportArea = this.supportArea.current

    return supportArea ? (
      <>
        <div className="card">
          <div className="card-header">
            <Nav tabs className="card-header-tabs">
              <NavItem className={classNames({ active: this.activeTab === TABS.general })}>
                <NavLink
                  onClick={() => {
                    this.setTab(TABS.general)
                  }}
                >General</NavLink>
              </NavItem>
            </Nav>
          </div>
          <div className="card-body">
            <TabContent activeTab={this.activeTab}>
              <LazyTabPane tabId={TABS.general} activeTab={this.activeTab}>
                <h3>
                  {supportArea.name} <RequirePermission permission={Permission.EditSupportArea}>
                  {() =>
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.showEditModal('Basic Info',
                        <SupportAreaBasicInfoForm
                          supportArea={supportArea}
                          onCancel={() => this.closeEditModal()}
                          onSaved={() => {
                            this.closeEditModal()
                            this.supportArea.invalidate()
                          }}
                        />)
                    }}><i className="fa fa-pencil"/></a>
                  }</RequirePermission>
                </h3>

                <hr/>

                <div className="row">
                  <div className="col-md-6">
                    <h5>Chapters</h5>
                    <LazyResourcePanel resource={supportArea.supportAreaChapters} emptyMessage="There are no chapters assigned to this support area">
                      {supportAreaChapters => <>
                        <ul className="stacked-item-list">
                          <FlipMove>
                            {supportAreaChapters.map(supportAreaChapter => <li key={supportAreaChapter.id}>
                              <div style={{ flex: 1 }}>
                                {supportAreaChapter.chapter.name}
                              </div>
                              <div>
                                <a className="text-danger" href="" onClick={(ev) => {
                                  ev.preventDefault();
                                  this.removeChapter(supportAreaChapter.chapter)
                                }}><i className="fa fa-trash"/></a>
                              </div>
                            </li>)}
                          </FlipMove>
                        </ul>
                      </>}
                    </LazyResourcePanel>
                    {this.supportArea.current && this.supportArea.current.supportAreaChapters.current
                    && <div className="form-group">
                      <Button
                        type="button"
                        color="primary"
                        block
                        onClick={() => {
                          this.isSearchChaptersOpen = true
                        }}
                      >
                        Add A Chapter
                      </Button>
                    </div>}
                  </div>

                  <div className="col-md-6">
                    <h5>Area Reps</h5>
                    <LazyResourcePanel resource={supportArea.supportAreaUsers} emptyMessage="There are no users assigned to this support area">
                      {supportAreaUsers => <>
                        <ul className="stacked-item-list">
                          <FlipMove>
                            {supportAreaUsers.map(supportAreaUser => <li key={supportAreaUser.id}>
                              <div style={{ flex: 1 }}>
                                {supportAreaUser.user.name}<br/>
                                <span className="text-muted">{supportAreaUser.title}</span>
                              </div>
                              <div>
                                <a className="text-danger" href="" onClick={(ev) => {
                                  ev.preventDefault();
                                  this.removeUser(supportAreaUser.user)
                                }}><i className="fa fa-trash"/></a>
                              </div>
                            </li>)}
                          </FlipMove>
                        </ul>
                      </>}
                    </LazyResourcePanel>
                    {this.supportArea.current && this.supportArea.current.supportAreaUsers.current
                    && <>
                      <div className="form-group">
                        <input
                          placeholder="Enter a title"
                          type="text"
                          name="title"
                          className="form-control"
                          value={this.formState.get('title')}
                          onChange={this.formState.onChange}/>
                      </div>
                      <div className="form-group">
                        <UserPickerInput
                          placeholder="Select a user"
                          onChange={user => {
                            this.formState.set('userId', user ? user.id : null)
                          }}
                          value={this.formState.get('userId')}
                        />
                      </div>
                      <div>
                        <Button
                          type="button"
                          color="primary"
                          block
                          disabled={!this.formState.get('userId') || !this.formState.get('title').length}
                          onClick={() => {
                            this.addUser(this.formState.get('userId'), this.formState.get('title'))
                          }}
                        >
                          Add A User
                        </Button>
                      </div>
                    </>}
                  </div>
                </div>
              </LazyTabPane>
            </TabContent>
          </div>
        </div>

        <Modal
          isOpen={this.showModal}
          size={this.editModalSize}
        >
          <ModalHeader>{this.editModalTitle}</ModalHeader>
          <ModalBody>{this.editModalContent}</ModalBody>
        </Modal>

        <Modal
          isOpen={this.isSearchChaptersOpen}
          size="lg"
        >
          <ModalHeader toggle={this.closeSearchChaptersModal}>
            Browse Chapters
          </ModalHeader>
          <ModalBody>
            <SearchableChapterList
              onChoose={(chapter) => {
                this.addChapter(chapter)
                this.closeSearchChaptersModal()
              }}
              style={{
                maxHeight: "400px",
              }}
            />
          </ModalBody>
        </Modal>
      </>) : null
  }

  private renderRedirect = () =>
    <Redirect to={route(Routes.system.supportAreas.index)}/>
}