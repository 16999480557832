import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Member from "./Member"
import PaymentMethod from "./PaymentMethod"
import Category from './Category'
import Chapter from './Chapter'
import Guest from './Guest'

export default class ChapterTransferRequest extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  viewedAt: Moment
  submittedAt: Moment
  processedAt: Moment | null
  archivedAt: Moment | null
  status: string
  isProcessed: boolean
  applicationPdfUrl: string | null

  chapterTransferFee: number
  taxRate: number
  transferReason: string

  applicationSignatureImageUrl: string

  memberId: number
  oldChapterId: number
  newChapterId: number
  oldCategoryId: number
  replacementGuestId: number | null
  paymentMethodId: number

  member: Member
  oldChapter: Chapter
  newChapter: Chapter
  oldCategory: Category
  replacementGuest: Guest
  paymentMethod: PaymentMethod

  getChapterTransferFeeTotal () {
    return this.chapterTransferFee + this.chapterTransferFee * this.taxRate
  }

  setMember (m?: {}) {
    return m ? new Member().init(m) : null
  }

  setOldChapter (c?: {}) {
    return c ? new Chapter().init(c) : null
  }

  setNewChapter (c?: {}) {
    return c ? new Chapter().init(c) : null
  }

  setOldCategory (c?: {}) {
    return c ? new Category().init(c) : null
  }

  setReplacementGuest (c?: {}) {
    return c ? new Guest().init(c) : null
  }

  setPaymentMethod (p?: {}) {
    return p ? new PaymentMethod(p) : null
  }

  getCasts () {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      processedAt: 'datetime',
      archivedAt: 'datetime',
      viewedAt: 'datetime',
      submittedAt: 'datetime',
      memberId: 'number',
      oldChapterId: 'number',
      newChapterId: 'number',
      oldCategoryId: 'number',
      replacementGuestId: 'number',
      paymentMethodId: 'number',
      chapterTransferFee: 'number',
      taxRate: 'number',
    }
  }
}
