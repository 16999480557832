import { FieldType, ResourceSpec } from "../ReportDataSource"
import Area from "../../models/Area"

export const AreaResource: ResourceSpec = {
  collectionResourceName: 'areas',
  modelClass: Area,

  fields: [
    {
      name: 'id',
      type: FieldType.Number,
    },
    {
      name: 'name',
      type: FieldType.String,
    },
  ],
  relationships: [],
}