import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import AppStateStore from "../stores/AppStateStore"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { toast } from "react-toastify"
import { route } from "../routes/routes"
import Util from "../common/Util"
import ChapterTransferRequest from '../models/ChapterTransferRequest'

type ApproveChapterTransferApplicationModalProps = {
  isOpen: boolean
  toggle: () => void
} & ApproveChapterTransferApplicationFormProps

@observer
export class ApproveChapterTransferApplicationModal extends React.Component<ApproveChapterTransferApplicationModalProps> {
  render (): React.ReactNode {
    const { isOpen, toggle, ...rest } = this.props

    return <Modal isOpen={isOpen} onClosed={this.props.onCancel} size="md">
      <ModalHeader toggle={toggle}>
        Approve Application
      </ModalHeader>
      <ModalBody>
        <ApproveApplicationForm
          {...rest}
        />
      </ModalBody>
    </Modal>
  }
}

type ApproveChapterTransferApplicationFormProps = {
  onCancel: () => void
  onSaved: (memberId: number) => void
  chapterTransferRequest: ChapterTransferRequest
}

@observer
export class ApproveApplicationForm extends React.Component<ApproveChapterTransferApplicationFormProps> {
  @observable private submitting = false

  private onSubmit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.getInstance()
      .post(route(ApiRoutes.chapterTransferRequests.approve, { id: this.props.chapterTransferRequest.id }))
      .then(response => {
        toast.success('Chapter Transfer Approved')
        this.props.onSaved(response.data.memberId)
      })
      .catch(error => Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      )
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  render (): React.ReactNode {
    return <>
      <form onSubmit={this.onSubmit}>
        <div className="form-row">
          <div className="col-sm-12">
            <div className="form-group">
              <p>
                Are you sure you want to approve this chapter transfer? This will remove the member from your chapter and open up their business category for a new member.
                The member will receive an email notification.
              </p>
            </div>
          </div>
        </div>

        <div className="form-buttons">
          <Button color="secondary" onClick={() => this.props.onCancel()}>Cancel</Button>
          <ButtonLoader type="submit" color="primary" loading={this.submitting}>Approve Chapter Transfer</ButtonLoader>
        </div>
      </form>
    </>
  }
}
