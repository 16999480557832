import BaseView from "../BaseView"
import * as React from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import Chapter from "../../models/Chapter"
import ApiClient from "../../api/ApiClient"
import AuthStore from "../../stores/AuthStore"
import Util from "../../common/Util"
import { BarLoader } from "react-spinners"
import ChapterPositionsForm from "../../components/ChapterPositionsForm"

@observer
export default class BoardCurrentPositionsView extends BaseView {
  @observable private chapter?: Chapter
  @observable private error?: string
  @observable private loading = false

  componentDidMount (): void {
    super.componentDidMount()

    this.loadChapter()
  }

  private loadChapter = () => {
    this.loading = true
    this.error = undefined

    ApiClient.query(`
    chapter {
      *
    }`, {
      where: [{ id: AuthStore.getUser()!.chapter.id }],
    })
      .then(response => this.chapter = new Chapter().init(response.data.chapter))
      .catch(error => this.error = Util.extractErrorMessage(error.response))
      .then(() => this.loading = false)
  }

  renderContentHeader (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <h1>Board Positions</h1>
  }

  renderContentBody (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return this.error
      ? <div className="alert alert-error">{this.error}</div>
      : (this.loading || !this.chapter)
        ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
        : <ChapterPositionsForm chapter={this.chapter} onChapterInvalidated={() => this.loadChapter()}/>
  }
}
