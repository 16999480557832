import { RouteComponentProps, withRouter } from "react-router"
import * as React from "react"
import { Link, LinkProps } from "react-router-dom"
import classNames from "classnames"
import { observer } from "mobx-react"
import { Badge } from "reactstrap"

type NavMenuLinkProps = RouteComponentProps & LinkProps & {
  iconClassName?: string
  linkText: string

  showBadge?: boolean
  badgeCount?: number
  badgeColor?: string
}

@observer
class NavMenuLink extends React.Component<NavMenuLinkProps, any> {
  render () {
    const {
      location,
      iconClassName,
      linkText,
      staticContext,
      showBadge,
      badgeColor,
      badgeCount,
      ...rest
    } = this.props
    return (
      <Link {...rest}
            className={classNames({ 'active': (location && location.pathname === this.props.to) })}>
        {iconClassName && <i className={iconClassName}/>}
        <span>{linkText}</span>
        {
          showBadge
            ? <Badge
              color={badgeColor || 'danger'}
              className="ml-2">
              {badgeCount || 0}
            </Badge>
            : null
        }

      </Link>
    )
  }
}

export default withRouter(NavMenuLink)
