import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Progress } from "reactstrap"
import { DocumentLibraryDescriptor } from "../../models/DocumentLibraryItem"
import { observable } from "mobx"
import Util, { logException } from "../../common/Util"
import AppStateStore from "../../stores/AppStateStore"
import ButtonLoader from "../ButtonLoader"
import Dropzone, { DropFilesEventHandler } from "react-dropzone"
import { toast } from "react-toastify"
import ApiClient from "../../api/ApiClient"
import classNames from 'classnames'

type UploadDocumentModalProps = {
  toggle: () => void
  isOpen: boolean
  libraryDescriptor: DocumentLibraryDescriptor
  onDocumentUploaded: () => void
  parentDocumentLibraryItemId?: string
}

@observer
export default class UploadDocumentModal extends React.Component<UploadDocumentModalProps> {
  @observable
  private files: File[] | null = null

  @observable
  private uploadProgress: number = 0

  @observable
  private uploading: boolean = false

  @observable private uploadingFile: File | null

  private toggle = () => {
    if (!this.uploading) {
      this.props.toggle()
    }
  }

  private handleDrop: DropFilesEventHandler = (acceptedFiles) => {
    this.files = (acceptedFiles && acceptedFiles.length) ? acceptedFiles : null

    if (this.files) {
      this.submit()
    }
  }

  private onUploadProgress = (progress: ProgressEvent) => {
    this.uploadProgress = (progress.loaded / progress.total) * 100
  }

  private submit = (ev?: SyntheticEvent) => {
    ev && ev.preventDefault()

    if (this.files) {

      this.uploading = true

      const files = this.files

      const uploadDocument = (idx: number) => {
        this.uploadingFile = files[idx]

        ApiClient.uploadDocument(this.props.libraryDescriptor, files[idx], this.props.parentDocumentLibraryItemId, this.onUploadProgress)
          .then(() => {
            ++idx
            if (idx < files.length) {
              this.uploadProgress = 0
              uploadDocument(idx)
            } else {
              toast.success('The file was successfully uploaded')
              this.props.onDocumentUploaded()

              this.uploading = false
              this.uploadProgress = 0
              this.uploadingFile = null
            }
          }, error => {
            Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
              AppStateStore.showAlertModal('Error', message, m => {
                m.hide()
              })
              return true
            })
          })
          .catch(ex => {
            logException(ex)
          })
      }

      uploadDocument(0)
    }
  }

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <Modal isOpen={this.props.isOpen} toggle={this.toggle}>
      <ModalHeader toggle={this.toggle}>
        Upload New Document
      </ModalHeader>
      <ModalBody>
        <form method="post" action="" onSubmit={this.submit}>
          <Dropzone disabled={this.uploading} multiple={true} onDrop={this.handleDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ cursor: 'pointer' }}>
                  <input {...getInputProps()} />
                  {<div className={'text-center'} style={{ padding: '3rem' }}>
                    <div className="text-muted text-center" style={{ fontSize: 50 }}><i className="fa fa-upload"/></div>
                    {
                      isDragActive
                        ? <p>Drop file here</p>
                        : <p>Drag a file from your computer into this window, or click here to browse your computer files.</p>
                    }
                  </div>}
                </div>
              )
            }}
          </Dropzone>

          {
            this.uploading
              ? (
                <>
                  {this.uploadingFile ? <div>File to upload: <b>{this.uploadingFile!.name}</b></div> : null}
                  <br/>
                  <Progress value={this.uploadProgress}/>
                  <br/>
                  <br/>

                  <div className="form-buttons">
                  </div>
                </>
              )
              : null
          }
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={this.uploading}
          type="button"
          color="secondary"
          onClick={this.toggle}>Cancel</Button>
        <ButtonLoader
          disabled={!this.files}
          loading={this.uploading}
          type={'submit'}
          color={'success'}><i className="fa fa-upload"/> Upload</ButtonLoader>
      </ModalFooter>
    </Modal>
  }
}
