import * as React from "react"
import { observer } from "mobx-react"
import { Button } from "reactstrap"
import ILazyResource from "../models/ILazyResource"

type LazyResourcePanelProps<T> = {
  resource: ILazyResource<T>
  children: (resource: T) => React.ReactNode
  emptyMessage: React.ReactNode
  hideWhenEmpty: boolean
  renderWhenEmpty: boolean
  loading: boolean
  initialLoader?: React.ReactNode
}

@observer
export default class LazyResourcePanel<T> extends React.Component<LazyResourcePanelProps<T>, never> {
  static defaultProps = {
    hideWhenEmpty: false,
    renderWhenEmpty: false,
    emptyMessage: null,
    loading: false,
  }

  componentWillMount (): void {
    this.props.resource.refresh().then(() => {
    })
  }

  componentDidUpdate (prevProps: Readonly<LazyResourcePanelProps<T>>, prevState: Readonly<never>, snapshot?: any): void {
    if (!this.props.resource.loaded) {
      this.props.resource.refresh().then(() => {
      })
    }
  }

  private renderLoading = () => {
    return !this.props.resource.loaded && this.props.initialLoader ? this.props.initialLoader : <div className="lazy-spinner-backdrop"><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/></div>
  }

  private renderList = () => {
    const resource = this.props.resource

    if (!resource.loaded) {
      return null
    }

    return (!this.isEmpty(resource.current) || this.props.renderWhenEmpty)
      ? this.props.children(resource.current!)
      : <div className="text-muted">{this.props.emptyMessage}</div>
  }

  isEmpty = (v: any) => {
    return v === undefined || (v.length !== undefined && v.length === 0)
  }

  private renderError = () => {
    return <div className="text-center">
      <div className="text-danger">{this.props.resource.error}</div>
      <Button type="button" color="secondary" onClick={() => this.props.resource.refresh(true)}>Retry</Button>
    </div>
  }

  render (): React.ReactNode {
    if (this.props.hideWhenEmpty && this.isEmpty(this.props.resource.current)) {
      return null
    }

    return <div className="lazy-resource-panel">
      {(this.props.resource.error && this.props.resource.errorCode !== 404) ? this.renderError() : this.renderList()}
      {(this.props.resource.loading || this.props.loading) ? this.renderLoading() : null}
    </div>
  }
}