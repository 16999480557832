import * as React from "react"
import { SyntheticEvent } from "react"
import { Prompt, Redirect } from "react-router-dom"
import { observable } from "mobx"
import { observer } from "mobx-react"
import { BarLoader } from "react-spinners"
import { Button } from "reactstrap"
import { LinkContainer } from "react-router-bootstrap"
import { toast } from "react-toastify"
import BaseView from "../BaseView"
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import FormHelper from "../../forms/FormHelper"
import Notice from "../../models/Notice"
import BackLink from "../../components/BackLink"
import AppStateStore from "../../stores/AppStateStore"
import AuthStore from "../../stores/AuthStore"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import ApiClient from "../../api/ApiClient"
import Util, { logException, modelToCamelCase, modelToSnakeCase } from "../../common/Util"
import ButtonLoader from "../../components/ButtonLoader"

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

@observer
export default class EditNoticeView extends BaseView<Props> {
  @observable private formState = new FormState({
    title: '',
    message: '',
    status: 'Active',
    type: 'Normal',
  })
  @observable private formErrors = new ErrorBag()
  private formHelper = new FormHelper(this.formState, this.formErrors)
  @observable private submitting = false
  @observable private loading = false
  @observable private redirect = false
  @observable private editNotice?: Notice

  private get isEdit () {
    return !!this.props.match.params.id
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>{this.isEdit ? 'Update Existing Notice' : 'Add New Notice'}</h1>
        {this.isEdit && this.editNotice && <ul className="content-header-actions">
          <li>
            <Button
              color="warning"
              onClick={() => {
                AppStateStore.showModalSpinner()
                AuthStore.requestImpersonate({ user_id: this.editNotice!.id })
                  .then(() => {
                    AppStateStore.dismissModalSpinner()
                    window.location.href = route(Routes.index)
                  })
                  .catch(() => {
                    AppStateStore.showAlertModal('Error', 'There was an error impersonating the user')
                  })
              }}
            >Impersonate User</Button>
          </li>
        </ul>}
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    if (this.props.match.params.id) {
      this.loading = true

      ApiClient.query(`
        notice {
          *
        }
        `, {
          where:
            [{ id: this.props.match.params.id }],
        }
      )
        .then(response => {
          const noticeData = modelToCamelCase(response.data.notice)
          this.editNotice = new Notice().init(noticeData)
          this.formState.setAll(noticeData)
          this.formState.clearDirty()
          this.loading = false
        })
        .catch(ex => {
          AppStateStore.showAlertModal('Error', 'The notice was not found', m => {
            m.hide()
            this.redirect = true
          })
        })
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()

    this.submitting = true
    AppStateStore.showModalSpinner()

    const apiCall = this.editNotice
      ? () => ApiClient.notices.update(this.editNotice!.id, modelToSnakeCase(this.formHelper.toObject()))
      : () => ApiClient.notices.create(modelToSnakeCase(this.formHelper.toObject()))

    apiCall().then(() => {
      toast.success(this.isEdit ? 'Notice updated' : 'Notice created')
      this.redirect = true
    }, error => {
      const errors = new ErrorBag()
      Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      })

      this.formErrors.addErrors(errors.getErrorList())
    })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  renderContentBody (): React.ReactNode {
    return this.redirect
      ? this.renderRedirect()
      : this.loading ? this.renderLoading() : this.renderForm()
  }

  private renderLoading = () => <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>

  private renderForm = () => (
    <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      {this.formState.isDirty && <Prompt message="Are you sure you want to leave this page? You will lose any unsaved changes!"/>}
      <div className="form-row">
        <div className="col-12">
          {this.formHelper.renderTextInput({
            name: 'title',
            type: 'text',
            label: 'Title',
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-12">
          {this.formHelper.renderTextAreaInput({
            name: 'message',
            label: 'Message',
          })}
        </div>
      </div>

      <div className="form-row">
        <div className="col-6">
          {this.formHelper.renderSelectInput({
            name: 'type',
            label: 'Type',
            options: [
              'Normal',
              'Sticky',
            ].map(t => ({ value: t, text: t }))
          })}
        </div>
        <div className="col-6">
          {this.formHelper.renderSelectInput({
            name: 'status',
            label: 'Status',
            options: [
              { value: 'Active', text: 'Active' },
              { value: 'Inactive', text: 'Inactive' },
            ]
          })}
        </div>
      </div>
      <div className="form-buttons">
        <LinkContainer to={route(Routes.system.notices.index)}><Button type="button" color=""><i className="fa fa-ban"/> Cancel</Button></LinkContainer>
        <ButtonLoader type="submit" color="success" loading={this.submitting}><i className="fa fa-save"/> {this.isEdit ? 'Update Notice' : 'Save New Notice'}</ButtonLoader>
      </div>
    </form>
  )

  private renderRedirect = () => <Redirect to={route(Routes.system.notices.index)}/>
}