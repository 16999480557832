import * as React from "react"
import { SyntheticEvent } from "react"
import BaseView from "./BaseView"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { Prompt, Redirect } from "react-router-dom"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { observer } from "mobx-react"
import ButtonLoader from "../components/ButtonLoader"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import Util, { logException, modelToCamelCase, modelToSnakeCase } from "../common/Util"
import { BarLoader } from "react-spinners"
import { Button } from "reactstrap"
import { LinkContainer } from "react-router-bootstrap"
import Category from "../models/Category"
import { toast } from "react-toastify"
import BackLink from "../components/BackLink"

type EditCategoryFormState = {
  name: string
  description: string
  status: string
}

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

@observer
export default class EditCategoryView extends BaseView<Props> {
  @observable private formState = new FormState<EditCategoryFormState>({
    name: '',
    description: '',
    status: 'Active',
  })
  @observable private formErrors = new ErrorBag()
  private formHelper = new FormHelper(this.formState, this.formErrors)
  @observable private submitting = false
  @observable private loading = false
  @observable private redirect = false
  @observable private editCategory?: Category

  private get isEdit () {
    return !!this.props.match.params.id
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>{this.isEdit ? 'Update Existing Category' : 'Add New Category'}</h1>
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    if (this.props.match.params.id) {
      this.loading = true

      ApiClient.categories.show(this.props.match.params.id)
        .then(response => {
          const categoryData = modelToCamelCase(response.data.category)
          this.editCategory = new Category(modelToCamelCase(categoryData))
          this.formState.setAll(categoryData as EditCategoryFormState)
          this.formState.clearDirty()
          this.loading = false
        }, () => {
          AppStateStore.showAlertModal('Error', 'The category was not found', m => {
            m.hide()
            this.redirect = true
          })
        })
        .catch(ex => {
          logException(ex)
          AppStateStore.showAlertModal('Error', 'The category was not found', m => {
            m.hide()
            this.redirect = true
          })
        })
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    const apiCall = this.editCategory
      ? () => ApiClient.categories.update(this.editCategory!.id, modelToSnakeCase(this.formHelper.toObject()))
      : () => ApiClient.categories.create(modelToSnakeCase(this.formHelper.toObject()))

    apiCall().then(() => {
      toast.success(this.isEdit ? 'Category updated' : 'Category created')
      this.redirect = true
    }, error => {
      const errors = new ErrorBag()
      Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      })

      this.formErrors.addErrors(errors.getErrorList())
    })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  renderContentBody (): React.ReactNode {
    return this.redirect
      ? this.renderRedirect()
      : this.loading ? this.renderLoading() : this.renderForm()
  }

  private renderLoading = () => <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>

  private renderForm = () => (
    <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      {this.formState.isDirty && <Prompt message="Are you sure you want to leave this page? You will lose any unsaved changes!"/>}
      <div className="form-row">
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'name',
            type: 'text',
            label: 'Category Name',
          })}
        </div>
        <div className="col-sm-6">
          {this.formHelper.renderSelectInput({
            name: 'status',
            label: 'Status',
            options: [
              { value: 'Active', text: 'Active' },
              { value: 'Inactive', text: 'Inactive' },
            ]
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-12">
          {this.formHelper.renderTextAreaInput({
            name: 'description',
            label: 'Category Description',
          })}
        </div>
      </div>
      <div className="form-buttons">
        <LinkContainer to={route(Routes.system.categories.index)}><Button type="button" color=""><i className="fa fa-ban"/> Cancel</Button></LinkContainer>
        <ButtonLoader type="submit" color="success" loading={this.submitting}><i className="fa fa-save"/> {this.isEdit ? 'Update Category' : 'Save New Category'}</ButtonLoader>
      </div>
    </form>
  )

  private renderRedirect = () => <Redirect to={route(Routes.system.categories.index)}/>
}