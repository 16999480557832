import HomeView from "../views/HomeView"
import RegionsView from "../views/RegionsView"
import { compileRoutes } from "./routes"
import EditRegionView from "../views/EditRegionView"
import AreasView from "../views/AreasView"
import EditAreaView from "../views/EditAreaView"
import CategoriesView from "../views/CategoriesView"
import EditCategoryView from "../views/EditCategoryView"
import pluralize from 'pluralize'
import ChaptersView from "../views/ChaptersView"
import EditChapterView from "../views/EditChapterView"
import MembersView from "../views/MembersView"
import CreateMemberView from "../views/CreateMemberView"
import MemberDetailView from "../views/MemberDetailView"
import UsersView from "../views/UsersView"
import EditUserView from "../views/EditUserView"
import ChapterDetailView from "../views/ChapterDetailView"
import SupportAreasView from "../views/SupportAreasView"
import CreateSupportAreaView from "../views/CreateSupportAreaView"
import SupportAreaDetailView from "../views/SupportAreaDetailView"
import MemberDashboardView from "../views/member/MemberDashboardView"
import MemberTipsView from "../views/member/MemberTipsView"
import GuestsView from "../views/GuestsView"
import GuestDetailView from "../views/GuestDetailView"
import ApplicationsView from "../views/ApplicationsView"
import ApplicationDetail from "../views/ApplicationDetail"
import MemberChapterView from "../views/chapter/MemberChapterView"
import MemberBillingView from "../views/member/MemberBillingView"
import AttendanceView from "../views/AttendanceView"
import CorporateDocumentLibraryView from "../views/CorporateDocumentLibraryView"
import MemberSettingsView from "../views/member/MemberSettingsView"
import MemberProfilesView from "../views/member/MemberProfilesView"
import ChapterCalendarView from "../views/chapter/ChapterCalendarView"
import ChapterGuestsView from "../views/chapter/ChapterGuestsView"
import NetworkDocumentLibraryView from "../views/network/NetworkDocumentLibraryView"
import FulfillmentView from "../views/system/FulfillmentView"
import SystemCalendarView from "../views/system/SystemCalendarView"
import ReportingView from "../views/system/ReportingView"
import NetworkMemberSearchView from "../views/network/NetworkMemberSearchView"
import NetworkChapterSearchView from "../views/network/NetworkChapterSearchView"
import NetworkCategorySearchView from "../views/network/NetworkCategorySearchView"
import NetworkChapterDetailView from "../views/network/NetworkChapterDetailView"
import NetworkMemberDetailView from "../views/network/NetworkMemberDetailView"
import BoardProgramView from "../views/board/BoardProgramView"
import TransactionsView from "../views/system/TransactionsView"
import NetworkOpenCategoryReport from "../views/network/NetworkOpenCategoryReport"
import LegacyUrlHandlerView from "../views/public/LegacyUrlHandlerView"
import NetworkSupportView from "../views/network/NetworkSupportView"
import ChapterDocumentLibrary from "../views/board/ChapterDocumentLibrary"
import MessagesView from "../views/member/MessagesView"
import ChapterTipsView from "../views/board/ChapterTipsView"
import BoardReportingView from "../views/board/BoardReportingView"
import InvoicesView from "../views/system/InvoicesView"
import RenewalsView from "../views/system/RenewalsView"
import NoticesView from "../views/system/NoticesView"
import EditNoticeView from "../views/system/EditNoticeView"
import BoardPositionsView from "../views/board/BoardPositionsView"
import SystemMessagesView from "../views/system/SystemMessagesView"
import MemberSystemMessagesView from "../views/system/MemberSystemMessagesView"
import PendingMembersView from "../views/PendingMembersView"
import RenewalFeeCalculatorView from '../views/system/RenewalFeeCalculatorView'
import EditMemberProfileView from '../views/board/EditMemberProfileView'
import ExtendedNetworkView from '../views/member/ExtendedNetworkView'
import CategoryChangeRequestDetailView from '../views/CategoryChangeRequestDetailView'
import RequestChapterTransferView from '../views/RequestChapterTransferView'
import ChapterTransferRequestDetailView from '../views/ChapterTransferRequestDetailView'
import AdminDashboardView from '../views/DashboardView'
import BoardDocumentsView from '../views/system/BoardDocumentsView'
import CreateBoardDocumentView from '../views/system/CreateBoardDocumentView'
import BoardDocumentView from '../views/system/BoardDocumentView'
import SignBoardDocumentView from '../views/public/SignBoardDocumentView'
import NtsEventsView from '../views/system/NtsEventsView'
import EditNtsEventView from '../views/system/EditNtsEventView'
import NtsEventView from '../views/system/NtsEventView'
import NetworkVideoLibraryView from '../views/network/NetworkVideoLibraryView'
import SentNotificationsView from '../views/system/SentNotificationsView'
import SendNotificationView from '../views/system/SendNotificationView'
import SponsorshipCreditsView from '../views/SponsorshipCreditsView'
import BoardTrainingsView from "../views/system/BoardTrainingsView"
import EditBoardTrainingView from "../views/system/EditBoardTrainingView"
import BoardTrainingView from "../views/system/BoardTrainingView"
import AdminEmailTemplatesView from "../views/system/AdminEmailTemplatesView"

const crudAppRoutes = (base: string) => {
  return {
    index: `/${pluralize(base)}`,
    create: `/${base}`,
    edit: `/${base}/:id`,
  }
}

export const Routes = {
  index: '/',
  login: '/login',
  requestPasswordReset: '/request-password-reset',
  resetPassword: '/reset-password/:token/:username',
  legacyUrl: '/m/api/',

  /**
   * MEMBER ROUTES
   */

  member: {
    dashboard: '/member/dashboard',
    settings: '/member/settings',
    profile: '/member/profile/:tab?',
    billing: '/member/billing',
    tips: '/member/tips/:tab?',
    messages: '/member/messages/:conversationId?',
    extendedNetwork: '/member/extended-network/:tab?',
    requestChapterTransfer: '/member/request-chapter-transfer/:memberId',
    emailTemplates: '/member/system/email-templates'
  },

  /**
   * CHAPTER ROUTES
   */
  chapter: {
    index: '/chapter/index',
    calendar: '/chapter/calendar',
    guests: '/chapter/guests',
    tips: '/chapter/tips',
  },

  board: {
    program: '/board/program',
    attendance: '/board/attendance',
    takeAttendance: '/board/attendance/:id',
    reports: '/board/reports',
    documentLibrary: '/document-library',
    boardPositions: '/board/board-positions',
    editMemberProfile: '/board/member-profile/:id/:tab?',
    signBoardDocument: '/board/sign-document/:slug',
  },

  /**
   * NETWORK SEARCH
   */
  network: {
    chapters: '/network/chapters',
    chapter: '/network/chapter/:chapterId/:tab?',
    members: '/network/members',
    member: '/network/member/:memberId',
    categories: '/network/categories',
    documentLibrary: '/network/document-library',
    videoLibrary: '/network/video-library',
    support: '/network/support',
    openCategoryReport: '/network/open-categories/:categoryId',
  },

  /**
   * ADMIN ROUTES
   */

  system: {
    regions: crudAppRoutes('region'),
    areas: crudAppRoutes('area'),
    categories: crudAppRoutes('category'),
    calendar: '/manage/calendar',
    chapters: {
      ...crudAppRoutes('manage/chapter'),
      show: `/manage/chapter/:id/show`,
    },
    members: {
      ...crudAppRoutes('manage/member'),
      show: `/manage/member/:id/show`,
    },
    pendingMembers: '/manage/pending-members',
    sponsorshipCredits: '/manage/sponsorship-credits',
    users: {
      ...crudAppRoutes('user'),
    },
    supportAreas: {
      ...crudAppRoutes('support-area'),
      show: '/support-area/:id/show',
    },
    guests: {
      index: '/guests',
      show: '/guests/:id',
    },
    applications: {
      index: '/applications/:tab?',
      show: '/application/:id',
      showCategoryChangeRequest: '/category-change-request/:id',
      showChapterTransferRequest: '/chapter-transfer-request/:id',
    },

    fulfillment: '/system/fulfillment',
    documentLibrary: '/system/corporate-document-library',
    reporting: '/system/reporting',
    transactions: '/system/transactions',
    invoices: '/system/invoices',
    renewals: '/system/renewals',
    dashboard: '/system/dashboard',

    notices: {
      ...crudAppRoutes('notice'),
    },

    sentNotifications: {
      index: '/sent-notifications',
      create: '/sent-notifications/create',
    },

    boardDocuments: {
      index: '/board-documents',
      create: '/board-document',
      show: '/board-document/:id',
    },
    ntsEvents: {
      detail: '/nts-event/detail/:id',
      ...crudAppRoutes('nts-event'),
    },

    boardTraining: {
      detail: '/board-training/detail/:id',
      ...crudAppRoutes('board-training'),
    },

    systemMessages: '/system/sent-messages',
    emailTemplates: '/system/email-templates'
  },

  /**
   * PUBLIC ROUTES
   */

  public: {
    editApplication: '/guest/application/:slug',
    acceptEventInvitation: '/guest/accept-invitation/:slug',
    declineEventInvitation: '/guest/decline-invitation/:slug',
    payInvoice: '/invoice/:slug',
    verifyNotificationDestination: '/vnd/:slug',
    joinExtendedNetwork: '/extnetwork/:slug',
  },

  legacy: {
    memberOnboarding: '/onboarding/:slug',
  },

  printableChapterRoster: '/printable-chapter-roster/:chapter?',

  devTools: {
    renewalFeeCalculator: '/dev-tools/renewal-fee-calculator',
  }
}

compileRoutes(Routes)

export const AppRoutes = [
  {
    path: Routes.index,
    component: HomeView,
  },

  {
    path: Routes.legacyUrl,
    component: LegacyUrlHandlerView,
    exact: false,
    strict: false,
  },

  /**
   * MEMBER VIEWS
   */

  {
    path: Routes.member.settings,
    component: MemberSettingsView,
  },
  {
    path: Routes.member.dashboard,
    component: MemberDashboardView,
  },
  {
    path: Routes.member.profile,
    component: MemberProfilesView,
  },
  {
    path: Routes.member.billing,
    component: MemberBillingView,
  },
  {
    path: Routes.member.tips,
    component: MemberTipsView,
  },
  {
    path: Routes.member.messages,
    component: MessagesView,
  },
  {
    path: Routes.member.extendedNetwork,
    component: ExtendedNetworkView,
  },
  {
    path: Routes.member.requestChapterTransfer,
    component: RequestChapterTransferView,
  },
  {
    path: Routes.board.signBoardDocument,
    component: SignBoardDocumentView,
  },

  /**
   * CHAPTER VIEWS
   */

  {
    path: Routes.chapter.index,
    component: MemberChapterView,
  },

  {
    path: Routes.chapter.guests,
    component: ChapterGuestsView,
  },
  {
    path: Routes.chapter.calendar,
    component: ChapterCalendarView,
  },
  {
    path: Routes.chapter.tips,
    component: ChapterTipsView,
  },

  {
    path: Routes.board.attendance,
    component: AttendanceView,
  },
  {
    path: Routes.board.reports,
    component: BoardReportingView,
  },
  {
    path: Routes.board.program,
    component: BoardProgramView
  },
  {
    path: Routes.board.documentLibrary,
    component: ChapterDocumentLibrary,
  },
  {
    path: Routes.board.boardPositions,
    component: BoardPositionsView,
  },
  {
    path: Routes.board.editMemberProfile,
    component: EditMemberProfileView,
  },

  // {
  //   path: Routes.calendar,
  //   component: CalendarView,
  // },

  /**
   * NETWORK VIEWS
   */

  {
    path: Routes.network.documentLibrary,
    component: NetworkDocumentLibraryView,
  },
  {
    path: Routes.network.videoLibrary,
    component: NetworkVideoLibraryView,
  },
  {
    path: Routes.network.members,
    component: NetworkMemberSearchView,
  },
  {
    path: Routes.network.member,
    component: NetworkMemberDetailView,
  },
  {
    path: Routes.network.chapters,
    component: NetworkChapterSearchView,
  },
  {
    path: Routes.network.chapter,
    component: NetworkChapterDetailView,
  },
  {
    path: Routes.network.categories,
    component: NetworkCategorySearchView,
  },
  {
    path: Routes.network.openCategoryReport,
    component: NetworkOpenCategoryReport,
  },
  {
    path: Routes.network.support,
    component: NetworkSupportView
  },

  /**
   * ADMIN VIEWS
   */

  {
    path: Routes.system.regions.index,
    component: RegionsView,
  },
  {
    path: Routes.system.regions.create,
    component: EditRegionView,
  },
  {
    path: Routes.system.regions.edit,
    component: EditRegionView,
  },

  {
    path: Routes.system.areas.index,
    component: AreasView,
  },
  {
    path: Routes.system.areas.create,
    component: EditAreaView,
  },
  {
    path: Routes.system.areas.edit,
    component: EditAreaView,
  },

  {
    path: Routes.system.categories.index,
    component: CategoriesView,
  },
  {
    path: Routes.system.categories.create,
    component: EditCategoryView,
  },
  {
    path: Routes.system.categories.edit,
    component: EditCategoryView,
  },

  {
    path: Routes.system.chapters.index,
    component: ChaptersView,
  },
  {
    path: Routes.system.chapters.create,
    component: EditChapterView,
  },
  {
    path: Routes.system.chapters.edit,
    component: EditChapterView,
  },
  {
    path: Routes.system.chapters.show,
    component: ChapterDetailView,
  },
  {
    path: Routes.system.members.index,
    component: MembersView,
  },
  {
    path: Routes.system.pendingMembers,
    component: PendingMembersView,
  },
  {
    path: Routes.system.sponsorshipCredits,
    component: SponsorshipCreditsView,
  },
  {
    path: Routes.system.members.create,
    component: CreateMemberView,
  },
  {
    path: Routes.system.members.show,
    component: MemberDetailView,
  },
  {
    path: Routes.system.users.index,
    component: UsersView,
  },
  {
    path: Routes.system.users.create,
    component: EditUserView,
  },
  {
    path: Routes.system.users.edit,
    component: EditUserView,
  },
  {
    path: Routes.system.supportAreas.index,
    component: SupportAreasView,
  },
  {
    path: Routes.system.supportAreas.create,
    component: CreateSupportAreaView,
  },
  {
    path: Routes.system.supportAreas.show,
    component: SupportAreaDetailView,
  },
  {
    path: Routes.system.supportAreas.show,
    component: HomeView,
  },
  {
    path: Routes.system.guests.index,
    component: GuestsView,
  },
  {
    path: Routes.system.guests.show,
    component: GuestDetailView,
  },
  {
    path: Routes.system.applications.index,
    component: ApplicationsView,
  },
  {
    path: Routes.system.applications.show,
    component: ApplicationDetail,
  },
  {
    path: Routes.system.applications.showCategoryChangeRequest,
    component: CategoryChangeRequestDetailView,
  },
  {
    path: Routes.system.applications.showChapterTransferRequest,
    component: ChapterTransferRequestDetailView,
  },

  {
    path: Routes.system.fulfillment,
    component: FulfillmentView,
  },
  {
    path: Routes.system.documentLibrary,
    component: CorporateDocumentLibraryView,
  },
  {
    path: Routes.system.calendar,
    component: SystemCalendarView,
  },
  {
    path: Routes.system.reporting,
    component: ReportingView,
  },
  {
    path: Routes.system.transactions,
    component: TransactionsView,
  },
  {
    path: Routes.system.invoices,
    component: InvoicesView,
  },
  {
    path: Routes.system.renewals,
    component: RenewalsView,
  },
  {
    path: Routes.system.notices.index,
    component: NoticesView,
  },
  {
    path: Routes.system.notices.create,
    component: EditNoticeView,
  },
  {
    path: Routes.system.notices.edit,
    component: EditNoticeView,
  },
  {
    path: Routes.system.sentNotifications.index,
    component: SentNotificationsView,
  },
  {
    path: Routes.system.sentNotifications.create,
    component: SendNotificationView,
  },
  {
    path: Routes.system.systemMessages,
    component: SystemMessagesView,
  },
  {
    path: Routes.system.emailTemplates,
    component: AdminEmailTemplatesView,
  },
  {
    path: Routes.member.emailTemplates,
    component: MemberSystemMessagesView,
  },
  {
    path: Routes.system.dashboard,
    component: AdminDashboardView,
  },
  {
    path: Routes.system.boardDocuments.index,
    component: BoardDocumentsView,
  },
  {
    path: Routes.system.boardDocuments.create,
    component: CreateBoardDocumentView,
  },
  {
    path: Routes.system.boardDocuments.show,
    component: BoardDocumentView,
  },
  {
    path: Routes.system.ntsEvents.index,
    component: NtsEventsView,
  },
  {
    path: Routes.system.ntsEvents.create,
    component: EditNtsEventView,
  },
  {
    path: Routes.system.ntsEvents.edit,
    component: EditNtsEventView,
  },
  {
    path: Routes.system.ntsEvents.detail,
    component: NtsEventView,
  },
  {
    path: Routes.system.boardTraining.index,
    component: BoardTrainingsView,
  },
  {
    path: Routes.system.boardTraining.create,
    component: EditBoardTrainingView,
  },
  {
    path: Routes.system.boardTraining.edit,
    component: EditBoardTrainingView,
  },
  {
    path: Routes.system.boardTraining.detail,
    component: BoardTrainingView,
  },
  {
    path: Routes.devTools.renewalFeeCalculator,
    component: RenewalFeeCalculatorView,
  },
].map(r => Object.assign({
  exact: true,
  sensitive: true,
  strict: true,
}, r))
