import * as React from "react"
import { observable } from "mobx"
import { createLazyResource, safeNull } from "../common/Util"
import Member from "../models/Member"
import ApiClient from "../api/ApiClient"
import LazyResourcePanel from "./LazyResourcePanel"
import MemberProfileImage from "./MemberProfileImage"
import { observer } from "mobx-react"
import { Link } from "react-router-dom"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import _ from 'lodash';
import { can, Permission } from './RequirePermission'
import { Button } from 'reactstrap'
import CopySponsorsModal from './member/CopySponsorsModal'

@observer
export default class SponsoredList extends React.Component<{
  memberId: number
  onMemberInvalidated: () => void
}> {
  @observable private renderCopySponsorsModal = false

  @observable private member = createLazyResource<Member>(() => {
    return ApiClient.query(
      `
member {
  *
  sponsoredMembers {
    *
    
    category {
      *
    }
  }
}
      `,
      {
        where: [{ id: this.props.memberId }],
      }
    )
  }, response => {
    const member = new Member(response.data.member)
    member.sponsoredMembers = _.orderBy(member.sponsoredMembers.filter(m => m.status != 'Pending'), m => m.joinDate ? m.joinDate.format('YYYY/MM/MM') : '', ['desc'])
    return member
  })

  private invalidateMember = () => {
    this.member.invalidate().then(() => {
    })
    this.props.onMemberInvalidated()
  }

  private copySponsors = (sourceMember: Member) => {

  }

  render (): React.ReactNode {
    return <>
      <LazyResourcePanel resource={this.member}>
        {member => <>
          <table className="table table-striped">
            <thead>
            <tr>
              <th>Member</th>
              <th>Join Date</th>
              <th>Category</th>
              <th>Badge Color</th>
              <th>Phone Number</th>
              <th>Email Address</th>
            </tr>
            </thead>
            <tbody>
            {
              member.sponsoredMembers
                ? member.sponsoredMembers.map(sponsored => <tr key={sponsored.id}>
                  <td style={{ verticalAlign: 'middle' }}>
                    <div className="d-flex align-items-center">
                      <div>
                        <MemberProfileImage profileImageUrl={sponsored.profileImageUrl} size={35}/>
                      </div>
                      <div className="ml-2">
                        <Link to={route(Routes.network.member, { memberId: sponsored.id })}>{sponsored.fullName}</Link>
                      </div>
                    </div>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>{safeNull(() => sponsored.joinDate!.format('MM/DD/YYYY')) || '---'}</td>
                  <td style={{ verticalAlign: 'middle' }}>{safeNull(() => sponsored.category!.name) || '---'}</td>
                  <td style={{ verticalAlign: 'middle' }}>{sponsored.badgeColor || '---'}</td>
                  <td style={{ verticalAlign: 'middle' }}>{safeNull(() => sponsored.phoneNumber) || '---'}</td>
                  <td style={{ verticalAlign: 'middle' }}>{safeNull(() => sponsored.emailAddress) || '---'}</td>
                </tr>)
                : null
            }
            </tbody>
          </table>
          {
            (!member.sponsoredMembers || !member.sponsoredMembers.length)
              ? <div>This member has not sponsored any members</div>
              : null
          }
          {
            can(Permission.EditMemberNetwork, { memberId: member.id })
              ? <div className="form-buttons">
                <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    this.renderCopySponsorsModal = true
                  }}
                >Copy Sponsors</Button>
              </div>
              : null
          }
        </>}
      </LazyResourcePanel>
      {
        this.renderCopySponsorsModal
        ? <CopySponsorsModal
            onClosed={() => {
              this.renderCopySponsorsModal = false
              this.invalidateMember()
            }}
            memberId={this.props.memberId}
            />
            : null
      }
    </>
  }
}
