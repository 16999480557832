import { observer } from 'mobx-react'
import React from 'react'
import { observable, toJS } from 'mobx'
import Util from '../../common/Util'
import ApiClient from '../../api/ApiClient'
import LoadingPanel from '../LoadingPanel'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { route } from '../../routes/routes'
import { Routes } from '../../routes/AppRoutes'
import TableView, { TableViewColumn } from '../table-view/TableView'
import { ArrayTableViewAdapter } from '../table-view/ArrayTableViewAdapter'

type GuestData = {
  id: number
  fullName: string
  email: string | null
  phone: string | null
  sourceMember: {
    id: number
    fullName: string
  } | null
  targetChapter: {
    id: number
    name: string
  } | null
}

type GuestListData = {
  guests: GuestData[]
}

type Props = {
  url: string
  title: string
}

@observer
export default class GuestList extends React.Component<Props> {
  @observable
  private guests?: GuestListData
  @observable
  private error?: string
  @observable
  private loading = false

  componentDidMount () {
    this.loadData().then()
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (!_.isEqual(toJS(prevProps.url), toJS(this.props.url))) {
      this.loadData().then()
    }
  }

  private loadData = async () => {
    try {
      this.error = undefined
      this.loading = true

      const response = await ApiClient.getInstance().get(this.props.url)

      this.guests = response.data

      this.loading = false
    } catch (err) {
      this.error = Util.extractErrorMessage(err.response)
    }
  }

  get columns (): TableViewColumn[] {
    return [
      {
        accessor: 'fullName',
        title: 'Name',
        sortable: true,
        renderItem: (name: string, guest: GuestData) => <Link to={route(Routes.system.guests.show, { id: guest.id })}>{guest.fullName}</Link>,
      },
      {
        accessor: 'email',
        sortable: true,
        title: 'Email',
      },
      {
        accessor: 'phone',
        sortable: true,
        title: 'Phone',
      },
      {
        accessor: 'targetChapter',
        title: 'Chapter',
        sortable: true,
        renderItem: (chapter: {id: number, name: string} | null) => chapter ? <Link to={route(Routes.system.chapters.show, { id: chapter.id })}>{chapter.name}</Link> : '',
      },
      {
        accessor: 'sourceMember',
        title: 'Sponsor',
        sortable: true,
        renderItem: (sponsor: {id: number, fullName: string} | null) => sponsor ? <Link to={route(Routes.system.members.show, { id: sponsor.id })}>{sponsor.fullName}</Link> : '',
      },
    ]
  }

  render () {
    return <div>
      <h2>{this.props.title}</h2>
      <div>
        <LoadingPanel loading={this.loading} loaded={!!this.guests} error={this.error}>
          {
            this.guests
              ? <TableView
                hideItemCount={true}
                hideRowNumbers={true}
                adapter={new ArrayTableViewAdapter(this.guests.guests, {
                  sort: {
                    targetChapter: (guest: GuestData) => guest.targetChapter ? guest.targetChapter.name : '',
                    sourceMember: (guest: GuestData) => guest.sourceMember ? guest.sourceMember.fullName : '',
                  }
                })}
                columns={this.columns}
              />
              : null
          }
        </LoadingPanel>
      </div>
    </div>
  }
}
