import BaseModel from "./BaseModel"
import { Moment } from "moment"

export enum PaymentMethodType {
  Card = 'Card',
  BankAccount = 'Bank Account',
}

export default class PaymentMethod extends BaseModel {
  id: number
  paymentMethodType: PaymentMethodType
  createdAt: Moment
  description: string
  isDefault: boolean
  isCompany: boolean
  expirationMonth: string | null
  expirationYear: string | null

  constructor (data = {}) {
    super();
    this.init(data);
  }

  get optionDescription () {
    return `${this.description}${this.paymentMethodType === PaymentMethodType.Card ? ` (Exp. ${this.expirationMonth}/${this.expirationYear})` : ''}`
  }

  getCasts (): {} {
    return {
      id: 'number',
      isDefault: 'boolean',
      isCompany: 'boolean',
      createdAt: 'datetime',
    }
  }
}