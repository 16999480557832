import { Moment } from "moment";
import BaseModel from "./BaseModel";
import Member from "./Member";



export default class Testimony extends BaseModel {
  id: number
  testimony: string | null
  sourceMemberId: number
  targetMemberId: number
  datePassed: Moment
  isApproved: Boolean

  sourceMember: Member
  targetMember: Member


  constructor (data?: {}) {
    super()

    data && this.init(data)

  }


  setSourceMember (m?: {}) {
    return m ? new Member(m) : null
  }

  setTargetMember (m?: {}) {
    return m ? new Member(m) : null
  }

  getCasts () {
    return {
      createdAt: 'datetime',
      updatedAt: 'datetime',
      datePassed: 'datetime',
    }
  }

}