import { observer } from "mobx-react"
import * as React from "react"
import { SyntheticEvent } from "react"
import BaseView from "./BaseView"
import { autorun, computed, observable } from "mobx"
import FormHelper from "../forms/FormHelper"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import { Prompt, Redirect } from "react-router"
import ButtonLoader from "../components/ButtonLoader"
import Util, { logException, modelToCamelCase, modelToSnakeCase } from "../common/Util"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import Member from "../models/Member"
import { Alert, Button } from "reactstrap"
import classNames from "classnames"
import * as _ from 'lodash'
import { Link } from "react-router-dom"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import * as AsyncHelpers from "../api/AsyncHelpers"
import Chapter from "../models/Chapter"
import Category from "../models/Category"
import { SelectOption } from "../components/inputs/SelectInput"
import Config from "../common/Config"
import { LinkContainer } from "react-router-bootstrap"
import { toast } from "react-toastify"
import BackLink from "../components/BackLink"
import { placeResultToAddress } from "../common/GoogleHelper"
import TableView, { TableViewColumn } from "../components/table-view/TableView"
import { ArrayTableViewAdapter } from "../components/table-view/ArrayTableViewAdapter"

type DupeFormState = {
  firstName: string
  lastName: string
  email: string
  phone: string
}

type AvailFormState = {
  chapterId?: number
  categoryId?: number
}

type MemberFormState = {
  prefix?: string
  membershipType?: string
  memberType?: string
  firstName: string
  lastName: string
  suffix?: string
  gender?: string
  email: string
  phone: string
  activateMember: boolean

  business: {
    name: string
    phone: string
    fax: string
    email: string
    websiteUrl: string
    address: string
    address2: string
    country: string
    state: string
    city: string
    zipCode: string
  }

  sponsor: {
    chapterId?: string
    memberId?: string
  }
}

const duplicateMemberColumns: TableViewColumn[] = [
  {
    title: 'Member',
    accessor: 'name',
    renderItem: (value: string, item: Member) => {
      return (<div className={classNames({ 'text-muted': item.isDisabled() })}>
        {item.getDisplayName()}{item.isDisabled() && ` (${item.status})`}
      </div>)
    },
  },
  {
    title: 'Chapter',
    accessor: 'chapter.name',
  },
  {
    title: 'Category',
    accessor: 'category.name',
  },
  {
    title: 'Actions',
    headerClassName: 'justify-content-center',
    renderItem: (value: any, item: Member) => {
      return (
        <div className="table-actions">
          <Link type="link" to={{ pathname: route(Routes.system.members.show, { id: item.id }), state: { id: item.id } }}><i className="fa fa-search"/></Link>
        </div>
      )
    },
    sortable: false,
  },
]

@observer
export default class CreateMemberView extends BaseView {
  @observable private dupeFormState = new FormState<DupeFormState>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  })
  @observable private dupeFormErrors = new ErrorBag()
  private dupeFormHelper = new FormHelper(this.dupeFormState, this.dupeFormErrors)

  @observable private availFormState = new FormState<AvailFormState>({
    chapterId: undefined,
    categoryId: undefined,
  })
  @observable private availFormErrors = new ErrorBag()
  private availFormHelper = new FormHelper(this.availFormState, this.availFormErrors)

  @observable private memberFormState = new FormState<MemberFormState>({
    membershipType: undefined,
    memberType: undefined,
    prefix: undefined,
    firstName: '',
    lastName: '',
    suffix: '',
    gender: undefined,
    email: '',
    phone: '',
    activateMember: false,
    business: {
      name: '',
      phone: '',
      fax: '',
      email: '',
      websiteUrl: '',
      address: '',
      address2: '',
      country: '',
      state: '',
      city: '',
      zipCode: '',
    },

    sponsor: {
      chapterId: undefined,
      memberId: undefined,
    },
  })
  @observable private memberFormErrors = new ErrorBag()
  private memberFormHelper = new FormHelper(this.memberFormState, this.memberFormErrors)

  @observable private submitting = false
  @observable private hasCheckedForDuplicates = false
  @observable private possibleDuplicateMembers: Member[] = []
  @observable private isFormVisible = false
  @observable private selectedChapter?: SelectOption
  @observable private selectedCategory?: SelectOption
  @observable private redirect = false

  @observable private member?: Member

  componentWillMount (): void {
    autorun(() => {
      this.availFormState.get('chapterId')
      this.availFormState.set('categoryId', undefined)
      this.selectedCategory = undefined
    })
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>Add New Member</h1>
      </>
    )
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    if (this.isFormVisible) {
      const data = modelToSnakeCase(this.memberFormState.toObject())

      Object.assign(data, {
        chapter_id: this.selectedChapter!.value,
        category_id: this.selectedCategory!.value,
      })

      this.memberFormErrors.clearErrors()
      this.submitting = true
      AppStateStore.showModalSpinner()

      ApiClient.members.create(data).then((response) => {
        toast.success('Member created')
        this.member = new Member(response.data.member)
        this.redirect = true
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.memberFormErrors.addErrors(errors.getErrorList())
      })
        .catch(ex => {
          logException(ex)
          this.memberFormErrors = new ErrorBag().addError('_form', 'A server error has occurred')
        })
        .then(() => {
          AppStateStore.dismissModalSpinner()
          this.submitting = false
        })
    } else if (!this.hasCheckedForDuplicates) {
      this.checkForDuplicates()
    } else {
      // shouldn't be able to get here
    }
  }

  private checkForDuplicates = () => {
    this.dupeFormErrors.clearErrors()
    this.submitting = true
    this.possibleDuplicateMembers = []
    AppStateStore.showModalSpinner()

    ApiClient.members.checkDuplicates(modelToSnakeCase(this.dupeFormHelper.toObject())).then((response) => {
      this.possibleDuplicateMembers = response.data.members.map((r: {}) => new Member(modelToCamelCase(r)))

      if (!this.possibleDuplicateMembers.length) {
        this.hasCheckedForDuplicates = true
      }
    }, error => {
      const errors = new ErrorBag()
      Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      })

      this.dupeFormErrors.addErrors(errors.getErrorList())
    })
      .catch(ex => {
        logException(ex)
        this.dupeFormErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private skipDuplicates = () => {
    AppStateStore.showConfirmationModal('Confirm', 'Are you sure you want to proceed with creating a new member?', (result, modal) => {
      if (result) {
        this.hasCheckedForDuplicates = true
      }

      modal.hide()
    })
  }

  private showForm = () => {
    this.isFormVisible = true

    this.memberFormState.setAll({
      firstName: this.dupeFormState.get('firstName'),
      lastName: this.dupeFormState.get('lastName'),
      phone: this.dupeFormState.get('phone'),
      email: this.dupeFormState.get('email'),
    })

    window.scrollTo(0, 0)
  }

  renderContentBody (): React.ReactNode {
    return this.redirect
      ? this.renderRedirect()
      : (
        this.isFormVisible
          ? this.renderForm()
          : this.renderPrefill()
      )
  }

  private renderRedirect = () => <Redirect to={this.member ? route(Routes.system.members.show, { id: this.member.id }) : route(Routes.system.members.index)}/>

  private fillAddress = (place: google.maps.places.PlaceResult) => {
    this.memberFormState.setAll(placeResultToAddress(place))
  }

  private fillBusinessAddress = (place: google.maps.places.PlaceResult) => {
    this.memberFormState.setAll(placeResultToAddress(place), 'business.')
  }

  private renderForm = () => {
    return (
      <>
        <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
          {this.memberFormState.isDirty && <Prompt message="Are you sure you want to leave this page? You will lose any unsaved changes!"/>}
          <div className="form-row">
            <div className="col-sm-2">
              {this.memberFormHelper.renderDatePickerInput({
                name: 'joinDate',
                label: 'Join Date',
              })}
            </div>
            <div className="col-sm-5">
              {this.memberFormHelper.renderSelectInput({
                placeholder: '(choose an option)',
                name: 'membershipType',
                label: 'Membership Type',
                options: Config.MEMBERSHIP_TYPE_OPTIONS.map(o => ({ value: o, text: o })),
              })}
            </div>
            {
              // TODO: implement member type when creating?
            }
            {/*<div className="col-sm-5">*/}
            {/*  {this.memberFormHelper.renderSelectInput({*/}
            {/*    placeholder: '(choose an option)',*/}
            {/*    name: 'memberType',*/}
            {/*    label: 'Member Type',*/}
            {/*    options: Config.MEMBER_TYPE_OPTIONS.map(o => ({ value: o, text: o })),*/}
            {/*  })}*/}
            {/*</div>*/}
          </div>
          <div className="section-title">
            <div className="form-row">
              <div className="col-sm-4 text-center"><b>Chapter</b></div>
              <div className="col-sm-4 text-center"><b>State</b></div>
              <div className="col-sm-4 text-center"><b>Official LeTip Business Category</b></div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-sm-4 text-center">{_.get(this.selectedChapter, 'label')}</div>
            <div className="col-sm-4 text-center">{_.get(this.selectedChapter, 'meta.state')}</div>
            <div className="col-sm-4 text-center">{_.get(this.selectedCategory, 'label')}</div>
          </div>
          <br/>

          <div className="section-title"><h6>Member Information</h6></div>

          <h6 className="text-muted">Contact Information</h6>

          <div className="form-row">
            <div className="col-sm-2">
              {this.memberFormHelper.renderSelectInput({
                placeholder: '(choose)',
                name: 'prefix',
                label: 'Prefix',
                options: Config.NAME_PREFIX_OPTIONS.map(o => ({ value: o, text: o })),
              })}
            </div>
            <div className="col-sm-3">
              {this.memberFormHelper.renderTextInput({
                name: 'firstName',
                type: 'text',
                label: 'First Name',
              })}
            </div>
            <div className="col-sm-3">
              {this.memberFormHelper.renderTextInput({
                name: 'lastName',
                type: 'text',
                label: 'Last Name',
              })}
            </div>
            <div className="col-sm-2">
              {this.memberFormHelper.renderSelectInput({
                placeholder: '(choose)',
                name: 'suffix',
                label: 'Suffix',
                options: Config.NAME_SUFFIX_OPTIONS.map(o => ({ value: o, text: o })),
              })}
            </div>
            <div className="col-sm-2">
              {this.memberFormHelper.renderSelectInput({
                placeholder: '(choose)',
                name: 'gender',
                label: 'Gender',
                options: Config.GENDER_OPTIONS.map(o => ({ value: o, text: o })),
              })}
            </div>
          </div>
          <div className="form-row">
            <div className="col-sm-6">
              {this.memberFormHelper.renderTextInput({
                name: 'email',
                type: 'email',
                label: 'Email Address',
              })}
            </div>
            <div className="col-sm-6">
              {this.memberFormHelper.renderTextInput({
                name: 'phone',
                type: 'text',
                label: 'Phone Number',
              })}
            </div>
          </div>
          {
            this.memberFormState.get('address')
              ? <>
                <div className="form-row">
                  <div className="col-sm-8">
                    {this.memberFormHelper.renderTextInput({
                      name: 'address',
                      type: 'text',
                      label: 'Street',
                      disabled: true,
                    })}
                  </div>
                  <div className="col-sm-4">
                    {this.memberFormHelper.renderTextInput({
                      name: 'address2',
                      type: 'text',
                      label: 'Suite / Room / Apt #',
                    })}
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-sm-3">
                    {this.memberFormHelper.renderTextInput({
                      name: 'city',
                      type: 'text',
                      label: 'City',
                      disabled: true,
                    })}
                  </div>
                  <div className="col-sm-3">
                    {this.memberFormHelper.renderTextInput({
                      name: 'state',
                      type: 'text',
                      label: 'State',
                      disabled: true,
                    })}
                  </div>
                  <div className="col-sm-3">
                    {this.memberFormHelper.renderTextInput({
                      name: 'zipCode',
                      type: 'text',
                      label: 'Zip Code',
                      disabled: true,
                    })}
                  </div>
                  <div className="col-sm-3">
                    {this.memberFormHelper.renderTextInput({
                      name: 'country',
                      type: 'text',
                      label: 'Country',
                      disabled: true,
                    })}
                  </div>
                </div>
              </>
              : null
          }

          <div className="section-title"><h6>Business Information</h6></div>

          <h6 className="text-muted">Contact Information</h6>

          <div className="form-row">
            <div className="col-sm-4">
              {this.memberFormHelper.renderTextInput({
                type: 'text',
                name: 'business.name',
                label: 'Business Name',
              })}
            </div>
            <div className="col-sm-4">
              {this.memberFormHelper.renderTextInput({
                type: 'text',
                name: 'business.phone',
                label: 'Business Phone',
              })}
            </div>
            <div className="col-sm-4">
              {this.memberFormHelper.renderTextInput({
                type: 'text',
                name: 'business.fax',
                label: 'Business Fax',
              })}
            </div>
          </div>
          <div className="form-row">
            <div className="col-sm-6">
              {this.memberFormHelper.renderTextInput({
                name: 'business.email',
                type: 'email',
                label: 'Business Email Address',
              })}
            </div>
            <div className="col-sm-6">
              {this.memberFormHelper.renderTextInput({
                name: 'business.websiteUrl',
                type: 'text',
                label: 'Business Website',
              })}
            </div>
          </div>

          <h6 className="text-muted">Address Information</h6>

          <div className="form-row">
            <div className="col-sm-8">
              {this.memberFormHelper.renderTextInput({
                name: 'business.address',
                type: 'text',
                label: 'Street',
              })}
            </div>
            <div className="col-sm-4">
              {this.memberFormHelper.renderTextInput({
                name: 'business.address2',
                type: 'text',
                label: 'Suite / Room / Apt #',
              })}
            </div>
          </div>

          <div className="form-row">
            <div className="col-sm-3">
              {this.memberFormHelper.renderTextInput({
                name: 'business.city',
                type: 'text',
                label: 'City',
              })}
            </div>
            <div className="col-sm-3">
              {this.memberFormHelper.renderTextInput({
                name: 'business.state',
                type: 'text',
                label: 'State',
              })}
            </div>
            <div className="col-sm-3">
              {this.memberFormHelper.renderTextInput({
                name: 'business.zipCode',
                type: 'text',
                label: 'Zip Code',
              })}
            </div>
            <div className="col-sm-3">
              {this.memberFormHelper.renderSelectInput({
                name: 'business.country',
                options: Config.COUNTRY_OPTIONS.map(c => ({ value: c, text: c })),
                label: 'Country',
              })}
            </div>
          </div>

          <div className="section-title"><h6>Sponsor Information</h6></div>

          <div className="form-row">
            <div className="col-sm-6">
              {this.memberFormHelper.renderAsyncSelectInput({
                name: 'sponsor.chapterId',
                label: 'Sponsor\'s Chapter',
                loadOptions: AsyncHelpers.loadChapterOptions,
              })}
            </div>
            {this.memberFormState.get('sponsor.chapterId') ? (
              <div className="col-sm-6" key={`sponsor-members-${this.memberFormState.get('sponsor.chapterId')}`}>
                {this.memberFormHelper.renderAsyncSelectInput({
                  name: 'sponsor.memberId',
                  label: 'Sponsor',
                  loadOptions: AsyncHelpers.loadMemberOptions(this.memberFormState.get('sponsor.chapterId')),
                })}
              </div>
            ) : null}
          </div>

          <br/>
          <div className="form-buttons">
            <LinkContainer to={route(Routes.system.members.index)}><Button type="button" color=""><i className="fa fa-ban"/> Cancel</Button></LinkContainer>
            <ButtonLoader type="submit" color="success" loading={this.submitting}><i className="fa fa-save"/> Add New Member</ButtonLoader>
          </div>
        </form>
      </>
    )
  }

  @computed
  private get duplicateMembersAdapter () {
    return new ArrayTableViewAdapter(this.possibleDuplicateMembers)
  }

  private renderPrefill = () => {
    return (
      <>
        <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
          <h5 className="text-muted">Basic Info</h5>
          <div className="form-row">
            <div className="col-sm-6">
              {this.dupeFormHelper.renderTextInput({
                name: 'firstName',
                type: 'text',
                label: 'First Name',
              })}
            </div>
            <div className="col-sm-6">
              {this.dupeFormHelper.renderTextInput({
                name: 'lastName',
                type: 'text',
                label: 'Last Name',
              })}
            </div>
          </div>
          <div className="form-row">
            <div className="col-sm-6">
              {this.dupeFormHelper.renderTextInput({
                name: 'email',
                type: 'email',
                label: 'Email Address',
              })}
            </div>
            <div className="col-sm-6">
              {this.dupeFormHelper.renderTextInput({
                name: 'phone',
                type: 'text',
                label: 'Phone Number',
              })}
            </div>
          </div>

          {!this.hasCheckedForDuplicates && !this.possibleDuplicateMembers.length ? (
            <div className="form-buttons">
              <ButtonLoader type="submit" color="primary" loading={this.submitting}>Continue</ButtonLoader>
            </div>
          ) : null}

          {!this.hasCheckedForDuplicates && this.possibleDuplicateMembers.length ? (
            <>
              <br/>
              <Alert color="warning">
                <i className="fa fa-warning"/> Possible duplicate member(s) found
              </Alert>
              <TableView
                adapter={this.duplicateMembersAdapter}
                columns={duplicateMemberColumns}
              />
              <br/>
              <div className="form-buttons">
                <Button type="button" color="secondary" onClick={() => this.possibleDuplicateMembers = []}>Back</Button>
                <Button type="button" color="primary" onClick={this.skipDuplicates}>Proceed</Button>
              </div>
            </>
          ) : null}

          {this.hasCheckedForDuplicates ? this.renderAvailabilityConfirmation() : null}
        </form>
      </>
    )
  }

  private renderAvailabilityConfirmation = () => {
    return (
      <>
        <h5 className="text-muted">Availability Confirmation</h5>
        <div className="form-row">
          <div className="col-sm-6">
            {this.availFormHelper.renderAsyncSelectInput({
              name: 'chapterId',
              label: 'Chapter',
              loadOptions: AsyncHelpers.loadChapterOptions,
              onChange: (option) => this.selectedChapter = option,
            })}
          </div>
          {this.availFormState.get('chapterId') ? (
            <div className="col-sm-6" key={`avail-categories-${this.availFormState.get('chapterId')}`}>
              {this.availFormHelper.renderAsyncSelectInput({
                name: 'categoryId',
                label: 'Open Categories',
                loadOptions: AsyncHelpers.loadCategoryOptions(this.availFormState.get('chapterId')),
                onChange: (option) => this.selectedCategory = option,
              })}
            </div>
          ) : null}
        </div>
        {this.selectedChapter && this.selectedCategory ? (
          <>
            <br/>
            <div className="form-buttons">
              <Button type="button" color="primary" onClick={this.showForm}>Proceed</Button>
            </div>
          </>
        ) : null}
      </>
    )
  }
}
