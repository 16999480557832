import { observer } from "mobx-react"
import * as React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { computed, observable } from "mobx"
import ButtonLoader from "../ButtonLoader"
import { loadMemberOptions } from "../../api/AsyncHelpers"
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import FormHelper from "../../forms/FormHelper"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import Util, { modelToSnakeCase, safeNull } from "../../common/Util"
import { toast } from "react-toastify"
import { EventBusContext } from "../../common/EventBus"
import AppStateStore from "../../stores/AppStateStore"
import AuthStore from "../../stores/AuthStore"
import FormError from "../FormError"

type Props = {
  isOpen: boolean
  onClosed: () => void
  toggle: () => void
}

@observer
export default class SendMessageModal extends React.Component<Props> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  @observable private submitting = false

  @observable private formState = new FormState({
    recipientId: undefined,
    message: '',
    doNotAllowReplies: false,
    sendToChapter: false,
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable private showAllMembers = false

  @computed get defaultChapterId () {
    return safeNull(() => AuthStore.getUser()!.chapter.id)
  }

  private toggle = () => {
    if (!this.submitting) {
      this.props.toggle()
    }
  }

  private submit = () => {
    this.submitting = true
    this.formErrors.clearErrors()

    ApiClient.getInstance().post(ApiRoutes.messaging.sendMessage, modelToSnakeCase(this.formHelper.toObject()))
      .then(() => {
        toast.success('Message has been sent')
        this.context.eventBus.dispatch('messages-invalidated')
        this.props.toggle()
      })
      .catch(error => Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      }))
      .then(() => {
        this.submitting = false
      })
  }

  render () {
    return <Modal
      isOpen={this.props.isOpen}
      toggle={this.toggle}
      onClosed={this.props.onClosed}
      size="lg">
      <ModalHeader toggle={this.toggle}
      >
        Send a Message
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            {
              (this.defaultChapterId && !this.formState.get('sendToChapter'))
                ? <div className="form-check form-checkbox">
                  <label>
                    <input type="checkbox"
                           className="form-check-input"
                           checked={this.showAllMembers}
                           onChange={ev => this.showAllMembers = ev.target.checked}
                    />
                    <span className="label-text">Show entire network</span>
                  </label>
                </div>
                : null
            }
            {
              AuthStore.getUser()!.isBoardMember
                ? <div className="form-check form-checkbox">
                  <label>
                    <input type="checkbox"
                           className="form-check-input"
                           checked={this.formState.get('sendToChapter')}
                           onChange={ev => {
                             this.formState.set('sendToChapter', ev.target.checked)
                             if (this.formState.get('sendToChapter')) {
                               this.showAllMembers = false
                             }
                           }}
                    />
                    <span className="label-text">Send to all members of {AuthStore.getUser()!.chapter.name}</span>
                  </label>
                </div>
                : null
            }
            {
              this.formState.get('sendToChapter')
                ? <div className="form-row">
                  <div className="col-sm-12">
                    <div className="form-check form-checkbox">
                      <label>
                        <input type="checkbox"
                               className="form-check-input"
                               name="doNotAllowReplies"
                               checked={this.formState.get('doNotAllowReplies')}
                               onChange={this.formState.onChange}
                        />
                        <span className="label-text">Do not allow replies</span>
                      </label>
                    </div>
                    <FormError errors={this.formErrors} fieldName="doNotAllowReplies"/>
                  </div>
                </div>
                : <div key={`recipients-${this.showAllMembers ? 'all' : 'chapter'}`}>
                  {this.formHelper.renderAsyncSelectInput({
                    label: 'Send To',
                    name: 'recipientId',
                    isMulti: true,
                    loadOptions: loadMemberOptions(this.showAllMembers ? undefined : this.defaultChapterId),
                    disabled: this.submitting,
                  })}
                </div>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {this.formHelper.renderTextAreaInput({
              label: 'Message',
              name: 'message',
              height: 150,
              disabled: this.submitting,
            })}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="secondary"
          disabled={this.submitting}
          onClick={this.toggle}>Cancel</Button>
        <ButtonLoader
          type="button"
          color="primary"
          loading={this.submitting}
          onClick={this.submit}><i className="fa fa-send"/> Send Message</ButtonLoader>
      </ModalFooter>
    </Modal>
  }
}
