import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Transaction from "./Transaction"
import InvoiceLineItem from "./InvoiceLineItem"
import Member from "./Member"
import Chapter from "./Chapter"

export enum InvoiceStatus {
  Paid = 'Paid',
  Unpaid = 'Unpaid',
}

export default class Invoice extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  memberId: number | null
  chapterId: number | null
  description: string
  total: number
  amountDue: number
  paidAt: Moment | null
  status: InvoiceStatus
  legacyInvoiceId: number | null
  dueDate: Moment | null
  lateFee: number | null
  lateDate: Moment | null
  lateFeeAppliedAt: Moment | null
  invoiceType: string
  payInvoiceUrl: string
  isPaymentPlan: boolean

  billingContactName: string
  billingContactBusinessName: string
  billingContactAddress: string
  billingContactAddress2: string
  billingContactCity: string
  billingContactState: string
  billingContactZipCode: string
  billingContactCountry: string

  shippingContactName: string
  shippingContactBusinessName: string
  shippingContactAddress: string
  shippingContactAddress2: string
  shippingContactCity: string
  shippingContactState: string
  shippingContactZipCode: string
  shippingContactCountry: string

  transactions: Transaction[]
  invoiceLineItems: InvoiceLineItem[]
  member: Member | null
  chapter: Chapter | null

  get invoiceNumber () {
    return this.legacyInvoiceId ? `L-${this.legacyInvoiceId}` : this.id
  }

  getCasts () {
    return {
      createdAt: 'datetime',
      updatedAt: 'datetime',
      paidAt: 'datetime',
      total: 'number',
      amountDue: 'number',
      legacyInvoiceId: 'number',
      dueDate: 'datetime',
      lateFee: 'number',
      lateDate: 'datetime',
      lateFeeAppliedAt: 'datetime',
      isPaymentPlan: 'boolean',
    }
  }

  setMember (m?: {}) {
    return m ? new Member().init(m) : null
  }

  setChapter (c?: {}) {
    return c ? new Chapter().init(c) : null
  }

  setTransactions (t?: []) {
    return t ? t.map(t => new Transaction().init(t)) : []
  }

  setInvoiceLineItems (i?: []) {
    return i ? i.map(i => new InvoiceLineItem().init(i)) : []
  }
}
