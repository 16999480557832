import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import Util, { createLazyResource, formatCurrency, modelToSnakeCase, safeNull } from "../common/Util"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import Invoice from "../models/Invoice"
import { computed, observable } from "mobx"
import LazyResourcePanel from "./LazyResourcePanel"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import AppStateStore from "../stores/AppStateStore"
import { route } from "../routes/routes"
import { toast } from "react-toastify"
import { EventBusContext } from "../common/EventBus"
import PaymentMethod, { PaymentMethodType } from "../models/PaymentMethod"
import * as _ from "lodash"
import AddPaymentMethodModal from "./AddPaymentMethodModal"
import Chapter from "../models/Chapter"
import Member from "../models/Member"
import RequirePermission, { Permission } from "./RequirePermission"
import { TransactionStatus } from "../models/Transaction"
import AuthStore from "../stores/AuthStore"
import { UserRole } from "../models/AuthUser"
import AchAuthorizationVerbiage from './AchAuthorizationVerbiage'

type InvoiceDetailModalProps = {
  isOpen: boolean
  toggle: () => void
  invoiceId: number
  onClosed: () => void
}

const initialPaymentFormState = {
  paymentMethod: undefined,
  amount: '',
  description: '',
  checkInformation: '',
}

@observer
export class InvoiceDetailModal extends React.Component<InvoiceDetailModalProps> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  @observable private showAddPaymentMethodModal = false
  @observable private renderAddPaymentMethodModal = false

  @observable private invoice = createLazyResource<Invoice>(() => {
    return ApiClient.query(
      `
invoice {
  *
  invoiceLineItems {
    *
  }
  
  transactions {
    *
    
    paymentMethod {
      *
    }
  }
  
  member {
    *
    billingEmailAddress
    
    paymentMethods {
      *
    }    
  }
  
  chapter {
    *
    
    paymentMethods {
      *
    }
  }
}
      `,
      {
        where: [{ id: this.props.invoiceId }]
      }
    )
  }, response => new Invoice().init(response.data.invoice))

  @computed get entity (): Chapter | Member | null {
    return this.invoice.current ?
      this.invoice.current.member || this.invoice.current.chapter
      : null
  }

  @computed
  private get paymentMethodOptions () {
    let options: {value: any, text: string}[] = safeNull(() => (this.entity ? this.entity!.paymentMethods.current! : []).map(pm => ({ value: String(pm.id), text: pm.optionDescription }))) || []

    if (AuthStore.getUser()!.hasRole(UserRole.Admin)) {
      options = options.concat([
        {
          value: 'personal_check',
          text: 'Personal Check',
        },
        {
          value: 'company_check',
          text: 'Company Check',
        },
        {
          value: 'money_order',
          text: 'Cashier\'s Check or Money Order',
        },
      ])
    }

    return options
  }

  @computed get selectedPaymentMethod () {
    return _.find<PaymentMethod>((this.entity ? this.entity!.paymentMethods.current : []), pp => pp.id == this.paymentFormState.get('paymentMethod'))
  }

  @observable private showSendInvoiceModal = false
  @observable private renderSendInvoiceModal = false

  @observable private sendInvoiceFormState = new FormState({
    email: '',
  })

  @observable private sendInvoiceFormErrors = new ErrorBag()

  private sendInvoiceFormHelper = new FormHelper(this.sendInvoiceFormState, this.sendInvoiceFormErrors)

  @observable showPaymentForm = false
  @observable
  private paymentFormState = new FormState(initialPaymentFormState)
  @observable
  private paymentFormErrors = new ErrorBag()

  private paymentFormHelper = new FormHelper(this.paymentFormState, this.paymentFormErrors)

  private initialAdjustmentFormState = {
    description: '',
    amount: '',
  }
  @observable private showAdjustmentForm = false
  @observable private adjustmentFormState = new FormState({
    description: '',
    amount: '',
  })
  @observable private adjustmentFormErrors = new ErrorBag()

  private adjustmentFormHelper = new FormHelper(this.adjustmentFormState, this.adjustmentFormErrors)

  private submitAdjustmentForm = (ev: SyntheticEvent) => {
    ev.preventDefault()
    this.adjustmentFormErrors.clearErrors()

    AppStateStore.showModalSpinner()
    ApiClient.getInstance().post(route(ApiRoutes.invoices.applyAdjustment, { invoiceId: this.invoice.current!.id }), modelToSnakeCase(this.adjustmentFormHelper.toObject()))
      .then(() => {
        toast.success('Adjustment Applied')
        this.context.eventBus.dispatch('invoice-invalidated')
        this.showAdjustmentForm = false
      })
      .catch(error => {
        Util.handleErrorResponse(error.response, this.adjustmentFormErrors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
      })
  }

  componentDidMount (): void {
    this.context.eventBus.on('invoice-invalidated', this.onInvoiceInvalidated)

    this.invoice.get().then(invoice => {
      if (this.entity) {
        this.entity!.paymentMethods.get().then(paymentMethods => {
          const defaultPaymentMethod = _.find<PaymentMethod>(paymentMethods, p => p.isDefault)

          if (defaultPaymentMethod) {
            this.paymentFormState.set('paymentMethod', String(defaultPaymentMethod.id))
          }
        })
      }
    })
  }

  componentWillUnmount (): void {
    this.context.eventBus.remove(this.onInvoiceInvalidated)
  }

  private onInvoiceInvalidated = () => {
    this.invoice.invalidate()
  }

  componentDidUpdate (prevProps: Readonly<InvoiceDetailModalProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.invoiceId !== prevProps.invoiceId) {
      this.invoice.invalidate().then(() => {
      })
    }
  }

  @computed get isPaymentFormValid () {
    return this.paymentFormState.get('amount') && this.paymentFormState.get('amount').length && this.paymentFormState.get('paymentMethod')
  }

  private showSendInvoice = () => {
    if (this.invoice.current) {
      if (this.invoice.current.member) {
        this.sendInvoiceFormState.set('email', this.invoice.current.member.billingEmailAddress || '')
      }

      this.sendInvoiceFormErrors.clearErrors()

      this.showSendInvoiceModal = true
      this.renderSendInvoiceModal = true
    }
  }

  private sendInvoice = () => {
    AppStateStore.showModalSpinner()
    this.sendInvoiceFormErrors.clearErrors()

    ApiClient.getInstance()
      .post(route(ApiRoutes.invoices.send, { invoiceId: this.invoice.current!.id }), modelToSnakeCase(this.sendInvoiceFormHelper.toObject()))
      .then(() => {
        toast.success('The invoice has been sent')
        this.context.eventBus.dispatch('invoice-invalidated')
        this.sendInvoiceFormState.set('email', '')
        this.showSendInvoiceModal = false
      })
      .catch(error => Util.handleErrorResponse(error.response, this.paymentFormErrors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      }))
      .then(() => {
        AppStateStore.dismissModalSpinner()
      })
  }

  private payInvoice = (ev: SyntheticEvent) => {
    ev.preventDefault()

    const paymentMethodDescription = ['personal_check', 'company_check', 'money_order'].indexOf(this.paymentFormState.get('paymentMethod')) > -1
      ? _.startCase(this.paymentFormState.get('paymentMethod'))
      : safeNull(() => _.find<PaymentMethod>((this.entity ? this.entity!.paymentMethods.current : []), pp => pp.id == this.paymentFormState.get('paymentMethod'))!.optionDescription)

    if (paymentMethodDescription) {
      AppStateStore.showConfirmationModal('Pay Invoice', `Are you sure you want to pay ${formatCurrency(this.paymentFormState.get('amount'))} using your ${paymentMethodDescription}`, (result, modal) => {
        modal.hide()

        if (result) {
          AppStateStore.showModalSpinner()
          this.paymentFormErrors.clearErrors()

          ApiClient.getInstance()
            .post(route(ApiRoutes.invoices.payInvoice, { invoiceId: this.invoice.current!.id }), modelToSnakeCase(this.paymentFormHelper.toObject()))
            .then(() => {
              toast.success('The invoice has been paid')
              this.context.eventBus.dispatch('invoice-invalidated')
              this.paymentFormState.setAll(initialPaymentFormState)
              this.showPaymentForm = false
            })
            .catch(error => Util.handleErrorResponse(error.response, this.paymentFormErrors, undefined, (response, message) => {
              this.context.eventBus.dispatch('invoice-invalidated')
              AppStateStore.showAlertModal('Error', message, m => {
                m.hide()
              })
              return true
            }))
            .then(() => {
              AppStateStore.dismissModalSpinner()
            })
        }
      })
    } else {
      AppStateStore.showAlertModal('Error', 'Payment method is required')
    }
  }

  render (): React.ReactNode {
    const { isOpen, toggle } = this.props

    return <>
      <Modal isOpen={isOpen} size="lg" onClosed={this.props.onClosed}>
        <ModalHeader toggle={toggle}>
          Invoice Detail
        </ModalHeader>
        <ModalBody>
          {
            this.props.invoiceId
              ? <LazyResourcePanel resource={this.invoice}>
                {invoice => <>
                  <div className="row">
                    <div className="col-8">
                      <b>Invoice #</b><br/>
                      {invoice.legacyInvoiceId ? `L-${invoice.legacyInvoiceId}` : invoice.id}
                    </div>
                    <div className="col-4">
                      <b>Date</b><br/>
                      {invoice.createdAt.format('MM/DD/YYYY')}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <b>Description</b><br/>
                      {invoice.description}
                    </div>
                    <div className="col-4">
                      <b>Paid On</b><br/>
                      {invoice.paidAt ? invoice.paidAt.format('MM/DD/YYYY') : '---'}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <b>Amount Due</b><br/>
                      {formatCurrency(invoice.amountDue)}
                    </div>
                    <div className="col-4">
                      <b>Due On</b><br/>
                      {invoice.dueDate ? invoice.dueDate.format('MM/DD/YYYY') : '---'}
                    </div>
                  </div>
                  <br/>
                  <table className="table table-striped">
                    <thead>
                    <tr>
                      <th>Line Item Description</th>
                      <th className="text-right">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    {invoice.invoiceLineItems.map(lineItem => <tr key={lineItem.id}>
                      <td>{lineItem.description}</td>
                      <td className="text-right">{formatCurrency(lineItem.amount)}</td>
                    </tr>)}
                    </tbody>
                  </table>
                  {
                    invoice.transactions.length
                      ? <>
                        <br/>
                        <h6 className="text-muted">Transactions</h6>
                        <table className="table table-striped">
                          <thead>
                          <tr>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Method</th>
                            <th>Memo</th>
                            <th className="text-right">Amount</th>
                          </tr>
                          </thead>
                          <tbody>
                          {invoice.transactions.map(transaction => <tr key={transaction.id}>
                            <td>
                              {transaction.createdAt.format('MM/DD/YYYY')}
                            </td>
                            <td>
                              {transaction.description}
                              {
                                [TransactionStatus.Error, TransactionStatus.Declined].indexOf(transaction.status) > -1
                                  ? ` (${transaction.status})`
                                  : null
                              }
                            </td>
                            <td>
                              {transaction.paymentMethod ? transaction.paymentMethod.description : '---'}
                            </td>
                            <td>
                              {transaction.memo || '---'}
                            </td>
                            <td className="text-right">{formatCurrency(transaction.amount)}</td>
                          </tr>)}
                          </tbody>
                        </table>
                      </>
                      : null
                  }
                  {
                    (!invoice.paidAt && !this.showAdjustmentForm && !this.showPaymentForm)
                      ? <div className="form-buttons">
                        {
                          AuthStore.getUser()!.hasRole(UserRole.Admin)
                            ? <Button
                              type="button"
                              color="primary"
                              onClick={() => {
                                this.adjustmentFormState.setAll(this.initialAdjustmentFormState)
                                this.showAdjustmentForm = true
                              }}
                            >Apply Adjustment</Button>
                            : null
                        }

                        &nbsp;
                        <a
                          className="btn btn-primary"
                          target="_blank"
                          href={invoice.payInvoiceUrl}
                          onClick={this.props.toggle}
                        >
                          Pay Invoice
                        </a>

                        {
                          AuthStore.getUser()!.hasRole(UserRole.Admin)
                          ? <>
                            &nbsp;
                              <Button
                                type="button"
                                color="success"
                                onClick={() => {
                                  this.paymentFormState.set('amount', String(invoice.amountDue))
                                  this.paymentFormState.set('description', 'Web Payment')
                                  this.showPaymentForm = true
                                }}
                              >Apply Payment</Button>
                            </>
                            : null
                        }
                      </div>
                      : null
                  }
                  {
                    this.showAdjustmentForm
                      ? <form onSubmit={this.submitAdjustmentForm} className="p-4" style={{ backgroundColor: '#eee' }}>
                        <div className="form-row">
                          <div className="col-6">
                            {
                              this.adjustmentFormHelper.renderTextInput({
                                name: 'description',
                                label: 'Description',
                              })
                            }
                          </div>
                          <div className="col-6">
                            {
                              this.adjustmentFormHelper.renderTextInput({
                                prepend: '$',
                                name: 'amount',
                                label: 'Amount',
                              })
                            }
                          </div>
                        </div>
                        <div className="form-row">
                          <p className="help-text">You can enter a negative value to lower the invoice amount due, and a positive amount to raise the invoice amount due.</p>
                        </div>
                        <div className="form-buttons">
                          <Button
                            type="button"
                            color="secondary"
                            onClick={() => this.showAdjustmentForm = false}
                          >Cancel</Button>
                          <Button
                            type="submit"
                            color="primary"
                          >Apply Adjustment</Button>
                        </div>
                      </form>
                      : null
                  }

                  {
                    (!invoice.paidAt && invoice.amountDue > 0)
                      ? <>
                        <hr/>
                        {
                          this.showPaymentForm
                            ? <>
                              <form onSubmit={this.payInvoice} className="p-4" style={{ backgroundColor: '#eee' }}>
                                <div className="form-row">
                                  <div className="col-12">
                                    <label>Payment Method</label>
                                    {this.paymentFormHelper.renderSelectInput({
                                      name: 'paymentMethod',
                                      options: this.paymentMethodOptions,
                                      append: <Button
                                        type="button"
                                        color="primary"
                                        onClick={() => {
                                          this.showAddPaymentMethodModal = true
                                          this.renderAddPaymentMethodModal = true
                                        }}>Add Payment Method</Button>,
                                      appendContent: true,
                                    })}
                                  </div>
                                </div>
                                {['personal_check', 'company_check', 'money_order'].indexOf(this.paymentFormState.get('paymentMethod')) > -1 &&
                                <div className="form-row">
                                  <div className="col-md-12">
                                    {this.paymentFormHelper.renderTextInput({
                                      type: 'text',
                                      name: 'checkInformation',
                                      label: 'Check Information',
                                    })}
                                  </div>
                                </div>}
                                {
                                  (this.paymentFormState.get('paymentMethod'))
                                    ? AuthStore.getUser()!.hasRole(UserRole.Admin)
                                    ? <>
                                      <div className="form-row">
                                        <div className="col-12">
                                          {
                                            this.paymentFormHelper.renderTextInput({
                                              label: 'Amount',
                                              name: 'amount',
                                              prepend: '$',
                                            })
                                          }
                                        </div>
                                      </div>
                                      <div className="form-row">
                                        <div className="col-12">
                                          {
                                            this.paymentFormHelper.renderTextInput({
                                              label: 'Description',
                                              name: 'description',
                                            })
                                          }
                                        </div>
                                      </div>
                                    </>
                                    : <div className="form-row">
                                      <div className="col-12">
                                        <div className="form-group">
                                          <label>Payment Amount</label><br/>
                                          <b>{formatCurrency(this.paymentFormState.get('amount'))}</b>
                                        </div>
                                      </div>
                                    </div>
                                    : null
                                }

                                {
                                  (this.selectedPaymentMethod && this.selectedPaymentMethod.paymentMethodType === PaymentMethodType.BankAccount)
                                    ? <Alert color="warning">
                                      <AchAuthorizationVerbiage/>
                                    </Alert>
                                    : null
                                }

                                <div className="form-buttons">
                                  <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => this.showPaymentForm = false}
                                  >Cancel</Button>
                                  <Button
                                    type="submit"
                                    color="primary"
                                    disabled={!this.isPaymentFormValid}
                                  >{
                                    AuthStore.getUser()!.hasRole(UserRole.Admin)
                                      ? 'Apply Payment'
                                      : 'Submit Payment'
                                  }</Button>
                                </div>
                              </form>
                            </>
                            : null
                        }
                      </>
                      : null
                  }
                </>}
              </LazyResourcePanel>
              : null
          }
        </ModalBody>
        <ModalFooter>
          {
            this.invoice.current
              ? <RequirePermission
                permission={Permission.SendInvoice}
                context={
                  this.invoice.current.chapterId
                    ? { chapterId: this.invoice.current.chapterId }
                    : this.invoice.current.memberId
                    ? { memberId: this.invoice.current.memberId }
                    : undefined
                }
              >
                {() => <>
                  <a
                    target="_blank"
                    href={this.invoice.current!.payInvoiceUrl}
                    className="btn btn-secondary"
                  >View Payment Page</a>
                <Button
                  color="secondary"
                  onClick={() => this.showSendInvoice()}
                >Send Invoice</Button>
                </>}
              </RequirePermission>
              : null
          }
          <Button
            color="secondary"
            onClick={() => this.props.toggle()}
          >Close Window</Button>
        </ModalFooter>
      </Modal>
      {
        (this.renderAddPaymentMethodModal && this.invoice.current)
          ? <AddPaymentMethodModal
            isOpen={this.showAddPaymentMethodModal}
            toggle={() => this.showAddPaymentMethodModal = false}
            onClosed={() => this.renderAddPaymentMethodModal = false}
            onSaved={() => {
              this.showAddPaymentMethodModal = false
              this.invoice.invalidate()
            }}
            chapterId={this.invoice.current!.chapterId || undefined}
            memberId={this.invoice.current!.memberId || undefined}
          />
          : null
      }

      {
        (this.renderSendInvoiceModal && this.invoice.current)
          ? <Modal
            isOpen={this.showSendInvoiceModal}
            toggle={() => this.showSendInvoiceModal = false}
            onClosed={() => this.renderSendInvoiceModal = false}
          >
            <ModalHeader toggle={() => this.showSendInvoiceModal = false}>Send Invoice</ModalHeader>
            <ModalBody>
              <p>This will send a copy of the invoice to the specified email address</p>
              {
                this.sendInvoiceFormHelper.renderTextInput({
                  type: 'email',
                  label: 'Email Address',
                  name: 'email',
                })
              }
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => this.sendInvoice()}
              >Send Email</Button>
              <Button
                color="secondary"
                onClick={() => this.showSendInvoiceModal = false}
              >Cancel</Button>
            </ModalFooter>
          </Modal>
          : null
      }
    </>
  }
}
