import Member from "../models/Member"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { observer } from "mobx-react"
import { Button } from "reactstrap"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import Util, { logException, modelToSnakeCase } from "../common/Util"
import { IAddress } from "../models/Address"
import * as _ from "lodash"
import ButtonLoader from "./ButtonLoader"
import { toast } from "react-toastify"
import Config from "../common/Config"


type Props = {
  member: Member,
  onFinished: (dirty: boolean) => void
}

interface EditFormState extends IAddress {
  useBusinessAddress: boolean
}

@observer
export default class MemberShippingInformationForm extends React.Component<Props, never> {
  @observable
  private formState = new FormState<EditFormState>({
    address: _.get(this.props.member.shippingAddress, 'address', ''),
    address2: _.get(this.props.member.shippingAddress, 'address2', ''),
    city: _.get(this.props.member.shippingAddress, 'city', ''),
    state: _.get(this.props.member.shippingAddress, 'state', ''),
    zipCode: _.get(this.props.member.shippingAddress, 'zipCode', ''),
    country: _.get(this.props.member.shippingAddress, 'country', ''),
    useBusinessAddress: !this.props.member.shippingAddress
  })

  private isDirty = false

  @observable
  private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable
  private submitting = false

  private finish = () => {
    this.props.onFinished(this.isDirty)
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.members.updateShippingAddress(this.props.member.id, modelToSnakeCase(this.formHelper.toObject()))
      .then(response => {
        toast.success('Shipping Info Updated')
        this.isDirty = true
        this.finish();
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  render (): React.ReactNode {
    return <>
      <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
        <div className="form-row">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="form-check form-checkbox">
                <label>
                  <input type="checkbox"
                         className="form-check-input"
                         name="useBusinessAddress"
                         checked={!!this.formState.get('useBusinessAddress')}
                         onChange={this.formState.onChange}
                  />
                  <span className="label-text">Use Business Address</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        {!this.formState.get('useBusinessAddress') && (
          <>
            <div className="form-row">
              <div className="col-sm-8">
                {this.formHelper.renderTextInput({
                  name: 'address',
                  type: 'text',
                  label: 'Street',
                })}
              </div>
              <div className="col-sm-4">
                {this.formHelper.renderTextInput({
                  name: 'address2',
                  type: 'text',
                  label: 'Suite / Room / Apt #',
                })}
              </div>
            </div>

            <div className="form-row">
              <div className="col-sm-3">
                {this.formHelper.renderTextInput({
                  name: 'city',
                  type: 'text',
                  label: 'City',
                })}
              </div>
              <div className="col-sm-3">
                {this.formHelper.renderTextInput({
                  name: 'state',
                  type: 'text',
                  label: 'State',
                })}
              </div>
              <div className="col-sm-3">
                {this.formHelper.renderTextInput({
                  name: 'zipCode',
                  type: 'text',
                  label: 'Zip Code',
                })}
              </div>
              <div className="col-sm-3">
                {this.formHelper.renderSelectInput({
                  name: 'country',
                  options: Config.COUNTRY_OPTIONS.map(c => ({ value: c, text: c })),
                  label: 'Country',
                })}
              </div>
            </div>
          </>
        )}

        <div className="form-buttons">
          <Button type="button" color="" onClick={() => this.finish()}>Cancel</Button>
          <ButtonLoader type="submit" color="success" loading={this.submitting}>Save Changes</ButtonLoader>
        </div>
      </form>
    </>
  }
}