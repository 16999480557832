import * as React from "react"
import { SyntheticEvent } from "react"
import BaseView from "./BaseView"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { Link, Prompt, Redirect } from "react-router-dom"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { observer } from "mobx-react"
import ButtonLoader from "../components/ButtonLoader"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import Util, { logException, modelToCamelCase, modelToSnakeCase } from "../common/Util"
import { BarLoader } from "react-spinners"
import { Button } from "reactstrap"
import { LinkContainer } from "react-router-bootstrap"
import { toast } from "react-toastify"
import User from "../models/User"
import { MemberPickerInput } from "../components/inputs/MemberPickerInput"
import BackLink from "../components/BackLink"
import LazyResourcePanel from "../components/LazyResourcePanel"
import AuthStore from "../stores/AuthStore"
import SupportAreaUser from "../models/SupportAreaUser"
import Region from "../models/Region"
import SelectInput from "../components/inputs/SelectInput"

type EditUserFormState = {
  name: string
  email: string
  username: string
  status: string
  password: string
  password2: string
  roles: string[]
  adminRegionIds: string[]
  memberId?: number
}

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

@observer
export default class EditUserView extends BaseView<Props> {
  @observable private formState = new FormState<EditUserFormState>({
    name: '',
    email: '',
    username: '',
    status: 'Active',
    password: '',
    password2: '',
    roles: [],
    adminRegionIds: [],
    memberId: undefined,
  })
  @observable private formErrors = new ErrorBag()
  private formHelper = new FormHelper(this.formState, this.formErrors)
  @observable private submitting = false
  @observable private loading = false
  @observable private redirect = false
  @observable private editUser?: User
  @observable private regions: Region[] = []

  private adminRegionIdsInput = React.createRef<SelectInput>()

  private get isEdit () {
    return !!this.props.match.params.id
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>{this.isEdit ? 'Update Existing User' : 'Add New User'}</h1>
        {this.isEdit && this.editUser && <ul className="content-header-actions">
          <li>
            <Button
              color="warning"
              onClick={() => {
                AppStateStore.showModalSpinner()
                AuthStore.requestImpersonate({ user_id: this.editUser!.id })
                  .then(() => {
                    AppStateStore.dismissModalSpinner()
                    window.location.href = route(Routes.index)
                  })
                  .catch(() => {
                    AppStateStore.showAlertModal('Error', 'There was an error impersonating the user')
                  })
              }}
            >Impersonate User</Button>
          </li>
        </ul>}
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    ApiClient.query(`
      regions {
      *
      }`,
      {
        order: ['name'],
      })
      .then(response => {
        this.regions = response.data.regions.map((r: any) => new Region().init(r))
        this.adminRegionIdsInput.current && this.adminRegionIdsInput.current.forceUpdate()
      })
      .catch(ex => {
        AppStateStore.showAlertModal('Error', 'There was an error loading the regions', m => {
          m.hide()
          this.redirect = true
        })
      })

    if (this.props.match.params.id) {
      this.loading = true

      ApiClient.users.show(this.props.match.params.id)
        .then(response => {
          const userData = modelToCamelCase(response.data.user)
          this.editUser = new User(modelToCamelCase(userData))
          this.formState.setAll(userData as EditUserFormState)
          this.formState.clearDirty()
          this.loading = false
        }, () => {
          AppStateStore.showAlertModal('Error', 'The user was not found', m => {
            m.hide()
            this.redirect = true
          })
        })
        .catch(ex => {
          logException(ex)
          AppStateStore.showAlertModal('Error', 'The user was not found', m => {
            m.hide()
            this.redirect = true
          })
        })
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()

    if (this.formState.get('password') !== this.formState.get('password2')) {
      this.formErrors.addError('password2', 'The two entered passwords do not match')
      return
    }

    this.submitting = true
    AppStateStore.showModalSpinner()

    const apiCall = this.editUser
      ? () => ApiClient.users.update(this.editUser!.id, modelToSnakeCase(this.formHelper.toObject()))
      : () => ApiClient.users.create(modelToSnakeCase(this.formHelper.toObject()))

    apiCall().then(() => {
      toast.success(this.isEdit ? 'User updated' : 'User created')
      this.redirect = true
    }, error => {
      const errors = new ErrorBag()
      Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      })

      this.formErrors.addErrors(errors.getErrorList())
    })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private removeSupportArea = (supportAreaUser: SupportAreaUser) => {
    AppStateStore.showConfirmationModal('Remove Area Support User', 'Are you sure you want to remove this user from this support area?', (result, modal) => {
      if (result) {
        AppStateStore.showModalSpinner()
        ApiClient.supportAreas.removeUser(supportAreaUser.supportArea.id, this.editUser!.id)
          .then(() => {
            toast.success('User removed from support area')
            this.formState.setAll({
              title: '',
              userId: undefined,
            })
          }, error => {
            Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
              AppStateStore.showAlertModal('Error', message, m => {
                m.hide()
              })
              return true
            })
          })
          .then(() => {
            AppStateStore.dismissModalSpinner()
            this.editUser!.supportAreaUsers.invalidate()
          })
      }

      modal.hide();
    }, { type: 'danger' })
  }

  private loadAdminRegionOptions = (regions: Region[]) => {
    return (inputValue: any, callback: any) => {
      callback(
        regions.filter(v => !inputValue || inputValue.length == 0 || v.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
          .map(v => ({
            label: v.name,
            value: String(v.id),
          }))
      )
    }
  }

  renderContentBody (): React.ReactNode {
    return this.redirect
      ? this.renderRedirect()
      : this.loading ? this.renderLoading() : this.renderForm()
  }

  private renderLoading = () => <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>

  private renderForm = () => (
    <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      {this.formState.isDirty && <Prompt message="Are you sure you want to leave this page? You will lose any unsaved changes!"/>}
      <div className="form-row">
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'name',
            type: 'text',
            label: 'Full Name',
          })}
        </div>
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'email',
            type: 'email',
            label: 'Email Address',
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'username',
            type: 'text',
            label: 'Username',
          })}
        </div>
        <div className="col-sm-6">
          {this.formHelper.renderSelectInput({
            name: 'status',
            label: 'Status',
            options: [
              { value: 'Active', text: 'Active' },
              { value: 'Inactive', text: 'Inactive' },
            ]
          })}
        </div>
      </div>
      {this.editUser &&
      <div className="form-row">
        <div className="col-sm-12">
          <div className="form-group">
            <small className="form-text text-muted">Leave the password fields blank to keep the user's existing password</small>
          </div>
        </div>
      </div>}
      <div className="form-row">
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'password',
            type: 'password',
            label: this.editUser ? 'New Password' : 'Desired Password',
          })}
        </div>
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'password2',
            type: 'password',
            label: 'Confirm Password',
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          {this.formHelper.renderAsyncSelectInput({
            name: 'roles',
            label: 'Roles',
            isMulti: true,
            defaultValue: this.editUser ? this.editUser.roles.map(r => ({ value: r, label: r })) : [],
            loadOptions: (inputValue: any, callback: any) => {
              callback(
                [
                  'Admin',
                  'Employee',
                ].filter(v => !inputValue || inputValue.length == 0 || v.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
                  .map(v => ({
                    label: v,
                    value: v
                  }))
              )
            }
          })}
        </div>
        <div className="col-sm-6">
          <label>Member</label>
          <div>
            <MemberPickerInput
              showViewProfile={true}
              value={this.formState.get('memberId')}
              onChange={(member) => {
                this.formState.set('memberId', member ? member.id : undefined)
              }}
              filters={{ showInactive: true }}
            />
          </div>
        </div>
      </div>
      {this.editUser && <div className="form-row">
        <div className="col-sm-6">
          <label>Support Area(s) <Link
            to={route(Routes.system.supportAreas.index)}
            className="btn btn-primary btn-sm"
          >Manage</Link></label>
          <LazyResourcePanel resource={this.editUser.supportAreaUsers} emptyMessage="This user is not assigned to any support areas">
            {(supportAreaUsers) => <ul className="stacked-item-list">{supportAreaUsers.map(supportAreaUser => <li key={supportAreaUser.id}>
              <div style={{ flex: 1 }}>
                {supportAreaUser.supportArea.name}<br/>
                <span className="text-muted">{supportAreaUser.title}</span>
              </div>
              <div>
                <Link to={route(Routes.system.supportAreas.show, { id: supportAreaUser.supportArea.id })}><i className="fa fa-search"/></Link>
                <br/>
                <a className="text-danger" href="" onClick={(ev) => {
                  ev.preventDefault();
                  this.removeSupportArea(supportAreaUser)
                }}><i className="fa fa-trash"/></a>
              </div>
            </li>)}</ul>}
          </LazyResourcePanel>
        </div>
        <div className="col-sm-6">
          {this.formHelper.renderAsyncSelectInput({
            ref: this.adminRegionIdsInput,
            name: 'admin_region_ids',
            label: 'Admin Regions',
            isMulti: true,
            defaultValue: this.editUser ? this.regions.filter(r => this.editUser!.adminRegionIds.indexOf(Number(r.id)) > -1).map(r => ({ value: String(r.id), label: r.name })) : [],
            loadOptions: this.loadAdminRegionOptions(this.regions),
          })}
        </div>
      </div>}

      <div className="form-buttons">
        <LinkContainer to={route(Routes.system.users.index)}><Button type="button" color=""><i className="fa fa-ban"/> Cancel</Button></LinkContainer>
        <ButtonLoader type="submit" color="success" loading={this.submitting}><i className="fa fa-save"/> {this.isEdit ? 'Update User' : 'Save New User'}</ButtonLoader>
      </div>
    </form>
  )

  private renderRedirect = () => <Redirect to={route(Routes.system.users.index)}/>
}