import * as React from "react"
import { observer } from "mobx-react"
import BaseView from "../BaseView"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import BoardDocument from '../../models/BoardDocument'
import { observable } from 'mobx'
import Util, { copyToClipboard, formatNumber } from '../../common/Util'
import { Alert, Button } from 'reactstrap'
import { BarLoader } from 'react-spinners'
import AuthStore from '../../stores/AuthStore'
import BackLink from '../../components/BackLink'
import AppStateStore from '../../stores/AppStateStore'
import { route } from '../../routes/routes'
import BoardDocumentSignature from '../../models/BoardDocumentSignature'
import _ from 'lodash'
import { toast } from 'react-toastify'

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

@observer
export default class BoardDocumentView extends BaseView<Props> {
  @observable private boardDocument?: BoardDocument
  @observable private sentBoardDocuments: BoardDocumentSignature[]
  @observable private loading = false
  @observable private error?: string

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>Board Document</h1>
        {
          this.boardDocument
            ? <ul className="content-header-actions">
              <li>
                <Button type="button" color="primary" onClick={() => this.onSendToBoardClicked()}><i className="fa fa-send"/> Send to Board Members</Button>
              </li>
            </ul>
            : null
        }
      </>
    )
  }

  componentDidMount () {
    super.componentDidMount()

    this.loadBoardDocument().then()
  }

  private loadBoardDocument = async () => {
    this.loading = true

    try {
      let response = await ApiClient.query(`
boardDocument {
  *
}
`, { where: [{ id: this.props.match.params.id }] }
      )

      this.boardDocument = new BoardDocument().init(response.data.boardDocument)

      response = await ApiClient.query(`
boardDocumentSignatures {
  *
  chapter {
    name
  }
  member {
    fullName
  }
  position {
    name
  }
}
`, { where: [{ boardDocumentId: this.boardDocument!.id }] })

      this.sentBoardDocuments = _.orderBy(response.data.boardDocumentSignatures.map((s: any) => new BoardDocumentSignature().init(s)), ['signedAt', 'chapter.name'], ['desc', 'asc'])
    } catch (err) {
      this.error = Util.extractErrorMessage(err.response)
    }

    this.loading = false
  }

  private sendToBoard = async () => {
    AppStateStore.showModalSpinner()

    try {
      const response = await ApiClient.getInstance().post(route(ApiRoutes.boardDocuments.send, { id: this.props.match.params.id }))

      AppStateStore.showAlertModal('Board Document Sent', `The board document was sent to ${formatNumber(response.data.sent_board_members)} board members(s) of ${formatNumber(response.data.sent_chapters)} chapter(s)`)
      this.loadBoardDocument().then()
    } catch (err) {
      AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
    }

    AppStateStore.dismissModalSpinner()
  }

  private onSendToBoardClicked = async () => {
    AppStateStore.showConfirmationModal('Confirm', 'Are you sure you want to send this document to all active chapters who have not signed it yet?', (result, modal) => {
      if (result) {
        this.sendToBoard().then()
      }

      modal.hide()
    })
  }

  renderContentBody (): React.ReactNode {
    return this.error
      ? <Alert color="danger">{this.error}</Alert>
      : this.loading ? this.renderLoading() : this.renderDetail()
  }

  private renderLoading = () => <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>

  private renderDetail = () => {
    return this.boardDocument
      ? <div className="row">
        <div className="col-md-6">
          <h4>Document Info</h4>
          <div className="mb-4">
            {this.boardDocument!.title}<br/>
            <a target="_blank" href={`${this.boardDocument!.pdfUrl}?token=${AuthStore.generateTempAuthToken()}`}>{this.boardDocument!.pdfName}</a>
          </div>
          <div>
            <b>Allowed Board Members</b><br/>
            {
              this.boardDocument.allowedBoardPositions
                ? this.boardDocument.allowedBoardPositions.map(p => p.name).join(', ')
                : 'Any Board Member'
            }
          </div>
        </div>
        <div className="col-md-6">
          <h4>Chapters</h4>
          {
            this.sentBoardDocuments.map(sentDoc => <div className="mb-2" key={sentDoc.id}>
              {
                sentDoc.signedAt
                  ? <i className="fa fa-check mr-1" style={{ color: '#090' }}/>
                  : <i className="fa fa-exclamation-triangle mr-1" style={{ color: '#f00' }}/>
              } <b>{sentDoc.chapter.name}</b>
              {
                sentDoc.signedAt
                  ? <div style={{ color: '#666' }}>
                    Signed by {sentDoc.member.fullName} ({sentDoc.position.name}) on {sentDoc.signedAt.format('MM/DD/YYYY h:mm a')}
                  </div>
                  : <Button className="ml-1" size="sm" color="primary" onClick={() => {
                    copyToClipboard(sentDoc.signUrl)
                    toast.success('Signing URL copied to clipboard!')
                  }}><i className="fa fa-copy"/> Copy Signing URL</Button>
              }
            </div>)
          }
        </div>
      </div>
      : null
  }
}
