import * as React from "react"
import BaseView from "./BaseView"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { Link, Redirect, RouteComponentProps } from "react-router-dom"
import { observable } from "mobx"
import { observer } from "mobx-react"
import AppStateStore from "../stores/AppStateStore"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import Util, { formatCurrency, logException, modelToCamelCase, safeNull } from "../common/Util"
import { BarLoader } from "react-spinners"
import Member, { VideoData } from "../models/Member"
import { Button, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import classNames from 'classnames'
import { createBrowserHistory } from 'history'
import * as _ from 'lodash'
import moment from 'moment'
import { renderIf } from "../common/RenderUtil"
import ContactMethod from "../models/ContactMethod"
import Membership from "../models/Membership"
import MemberSidebar from "../components/MemberSidebar"
import MemberProfile from "../components/MemberProfile"
import MemberBilling from "../components/MemberBilling"
import LazyTabPane from "../components/LazyTabPane"
import LazyResourcePanel from "../components/LazyResourcePanel"
import MemberBusinessSidebar from "../components/MemberBusinessSidebar"
import MemberBusiness from "../components/MemberBusiness"
import BackLink from "../components/BackLink"
import RequirePermission, { can, Permission } from "../components/RequirePermission"
import AuthStore from "../stores/AuthStore"
import VideoPlayer from "../components/VideoPlayer"
import MemberNetworkInfo from "../components/MemberNetworkInfo"
import { LibraryOwnerType, LibraryType } from "../models/DocumentLibraryItem"
import DocumentLibrary from "../components/DocumentLibrary"
import NotesList from "../components/NotesList"
import ManageMemberSettings from "../components/system/members/ManageMemberSettings"
import EventBus, { EventBusContext } from "../common/EventBus"
import SentSystemMessages from "../components/system/SentSystemMessages"
import LazyResource from "../models/LazyResource"
import MemberUsageStats from "../models/MemberUsageStats"

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

const TABS = {
  overview: 'overview',
  profile: 'profile',
  business: 'business',
  network: 'network',
  billing: 'billing',
  filesNotes: 'files_notes',
  settings: 'settings',
  systemMessages: 'systemMessages',
}

@observer
export default class MemberDetailView extends BaseView<Props & RouteComponentProps> {
  @observable private redirect = false
  @observable private loading = false
  @observable private member?: Member

  @observable private activeTab = TABS.overview

  private history = createBrowserHistory()

  @observable
  private showModal = false
  @observable
  private editModalContent?: React.ReactNode
  @observable
  private editModalSize = 'lg'
  @observable
  private editModalTitle = 'Edit'
  @observable
  private videoModalData?: VideoData = undefined

  @observable private usageStats = new LazyResource<MemberUsageStats>((callback, error) => {
    ApiClient.getInstance().get(route(ApiRoutes.members.usageStats, { id: this.props.match.params.id }))
      .then(response => {
        callback(new MemberUsageStats().init(modelToCamelCase(response.data.usage_stats)))
      }).catch(error => error(Util.extractErrorMessage(error)))
  })

  private eventBus = new EventBus()

  private showEditModal = (title: string, component: React.ReactNode, size: string = 'lg') => {
    this.editModalTitle = title
    this.editModalContent = component
    this.showModal = true
    this.editModalSize = size
  }

  private closeEditModal = () => {
    this.showModal = false
    this.editModalContent = undefined
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>Member Detail</h1>
        {
          this.member
          && can(Permission.ImpersonateMember, { memberId: this.member.id, chapterId: safeNull(() => this.member!.chapter!.id), regionId: safeNull(() => this.member!.chapter!.area!.region!.id) })
            ? <ul className="content-header-actions">
              <li>
                <Button
                  color="warning"
                  onClick={() => {
                    AppStateStore.showModalSpinner()
                    AuthStore.requestImpersonate({ member_id: this.member!.id })
                      .then(() => {
                        window.location.href = route(Routes.index)
                      })
                      .catch(err => {
                        AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
                      })
                      .then(() => AppStateStore.dismissModalSpinner())
                  }}
                >Impersonate Member</Button>
              </li>
            </ul>
            : null

        }
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    this.activeTab = _.get(this.history.location.state, 'activeTab') || TABS.overview

    if (!this.props.match.params.id) {
      this.redirect = true
    } else {
      this.loadMemberData()
    }
  }

  componentDidUpdate (prevProps: Readonly<Props & RouteComponentProps>, prevState: Readonly<any>, snapshot?: any): void {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.activeTab = TABS.overview
      this.member = undefined
      this.loadMemberData()
    }
  }

  private loadMemberData = () => {
    const firstLoad = !this.member
    firstLoad ? this.loading = true : AppStateStore.showModalSpinner()

    ApiClient.members.show(this.props.match.params.id)
      .then(response => {
        const memberData = modelToCamelCase(response.data.member)
        this.member = new Member(memberData)
      }, () => {
        AppStateStore.showAlertModal('Error', 'The member was not found', m => {
          m.hide()
          this.redirect = true
        })
      })
      .catch(ex => {
        logException(ex)
        AppStateStore.showAlertModal('Error', 'The member was not found', m => {
          m.hide()
          this.redirect = true
        })
      })
      .then(() => {
        firstLoad ? this.loading = false : AppStateStore.dismissModalSpinner()
      })
  }

  private onMemberInvalidated = () => {
    this.loadMemberData()

    this.eventBus.dispatch('member-invalidated')
  }

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(window.location.pathname, { ...this.history.location.state, activeTab: this.activeTab })
    }
  }

  private playVideo = (videoData: VideoData) => {
    this.videoModalData = videoData
  }

  render (): React.ReactNode {
    return this.redirect
      ? this.renderRedirect()
      : this.loading ? this.renderLoading() : this.renderContent()
  }

  private renderUsageStats = (usageStats: MemberUsageStats) => {
    return <div>
      <div className="row">
        <div className="col-6">
          <b>Last Mobile Activity</b><br/>
          {usageStats.lastMobileActivityDate ? usageStats.lastMobileActivityDate.format('MM/DD/YYYY h:mm a') : 'Never'}
        </div>
        <div className="col-6">
          <b>Last Web Activity</b><br/>
          {usageStats.lastWebActivityDate ? usageStats.lastWebActivityDate.format('MM/DD/YYYY h:mm a') : 'Never'}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6">
          <b>Last Mobile Tip Sent</b><br/>
          {usageStats.lastSentMobileTipDate ? usageStats.lastSentMobileTipDate.format('MM/DD/YYYY h:mm a') : 'Never'}
        </div>
        <div className="col-6">
          <b>Last Web Tip Sent</b><br/>
          {usageStats.lastSentWebTipDate ? usageStats.lastSentWebTipDate.format('MM/DD/YYYY h:mm a') : 'Never'}
        </div>
      </div>
    </div>
  }

  private renderLoading = () => <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>

  private renderContent = () => {
    const member = this.member

    return member ? (
      <EventBusContext.Provider value={{ eventBus: this.eventBus }}>
        <div className="card">
          <div className="card-header">
            <Nav tabs className="card-header-tabs">
              <NavItem className={classNames({ active: this.activeTab === TABS.overview })}>
                <NavLink
                  onClick={() => {
                    this.setTab(TABS.overview)
                  }}
                >Overview</NavLink>
              </NavItem>
              <NavItem className={classNames({ active: this.activeTab === TABS.profile })}>
                <NavLink
                  onClick={() => {
                    this.setTab(TABS.profile)
                  }}
                >Profile</NavLink>
              </NavItem>
              <NavItem className={classNames({ active: this.activeTab === TABS.business })}>
                <NavLink
                  onClick={() => {
                    this.setTab(TABS.business)
                  }}
                >Business</NavLink>
              </NavItem>
              <RequirePermission permission={Permission.EditMemberNetwork} context={{ memberId: member ? member.id : undefined, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region.id) }}>
                {() => <NavItem className={classNames({ active: this.activeTab === TABS.network })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.network)
                    }}
                  >Network</NavLink>
                </NavItem>}
              </RequirePermission>
              <RequirePermission permission={Permission.ViewMemberBilling} context={{ memberId: member ? member.id : undefined, regionId: safeNull(() => this.member!.chapter!.area!.region.id) }}>
                {() => <NavItem className={classNames({ active: this.activeTab === TABS.billing })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.billing)
                    }}
                  >Billing</NavLink>
                </NavItem>}
              </RequirePermission>
              <NavItem className={classNames({ active: this.activeTab === TABS.filesNotes })}>
                <NavLink
                  onClick={() => {
                    this.setTab(TABS.filesNotes)
                  }}
                >Files/Notes</NavLink>
              </NavItem>
              <NavItem className={classNames({ active: this.activeTab === TABS.settings })}>
                <NavLink
                  onClick={() => {
                    this.setTab(TABS.settings)
                  }}
                >Settings</NavLink>
              </NavItem>
              <RequirePermission permission={Permission.ViewSentSystemMessages}>
                {() => <NavItem className={classNames({ active: this.activeTab === TABS.systemMessages })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.systemMessages)
                    }}
                  >System Messages</NavLink>
                </NavItem>}
              </RequirePermission>
            </Nav>
          </div>
          <div className="card-body">
            <TabContent activeTab={this.activeTab}>
              <TabPane tabId={TABS.overview}>
                <h5 className="text-muted">Member Information</h5>
                <div className="row" style={{ marginTop: 20 }}>
                  <div className="col-sm-4">
                    <div className="d-flex flex-row align-items-center">
                      <div style={{ paddingRight: 20 }}>
                        <img alt="Member Profile Image" src={member.getProfileImageUrl()}
                             style={{
                               width: 130,
                               height: 130,
                               objectFit: 'cover',
                               objectPosition: 'top center',
                               backgroundColor: '#ccc',
                             }}/>
                      </div>
                      <div className="flex-fill">
                        <h5>{member.fullName}</h5>
                        {renderIf(_.get(member, 'business.name'), <br/>)}
                        {renderIf(member.joinDate, <br/>, d => moment(d).format('MM/DD/YYYY'))}
                        <b>Account Status: </b> {member.status}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 stacked-labeled-list">
                    {member.phoneNumber ? <div>{member.phoneNumber}</div> : null}
                    {member.emailAddress ? <div>{member.emailAddress}</div> : null}
                  </div>
                  <div className="col-sm-4 stacked-labeled-list">
                    <div>
                      <label>Member ID:</label>
                      <span>{member.id}</span>
                    </div>
                    <div>
                      <label>Gender:</label>
                      <span>{member.gender || '---'}</span>
                    </div>
                    <div>
                      <label>Birthday:</label>
                      <span>{member.birthDate ? member.birthDate.format('MM/DD/YYYY') : '---'}</span>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={TABS.profile}>
                <div className="member-detail-pane">
                  <MemberSidebar member={member} onMemberInvalidated={this.onMemberInvalidated}/>
                  <div className="member-detail-content">
                    <MemberProfile
                      member={member}
                      onMemberInvalidated={this.onMemberInvalidated}
                    />
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={TABS.business}>
                <div className="member-detail-pane">
                  <MemberBusinessSidebar
                    member={member}
                    onMemberInvalidated={this.onMemberInvalidated}
                  />
                  <div className="member-detail-content">
                    <MemberBusiness
                      member={member}
                      onMemberInvalidated={this.onMemberInvalidated}
                    />
                  </div>
                </div>
              </TabPane>
              <RequirePermission permission={Permission.EditMemberNetwork} context={{ memberId: member ? member.id : undefined, chapterId: member.chapterId, regionId: safeNull(() => member!.chapter!.area!.region.id) }}>
                {() =>
                  <LazyTabPane tabId={TABS.network} activeTab={this.activeTab}>
                    <div className="member-detail-pane">
                      <MemberSidebar member={member} onMemberInvalidated={this.onMemberInvalidated}/>
                      <div className="member-detail-content">
                        <MemberNetworkInfo
                          member={member}
                        />
                      </div>
                    </div>
                  </LazyTabPane>
                }</RequirePermission>
              <RequirePermission permission={Permission.ViewMemberBilling} context={{ memberId: this.member ? this.member.id : undefined, regionId: safeNull(() => this.member!.chapter!.area!.region.id) }}>
                {() =>
                  <LazyTabPane tabId={TABS.billing} activeTab={this.activeTab}>
                    <div className="member-detail-pane">
                      <MemberSidebar member={member} onMemberInvalidated={this.onMemberInvalidated}/>
                      <div className="member-detail-content">
                        <MemberBilling
                          memberId={member.id}
                          onMemberInvalidated={this.onMemberInvalidated}
                        />
                      </div>
                    </div>
                  </LazyTabPane>
                }</RequirePermission>
              <TabPane tabId={TABS.filesNotes}>
                <div className="member-detail-pane">
                  <MemberSidebar member={member} onMemberInvalidated={this.onMemberInvalidated}/>
                  <div className="member-detail-content">
                    <div className="row">
                      <div className="col-md-6">
                        <DocumentLibrary libraryDescriptor={{
                          libraryOwnerType: LibraryOwnerType.Member,
                          libraryOwnerId: member.id,
                          libraryType: LibraryType.MemberDocumentLibrary,
                        }}/>
                      </div>
                      <div className="col-md-6">
                        <NotesList memberId={member.id}/>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <LazyTabPane tabId={TABS.settings} activeTab={this.activeTab}>
                <ManageMemberSettings
                  onMemberInvalidated={() => this.onMemberInvalidated()}
                  memberId={member.id}
                />
              </LazyTabPane>
              <RequirePermission permission={Permission.ViewSentSystemMessages}>
                {() =>
                  <LazyTabPane tabId={TABS.systemMessages} activeTab={this.activeTab}>
                    <SentSystemMessages
                      stateKey={`SentSystemMessages-Member-${member.id}`}
                      memberId={member.id}
                    />
                  </LazyTabPane>
                }</RequirePermission>
            </TabContent>
          </div>
        </div>

        <TabContent activeTab={this.activeTab}>
          <TabPane tabId={TABS.overview}>
            <div className="row">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-header">
                    <h5>Network Information</h5>
                  </div>
                  <div className="card-body">
                    <div className="stacked-labeled-list label-200">
                      <div>
                        <label>Badge Color:</label>
                        <span>{member.badgeColor || '---'}</span>
                      </div>
                      <div>
                        <label>Membership Type:</label>
                        <span>{member.membershipType || '---'}</span>
                      </div>
                      <div>
                        <label>Chapter:</label>
                        <span>{
                          member.chapter
                            ? <Link to={route(Routes.system.chapters.show, { id: member.chapter.id })}>{member.chapter.name}</Link>
                            : '---'
                        }</span>
                      </div>
                      <div>
                        <label>Category:</label>
                        <span>{member.category ? member.category.name : '---'}</span>
                      </div>
                      <div>
                        <label>Area:</label>
                        <span>{member.getAreaName() || '---'}</span>
                      </div>
                      <div>
                        <label>Position:</label>
                        <span>{member.getPositionName() || 'None Assigned'}</span>
                      </div>
                      <div>
                        <label>Number Sponsored:</label>
                        <span><i className="fa fa-user text-muted"/> {member.numberOfSponsoredMembers}</span>
                      </div>
                      <div>
                        <label>Sponsored By:</label>
                        <span>{member.sponsors.length ? _.map(member.sponsors, (s, idx) => <span key={idx}><Link to={route(Routes.system.members.show, { id: s.id })}>{s.fullName}</Link>{idx < member.sponsors.length - 1 ? ',' : null} </span>) : 'None Assigned'}</span>
                      </div>
                      <div>
                        <label>NTS:</label>
                        <span>{member.ntsTrainingCompletedAt ? 'Yes' : 'No'}</span>
                      </div>
                      <div>
                        <label>Inactive Date:</label>
                        <span>{member.calculatedInactiveDate ? member.calculatedInactiveDate.format('MM/DD/YYYY') : '---'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <RequirePermission permission={Permission.ViewMemberBilling} context={{ memberId: this.member ? this.member.id : undefined, regionId: safeNull(() => this.member!.chapter!.area!.region.id) }}>
                  {() =>
                    <div className="card">
                      <div className="card-header">
                        <h5>Billing Information</h5>
                      </div>
                      <div className="card-body">
                        <LazyResourcePanel resource={member.activeMembership} emptyMessage="No active membership">
                          {membership => membership ? this.renderMembership(membership) : null}
                        </LazyResourcePanel>
                      </div>
                    </div>
                  }
                </RequirePermission>

                <RequirePermission permission={Permission.ViewMemberUsageStats} context={{ memberId: this.member ? this.member.id : undefined }}>
                  {() =>
                    <div className="card">
                      <div className="card-header">
                        <h5>Usage Information</h5>
                      </div>
                      <div className="card-body">
                        <LazyResourcePanel resource={this.usageStats} emptyMessage="No usage stats">
                          {usageStats => usageStats ? this.renderUsageStats(usageStats) : null}
                        </LazyResourcePanel>
                      </div>
                    </div>
                  }
                </RequirePermission>

                {
                  member.videoData
                    ? <div className="card">
                      <div className="card-body text-center">
                        <div className="video-thumbnail" onClick={() => this.playVideo(member.videoData!)}>
                          {
                            member.videoData.thumbnailUrl
                              ? <img
                                style={{ maxWidth: '100%' }}
                                src={member.videoData.thumbnailUrl}/>
                              : null
                          }
                          <div className="video-thumbnail-play"><i className="fa fa-play"/></div>
                        </div>
                      </div>
                    </div>
                    : null
                }
              </div>
            </div>
          </TabPane>
        </TabContent>

        <Modal isOpen={this.showModal} size={this.editModalSize}>
          <ModalHeader>{this.editModalTitle}</ModalHeader>
          <ModalBody>{this.editModalContent}</ModalBody>
        </Modal>

        {
          this.member
            ? <Modal isOpen={!!this.videoModalData} size="lg" toggle={() => this.videoModalData = undefined}>
              <ModalHeader toggle={() => this.videoModalData = undefined}>
                {this.member.business ? this.member.business.name : 'Video Player'}
              </ModalHeader>
              <ModalBody>
                <VideoPlayer videoData={this.videoModalData!}/>
              </ModalBody>
            </Modal>
            : null
        }
      </EventBusContext.Provider>
    ) : null
  }

  private renderMembership = (membership: Membership) => {
    return <div className="stacked-labeled-list label-200">
      <div>
        <label>Renewal Method:</label>
        <span>{membership.isAutoRenewal ? 'Automatic' : 'Manual'}</span>
      </div>
      <div>
        <label>Renewal Date:</label>
        <span>{membership.endsAt ? membership.endsAt.format('MM/DD/YYYY') : '---'}</span>
      </div>
      <div>
        <label>Renewal Amount:</label>
        <span>{membership.renewalFee ? formatCurrency(membership.renewalFee) : '---'}</span>
      </div>
      <div>
        <label>Last Renewal:</label>
        <span>{membership.lastRenewedAt ? membership.lastRenewedAt.format('MM/DD/YYYY') : '---'}</span>
      </div>
    </div>
  }

  private renderRedirect = () =>
    <Redirect to={route(Routes.system.members.index)}/>
}
