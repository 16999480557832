import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { TableViewColumnChooser } from "../../table-view/TableViewColumnChooser"
import AuthStore from "../../../stores/AuthStore"
import AddressView from "../../AddressView"
import ApiClient, { apiRoute, ApiRoutes } from "../../../api/ApiClient"
import BadgeShippingRequest from "../../../models/BadgeShippingRequest"
import AppStateStore from "../../../stores/AppStateStore"
import Util, { safeNull } from "../../../common/Util"
import { route } from "../../../routes/routes"
import { TableViewColumn } from "../../table-view/TableView"
import { observable } from "mobx"
import BadgeShippingReportCsvTransformer from "../../../models/renderers/BadgeShippingReportCsvTransformer"
import { ApiTableViewAdapter } from "../../table-view/ApiTableViewAdapter"
import { Link } from "react-router-dom"
import { Routes } from "../../../routes/AppRoutes"
import { Button } from "reactstrap"
import * as _ from "lodash"
import pluralize from 'pluralize'
import EventBus from "../../../common/EventBus"
import { EditShippingRequestModal } from '../../EditShippingRequestModal'
import DateFilter from '../../table-view/filters/DateFilter'
import AsyncSelectFilter, { AsyncSelectFilterTargetType } from '../../table-view/filters/AsyncSelectFilter'
import { loadChapterOptionsForAdmin, loadMemberOptions } from '../../../api/AsyncHelpers'
import ManagedTableView from '../../table-view/ManagedTableView'
import { ManagedChooseableColumn } from '../../table-view/ManagedTableViewColumnChooser'
import SelectFilter, { SelectFilterTargetType } from '../../table-view/filters/SelectFilter'
import Config from '../../../common/Config'
import Member from '../../../models/Member'

type Props = {
  eventBus: EventBus
}

type PossibleDuplicate = {
  type: 'email' | 'name'
  member: Member
}

@observer
export default class PendingShipments extends React.Component<Props> {
  @observable selectedItems: number[] = []
  @observable private possibleDuplicates: PossibleDuplicate[] = []
  @observable private findingDuplicates = false

  private tableViewRef = React.createRef<ManagedTableView>()

  componentDidMount (): void {
    this.props.eventBus.on('invalidate', this.invalidateData)
  }

  componentWillUnmount (): void {
    this.props.eventBus.remove(this.invalidateData)
  }

  private invalidateData = () => {
    this.tableViewRef.current && this.tableViewRef.current.fetchData()
  }

  private selectItem = (id: number, checked: boolean) => {
    if (checked && this.selectedItems.indexOf(id) === -1) {
      this.selectedItems.push(id)
    }

    if (!checked && this.selectedItems.indexOf(id) > -1) {
      this.selectedItems = this.selectedItems.filter(i => i != id)
    }
  }

  private removeEntries = () => {
    if (!this.selectedItems.length) {
      AppStateStore.showAlertModal('Error', 'You must select at least one shipping request')
      return
    }

    AppStateStore.showConfirmationModal('Delete Shipping Requests', `Are you sure you want to delete ${this.selectedItems.length} shipping ${pluralize('request', this.selectedItems.length)}?`, (result, modal) => {
      if (result) {
        modal.hide()

        AppStateStore.showModalSpinner()

        ApiClient.getInstance()
          .post(route(ApiRoutes.shippingRequests.markComplete), {
            shipping_request_ids: this.selectedItems,
            status: 'Cancelled',
          })
          .then(() => {
            this.selectedItems = []
            this.tableViewRef.current!.fetchData()
          })
          .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
          .then(() => AppStateStore.dismissModalSpinner())
      } else {
        modal.hide()
      }
    })
  }

  private markComplete = () => {
    if (!this.selectedItems.length) {
      AppStateStore.showAlertModal('Error', 'You must select at least one shipping request')
      return
    }

    AppStateStore.showConfirmationModal('Complete Shipping Requests', `Are you sure you want to mark ${this.selectedItems.length} ${pluralize('shipment', this.selectedItems.length)} complete?`, (result, modal) => {
      if (result) {
        modal.hide()

        AppStateStore.showModalSpinner()

        ApiClient.getInstance()
          .post(route(ApiRoutes.shippingRequests.markComplete), {
            shipping_request_ids: this.selectedItems,
            status: 'Shipped',
          })
          .then(() => {
            this.selectedItems = []
            this.tableViewRef.current!.fetchData()
          })
          .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
          .then(() => AppStateStore.dismissModalSpinner())
      } else {
        modal.hide()
      }
    })
  }

  private findPossibleDuplicates = async (emailAddresses: string[], names: string[]) => {
    this.findingDuplicates = true

    try {
      let response = await ApiClient.query(`
members {
  id
  fullName
  emailAddress
}
    `, {
        where: [
          { id: 'email_address', op: 'in', value: emailAddresses },
        ],
      })
      const duplicateEmails = response.data.members.map((d:any) => new Member().init(d))

      response = await ApiClient.query(`
members {
  id
  fullName
  chapter {
    name
  }
}
    `, {
        where: [
          { id: 'full_name', op: 'in', value: names },
        ],
      })
      const duplicateNames = response.data.members.map((d:any) => new Member().init(d))

      this.possibleDuplicates = [
        ...(duplicateEmails.map((d: Member) => ({
          type: 'email',
          member: d,
        }))),
        ...(duplicateNames.map((d: Member) => ({
          type: 'name',
          member: d,
        }))),
      ]
    } catch (e) {
      console.error(e)
    }
    this.findingDuplicates = true
  }

  private shippingRequestsAdapter = new ApiTableViewAdapter(() => {
    return {
      query: `
    badgeShippingRequests {
      *

      badgeShippingAddress {
        *
      }

      member {
        *

        category {
          *
        }

        chapter {
          *

          area {
            *
          }
        }
      }
    }`,
      where: [
        { id: 'completedAt', op: 'null' },
      ]
    }
  }, 'badgeShippingRequests', BadgeShippingRequest, {
    onFetched: (results) => {
      const names = _.uniq(results.items.map(r => r.member.fullName).filter(v => v && v.trim().length > 0).map(v => v!.trim()))
      const emails = _.uniq(results.items.map(r => r.member.emailAddress).filter(v => v && v.trim().length > 0).map(v => v!.trim()))
      this.findPossibleDuplicates(emails, names).then()
    },
    transformResponse: (items) => {
      return _.sortBy(items, [i => safeNull(() => i.badgeShippingAddress!.name)]) as BadgeShippingRequest[]
    }
  })

  @observable private availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'date',
      column: {
        title: 'Date',
        accessor: 'createdAt',
        renderItem: value => value.format('MM/DD/YYYY'),
        sortable: false,
      },
      fixed: true,
    },
    {
      id: 'prefix',
      column: {
        title: 'Prefix',
        accessor: 'member.prefix',
      },
      default: true,
    },
    {
      id: 'member',
      column: {
        title: 'Member',
        accessor: 'member',
        renderItem: value => <>
          <i>{safeNull(() => value.joinDate!.format('MM/DD/YYYY')) || '---'}</i>
          <br/>
          <Link to={route(Routes.system.members.show, { id: value.id })}>{safeNull(() => value.preferredName || value.fullName) || '---'}</Link>
          {
            _.uniqBy(this.possibleDuplicates.filter(d => d.member.id !== value.id && (d.member.fullName === value.fullName || d.member.emailAddress === value.emailAddress)), m => m.member.id)
              .map(dup => <div key={dup.member.id}>
                Prev. Member: <Link to={route(Routes.system.members.show, { id: dup.member.id })}>{dup.member.fullName}</Link>
              </div>)
          }
        </>,
      },
      fixed: true,
    },
    {
      id: 'chapter',
      column: {
        title: 'Chapter',
        accessor: 'member.chapter',
        renderItem: value => <>
          {
            value
              ? <Link to={route(Routes.system.chapters.show, { id: value.id })}>{value.name}</Link>
              : '---'
          }
        </>,
      },
      fixed: true,
    },
    {
      id: 'category',
      column: {
        title: 'Category',
        accessor: 'member.category.name',
      },
      default: true,
    },
    {
      id: 'badgeColor',
      column: {
        title: 'Badge Color',
        accessor: 'badgeColor',
        renderItem: (value, item) => <div className={`badge-color-${safeNull(() => value.toLowerCase())}`}>
          {value}<br/>
          ({item.previousBadgeColor || 'None'})
        </div>

      },
      default: true,
    },
    {
      id: 'addressTo',
      column: {
        title: 'Address To',
        accessor: 'badgeShippingAddress.name',
      },
      default: true,
    },
    {
      id: 'address',
      column: {
        title: 'Address',
        accessor: 'badgeShippingAddress',
        renderItem: value => value
          ? <AddressView address={value} showMapLink={true}/>
          : null
      },
      default: true,
    },
    {
      id: 'notes',
      column: {
        title: 'Notes',
        accessor: 'shippingNotes',
      },
      default: true,
    },
    {
      id: 'actions',
      column: {
        renderItem: (value, item) => {
          return <div style={{ display: 'flex', alignItems: 'center' }}>
            <a className="mr-3 print-hide" href="" onClick={ev => {
              ev.preventDefault()
              this.editShippingRequestId = item.id
            }}><i className="fa fa-pencil"/></a>
            <input
              type="checkbox"
              checked={this.selectedItems.indexOf(item.id) > -1}
              onChange={ev => this.selectItem(item.id, ev.target.checked)}/>
          </div>
        }
      },
      fixed: true,
      hidden: true,
    },
  ]

  @observable private visibleColumns: TableViewColumn[] = TableViewColumnChooser.getInitialColumns(this.availableColumns)

  @observable private editShippingRequestId?: number

  private openLinkRef = React.createRef<HTMLAnchorElement>()

  private filters = [
    new DateFilter('createdAt', 'createdAt', 'Date Sent'),
    new AsyncSelectFilter('member', AsyncSelectFilterTargetType.Field, 'memberId', 'Member', loadMemberOptions()),
    new AsyncSelectFilter('chapter', AsyncSelectFilterTargetType.Field, 'member.chapterId', 'Chapter Name', loadChapterOptionsForAdmin),
    new SelectFilter('badgeColor', SelectFilterTargetType.Field, 'badgeColor', 'Badge Color', Config.BADGE_COLORS.map(c => ({
      value: c,
      label: c,
    }))),
  ]

  render (): React.ReactNode {
    return <>
      <div style={{ display: 'none' }}>
        <a href="" target="_blank" ref={this.openLinkRef}/>
      </div>

      <ManagedTableView
        stateKey="FulfillmentPendingShipments"
        hideItemCount={false}
        ref={this.tableViewRef}
        adapter={this.shippingRequestsAdapter}
        availableColumns={this.availableColumns}
        showSearch={false}
        defaultSort={{ sortKey: 'createdAt', descending: true }}
        exportTransformer={BadgeShippingReportCsvTransformer}
        filters={this.filters}
        customActions={<>
          <a
            onClick={(ev: SyntheticEvent<HTMLAnchorElement>) => {
              if (this.openLinkRef.current) {
                ev.preventDefault()
                this.openLinkRef.current.href = apiRoute(ApiRoutes.shippingRequests.printLabels, {
                  token: AuthStore.generateTempAuthToken(),
                })
                this.openLinkRef.current.click()
              }
            }}
            href={apiRoute(ApiRoutes.shippingRequests.printLabels, {
              token: AuthStore.generateTempAuthToken(),
            })}
            target="_blank"
            className="btn btn-success"
          >Print Shipping Labels</a>
        </>}
      />

      <div className="form-buttons">
        <Button
          color="danger"
          onClick={() => {
            this.removeEntries()
          }}
        >Delete Selected</Button>

        <Button
          color="primary"
          onClick={() => {
            this.markComplete()
          }}
        >Mark Complete</Button>
      </div>

      {
        this.editShippingRequestId
          ? <EditShippingRequestModal
            badgeShippingRequestId={this.editShippingRequestId}
            onClosed={() => this.editShippingRequestId = undefined}
            onSaved={() => this.invalidateData()}
          />
          : null
      }
    </>
  }
}
