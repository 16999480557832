import * as React from "react"
import { SyntheticEvent } from "react"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { Alert, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import classNames from "classnames"
import { observer } from "mobx-react"
import CreditCardInput, { CardInfo } from "./inputs/CreditCardInput"
import ButtonLoader from "./ButtonLoader"
import FormError from "./FormError"
import { BankAccountDataType, CreditCardDataType, loadAcceptJs } from "../common/AuthNet"
import AppStateStore from "../stores/AppStateStore"
import Util, { logException, modelToSnakeCase } from "../common/Util"
import * as _ from "lodash"
import ApiClient, { ApiRoutes } from '../api/ApiClient'
import { route } from '../routes/routes'
import AchAuthorizationVerbiage from './AchAuthorizationVerbiage'

type BankAccountType = string | 'checking' | 'savings'

type BankAccountFormState = {
  routingNumber: string
  accountNumber: string
  nameOnAccount: string
  accountType: BankAccountType
}

type Props = {
  onPaymentMethodAdded: (dataDescriptor: string, dataValue: string, description: string, paymentMethodType: string) => void
  onStoredPaymentMethodAdded: (uuid: string, description: string, paymentMethodType: string) => void
}

@observer
export default class AddRenewalPaymentMethodForm extends React.Component<Props, never> {
  @observable
  private bankAccountFormState = new FormState<BankAccountFormState>({
    routingNumber: '',
    accountNumber: '',
    nameOnAccount: '',
    accountType: 'checking'
  })

  @observable
  private bankAccountFormErrors = new ErrorBag()

  private bankAccountFormHelper = new FormHelper(this.bankAccountFormState, this.bankAccountFormErrors)

  @observable
  private cardInfo?: CardInfo

  @observable
  private creditCardFormErrors = new ErrorBag()

  @observable
  private paymentMethodType: string | 'BankAccount' | 'CreditCard' = 'CreditCard'

  @observable
  private submitting = false

  private submitPaymentMethod = async (formErrors: ErrorBag, paymentMethod: {cardData: CreditCardDataType} | {bankData: BankAccountDataType}) => {
    this.submitting = true
    AppStateStore.showModalSpinner()
    formErrors.clearErrors()

    loadAcceptJs()
      .then(acceptJs => {
        return acceptJs.tokenizePaymentMethod(paymentMethod)
          .then((response: AcceptJsResponseDataType) => {
            const paymentMethodDescription = this.paymentMethodType === 'BankAccount'
              ? `${_.upperFirst(this.bankAccountFormState.get('accountType'))} Account ending in XXXX${this.bankAccountFormState.get('accountNumber').substr(-4)}`
              : `${this.cardInfo!.cardTypeNiceName} ending in XXXX${this.cardInfo!.cardNumber.substr(-4)}`
            this.props.onPaymentMethodAdded(response.opaqueData.dataDescriptor, response.opaqueData.dataValue, paymentMethodDescription, this.paymentMethodType)
          }, (response: AcceptJsResponseDataType) => {
            response.messages.message.forEach(message => {
              formErrors.addError('general', message.text)
              if (message.code === 'E_WC_06' || message.code === 'E_WC_07') {
                formErrors.addError('cardExpiration', message.text)
              } else if (message.code === 'E_WC_15') {
                formErrors.addError('cardCode', message.text)
              } else if (message.code === 'E_WC_05') {
                formErrors.addError('cardNumber', message.text)
              } else if (message.code === 'E_WC_24') {
                formErrors.addError('accountNumber', message.text)
              } else if (message.code === 'E_WC_25') {
                formErrors.addError('routingNumber', message.text)
              } else if (message.code === 'E_WC_26') {
                formErrors.addError('nameOnAccount', message.text)
              } else if (message.code === 'E_WC_27') {
                formErrors.addError('accountType', message.text)
              } else {
                formErrors.addError('_form', message.text)
              }
            })
          })
      }, err => {
        AppStateStore.showAlertModal('Error', 'There was an error contacting the payment provider')
      })
      .catch(ex => {
        logException(ex)
        AppStateStore.showAlertModal('Error', 'There was an error contacting the payment provider')
      })
      .then(() => {
        this.submitting = false
        AppStateStore.dismissModalSpinner()
      })
  }

  private submitCreditCard = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitPaymentMethod(this.creditCardFormErrors, {
      cardData: {
        cardNumber: this.cardInfo!.cardNumber.replace(/ /g, ''),
        cardCode: this.cardInfo!.cardCode,
        month: this.cardInfo!.expMonth,
        year: this.cardInfo!.expYear,
      }
    })
  }

  private submitBankAccount = (ev: SyntheticEvent) => {
    ev.preventDefault()
    AppStateStore.showModalSpinner()

    this.bankAccountFormErrors.clearErrors()

    const postData = {
      ...modelToSnakeCase(this.bankAccountFormState.toObject()),
      provider: 'Paya',
    }

    ApiClient.getInstance().post(route(ApiRoutes.public.paya.addPaymentMethod), postData)
      .then(response => {
        this.props.onStoredPaymentMethodAdded(response.data.uuid, response.data.description, this.paymentMethodType)
      }, error => {
        Util.handleErrorResponse(error.response, this.bankAccountFormErrors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      })
      .then(() => {
        this.submitting = false
        AppStateStore.dismissModalSpinner()
      })
  }

  render (): React.ReactNode {
    return <>
      <Nav tabs>
        <NavItem
          className={classNames({ active: this.paymentMethodType === 'CreditCard' })}
        >
          <NavLink
            onClick={() => {
              this.paymentMethodType = 'CreditCard'
            }}
          >Credit/Debit Card</NavLink>
        </NavItem>
        <NavItem
          className={classNames({ active: this.paymentMethodType == 'BankAccount' })}
        >
          <NavLink
            onClick={() => {
              this.paymentMethodType = 'BankAccount'
            }}
          >Bank Account</NavLink>
        </NavItem>
      </Nav>

      <div className="tab-content-body">
        <TabContent activeTab={this.paymentMethodType}>
          <TabPane tabId={'CreditCard'}>
            <label>Card Info</label>
            <form method="post" action="#" acceptCharset="UTF-8" onSubmit={this.submitCreditCard}>
              <div className="form-row">
                <div className="col-md-12">
                  <CreditCardInput
                    onChange={(cardInfo) => this.cardInfo = cardInfo}
                  />
                  <FormError errors={this.creditCardFormErrors} fieldName="cardNumber"/>
                  <FormError errors={this.creditCardFormErrors} fieldName="cardExpiration"/>
                  <FormError errors={this.creditCardFormErrors} fieldName="cardCode"/>
                  <FormError errors={this.creditCardFormErrors} fieldName="_form"/>
                </div>
              </div>
              <br/>
              <div className="form-buttons">
                <ButtonLoader type="submit" color="success" loading={this.submitting} disabled={!this.cardInfo || !this.cardInfo.isValid} onClick={this.submitCreditCard}>{'Add Card'}</ButtonLoader>
              </div>
            </form>
          </TabPane>
          <TabPane tabId={'BankAccount'}>
            <div className="form-row">
              <div className="col-md-6">
                <div className="form-group">
                  {this.bankAccountFormHelper.renderSelectInput({
                    label: 'Account Type',
                    options: [
                      { value: 'checking', text: 'Checking Account' },
                      { value: 'savings', text: 'Savings Account' },
                    ],
                    name: 'accountType',
                  })}
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-6">
                <div className="form-group">
                  {this.bankAccountFormHelper.renderTextInput({
                    label: 'Routing Number',
                    type: 'text',
                    name: 'routingNumber',
                  })}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  {this.bankAccountFormHelper.renderTextInput({
                    label: 'Account Number',
                    type: 'text',
                    name: 'accountNumber',
                  })}
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <div className="form-group">
                  {this.bankAccountFormHelper.renderTextInput({
                    label: 'Name On Account',
                    type: 'text',
                    name: 'nameOnAccount',
                  })}
                </div>
              </div>
            </div>
            <FormError errors={this.bankAccountFormErrors} fieldName="_form"/>

            {
              <Alert color="warning">
                <AchAuthorizationVerbiage/>
              </Alert>
            }

            <div className="form-buttons">
              <ButtonLoader type="submit" color="success" loading={this.submitting} onClick={this.submitBankAccount}>{'Add Bank Account'}</ButtonLoader>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </>
  }
}
