import * as React from "react"
import BaseView from "../BaseView"
import MemberNotificationSettings from "../../components/MemberNotificationSettings"
import MemberPreferenceSettings from "../../components/MemberPreferenceSettings"
import UserLoginSettings from "../../components/UserLoginSettings"
import AuthStore from "../../stores/AuthStore"
import { observer } from "mobx-react"

@observer
export default class MemberSettingsView extends BaseView {
  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>Settings and Notifications</h1>
      </>
    )
  }

  renderContentBody (): React.ReactNode {
    return <>
      <UserLoginSettings userId={AuthStore.getUser()!.id}/>
      <br/><br/>
      {
        AuthStore.getUser()!.member
          ? <>
            <MemberPreferenceSettings memberId={AuthStore.getUser()!.member!.id}/>
            <br/><br/>
            <MemberNotificationSettings memberId={AuthStore.getUser()!.member!.id}/>
          </>
          : null
      }
    </>
  }
}