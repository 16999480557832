import * as React from "react"
import { observer } from "mobx-react"
import ErrorBag from "../common/ErrorBag"

type Props = {
  errors: ErrorBag
  fieldName: string
}

@observer
export default class FormError extends React.Component<Props, never> {
  render (): React.ReactNode {
    const { errors, fieldName } = this.props
    return errors.hasErrors(fieldName) ? <div className="form-error is-invalid text-danger">{errors.getError(fieldName)}</div> : null
  }
}