import * as React from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import Util, { publicPath } from "../../common/Util"
import Config from "../../common/Config"
import { BarLoader } from "react-spinners"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import { route } from "../../routes/routes"

type Props = {
  match: {
    params: {
      slug: number
    }
  },
}

@observer
export default class VerifyNotificationDestinationView extends React.Component<Props> {
  @observable private loading = false
  @observable private error?: string
  @observable private channel?: string

  componentDidMount (): void {
    this.loading = true

    ApiClient.getInstance()
      .post(route(
        ApiRoutes.public.verifyNotificationDestination,
        { slug: this.props.match.params.slug }
      ))
      .then(response => {
        this.loading = false
        this.channel = response.data.channel
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
          this.error = 'The link you followed may be invalid, or you may have already verified this phone number or email address'
        } else {
          this.error = Util.extractErrorMessage(err.response)
        }
      })
  }

  private renderVerified = () => {
    return this.channel
      ? <div className="alert alert-success text-center">
        Thank you for confirming your {this.channel === 'sms' ? 'phone number' : 'email address'}!
      </div>
      : null
  }

  private renderError = () => {
    return <div className="alert alert-danger text-center">
      <p>Sorry, we were unable to verify your notification method.</p>
      <p>{this.error}</p>
    </div>
  }

  render (): React.ReactNode {
    return <>
      <div className="header-container">
        <div className="brand">
          <img className="brand-image" alt="LeTip Wired" src={publicPath('img/wired-logo.png')}/>
        </div>
        <div className="header-nav"/>
      </div>
      <div className="body-container invitation-response">
        <div className="left-nav"/>
        <div className="content-container">
          <div className="content">
            {this.error
              ? this.renderError()
              : this.loading
                ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
                : this.renderVerified()
            }
          </div>
          <div className="content-footer">
            Version {Config.VERSION || '???'}{Config.BUILD_NUMBER ? `-${Config.BUILD_NUMBER}` : null} {Config.ENV !== 'production' ? `(${Config.ENV})` : null} {Config.NODE_ENV !== 'production' ? `[${Config.NODE_ENV}]` : null}
          </div>
        </div>
      </div>
    </>
  }
}