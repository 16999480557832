import React from 'react'
import ApiClient, { ApiRoutes } from '../../api/ApiClient'
import AuthStore from '../../stores/AuthStore'
import ExtendedNetworkCategoryMember from '../../models/ExtendedNetworkCategoryMember'
import LazyResourcePanel from '../LazyResourcePanel'
import Util, { createLazyResource, modelToCamelCase, publicPath, safeNull } from '../../common/Util'
import { Link } from 'react-router-dom'
import { route } from '../../routes/routes'
import { Routes } from '../../routes/AppRoutes'
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import AppStateStore from '../../stores/AppStateStore'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import Member from '../../models/Member'
import _ from 'lodash'
import axios, { CancelTokenSource } from 'axios'
import LoadingPanel from '../LoadingPanel'

@observer
export default class ExtendedNetworkCategories extends React.Component {
  @observable private renderAddModal = false

  private extendedNetworkCategoryMembers = createLazyResource<ExtendedNetworkCategoryMember[]>(() => {
    return ApiClient.query(`
member {
  extendedNetworkCategoryMembers
}
    `, {
      where: [
        { id: AuthStore.getUser()!.member!.id },
      ],
    })
  }, response => response.data.member.extendedNetworkCategoryMembers.map((c: any) => new ExtendedNetworkCategoryMember().init(modelToCamelCase(c))))

  private showCategory = (category: ExtendedNetworkCategoryMember) => {
    AppStateStore.showModalSpinner()
    ApiClient.getInstance().post(route(ApiRoutes.extendedNetwork.showCategory), {
      category_id: category.category.id,
      member_id: category.member.id,
    }).then(() => this.extendedNetworkCategoryMembers.invalidate().then())
      .catch(response => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(response)))
      .then(() => AppStateStore.dismissModalSpinner())
  }

  private hideCategory = (category: ExtendedNetworkCategoryMember) => {
    AppStateStore.showModalSpinner()
    ApiClient.getInstance().post(route(ApiRoutes.extendedNetwork.hideCategory), {
      category_id: category.category.id,
      member_id: category.member.id,
    }).then(() => this.extendedNetworkCategoryMembers.invalidate().then())
      .catch(response => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(response)))
      .then(() => AppStateStore.dismissModalSpinner())
  }

  private unassignCategory = (category: ExtendedNetworkCategoryMember) => {
    AppStateStore.showModalSpinner()
    ApiClient.getInstance().post(route(ApiRoutes.extendedNetwork.unassignCategory), {
      category_id: category.category.id,
    }).then(() => this.extendedNetworkCategoryMembers.invalidate().then())
      .catch(response => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(response)))
      .then(() => AppStateStore.dismissModalSpinner())
  }

  private addExternalMember = async (categoryId: number, memberId: number) => {
    AppStateStore.showModalSpinner()
    try {
      await ApiClient.getInstance().post(route(ApiRoutes.extendedNetwork.assignCategory), {
        category_id: categoryId,
        member_id: memberId,
      })
      this.extendedNetworkCategoryMembers.invalidate().then()
    } catch (err) {
      AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err))
    }
    AppStateStore.dismissModalSpinner()
  }

  render () {
    return <>
      <Alert color="info">
        You can configure which members can receive tips from your extended network below.
      </Alert>
      <div className="row">
        <div className="col-md-6">
          <LazyResourcePanel resource={this.extendedNetworkCategoryMembers}>
            {categories => <div>
              {categories.map(category => <div key={category.category.id} className="d-flex align-items-center mb-2">
                <div style={{ width: 100 }}>
                  {
                    category.isHidden
                      ? <Button block color="primary" onClick={() => this.showCategory(category)}>Show</Button>
                      : category.isOutsideChapter
                        ? <Button block color="primary" onClick={() => this.unassignCategory(category)}>Remove</Button>
                        : <Button block color="primary" onClick={() => this.hideCategory(category)}>Hide</Button>
                  }
                </div>
                <div style={{ width: 50, textAlign: 'center' }}>
                  {
                    category.isHidden
                      ? <i style={{ fontSize: 22 }} className="fa fa-times text-danger" title="This category is hidden from your extended network"/>
                      : category.isOutsideChapter
                        ? <i style={{ fontSize: 22 }} className="fa fa-mail-forward" title="This member is from outside of your chapter"/>
                        : <i style={{ fontSize: 22 }} className="fa fa-check text-success" title="This member is in your chapter"/>
                  }
                </div>
                <div className="mr-2"><img style={{ width: 60, height: 60, objectFit: 'cover', objectPosition: 'top center', backgroundColor: '#ccc' }} src={category.member.profileImageUrl || publicPath('/img/user-profile-placeholder.png')}/></div>
                <div className="flex-fill">
                  <span>{category.category.name}</span><br/>
                  <Link to={route(Routes.network.member, { memberId: category.member.id })}>{category.member.fullName}</Link>
                </div>
              </div>)}
            </div>}
          </LazyResourcePanel>
        </div>
        <div className="col-md-6">
          <h5>Inter-chapter</h5>
          <hr/>
          <p>If a category is not already filled by someone in your own chapter, you can choose a member from another chapter to add to your extended network</p>
          <Button type="button" color="primary" onClick={() => this.renderAddModal = true}>Add Category</Button>
        </div>
      </div>
      {
        (this.renderAddModal && this.extendedNetworkCategoryMembers.current)
          ? <ChooseExternalMemberModal
            onChoose={(categoryId, memberId) => this.addExternalMember(categoryId, memberId)}
            onClosed={() => this.renderAddModal = false}
            filledCategories={this.extendedNetworkCategoryMembers.current}
            chapterId={AuthStore.getUser()!.chapter.id}
          />
          : null
      }
    </>
  }
}

type ChooseExternalMemberModalProps = {
  onClosed: () => void
  onChoose: (categoryId: number, memberId: number) => void
  chapterId: number
  filledCategories: ExtendedNetworkCategoryMember[]
}

@observer
class ChooseExternalMemberModal extends React.Component<ChooseExternalMemberModalProps> {
  private MAX_FETCH = 100

  @observable loading = false
  @observable loaded = false
  @observable show = true
  @observable members: Member[] = []
  @observable totalMembers: number = 0
  @observable error?: string
  @observable searchText = ''
  private fetchDataCancelToken?: CancelTokenSource

  private loadMembers = async () => {
    this.error = undefined
    this.loading = true

    if (this.fetchDataCancelToken) {
      this.fetchDataCancelToken.cancel()
      this.fetchDataCancelToken = undefined
    }

    if (this.searchText.trim().length > 0) {
      const cancelToken = axios.CancelToken.source()
      this.fetchDataCancelToken = cancelToken
      try {
        const response = await ApiClient.query(`members {
        id
        fullName
        profileImageUrl
        chapter {
          name
        }
        category {
          id
          name
        }
      }`, {
          returnTotal: true,
          where: [
            { _scope: 'search', value: this.searchText.trim() },
            { _scope: 'active' },
            { id: 'chapterId', op: '!=', value: this.props.chapterId }
          ],
          order: [
            { id: 'fullName' },
          ],
          limit: this.MAX_FETCH,
        }, {
          cancelToken: cancelToken.token,
        })
        this.members = response.data.members.map((m: any) => new Member().init(m))
        this.totalMembers = response.data._meta.total
      } catch (err) {
        if (!axios.isCancel(err)) {
          this.error = Util.extractErrorMessage(err)
        }
      }
    } else {
      this.members = []
    }

    this.loading = false
    this.loaded = true
  }

  private refreshSearch = _.debounce(() => {
    this.loadMembers().then()
  }, 500)

  private toggle = () => {
    this.show = false
  }

  private chooseMember = (member: Member) => {
    this.props.onChoose(member.category!.id, member.id)
    this.toggle()
  }

  render () {
    return <Modal isOpen={this.show} onClosed={this.props.onClosed} size={'lg'} toggle={this.toggle}>
      <ModalHeader toggle={this.toggle}>
        Choose Member
      </ModalHeader>
      <ModalBody>
        {
          this.error
            ? <Alert color={'danger'}>{this.error}</Alert>
            : null
        }
        <div className="member-card-search-search-input">
          <i className="fa fa-search"/>
          <input
            className="form-control"
            placeholder="Search members"
            type="text"
            name="search"
            value={this.searchText}
            onChange={ev => {
              this.searchText = ev.target.value
              this.refreshSearch()
            }}
          />
        </div>
        <div>
          <LoadingPanel loading={this.loading} loaded={this.loaded}>
            {
              this.members.length
                ? <table className="table table-striped">
                  <thead>
                  <tr>
                    <th/>
                    <th>Member</th>
                    <th>Chapter</th>
                    <th>Category</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    this.members.map(member => <tr key={member.id}>
                      <td>
                        {
                          member.category
                            ? this.props.filledCategories.filter(c => c.category.id === member.category!.id).length > 0
                              ? <div className={'text-muted'}>Category Filled</div>
                              : <Button color={'primary'} onClick={() => this.chooseMember(member)}>Choose Member</Button>
                            : <div className={'text-muted'}>No Category</div>
                        }
                      </td>
                      <td>
                        {member.fullName}
                      </td>
                      <td>
                        {safeNull(() => member.chapter!.name) || '---'}
                      </td>
                      <td>
                        {safeNull(() => member.category!.name) || '---'}
                      </td>
                    </tr>)
                  }
                  </tbody>
                </table>
                : null
            }
            {
              (this.members.length && this.totalMembers > this.MAX_FETCH)
                ? <div>
                  <div className={'text-muted text-center'}>{Util.formatNumber(this.totalMembers - this.members.length)} additional member(s) not displayed</div>
                </div>
                : null
            }
          </LoadingPanel>
        </div>
      </ModalBody>
    </Modal>
  }
}
