import { observer } from "mobx-react"
import * as React from "react"
import { ChangeEvent } from "react"
import { observable } from "mobx"
import { createLazyResource } from "../../common/Util"
import Area from "../../models/Area"
import ApiClient from "../../api/ApiClient"
import LazyResourcePanel from "../LazyResourcePanel"

@observer
export class AreaFilters extends React.Component<{
  selectedAreas: number[]
  onClicked: (ev: ChangeEvent<HTMLInputElement>, areaId: number) => void
}> {

  @observable private areas = createLazyResource<Area[]>(() => {
    return ApiClient.query(`
    areas {
      *
    }`, {
      where: [
        { _scope: 'active' },
      ],
      order: [
        'name',
      ],
    })
  }, response => response.data.areas.map((a: {}) => new Area().init(a)))

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <LazyResourcePanel resource={this.areas}>
      {areas => areas.map(area =>
        <div key={area.id} className="form-check form-checkbox">
          <label>
            <input type="checkbox"
                   className="form-check-input"
                   checked={this.props.selectedAreas.indexOf(area.id) > -1}
                   onChange={(ev) => this.props.onClicked(ev, area.id)}
            />
            <span className="label-text">{area.name}</span>
          </label>
        </div>)}
    </LazyResourcePanel>
  }
}