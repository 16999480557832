import BaseFilter from "./BaseFilter"
import { computed, observable } from 'mobx'
import React from 'react'
import EventBus from '../../../common/EventBus'
import { SelectOption } from '../../inputs/SelectInput'
import SelectFilterComponent from './SelectFilterComponent'

export enum SelectFilterTargetType {
  Field,
  Scope,
}

export type SelectFilterState = {
  value: string
}

export default class SelectFilter extends BaseFilter<SelectFilterState> {
  @observable value: string = ''

  @computed get state (): SelectFilterState {
    return {
      value: this.value,
    }
  }

  @computed get isActive (): boolean {
    return this.value !== ''
  }

  @computed get description (): string {
    return this.value.trim().length
      ? `${this.label} is ${this.value}`
      : ''
  }

  constructor (public id: string, public targetType: SelectFilterTargetType, public target: string, public label: string, public options: SelectOption[]) {
    super()
  }

  getWhereClause = () => {
    return this.value.trim().length
      ? this.targetType === SelectFilterTargetType.Scope
        ? [{
          _scope: this.target,
          value: this.value,
        }]
        : [{
          id: this.target,
          value: this.value,
        }]
      : []
  }

  restoreState = (state: SelectFilterState) => {
    this.value = state.value
  }

  private onChanged = (value: string) => {
    this.value = value
  }

  render = (eventBus: EventBus) => {
    return React.createElement(SelectFilterComponent, {
      label: this.label,
      state: this.state,
      onChanged: this.onChanged,
      options: this.options,
      eventBus: eventBus,
    })
  }
}