import BaseModel from "./BaseModel"
import Address from "./Address"
import ContactMethod from "./ContactMethod"
import { publicPath } from "../common/Util"
import { SocialLinksType } from "./Chapter"

export type HoursOfOperationDay = { day: string, open: string, close: string };

export const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export default class Business extends BaseModel {
  id: number
  name: string
  websiteUrl?: string
  logoUrl?: string
  description?: string
  hoursOfOperation: HoursOfOperationDay[]
  isAddressPrivate: boolean
  isEmailPrivate: boolean
  socialLinks: SocialLinksType
  phoneNumber: string
  emailAddress: string

  address?: Address

  constructor (data?: {}) {
    super()

    if (data) {
      this.init(data)
    }
  }

  getCasts (): {} {
    return {
      id: 'number',
      isAddressPrivate: 'boolean',
      isEmailPrivate: 'boolean'
    }
  }

  setSocialLinks (s?: {}) {
    return {
      facebook: '',
      twitter: '',
      linkedin: '',
      instagram: '',
      youtube: '',
      alignable: '',
      pinterest: '',
      snapchat: '',
      vimeo: '',
      ...(s || {}),
    }
  }
  getLogoImageUrl = () => {
    return this.logoUrl || publicPath('/img/business-logo-placeholder.png')
  }

  setAddress = (a: {}): Address | undefined => {
    return a ? new Address(a) : undefined
  }
}
