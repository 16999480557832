import CsvTransformer from "./CsvTransformer"
import moment from "moment-timezone"
import { RenewalPaymentReportData } from "../../components/system/reporting/ChapterRenewalPaymentReport"

export default class ChapterRenewalPaymentCsvTransformer extends CsvTransformer<RenewalPaymentReportData> {
  get filename () {
    return `renewal-payment-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'Full Name',
      valueTransformer: row => row.member.fullName,
    })

    this.addColumn({
      header: 'Join Date',
      valueTransformer: row => row.member.joinDate ? moment(row.member.joinDate).format('MM/DD/YYYY') : '',
    })

    this.addColumn({
      header: 'Next Renewal Due Date',
      valueTransformer: row => row.member.renewalDate ? moment(row.member.renewalDate).format('MM/DD/YYYY') : '',
    })

    this.addColumn({
      header: 'Current Status',
      valueTransformer: row => row.member.currentStatus,
    })
  }
}
