import { observer } from 'mobx-react'
import React from 'react'
import { observable, toJS } from 'mobx'
import Util from '../../common/Util'
import ApiClient, { ApiRoutes } from '../../api/ApiClient'
import { route } from '../../routes/routes'
import LoadingPanel from '../LoadingPanel'
import _ from 'lodash'
import moment from 'moment'

type ChaptersStartedData = {
  chapters: {
    id: number
    name: string
    status: string
    dateFounded: string
    numberOfMembers: number
  }[]
}

type Props = {
  filters: {
    state?: string
    areaId?: number
    chapterId?: number
  }
}

@observer
export default class ChaptersStartedDashboard extends React.Component<Props> {
  @observable
  private chaptersStarted?: ChaptersStartedData
  @observable
  private error?: string
  @observable
  private loading = false

  componentDidMount () {
    this.loadData().then()
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (!_.isEqual(toJS(prevProps.filters), toJS(this.props.filters))) {
      this.loadData().then()
    }
  }

  private loadData = async () => {
    try {
      this.error = undefined
      this.loading = true

      const response = await ApiClient.getInstance().get(route(ApiRoutes.dashboard.chaptersStarted, {}, this.props.filters))

      this.chaptersStarted = response.data

      this.loading = false
    } catch (err) {
      this.error = Util.extractErrorMessage(err.response)
    }
  }

  render () {
    return <div>
      <b>Chapters started in the last 90 days</b>
      <LoadingPanel loading={this.loading} loaded={!!this.chaptersStarted} error={this.error}>
        {
          this.chaptersStarted
            ? <table className="table table-striped">
              <tbody>
              {
                this.chaptersStarted.chapters.map(chapter => <tr key={String(chapter.id)}>
                  <td>{chapter.name}</td>
                  <td>{Util.formatNumber(chapter.numberOfMembers)} members</td>
                  <td>{moment(chapter.dateFounded).format('MM/DD/YYYY')}</td>
                  <td>{chapter.status}</td>
                </tr>)
              }
              </tbody>
            </table>
            : null
        }
      </LoadingPanel>
    </div>
  }
}
