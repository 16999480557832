import BaseView from "../BaseView"
import * as React from "react"
import { observer } from "mobx-react"
import DocumentLibrary from "../../components/DocumentLibrary"
import { LibraryOwnerType, LibraryType } from "../../models/DocumentLibraryItem"
import AuthStore from "../../stores/AuthStore"
import { Alert } from 'reactstrap'

@observer
export default class NetworkDocumentLibraryView extends BaseView {
  renderContentHeader (): React.ReactNode | null {
    return <>
      <h1>{AuthStore.getUser()!.chapter.name} Document Library</h1>
    </>
  }

  renderContentBody (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <div>
      <Alert color="info">
        This is a secure place for your chapter to keep important documents. Only
        Board members of your chapter can see this feature, and Board Members elected in
        following years will be able to see these documents. Feel free to upload under assigned
        folder or to create new ones.
      </Alert>
      <DocumentLibrary libraryDescriptor={{
        libraryType: LibraryType.ChapterDocumentLibrary,
        libraryOwnerId: AuthStore.getUser()!.chapter.id,
        libraryOwnerType: LibraryOwnerType.Chapter,
      }}/>
    </div>
  }
}
