import BaseFilter from "./BaseFilter"
import { parseDateLocal } from "../../../common/Util"
import { computed, observable } from 'mobx'
import React from 'react'
import EventBus from '../../../common/EventBus'
import moment from 'moment-timezone'
import DateFilterComponent from './DateFilterComponent'

export type DateFilterState = {
  startDate: string
  endDate: string
}

type DateFilterOptions = {
  usesLocalTimezone?: boolean
}

export default class DateFilter extends BaseFilter<DateFilterState> {
  @observable startDate: string = ''
  @observable endDate: string = ''

  @computed get state (): DateFilterState {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
    }
  }

  @computed get isActive (): boolean {
    return this.startDate.trim().length > 0 || this.endDate.trim().length > 0
  }

  @computed get description (): string {
    if (this.startDate.trim().length && this.endDate.trim().length) {
      return `${this.label} between ${this.startDate} and ${this.endDate}`
    } else if (this.startDate.trim().length) {
      return `${this.label} after ${this.startDate}`
    } else if (this.endDate.trim().length) {
      return `${this.label} before ${this.endDate}`
    } else {
      return ''
    }
  }

  constructor (public id: string, public targetField: string, public label: string, public options: DateFilterOptions = {}) {
    super()

    this.options = Object.assign({
      usesLocalTimezone: true,
    }, options)
  }

  getWhereClause = () => {
    const where = []

    if (this.startDate.trim().length) {
      const startDate = moment(this.startDate).isValid() ? parseDateLocal(this.startDate, this.options.usesLocalTimezone ? undefined : 'UTC').startOf('day').tz('UTC').toISOString() : undefined
      if (startDate) {
        where.push({
          id: this.targetField,
          op: '>=',
          value: startDate,
        })
      }
    }

    if (this.endDate.trim().length) {
      const endDate = moment(this.endDate).isValid() ? parseDateLocal(this.endDate, this.options.usesLocalTimezone ? undefined : 'UTC').add(1, 'day').tz('UTC').toISOString() : undefined
      if (endDate) {
        where.push({
          id: this.targetField,
          op: '<',
          value: endDate,
        })
      }
    }

    return where
  }

  restoreState = (state: DateFilterState) => {
    this.startDate = state.startDate
    this.endDate = state.endDate
  }

  private onStartDateChanged = (value: string) => {
    this.startDate = value
  }

  private onEndDateChanged = (value: string) => {
    this.endDate = value
  }

  render = (eventBus: EventBus) => {
    return React.createElement(DateFilterComponent, {
      label: this.label,
      state: this.state,
      eventBus: eventBus,
      onStartDateChanged: this.onStartDateChanged,
      onEndDateChanged: this.onEndDateChanged,
    })
  }
}