import * as React from "react"
import Address, { IAddress } from "../models/Address"
// import Config from "../common/Config"

export default class MapView extends React.Component<{
  address: IAddress
  width?: number
  height?: number
}> {
  render (): React.ReactNode {
    // Google Maps image was not getting displayed on Network Search - Chapters
    // const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?key=${Config.GOOGLE_API_KEY}&size=${this.props.width || 500}x${this.props.height || 500}&markers=${encodeURIComponent(Address.getFullAddress(this.props.address))}`
    const mapUrl = `https://maps.google.com/?q=${encodeURIComponent(Address.getFullAddress(this.props.address))}`

    // Google Maps image was not getting displayed on Network Search - Chapters
    // return <a href={mapUrl} target="_blank"><img className="map-view" src={imageUrl}/></a>
    return <a href={mapUrl} target="_blank"><img style={{ width: 20, height: 20 }} className="map-view" src={`/img/google-maps.png`}/></a>
  }
}