import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import ButtonLoader from "../../ButtonLoader"
import { observable } from "mobx"
import ApiClient from "../../../api/ApiClient"
import AppStateStore from "../../../stores/AppStateStore"
import Util, { modelToSnakeCase } from "../../../common/Util"
import { toast } from "react-toastify"
import Member from "../../../models/Member"
import FormState from '../../../common/FormState'
import ErrorBag from '../../../common/ErrorBag'
import FormHelper from '../../../forms/FormHelper'

type TerminateMembershipModalProps = {
  onClosed: () => void
  memberId: number
}

@observer
export default class TerminateMembershipModal extends React.Component<TerminateMembershipModalProps> {
  @observable private isOpen = true
  @observable private submitting = false
  @observable private member?: Member

  @observable private formState = new FormState({
    sendTerminationLetters: false,
    notes: '',
  })
  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private loadMember = () => {
    ApiClient.query(
      `
member {
  *
  activeMembership {
    id
  }
}
      `,
      {
        where: [{ id: this.props.memberId }]
      }
    )
      .then(response => this.member = new Member().init(response.data.member))
      .catch(() => {
      })
  }

  componentDidMount (): void {
    this.loadMember()
  }

  componentDidUpdate (prevProps: Readonly<TerminateMembershipModalProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.memberId !== this.props.memberId) {
      this.loadMember()
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitting = true

    ApiClient.members.terminateMembership(this.props.memberId, modelToSnakeCase(this.formHelper.toObject()))
      .then(() => {
        toast.success('Membership has been terminated')
        this.isOpen = false
      })
      .catch(error => {
        Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      })
      .then(() => {
        this.submitting = false
      })
  }

  private toggle = () => {
    if (!this.submitting) {
      this.isOpen = false
    }
  }

  @observable private showCategoryAvailabilityWarning = false

  render () {
    return <>
      <Modal isOpen={this.isOpen} toggle={this.toggle} onClosed={this.props.onClosed} size="md">
        <ModalHeader toggle={this.toggle}>
          Terminate Membership
        </ModalHeader>
        <ModalBody>
          <p>
            Are you sure you want to terminate this member's membership?
          </p>
          <div className="form-group mt-4">
            {this.formHelper.renderCheckboxInput({
              name: 'sendTerminationLetters',
              label: 'Send Termination Letters',
              disabled: this.submitting,
            })}
          </div>
          <div className="form-group mt-4">
            {this.formHelper.renderTextAreaInput({
              name: 'notes',
              label: 'Notes',
              disabled: this.submitting,
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={this.toggle}
          >Cancel</Button>
          <ButtonLoader
            type="button"
            color="danger"
            onClick={this.submit}
            loading={this.submitting}
          >Terminate Membership</ButtonLoader>
        </ModalFooter>
      </Modal>
    </>
  }
}
