import * as React from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import { createLazyResource, nl2br, normalizeUrl } from "../../common/Util"
import Chapter from "../../models/Chapter"
import ApiClient from "../../api/ApiClient"
import LazyResourcePanel from "../LazyResourcePanel"
import AddressView from "../AddressView"
import MapView from "../MapView"

type Props = {
  chapterId: number
}

@observer
export default class NetworkChapterGeneral extends React.Component<Props> {
  @observable private chapter = createLazyResource<Chapter>(() => {
    return ApiClient.query(
      `
chapter {
  *

  meetingLocationAddress {
    *
  }
}
      `,
      {
        where: [{ id: this.props.chapterId }]
      }
    )
  }, response => new Chapter(response.data.chapter))


  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.chapterId != this.props.chapterId) {
      this.chapter.invalidate()
    }
  }

  private renderSocialMediaLinks (social: {}) {
    const sites = {
      'facebook': { name: 'Facebook', icon: 'fa fa-facebook-square' },
      'twitter': { name: 'Twitter', icon: 'fa fa-twitter-square' },
      'linkedin': { name: 'Linkedin', icon: 'fa fa-linkedin-square' },
      'instagram': { name: 'Instagram', icon: 'fa fa-instagram' },
      'youtube': { name: 'YouTube', icon: 'fa fa-youtube' },
      'alignable': { name: 'Alignable', icon: 'fa fa-home' },
      'pinterest': { name: 'Pinterest', icon: 'fa fa-pinterest' },
      'snapchat': { name: 'Snapchat', icon: 'fa fa-snapchat' },
      'vimeo': { name: 'Vimeo', icon: 'fa fa-vimeo' },
    }

    const links = [
      'facebook',
      'twitter',
      'linkedin',
      'instagram',
      'youtube',
      'pinterest',
      'snapchat',
      'vimeo',
      'alignable',
    ].filter(soc => !!social[soc])

    return links.length
      ? links.map(soc => <div key={soc} className="social-link-list-item">
        <a href={normalizeUrl(social[soc])} target="_blank"><i className={sites[soc].icon}/> {sites[soc].name}</a>
      </div>)
      : <p>Social media links have not been entered</p>
  }

  private hasSocialMediaLinks (social: {}) {
    return [
      'facebook',
      'twitter',
      'linkedin',
      'instagram',
      'youtube',
      'alignable',
      'pinterest',
      'snapchat',
      'vimeo',
    ].filter(soc => !!social[soc]).length > 0
  }

  render (): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <LazyResourcePanel resource={this.chapter}>
      {chapter => <>
        <div className="row">
          <div className="col-md-6">
            <h5 className="text-muted">Name</h5>
            <div>
              {chapter.name}
            </div>

            {chapter.bio && <>
              <h5 className="text-muted mt-4">Bio</h5>
              <div>
                {nl2br(chapter.bio)}
              </div>
            </>}

            {chapter.websiteUrl && <>
              <h5 className="text-muted mt-4">Website</h5>
              <div>
                <a href={normalizeUrl(chapter.websiteUrl)} target="_blank">{chapter.websiteUrl}</a>
              </div>
            </>}

            {
              this.hasSocialMediaLinks(chapter.socialLinks)
                ? <>
                  <h5 className="text-muted mt-4">Social Media Profiles</h5>
                  <div className="form-row">
                    <div className="col-12">
                      {this.renderSocialMediaLinks(chapter.socialLinks)}
                    </div>
                  </div>
                </>
                : null
            }
          </div>
          <div className="col-md-6">
            <h5 className="text-muted">General Meeting Information</h5>
            <div className="row">
              <div className="col-md-4">
                <b>Day</b><br/>
                {chapter.meetingDay}
              </div>
              <div className="col-md-4">
                <b>Time</b><br/>
                {chapter.meetingTime}
              </div>
            </div>
            <br/>
            <div>
              <b>{chapter.meetingLocationDescription}</b>
              {chapter.meetingLocationAddress && <AddressView address={chapter.meetingLocationAddress} showMapLink={false}/>}
            </div>
            {chapter.meetingLocationAddress && <div className="mt-4">
              <MapView address={chapter.meetingLocationAddress}/>
            </div>}
          </div>
        </div>
      </>}
    </LazyResourcePanel>
  }
}
