import * as React from "react"
import { observable } from "mobx"
import Util, { safeNull, setUserTimezone } from "../common/Util"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import { route } from "../routes/routes"
import AppStateStore from "../stores/AppStateStore"
import { toast } from "react-toastify"
import Member from "../models/Member"
import moment from 'moment-timezone'
import { observer } from "mobx-react"
import AuthStore from "../stores/AuthStore"

@observer
export default class MemberPreferenceSettings extends React.Component<{
  memberId: number
}> {
  @observable private member?: Member

  @observable private formState = new FormState({
    timezone: '',
  })
  @observable private formErrors = new ErrorBag()

  componentDidMount (): void {
    ApiClient.query(
      `
member {
  *
}
      `,
      {
        where: [{ id: this.props.memberId }]
      }
    )
      .then(response => {
        this.member = new Member().init(response.data.member)
        this.formState.set('timezone', this.member.timezone)
      })
  }

  private submit = (timezone: string) => {
    const original = this.formState.get('timezone') || ""

    this.formState.set('timezone', timezone)

    this.formErrors.clearErrors()

    ApiClient.getInstance()
      .put(route(ApiRoutes.members.updateTimeZone, { id: this.props.memberId }), this.formState.toObject())
      .then(response => {
        if (this.props.memberId == safeNull(() => AuthStore.getUser()!.member!.id)) {
          AuthStore.getUser()!.timezone = response.data.member.timezone
          setUserTimezone(response.data.member.timezone)
        }
        toast.success('Time Zone updated')
      })
      .catch(error => {
          Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
            AppStateStore.showAlertModal('Error', message, m => {
              m.hide()
            })
            return true
          })

          this.formState.setAll({
            timezone: original
          })
        }
      )
  }

  render (): React.ReactNode {
    const renderOption = (tz: string) => {
      return <option value={tz}>{tz} - {moment().tz(tz).format('h:mm A')}</option>
    }
    return <>
      <h6 className="text-muted mb-4">Time Zone</h6>
      {
        this.member
          ? <div className="row">
            <div className="col-md-6 col-12">
              <select
                className="form-control"
                value={this.formState.get('timezone') || ''}
                onChange={ev => this.submit(ev.target.value)}
              >
                <option value="">(choose one)</option>
                <optgroup label="Pacific Time">
                  {renderOption('America/Vancouver')}
                  {renderOption('America/Los_Angeles')}
                </optgroup>
                <optgroup label="Mountain Time">
                  {renderOption('America/Edmonton')}
                  {renderOption('America/Denver')}
                  {renderOption('America/Phoenix')}
                </optgroup>
                <optgroup label="Central Time">
                  {renderOption('America/Winnipeg')}
                  {renderOption('America/Chicago')}
                </optgroup>
                <optgroup label="Eastern Time">
                  {renderOption('America/Toronto')}
                  {renderOption('America/New_York')}
                </optgroup>
                <optgroup label="Hawaii Time">
                  {renderOption('Pacific/Honolulu')}
                </optgroup>
                <optgroup label="Alaska Time">
                  {renderOption('America/Anchorage')}
                </optgroup>
                <optgroup label="Newfoundland Time">
                  {renderOption('America/St_Johns')}
                </optgroup>
                <optgroup label="Atlantic Time">
                  {renderOption('America/Halifax')}
                </optgroup>
              </select>
            </div>
          </div>
          : null
      }
    </>
  }
}