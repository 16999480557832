import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone/moment-timezone"
import ConversationParticipant from "./ConversationParticipant"
import ReceivedMessage from "./ReceivedMessage"

export default class Message extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  readAt: Moment | null
  message: string

  senderParticipantId: number

  sender: ConversationParticipant
  receivedMessages: Message[]

  getSenderName = () => {
    return this.sender.user.member ? this.sender.user.member.getPreferredFullName() : this.sender.user.name
  }

  getSenderProfileImageUrl = () => {
    return this.sender.user.member ? this.sender.user.member.getProfileImageUrl() : undefined
  }

  getCasts (): {} {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      senderParticipantId: 'number',
    }
  }

  setSender (s?: {}) {
    return s ? new ConversationParticipant().init(s) : null
  }

  setReceivedMessages (m?: []) {
    return m ? m.map(mm => new ReceivedMessage().init(mm)) : []
  }
}