import { Modal, ModalBody, ModalHeader } from "reactstrap"
import * as React from "react"
import { observer } from "mobx-react"
import EditEventForm from "./EditEventForm"
import { BarLoader } from "react-spinners"
import { observable } from "mobx"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import AppStateStore from "../stores/AppStateStore"
import Util from "../common/Util"
import Event from '../models/Event'
import EditRecurringEventForm from "./EditRecurringEventForm"
import { route } from "../routes/routes"
import RecurringEvent from "../models/RecurringEvent"
import { modelToCamelCase } from "../common/Util"


type EditRecurringEventModalProps = {
  isOpen: boolean
  toggle: () => void
  eventId: number
  onCancel: () => void
  onSaved: () => void
  onDelete:() => void
}

@observer
export class EditRecurringEventModal extends React.Component<EditRecurringEventModalProps> {
  static defaultProps = {
    clearAfterSave: true,
  }

  @observable private event?: RecurringEvent

  componentDidMount (): void {
    this.loadEvent()
  }

  private loadEvent = () => {
    if (!this.props.eventId) {
      return
    }
    ApiClient.getInstance()
        .get(route(ApiRoutes.calendar.fetchRecurringEventById, { id: this.props.eventId }))
    .then(response =>this.event = new RecurringEvent().init(modelToCamelCase(response.data)))
      .catch(error => {
        console.log("Error", error)
        this.props.toggle()
        AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response))
      })
  }

  componentDidUpdate (prevProps: Readonly<EditRecurringEventModalProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.eventId !== this.props.eventId) {
      this.event = undefined
      this.loadEvent()
    }
  }

  render (): React.ReactNode {
    const { isOpen, toggle } = this.props

    return <Modal isOpen={isOpen} onClosed={this.props.onCancel} size="lg">
      <ModalHeader toggle={toggle}>
        Edit Event
      </ModalHeader>
      <ModalBody>
        {
          this.event
            ? <EditRecurringEventForm
              onCancel={this.props.onCancel}
              onSaved={this.props.onSaved}
              event={this.event}
              onDelete={this.props.onDelete}
            />
            : <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
        }
      </ModalBody>
    </Modal>
  }
}
