import * as React from "react"
import TableView from "./TableView"
import CsvTransformer from "../../models/renderers/CsvTransformer"
import { observable } from "mobx"
import { observer } from "mobx-react"
import ButtonLoader from "../ButtonLoader"
import { toast } from "react-toastify"

type TableViewExportButtonProps<T> = {
  tableViewRef: React.RefObject<TableView>
  transformerClass: () => CsvTransformer<T>
}

@observer
export default class TableViewExportButton<T> extends React.Component<TableViewExportButtonProps<T>> {
  @observable private exporting = false

  private export = async () => {
    if (this.props.tableViewRef.current) {
      const transformer = this.props.transformerClass()

      this.exporting = true
      this.props.tableViewRef.current.fetchAll(
        (items) => {
          transformer.addRows(items)
        }, error => {
          this.exporting = false
          toast.error(`Error generating CSV: ${error}`)
        }, () => {
          const blob = new Blob([transformer.toString()], {
            type: 'text/csv;charset=utf-8;'
          })

          if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, transformer.filename)
          } else {
            const blobUrl = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.setAttribute('href', blobUrl)
            link.setAttribute('download', transformer.filename)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            link.remove()

            // automatically clean up the object url after some time
            setTimeout(() => URL.revokeObjectURL(blobUrl), 1000 * 60 * 5)
          }

          this.exporting = false
        }
      )
    }
  }

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <ButtonLoader
      loading={this.exporting}
      type={'button'}
      color={'secondary'}
      onClick={this.export}
    ><i className="fa fa-download"/> {this.exporting ? 'Generating CSV' : 'Export CSV'}</ButtonLoader>
  }
}
