import * as React from "react"
import { observer } from "mobx-react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import AddPaymentMethodForm from "./AddPaymentMethodForm"
import PaymentMethod from "../models/PaymentMethod"

type AddPaymentMethodModalProps = {
  isOpen: boolean
  toggle: () => void
  onClosed: () => void
  onSaved: (paymentMethod: PaymentMethod) => void
  memberId?: number
  chapterId?: number
}

@observer
export default class AddPaymentMethodModal extends React.Component<AddPaymentMethodModalProps> {
  private toggle = () => {
    this.props.toggle()
  }

  render () {
    return <>
      <Modal isOpen={this.props.isOpen} toggle={this.toggle} onClosed={this.props.onClosed} size="lg">
        <ModalHeader toggle={this.toggle}>
          Add Payment Method
        </ModalHeader>
        <ModalBody>
          <AddPaymentMethodForm
            memberId={this.props.memberId}
            chapterId={this.props.chapterId}
            onSaved={paymentMethod => {
              this.props.onSaved(paymentMethod)
            }}
            onCancel={() => this.toggle()}/>
        </ModalBody>
      </Modal>
    </>
  }
}