import { observer } from "mobx-react"
import React, { ChangeEvent } from "react"
import BaseFilterComponent from './BaseFilterComponent'
import { SelectOption } from '../../inputs/SelectInput'
import { computed } from 'mobx'
import { SelectFilterState } from './SelectFilter'
import _ from 'lodash';

export type Props = {
  label: string
  state: SelectFilterState
  options: SelectOption[]
  onChanged: (value: string) => void
}

@observer
export default class SelectFilterComponent extends BaseFilterComponent<Props> {
  @computed
  private get selectedOption () {
    return _.find(this.props.options, o => o.value === this.props.state.value)
  }

  private onChanged = (ev: ChangeEvent<HTMLSelectElement>) => {
    this.props.onChanged(ev.target.value)
    this.props.eventBus.dispatch('changed')
  }

  render () {
    return <div className="form-group">
      {this.props.label && <label>{this.props.label}</label>}
      <select
        className="form-control"
        onChange={this.onChanged}
        value={this.selectedOption ? String(this.selectedOption.value) : ''}>
        <option value="">(choose one)</option>
        {
          this.props.options.map(o => <option key={o.value} value={String(o.value)}>{o.label}</option>)
        }
      </select>
    </div>
  }
}