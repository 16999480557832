import BaseView from "./BaseView"
import { observer } from "mobx-react"
import * as React from "react"
import BackLink from "../components/BackLink"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { observable } from "mobx"
import { BarLoader } from "react-spinners"
import { LinkContainer } from "react-router-bootstrap"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import Util, { safeNull } from "../common/Util"
import { Redirect } from "react-router-dom"
import AuthStore from "../stores/AuthStore"
import AppStateStore from "../stores/AppStateStore"
import ChapterTransferRequest from '../models/ChapterTransferRequest'
import { ApproveChapterTransferApplicationModal } from '../components/ApproveChapterTransferApplicationModal';
import { createBrowserHistory } from 'history'

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

@observer
export default class ChapterTransferRequestDetailView extends BaseView<Props> {
  @observable private chapterTransferRequest: ChapterTransferRequest
  @observable private error: string
  @observable private redirect: string
  @observable private confirmed = false

  @observable private isApproveModalVisible = false
  @observable private isDeclineModalVisible = false

  private history = createBrowserHistory()

  componentDidMount (): void {
    super.componentDidMount()

    ApiClient.query(`
chapterTransferRequest {
  *

  member {
    id
    fullName
  }
  
  oldCategory {
    id
    name
  }
  
  oldChapter {
    id
    name
  }
  
  newChapter {
    id
    name
  }
  
  replacementGuest {
    id
    fullName
    phone
    email
    companyName
  }    
    `, {
      where: [{ id: this.props.match.params.id }]
    }).then(response => this.chapterTransferRequest = new ChapterTransferRequest().init(response.data.chapterTransferRequest))
      .catch(error => {
        this.error = error.response.status === 404 ? 'Chapter transfer request not found' : 'There was an error loading the chapter transfer request'
      })
  }

  private declineApplication = () => {
    this.isDeclineModalVisible = false

    ApiClient.getInstance().post(route(ApiRoutes.chapterTransferRequests.deny, { id: this.chapterTransferRequest.id }))
      .then(response => this.redirect = route(Routes.system.applications.index, { tab: 'chapter-transfer' }))
      .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
  }

  private approve = () => {
    if (!this.confirmed) {
      AppStateStore.showAlertModal('Error', 'You must read the conditions and agree before approving')
      return;
    }

    this.isApproveModalVisible = true
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>Chapter Transfer Request</h1>
        {this.chapterTransferRequest && <ul className="content-header-actions">
          <li>
            <LinkContainer to={route(Routes.system.members.show, { id: this.chapterTransferRequest.member.id })}>
              <Button color="primary" onClick={() => {
              }}>View Member Profile</Button>
            </LinkContainer>
          </li>
        </ul>}
      </>
    )
  }

  renderContentBody (): React.ReactNode | null {
    return this.redirect
      ? <Redirect to={this.redirect}/>
      : this.error
        ? <div className="alert alert-danger">{this.error}</div>
        : this.chapterTransferRequest ? this.renderChapterTransferRequest(this.chapterTransferRequest) : <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
  }

  private renderChapterTransferRequest = (chapterTransferRequest: ChapterTransferRequest) => {
    return <div className="application-detail">
      {!chapterTransferRequest.isProcessed
        ? <>
          <div className="row mb-4">
            <div className="col-md-3 col-sm-12">
              <Button
                block
                onClick={() => this.history.goBack()}
                color="secondary">
                Cancel
              </Button>
            </div>
            <div className="col-md-3 col-sm-12">
            </div>
            <div className="col-md-3 col-sm-12">
              <Button
                block
                onClick={() => this.isDeclineModalVisible = true}
                color="danger">
                Decline Application
              </Button>
            </div>
            <div className="col-md-3 col-sm-12">
              <Button
                block
                onClick={() => this.approve()}
                color="success">
                Approve Application
              </Button>
            </div>
          </div>
        </>
        : null
      }
      <SectionHeader number={1} title="Member Info"/>

      <div className="form-row">
        <div className="col-sm-12 col-md-6">
          <label>Name</label>
          {safeNull(() => chapterTransferRequest.member.fullName) || '---'}
        </div>
        <div className="col-sm-12 col-md-6">
          <label>Category</label>
          {safeNull(() => chapterTransferRequest.oldCategory.name) || '---'}
        </div>
      </div>


      <SectionHeader number={2} title="Chapter Transfer"/>

      <div className="form-row">
        <div className="col-sm-12 col-md-6">
          <label>Old Chapter</label>
          {safeNull(() => chapterTransferRequest.oldChapter.name) || '---'}
        </div>
        <div className="col-sm-12 col-md-6">
          <label>New Chapter</label>
          {safeNull(() => chapterTransferRequest.newChapter.name) || '---'}
        </div>
        <div className="col-12">
          <label>Transfer Reason</label>
          {chapterTransferRequest.transferReason}
        </div>
      </div>

      <SectionHeader number={3} title="Replacement Guest"/>

      {
        chapterTransferRequest.replacementGuest
          ? <>
            <div className="form-row">
              <div className="col-sm-12 col-md-6">
                <label>Name</label>
                {safeNull(() => chapterTransferRequest.replacementGuest.fullName) || '---'}
              </div>
              <div className="col-sm-12 col-md-6">
                <label>Company Name</label>
                {safeNull(() => chapterTransferRequest.replacementGuest.companyName) || '---'}
              </div>
              <div className="col-sm-12 col-md-6">
                <label>Contact Phone</label>
                {safeNull(() => chapterTransferRequest.replacementGuest.phone) || '---'}
              </div>
              <div className="col-sm-12 col-md-6">
                <label>Contact Email</label>
                {
                  chapterTransferRequest.replacementGuest.email
                    ? <a href={`mailto:${chapterTransferRequest.replacementGuest.email}`}>{chapterTransferRequest.replacementGuest.email}</a>
                    : '---'
                }
              </div>
            </div>
          </>
          : <p>No replacement guest provided</p>
      }

      <SectionHeader number={4} title="Acceptance and Signature"/>

      <div className="application-subheader">Application Agreement and Signature</div>

      <div>
        <img src={`${chapterTransferRequest.applicationSignatureImageUrl}?token=${AuthStore.generateTempAuthToken()}`}/>
      </div>

      <hr/>

      <div>
        <p>
          <b>You must affirm the following before approving this chapter transfer:</b>
          <ul>
            <li>Member has no outstanding debt to current chapter</li>
            <li>Member has met the attendance requirement of the current chapter</li>
            <li>Member is in good standing with the current chapter</li>
            <li>Member has made a concerted effort to find a chapter replacement (or departing chapter prefers to fill position).</li>
          </ul>
        </p>
        <div className="form-group">
          <div className="form-check form-checkbox">
            <label>
              <input type="checkbox"
                     className="form-check-input"
                     name="confirm"
                     checked={this.confirmed}
                     onChange={ev => this.confirmed = ev.target.checked}
              />
              <span className="label-text">I affirm that I have read and understood the conditions above</span>
            </label>
          </div>
        </div>
      </div>

      {!chapterTransferRequest.isProcessed
        ? <>
          <div className="row mt-4">
            <div className="col-md-3 col-sm-12">
              <Button
                block
                onClick={() => this.history.goBack()}
                color="secondary">
                Cancel
              </Button>
            </div>
            <div className="col-md-3 col-sm-12">
            </div>
            <div className="col-md-3 col-sm-12">
              <Button
                block
                onClick={() => this.isDeclineModalVisible = true}
                color="danger">
                Decline Application
              </Button>
            </div>
            <div className="col-md-3 col-sm-12">
              <Button
                block
                onClick={() => this.approve()}
                color="success">
                Approve Application
              </Button>
            </div>
          </div>
        </>
        : null
      }

      <ApproveChapterTransferApplicationModal
        isOpen={this.isApproveModalVisible}
        toggle={() => this.isApproveModalVisible = !this.isApproveModalVisible}
        onCancel={() => this.isApproveModalVisible = false}
        onSaved={memberId => this.redirect = route(Routes.system.applications.index, { tab: 'chapter-transfer' })}
        chapterTransferRequest={chapterTransferRequest}/>

      <Modal
        isOpen={this.isDeclineModalVisible}
        size="md"
      >
        <ModalHeader
          toggle={() => this.isDeclineModalVisible = false}
        >
          Decline Application
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you want to decline this application? The applicant will be notified and no payment will be collected.</p>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => this.isDeclineModalVisible = false}
            color="secondary">
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={() => this.declineApplication()}
          >
            Decline Application
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  }
}

@observer
class SectionHeader extends React.Component
  <{
    number: number,
    title: string,
  }> {

  render (): React.ReactNode {
    return <div className="application-section-header">
      <div className="application-section-number">{this.props.number}</div>
      <div className="application-section-title">{this.props.title}</div>
    </div>
  }
}
