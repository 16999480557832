import * as React from "react"
import { QueryWhereClause } from "../api/ApiClient"
import { observer } from "mobx-react"
import { computed, observable } from "mobx"
import { Button } from "reactstrap"
import Config from "../common/Config"
import User from "../models/User"
import { TableViewFilters } from "./table-view/TableViewFilters"
import TableView, { TableViewColumn } from "./table-view/TableView"
import { TableViewPagination } from "./table-view/TableViewPagination"
import { ApiTableViewAdapter } from "./table-view/ApiTableViewAdapter"

export enum UserListColumn {
  Name = 'name',
  Username = 'username',
  Email = 'email',
  Status = 'status',
  Actions = 'actions',
}

type Props = {
  hideActions: boolean
  onChoose: (user: User) => void
  filters?: SearchableUserListFilterType
  style: {}
  columns: UserListColumn[]
  actionsComponent: (row: SearchableUserListRowType, onChoose: (user: User) => void) => React.ReactNode
}

export type SearchableUserListFilterType = {
  showInactive?: boolean
}

@observer
export default class SearchableUserList extends React.Component<Props> {
  static defaultProps = {
    hideActions: false,
    onChoose: () => {
    },
    style: {},
    columns: [
      UserListColumn.Name,
      UserListColumn.Username,
      UserListColumn.Email,
      UserListColumn.Status,
      UserListColumn.Actions,
    ],
    hideColumns: [],
    actionsComponent: (row: SearchableUserListRowType, onChoose: (user: User) => void) => <ActionsComponent row={row} onChoose={onChoose}/>
  }

  @computed get visibleColumns (): TableViewColumn[] {
    return this.props.columns.filter(c => c !== UserListColumn.Actions || !this.props.hideActions).map(c => this.availableColumns[c])
  }

  @observable private numberOfPages: number = 0
  @observable private currentPage: number = 1
  @observable private filters = {}
  private tableViewRef = React.createRef<TableView>()

  private setPage = (page: number) => {
    this.currentPage = page
  }

  private usersAdapter = new ApiTableViewAdapter(() => {
    const where: QueryWhereClause[] = []

    if (!this.props.filters || this.props.filters.showInactive) {
      where.push({ _scope: 'active' })
    }

    return {
      query: `
      users {
        *
      }
    `,
      where: where,
    }
  }, 'users', User, {
    onFetched: result => {
      this.numberOfPages = Math.ceil(result.total / Config.TABLE_VIEW_PAGE_SIZE)
      if (this.currentPage < 1) {
        this.currentPage = 1
      }
      if (this.currentPage > this.numberOfPages) {
        this.currentPage = this.numberOfPages
      }
    }
  })

  private availableColumns: { [name: string]: TableViewColumn } = {
    [UserListColumn.Name]: {
      title: 'User',
      accessor: 'name',
      sortable: true,
    },
    [UserListColumn.Username]: {
      title: 'Username',
      accessor: 'username',
      sortable: true,
    },
    [UserListColumn.Email]: {
      title: 'Email',
      accessor: 'email',
      sortable: true,
    },
    [UserListColumn.Status]: {
      title: 'Status',
      accessor: 'status',
      sortable: true,
    },
    [UserListColumn.Actions]: {
      title: 'Actions',
      headerClassName: 'justify-content-center',
      renderItem: (value: any, item: User) => this.props.actionsComponent({ original: item }, this.onChoose),
    },
  }

  private onChoose = (user: User) => {
    this.props.onChoose(user)
  }

  render (): React.ReactNode {
    return <>
      <div>
        <TableViewFilters
          initialState={{ searchText: '' }}
          searchLabel="Find A User"
          onChanged={filters => this.filters = { search: filters.search, filters: filters.filters.map(f => f.getWhereClause()) }}
        />
      </div>
      <TableView
        hideItemCount={true}
        ref={this.tableViewRef}
        adapter={this.usersAdapter}
        columns={this.visibleColumns}
        pagination={{
          offset: Math.max((this.currentPage - 1), 0) * Config.TABLE_VIEW_PAGE_SIZE,
          limit: Config.TABLE_VIEW_PAGE_SIZE,
        }}
        filters={this.filters}
      />
      {
        this.numberOfPages > 1
          ? <TableViewPagination numberOfPages={this.numberOfPages} currentPage={this.currentPage} setPage={page => this.setPage(page)}/>
          : null
      }
    </>
  }
}

export type SearchableUserListRowType = {
  original: User
}

type ActionsComponentProps = {
  row: SearchableUserListRowType
  onChoose: (user: User) => void
}

class ActionsComponent extends React.Component<ActionsComponentProps> {
  static defaultProps = {
    onChoose: () => {
    }
  }

  render (): React.ReactNode {
    return <div className="text-right">
      <Button
        type="button"
        color="primary"
        size="sm"
        onClick={() => this.props.onChoose(this.props.row.original)}
      >choose</Button>
    </div>
  }
}