import { observer } from "mobx-react"
import * as React from "react"
import { observable } from "mobx"
import { createLazyResource, formatNumber, modelToCamelCase } from "../../common/Util"
import classNames from "classnames"
import LazyResourcePanel from "../LazyResourcePanel"
import pluralize from "pluralize"
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import * as _ from "lodash"
import { AxiosPromise } from "axios"

export type TipData = {
  totalCount: number
  totalValue?: number
}

@observer
export default class TipsWidget extends React.Component<{
  type: 'Passed' | 'Received',
  showValue: boolean,
  period: string,
  load: (type: string, period: string) => AxiosPromise,
  onPeriodChange: (period: string) => void,
}> {
  static defaultProps = {
    showValue: true
  }

  @observable private tipData = createLazyResource<TipData>(() => {
    return this.props.load(this.props.type, this.props.period)
  }, response => modelToCamelCase(response.data.tip_data) as TipData)

  @observable private isOpen = false

  private periodNames = {
    month: 'This Month',
    lastMonth: 'Last Month',
    year: 'This Year',
    lastYear: 'Last Year',
    today: 'Today',
  }

  private toggle = () => {
    this.isOpen = !this.isOpen
  }

  componentDidMount (): void {
    this.tipData.refresh().then(() => {
    })
  }

  componentDidUpdate (prevProps: Readonly<{ type: "Passed" | "Received"; showValue: boolean; period: string; load: (type: string, period: string) => AxiosPromise; onPeriodChange: (period: string) => void }>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.period !== this.props.period) {
      this.tipData.invalidate()
    }
  }

  render (): React.ReactNode {
    const { type } = this.props

    return <div className="card">
      <div className="card-body">
        <div className="d-flex">
          <div className="mr-3">
            <div className={classNames(['widget-icon', type === 'Passed' ? 'bg-primary' : 'bg-success'])}>
              <i className={classNames(['fa', type === 'Passed' ? 'fa-user' : 'fa-money'])}/>
            </div>
          </div>
          <div className="flex-fill pt-3">
            <LazyResourcePanel resource={this.tipData}>
              {tipData => <>
                <h5><span className="text-primary">{tipData.totalCount}</span> {pluralize('Tip', tipData.totalCount)} {type}</h5>
                {this.props.showValue && tipData.totalValue && tipData.totalValue > 0 && <div><i className="fa fa-money text-success"/> ${formatNumber(tipData.totalValue, 2)}</div>}
              </>}
            </LazyResourcePanel>
          </div>
        </div>
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}>
          <ButtonDropdown isOpen={this.isOpen} toggle={this.toggle}>
            <DropdownToggle outline caret size="sm" color="secondary">{this.periodNames[this.props.period]}</DropdownToggle>
            <DropdownMenu>
              {_.map(this.periodNames, (p, k) => <DropdownItem key={k} onClick={() => {
                this.props.onPeriodChange(k)
              }}>{p}</DropdownItem>)}
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      </div>
    </div>
  }
}
