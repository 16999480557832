import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Member from "./Member"
import Chapter from "./Chapter"
import Category from "./Category"
import ExtendedNetworkMember from './ExtendedNetworkMember'

export default class Tip extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  sourceUserId: number
  sourceChapterId: number
  sourceMemberId: number
  targetChapterId: number
  targetMemberId: number | null
  targetCategoryId: number
  datePassed: Moment
  tipType: string
  contactName: string
  contactCompany: string
  contactPhone: string
  contactEmail: string
  tipDetails: string
  status: string
  value: number
  isPrivate: boolean
  socialMediaLink: string | null

  sourceChapter: Chapter
  sourceMember: Member
  targetChapter: Chapter
  targetMember: Member | null
  targetCategory: Category
  extendedNetworkMember: ExtendedNetworkMember | null

  setSourceChapter (c?: {}) {
    return c ? new Chapter(c) : null
  }

  setSourceMember (m?: {}) {
    return m ? new Member(m) : null
  }

  setTargetChapter (c?: {}) {
    return c ? new Chapter(c) : null
  }

  setTargetMember (m?: {}) {
    return m ? new Member(m) : null
  }

  setTargetCategory (c?: {}) {
    return c ? new Category(c) : null
  }

  setExtendedNetworkMember (c?: {}) {
    return c ? new ExtendedNetworkMember().init(c) : null
  }

  getCasts () {
    return {
      createdAt: 'datetime',
      updatedAt: 'datetime',
      datePassed: 'datetime',
      isPrivate: 'boolean',
    }
  }
}
