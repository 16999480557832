import * as React from "react"
import { publicPath } from "../common/Util"
import Config from "../common/Config"

type State = {
  error: any
  hasError: boolean
}

export default class ErrorBoundary extends React.Component<any, State> {
  constructor (props: any) {
    super(props)
    this.state = { hasError: false, error: undefined }
  }

  static getDerivedStateFromError (error: any) {
    return { hasError: true, error: error }
  }

  componentDidCatch (error: any, info: any) {
  }

  render () {
    if (this.state.hasError) {
      return <>
        <div className="header-container">
          <div className="brand">
            <img className="brand-image" alt="LeTip Wired" src={publicPath('img/wired-logo.png')}/>
          </div>
          <div className="header-nav"/>
        </div>
        <div className="body-container">
          <div className="left-nav"/>
          <div className="content-container">
            <div className="content">
              <h1>Oops, something went wrong!</h1>
              <div className="alert alert-danger">
                We're sorry, an unexpected error has occurred. Please refresh the page.
                <br/><br/>
                If you continue to have problems, return to the home page by <a href="/">clicking here</a>.
              </div>
            </div>
            <div className="content-footer">
              Version {Config.VERSION || '???'}{Config.BUILD_NUMBER ? `-${Config.BUILD_NUMBER}` : null} {Config.ENV !== 'production' ? `(${Config.ENV})` : null} {Config.NODE_ENV !== 'production' ? `[${Config.NODE_ENV}]` : null}
            </div>
          </div>
        </div>
      </>
    }

    return this.props.children
  }
}