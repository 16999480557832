import BaseFilter from "./BaseFilter"
import { escapeLike } from "../../../common/Util"
import TextFilterComponent from './TextFilterComponent'
import { autorun, computed, observable } from 'mobx'
import React from 'react'
import EventBus from '../../../common/EventBus'

export enum TextFilterTargetType {
  Field,
  Scope,
}

export enum TextFilterOperator {
  StartsWith,
  Contains,
}

export type TextFilterState = {
  value: string
}

export default class TextFilter extends BaseFilter<TextFilterState> {
  @observable value: string = ''

  @computed get state (): TextFilterState {
    return {
      value: this.value,
    }
  }

  @computed get isActive (): boolean {
    return this.value.trim().length > 0
  }

  @computed get description (): string {
    return this.value.trim().length
      ? `${this.label} is ${this.value}`
      : ''
  }

  constructor (public id: string, public targetType: TextFilterTargetType, public target: string, public label: string, public operator: TextFilterOperator = TextFilterOperator.StartsWith ) {
    super()
  }

  getWhereClause = () => {
    return this.value.trim().length
      ? this.targetType === TextFilterTargetType.Scope
        ? [{
          _scope: this.target,
          value: this.value,
        }]
        : [{
          id: this.target,
          op: 'like',
          value: this.operator === TextFilterOperator.StartsWith ? `${escapeLike(this.value)}%` : `%${escapeLike(this.value)}%`,
        }]
      : []
  }

  restoreState = (state: TextFilterState) => {
    this.value = state.value
  }

  private onChanged = (value: string) => {
    this.value = value
  }

  render = (eventBus: EventBus) => {
    return React.createElement(TextFilterComponent, {
      label: this.label,
      state: this.state,
      onChanged: this.onChanged,
      eventBus: eventBus,
    })
  }
}