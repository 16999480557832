import * as React from "react"
import { RouteComponentProps, withRouter } from "react-router"
import NavMenuLink from "./NavMenuLink"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { observer } from "mobx-react"
import RequirePermission, { can, Permission } from "./RequirePermission"
import RequireMember from "./RequireMember"
import RequireChapter from "./RequireChapter"
import AppStateStore from "../stores/AppStateStore"
import Config from '../common/Config'
import AuthUser, { UserRole } from "../models/AuthUser"


@observer
class LeftNav extends React.Component<RouteComponentProps<{}>> {
  public render () {
    return (
      <div className="left-nav">
        <ul className="menu-nav">
          {
            can(Permission.SeeDashboard)
              ? <>
                <li className="menu-item">
                  <NavMenuLink to={route(Routes.system.dashboard)} linkText="Dashboard" iconClassName="flaticon-dashboard"/>
                </li>
              </>
              : null
          }
          {can(Permission.SeeAdminTools) && <>
            <li className="menu-section-title">
              <h4>ADMIN</h4>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.members.index)} linkText="Members" iconClassName="flaticon-users"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.pendingMembers)} linkText="Pending Members" iconClassName="flaticon-user-settings"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.chapters.index)} linkText="Chapters" iconClassName="flaticon-network"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.categories.index)} linkText="Categories" iconClassName="flaticon-list-3"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.applications.index)} iconClassName="flaticon-suitcase" linkText="Applications"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.guests.index)} iconClassName="flaticon-users" linkText="Guests"/>
            </li>
            {can(Permission.SeeFulfillmentList) && <li className="menu-item">
              <NavMenuLink to={route(Routes.system.fulfillment)} linkText="Fulfillment" iconClassName="flaticon-truck"/>
            </li>}
            {/*<li className="menu-item">*/}
            {/*  <NavMenuLink to={route(Routes.system.documentLibrary)} linkText="Document Library" iconClassName="flaticon-file-2"/>*/}
            {/*</li>*/}
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.calendar)} linkText="Calendar" iconClassName="flaticon-calendar"/>
            </li>
            {can(Permission.SeeTransactionsList) && <li className="menu-item">
              <NavMenuLink to={route(Routes.system.transactions)} linkText="Transactions" iconClassName="flaticon-coins"/>
            </li>}
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.invoices)} linkText="Invoices" iconClassName="flaticon-browser"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.renewals)} linkText="Renewals" iconClassName="flaticon-bag"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.reporting)} linkText="Reports" iconClassName="flaticon-graph"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.notices.index)} linkText="Notices" iconClassName="flaticon-warning"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.sentNotifications.index)} linkText="Notifications" iconClassName="flaticon-upload-1"/>
            </li>
            {/*<li className="menu-item">*/}
            {/*  <NavMenuLink to={route(Routes.system.boardDocuments.index)} linkText="Board Documents" iconClassName="flaticon-background"/>*/}
            {/*</li>*/}
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.ntsEvents.index)} linkText="NTS Events" iconClassName="flaticon-presentation-1"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.boardTraining.index)} linkText="Board Training Events" iconClassName="flaticon-presentation-1"/>
            </li>
            {can(Permission.SeeSuperAdminTools) && <>
              <li className="menu-item">
                <NavMenuLink to={route(Routes.system.sponsorshipCredits)} linkText="Sponsorship Credits" iconClassName="flaticon-gift"/>
              </li>
            </>}
          </>}

          {can(Permission.SeeAreaRepTools) && !can(Permission.SeeAdminTools) && <>
            <li className="menu-section-title">
              <h4>ADMIN</h4>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.members.index)} linkText="Members" iconClassName="flaticon-users"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.chapters.index)} linkText="Chapters" iconClassName="flaticon-network"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.applications.index)} iconClassName="flaticon-suitcase" linkText="Applications"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.guests.index)} iconClassName="flaticon-users" linkText="Guests"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.calendar)} linkText="Calendar" iconClassName="flaticon-calendar"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.renewals)} linkText="Renewals" iconClassName="flaticon-bag"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.system.reporting)} linkText="Reports" iconClassName="flaticon-graph"/>
            </li>
          </>}

          <li className="menu-section-title">
            <h4>MY ACCOUNT</h4>
          </li>
          <RequireMember>{() => <>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.member.dashboard)} iconClassName="flaticon-dashboard" linkText="Dashboard"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.member.tips)} iconClassName="fa fa-money" linkText="Tips"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.member.profile)} iconClassName="flaticon-interface-3" linkText="My Profiles"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.member.billing)} iconClassName="flaticon-coins" linkText="Billing"/>
            </li>
            <li className="menu-item">
              <NavMenuLink
                to={route(Routes.member.messages)}
                iconClassName="flaticon-email"
                linkText="Messages"
                showBadge={AppStateStore.badgeCounts.unreadMessageCount > 0}
                badgeCount={AppStateStore.badgeCounts.unreadMessageCount}
              />
            </li>
            {
              Config.DISABLE_EXTENDED_NETWORK_MENU
                ? null
                : <li className="menu-item">
                  <NavMenuLink to={route(Routes.member.extendedNetwork)} iconClassName="flaticon-globe" linkText="My Extended Network"/>
                </li>
            }

          </>}</RequireMember>

          <li className="menu-item">
            <NavMenuLink to={route(Routes.member.settings)} iconClassName="flaticon-settings-1" linkText="Settings and Notifications"/>
          </li>

          <RequireChapter>{() => <>
            <li className="menu-section-title">
              <h4>MY CHAPTER</h4>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.chapter.index)} iconClassName="flaticon-network" linkText="My Chapter"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.chapter.tips)} iconClassName="flaticon-customer" linkText="Chapter Tips"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.chapter.calendar)} linkText="Calendar" iconClassName="flaticon-calendar"/>
            </li>
            <li className="menu-item">
              <NavMenuLink to={route(Routes.chapter.guests)} linkText="Guests" iconClassName="flaticon-users"/>
            </li>
          </>}</RequireChapter>

          <li className="menu-section-title">
            <h4>LETIP NETWORK SEARCH</h4>
          </li>
          <li className="menu-item">
            <NavMenuLink to={Routes.network.members} linkText="Members" iconClassName="flaticon-users"/>
          </li>
          <li className="menu-item">
            <NavMenuLink to={Routes.network.chapters} linkText="Chapters" iconClassName="flaticon-network"/>
          </li>
          <li className="menu-item">
            <NavMenuLink to={Routes.network.categories} linkText="Categories" iconClassName="flaticon-list-3"/>
          </li>
          <li className="menu-item">
            <NavMenuLink to={route(Routes.network.documentLibrary)} linkText="Document Library" iconClassName="flaticon-tool"/>
          </li>
          <li className="menu-item">
            <NavMenuLink to={route(Routes.network.videoLibrary)} linkText="Video Library" iconClassName="flaticon-squares-3"/>
          </li>
          <li className="menu-item">
            <NavMenuLink to={route(Routes.network.support)} linkText="Support" iconClassName="flaticon-questions-circular-button"/>
          </li>
          <li className="menu-item">
            <a href="http://www.letipwebstore.com/" target="_blank"><i className="flaticon-cart"/> Webstore</a>
          </li>
          <li className="menu-item">
            <a href="https://letip.com/letip-webinars/" target="_blank"><i className="flaticon-imac"/> Upcoming Webinars</a>
          </li>

          {can(Permission.SeeBoardMemberTools) && <>
            <li className="menu-section-title">
              <h4>BOARD MEMBER TOOLS</h4>
            </li>
            {can(Permission.ManageAttendance) &&
              <li className="menu-item">
                <NavMenuLink to={route(Routes.board.attendance)} iconClassName="flaticon-calendar-3" linkText="Attendance"/>
              </li>}
            {can(Permission.ManageBoardApplications) &&
              <li className="menu-item">
                <NavMenuLink to={route(Routes.system.applications.index)} iconClassName="flaticon-suitcase" linkText="Applications"/>
              </li>}
            {can(Permission.ViewBoardReports) &&
              <li className="menu-item">
                <NavMenuLink to={route(Routes.board.reports)} iconClassName="flaticon-graph" linkText="Reporting"/>
              </li>}
            {can(Permission.ManageProgram) &&
              <li className="menu-item">
                <NavMenuLink to={route(Routes.board.program)} iconClassName="flaticon-calendar-with-a-clock-time-tools" linkText="Manage Program"/>
              </li>}
            {can(Permission.ManageChapterDocumentLibrary) &&
              <li className="menu-item">
                <NavMenuLink to={route(Routes.board.documentLibrary)} iconClassName="flaticon-tool" linkText="Chapter Documents"/>
              </li>}
            {can(Permission.ManageChapter) &&
              <li className="menu-item">
                <NavMenuLink to={route(Routes.board.boardPositions)} iconClassName="flaticon-customer" linkText="Board Positions"/>
              </li>}

             {can(Permission.ManageChapter) &&
              <li className="menu-item">
                <NavMenuLink to={route(Routes.system.emailTemplates)} linkText="Email Templates" iconClassName="flaticon-mail-1"/>
              </li>
             }
          </>
          }

          {/* <RequireMember>{() =>
              can(Permission.SeeAdminTools)
                ? null
                :  <>
                <li className="menu-section-title">
                  <h4>SETTINGS</h4>
                </li>
                <li className="menu-item">
                    <NavMenuLink to={route(Routes.member.emailTemplates)} linkText="Email Templates" iconClassName="flaticon-mail-1"/>
                </li>
                </>
            }
          </RequireMember> */}

          <RequirePermission permission={Permission.ViewSystemSettings}>
            {() => <>
              <li className="menu-section-title">
                <h4>SETTINGS</h4>
              </li>
              <RequirePermission permission={Permission.ViewUsers}>
                {() => <li className="menu-item">
                  <NavMenuLink to={route(Routes.system.users.index)} linkText="Users" iconClassName="flaticon-lock"/>
                </li>}
              </RequirePermission>
              <li className="menu-item">
                <NavMenuLink to={route(Routes.system.regions.index)} linkText="Regions" iconClassName="flaticon-placeholder"/>
              </li>
              <li className="menu-item">
                <NavMenuLink to={route(Routes.system.areas.index)} linkText="Areas" iconClassName="flaticon-pin"/>
              </li>
              <li className="menu-item">
                <NavMenuLink to={route(Routes.system.supportAreas.index)} linkText="Support Areas" iconClassName="flaticon-support"/>
              </li>
              <li className="menu-item">
                <NavMenuLink to={route(Routes.system.systemMessages)} linkText="System Messages" iconClassName="flaticon-mail-1"/>
              </li>
            </>}
          </RequirePermission>

          <RequirePermission permission={Permission.AccessDevTools}>
            {() => <>
              <li className="menu-section-title">
                <h4>DEV TOOLS</h4>
              </li>
              <li className="menu-item">
                <NavMenuLink to={route(Routes.devTools.renewalFeeCalculator)} linkText="Renewal Calculator" iconClassName="fa fa-calculator"/>
              </li>
            </>}
          </RequirePermission>
        </ul>
      </div>
    )
  }
}

export default withRouter(LeftNav)
