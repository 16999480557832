import CsvTransformer from "./CsvTransformer"
import { safeNull } from "../../common/Util"
import Member from "../Member"
import moment from "moment-timezone"
import User from "../User"

export default class UsersCsvTransformer extends CsvTransformer<User> {
  get filename () {
    return `users-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'id',
      valueTransformer: row => String(row.id),
    })

    this.addColumn({
      header: 'Name',
      valueTransformer: row => safeNull(() => row.name) || '',
    })

    this.addColumn({
      header: 'Username',
      valueTransformer: row => safeNull(() => row.username) || '',
    })

    this.addColumn({
      header: 'Email',
      valueTransformer: row => safeNull(() => row.email) || '',
    })
  }
}