import BaseView from "../BaseView"
import * as React from "react"
import BackLink from "../../components/BackLink"
import { observable } from "mobx"
import Chapter from "../../models/Chapter"
import ApiClient, { apiRoute, ApiRoutes } from "../../api/ApiClient"
import { BarLoader } from "react-spinners"
import { observer } from "mobx-react"
import Util, { safeNull } from "../../common/Util"
import { Button, Nav, NavItem, NavLink, TabContent } from "reactstrap"
import classNames from "classnames"
import LazyTabPane from "../../components/LazyTabPane"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { createBrowserHistory } from "history"
import NetworkChapterGeneral from "../../components/network/NetworkChapterGeneral"
import ChapterRoster from "../../components/chapter/ChapterRoster"
import SearchableMemberList, { MemberListColumn, SearchableMemberListContext } from "../../components/SearchableMemberList"
import RequireRole from "../../components/RequireRole"
import { UserRole } from "../../models/AuthUser"
import { LinkContainer } from "react-router-bootstrap"
import { can, Permission } from "../../components/RequirePermission"
import AuthStore from '../../stores/AuthStore'

const TABS = {
  general: 'general',
  roster: 'roster',
  board: 'board',
}

type Props = {
  match: {
    params: {
      chapterId: number
      tab?: string
    }
  }
}

@observer
export default class NetworkChapterDetailView extends BaseView<Props> {
  @observable loading = false
  @observable error?: string
  @observable private chapter?: Chapter

  componentDidMount (): void {
    super.componentDidMount()

    this.activeTab = this.props.match.params.tab || TABS.general

    this.loadChapter()
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any): void {
    if (prevProps.match.params.chapterId !== this.props.match.params.chapterId) {
      this.loadChapter()
    }
  }

  private loadChapter = () => {
    this.loading = true

    ApiClient.query(
      `
chapter {
  *
  
  area {
    *
    
    region {
      *
    }
  }
}
      `,
      {
        where: [{ id: this.props.match.params.chapterId }]
      }
    )
      .then(response => this.chapter = new Chapter().init(response.data.chapter))
      .catch(err => this.error = (err.response.status === 404 ? 'The chapter you are looking for does not exist!' : Util.extractErrorMessage(err.response)))
      .then(() => this.loading = false)
  }

  @observable private activeTab = TABS.general

  private history = createBrowserHistory()

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(route(Routes.network.chapter, { chapterId: this.props.match.params.chapterId, tab: this.activeTab }))
    }
  }

  renderContentHeader () {
    return (
      <>
        <BackLink/>
        <h1>Chapter Detail{this.chapter && ` for ${this.chapter.name}`}</h1>

        {
          this.chapter
          && can(Permission.AdministerChapter, { chapterId: this.chapter.id, regionId: safeNull(() => this.chapter!.area!.region.id) })
            ? <ul className="content-header-actions">
              <li>
                <LinkContainer to={route(Routes.system.chapters.show, { id: this.props.match.params.chapterId })}>
                  <Button color="primary" onClick={() => {
                  }}>Edit Chapter</Button>
                </LinkContainer>
              </li>
            </ul>
            : null
        }
      </>
    )
  }

  render () {
    return this.loading
      ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
      : this.error
        ? <div className="alert alert-danger">{this.error}</div>
        : this.chapter
          ? this.renderDetail(this.chapter!)
          : null
  }

  private renderDetail = (chapter: Chapter) => {
    return <>
      <div className="card">
        <div className="card-header">
          <Nav tabs className="card-header-tabs">
            <NavItem className={classNames({ active: this.activeTab === TABS.general })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.general)
                }}
              >General</NavLink>
            </NavItem>
            <NavItem className={classNames({ active: this.activeTab === TABS.roster })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.roster)
                }}
              >Roster</NavLink>
            </NavItem>
            <NavItem className={classNames({ active: this.activeTab === TABS.board })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.board)
                }}
              >Board/Chair</NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="card-body">
          <TabContent activeTab={this.activeTab}>
            <LazyTabPane tabId={TABS.general} activeTab={this.activeTab}>
              <NetworkChapterGeneral chapterId={this.props.match.params.chapterId}/>
            </LazyTabPane>
            <LazyTabPane tabId={TABS.roster} activeTab={this.activeTab}>
              <div className="text-right">
                <a
                  target="_blank"
                  href=""
                  className="btn btn-primary"
                  onClick={ev => {
                    ev.preventDefault()

                    const url = apiRoute(route(ApiRoutes.printChapterRoster, {chapterId: chapter.id}), {
                      token: AuthStore.generateTempAuthToken(),
                    })

                    const link = document.createElement('a')
                    link.setAttribute('target', '_blank')
                    link.setAttribute('href', url)
                    link.style.visibility = 'hidden'
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                  }}
                >Print Roster</a>
              </div>
              <SearchableMemberList
                tableClassName="small-text"
                filters={{
                  chapter: this.props.match.params.chapterId,
                  showInactive: false,
                }}
                linkToNetworkPage={true}
                hideActions={true}
                context={SearchableMemberListContext.Network}
                columns={[
                  MemberListColumn.Name,
                  MemberListColumn.Company,
                  MemberListColumn.Category,
                  MemberListColumn.JoinDate,
                  MemberListColumn.BadgeColor,
                  MemberListColumn.PhoneNumber,
                  MemberListColumn.EmailAddress,
                  MemberListColumn.Website,
                  MemberListColumn.Position,
                ]}
                defaultSort={{sortKey: 'lastName'}}
              />
            </LazyTabPane>
            <LazyTabPane tabId={TABS.board} activeTab={this.activeTab}>
              <ChapterRoster chapterId={this.props.match.params.chapterId}/>
            </LazyTabPane>
          </TabContent>
        </div>
      </div>
    </>
  }
}
