import BaseFilter from "./BaseFilter"
import { computed, observable } from 'mobx'
import React from 'react'
import EventBus from '../../../common/EventBus'
import { LoadOptionsMethod, SelectOption } from '../../inputs/SelectInput'
import AsyncSelectFilterComponent from './AsyncSelectFilterComponent'

export enum AsyncSelectFilterTargetType {
  Field,
  Scope,
}

export type AsyncSelectFilterState = {
  value: string
  label: string
}

export default class AsyncSelectFilter extends BaseFilter<AsyncSelectFilterState> {
  @observable selectedOption?: SelectOption

  @computed get state (): AsyncSelectFilterState {
    return this.selectedOption
      ? {
        value: this.selectedOption.value,
        label: this.selectedOption.label,
      }
      : {
        value: '',
        label: '',
      }
  }

  @computed get isActive (): boolean {
    return this.selectedOption !== undefined
  }

  @computed get description (): string {
    return this.selectedOption
      ? `${this.label} is ${this.selectedOption.label}`
      : ''
  }

  constructor (public id: string, public targetType: AsyncSelectFilterTargetType, public target: string, public label: string, private loadOptions: LoadOptionsMethod) {
    super()
  }

  getWhereClause = () => {
    return this.selectedOption
      ? this.targetType === AsyncSelectFilterTargetType.Scope
        ? [{
          _scope: this.target,
          value: this.selectedOption.value,
        }]
        : [{
          id: this.target,
          value: this.selectedOption.value,
        }]
      : []
  }

  restoreState = (state: AsyncSelectFilterState) => {
    if (state.value !== '') {
      this.selectedOption = {
        value: state.value,
        label: state.label,
      }
    }
  }

  private onChanged = (selectOption: SelectOption) => {
    this.selectedOption = selectOption || undefined
  }

  render = (eventBus: EventBus) => {
    return React.createElement(AsyncSelectFilterComponent, {
      label: this.label,
      state: this.state,
      onChanged: this.onChanged,
      loadOptions: this.loadOptions,
      eventBus: eventBus,
    })
  }
}