import CsvTransformer from "./CsvTransformer"
import { safeNull } from "../../common/Util"
import Member from "../Member"
import moment from "moment-timezone"

export default class PendingMembersCsvTransformer extends CsvTransformer<Member> {
  get filename () {
    return `pending-members-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'id',
      valueTransformer: row => String(row.id),
    })

    this.addColumn({
      header: 'Member',
      valueTransformer: row => row.fullName,
    })

    this.addColumn({
      header: 'Chapter',
      valueTransformer: row => safeNull(() => row.chapter!.name) || '',
    })

    this.addColumn({
      header: 'Category',
      valueTransformer: row => safeNull(() => row.category!.name) || '',
    })

    this.addColumn({
      header: 'Company',
      valueTransformer: row => safeNull(() => row.business!.name) || '',
    })

    this.addColumn({
      header: 'Application Status',
      valueTransformer: row => row.applicationStatus || '',
    })

    this.addColumn({
      header: 'Join Date',
      valueTransformer: row => safeNull(() => row.joinDate!.format('MM/DD/YYYY')),
    })

    this.addColumn({
      header: 'Position',
      valueTransformer: row => safeNull(() => row.primaryMemberPosition!.position.name) || '',
    })

    this.addColumn({
      header: 'Badge Color',
      valueTransformer: row => row.badgeColor,
    })

    this.addColumn({
      header: 'NTS Training',
      valueTransformer: row => row.ntsTrainingCompletedAt ? 'Yes' : 'No',
    })

    this.addColumn({
      header: 'Phone',
      valueTransformer: row => safeNull(() => row.phoneNumber) || '',
    })

    this.addColumn({
      header: 'Email',
      valueTransformer: row => safeNull(() => row.emailAddress) || '',
    })

    this.addColumn({
      header: '# Sponsored',
      valueTransformer: row => safeNull(() => row.numberOfSponsoredMembers) || '',
    })
  }
}
