import React from "react"
import { action, computed, observable } from "mobx"
import { observer } from "mobx-react"
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import classNames from 'classnames'
import { Editor } from '@tinymce/tinymce-react';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const createEditorState = (text: string) => {
  const contentState = stateFromHTML(text);
  return EditorState.createWithContent(contentState);
};

type Props = {
  text: string
  onChangeTemplate: (document: any) => void
  isSmsTemplate: boolean
}

@observer
export default class TextEditor extends React.Component<Props> {

  private header: string;
  private body: string;
  private footer: string;

  private editor: any = React.createRef();
  private editorRef: any = React.createRef();

  css = `
  .code {
      color: #f00;
  }
`

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.text != this.props.text) {
      if (!this.props.isSmsTemplate) {
        this.header = this.parseHeader("<", "<table", this.props.text);
        this.body = this.parseBody("<table", "</body>", this.props.text);
        this.footer = this.parseFooter("</body>", "</html>", this.props.text);
      }

      // const view = this.editor.current.view;
      // if (view) {
      //   EditorUtils.setHtml(view, this.props.text);
      // }

    }
  }


  parseHeader(beginString: string, endString: string, originalString: string): string {
    var beginIndex: number = originalString.indexOf(beginString);
    if (beginIndex === -1) {
      return "";
    }
    var beginStringLength: number = beginString.length;
    var substringBeginIndex: number = beginIndex
    var substringEndIndex: number = originalString.indexOf(endString, substringBeginIndex) - beginStringLength;
    if (substringEndIndex === -1) {
      return "";
    }
    return originalString.substring(substringBeginIndex, substringEndIndex);
  }

  parseBody(beginString: string, endString: string, originalString: string): string {
    var beginIndex: number = originalString.indexOf(beginString);
    if (beginIndex === -1) {
      return "";
    }
    var beginStringLength: number = beginString.length;
    var substringBeginIndex: number = beginIndex
    var substringEndIndex: number = originalString.indexOf(endString, substringBeginIndex);
    if (substringEndIndex === -1) {
      return "";
    }
    return originalString.substring(substringBeginIndex, substringEndIndex);
  }

  parseFooter(beginString: string, endString: string, originalString: string): string {
    var beginIndex: number = originalString.indexOf(beginString);
    if (beginIndex === -1) {
      return "";
    }
    var beginStringLength: number = beginString.length;
    var substringBeginIndex: number = beginIndex;
    var substringEndIndex: number = originalString.indexOf(endString, substringBeginIndex) + endString.length;
    if (substringEndIndex === -1) {
      return "";
    }
    return originalString.substring(substringBeginIndex, substringEndIndex);
  }

  onChangeTemplate = (event: string) => {
    //let template = event.html;
    let template = this.header + event + this.footer;

    if (!this.props.isSmsTemplate) {
      template = this.header + template + this.footer;
    }
    this.props.onChangeTemplate(template);
  }

  render(): React.ReactNode {

    if (!this.props.isSmsTemplate) {
      this.header = this.parseHeader("<", "<table", this.props.text);
      this.body = this.parseBody("<table", "</body>", this.props.text);
      this.footer = this.parseFooter("</body>", "</html>", this.props.text);
    }

    //this.body = this.props.text;
    return <>
      <div className={classNames("text-editor", "mt-4")}>
        {/* <Editor
        editorState={this.editorState}
        toolbar={{
          options: ['inline', 'blockType', 'list', 'link', 'image'],
        }}
        onEditorStateChange={this.onEditorStateChange}
      /> */

          <div>
            <Editor
              apiKey={'0ll0w8do1xmw30a7t0iqbd3o0ofojz2ldidne47ht8tvurmw'}
              onInit={(evt, editor) => (this.editorRef.current = editor)}
              initialValue={this.body}
              init={{
                min_height: 600,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
              }
              }
              onEditorChange={this.onChangeTemplate}
            />
          </div>

        }
        {
          // <div className="css" style={{ display: 'block', margin: '8px'}} dangerouslySetInnerHTML={{ __html: this.body }} contentEditable onInput={this.onChangeTemplate}></div>
        }
      </div>
    </>
  }
}


