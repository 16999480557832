import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import Util, { formatCurrency, joinStringsAnd, modelToCamelCase, modelToSnakeCase, publicPath, transformIf } from "../../common/Util"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import { route } from "../../routes/routes"
import BaseModel from "../../models/BaseModel"
import moment, { Moment } from "moment-timezone"
import { computed, observable } from "mobx"
import Config from "../../common/Config"
import { BarLoader } from "react-spinners"
import FormHelper from "../../forms/FormHelper"
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import { loadCategoryOptions } from "../../api/AsyncHelpers"
import CreditCardInput, { CardInfo } from "../../components/inputs/CreditCardInput"
import classNames from "classnames"
import FormError from "../../components/FormError"
import { SelectOption } from "../../components/inputs/SelectInput"
import TabContent from 'reactstrap/lib/TabContent';
import TabPane from 'reactstrap/lib/TabPane';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink } from "reactstrap"
import { AcceptJsClient, BankAccountDataType, loadAcceptJs } from "../../common/AuthNet"
import SignatureCanvas from 'react-signature-canvas'
import AppStateStore from "../../stores/AppStateStore"
import * as _ from "lodash"
import Chapter from "../../models/Chapter"
import { ApplicationType } from '../../models/Application'

type Props = {
  match: {
    params: {
      slug: number
    }
  }
}

class ApplicationData extends BaseModel {
  id: number
  createdAt: Moment
  status: string
  applicationType: ApplicationType
  targetChapter: Chapter
  sponsor: {
    fullName: string
    chapter: {
      name: string
    }
  }
  guest: {
    firstName: string
    lastName: string
    phone: string
    email: string
    companyName: string
    category: {
      id: number
      name: string
    }
  }

  getCasts () {
    return {
      createdAt: 'datetime',
    }
  }
}

type ChapterDueData = {
  code: string
  label: string
  amount: number
}

type PaymentPlanData = {
  initialAmount: number
  fee: number
  intervals: number[]
}

@observer
export default class EditApplicationView extends React.Component<Props> {
  @observable private applicationData: ApplicationData
  @observable private loading = false
  @observable private error?: string
  @observable private _membershipFee: number
  @observable private _renewalFee: number
  @observable private _chapterFees: ChapterDueData[] = []
  @observable private _taxRate: number = 0
  @observable private _paymentPlanData?: PaymentPlanData
  @observable private showConfirmNameModal = false
  @observable private _veteranDiscountPercent: number = 0
  @observable private shouldShowPaymentPlan: boolean = true

  private renewalSignatureRef = React.createRef<ApplicationSignature>()
  private applicationSignatureRef = React.createRef<ApplicationSignature>()

  @observable private formState = new FormState({
    membershipType: 'Personal',
    prefix: undefined,
    firstName: '',
    lastName: '',
    gender: undefined,
    email: '',
    phone: '',
    address: '',
    address2: '',
    country: 'United States',
    state: '',
    city: '',
    zipCode: '',
    categoryId: undefined,
    isBusinessAddressSame: false,
    business: {
      name: '',
      phone: '',
      fax: '',
      email: '',
      websiteUrl: '',
      address: '',
      address2: '',
      country: 'United States',
      state: '',
      city: '',
      zipCode: '',
    },
    payment: {
      paymentMethodType: 'CreditCard',
    },
    renewal: {
      renewalMethod: 'Automatic',
      paymentMethodType: 'Same',
    },
    acceptRenewalTerms: false,
    acceptAgreement: false,
    boardInterestOption: '',
    boardInterestText: '',
    usePaymentPlan: false,
    isVeteran: false,
  })
  @observable private formErrors = new ErrorBag()

  private paymentInfo: {cardInfo?: CardInfo, bankInfo: BankAccountDataType} = {
    cardInfo: undefined,
    bankInfo: {
      accountType: '',
      routingNumber: '',
      accountNumber: '',
      nameOnAccount: '',
    }
  }

  private renewalInfo: {cardInfo?: CardInfo, bankInfo: BankAccountDataType} = {
    cardInfo: undefined,
    bankInfo: {
      accountType: '',
      routingNumber: '',
      accountNumber: '',
      nameOnAccount: '',
    }
  }

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @computed
  private get totalDue () {
    let totalDue = (this._paymentPlanData && this.formState.get('usePaymentPlan'))
      ? this.membershipFee + this._paymentPlanData.fee
      : this.membershipFee

      if(this.formState.get('isVeteran') && this._veteranDiscountPercent > 0) {
        totalDue -= (this._membershipFee * (this._veteranDiscountPercent / 100))
      }

      return totalDue
  }

  @computed get membershipFee () {
    return Number(this._membershipFee)
  }

  @computed get renewalFee () {
    return Number(this._renewalFee)
  }

  @computed get taxRate () {
    return this._taxRate ? Number(this._taxRate) : 0
  }

  @computed
  private get initialChapterDues () {
    return _.find(this._chapterFees, f => f.code === 'Initial Chapter Dues')
  }

  @computed
  private get quarterlyChapterDues () {
    return _.find(this._chapterFees, f => f.code === 'Quarterly Chapter Dues')
  }

  @computed
  private get otherChapterFee () {
    return _.find(this._chapterFees, f => f.code === 'Other')
  }

  @computed
  private get paymentPlanTotalAmount () {
    return this._paymentPlanData ? this.membershipFee + this._paymentPlanData.fee : 0
  }

  @computed
  private get paymentPlanDescription () {
    if (this._paymentPlanData) {
      const intervalAmount = (this.membershipFee - this._paymentPlanData.initialAmount + this._paymentPlanData.fee) / this._paymentPlanData.intervals.length
      const intervalDescriptions = this._paymentPlanData.intervals.map(interval => `${formatCurrency(intervalAmount)} in ${interval} days`)
      return `I would like to  choose the ${this._paymentPlanData.intervals.length + 1} part payment plan option with ${formatCurrency(this._paymentPlanData.initialAmount)} at the time of approval, ${joinStringsAnd(intervalDescriptions)} for a total of ${formatCurrency(this.paymentPlanTotalAmount)} USD (plus tax)`
    }

    return ''
  }

  componentDidMount (): void {
    this.loading = true

    ApiClient.getInstance().get(route(ApiRoutes.public.getApplication, { slug: this.props.match.params.slug }))
      .then(response => {
        this.applicationData = new ApplicationData().init(modelToCamelCase(response.data.application))
        this._membershipFee = Number(response.data.membership_fee)
        this._taxRate = Number(response.data.tax_rate)
        this._renewalFee = Number(response.data.renewal_fee)
        this.shouldShowPaymentPlan = response.data.should_show_payment_plan!=null ? response.data.should_show_payment_plan : true
        this._chapterFees = response.data.chapter_fees.map((fee: any) => {
          return {
            code: fee.code,
            label: fee.label,
            amount: Number(fee.amount),
          }
        })

        this._paymentPlanData = response.data.payment_plan ? modelToCamelCase(response.data.payment_plan) as PaymentPlanData : undefined

        this._veteranDiscountPercent = response.data.veteran_discount_percent ? Number(response.data.veteran_discount_percent) : 0

        if (this.applicationData.applicationType === ApplicationType.CompanyReplacement) {
          this.formState.set('membershipType', 'Company')
        }

        this.formState.set('firstName', this.applicationData.guest.firstName)
        this.formState.set('lastName', this.applicationData.guest.lastName)
        this.formState.set('email', this.applicationData.guest.email)
        this.formState.set('phone', this.applicationData.guest.phone)
        this.formState.set('business.name', this.applicationData.guest.companyName)
        this.formState.set('categoryId', this.applicationData.guest.category ? this.applicationData.guest.category.id : undefined)

        if (this.applicationData.targetChapter.billingRegion === 'Canada') {
          this.formState.set('country', 'Canada')
          this.formState.set('business.country', 'Canada')
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          this.error = 'The application link you have followed is no longer valid'
        } else {
          this.error = Util.extractErrorMessage(error.response)
        }
      })
      .then(() => this.loading = false)
  }

  private scrollToFormErrors = (formErrors?: ErrorBag) => {
    setTimeout(() => {
      const el: HTMLCollectionOf<Element> = document.getElementsByClassName('form-error')

      if (el.length) {
        window.scroll({ left: 0, top: window.scrollY + el[0].getBoundingClientRect().top - 100, behavior: 'smooth' })
      } else if (formErrors && formErrors.hasErrors()) {
        // there are no visible errors on the form, but we have errors. show them in a modal
        AppStateStore.showAlertModal('Error', _.map(formErrors.getErrors(), (e, idx) => <div key={idx}>{e}</div>))
      }
    })
  }

  private submitForm = async (ev: SyntheticEvent) => {
    ev.preventDefault()
    await this.submit(false)
  }

  private submit = async (skipConfirmName: boolean = false) => {
    this.formErrors.clearErrors()

    if (!this.formState.get('acceptRenewalTerms')) {
      this.formErrors.addError('acceptRenewalTerms', 'You must accept the above terms')
    }

    if (!this.formState.get('acceptAgreement')) {
      this.formErrors.addError('acceptAgreement', 'You must accept the above terms')
    }

    const renewalSignatureImageData = this.renewalSignatureRef.current!.toDataURL()
    const applicationSignatureImageData = this.applicationSignatureRef.current!.toDataURL()

    let membershipPaymentMethodType = this.formState.get('payment.paymentMethodType')
    let renewalPaymentMethodType = this.formState.get('renewal.paymentMethodType')

    // validate membership payment data
    if (membershipPaymentMethodType === 'CreditCard') {
      if (!this.paymentInfo.cardInfo || !this.paymentInfo.cardInfo.isValid) {
        this.formErrors.addError('payment.cardError', 'You must enter a valid card')
      }
    } else if (membershipPaymentMethodType === 'BankAccount') {
      if (
        !this.paymentInfo.bankInfo.accountNumber.trim().length
        || !this.paymentInfo.bankInfo.nameOnAccount.trim().length
        || !this.paymentInfo.bankInfo.routingNumber.trim().length
        || !this.paymentInfo.bankInfo.accountType.trim().length
      ) {
        this.formErrors.addError('payment.bankError', 'You must enter valid bank account info')
      } else {
        if (this.paymentInfo.bankInfo.nameOnAccount.length > 22) {
          this.formErrors.addError('payment.bankError', 'Name on account must be less than 22 characters')
        }

        if (!/^[a-zA-Z0-9 ]+$/.test(this.paymentInfo.bankInfo.nameOnAccount)) {
          this.formErrors.addError('payment.bankError', 'Name on account can only contain letters, numbers, and spaces')
        }
      }
    }

    if (this.formState.get('renewal.renewalMethod') === 'Automatic') {
      // validate renewal payment data
      if (renewalPaymentMethodType === 'CreditCard') {
        if (!this.renewalInfo.cardInfo || !this.renewalInfo.cardInfo.isValid) {
          this.formErrors.addError('renewal.cardError', 'You must enter a valid card')
        }
      } else if (renewalPaymentMethodType === 'BankAccount') {
        if (
          !this.renewalInfo.bankInfo.accountNumber.trim().length
          || !this.renewalInfo.bankInfo.nameOnAccount.trim().length
          || !this.renewalInfo.bankInfo.routingNumber.trim().length
          || !this.renewalInfo.bankInfo.accountType.trim().length
        ) {
          this.formErrors.addError('renewal.bankError', 'You must enter valid bank account info')
        }
      }
    }

    if (this.formErrors.hasErrors()) {
      this.scrollToFormErrors(this.formErrors)
      return
    }

    if (!skipConfirmName) {
      this.showConfirmNameModal = true
      return
    }

    AppStateStore.showModalSpinner()

    let acceptJs: AcceptJsClient

    try {
      acceptJs = await loadAcceptJs()
    } catch (err) {
      AppStateStore.showAlertModal('Error', 'Unable to connect to payment processor')
      AppStateStore.dismissModalSpinner()

      if (this.formErrors.hasErrors()) {
        this.scrollToFormErrors(this.formErrors)
      }
      return
    }

    let membershipPaymentOpaqueData
    let renewalPaymentOpaqueData

    if (membershipPaymentMethodType === 'BankAccount') {
      // Paya tokenization
      const postData = {
        ...modelToSnakeCase(this.paymentInfo.bankInfo),
        provider: 'Paya',
      }

      try {
        const response = await ApiClient.getInstance().post(route(ApiRoutes.public.paya.addPaymentMethod), postData)
        membershipPaymentOpaqueData = {
          data_descriptor: 'tokenized_uuid',
          data_value: response.data.uuid,
        }
      } catch (error) {
        Util.handleErrorResponse(error.response, undefined, undefined, (response, message) => {
          let errors = _.flatten<string>(Object.values(response.data.data.errors))
          if (errors.length) {
            errors.forEach(err => this.formErrors.addError('payment.bankError', err))
          } else {
            this.formErrors.addError('payment.bankError', message)
          }
          return true
        })

        AppStateStore.dismissModalSpinner()

        if (this.formErrors.hasErrors()) {
          this.scrollToFormErrors(this.formErrors)
        }
        return
      }
    } else {
      // AuthNet tokenization
      try {

        const paymentMethod = membershipPaymentMethodType === 'CreditCard'
          ? {
            cardData: {
              cardNumber: this.paymentInfo.cardInfo!.cardNumber.replace(/ /g, ''),
              cardCode: this.paymentInfo.cardInfo!.cardCode,
              month: this.paymentInfo.cardInfo!.expMonth,
              year: this.paymentInfo.cardInfo!.expYear,
            }
          }
          : { bankData: this.paymentInfo.bankInfo }
        const tokenizeResponse: AcceptJsResponseDataType = await acceptJs.tokenizePaymentMethod(paymentMethod)

        membershipPaymentOpaqueData = tokenizeResponse.opaqueData
      } catch (response) {
        if (response.messages) {
          (response as AcceptJsResponseDataType).messages.message.forEach(message => {
            this.formErrors.addError(membershipPaymentMethodType === 'CreditCard' ? 'payment.cardError' : 'payment.bankError', message.text)
          })
        } else {
          this.formErrors.addError(membershipPaymentMethodType === 'CreditCard' ? 'payment.cardError' : 'payment.bankError', 'Error contacting server')
        }

        AppStateStore.dismissModalSpinner()

        if (this.formErrors.hasErrors()) {
          this.scrollToFormErrors(this.formErrors)
        }
        return
      }
    }

    if (this.formState.get('renewal.renewalMethod') === 'Automatic') {
      // tokenize the renewal payment method
      if (renewalPaymentMethodType !== 'Same') {
        if (renewalPaymentMethodType === 'BankAccount') {
          // Paya tokenization
          const postData = {
            ...modelToSnakeCase(this.renewalInfo.bankInfo),
            provider: 'Paya',
          }

          try {
            const response = await ApiClient.getInstance().post(route(ApiRoutes.public.paya.addPaymentMethod), postData)
            renewalPaymentOpaqueData = {
              data_descriptor: 'tokenized_uuid',
              data_value: response.data.uuid,
            }
          } catch (error) {
            Util.handleErrorResponse(error.response, undefined, undefined, (response, message) => {
              let errors = _.flatten<string>(Object.values(response.data.data.errors))
              if (errors.length) {
                errors.forEach(err => this.formErrors.addError('renewal.bankError', err))
              } else {
                this.formErrors.addError('renewal.bankError', message)
              }
              return true
            })

            AppStateStore.dismissModalSpinner()

            if (this.formErrors.hasErrors()) {
              this.scrollToFormErrors(this.formErrors)
            }
            return
          }
        } else {
          // AuthNet tokenization
          try {
            const paymentMethod = renewalPaymentMethodType === 'CreditCard'
              ? {
                cardData: {
                  cardNumber: this.renewalInfo.cardInfo!.cardNumber.replace(/ /g, ''),
                  cardCode: this.renewalInfo.cardInfo!.cardCode,
                  month: this.renewalInfo.cardInfo!.expMonth,
                  year: this.renewalInfo.cardInfo!.expYear,
                }
              }
              : { bankData: this.renewalInfo.bankInfo }

            const tokenizeResponse: AcceptJsResponseDataType = await acceptJs.tokenizePaymentMethod(paymentMethod)

            renewalPaymentOpaqueData = tokenizeResponse.opaqueData
          } catch (response) {
            if (response.messages) {
              (response as AcceptJsResponseDataType).messages.message.forEach(message => {
                this.formErrors.addError(renewalPaymentMethodType === 'CreditCard' ? 'renewal.cardError' : 'renewal.bankError', message.text)
              })
            } else {
              this.formErrors.addError(renewalPaymentMethodType === 'CreditCard' ? 'renewal.cardError' : 'renewal.bankError', 'Error  contacting server')
            }

            AppStateStore.dismissModalSpinner()

            if (this.formErrors.hasErrors()) {
              this.scrollToFormErrors(this.formErrors)
            }
            return
          }
        }
      }
    }

    const submitData = {
      ...this.formHelper.toObject(),
      membershipPaymentOpaqueData,
      renewalPaymentOpaqueData,
      renewalSignatureImageData,
      applicationSignatureImageData,
      membershipFee: this.membershipFee,
      renewalFee: this.renewalFee,
    }

    ApiClient.getInstance().post(route(ApiRoutes.public.submitApplication, { slug: this.props.match.params.slug }), modelToSnakeCase(submitData))
      .then(() => {
        this.applicationData.status = 'Submitted'
      })
      .catch(error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
        this.scrollToFormErrors(this.formErrors)
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
      })
  }

  private renderApplication = () => {
    let sectionNumber = 1
    return <>
      {this.applicationData && <div className="content-header">
        <h2>{`Hello, ${this.applicationData.guest.firstName}!`}</h2>
        <p>Please complete the LeTip Membership Application below. Thank you!</p>
      </div>}

      <div className="card">
        <div className="card-body">
          <h3>Chapter: {this.applicationData.targetChapter.name}</h3>
          <SectionHeader number={sectionNumber++} title="Contact Information"/>
          <ContactInformationForm formHelper={this.formHelper} applicationData={this.applicationData}/>

          <SectionHeader number={sectionNumber++} title="Business Information"/>
          <BusinessInformationForm
            formHelper={this.formHelper}
            initialCategory={transformIf(this.applicationData && this.applicationData.guest.category, c => ({ id: c.id, label: c.name }))}
            applicationData={this.applicationData}
          />

          {
            this.applicationData.applicationType !== ApplicationType.CompanyReplacement && this.applicationData.sponsor
              ? <>
                <SectionHeader number={sectionNumber++} title="Sponsor Information"/>
                <p>Your Sponsor is the member who introduced you to LeTip.</p>
                <div className="form-row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>Sponsor's Name</label>
                      <div><b>{this.applicationData.sponsor.fullName}</b></div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <label>Sponsor's Chapter</label>
                      <div><b>{this.applicationData.sponsor.chapter.name}</b></div>
                    </div>
                  </div>
                </div>
              </>
              : null
          }

          <SectionHeader number={sectionNumber++} title="Payment Information"/>
          <p>Your account will be charged if your application is approved and you are voted into the Chapter</p>
          <PaymentMethodForm
            formState={this.formState}
            formErrors={this.formErrors}
            formHelper={this.formHelper}
            cardFormErrorFieldName="payment.cardError"
            bankFormErrorFieldName="payment.bankError"
            onCardInfoChanged={cardInfo => this.paymentInfo.cardInfo = cardInfo}
            onBankInfoChanged={bankInfo => this.paymentInfo.bankInfo = bankInfo}
          />
          <div className="row mt-4">
            <div className="col-md-6">
              {/*<div className="callout-box">*/}
              {/*  Promo*/}
              {/*  {*/}
              {/*    // TODO: promo code*/}
              {/*  }*/}
              {/*</div>*/}
            </div>
            <div className="col-md-6">
              <div className="callout-box text-right">
                <span style={{ fontSize: 16 }}>
                  Total Due: {formatCurrency(this.totalDue)} USD
                  {this.taxRate ? ' (plus tax)' : ''}
                </span>
              </div>
            </div>
          </div>

          {
            // Suffolk county payment plan enablement fix
            // (this._paymentPlanData && this.applicationData.applicationType === ApplicationType.NewMember && this.shouldShowPaymentPlan == true)
            (this._paymentPlanData && this.applicationData.applicationType === ApplicationType.NewMember)
              ? <div className="row">
                <div className="col-12">
                  <div className="application-checkbox-container">
                    <div className="form-check form-checkbox">
                      <label>
                        <input type="checkbox"
                               className="form-check-input"
                               name="usePaymentPlan"
                               checked={this.formState.get('usePaymentPlan')}
                               onChange={this.formState.onChange}
                        />
                        <span className="label-text">{this.paymentPlanDescription}</span>
                      </label>
                    </div>
                    <FormError errors={this.formErrors} fieldName="acceptRenewalTerms"/>
                  </div>
                </div>
              </div>
              : null
          }

          {
            (this._veteranDiscountPercent > 0 && this.applicationData.applicationType === ApplicationType.NewMember)
              ? <div className="row">
                <div className="col-12">
                  <div className="application-checkbox-container">
                    <div className="form-check form-checkbox">
                      <label>
                        <input type="checkbox"
                               className="form-check-input"
                               name="isVeteran"
                               checked={this.formState.get('isVeteran')}
                               onChange={this.formState.onChange}
                        />
                        <span className="label-text">I am a veteran</span>
                      </label>
                    </div>
                    <FormError errors={this.formErrors} fieldName="isVeteran"/>
                  </div>
                </div>
              </div>
              : null
          }

          <SectionHeader number={sectionNumber++} title="Renewal"/>
          <RenewalForm
            formState={this.formState}
            formErrors={this.formErrors}
            formHelper={this.formHelper}
            cardFormErrorFieldName="renewal.cardError"
            bankFormErrorFieldName="renewal.bankError"
            onCardInfoChanged={cardInfo => this.renewalInfo.cardInfo = cardInfo}
            onBankInfoChanged={bankInfo => this.renewalInfo.bankInfo = bankInfo}
          />

          <SectionHeader number={sectionNumber} title="Acceptance and Signature"/>
          <div className="application-subheader">Renewal Terms and Conditions</div>
          <p><b>Subject to the following conditions:</b></p>
          <ol>
            <li>The item shall be withdrawn on or about the 1st of your anniversary month.</li>
            <li>By executing this agreement, you acknowledge your awareness that certain disclosures required by the Electronic Funds Transfer Act and its regulations are available for your review at LeTip International's website: www.letip.com under Terms of Use.</li>
            <li>If this pre-authorization payment arrangement is revoked for any reason, this does not release you from your membership financial obligations.</li>
            <li>A service fee will be assessed and drafted for any check, credit card which is returned by your financial institution for any reason. Late payment of renewal dues may be subject to an automated late fee.</li>
            <li>This preauthorization payment arrangement shall apply to the following applicant:</li>
          </ol>
          <div className="application-checkbox-container">
            <div className="form-check form-checkbox">
              <label>
                <input type="checkbox"
                       className="form-check-input"
                       name="acceptRenewalTerms"
                       checked={this.formState.get('acceptRenewalTerms')}
                       onChange={this.formState.onChange}
                />
                <span className="label-text">I hereby authorize LeTip International, Inc. to withdraw funds (e-checks or credit card) for the purpose of paying my annual LeTip International membership dues, including any late fees or service fees.</span>
              </label>
            </div>
            <FormError errors={this.formErrors} fieldName="acceptRenewalTerms"/>
          </div>

          <div className="application-subheader">Application Signature</div>
          <p>
            My signature below attests that I understand that <b>LeTip International dues are non-refundable</b>,
            and that I have read, understand, and agree to abide by LeTip International's Application Agreement,
            fee structure and program requirements. I also understand that if I resign from LeTip, or my membership
            is terminated by the board of directors or by a LeTip International representative, <b>my membership
            and renewal dues are non-refundable.</b>
          </p>

          <ApplicationSignature
            ref={this.renewalSignatureRef}
          />
          <FormError errors={this.formErrors} fieldName="renewalSignatureImageData"/>

          <div className="application-subheader">Application Agreement and Signature</div>
          
          <p>
            My signature below attests that I understand that <b>LeTip International dues are non-refundable</b>, and that I have read, understand, and agree to abide by LeTip International’s Application Agreement, fee structure, bylaws, and program requirements. I also agree that if I resign from LeTip, or my membership is terminated by the board of directors or by a LeTip International representative, <b>my membership and renewal dues are non-refundable</b>. By submitting this application, I agree that I’ve read the bylaws provided herein this application email.
          </p>

          <p>
            Once your application is submitted, the Chapter Inspector will schedule a time to complete your interview and confirm eligibility to join the chapter. 
            <br/>
            <b>The applicant is required to read and agree to the statements shown below</b> and the Chapter Inspector will review these statements during the visit. Following the Inspection
            and your attendance at a second LeTip Chapter meeting, the Chapter will vote on your application. Once voted on, and if approved for
            membership your selected method of payment for LeTip national dues will be charged. <span style={{ backgroundColor: 'yellow' }}>Your local chapter dues will be paid through the local Treasurer of the Chapter.</span>
          </p>
          <p>
          <b>Attendance is Important:</b>
          </p>
          <ul>
            <li>I understand that if I, or my alternate, miss two (2) consecutive unexcused meetings, or four (4) meetings in a calendar quarter, excused or not, my membership may be terminated.
            </li>
            <li>I will notify the Chapter's Vice-President in advance if I am unable to attend a meeting.</li>
            <li>I understand quarterly Business Mixer attendance is mandatory and necessary to help build the chapter.</li>
            <li>I agree to present and conduct myself in a professional manner.</li>
          </ul>
          <p>
          <b>Tipping is important:</b>
          </p>
          <ul>
            <li>I will develop a relationship with each member in my LeTip Chapter and give them the opportunity to quote my business/individual needs.</li>
            <li>I agree to Article IV, Section 4.1 (e) of the LeTip Bylaws which states that I am required to provide a minimum of 4 Tips per month in my chapter. I am willing to make this commitment and understand failing to pass 4 Tips per
              month is grounds for termination.
            </li>
          </ul>
            <p>
              <b>Sponsoring New Members is important:</b>
            </p>
          <ul>
            <li>I will learn how to invite qualified guests to meetings and encourage them to join.</li>
            <li>I agree that it is my responsibility to help grow my chapter.</li>
            <li>I will strive to sponsor at least one new member into LeTip within my first year.</li>
          </ul>
          <p>
            <b>Ethics is Important:</b>
          </p>
          <ul>
            <li>I understand that my membership may be terminated because of a breach of ethics.</li>
            {/* <li>I work full-time in my Official LeTip Category.</li> */}
            <li>I agree to represent only one Official LeTip Category, as it appears on my badge.</li>
            <li>If required, I maintain a current license, accreditation, and/or insurance deemed necessary to work in my category.</li>
            <li>I will conform to LeTip's Bylaws, Rules & Procedures, and any subsequent changes that may occur.</li>
            <li>I understand that I cannot belong to a similar lead generated/category exclusive networking group (BNI, etc.), tip or barter organization.</li>
            <li>I understand the information on this application will be used by the chapter when voting on my membership, or if a new chapter, by LeTip International's Representative.</li>
          </ul>
          <p>
            <b>Participation Is Important:</b>
          </p>
          <ul>
            <li>
              I understand that members are encouraged and expected to serve on the board or a committee. I am interested in (choose one):
              <br/>
              <label>
                <input
                  type="radio"
                  name="boardInterestOption"
                  value="Becoming a board member"
                  checked={this.formState.get('boardInterestOption') === 'Becoming a board member'}
                  onChange={ev => this.formState.set('boardInterestOption', ev.target.value)}
                /> Becoming a board member
              </label>
              &nbsp;
              &nbsp;
              &nbsp;
              <label>
                <input
                  type="radio"
                  name="boardInterestOption"
                  value="Serving on a committee"
                  checked={this.formState.get('boardInterestOption') === 'Serving on a committee'}
                  onChange={ev => this.formState.set('boardInterestOption', ev.target.value)}
                /> Serving on a committee
              </label>
              &nbsp;
              &nbsp;
              &nbsp;
              My interests are: <input
              name="boardInterestText"
              type="text"
              style={{ display: 'inline-block', width: '360px' }}
              className="form-control"
              value={this.formState.get('boardInterestText')}
              onChange={ev => this.formState.set('boardInterestText', ev.target.value)}
            />
              <FormError errors={this.formErrors} fieldName={'boardInterestOption'}/>
              <FormError errors={this.formErrors} fieldName={'boardInterestText'}/>
            </li>
            <li>New Member Training also referred to as our Network Training Seminar (NTS) should be taken within the first 60 days of membership.</li>
          </ul>
          <p>
            <b>Financial Obligations are Important:</b>
          </p>
          <ul>
            <li>I fully understand my financial obligations regarding my initial and annual membership dues which include:<br/>Initial LeTip Membership Dues of {formatCurrency(this.membershipFee)} {this.taxRate ? '(plus tax)' : ''}</li>
            <li>Annual LeTip Membership Renewal is due during my anniversary month.</li>
            <li>If a payment declines, there will be a $25 fee.</li>
            <li>AUTOMATIC RENEWAL<br/>I agree to have my membership automatically renew at the rate indicated below. My membership will renew annually on the date that my membership application is approved by
              LeTip International, Inc. I acknowledge that LeTip International, Inc. reserves the right to increase membership dues. I understand that I will be given not less than 30-days notice of any such increase. If I cancel automatic renewal of my membership, I will submit written notice not
              less than 30 days before cancellation via LeTip Wired.
            </li>
            <li>ANNUAL Renewal Dues {formatCurrency(this.renewalFee)} {this.taxRate ? ' (plus tax)' : ''}</li>
              <ul>
                <li>You will receive a free annual renewal after sponsoring 6 new members into LeTip.</li>
                <li>You will receive free lifetime annual renewals after sponsoring 25 new members into LeTip.</li>
              </ul>
            <li>The Chapter is a separate entity from LeTip International and has its own financial obligations</li>
            <ul>
              {
                this.initialChapterDues
                ? <li>Initial Chapter dues of {formatCurrency(this.initialChapterDues.amount)}</li>
                : null
              }
              {
                this.quarterlyChapterDues
                ? <li>Quarterly Chapter dues of {formatCurrency(this.quarterlyChapterDues.amount)}</li>
                : null
              }              
              {
                this.otherChapterFee
                ? <li>{this.otherChapterFee.label} {formatCurrency(this.otherChapterFee.amount)}</li>
                : null
              }
            </ul>
          </ul>

          <div className="application-checkbox-container">
            <div className="form-check form-checkbox">
              <label>
                <input type="checkbox"
                       className="form-check-input"
                       name="acceptAgreement"
                       checked={this.formState.get('acceptAgreement')}
                       onChange={ev => this.formState.onChange(ev)}
                />
                <span className="label-text">I have read and understand all the above statements, and agree to abide by them.</span>
              </label>
            </div>
            <FormError errors={this.formErrors} fieldName="acceptAgreement"/>
          </div>

          <ApplicationSignature
            ref={this.applicationSignatureRef}
          />
          <FormError errors={this.formErrors} fieldName="applicationSignatureImageData"/>
        </div>
      </div>

      <div className="form-buttons">
        <Button
          color="success"
          onClick={this.submitForm}
        >Submit Application</Button>
      </div>

      {
        this.showConfirmNameModal
          ? <ConfirmNameModal
            onClosed={() => this.showConfirmNameModal = false}
            onConfirmed={() => this.submit(true)}
            onFieldChanged={(field, value) => this.formState.set(field, value)}
            fields={{ firstName: this.formState.get('firstName'), lastName: this.formState.get('lastName') }}
          />
          : null
      }
    </>
  }

  private renderError = () => {
    return <div className="alert alert-danger">{this.error}</div>
  }

  private renderThankYou = () => {
    return <>
      {this.applicationData && <div className="content-header">
        <h2>{`Thank you, ${this.applicationData.guest.firstName}!`}</h2>
        <p>Your application has been submitted!</p>
      </div>}

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <h3 className="mb-5">What's Next?</h3>
              <ol>
                <li>You must attend 2 meetings prior to the Chapter voting on your membership.</li>
                <li>The Chapter Inspector will contact you to complete an Inspection of your business. All LeTip Members’ businesses go through an inspection and review the Application prior to voting.</li>
                <li>The Chapter will vote on your Membership Application.</li>
                <li>You will receive confirmation of the status of your Application once voted on.</li>
                <li>Once approved, your application fee for LeTip will be processed via the payment type you provided on your application. Your chapter fees will be processed separately through your Chapter Treasurer. If your application is voted down, contact LeTip to discuss other options in your
                  Area. (800) 255-3847.
                </li>
                <li>If you have any questions during the application process, please don’t hesitate to contact the LeTip headquarters at (800) 255-3847.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  }

  private renderBody = () => {
    if (this.applicationData) {
      if (this.applicationData.status === 'Pending') {
        return this.renderApplication()
      } else if (this.applicationData.status === 'Submitted') {
        return this.renderThankYou()
      } else {
        return <div>It looks like this application has already been processed or the link you followed is invalid</div>
      }
    }

    return null

  }

  render (): React.ReactNode {
    return <>
      <div className="header-container">
        <div className="brand">
          <img className="brand-image" alt="LeTip Wired" src={publicPath('img/wired-logo.png')}/>
        </div>
        <div className="header-nav"/>
      </div>
      <div className="body-container edit-application">
        <div className="left-nav"/>
        <div className="content-container">
          <div className="content">
            {this.error
              ? this.renderError()
              : <>
                {(this.loading || !this.applicationData)
                  ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
                  : this.renderBody()}
              </>}
          </div>
          <div className="content-footer">
            Version {Config.VERSION || '???'}{Config.BUILD_NUMBER ? `-${Config.BUILD_NUMBER}` : null} {Config.ENV !== 'production' ? `(${Config.ENV})` : null} {Config.NODE_ENV !== 'production' ? `[${Config.NODE_ENV}]` : null}
          </div>
        </div>
      </div>
    </>
  }
}

@observer
class ApplicationSignature extends React.Component {
  private canvasRef = React.createRef<SignatureCanvas>()

  toDataURL (): string {
    return (this.canvasRef.current as any).toDataURL("image/png")
  }

  render (): React.ReactNode {
    return <div className="signature-container">
      <div className="signature-box">
        <SignatureCanvas
          canvasProps={{ className: 'signature-canvas' }}
          ref={this.canvasRef}
        />
        <label>Sign with your mouse</label>
      </div>
      <div className="clear-signature">
        <Button
          color="secondary"
          onClick={() => (this.canvasRef.current as any)!.clear()}
        >Clear Signature</Button>
      </div>
      <div className="signature-date">
        <span>{moment().format('MMMM DD, YYYY')}</span>
      </div>
      <div className="label-tab">
        Sign Here
        <div className="label-tab-arrow"/>
      </div>
    </div>
  }
}

@observer
class SectionHeader extends React.Component
  <{
    number: number,
    title: string,
  }> {

  render (): React.ReactNode {
    return <div className="application-section-header">
      <div className="application-section-number">{this.props.number}</div>
      <div className="application-section-title">{this.props.title}</div>
    </div>
  }
}

@observer
class ContactInformationForm extends React.Component<{
  formHelper: FormHelper<any>,
  applicationData: ApplicationData,
}> {

  render (): React.ReactNode {
    const formHelper = this.props.formHelper
    return <>
      <div className="form-row" hidden>
        <div className="col-4">
          {
            this.props.applicationData.applicationType === ApplicationType.CompanyReplacement
              ? <div className="form-group">
                <label>Membership Type</label>
                <div><b>Company</b></div>
              </div>
              // : formHelper.renderSelectInput({
                 : formHelper.renderTextInput({
                // placeholder: '(choose)',
                name: 'membershipType',
                label: 'Membership Type',
                // options: ['Personal', 'Company'].map(s => ({ value: s, text: s })),
                type: 'text',
                disabled: true,
              })
          }
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-2">
          {formHelper.renderSelectInput({
            placeholder: '(choose)',
            name: 'prefix',
            label: 'Prefix',
            options: [...Config.NAME_PREFIX_OPTIONS.map(o => ({ value: o, text: o }))]
          })}
        </div>
        <div className="col-sm-3">
          {formHelper.renderTextInput({
            name: 'firstName',
            type: 'text',
            label: 'First Name',
          })}
        </div>
        <div className="col-sm-3">
          {formHelper.renderTextInput({
            name: 'lastName',
            type: 'text',
            label: 'Last Name',
          })}
        </div>
        <div className="col-sm-2">
          {formHelper.renderSelectInput({
            placeholder: '(choose)',
            name: 'suffix',
            label: 'Suffix',
            options: [...Config.NAME_SUFFIX_OPTIONS.map(o => ({ value: o, text: o }))]
          })}
        </div>
        <div className="col-sm-2">
          {formHelper.renderSelectInput({
            placeholder: '(choose)',
            name: 'gender',
            label: 'Gender',
            options: [...Config.GENDER_OPTIONS.map(o => ({ value: o, text: o }))],
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          {formHelper.renderTextInput({
            name: 'email',
            type: 'email',
            label: 'Email Address',
          })}
        </div>
        <div className="col-sm-6">
          {formHelper.renderTextInput({
            name: 'phone',
            type: 'text',
            label: 'Phone Number',
          })}
        </div>
      </div>

      <h6>Address Information</h6>

      <div className="form-row">
        <div className="col-sm-8">
          {this.props.formHelper.renderTextInput({
            name: 'address',
            type: 'text',
            label: 'Street',
          })}
        </div>
        <div className="col-sm-4">
          {this.props.formHelper.renderTextInput({
            name: 'address2',
            type: 'text',
            label: 'Suite / Room / Apt #',
          })}
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-3">
          {this.props.formHelper.renderTextInput({
            name: 'city',
            type: 'text',
            label: 'City',
          })}
        </div>
        <div className="col-sm-3">
          {this.props.formHelper.renderTextInput({
            name: 'state',
            type: 'text',
            label: 'State',
          })}
        </div>
        <div className="col-sm-3">
          {this.props.formHelper.renderTextInput({
            name: 'zipCode',
            type: 'text',
            label: 'Zip Code',
          })}
        </div>
        <div className="col-sm-3">
          {this.props.formHelper.renderSelectInput({
            name: 'country',
            options: Config.COUNTRY_OPTIONS.map(c => ({ value: c, text: c })),
            label: 'Country',
          })}
        </div>
      </div>
    </>
  }
}

@observer
class BusinessInformationForm extends React.Component
  <{
    formHelper: FormHelper<any>,
    initialCategory?: SelectOption,
    applicationData: ApplicationData,
  }> {

  render (): React.ReactNode {
    const formHelper = this.props.formHelper
    return <>
      <h6>Business Category</h6>
      <div className="form-row">
        <div className="col-sm-12">
          {formHelper.renderAsyncSelectInput({
            label: 'Category',
            name: 'categoryId',
            defaultValue: this.props.initialCategory,
            loadOptions: loadCategoryOptions(),
            disabled: this.props.applicationData.applicationType === ApplicationType.CompanyReplacement,
          })}
        </div>
      </div>

      <h6>Contact Information</h6>

      <div className="form-row">
        <div className="col-sm-4">
          {formHelper.renderTextInput({
            type: 'text',
            name: 'business.name',
            label: 'Business Name',
            disabled: this.props.applicationData.applicationType === ApplicationType.CompanyReplacement,
          })}
        </div>
        <div className="col-sm-4">
          {formHelper.renderTextInput({
            type: 'text',
            name: 'business.phone',
            label: 'Business Phone',
          })}
        </div>
        <div className="col-sm-4">
          {formHelper.renderTextInput({
            type: 'text',
            name: 'business.fax',
            label: 'Business Fax',
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          {formHelper.renderTextInput({
            name: 'business.email',
            type: 'email',
            label: 'Business Email Address',
          })}
        </div>
        <div className="col-sm-6">
          {formHelper.renderTextInput({
            name: 'business.websiteUrl',
            type: 'text',
            label: 'Business Website',
          })}
        </div>
      </div>

      <h6>Address Information</h6>

      <div className="form-row">
        <div className="col-12">
          {formHelper.renderCheckboxInput({
            label: 'Use same address as above',
            name: 'isBusinessAddressSame',
          })}
        </div>
      </div>

      {
        !formHelper.getFormState().get('isBusinessAddressSame')
          ? <>
            <div className="form-row">
              <div className="col-sm-8">
                {this.props.formHelper.renderTextInput({
                  name: 'business.address',
                  type: 'text',
                  label: 'Street',
                })}
              </div>
              <div className="col-sm-4">
                {this.props.formHelper.renderTextInput({
                  name: 'business.address2',
                  type: 'text',
                  label: 'Suite / Room / Apt #',
                })}
              </div>
            </div>

            <div className="form-row">
              <div className="col-sm-3">
                {this.props.formHelper.renderTextInput({
                  name: 'business.city',
                  type: 'text',
                  label: 'City',
                })}
              </div>
              <div className="col-sm-3">
                {this.props.formHelper.renderTextInput({
                  name: 'business.state',
                  type: 'text',
                  label: 'State',
                })}
              </div>
              <div className="col-sm-3">
                {this.props.formHelper.renderTextInput({
                  name: 'business.zipCode',
                  type: 'text',
                  label: 'Zip Code',
                })}
              </div>
              <div className="col-sm-3">
                {this.props.formHelper.renderSelectInput({
                  name: 'business.country',
                  options: Config.COUNTRY_OPTIONS.map(c => ({ value: c, text: c })),
                  label: 'Country',
                })}
              </div>
            </div>
          </>
          : null
      }
    </>
  }
}

type BankInfo = {
  routingNumber: '',
  accountNumber: '',
  nameOnAccount: '',
  accountType: 'checking'
}

@observer
class PaymentMethodForm extends React.Component<{
  formState: FormState<any>
  formHelper: FormHelper<any>
  formErrors: ErrorBag
  bankFormErrorFieldName: string
  cardFormErrorFieldName: string
  onCardInfoChanged: (cardInfo: CardInfo) => void
  onBankInfoChanged: (bankInfo: BankInfo) => void
}> {
  @observable
  private bankAccountFormState = new FormState<BankInfo>({
    routingNumber: '',
    accountNumber: '',
    nameOnAccount: '',
    accountType: 'checking'
  })

  @observable
  private bankAccountFormErrors = new ErrorBag()

  private bankAccountFormHelper = new FormHelper(this.bankAccountFormState, this.bankAccountFormErrors)

  @observable
  private cardInfo?: CardInfo

  @observable
  private creditCardFormErrors = new ErrorBag()

  render (): React.ReactNode {
    return <>
      <Nav tabs>
        <NavItem
          className={classNames({ active: this.props.formState.get('payment.paymentMethodType') === 'CreditCard' })}
        >
          <NavLink
            onClick={() => {
              this.props.formState.set('payment.paymentMethodType', 'CreditCard')
            }}
          >Credit/Debit Card</NavLink>
        </NavItem>
        <NavItem
          className={classNames({ active: this.props.formState.get('payment.paymentMethodType') == 'BankAccount' })}
        >
          <NavLink
            onClick={() => {
              this.props.formState.set('payment.paymentMethodType', 'BankAccount')
            }}
          >Bank Account</NavLink>
        </NavItem>
      </Nav>

      <div className="tab-content-body">
        <br/>
        <TabContent activeTab={this.props.formState.get('payment.paymentMethodType')}>
          <TabPane tabId={'CreditCard'}>
            <label>Card Info</label>
            <form method="post" action="#" acceptCharset="UTF-8" onSubmit={ev => ev.preventDefault()}>
              <div className="form-row">
                <div className="col-md-6">
                  <CreditCardInput
                    onChange={(cardInfo) => {
                      this.cardInfo = cardInfo
                      this.props.onCardInfoChanged(cardInfo)
                    }}
                  />
                  <FormError errors={this.props.formErrors} fieldName={this.props.cardFormErrorFieldName}/>
                </div>
              </div>
            </form>
          </TabPane>
          <TabPane tabId={'BankAccount'}>
            <form method="post" action="#" acceptCharset="UTF-8" onSubmit={ev => ev.preventDefault()}>
              <div className="form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    {this.bankAccountFormHelper.renderSelectInput({
                      label: 'Account Type',
                      options: [
                        { value: 'checking', text: 'Checking Account' },
                        { value: 'savings', text: 'Savings Account' },
                      ],
                      name: 'accountType',
                      onChange: () => this.props.onBankInfoChanged(this.bankAccountFormState.toObject()),
                    })}
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    {this.bankAccountFormHelper.renderTextInput({
                      label: 'Routing Number',
                      type: 'text',
                      name: 'routingNumber',
                      onChange: () => this.props.onBankInfoChanged(this.bankAccountFormState.toObject()),
                    })}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    {this.bankAccountFormHelper.renderTextInput({
                      label: 'Account Number',
                      type: 'text',
                      name: 'accountNumber',
                      onChange: () => this.props.onBankInfoChanged(this.bankAccountFormState.toObject()),
                    })}
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12">
                  <div className="form-group">
                    {this.bankAccountFormHelper.renderTextInput({
                      label: 'Name On Account',
                      type: 'text',
                      name: 'nameOnAccount',
                      onChange: () => this.props.onBankInfoChanged(this.bankAccountFormState.toObject()),
                    })}
                  </div>
                </div>
              </div>
              <FormError errors={this.props.formErrors} fieldName={this.props.bankFormErrorFieldName}/>
            </form>
          </TabPane>
        </TabContent>
        <FormError errors={this.props.formErrors} fieldName="membershipPaymentData"/>
      </div>
    </>
  }
}

@observer
class RenewalForm extends React.Component<{
  formHelper: FormHelper<any>
  formState: FormState<any>
  formErrors: ErrorBag
  bankFormErrorFieldName: string
  cardFormErrorFieldName: string
  onCardInfoChanged: (cardInfo: CardInfo) => void
  onBankInfoChanged: (bankInfo: BankInfo) => void
}> {
  @observable
  private bankAccountFormState = new FormState<BankInfo>({
    routingNumber: '',
    accountNumber: '',
    nameOnAccount: '',
    accountType: 'checking'
  })

  @observable
  private bankAccountFormErrors = new ErrorBag()

  private bankAccountFormHelper = new FormHelper(this.bankAccountFormState, this.bankAccountFormErrors)

  @observable
  private cardInfo?: CardInfo

  @observable
  private creditCardFormErrors = new ErrorBag()

  render (): React.ReactNode {
    const { formHelper, formState } = this.props

    return <>
      <div className="form-row">
        <div className="col-sm-6">
          {formHelper.renderSelectInput({
            label: 'Renewal Method',
            name: 'renewal.renewalMethod',
            options: [
              { value: 'Automatic', text: 'Automatic Renewal' },
              { value: 'Manual', text: 'Manual Renewal' },
            ],
          })}
        </div>
        {formState.get('renewal.renewalMethod') === 'Automatic' &&
        <div className="col-sm-6">
          {formHelper.renderSelectInput({
            label: 'Payment Method',
            name: 'renewal.paymentMethodType',
            options: [
              { value: 'same', text: 'Same As Above' },
              { value: 'CreditCard', text: 'Credit Card' },
              { value: 'BankAccount', text: 'Bank Account' },
            ],
          })}
        </div>}
      </div>
      {formState.get('renewal.renewalMethod') === 'Automatic' && <>
        {formState.get('renewal.paymentMethodType') === 'CreditCard' &&
        <form method="post" action="#" acceptCharset="UTF-8" onSubmit={ev => ev.preventDefault()}>
          <div className="form-row">
            <div className="col-md-6">
              <label>Card Info</label>
              <CreditCardInput
                onChange={(cardInfo) => {
                  this.cardInfo = cardInfo
                  this.props.onCardInfoChanged(cardInfo)
                }}
              />
              <FormError errors={this.props.formErrors} fieldName={this.props.cardFormErrorFieldName}/>
            </div>
          </div>
        </form>
        }
        {formState.get('renewal.paymentMethodType') === 'BankAccount' &&
        <form method="post" action="#" acceptCharset="UTF-8" onSubmit={ev => ev.preventDefault()}>
          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group">
                {this.bankAccountFormHelper.renderSelectInput({
                  label: 'Account Type',
                  options: [
                    { value: 'checking', text: 'Checking Account' },
                    { value: 'savings', text: 'Savings Account' },
                  ],
                  name: 'bankAccountType',
                  onChange: () => this.props.onBankInfoChanged(this.bankAccountFormState.toObject()),
                })}
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group">
                {this.bankAccountFormHelper.renderTextInput({
                  label: 'Routing Number',
                  type: 'text',
                  name: 'routingNumber',
                  onChange: () => this.props.onBankInfoChanged(this.bankAccountFormState.toObject()),
                })}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                {this.bankAccountFormHelper.renderTextInput({
                  label: 'Account Number',
                  type: 'text',
                  name: 'accountNumber',
                  onChange: () => this.props.onBankInfoChanged(this.bankAccountFormState.toObject()),
                })}
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                {this.bankAccountFormHelper.renderTextInput({
                  label: 'Name On Account',
                  type: 'text',
                  name: 'nameOnAccount',
                  onChange: () => this.props.onBankInfoChanged(this.bankAccountFormState.toObject()),
                })}
              </div>
            </div>
          </div>
          <FormError errors={this.props.formErrors} fieldName={this.props.bankFormErrorFieldName}/>
        </form>
        }
      </>}
    </>
  }
}

type ConfirmNameModalProps = {
  onClosed: () => void
  onConfirmed: () => void
  onFieldChanged: (field: string, value: string) => void
  fields: {
    firstName: string
    lastName: string
  }
}

@observer
class ConfirmNameModal extends React.Component<ConfirmNameModalProps> {
  @observable private show = true

  private toggle = () => {
    this.show = false
  }

  render () {
    return <Modal isOpen={this.show} onClosed={this.props.onClosed} size="md">
      <ModalHeader toggle={this.toggle}>
        Confirm Name
      </ModalHeader>
      <ModalBody>
        <p>
          Please confirm the spelling of your name before submitting your application
        </p>
        <div className="row">
          <div className="col-6">
            <label>First Name</label>
            <input
              className="form-control"
              type="text"
              value={this.props.fields.firstName}
              onChange={ev => this.props.onFieldChanged('firstName', ev.target.value)}
            />
          </div>
          <div className="col-6">
            <label>Last Name</label>
            <input
              className="form-control"
              type="text"
              value={this.props.fields.lastName}
              onChange={ev => this.props.onFieldChanged('lastName', ev.target.value)}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
        <Button type="button" color="primary" onClick={() => {
          this.props.onConfirmed()
          this.toggle()
        }}>Confirm Name</Button>
      </ModalFooter>
    </Modal>
  }
}
