import * as React from "react"
import Chapter from "../../models/Chapter"
import { observer } from "mobx-react"
import TipsWidget from "./TipsWidget"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import { route } from "../../routes/routes"
import TopTippersWidget from "./TopTippersWidget"
import { autorun, computed, observable } from "mobx"
import TipsChart from "../TipsChart"
import moment from "moment-timezone"
import RankWidget from "../RankWidget"
import LazyResourcePanel from "../LazyResourcePanel"
import { createLazyResource, modelToCamelCase } from "../../common/Util"

type ChapterRankData = {
  area: {
    id: number
    name: string
  }

  rank: {
    memberSizeRankByArea: number
    memberSizeRankOverall: number
    tipRankByArea: number
  }
}

@observer
export default class ChapterDashboard extends React.Component<{
  chapter: Chapter,
}> {
  @observable private summaryPeriod = 'month'
  @observable private chartPeriod: string = 'past_12_months'
  @observable private chapterRank = createLazyResource<ChapterRankData>(
    () => ApiClient.getInstance().get(route(ApiRoutes.chapters.rank, { id: this.props.chapter.id }, { start_date: this.tipChartRange.start.format('YYYY-MM-DD'), end_date: this.tipChartRange.end.format('YYYY-MM-DD') })),
    response => modelToCamelCase(response.data) as ChapterRankData
  )

  @computed get tipChartRange () {
    if (this.chartPeriod === 'this_year') {
      return { start: moment().startOf('year'), end: moment().endOf('year') }
    } else if (this.chartPeriod === 'last_year') {
      return { start: moment().subtract(1, 'year').startOf('year'), end: moment().subtract(1, 'year').endOf('year') }
    } else {
      return { start: moment().startOf('month').subtract(11, 'month'), end: moment().endOf('month') }
    }
  }

  private previousTipChartRange?: any

  componentDidMount (): void {
    autorun(() => {
      if (this.tipChartRange !== this.previousTipChartRange) {
        this.previousTipChartRange = this.tipChartRange
        this.chapterRank.invalidate()
      }
    })
  }

  render (): React.ReactNode {
    const chapter = this.props.chapter

    return <>
      <div className="row">
        <div className="col-7">
          <div className="d-flex">
            <div className="flex-fill">
              <h4>{chapter.name}</h4>
            </div>
            <div>
              <h5 className="text-primary">{chapter.numberOfActiveMembers} Active Members</h5>
            </div>
          </div>

          <LazyResourcePanel resource={this.chapterRank}>
            {chapterRank => <>
              <div className="row mb-4 mt-2">
                <div className="col-4">
                  <RankWidget
                    title="Member Size Rank By Area"
                    rank={chapterRank.rank.memberSizeRankByArea}
                    classes={['bg-primary']}
                    label={chapterRank.area.name}
                  />
                </div>
                <div className="col-4">
                  <RankWidget
                    title="Member Size Rank Overall"
                    rank={chapterRank.rank.memberSizeRankOverall}
                    classes={['bg-warning']}
                    label="Network Wide"
                  />
                </div>
                <div className="col-4">
                  <RankWidget
                    title="Tip Rank By Area"
                    rank={chapterRank.rank.tipRankByArea}
                    classes={['bg-info']}
                    label={chapterRank.area.name}
                  />
                </div>
              </div>
            </>}
          </LazyResourcePanel>

          <div className="d-flex">
            <div className="flex-fill">
              <h6>Chapter Tips Passed</h6>
            </div>
            <div>
              <select
                className="form-control"
                value={this.chartPeriod}
                onChange={ev => this.chartPeriod = ev.target.value}
              >
                <option value={'past_12_months'}>Past 12 Months</option>
                <option value={'this_year'}>This Year</option>
                <option value={'last_year'}>Last Year</option>
              </select>
            </div>
          </div>

          <TipsChart
            chapterId={chapter.id}
            dateRange={this.tipChartRange}
          />
        </div>
        <div className="col-5">
          <div className="chapter-sidebar">
            <h5>Summary</h5>
            <TipsWidget
              type="Passed"
              showValue={true}
              period={this.summaryPeriod}
              onPeriodChange={period => this.summaryPeriod = period}
              load={(type, period) => ApiClient.getInstance().get(route(ApiRoutes.tips.stats, {}, { type: `chapterCount`, chapter: chapter.id, view: type === 'Passed' ? 'sent' : 'received', period: period }))}
            />

            <h5>Top Tippers</h5>
            <TopTippersWidget
              period={this.summaryPeriod}
              load={period => {
                return ApiClient.getInstance().get(route(ApiRoutes.chapters.topTippers, { id: chapter.id }, { period: period }))
              }}
            />
          </div>
        </div>
      </div>
    </>
  }
}