import * as React from "react"
import BaseView from "./BaseView"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { Link, Redirect, RouteComponentProps } from "react-router-dom"
import { observable } from "mobx"
import { observer } from "mobx-react"
import AppStateStore from "../stores/AppStateStore"
import ApiClient, { apiRoute, ApiRoutes } from "../api/ApiClient"
import { modelToCamelCase } from "../common/Util"
import { BarLoader } from "react-spinners"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import classNames from 'classnames'
import { createBrowserHistory } from 'history'
import * as _ from 'lodash'
import Chapter from "../models/Chapter"
import LazyResource from "../models/LazyResource"
import ChapterGeneralView from "../components/ChapterGeneralView"
import LazyResourcePanel from "../components/LazyResourcePanel"
import ChapterPositionsForm from "../components/ChapterPositionsForm"
import LazyTabPane from "../components/LazyTabPane"
import ChapterMemberPositionsView from "../components/ChapterMemberPositionsView"
import SearchableMemberList, { MemberListColumn, SearchableMemberListRowType } from "../components/SearchableMemberList"
import BackLink from "../components/BackLink"
import ManageProgram from "../components/chapter/ManageProgram"
import DocumentLibrary from "../components/DocumentLibrary"
import { LibraryOwnerType, LibraryType } from "../models/DocumentLibraryItem"
import NotesList from "../components/NotesList"
import ManageChapterSettings from "../components/system/chapters/ManageChapterSettings"
import ChapterBilling from "../components/ChapterBilling"
import EventBus, { EventBusContext } from "../common/EventBus"
import ChapterApplications from "../components/chapter/ChapterApplications"
import ManageChapterFees from '../components/chapter/ManageChapterFees'
import EmailChapterButton from '../components/EmailChapterButton'
import AuthStore from '../stores/AuthStore'
import ChapterIncomingPositionsForm from "../components/ChapterIncomingPositionsForm"
import ArchivedBoardMembersView from "../components/ArchivedBoardMembersView"

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

const TABS = {
  general: 'general',
  roster: 'roster',
  board: 'board',
  upcomingBoard: 'upcomingBoard',
  archivedBoardMembers: 'archivedBoardMembers',
  tips: 'tips',
  files: 'files',
  applications: 'applications',
  status: 'status',
  program: 'program',
  fees: 'fees',
  billing: 'billing',
}

@observer
export default class ChapterDetailView extends BaseView<Props & RouteComponentProps> {
  @observable private redirect = false
  @observable private chapter = new LazyResource<Chapter>((callback, error) => {
    ApiClient.chapters.show(this.props.match.params.id)
      .then(response =>
        {return callback(new Chapter(modelToCamelCase(response.data.chapter)))})
      .catch(() => {
        error('There was an error loading the chapter data')

        AppStateStore.showAlertModal('Error', 'There was an error loading the chapter data', m => {
          m.hide()
          this.redirect = true
        })
      })
  })

  @observable private activeTab = TABS.general

  private eventBus = new EventBus()

  private history = createBrowserHistory()

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>Chapter Detail{this.chapter.current && ` for ${this.chapter.current.name}`}</h1>
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    this.activeTab = _.get(this.history.location.state, 'activeTab') || TABS.general

    if (!this.props.match.params.id) {
      this.redirect = true
    }
  }

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(window.location.pathname, { ...this.history.location.state, activeTab: this.activeTab })
    }
  }

  render (): React.ReactNode {
    return this.redirect
      ? this.renderRedirect()
      : !this.chapter.current || !this.chapter.loaded ? this.renderLoading() : this.renderContent()
  }

  invalidateChapter = () => {
    this.chapter.invalidate().then(() => {
    })
    this.eventBus.dispatch('chapter-invalidated')
  }

  private renderLoading = () => <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>

  private renderContent = () => {
    const chapter = this.chapter.current

    return chapter ? (
      <>
        <EventBusContext.Provider value={{ eventBus: this.eventBus }}>
          <div className="card">
            <div className="card-header">
              <Nav tabs className="card-header-tabs">
                <NavItem className={classNames({ active: this.activeTab === TABS.general })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.general)
                    }}
                  >General</NavLink>
                </NavItem>
                <NavItem className={classNames({ active: this.activeTab === TABS.roster })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.roster)
                    }}
                  >Roster</NavLink>
                </NavItem>
                <NavItem className={classNames({ active: this.activeTab === TABS.board })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.board)
                    }}
                  >Board/Chair</NavLink>
                </NavItem>
                <NavItem className={classNames({ active: this.activeTab === TABS.upcomingBoard })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.upcomingBoard)
                    }}
                  >Incoming Board</NavLink>
                </NavItem>
                <NavItem className={classNames({ active: this.activeTab === TABS.archivedBoardMembers })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.archivedBoardMembers)
                    }}
                  >Archived Board Members</NavLink>
                </NavItem>
                <NavItem className={classNames({ active: this.activeTab === TABS.tips })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.tips)
                    }}
                  >Tips</NavLink>
                </NavItem>
                <NavItem className={classNames({ active: this.activeTab === TABS.files })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.files)
                    }}
                  >Files/Notes</NavLink>
                </NavItem>
                <NavItem className={classNames({ active: this.activeTab === TABS.applications })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.applications)
                    }}
                  >Applications</NavLink>
                </NavItem>
                <NavItem className={classNames({ active: this.activeTab === TABS.status })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.status)
                    }}
                  >Chapter Status</NavLink>
                </NavItem>
                <NavItem className={classNames({ active: this.activeTab === TABS.program })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.program)
                    }}
                  >Program</NavLink>
                </NavItem>
                <NavItem className={classNames({ active: this.activeTab === TABS.fees })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.fees)
                    }}
                  >Dues/Fees</NavLink>
                </NavItem>
                <NavItem className={classNames({ active: this.activeTab === TABS.billing })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.billing)
                    }}
                  >Billing</NavLink>
                </NavItem>
              </Nav>
            </div>
            <div className="card-body">
              <TabContent activeTab={this.activeTab}>
                <LazyTabPane tabId={TABS.general} activeTab={this.activeTab}>
                  <LazyResourcePanel resource={this.chapter} hideWhenEmpty={true}>
                    {chapter => <ChapterGeneralView chapter={chapter} onChapterInvalidated={() => this.invalidateChapter()}/>}
                  </LazyResourcePanel>
                </LazyTabPane>
                <TabPane tabId={TABS.roster}>
                  <LazyResourcePanel resource={this.chapter} hideWhenEmpty={true}>
                    {chapter => <ChapterMemberPositionsView chapter={chapter}/>}
                  </LazyResourcePanel>
                </TabPane>
                <LazyTabPane tabId={TABS.board} activeTab={this.activeTab}>
                  <LazyResourcePanel resource={this.chapter} hideWhenEmpty={true}>
                    {chapter => <ChapterPositionsForm chapter={chapter} onChapterInvalidated={() => this.invalidateChapter()}/>}
                  </LazyResourcePanel>
                </LazyTabPane>
                <LazyTabPane tabId={TABS.upcomingBoard} activeTab={this.activeTab}>
                  <LazyResourcePanel resource={this.chapter} hideWhenEmpty={true}>
                    {chapter => <ChapterIncomingPositionsForm chapter={chapter} onChapterInvalidated={() => this.invalidateChapter()}/>}
                  </LazyResourcePanel>
                </LazyTabPane>
                <LazyTabPane tabId={TABS.archivedBoardMembers} activeTab={this.activeTab}>
                  <LazyResourcePanel resource={this.chapter} hideWhenEmpty={true}>
                    {chapter => <ArchivedBoardMembersView chapter={chapter} />}
                  </LazyResourcePanel>
                </LazyTabPane>
                <LazyTabPane tabId={TABS.tips} activeTab={this.activeTab}>
                </LazyTabPane>
                <LazyTabPane tabId={TABS.files} activeTab={this.activeTab}>
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <DocumentLibrary
                        libraryDescriptor={{
                          libraryOwnerType: LibraryOwnerType.Chapter,
                          libraryOwnerId: chapter.id,
                          libraryType: LibraryType.ChapterDocumentLibrary,
                        }}
                      />
                    </div>
                    <div className="col-md-6 col-12">
                      <NotesList chapterId={chapter.id}/>
                    </div>
                  </div>
                </LazyTabPane>
                <LazyTabPane tabId={TABS.applications} activeTab={this.activeTab}>
                  <ChapterApplications chapterId={chapter.id}/>
                </LazyTabPane>
                <LazyTabPane tabId={TABS.status} activeTab={this.activeTab}>
                  <ManageChapterSettings chapterId={chapter.id} onChapterInvalidated={() => this.invalidateChapter()}/>
                </LazyTabPane>
                <LazyTabPane tabId={TABS.program} activeTab={this.activeTab}>
                  <ManageProgram chapter={chapter}/>
                </LazyTabPane>
                <LazyTabPane tabId={TABS.fees} activeTab={this.activeTab}>
                  <ManageChapterFees chapterId={chapter.id} onChapterInvalidated={() => this.invalidateChapter()}/>
                </LazyTabPane>
                <LazyTabPane tabId={TABS.billing} activeTab={this.activeTab}>
                  <ChapterBilling chapterId={chapter.id} onChapterInvalidated={() => this.invalidateChapter()}/>
                </LazyTabPane>
              </TabContent>
            </div>
          </div>

          <TabContent activeTab={this.activeTab}>
            <LazyTabPane activeTab={this.activeTab} tabId={TABS.roster}>
              <div className="card">
                <div className="card-header">
                  <div className="d-flex">
                    <div className="flex-fill">
                      <h5>{chapter.name} Members</h5>
                    </div>
                    <div>
                      <EmailChapterButton chapterId={chapter.id} className='mr-1'/>
                      <a
                        target="_blank"
                        href=""
                        className="btn btn-primary"
                        onClick={ev => {
                          ev.preventDefault()

                          const url = apiRoute(route(ApiRoutes.printChapterRoster, {chapterId: chapter.id}), {
                            token: AuthStore.generateTempAuthToken(),
                          })

                          const link = document.createElement('a')
                          link.setAttribute('target', '_blank')
                          link.setAttribute('href', url)
                          link.style.visibility = 'hidden'
                          document.body.appendChild(link)
                          link.click()
                          link.remove()
                        }}
                      >Print Roster</a>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <SearchableMemberList
                    hideActions={true}
                    allowEntireNetwork={false}
                    filters={{
                      chapter: chapter.id,
                      showInactive: false,
                    }}
                    columns={[
                      MemberListColumn.Name,
                      MemberListColumn.Category,
                      MemberListColumn.Position,
                      MemberListColumn.BadgeColor,
                      MemberListColumn.Actions,
                    ]}
                    additionalColumns={[
                      MemberListColumn.JoinDate,
                      MemberListColumn.NTS,
                      MemberListColumn.PhoneNumber,
                      MemberListColumn.EmailAddress,
                    ]}
                    allowExport={true}
                    showColumnChooser={true}
                    actionsComponent={(row: SearchableMemberListRowType) => {
                      return <div className="table-actions">
                        <Link type="link" to={{ pathname: route(Routes.system.members.show, { id: row.original.id }), state: { id: row.original.id } }}><i className="fa fa-search"/></Link>
                      </div>
                    }}
                  />
                </div>
              </div>
            </LazyTabPane>
          </TabContent>
        </EventBusContext.Provider>
      </>
    ) : null
  }

  private renderRedirect = () =>
    <Redirect to={route(Routes.system.chapters.index)}/>
}
