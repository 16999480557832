import { observer } from 'mobx-react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import React from 'react'
import { observable, toJS } from 'mobx'
import Util from '../../common/Util'
import ApiClient, { ApiRoutes } from '../../api/ApiClient'
import { route } from '../../routes/routes'
import LoadingPanel from '../LoadingPanel'
import _ from 'lodash'

type RenewalsData = {
  renewalsCount: {
    thisMonthTotal: number
    thisMonthPaid: number
    thisMonthOpen: number
    lastMonthTotal: number
    lastMonthPaid: number
    lastMonthOpen: number
    terminating: number
  }
}

type Props = {
  filters: {
    state?: string
    areaId?: number
    chapterId?: number
  }
  onShowRenewalsList: (url: string, title: string) => void
}

@observer
export default class RenewalsDashboard extends React.Component<Props> {
  @observable
  private renewals?: RenewalsData
  @observable
  private error?: string
  @observable
  private loading = false

  componentDidMount () {
    this.loadData().then()
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (!_.isEqual(toJS(prevProps.filters), toJS(this.props.filters))) {
      this.loadData().then()
    }
  }

  private loadData = async () => {
    try {
      this.error = undefined
      this.loading = true

      const response = await ApiClient.getInstance().get(route(ApiRoutes.dashboard.renewals, {}, this.props.filters))

      this.renewals = response.data

      this.loading = false
    } catch (err) {
      this.error = Util.extractErrorMessage(err.response)
    }
  }

  render () {
    return <Card>
      <CardHeader>
        <b>Membership Renewals</b>
      </CardHeader>
      <CardBody>
        <LoadingPanel loading={this.loading} loaded={!!this.renewals} error={this.error}>
          {
            this.renewals
              ? <>
                <div className="d-flex">
                  <div className="flex-fill">
                    This month total
                  </div>
                  <div className="text-right">
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.props.onShowRenewalsList(route(ApiRoutes.dashboard.renewals, {}, {
                        ...this.props.filters,
                        list: 'thisMonthTotal',
                      }), 'Renewals This Month')
                    }}>
                    {Util.formatNumber(this.renewals!.renewalsCount.thisMonthTotal)}
                    </a>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="flex-fill">
                    Paid to date
                  </div>
                  <div className="text-right">
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.props.onShowRenewalsList(route(ApiRoutes.dashboard.renewals, {}, {
                        ...this.props.filters,
                        list: 'thisMonthPaid',
                      }), 'Renewals Paid This Month')
                    }}>
                    {Util.formatNumber(this.renewals!.renewalsCount.thisMonthPaid)}
                    </a>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="flex-fill">
                    Open
                  </div>
                  <div className="text-right">
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.props.onShowRenewalsList(route(ApiRoutes.dashboard.renewals, {}, {
                        ...this.props.filters,
                        list: 'thisMonthOpen',
                      }), 'Renewals Open This Month')
                    }}>
                      {Util.formatNumber(this.renewals!.renewalsCount.thisMonthOpen)}
                    </a>
                  </div>
                </div>
                <div className="d-flex mt-4">
                  <div className="flex-fill">
                    Last Month Total
                  </div>
                  <div className="text-right">
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.props.onShowRenewalsList(route(ApiRoutes.dashboard.renewals, {}, {
                        ...this.props.filters,
                        list: 'lastMonthTotal',
                      }), 'Renewals Last Month')
                    }}>
                    {Util.formatNumber(this.renewals!.renewalsCount.lastMonthTotal)}
                    </a>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="flex-fill">
                    Paid to date
                  </div>
                  <div className="text-right">
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.props.onShowRenewalsList(route(ApiRoutes.dashboard.renewals, {}, {
                        ...this.props.filters,
                        list: 'lastMonthPaid',
                      }), 'Renewals Paid Last Month')
                    }}>
                    {Util.formatNumber(this.renewals!.renewalsCount.lastMonthPaid)}
                    </a>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="flex-fill">
                    Open
                  </div>
                  <div className="text-right">
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.props.onShowRenewalsList(route(ApiRoutes.dashboard.renewals, {}, {
                        ...this.props.filters,
                        list: 'lastMonthOpen',
                      }), 'Open')
                    }}>
                      {Util.formatNumber(this.renewals!.renewalsCount.lastMonthOpen)}
                    </a>
                  </div>
                </div>
                <div className="d-flex mt-4">
                  <div className="flex-fill">
                    Terminating next month
                  </div>
                  <div className="text-right">
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.props.onShowRenewalsList(route(ApiRoutes.dashboard.renewals, {}, {
                        ...this.props.filters,
                        list: 'terminating',
                      }), 'Terminations next month for non-payment of renewal')
                    }}>
                      {Util.formatNumber(this.renewals!.renewalsCount.terminating)}
                    </a>
                  </div>
                </div>
              </>
              : null
          }
        </LoadingPanel>
      </CardBody>
    </Card>
  }
}
