import BaseView from "./BaseView"
import { observer } from "mobx-react"
import * as React from "react"
import BackLink from "../components/BackLink"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { observable } from "mobx"
import { BarLoader } from "react-spinners"
import { LinkContainer } from "react-router-bootstrap"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import Util, { formatCurrency, safeNull } from "../common/Util"
import { Redirect } from "react-router-dom"
import AuthStore from "../stores/AuthStore"
import AppStateStore from "../stores/AppStateStore"
import { ApproveCategoryChangeApplicationModal } from '../components/ApproveCategoryChangeApplicationModal'
import CategoryChangeRequest from '../models/CategoryChangeRequest'

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

@observer
export default class CategoryChangeRequestDetailView extends BaseView<Props> {
  @observable private categoryChangeRequest: CategoryChangeRequest
  @observable private error: string
  @observable private redirect: string

  @observable private isApproveModalVisible = false
  @observable private isDeclineModalVisible = false

  componentDidMount (): void {
    super.componentDidMount()

    ApiClient.query(`
categoryChangeRequest {
  *

  member {
    id
    fullName

    chapter {
      id
      name
    }
  }

  oldCategory {
    id
    name
  }

  category {
    id
    name
  }

  paymentMethod {
    *
  }
}
    `, {
      where: [{ id: this.props.match.params.id }]
    }).then(response => this.categoryChangeRequest = new CategoryChangeRequest().init(response.data.categoryChangeRequest))
      .catch(error => {
        this.error = error.response.status === 404 ? 'Category change request not found' : 'There was an error loading the category change request'
      })
  }

  private declineApplication = () => {
    this.isDeclineModalVisible = false

    ApiClient.getInstance().post(route(ApiRoutes.categoryChangeRequests.deny, { id: this.categoryChangeRequest.id }))
      .then(response => this.redirect = route(Routes.system.applications.index, { tab: 'category-change' }))
      .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>Category Change Request</h1>
        {this.categoryChangeRequest && <ul className="content-header-actions">
          <li>
            <LinkContainer to={route(Routes.system.members.show, { id: this.categoryChangeRequest.member.id })}>
              <Button color="primary" onClick={() => {
              }}>View Member Profile</Button>
            </LinkContainer>
          </li>
        </ul>}
      </>
    )
  }

  renderContentBody (): React.ReactNode | null {
    return this.redirect
      ? <Redirect to={this.redirect}/>
      : this.error
        ? <div className="alert alert-danger">{this.error}</div>
        : this.categoryChangeRequest ? this.renderCategoryChangeRequest(this.categoryChangeRequest) : <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
  }

  private renderCategoryChangeRequest = (categoryChangeRequest: CategoryChangeRequest) => {
    return <div className="application-detail">
      {!categoryChangeRequest.isProcessed && <>
        <div className="row mt-4">
          <div className="col-md-6 col-sm-12">
            <Button
              block
              onClick={() => this.isDeclineModalVisible = true}
              color="danger">
              Decline Application
            </Button>
          </div>
          <div className="col-md-6 col-sm-12">
            <Button
              block
              onClick={() => this.isApproveModalVisible = true}
              color="success">
              Approve Application
            </Button>
          </div>
        </div>
        <br/>
      </>}

      <SectionHeader number={1} title="Member Info"/>

      <div className="form-row">
        <div className="col-sm-12 col-md-6">
          <label>Name</label>
          {safeNull(() => categoryChangeRequest.member.fullName) || '---'}
        </div>
        <div className="col-sm-12 col-md-6">
          <label>Chapter</label>
          {safeNull(() => categoryChangeRequest.chapter.name) || '---'}
        </div>
      </div>

      <SectionHeader number={2} title="Category Change"/>

      <div className="form-row">
        <div className="col-sm-12 col-md-6">
          <label>Current Category</label>
          {safeNull(() => categoryChangeRequest.oldCategory.name) || '---'}
        </div>
        <div className="col-sm-12 col-md-6">
          <label>New Category</label>
          {safeNull(() => categoryChangeRequest.category.name) || '---'}
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-12 col-md-6">
          <label>Business Name</label>
          {safeNull(() => categoryChangeRequest.businessName) || '---'}
        </div>
        <div className="col-sm-12 col-md-6">
          <label>Email Address</label>
          {safeNull(() => categoryChangeRequest.emailAddress) || '---'}
        </div>
      </div>

      <SectionHeader number={3} title="Payment Information"/>

      <div className="form-row">
        <div className="col-sm-12">
          {safeNull(() => categoryChangeRequest.paymentMethod.description) || '---'}
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-6"/>
        <div className="col-md-6">
          <div className="callout-box text-right">
            <span style={{ fontSize: 16 }}>
              Total Due: {formatCurrency(categoryChangeRequest.categoryChangeFee)} USD
              {categoryChangeRequest.taxRate ? ' (plus tax)' : ''}
            </span>
          </div>
        </div>
      </div>

      <SectionHeader number={4} title="Acceptance and Signature"/>

      <div className="application-subheader">Application Agreement and Signature</div>

      <div>
        <img src={`${categoryChangeRequest.applicationSignatureImageUrl}?token=${AuthStore.generateTempAuthToken()}`}/>
      </div>

      {!categoryChangeRequest.isProcessed && <>
        <div className="row mt-4">
          <div className="col-md-6 col-sm-12">
            <Button
              block
              onClick={() => this.isDeclineModalVisible = true}
              color="danger">
              Decline Application
            </Button>
          </div>
          <div className="col-md-6 col-sm-12">
            <Button
              block
              onClick={() => this.isApproveModalVisible = true}
              color="success">
              Approve Application
            </Button>
          </div>
        </div>
      </>}

      <ApproveCategoryChangeApplicationModal
        isOpen={this.isApproveModalVisible}
        toggle={() => this.isApproveModalVisible = !this.isApproveModalVisible}
        onCancel={() => this.isApproveModalVisible = false}
        onSaved={memberId => this.redirect = route(Routes.system.applications.index, { tab: 'category-change' })}
        categoryChangeRequest={categoryChangeRequest}/>

      <Modal
        isOpen={this.isDeclineModalVisible}
        size="md"
      >
        <ModalHeader
          toggle={() => this.isDeclineModalVisible = false}
        >
          Decline Application
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you want to decline this application? The applicant will be notified and no payment will be collected.</p>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => this.isDeclineModalVisible = false}
            color="secondary">
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={() => this.declineApplication()}
          >
            Decline Application
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  }
}

@observer
class SectionHeader extends React.Component
  <{
    number: number,
    title: string,
  }> {

  render (): React.ReactNode {
    return <div className="application-section-header">
      <div className="application-section-number">{this.props.number}</div>
      <div className="application-section-title">{this.props.title}</div>
    </div>
  }
}
