import CsvTransformer from "./CsvTransformer"
import Transaction from "../Transaction"
import moment from "moment-timezone"
import { safeNull } from "../../common/Util"

export default class TransactionsCsvTransformer extends CsvTransformer<Transaction> {
  get filename () {
    return `transactions-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'id',
      valueTransformer: row => String(row.id),
    })

    this.addColumn({
      header: 'Date',
      valueTransformer: row => row.createdAt.format('MM/DD/YYYY HH:mm'),
    })

    this.addColumn({
      header: 'Description',
      valueTransformer: row => row.description,
    })

    this.addColumn({
      header: 'Type',
      valueTransformer: row => row.transactionType,
    })

    this.addColumn({
      header: 'Amount',
      valueTransformer: row => row.amount,
    })

    this.addColumn({
      header: 'Status',
      valueTransformer: row => row.status,
    })

    this.addColumn({
      header: 'Transaction Ref',
      valueTransformer: row => `${row.provider} ${row.externalTransactionId}`,
    })

    this.addColumn({
      header: 'Original Transaction Id',
      valueTransformer: row => row.parentTransactionId,
    })

    this.addColumn({
      header: 'Member ID',
      valueTransformer: row => row.memberId || '',
    })

    this.addColumn({
      header: 'Member Name',
      valueTransformer: row => row.member ? row.member.fullName : '',
    })

    this.addColumn({
      header: 'Chapter',
      valueTransformer: row => safeNull(() => row.invoice!.chapter!.name) || '',
    })

    this.addColumn({
      header: 'Area',
      valueTransformer: row => row.member
        ? safeNull(() => row.member!.chapter!.area!.name)
        : row.chapter
          ? safeNull(() => row.chapter!.area!.name)
          : '---',
    })

    this.addColumn({
      header: 'Invoice ID',
      valueTransformer: row => row.invoice ? row.invoice.invoiceNumber : '',
    })

    this.addColumn({
      header: 'Invoice Description',
      valueTransformer: row => row.invoice ? row.invoice.description : '',
    })
  }
}
