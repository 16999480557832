import * as React from "react"
import ApiClient, { ApiRoutes, QueryWhereClause } from "../api/ApiClient"
import { observer } from "mobx-react"
import { observable } from "mobx"
import BaseView from "./BaseView"
import { Routes } from "../routes/AppRoutes"
import { route } from "../routes/routes"
import { Link } from "react-router-dom"
import classNames from 'classnames'
import Member from "../models/Member"
import { ApiTableViewAdapter } from "../components/table-view/ApiTableViewAdapter"
import { ManagedChooseableColumn } from "../components/table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "../components/table-view/ManagedTableView";
import AsyncSelectFilter, { AsyncSelectFilterTargetType } from '../components/table-view/filters/AsyncSelectFilter'
import { loadChapterOptionsForAdmin } from '../api/AsyncHelpers'
import TextFilter, { TextFilterTargetType } from '../components/table-view/filters/TextFilter'
import SponsorshipCreditsCsvTransformer from '../models/renderers/SponsorshipCreditsCsvTransformer'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import AppStateStore from '../stores/AppStateStore'
import Util from '../common/Util'
import { toast } from 'react-toastify'
import SelectFilter, { SelectFilterTargetType } from '../components/table-view/filters/SelectFilter'

@observer
export default class SponsorshipCreditsView extends BaseView {
  @observable issueMember: Member | undefined
  @observable showIssueModal = false
  @observable sendRenewalInvoice = true

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>Sponsorship Credits</h1>
      </>
    )
  }

  @observable availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'member',
      column: {
        title: 'Member',
        accessor: 'fullName',
        sortKey: 'lastName',
        renderItem: (value, item) => <Link type="link" to={{ pathname: route(Routes.system.members.show, { id: item.id }), state: { id: item.id } }}>
          <div className={classNames({ 'text-muted': item.isDisabled() })}>
            {value}{item.isDisabled() && ` (${item.status})`}
          </div>
        </Link>,
        sortable: true,
      },
      fixed: true,
    },
    {
      id: 'chapter',
      column: {
        title: 'Chapter',
        accessor: 'chapter.name',
        sortable: true,
        renderItem: (value, item) => item.chapter ? <Link type="link" to={{ pathname: route(Routes.system.chapters.show, { id: item.chapter.id }), state: { id: item.chapter.id } }}>
          {value}
        </Link> : null
      },
      default: true,
    },
    {
      id: 'category',
      column: {
        title: 'Category',
        accessor: 'category.name',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'company',
      column: {
        title: 'Company',
        accessor: 'business.name',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'numberOfSponsorCredits',
      column: {
        title: '# Credits',
        accessor: 'numberOfSponsorCredits',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'joinDate',
      column: {
        title: 'Join Date',
        accessor: 'joinDate',
        sortable: true,
        renderItem: value => value ? value.format('MM/DD/YYYY') : '---'
      },
      default: false,
    },
    {
      id: 'position',
      column: {
        title: 'Position',
        accessor: 'primaryMemberPosition.position.name',
        sortable: true,
      },
      default: false,
    },
    {
      id: 'badgeColor',
      column: {
        title: 'Badge Color',
        accessor: 'badgeColor',
        sortable: true,
      },
      default: false,
    },
    {
      id: 'ntsTraining',
      column: {
        title: 'NTS Training',
        accessor: 'ntsTrainingCompletedAt',
        sortable: true,
        renderItem: value => value ? 'Yes' : 'No'
      },
      default: false,
    },
    {
      id: 'email',
      column: {
        title: 'Email',
        accessor: 'emailAddress',
        sortable: true,
      },
      default: false,
    },
    {
      id: 'phone',
      column: {
        title: 'Phone',
        accessor: 'phoneNumber',
        sortable: true,
      },
      default: false,
    },
    {
      id: 'numberSponsored',
      column: {
        title: '# Sponsored',
        accessor: 'numberOfSponsoredMembers',
        sortable: true,
      },
      default: false,
    },
    {
      id: 'actions',
      column: {
        title: 'Actions',
        renderItem: (_, member: Member) =>
          <Button
            type="button"
            color="primary"
            onClick={() => {
              this.issueMember = member
              this.showIssueModal = true
              this.sendRenewalInvoice = true
            }}>Issue Renewal Credit</Button>
      },
      fixed: true,
    }
  ]

  private tableViewRef = React.createRef<ManagedTableView>()

  private async issueRenewalCredit (member: Member) {
    AppStateStore.showModalSpinner()
    try {
      await ApiClient.getInstance()
        .post(route(ApiRoutes.members.applySponsorCredit, { id: member.id }), {
          send_email: this.sendRenewalInvoice ? 1 : 0,
        })

      this.showIssueModal = false

      toast.success('Renewal credit issued')
      this.tableViewRef.current && this.tableViewRef.current.fetchData()
    } catch (error) {
      AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response))
    }
    AppStateStore.dismissModalSpinner()
  }

  private membersAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      where.push(
        { _scope: 'userIsAdmin' },
        { id: 'numberOfSponsorCredits', op: '>', value: 0 },
      )

      return {
        query: `
      members {
        *

        chapter {
          *
        }

        business {
          *
        }

        category {
          *
        }

        primaryMemberPosition {
          *

          position {
            *
          }
        }
      }
    `,
        where: where,
      }
    },
    'members',
    Member
  )

  renderContentBody (): React.ReactNode {
    return <>
      {this.renderTable()}
      {
        this.issueMember
          ? <Modal
            onClosed={() => this.issueMember = undefined}
            isOpen={this.showIssueModal}
            size={'lg'}
            toggle={() => this.showIssueModal = false}
          >
            <ModalHeader toggle={() => this.showIssueModal = false}>Issue Renewal Credit</ModalHeader>
            <ModalBody>
              <p>
                You are about to issue a free renewal to <b>{this.issueMember.fullName}</b>
              </p>
              <p>
                This will use up to 6 sponsor credits and extend the member's current renewal date by 1 year. A $0 invoice will be generated, and you can optionally send this invoice receipt to the member.
              </p>
              <p>
                <div className="form-check form-checkbox">
                  <label>
                    <input type="checkbox"
                           className="form-check-input"
                           name="remember"
                           checked={this.sendRenewalInvoice}
                           onChange={ev => this.sendRenewalInvoice = ev.target.checked}
                    />
                    <span className="label-text">Send renewal invoice receipt to member</span>
                  </label>
                </div>
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => this.showIssueModal = false}
                color="secondary">
                Cancel
              </Button>
              <Button
                color="success"
                onClick={() => this.issueRenewalCredit(this.issueMember!)}
              >
                Issue Renewal Credit
              </Button>
            </ModalFooter>
          </Modal>
          : null
      }
    </>
  }

  private filters = [
    new TextFilter('member', TextFilterTargetType.Field, 'fullName', 'Member Name'),
    new SelectFilter('joinMonth', SelectFilterTargetType.Scope, 'joinMonth', 'Join Month', [
      { value: '1', label: 'January' },
      { value: '2', label: 'February' },
      { value: '3', label: 'March' },
      { value: '4', label: 'April' },
      { value: '5', label: 'May' },
      { value: '6', label: 'June' },
      { value: '7', label: 'July' },
      { value: '8', label: 'August' },
      { value: '9', label: 'September' },
      { value: '10', label: 'October' },
      { value: '11', label: 'November' },
      { value: '12', label: 'December' },
    ]),
    new AsyncSelectFilter('chapter', AsyncSelectFilterTargetType.Field, 'chapter.id', 'Chapter', loadChapterOptionsForAdmin),
  ]

  private renderTable = () => {
    return <ManagedTableView
      ref={this.tableViewRef}
      stateKey="SponsorshipCredits"
      availableColumns={this.availableColumns}
      adapter={this.membersAdapter}
      searchLabel="Find A Member"
      exportTransformer={SponsorshipCreditsCsvTransformer}
      hideItemCount={true}
      defaultSort={{ sortKey: 'numberOfSponsorCredits', descending: true }}
      filters={this.filters}
    />
  }
}
