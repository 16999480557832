import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { route } from "../routes/routes"
import Util, { modelToSnakeCase } from "../common/Util"
import { toast } from "react-toastify"
import AppStateStore from "../stores/AppStateStore"
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import ButtonLoader from "./ButtonLoader"

type Props = {
  boardMemberId?: number
  memberId: number
  isOpen: boolean
  toggle: () => void
  onClosed: () => void
}

@observer
export default class RequestResignationModal extends React.Component<Props> {
  @observable private formState = new FormState({
    reason: '',
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable private submitting = false

  protected submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitting = true
    this.formErrors.clearErrors()

    ApiClient.getInstance().post(route(ApiRoutes.members.requestResignation, { id: this.props.memberId }), modelToSnakeCase({
      ...this.formHelper.toObject(),
      boardMemberId: this.props.boardMemberId,
    }))
      .then(() => {
        toast.success('Resignation request has been submitted')
        this.props.toggle()
      })
      .catch(error => {
        Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      })
      .then(() => this.submitting = false)
  }

  render () {
    return <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} onClosed={this.props.onClosed}>
      <ModalHeader toggle={this.props.toggle}>
        Resignation Request
      </ModalHeader>
      <ModalBody>
        <Alert color="info">
          This will give Corporate a notification that this member is no longer in your chapter,
          please give us 24-48 hrs to process submission
        </Alert>
        <form onSubmit={this.submit}>
          <div className="form-row">
            <div className="col-12">
              {this.formHelper.renderTextAreaInput({
                name: 'reason',
                label: 'Reason',
              })}
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <ButtonLoader
          loading={this.submitting}
          type="button"
          color="primary"
          onClick={this.submit}
        >Submit Request</ButtonLoader>
        <Button
          type="button"
          color="secondary"
          onClick={this.props.toggle}
        >Cancel</Button>
      </ModalFooter>
    </Modal>
  }
}
