import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import ButtonLoader from "../../ButtonLoader"
import { observable } from "mobx"
import FormState from "../../../common/FormState"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"
import { MemberPickerInput } from "../../inputs/MemberPickerInput"
import { route } from "../../../routes/routes"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import Util, { modelToSnakeCase } from "../../../common/Util"
import { toast } from "react-toastify"
import AppStateStore from "../../../stores/AppStateStore"

type UpdateCompanyReplacementModalProps = {
  isOpen: boolean
  toggle: () => void
  onClosed: () => void
  onSaved: () => void
  chapterId?: number
  memberId: number
}

@observer
export default class UpdateCompanyReplacementModal extends React.Component<UpdateCompanyReplacementModalProps> {
  @observable private submitting = false

  @observable private formState = new FormState({
    type: '',
    memberId: undefined,
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  componentDidMount (): void {
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitting = true

    ApiClient.getInstance()
      .post(route(ApiRoutes.members.updateCompanyReplacement, { id: this.props.memberId }), modelToSnakeCase(this.formState.toObject()))
      .then(() => {
        toast.success('Company replacement updated')
        this.props.onSaved()
      })
      .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
      .then(() => this.submitting = false)
  }

  private toggle = () => {
    !this.submitting && this.props.toggle()
  }

  render () {
    return <>
      <Modal isOpen={this.props.isOpen} toggle={this.toggle} onClosed={this.props.onClosed} size="md">
        <ModalHeader toggle={this.toggle}>
          Change Company Replacement
        </ModalHeader>
        <ModalBody>
          {
            this.formHelper.renderSelectInput({
              name: 'type',
              label: 'Replacement Type',
              options: [
                { value: 'replacing', text: 'This member is replacing someone else' },
                { value: 'replaced', text: "This member is being replaced" },
                { value: 'clear', text: "This member was not a company replacement" },
              ]
            })
          }
          {
            ['replacing', 'replaced'].indexOf(this.formState.get('type')) > -1
              ? <div className="form-group">
                <label>
                  {
                    this.formState.get('type') === 'replaced'
                      ? 'Choose the replacement member'
                      : 'Choose the member being replaced'
                  }
                </label>
                <MemberPickerInput
                  placeholder="Choose a member"
                  onChange={member => {
                    this.formState.set('memberId', member ? member.id : undefined)
                  }}
                  value={this.formState.get('memberId')}
                  allowClickableColumns={false}
                  filters={{
                    ignoreMemberId: this.props.memberId,
                    chapter: this.props.chapterId,
                    allowEntireNetwork: true,
                  }}
                />
              </div>
              : null
          }
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={this.toggle}
          >Cancel</Button>
          <ButtonLoader
            type="button"
            color="primary"
            disabled={!this.formState.get('memberId') && this.formState.get('type') !== 'clear'}
            onClick={this.submit}
            loading={this.submitting}
          >Save</ButtonLoader>
        </ModalFooter>
      </Modal>
    </>
  }
}
