import { FieldType, ResourceSpec } from "../ReportDataSource"
import Category from "../../models/Category"

export const CategoryResource: ResourceSpec = {
  collectionResourceName: 'categories',
  modelClass: Category,

  fields: [
    {
      name: 'id',
      type: FieldType.Number,
    },
    {
      name: 'name',
      type: FieldType.String,
    },
  ],
  relationships: [],
}