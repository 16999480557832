import React, { ChangeEvent } from "react";
import { observer } from "mobx-react";
import TableView, { SortDescriptor } from "./TableView";
import Config from "../../common/Config";
import { action, computed, observable, toJS } from "mobx";
import { TableViewAdapter } from "./TableViewAdapter";
import { ITableViewFilterValue, TableViewFilters, TableViewFiltersType } from "./TableViewFilters";
import { ManagedChooseableColumn, ManagedTableViewColumnChooser } from "./ManagedTableViewColumnChooser";
import _ from 'lodash';
import { logException } from "../../common/Util";
import { TableViewPagination } from "./TableViewPagination";
import { QueryWhereClause } from "../../api/ApiClient";
import CsvTransformer from "../../models/renderers/CsvTransformer";
import TableViewExportButton from "./TableViewExportButton";
import { Alert, Button } from "reactstrap";
import BaseFilter from './filters/BaseFilter'
import EventBus from '../../common/EventBus'
import Member from '../../models/Member'

type Props = {
  stateKey?: string
  adapter: TableViewAdapter
  availableColumns: ManagedChooseableColumn[]
  defaultSort?: SortDescriptor
  showSearch: boolean
  searchLabel?: string
  hideItemCount: boolean
  allowShowInactive: boolean
  showInactiveLabel: string
  header?: React.ReactNode
  tableHeader?: React.ReactNode
  customFilters?: (eventBus: EventBus) => React.ReactNode
  exportTransformer?: new () => CsvTransformer<any>
  filters: BaseFilter<any>[]
  alwaysShowFilters: boolean
  customActions?: React.ReactNode
}

const FILTER_STATE_VERSION = 1

type TableViewFilterState = {
  version: number,
  state: {[id: string]: any}
}

type TableViewState = {
  columns: string[]
  page: number
  search?: string
  filterState?: TableViewFilterState
  showInactive?: boolean
  sortDescriptor?: SortDescriptor
}

export type ManagedTableViewFilters = {
  search: string
  showInactive: boolean
}

@observer
export default class ManagedTableView extends React.Component<Props> {
  static defaultProps = {
    showSearch: true,
    hideItemCount: false,
    allowShowInactive: false,
    showInactiveLabel: 'Show Inactive',
    filters: [],
    alwaysShowFilters: false,
  }

  @observable private currentPage = 1
  @observable private numberOfPages = 0
  @observable private filters?: TableViewFiltersType
  @observable private filterState?: ITableViewFilterValue[]
  @observable private visibleColumns = ManagedTableViewColumnChooser.getInitialColumns(this.props.availableColumns)
  @observable private showInactive = false
  @observable private _sortDescriptor?: SortDescriptor
  @observable private _showAdvancedFilters: boolean = false

  @computed
  private get showAdvancedFilters () {
    return this.props.alwaysShowFilters || this._showAdvancedFilters
  }

  @computed
  private get visibleFilters () {
    return this.props.filters.filter(f => !f.isExternal)
  }

  @computed
  private get sortDescriptor () {
    return this._sortDescriptor || this.props.defaultSort
  }

  @computed
  get tableViewFilters (): ManagedTableViewFilters | undefined {
    return this.filters
      ? {
        search: this.filters.search,
        showInactive: this.showInactive,
      }
      : undefined
  }

  @computed
  private get transformedFilters () {
    return this.filters ? {
      search: this.filters.search,
    } : {
      search: '',
    }
  }

  @computed
  private get activeFilters (): BaseFilter<any>[] {
    return this.props.filters.filter(f => f.isActive)
  }

  get stateKey () {
    return this.props.stateKey ? `ManagedTableViewState_${this.props.stateKey}` : undefined
  }

  private tableViewRef = React.createRef<TableView>()

  private filterEventBus = new EventBus()

  componentDidMount (): void {
    this.restoreState()

    this.props.adapter.eventBus.on('fetched', this.onFetched)
    this.filterEventBus.on('changed', this.onFiltersChanged)
    this.filterEventBus.on('shouldSubmit', this.onFiltersShouldSubmit)
  }

  componentWillUnmount (): void {
    this.props.adapter.eventBus.remove(this.onFetched)

    this.filterEventBus.remove(this.onFiltersChanged)
    this.filterEventBus.remove(this.onFiltersShouldSubmit)
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.stateKey !== this.props.stateKey) {
      this.restoreState()
    }
  }

  private onFiltersChanged = () => {
    this.persistState()

    this.submitFilters()
  }

  private onFiltersShouldSubmit = () => {
    this.submitFilters.flush()
  }

  private submitFilters = _.debounce(() => {
    this.currentPage = 1
    this.filterWhereClauses = this.buildFilterWhereClauses()
  }, Config.DEBOUNCE_TIME_MS)

  private onFetched = (data: {items: any[], total: number}) => {
    this.numberOfPages = Math.ceil(data.total / Config.TABLE_VIEW_PAGE_SIZE)
    if (this.currentPage < 1) {
      this.currentPage = 1
    }
    if (this.currentPage > this.numberOfPages) {
      this.currentPage = this.numberOfPages
    }
  }

  private setPage = (page: number) => {
    this.currentPage = page

    this.persistState()
  }

  private setColumns = (columns: ManagedChooseableColumn[]) => {
    this.visibleColumns = columns

    this.persistState()
  }

  @action
  private setFilters = (filters: TableViewFiltersType) => {
    this.filters = filters
    this.filterState = filters.filters.map(f => f.getState())
    this.currentPage = 1

    this.persistState()
  }

  private setShowInactive = (show: boolean) => {
    this.showInactive = show

    this.persistState()

    this.tableViewRef.current && this.tableViewRef.current.fetchData()
  }

  private onShowInactiveChanged = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setShowInactive(ev.target.checked)
  }

  private onSortChanged = (sortDescriptor: SortDescriptor | undefined) => {
    this._sortDescriptor = sortDescriptor

    this.persistState()
  }

  private buildFilterState: () => TableViewFilterState = () => {
    const state = {}

    this.props.filters.forEach(f => state[f.id] = toJS(f.state, { recurseEverything: true }))

    return {
      version: FILTER_STATE_VERSION,
      state: state,
    }
  }

  private buildFilterWhereClauses: () => QueryWhereClause[] = () => {
    return _.flatMap(this.props.filters, f => f.getWhereClause())
  }

  @observable private filterWhereClauses: QueryWhereClause[] = this.buildFilterWhereClauses()

  private restoreState = () => {
    if (!this.stateKey) {
      return
    }

    const stateJson = localStorage.getItem(this.stateKey)
    if (stateJson) {
      try {
        const state: TableViewState = JSON.parse(stateJson)

        if (state) {
          const selectedColumns: string[] = _.union(
            this.props.availableColumns.filter(c => c.fixed).map(c => c.id),
            state.columns
          )

          this.visibleColumns = this.props.availableColumns.filter(c => selectedColumns.indexOf(c.id) > -1)

          this.currentPage = state.page

          this.filters = {
            search: state.search || '',
            filters: [],
          }

          if (state.filterState) {
            const filterState = state.filterState
            if (state.filterState.version === FILTER_STATE_VERSION) {
              this.props.filters.forEach(filter => {
                if (filterState.state[filter.id]) {
                  filter.restoreState(filterState.state[filter.id])
                }
              })
            }

            this.filterWhereClauses = this.buildFilterWhereClauses()
          }

          this.showInactive = state.showInactive || false

          this._sortDescriptor = state.sortDescriptor
        }
      } catch (e) {
        logException(e)
      }
    }
  }

  private persistState = () => {
    if (!this.stateKey) {
      return
    }

    const state: TableViewState = {
      columns: this.visibleColumns.map(c => c.id),
      page: this.currentPage,
      search: this.filters ? this.filters.search : undefined,
      showInactive: this.showInactive,
      sortDescriptor: this._sortDescriptor,
      filterState: this.buildFilterState(),
    }

    try {
      localStorage.setItem(this.stateKey, JSON.stringify(state))
    } catch (e) {
      logException(e)
    }
  }

  private toggleAdvancedFilters = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.preventDefault()

    this._showAdvancedFilters = !this._showAdvancedFilters
  }

  fetchData = () => {
    this.tableViewRef.current && this.tableViewRef.current.fetchData()
  }

  render () {
    const { adapter, exportTransformer } = this.props

    return <>
      <div className="row list-view-header">
        {
          this.props.header
            ? <div className="list-view-info col-md-4 col-sm-12 col-lg-2">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {this.props.header}
              </div>
            </div>
            : null
        }
        <div className={`list-view-search col-sm-12 col-md-${this.props.header ? '4' : '6'} col-lg-${this.props.header ? '7' : '9'} pt-3`}>
          {
            this.props.showSearch
              ? <TableViewFilters
                initialState={{
                  searchText: this.filters ? this.filters.search : '',
                  filters: this.filterState,
                }}
                searchLabel={this.props.searchLabel || 'Find A Record'}
                onChanged={this.setFilters}
              />
              : null
          }

          {
            this.props.allowShowInactive
              ? <div className="list-view-search-inactive form-check form-checkbox">
                <label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={this.showInactive}
                    onChange={this.onShowInactiveChanged}
                  />
                  <span className="label-text">{this.props.showInactiveLabel}</span>
                </label>
              </div>
              : null
          }

          {
            this.visibleFilters.length
              ? <div>
                {
                  !this.props.alwaysShowFilters
                    ? this.showAdvancedFilters
                      ? <a href="" onClick={this.toggleAdvancedFilters}>Hide Advanced Filters</a>
                      : <a href="" onClick={this.toggleAdvancedFilters}>Show Advanced Filters</a>
                    : null
                }

                <div style={{ display: this.showAdvancedFilters ? 'block' : 'none' }}>
                  {
                    this.visibleFilters.map(filter => <div key={filter.id}>{filter.render(this.filterEventBus)}</div>)
                  }
                </div>

                {
                  (!this.showAdvancedFilters && this.activeFilters.length)
                    ? <Alert color="info" className="filter-descriptions-wrapper">
                      <h6>Filters</h6>
                      <div className="filter-descriptions">
                        {
                          this.activeFilters.map(f => <div key={f.id} className="filter-description">{f.description}</div>)
                        }
                      </div>
                    </Alert>
                    : null
                }
              </div>
              : null
          }
        </div>
        <div className={`col-sm12 col-md-${this.props.header ? '4' : '6'} col-lg-3`}>
          <div className="d-flex align-items-start justify-content-end pt-3" style={{ height: '100%' }}>
            {
              exportTransformer
                ? <div style={{ marginRight: 10 }}>
                  <TableViewExportButton
                    tableViewRef={this.tableViewRef}
                    transformerClass={() => new exportTransformer()}
                  />
                </div>
                : null
            }
            <ManagedTableViewColumnChooser
              availableColumns={this.props.availableColumns}
              visibleColumns={this.visibleColumns.map(c => c.id)}
              onChange={this.setColumns}
            />
            <div style={{ marginLeft: 10 }}>
              <Button
                type="button"
                color="primary"
                onClick={() => this.tableViewRef.current!.fetchData()}
              ><i className="fa fa-refresh"/></Button>
            </div>
          </div>
        </div>
      </div>
      {
        this.props.customActions
          ? <div className={'row'}>
            <div className={'col-12 text-right'}>
              {
                this.props.customActions
              }
            </div>
          </div>
          : null
      }

      {
        this.props.tableHeader
      }

      {
        this.props.customFilters
          ? this.props.customFilters(this.filterEventBus)
          : null
      }

      <TableView
        ref={this.tableViewRef}
        hideItemCount={this.props.hideItemCount}
        columns={this.visibleColumns.map(c => c.column)}
        adapter={adapter}
        defaultSort={this.sortDescriptor}
        onSortChanged={this.onSortChanged}
        pagination={{
          offset: Math.max((this.currentPage - 1), 0) * Config.TABLE_VIEW_PAGE_SIZE,
          limit: Config.TABLE_VIEW_PAGE_SIZE,
        }}
        filters={this.transformedFilters}
        where={this.filterWhereClauses}
      />

      {
        this.numberOfPages > 1
          ? <TableViewPagination
            numberOfPages={this.numberOfPages}
            currentPage={this.currentPage}
            setPage={this.setPage}
          />
          : null
      }
    </>
  }
}
