import * as React from "react"
import { observer } from "mobx-react"
import { autorun, observable } from "mobx"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import Util, { modelToCamelCase } from "../../common/Util"
import { route } from "../../routes/routes"
import { BarLoader } from "react-spinners"
import * as _ from "lodash"
import MeetingSlot, { MeetingType } from "../../models/MeetingSlot"
import ButtonLoader from "../../components/ButtonLoader"
import AppStateStore from "../../stores/AppStateStore"
import { toast } from "react-toastify"

type MeetingTypesProps = {
  chapterId: number
}

type ChapterInfo = {
  locationDesc: string,
  virtualMeetingDesc: string,
  defaultMeetingType: MeetingType,
  chapterId: string,
  chapterName: string
}

@observer
export default class MeetingTypesView extends React.Component<MeetingTypesProps> {

  private MEETING_TYPE_IN_PERSON: string = "in-person"
  private MEETING_TYPE_VIRTUAL: string = "virtual"
  private MEETING_TYPE_HYBRID: string = "hybrid"



  @observable chapterInfo?: { locationDesc: string, virtualMeetingDesc: string, defaultMeetingType: MeetingType }
  @observable private slots: MeetingSlot[] = []

  @observable private error?: string
  @observable private loading = false
  @observable private defaultEventType = '';
  @observable private submitting = false
  @observable private meetingInvite = '';



  private fetchEvents = async () => {
    this.loading = true
    this.error = undefined
    try {
      let response = await ApiClient.getInstance().get(route(ApiRoutes.calendar.fetchEvent, { id: this.props.chapterId }))
      this.chapterInfo = modelToCamelCase(response.data.chapter_info) as ChapterInfo
      this.slots = response.data.events.map((event: {}) => new MeetingSlot(modelToCamelCase(event)))
      this.defaultEventType = this.chapterInfo.defaultMeetingType
      this.meetingInvite = this.chapterInfo.virtualMeetingDesc;
    } catch (err) {
      console.log(err)
      this.error = ((err && err.response) ? Util.extractErrorMessage(err.response) : undefined) || 'An error has occurred'
    }
    this.loading = false
  }
  updateInvitation = (event: any) => {
    this.meetingInvite = event.target.value;
  }

  componentDidMount(): void {
    autorun(() => this.fetchEvents())
  }

  onChange = (event: any, meetingSlot: MeetingSlot): void => {
    let slotId = meetingSlot.id
    let oldSelected = meetingSlot.meetingType;
    let selected = event.target.value;
    let newValue = ''
    if (oldSelected as string == '') {
      newValue = selected as MeetingType
    }
    else if (oldSelected === selected) {
      // do nothing
    } else {
      if (oldSelected == MeetingType.Hybrid) {
        if (selected == MeetingType.InPerson) {
          newValue = MeetingType.Virtual
        } else {
          newValue = MeetingType.InPerson
        }
      } else {
        newValue = MeetingType.Hybrid
      }
    }

    this.slots = this.slots.map(slot => (slot.id === slotId ? { ...slot, meetingType: newValue } : slot)) as MeetingSlot[]
  }

  setDefaultChoice = (event: any): void => {
    let choice = event.target.value;
    this.slots.forEach(slot => slot.meetingType = choice as MeetingType)
    this.defaultEventType = choice
  }

  updateEvents = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    AppStateStore.showModalSpinner()

    const chapterDetails = {
      virtual_meeting_desc: this.meetingInvite,
      default_meeting_type: this.defaultEventType
    }

    const events: any = []
    this.slots.slice().forEach((slot, index) =>
      events[index] = {
        id: slot.id,
        week_index: slot.weekIndex,
        meeting_type: slot.meetingType,
        label: slot.label
      }
    )

    const postData = {
      chapter_info: chapterDetails,
      events: events,
    }
    console.log(postData);

    let apiCall = await ApiClient.getInstance().put(route(ApiRoutes.calendar.fetchEvent, { id: this.props.chapterId }),
      postData).then(response => {
        toast.success('Meeting Slots Updated')
      }, error => {
        Util.handleErrorResponse(error.response, undefined, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      })
      .then(() => {
        this.submitting = false
        AppStateStore.dismissModalSpinner()
      })
  }

  private renderEvents = () => {
    return <>
      <div className="meeting-slots">
        <div className={'slots-container'}>
          <div className="slots-header">
            <label>Scheduled Meeting List</label>
            <label>In Person</label>
            <label>Virtual</label>
          </div>
          <div className="slots-view">
            {(this.slots.length) ? this.slots.map(event =>
              <div className="slots-item" key={event.id}>
                <label
                  style={{
                    width: '100px', flexGrow: 2, textAlign: 'left', paddingLeft: '50px'
                  }}
                >{event.label}</label>
                <label
                  style={{ width: '50px', flexGrow: 1, textAlign: 'center' }}
                >
                  <input type="checkbox"
                    className="form-check-input"
                    name="inPerson"
                    value="in-person"
                    checked={event.meetingType == null ||event.meetingType == MeetingType.InPerson || event.meetingType == MeetingType.Hybrid}
                    onChange={(e) => this.onChange(e, event)}
                  />
                </label>
                <label
                  style={{ width: '50px', flexGrow: 1, textAlign: 'center' }}>
                  <input type="checkbox"
                    className="form-check-input"
                    name="virtual"
                    value="virtual"
                    checked={event.meetingType == MeetingType.Virtual || event.meetingType == MeetingType.Hybrid}
                    onChange={(e) => this.onChange(e, event)}
                  />
                </label>
              </div>
            ) : null}
          </div>
        </div>
        <div className={'slots-settings'}>
          <div className="slot-type">

            <div className="form-row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="form-check form-checkbox" style={{ margin: "20px", display: "flex", flexDirection: "column", flexWrap: "nowrap" }}>
                    <h5 className="text-muted" >Chapter Default Choice is </h5>
                    <label>
                      <input type="checkbox"
                        className="form-check-input"
                        name="sendCopy"
                        value="in-person"
                        checked={this.MEETING_TYPE_IN_PERSON == this.defaultEventType}
                        onChange={this.setDefaultChoice}
                      />
                      <span className="label-text">Meetings Are In Person</span>
                    </label>
                    <label>
                      <input type="checkbox"
                        className="form-check-input"
                        name="sendCopy"
                        value="virtual"
                        checked={this.MEETING_TYPE_VIRTUAL == this.defaultEventType}
                        onChange={this.setDefaultChoice}
                      />
                      <span className="label-text">Meetings Are Virtual</span>
                    </label>
                    <label>
                      <input type="checkbox"
                        className="form-check-input"
                        name="sendCopy"
                        value="hybrid"
                        checked={this.MEETING_TYPE_HYBRID == this.defaultEventType}
                        onChange={this.setDefaultChoice}
                      />
                      <span className="label-text">Meetings Are Hybrid</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div className="meeting-information">
            <div style={{ margin: "20px" }}>
              <label>What is the standard link for your virtual meetings</label>
              <input type="text" className="form-control" onChange={this.updateInvitation} value={this.meetingInvite ? this.meetingInvite : ''} />
              <label>If you have different links for each meetings you'll need to update <br />
                each individual meeting on the calendar</label>
              <label>Your in-person meeting address is : <b>{this.chapterInfo && this.chapterInfo!.locationDesc ? this.chapterInfo!.locationDesc : ''}</b></label>
              <label>Meeting location can be changed on your My Chapter &gt; Overview menu Location.</label>
              <div className="form-buttons">
                <ButtonLoader type="submit" color="success" loading={this.submitting} onClick={this.updateEvents}> <label style={{'marginTop': '0.5rem'}}>Update Changes to Calendar</label></ButtonLoader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  }

  render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return this.error
      ? this.error
      : this.loading
        ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d" />
        : this.renderEvents()
  }
}
