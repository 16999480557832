import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Member from "./Member"
import Chapter from './Chapter'
import Guest from './Guest'
import Application from './Application'

export default class CompanyReplacementRequest extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment

  companyReplacementMembershipFee: number
  taxRate: number

  isInQueue: boolean

  memberId: number
  chapterId: number
  guestId: number
  applicationId: number

  member: Member
  chapter: Chapter
  guest: Guest
  application: Application

  setMember (m?: {}) {
    return m ? new Member().init(m) : null
  }

  setChapter (c?: {}) {
    return c ? new Chapter().init(c) : null
  }

  setGuest (c?: {}) {
    return c ? new Guest().init(c) : null
  }

  setApplication (c?: {}) {
    return c ? new Application().init(c) : null
  }

  getCasts () {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      companyReplacementMembershipFee: 'number',
      taxRate: 'number',
      isInQueue: 'boolean',
      memberId: 'number',
      chapterId: 'number',
      guestId: 'number',
      applicationId: 'number',
    }
  }
}
