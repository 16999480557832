import { FieldType, ResourceSpec } from "../ReportDataSource"
import Chapter from "../../models/Chapter"
import { AreaResource } from "./AreaResource"

export const ChapterResource: ResourceSpec = {
  collectionResourceName: 'chapters',
  modelClass: Chapter,

  fields: [
    {
      name: 'id',
      type: FieldType.Number,
    },
    {
      name: 'name',
      type: FieldType.String,
    },
    {
      name: 'numberOfActiveMembers',
      type: FieldType.Number,
    },
    {
      name: 'status',
      type: FieldType.Enum,
      values: [
        { value: 'Active' },
        { value: 'Closed' },
        { value: 'Disbanded' },
        { value: 'Incubation' },
        { value: 'Pre-Sign' },
      ],
    }
  ],
  relationships: [
    {
      name: 'area',
      resourceSpec: AreaResource,
    }
  ],
}