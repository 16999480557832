import * as React from "react"
import { observer } from "mobx-react"
import Member from "../../models/Member"
import { Routes } from "../../routes/AppRoutes"
import { route } from "../../routes/routes"
import { Link } from "react-router-dom"

type Props = {
  member: Member
  linkTo?: any
  showBusiness?: boolean
  showCategory?: boolean
}
@observer
export default class MemberCell extends React.Component<Props> {
  static defaultProps = {
    showBusiness: false,
    showCategory: true,
  }

  render (): React.ReactNode {
    return <div className="d-flex align-items-center">
      <div className="mr-2"><img style={{ width: 60, height: 60, objectFit: 'cover', objectPosition: 'top center', backgroundColor: '#ccc' }} src={this.props.member.getProfileImageUrl()}/></div>
      <div className="flex-fill">
        <Link to={this.props.linkTo || route(Routes.network.member, { memberId: this.props.member.id })}>{this.props.member.fullName}</Link><br/>
        {
          (this.props.showCategory && this.props.member.category)
            ? <div>{this.props.member.category.name}</div>
            : null
        }
        {
          (this.props.showBusiness && this.props.member.business)
            ? <div>{this.props.member.business.name}</div>
            : null
        }
      </div>
    </div>
  }
}
