import * as React from "react"
import { SyntheticEvent } from "react"
import BaseView from "./BaseView"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { Link, Prompt, Redirect } from "react-router-dom"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { observer } from "mobx-react"
import ButtonLoader from "../components/ButtonLoader"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import Util, { logException, modelToCamelCase, modelToSnakeCase } from "../common/Util"
import { BarLoader } from "react-spinners"
import Region from "../models/Region"
import { Button } from "reactstrap"
import { LinkContainer } from "react-router-bootstrap"
import { toast } from "react-toastify"
import BackLink from "../components/BackLink"

type EditRegionFormState = {
  name: string
  status: string
  monthlyMembersGoal: string
  monthlyChaptersGoal: string
  monthlyRenewalsPercentageGoal: string
  monthlyGuestsGoal: string
}

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

@observer
export default class EditRegionView extends BaseView<Props> {
  @observable private formState = new FormState<EditRegionFormState>({
    name: '',
    status: 'Active',
    monthlyMembersGoal: '',
    monthlyChaptersGoal: '',
    monthlyRenewalsPercentageGoal: '',
    monthlyGuestsGoal: '',
  })
  @observable private formErrors = new ErrorBag()
  private formHelper = new FormHelper(this.formState, this.formErrors)
  @observable private submitting = false
  @observable private loading = false
  @observable private redirect = false
  @observable private editRegion?: Region

  private get isEdit () {
    return !!this.props.match.params.id
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>{this.isEdit ? 'Update Existing Region' : 'Add New Region'}</h1>
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    if (this.props.match.params.id) {
      this.loading = true

      ApiClient.regions.show(this.props.match.params.id)
        .then(response => {
          const regionData = modelToCamelCase(response.data.region)
          this.editRegion = new Region(modelToCamelCase(regionData))
          this.formState.setAll(regionData as EditRegionFormState)
          this.formState.clearDirty()
          this.loading = false
        }, () => {
          AppStateStore.showAlertModal('Error', 'The region was not found', m => {
            m.hide()
            this.redirect = true
          })
        })
        .catch(ex => {
          logException(ex)
          AppStateStore.showAlertModal('Error', 'The region was not found', m => {
            m.hide()
            this.redirect = true
          })
        })
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    const apiCall = this.editRegion
      ? () => ApiClient.regions.update(this.editRegion!.id, modelToSnakeCase(this.formHelper.toObject()))
      : () => ApiClient.regions.create(modelToSnakeCase(this.formHelper.toObject()))

    apiCall().then(() => {
      toast.success(this.isEdit ? 'Region updated' : 'Region created')
      this.redirect = true
    }, error => {
      const errors = new ErrorBag()
      Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      })

      this.formErrors.addErrors(errors.getErrorList())
    })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  renderContentBody (): React.ReactNode {
    return this.redirect
      ? this.renderRedirect()
      : this.loading ? this.renderLoading() : this.renderForm()
  }

  private renderLoading = () => <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>

  private renderForm = () => (
    <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      {this.formState.isDirty && <Prompt message="Are you sure you want to leave this page? You will lose any unsaved changes!"/>}
      <div className="form-row">
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'name',
            type: 'text',
            label: 'Region Name',
          })}
        </div>
        <div className="col-sm-6">
          {this.formHelper.renderSelectInput({
            name: 'status',
            label: 'Status',
            options: [
              { value: 'Active', text: 'Active' },
              { value: 'Inactive', text: 'Inactive' },
            ]
          })}
        </div>
      </div>
      <hr/>
      <h2>Goals</h2>
      <div className="form-row">
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'monthlyMembersGoal',
            type: 'number',
            label: 'New Members Monthly',
          })}
        </div>
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'monthlyRenewalsPercentageGoal',
            type: 'number',
            label: 'Renewals Monthly (percentage)',
            append: (<span>%</span>)
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'monthlyChaptersGoal',
            type: 'number',
            label: 'New Chapters Monthly',
          })}
        </div>
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'monthlyGuestsGoal',
            type: 'number',
            label: 'New Guests Monthly',
          })}
        </div>
      </div>

      <div className="form-buttons">
        <LinkContainer to={route(Routes.system.regions.index)}><Button type="button" color=""><i className="fa fa-ban"/> Cancel</Button></LinkContainer>
        <ButtonLoader type="submit" color="success" loading={this.submitting}><i className="fa fa-save"/> {this.isEdit ? 'Update Region' : 'Save New Region'}</ButtonLoader>
      </div>
    </form>
  )

  private renderRedirect = () => <Redirect to={route(Routes.system.regions.index)}/>
}