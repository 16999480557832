import BaseModel from "./BaseModel"
import Member from "./Member"
import Event from "./Event"

export default class MeetingAttendee extends BaseModel {
  id: number
  status: string | null

  event: Event
  member: Member

  setEvent (e?: {}) {
    return e ? new Event().init(e) : null
  }

  setMember (m?: {}) {
    return m ? new Member(m) : null
  }
}