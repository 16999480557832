import BaseModel from "./BaseModel"
import { Moment } from 'moment-timezone/moment-timezone'

export type AllowedPosition = {
  code: string
  name: string
}

export default class BoardDocument extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  title: string
  allowedBoardPositions: AllowedPosition[] | null
  pdfName: string
  pdfFilename: string
  pdfUrl: string
  signedCount: number
  totalCount: number

  constructor (data?: {}) {
    super()

    if (data) {
      this.init(data)
    }
  }

  getCasts () {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      signedCount: 'number',
      totalCount: 'number',
    }
  }
}
