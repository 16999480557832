import BaseView from "../BaseView"
import { computed, observable } from 'mobx'
import { observer } from "mobx-react"
import * as React from "react"
import * as _ from "lodash"
import { Nav, NavItem, NavLink, TabContent } from "reactstrap"
import classNames from "classnames"
import { createBrowserHistory } from "history"
import LazyTabPane from "../../components/LazyTabPane"
import ChapterTipsReport from "../../components/system/reporting/ChapterTipsReport"
import MemberRosterReport from "../../components/system/reporting/MemberRosterReport"
import AttendanceReport from "../../components/system/reporting/AttendanceReport"
import GuestAttendanceReport from "../../components/system/reporting/GuestAttendanceReport"
import QuarterlyTipReport from "../../components/system/reporting/QuarterlyTipReport"
import WeeklyTipReport from "../../components/system/reporting/WeeklyTipReport"
import ChapterTipClosureReport from "../../components/system/reporting/ChapterTipClosureReport"
import ChapterTipInsideOutsideReport from "../../components/system/reporting/ChapterTipInsideOutsideReport"
import AuthStore from "../../stores/AuthStore"
import ChapterTipMasterOverviewReport from "../../components/system/reporting/ChapterTipMasterOverviewReport"
import ChapterTippersStackedReport from "../../components/system/reporting/ChapterTippersStackedReport"
import ChapterTipOpenReport from '../../components/system/reporting/ChapterTipOpenReport'

const TABS = {
  reports: 'reports',
  custom: 'custom',
}

@observer
export default class BoardReportingView extends BaseView {
  @observable private activeTab = TABS.reports
  @observable private selectedReportId = ''

  @computed
  private get selectedReport () {
    return this.reports.find(r => r.id === this.selectedReportId)
  }

  private history = createBrowserHistory()

  private reports = [
    {
      id: 'chapter_tips_report',
      name: 'Chapter Tip Report',
      reportComponent: <ChapterTipsReport chapterId={AuthStore.getUser()!.chapter.id}/>,
    },
    {
      id: 'quarterly_tip_report',
      name: '4 Quarter Tip Report',
      reportComponent: <QuarterlyTipReport chapterId={AuthStore.getUser()!.chapter.id}/>,
    },
    {
      id: 'weekly_tip_report',
      name: 'Chapter Monthly Tip Report',
      reportComponent: <WeeklyTipReport chapterId={AuthStore.getUser()!.chapter.id}/>,
    },
    {
      id: 'tip_closure_report',
      name: 'Tip Closure Report',
      reportComponent: <ChapterTipClosureReport chapterId={AuthStore.getUser()!.chapter.id}/>,
    },
    {
      id: 'tip_inside_outside_report',
      name: 'Tip Inside vs. Outside Report',
      reportComponent: <ChapterTipInsideOutsideReport chapterId={AuthStore.getUser()!.chapter.id}/>,
    },
    {
      id: 'member_roster_report',
      name: 'Member Roster Report',
      reportComponent: <MemberRosterReport chapterId={AuthStore.getUser()!.chapter.id}/>,
    },
    {
      id: 'attendance_report',
      name: 'Attendance Report',
      reportComponent: <AttendanceReport chapterId={AuthStore.getUser()!.chapter.id}/>
    },
    {
      id: 'guest_attendance_report',
      name: 'Guest Attendance Report',
      reportComponent: <GuestAttendanceReport chapterId={AuthStore.getUser()!.chapter.id}/>
    },
    {
      id: 'tip_master_overview_report',
      name: 'Tip Master Overview Report',
      reportComponent: <ChapterTipMasterOverviewReport chapterId={AuthStore.getUser()!.chapter.id}/>
    },
    {
      id: 'tippers_stacked_report',
      name: 'Tippers Stacked Report',
      reportComponent: <ChapterTippersStackedReport chapterId={AuthStore.getUser()!.chapter.id}/>
    },
    {
      id: 'open_tips_report',
      name: 'Open Tips Report',
      reportComponent: <ChapterTipOpenReport chapterId={AuthStore.getUser()!.chapter.id}/>
    },
  ]

  renderContentHeader () {
    return (
      <>
        <h1>Reporting</h1>
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    this.activeTab = _.get(this.history.location.state, 'activeTab') || TABS.reports
  }

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(window.location.pathname, { ...this.history.location.state, activeTab: this.activeTab })
    }
  }

  render (): React.ReactNode {
    return <>
      <div className="card">
        <div className="card-header print-hide">
          <Nav tabs className="card-header-tabs">
            <NavItem className={classNames({ active: this.activeTab === TABS.reports })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.reports)
                }}
              >Reports</NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="card-body">
          <TabContent activeTab={this.activeTab}>
            <LazyTabPane tabId={TABS.reports} activeTab={this.activeTab}>
              <div className="row print-hide">
                <div className="col-md-4">
                  <select
                    className="form-control"
                    value={this.selectedReportId}
                    onChange={ev => this.selectedReportId = ev.target.value}
                  >
                    <option value="">(select a report)</option>
                    {this.reports.map(report => <option key={report.id} value={report.id}>{report.name}</option>)}
                  </select>
                </div>
              </div>
              <br/>
              <div className="row">
                <div className="col-12">
                  {
                    this.selectedReport
                      ? this.selectedReport.reportComponent
                      : <p>Choose a report above</p>
                  }
                </div>
              </div>
            </LazyTabPane>
          </TabContent>
        </div>
      </div>
    </>
  }
}
