import * as React from "react"
import BaseView from "./BaseView"
import { observer } from "mobx-react"
import { observable } from "mobx"
import { createLazyResource, modelToCamelCase } from "../common/Util"
import ApiClient, { QueryWhereClause } from "../api/ApiClient"
import Event from "../models/Event"
import AuthStore from "../stores/AuthStore"
import LazyResourcePanel from "../components/LazyResourcePanel"
import moment from 'moment-timezone'
import * as _ from "lodash"
import AttendanceForm from "../components/AttendanceForm"

@observer
export default class CalendarView extends BaseView {
  @observable showAllPastMeetings = false
  @observable selectedEventId?: number = undefined

  @observable private chapterMeetings = createLazyResource<Event[]>(() => {
    const where: QueryWhereClause[] = [
      { _scope: 'hasAttendance' },
      { _scope: 'chapter', value: AuthStore.getUser()!.chapter.id },
      { _scope: 'visible' },
    ]

    if (!this.showAllPastMeetings) {
      where.push({ id: 'startsAt', op: '>=', value: moment().subtract(2, 'week') })
    }

    return ApiClient.query(`
events {
  *
}
    `, {
      where: where,
      order: [
        'startsAt',
      ]
    })
  }, response => response.data.events.map((t: {}) => new Event().init(modelToCamelCase(t))))

  componentDidMount (): void {
    super.componentDidMount()

    this.chapterMeetings.refresh().then(() => {
      if (!this.selectedEventId && this.chapterMeetings.current) {
        const prev = _.find(this.chapterMeetings.current, (m: Event) => m.startsAt.isAfter(moment()))
        if (prev) {
          this.selectedEventId = prev.id
        } else if (this.chapterMeetings.current.length) {
          this.selectedEventId = this.chapterMeetings.current[0].id
        }
      }
    })
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>Attendance</h1>
      </>
    )
  }

  renderContentBody (): React.ReactNode {
    return <>
      <LazyResourcePanel resource={this.chapterMeetings}>
        {chapterMeetings => <>
          <div className="form-row">
            <div className="form-col-12">
              <div style={{ display: 'flex', alignItems:'flex-end' }}>
                <div>
                  <label>Choose A Chapter Meeting</label>
                  <select className="form-control"
                          value={this.selectedEventId}
                          onChange={ev => this.selectedEventId = Number(ev.target.value)}
                  >
                    {chapterMeetings.map(m => <option value={m.id} key={m.id}>{m.startsAt.format('dddd, MMMM D, YYYY')} - {m.title}</option>)}
                  </select>
                </div>
                <div style={{marginLeft:10}}>
                  <div className="form-check form-checkbox">
                    <label>
                      <input type="checkbox"
                             className="form-check-input"
                             checked={this.showAllPastMeetings}
                             onChange={(ev) => {
                               this.showAllPastMeetings = ev.target.checked
                               this.chapterMeetings.invalidate()
                             }}
                      />
                      <span className="label-text">Show All Past Meetings</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>}
      </LazyResourcePanel>
      <br/><br/>
      {this.selectedEventId && <AttendanceForm eventId={this.selectedEventId}/>}
    </>
  }
}
