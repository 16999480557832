import CsvTransformer from "./CsvTransformer"
import moment from "moment-timezone"
import { formatCurrency, safeNull } from "../../common/Util"

export type DailyRevenueReportCsvRow = {
  type: string

  area?: {
    id: number
    name: string
  }

  chapter?: {
    id: number
    name: string
  }

  invoice: {
    id: number
    total: string
  }

  member?: {
    id: number
    fullName: string
    joinDate: string
  }

  transaction: {
    id: number
    createdAt: string
    provider: string
    externalTransactionId: string
    amount: string
  }

  paymentType: string
  note: string
}

export default class DailyRevenueReportCsvTransformer extends CsvTransformer<DailyRevenueReportCsvRow> {
  get filename () {
    return `daily-revenue-report-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'Type',
      valueTransformer: row => row.type,
    })

    this.addColumn({
      header: 'Region',
      valueTransformer: row => safeNull(() => row.area!.name),
    })

    this.addColumn({
      header: 'Chapter Name',
      valueTransformer: row => safeNull(() => row.chapter!.name),
    })

    this.addColumn({
      header: 'Contact',
      valueTransformer: row => safeNull(() => row.member!.fullName),
    })

    this.addColumn({
      header: 'Join Date',
      valueTransformer: row => safeNull(() => row.member!.joinDate ? moment(row.member!.joinDate!).format('MM/DD/YYYY') : ''),
    })

    this.addColumn({
      header: 'Amount Due',
      valueTransformer: row => formatCurrency(Number(row.invoice.total)),
    })

    this.addColumn({
      header: 'Date Paid',
      valueTransformer: row => moment(row.transaction.createdAt).format('MM/DD/YYYY'),
    })

    this.addColumn({
      header: 'Payment Type',
      valueTransformer: row => row.paymentType,
    })

    this.addColumn({
      header: 'Note',
      valueTransformer: row => row.note,
    })
  }
}