import * as React from "react"
import { observer } from "mobx-react"
import _ from 'lodash'

type LoadingPanelProps = {
  children: (() => React.ReactNode) | React.ReactNode
  loading: boolean
  loaded: boolean
  error?: React.ReactNode
  loader?: React.ReactNode
}

@observer
export default class LoadingPanel extends React.Component<LoadingPanelProps> {
  private renderLoading = () => {
    return this.props.loader || <div className="lazy-spinner-backdrop"><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/></div>
  }

  render (): React.ReactNode {
    return <div className="lazy-resource-panel">
      {
        this.props.error
          ? this.props.error
          : <>
            {
              this.props.loaded
                ? _.isFunction(this.props.children) ? this.props.children() : this.props.children
                : null
            }
            {
              this.props.loading
                ? this.renderLoading()
                : null
            }
          </>
      }
    </div>
  }
}
