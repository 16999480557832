import { observer } from 'mobx-react'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import React from 'react'
import { observable, toJS } from 'mobx'
import Util from '../../common/Util'
import ApiClient, { ApiRoutes } from '../../api/ApiClient'
import { route } from '../../routes/routes'
import LoadingPanel from '../LoadingPanel'
import _ from 'lodash'

type NewMembershipsData = {
  newMemberCount: {
    day: number
    month: number
    year: number
  }
}

type Props = {
  filters: {
    state?: string
    areaId?: number
    chapterId?: number
  }
  onShowGraphClicked: () => void
  onShowMemberList: (url: string, title: string, viewOptions?: any) => void
}

@observer
export default class NewMembershipsDashboard extends React.Component<Props> {
  @observable
  private newMemberships?: NewMembershipsData
  @observable
  private error?: string
  @observable
  private loading = false

  componentDidMount () {
    this.loadData().then()
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (!_.isEqual(toJS(prevProps.filters), toJS(this.props.filters))) {
      this.loadData().then()
    }
  }

  private loadData = async () => {
    try {
      this.error = undefined
      this.loading = true

      const response = await ApiClient.getInstance().get(route(ApiRoutes.dashboard.newMemberships, {}, this.props.filters))

      this.newMemberships = response.data

      this.loading = false
    } catch (err) {
      this.error = Util.extractErrorMessage(err.response)
    }
  }

  render () {
    return <Card>
      <CardHeader>
        <div className="d-flex align-items-center">
          <div className="flex-fill">
            <b>New Memberships</b>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <LoadingPanel loading={this.loading} loaded={!!this.newMemberships} error={this.error}>
          {
            this.newMemberships
              ? <>
                <div className="d-flex">
                  <div className="flex-fill">
                    Today
                  </div>
                  <div className="text-right">
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.props.onShowMemberList(route(ApiRoutes.dashboard.newMemberships, {}, {
                        ...this.props.filters,
                        list: 'day',
                      }), 'New Members Today', {showJoinDate: true})
                    }}>
                      {Util.formatNumber(this.newMemberships!.newMemberCount.day)}
                    </a>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="flex-fill">
                    This Month
                  </div>
                  <div className="text-right">
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.props.onShowMemberList(route(ApiRoutes.dashboard.newMemberships, {}, {
                        ...this.props.filters,
                        list: 'month',
                      }), 'New Members This Month', {showJoinDate: true})
                    }}>
                      {Util.formatNumber(this.newMemberships!.newMemberCount.month)}
                    </a>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="flex-fill">
                    This Year
                  </div>
                  <div className="text-right">
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.props.onShowMemberList(route(ApiRoutes.dashboard.newMemberships, {}, {
                        ...this.props.filters,
                        list: 'year',
                      }), 'New Members This Year', {showJoinDate: true})
                    }}>
                      {Util.formatNumber(this.newMemberships!.newMemberCount.year)}
                    </a>
                  </div>
                </div>
              </>
              : null
          }
        </LoadingPanel>
      </CardBody>
    </Card>
  }
}
