import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Address from "./Address"
import Chapter from "./Chapter"
import Region from "./Region"
import Area from "./Area"
import ProgramPositionMember from "./ProgramPositionMember"
import ProgramScheduleRecord from "./ProgramScheduleRecord"

export enum EventType {
  ChapterMeeting = 'Chapter Meeting',
  BoardMeeting = 'Board Meeting',
  BusinessMixer = 'Business Mixer',
  NTS = 'Network Training Seminar',
  WhiteBadgeTraining = 'White Badge Training Seminar',
  NationalLeadershipCall = 'LeTip National Leadership Call',
  // PresidentMonthlyCalls = 'National Monthly Special Topic Call: Pres & VP',
  // BoardMonthlyCalls = 'National Monthly Special Topic Call: Membership & TipMaster',
  BTS = 'Board Training Seminar',
  PowerEvents = 'Power Events',
  Other = 'Other',
}

export enum EventStatus {
  Active = 'Active',
  Canceled = 'Canceled',
}

export default class Event extends BaseModel {
  id: number
  eventType: string
  startsAt: Moment
  endsAt: Moment
  timezone: string
  showInLocalTimezone: boolean
  title: string
  locationName: string | null
  visibility: string
  description: string
  notes: string
  isCustomEvent: boolean
  status: string
  webConferenceDetails: string
  displayTitle: string
  skipProgramSchedule: boolean

  chapterId: number | null
  areaId: number | null
  regionId: number | null

  meetingType: string | null

  address: Address | null
  chapter: Chapter | null
  area: Area | null
  region: Region | null
  programPositionMembers: ProgramPositionMember[]
  programSchedule: ProgramScheduleRecord[]

  getCasts () {
    return {
      startsAt: 'datetime',
      endsAt: 'datetime',

      id: 'number',
      chapterId: 'number',
      areaId: 'number',
      regionId: 'number',
      isCustomEvent: 'boolean',
      showInLocalTimezone: 'boolean',
      skipProgramSchedule: 'boolean',
    }
  }

  setChapter (a?: {}) {
    return a ? new Chapter().init(a) : null
  }

  setRegion (a?: {}) {
    return a ? new Region().init(a) : null
  }

  setArea (a?: {}) {
    return a ? new Area().init(a) : null
  }

  setAddress (a?: {}) {
    return a ? new Address(a) : null
  }

  setProgramPositionMembers (pp?: []) {
    return pp ? pp.map(pp => new ProgramPositionMember().init(pp)) : []
  }

  setProgramSchedule (ps?: []) {
    return ps ? ps.map(ps => new ProgramScheduleRecord().init(ps)) : []
  }
}
