import Member from "../../../models/Member"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import Util, { modelToSnakeCase } from "../../../common/Util"
import UserLoginSettings from "../../UserLoginSettings"
import FormState from "../../../common/FormState"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"
import Config from "../../../common/Config"
import ButtonLoader from "../../ButtonLoader"
import { route } from "../../../routes/routes"
import { toast } from "react-toastify"
import AppStateStore from "../../../stores/AppStateStore"
import Button from "reactstrap/lib/Button"
import UpdateMemberChapterModal from "./UpdateMemberChapterModal"
import LoadingPanel from "../../LoadingPanel"
import UpdateMemberCategoryModal from "./UpdateMemberCategoryModal"
import NotesList from "../../NotesList"
import EventBus from "../../../common/EventBus"
import UpdateCompanyReplacementModal from "./UpdateCompanyReplacementModal"
import { Link } from "react-router-dom"
import { Routes } from "../../../routes/AppRoutes"
import { ManageSponsorCredits } from './ManageSponsorCredits'

type ManageMemberSettingsProps = {
  memberId: number
  onMemberInvalidated?: () => void
}

@observer
export default class ManageMemberSettings extends React.Component<ManageMemberSettingsProps> {
  @observable private loading = false
  @observable private loaded = false
  @observable private error?: string
  @observable private member?: Member
  @observable private submitting = false

  @observable private showUpdateChapterModal = false
  @observable private renderUpdateChapterModal = false

  @observable private showUpdateCategoryModal = false
  @observable private renderUpdateCategoryModal = false

  @observable private showCompanyReplacementModal = false
  @observable private renderCompanyReplacementModal = false

  private applicationNotesEventBus = new EventBus()

  @observable private formState = new FormState({
    status: '',
    joinDate: '',
    inputDate: '',
    membershipType: '',
    ntsTrainingCompletedAt: '',
    bankCode: '',
    renewalDiscountType: '',
    renewalDiscountPercent: '',
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable private applicationStatusFormState = new FormState({
    applicationStatus: '',
  })

  @observable private applicationStatusFormErrors = new ErrorBag()

  private applicationStatusFormHelper = new FormHelper(this.applicationStatusFormState, this.applicationStatusFormErrors)

  private loadMember = () => {
    this.loading = true
    this.error = undefined

    ApiClient.query(`
    member {
      *
      user {
        *
      }
      chapter {
        *
      }
      category {
        *
      }
      replacedCompanyMember {
        *
      }
      replacedByCompanyMember {
        *
      }
    `, {
      where: [{ id: this.props.memberId }],
    })
      .then(response => {
        this.member = new Member().init(response.data.member)
        this.formState.setAll({
          status: this.member.status || '',
          joinDate: this.member.joinDate ? this.member.joinDate.format('MM/DD/YYYY') : '',
          inputDate: this.member.inputDate ? this.member.inputDate.format('MM/DD/YYYY') : '',
          ntsTrainingCompletedAt: this.member.ntsTrainingCompletedAt ? this.member.ntsTrainingCompletedAt.format('MM/DD/YYYY') : '',
          membershipType: this.member.membershipType || '',
          bankCode: this.member.bankCode || '',
          renewalDiscountType: this.member.renewalDiscountType || '',
          renewalDiscountPercent: this.member.renewalDiscountPercent || '',
        })

        this.applicationStatusFormState.setAll({
          applicationStatus: this.member.applicationStatus || '',
        })
      })
      .catch(error => this.error = Util.extractErrorMessage(error.response))
      .then(() => {
        this.loading = false
        this.loaded = true
      })
  }

  componentDidMount (): void {
    this.loadMember()
  }

  componentDidUpdate (prevProps: Readonly<ManageMemberSettingsProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.memberId !== prevProps.memberId) {
      this.loadMember()
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitting = true

    const data: any = modelToSnakeCase(this.formHelper.toObject())

    if (data.renewal_discount_type !== 'custom') {
      data.renewal_discount_percent = ''
    }

    ApiClient.getInstance()
      .post(route(ApiRoutes.members.updateSettings, { id: this.props.memberId }), data)
      .then(() => {
        toast.success('Member settings updated')
        this.loadMember()
        this.props.onMemberInvalidated && this.props.onMemberInvalidated()
      })
      .catch(error => Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      }))
      .then(() => this.submitting = false)
  }

  private sendLoginInfoEmail = (confirm: boolean = false) => {
    AppStateStore.showModalSpinner()

    ApiClient.getInstance().post(route(ApiRoutes.members.sendLoginInfo, { id: this.member!.id }), { confirm: confirm ? 1 : 0 })
      .then(() => {
        this.loadMember()
      })
      .catch(error => Util.handleErrorResponse(error.response, null, (response) => {
        if (response.status === 445) {
          AppStateStore.showConfirmationModal('Confirm', response.data.message, (result, modal) => {
            if (result) {
              this.sendLoginInfoEmail(true)
            }

            modal.hide()
          })
          return true
        }

        return false
      }, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      }))
      .then(() => AppStateStore.dismissModalSpinner())
  }

  private sendWelcomeLetterEmail = (confirm: boolean = false) => {
    AppStateStore.showModalSpinner()

    ApiClient.getInstance().post(route(ApiRoutes.members.sendWelcomeLetter, { id: this.member!.id }), { confirm: confirm ? 1 : 0 })
      .then(() => {
        this.loadMember()
      })
      .catch(error => Util.handleErrorResponse(error.response, null, (response) => {
        if (response.status === 445) {
          AppStateStore.showConfirmationModal('Confirm', response.data.message, (result, modal) => {
            if (result) {
              this.sendWelcomeLetterEmail(true)
            }

            modal.hide()
          })
          return true
        }

        return false
      }, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      }))
      .then(() => AppStateStore.dismissModalSpinner())
  }

  private submitApplicationStatus = () => {
    AppStateStore.showModalSpinner()

    ApiClient.getInstance()
      .post(route(ApiRoutes.members.updateApplicationStatus, { id: this.props.memberId }), modelToSnakeCase(this.applicationStatusFormHelper.toObject()))
      .then(() => {
        toast.success('Application status updated')
        this.loadMember()
        this.props.onMemberInvalidated && this.props.onMemberInvalidated()
        this.applicationNotesEventBus.dispatch('refresh')
      })
      .catch(error => Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      }))
      .then(() => AppStateStore.dismissModalSpinner())
  }

  private renderContent = () => {
    const member = this.member
    return <LoadingPanel loading={this.loading || !this.member} loaded={this.loaded}>
      {() => member
        ? <>
          <div className="form-row">
            <div className="col-md-6">
              {
                member.loginInfoSentAt
                  ? <p>Login info sent on {member.loginInfoSentAt.format('MM/DD/YY')}</p>
                  : <p>Login info has not been sent to this member</p>
              }
              <div>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => this.sendLoginInfoEmail()}
                >Send Login Info Email</Button>
              </div>
            </div>
            <div className="col-md-6">
              {
                member.welcomeLetterSentAt
                  ? <p>Welcome letter sent on {member.welcomeLetterSentAt.format('MM/DD/YY')}</p>
                  : <p>Welcome letter has not been sent to this member</p>
              }
              <div>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => this.sendWelcomeLetterEmail()}
                >Send Welcome Letter Email</Button>
              </div>
            </div>
          </div>

          <hr/>

          {
            member.userId
              ? <UserLoginSettings userId={member.userId}/>
              : <p>This member does not have a user</p>

          }

          <hr/>

          <h6 className="text-muted">Member Settings</h6>
          <form onSubmit={this.submit}>
            <div className="form-row">
              <div className="col-md-4">
                {
                  this.formHelper.renderDatePickerInput({
                    label: 'Join Date',
                    name: 'joinDate',
                  })
                }
              </div>
              <div className="col-md-4">
                {
                  this.formHelper.renderDatePickerInput({
                    label: 'Input Date',
                    name: 'inputDate',
                  })
                }
              </div>
              <div className="col-md-4">
                {
                  this.formHelper.renderDatePickerInput({
                    label: 'NTS Completed',
                    name: 'ntsTrainingCompletedAt',
                  })
                }
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-4">
                {
                  this.formHelper.renderSelectInput({
                    label: 'Status',
                    name: 'status',
                    options: Config.MEMBER_STATUS_OPTIONS.map(o => ({ value: o, text: o })),
                  })
                }
              </div>
              <div className="col-md-4">
                {
                  this.formHelper.renderSelectInput({
                    label: 'Membership Type',
                    name: 'membershipType',
                    options: Config.MEMBERSHIP_TYPE_OPTIONS.map(o => ({ value: o, text: o })),
                  })
                }
              </div>
              <div className="col-md-4">
                {
                  this.formHelper.renderTextInput({
                    label: 'BANKCODE',
                    name: 'bankCode',
                  })
                }
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-4">
                {
                  this.formHelper.renderSelectInput({
                    label: 'Renewal Discount Type',
                    name: 'renewalDiscountType',
                    options: [
                      { value: '', text: 'None' },
                      { value: 'veteran', text: 'Veteran Discount' },
                      { value: 'custom', text: 'Custom' },
                    ],
                  })
                }
              </div>
              <div className="col-md-4">
                {
                  this.formState.get('renewalDiscountType') === 'custom'
                    ? this.formHelper.renderTextInput({
                      label: 'Renewal Discount %',
                      name: 'renewalDiscountPercent',
                      append: '%',
                    })
                    : null
                }
              </div>
              <div className="col-md-4">
              </div>
            </div>
            <div className="form-buttons">
              <ButtonLoader
                type="submit"
                color="primary"
                loading={this.submitting}
              >Save Changes</ButtonLoader>
            </div>
          </form>

          <hr/>

          <h6 className="text-muted">Company Replacement</h6>

          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                <b>{member.isCompanyReplacement ? 'Yes' : 'No'}</b>
                {
                  member.replacedCompanyMember
                    ? <span>
                          &nbsp;- replaced <Link to={route(Routes.system.members.show, { id: member.replacedCompanyMember.id })}>{member.replacedCompanyMember.fullName}</Link>
                        </span>
                    : null
                }
                {
                  member.replacedByCompanyMember
                    ? <span>
                          &nbsp;- replaced by <Link to={route(Routes.system.members.show, { id: member.replacedByCompanyMember.id })}>{member.replacedByCompanyMember.fullName}</Link>
                        </span>
                    : null
                }
                &nbsp;
                <Button
                  type="button"
                  color="primary"
                  size="sm"
                  onClick={() => {
                    this.showCompanyReplacementModal = true
                    this.renderCompanyReplacementModal = true
                  }}
                >Change</Button>
              </div>
            </div>
          </div>

          <hr/>

          <h6 className="text-muted">Network Settings</h6>

          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Chapter</label>
                <div className="input-group">
                  <input
                    className="form-control"
                    type="text"
                    disabled
                    value={member.chapter ? member.chapter.name : 'None'}
                  />
                  <div className="input-group-append">
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => {
                        this.showUpdateChapterModal = true
                        this.renderUpdateChapterModal = true
                      }}>
                      Change
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Category</label>
                <div className="input-group">
                  <input
                    className="form-control"
                    type="text"
                    disabled
                    value={member.category ? member.category.name : 'None'}
                  />
                  <div className="input-group-append">
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => {
                        this.showUpdateCategoryModal = true
                        this.renderUpdateCategoryModal = true
                      }}>
                      Change
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="col-md-6">
              <div className="form-group">

                {
                  this.applicationStatusFormHelper.renderSelectInput({
                    name: 'applicationStatus',
                    label: 'Application Status',
                    options: [{ value: '', text: 'None' }].concat([
                      'No Payment Provided',
                      'Declined/Invalid Credit/Debit Card',
                      'Company Replacement Application - No Payment',
                      'Chapter Transfer Application - No Payment',
                      'Application has no email',
                    ].map(s => ({ value: s, text: s }))),
                    onChange: () => this.submitApplicationStatus(),
                  })
                }

                <label>Notes</label>
                <NotesList
                  eventBus={this.applicationNotesEventBus}
                  noteType="application"
                  memberId={member.id}
                />
              </div>
            </div>
          </div>

          <h6 className="text-muted">Sponsorship Credits</h6>

          {
            this.member
              ? <ManageSponsorCredits memberId={this.member.id}/>
              : null
          }

          {
            this.renderUpdateChapterModal
              ? <UpdateMemberChapterModal
                isOpen={this.showUpdateChapterModal}
                toggle={() => this.showUpdateChapterModal = false}
                onClosed={() => this.renderUpdateChapterModal = false}
                onSaved={() => {
                  this.showUpdateChapterModal = false
                  this.loadMember()
                }}
                memberId={this.props.memberId}/>
              : null
          }

          {
            this.renderUpdateCategoryModal
              ? <UpdateMemberCategoryModal
                isOpen={this.showUpdateCategoryModal}
                toggle={() => this.showUpdateCategoryModal = false}
                onClosed={() => this.renderUpdateCategoryModal = false}
                onSaved={() => {
                  this.showUpdateCategoryModal = false
                  this.loadMember()
                }}
                memberId={this.props.memberId}/>
              : null
          }

          {
            this.renderCompanyReplacementModal
              ? <UpdateCompanyReplacementModal
                isOpen={this.showCompanyReplacementModal}
                toggle={() => this.showCompanyReplacementModal = false}
                onClosed={() => this.renderCompanyReplacementModal = false}
                chapterId={this.member ? this.member.chapterId : undefined}
                memberId={this.props.memberId}
                onSaved={() => {
                  this.showCompanyReplacementModal = false
                  this.loadMember()
                }}
              />
              : null
          }
        </>
        : null
      }
    </LoadingPanel>
  }

  render () {
    return this.error
      ? <div className="alert alert-error">{this.error}</div>
      : this.renderContent()
  }
}
