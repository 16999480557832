import * as React from "react"
import BaseView from "../BaseView"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { observable } from "mobx"
import { observer } from "mobx-react"
import ApiClient from "../../api/ApiClient"
import { createLazyResource } from "../../common/Util"
import { BarLoader } from "react-spinners"
import Member from "../../models/Member"
import { Button, Nav, NavItem, NavLink, TabContent } from "reactstrap"
import classNames from 'classnames'
import { createBrowserHistory } from 'history'
import MemberSidebar from "../../components/MemberSidebar"
import MemberProfile from "../../components/MemberProfile"
import LazyTabPane from "../../components/LazyTabPane"
import LazyResourcePanel from "../../components/LazyResourcePanel"
import MemberBusinessSidebar from "../../components/MemberBusinessSidebar"
import MemberBusiness from "../../components/MemberBusiness"
import AuthStore from "../../stores/AuthStore"
import { LinkContainer } from "react-router-bootstrap"
import { TIPS_VIEW_TABS } from "./MemberTipsView"
import { InviteGuestModal } from "../../components/InviteGuestModal"
import MemberNetworkInfo from "../../components/MemberNetworkInfo"
import { RouteComponentProps } from "react-router"
import MemberTestimony from "../../components/MemberTestimony";

type Props = {
  match: {
    params: {
      tab?: string
    }
  }
} & RouteComponentProps

export const TABS = {
  profile: 'profile',
  business: 'business',
  network: 'network',
  testimony: 'testimony'
}

@observer
export default class MemberProfilesView extends BaseView<Props> {
  @observable
  private showInviteGuestModal = false

  @observable private member = createLazyResource<Member>(() => {
    if (!AuthStore.getUser()!.member) {
      return Promise.resolve({ data: { member: {} } })
    }

    return ApiClient.query(
      `
member {
  *
  crackMyCodeLandingUrl
  codebreakerEbookUrl
  address {
    *
  }
  shippingAddress {
    *
  }
  chapter {
    *
  }
  business {
    *
    address {
      *
    }
  }
  testimony{
    *
 }
}
      `,
      {
        where: [{ id: AuthStore.getUser()!.member!.id }]
      }
    )
  }, response => new Member(response.data.member))

  @observable private activeTab = TABS.profile

  private history = createBrowserHistory()

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>My Profiles</h1>
        {AuthStore.getUser()!.member &&
        <ul className="content-header-actions">
          <li>
            <Button color="primary" onClick={() => this.showInviteGuestModal = true}><i className="fa fa-user"/> Invite A Guest</Button>
          </li>
          <li>
            <LinkContainer to={{ pathname: route(Routes.member.tips, { tab: TIPS_VIEW_TABS.sendTip }) }}>
              <Button color="primary"><i className="fa fa-money"/> Pass A New Tip</Button>
            </LinkContainer>
          </li>
        </ul>}
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    this.activeTab = this.props.match.params.tab || TABS.profile
    this.member.refresh().then(() => {
    })
  }

  private onMemberInvalidated = () => {
    this.member.invalidate().then(() => {
    })
  }

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(route(Routes.member.profile, { tab: this.activeTab }))
    }
  }

  render (): React.ReactNode {
    return <LazyResourcePanel resource={this.member} initialLoader={<BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>}>
      {member => <>
        <div className="card">
          <div className="card-header">
            <Nav tabs className="card-header-tabs">
              <NavItem className={classNames({ active: this.activeTab === TABS.profile })}>
                <NavLink
                  onClick={() => {
                    this.setTab(TABS.profile)
                  }}
                >My Profile Information</NavLink>
              </NavItem>
              <NavItem className={classNames({ active: this.activeTab === TABS.business })}>
                <NavLink
                  onClick={() => {
                    this.setTab(TABS.business)
                  }}
                >Business Information</NavLink>
              </NavItem>
              <NavItem className={classNames({ active: this.activeTab === TABS.network })}>
                <NavLink
                  onClick={() => {
                    this.setTab(TABS.network)
                  }}
                >Network Information</NavLink>
              </NavItem>
              <NavItem className={classNames({ active: this.activeTab === TABS.testimony })}>
                <NavLink
                  onClick={() => {
                    this.setTab(TABS.testimony)
                  }}
                >Success Story</NavLink>
              </NavItem>
            </Nav>
          </div>
          <div className="card-body">
            <TabContent activeTab={this.activeTab}>
              <LazyTabPane tabId={TABS.profile} activeTab={this.activeTab}>
                <div className="member-detail-pane">
                  <MemberSidebar member={member} onMemberInvalidated={this.onMemberInvalidated}/>
                  <div className="member-detail-content">
                    <MemberProfile
                      member={member}
                      onMemberInvalidated={this.onMemberInvalidated}
                    />
                  </div>
                </div>
              </LazyTabPane>
              <LazyTabPane tabId={TABS.business} activeTab={this.activeTab}>
                <div className="member-detail-pane">
                  <MemberBusinessSidebar
                    member={member}
                    onMemberInvalidated={this.onMemberInvalidated}
                  />
                  <div className="member-detail-content">
                    <MemberBusiness
                      member={member}
                      onMemberInvalidated={this.onMemberInvalidated}
                    />
                  </div>
                </div>
              </LazyTabPane>
              <LazyTabPane tabId={TABS.network} activeTab={this.activeTab}>
                <div className="member-detail-pane">
                  <MemberSidebar
                    member={member}
                    onMemberInvalidated={this.onMemberInvalidated}
                  />
                  <div className="member-detail-content">
                    <MemberNetworkInfo
                      member={member}
                    />
                  </div>
                </div>
              </LazyTabPane>
              <LazyTabPane tabId={TABS.testimony} activeTab={this.activeTab}>
                <div className="member-detail-pane">
                  <MemberSidebar
                    member={member}
                    onMemberInvalidated={this.onMemberInvalidated}
                  />
                  <div className="member-detail-content">
                    <MemberTestimony
                      member={member}
                      // testimony={this.testimony}
                      onMemberInvalidated={this.onMemberInvalidated}
                    />
                  </div>
                </div>
              </LazyTabPane>
            </TabContent>
          </div>
        </div>

        <InviteGuestModal
          isOpen={this.showInviteGuestModal}
          toggle={() => this.showInviteGuestModal = !this.showInviteGuestModal}
          onCancel={() => this.showInviteGuestModal = false}
          onSaved={() => {
            this.showInviteGuestModal = false
          }}
        />
      </>}
    </LazyResourcePanel>
  }
}
