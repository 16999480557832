import { observer } from "mobx-react"
import * as React from "react"
import { Button } from "reactstrap"
import { formatNumber } from "../../common/Util"

@observer
export class TableViewPagination extends React.Component<{
  numberOfPages: number,
  currentPage: number,
  setPage: (page: number) => void,
}> {
  render (): React.ReactNode {
    return <div className="table-view-pagination">
      <Button
        color="primary"
        disabled={this.props.currentPage <= 1}
        onClick={() => this.props.setPage(this.props.currentPage - 1)}
      ><i className="fa fa-chevron-left"/></Button>
      <div className="current-page-indicator">Page {formatNumber(this.props.currentPage)} of {formatNumber(this.props.numberOfPages)}</div>
      <Button
        color="primary"
        disabled={this.props.currentPage >= this.props.numberOfPages}
        onClick={() => this.props.setPage(this.props.currentPage + 1)}
      ><i className="fa fa-chevron-right"/></Button>
    </div>
  }
}