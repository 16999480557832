import React from "react"
import { observable } from "mobx"
import { ApiTableViewAdapter } from "../table-view/ApiTableViewAdapter"
import { QueryWhereClause } from "../../api/ApiClient"
import { Moment } from "moment-timezone/moment-timezone"
import Member from "../../models/Member"
import SentSystemMessage from "../../models/SentSystemMessage"
import { observer } from "mobx-react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import SentSystemMessageDetail from "./SentSystemMessageDetail"
import { ManagedChooseableColumn } from "../table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "../table-view/ManagedTableView";
import SentSystemMessagesCsvTransformer from "../../models/renderers/SentSystemMessagesCsvTransformer";
import DateFilter from '../table-view/filters/DateFilter'
import SelectFilter, { SelectFilterTargetType } from '../table-view/filters/SelectFilter'
import AsyncSelectFilter, { AsyncSelectFilterTargetType } from '../table-view/filters/AsyncSelectFilter'
import { loadMemberOptions } from '../../api/AsyncHelpers'
import BaseFilter from '../table-view/filters/BaseFilter'

type Props = {
  memberId?: number
  stateKey: string
}

@observer
export default class SentSystemMessages extends React.Component<Props> {
  constructor (props: Props) {
    super(props)

    if (!this.props.memberId) {
      this.filters.push(new AsyncSelectFilter('member', AsyncSelectFilterTargetType.Scope, 'member', 'Member', loadMemberOptions()))
    }
  }

  @observable availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'createdAt',
      column: {
        title: 'Date',
        accessor: 'createdAt',
        sortable: true,
        renderItem: (value: Moment) => value.format('MM/DD/YYYY HH:mm Z'),
      },
      default: true,
    },
    {
      id: 'members',
      column: {
        title: 'Member',
        accessor: 'members',
        renderItem: (value: Member[], item) => value.map(m => m.fullName).join(', '),
      },
      default: true,
    },
    {
      id: 'recipient',
      column: {
        title: 'To',
        accessor: 'recipient',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'subject',
      column: {
        title: 'Subject',
        accessor: 'subject',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'status',
      column: {
        title: 'Status',
        accessor: 'status',
        sortable: true,
        renderItem: (value: string | null, item: SentSystemMessage) => (value === 'Queued' && item.scheduledAt) ? <div>{value}<br/>{item.scheduledAt.format('M/D @ h:mma z')}</div> : value,
      },
      default: true,
    },
    {
      id: 'channel',
      column: {
        title: 'Channel',
        accessor: 'channel',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'platform',
      column: {
        title: 'Platform',
        accessor: 'platform',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'template',
      column: {
        title: 'Template',
        accessor: 'systemMessageTemplate.templateName',
        sortable: true,
      },
      default: true,
    },
    {
      id: 'view',
      column: {
        title: 'View',
        headerClassName: 'justify-content-center',
        itemClassName: 'text-center',
        renderItem: (value: any, item: SentSystemMessage) => <a href="#" onClick={(ev) => {
          this.viewDetails(item.id)
          ev.preventDefault()
        }
        }><i className="fa fa-search"/></a>
      },
      default: true,
      fixed: true,
    },
  ]

  private tableViewRef = React.createRef<ManagedTableView>()

  @observable private detailId?: number
  @observable private showDetailModal = false

  private viewDetails = (id: number) => {
    this.detailId = id
    this.showDetailModal = true
  }

  private sentSystemMessagesAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      if (this.props.memberId) {
        where.push({ '_scope': 'member', 'value': this.props.memberId })
      }

      return {
        query: `
      sentSystemMessages {
        *

        systemMessageTemplate {
          templateName
        }

        members {
          fullName
        }
      }
    `,
        where: where,
      }
    },
    'sentSystemMessages',
    SentSystemMessage
  )

  render () {
    return <>
      {this.renderTable()}
      {
        this.detailId
          ? <Modal
            isOpen={this.showDetailModal}
            onClosed={() => this.detailId = undefined}
            toggle={() => this.showDetailModal = false}
            size="xl"
          >
            <ModalHeader toggle={() => this.showDetailModal = false}>View Message Details</ModalHeader>
            <ModalBody>
              <SentSystemMessageDetail sentSystemMessageId={this.detailId}/>
            </ModalBody>
          </Modal>
          : null
      }
    </>
  }

  private filters: BaseFilter<any>[] = [
    new SelectFilter('status', SelectFilterTargetType.Field, 'status', 'Status', [
      'Sent',
      'Delivered',
      'Queued',
      'Skipped',
      'Bounced',
      'Rejected',
      'Opened',
      'Clicked',
    ].map(t => ({ value: t, label: t }))),
    new DateFilter('createdAt', 'createdAt', 'Date Sent'),
  ]

  private renderTable = () => {
    return <ManagedTableView
      stateKey={this.props.stateKey}
      ref={this.tableViewRef}
      searchLabel="Find A Message"
      adapter={this.sentSystemMessagesAdapter}
      defaultSort={{ sortKey: 'createdAt', descending: true }}
      availableColumns={this.availableColumns}
      exportTransformer={SentSystemMessagesCsvTransformer}
      filters={this.filters}
    />
  }
}
