import * as React from "react"
import { SyntheticEvent } from "react"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { observer } from "mobx-react"
import { Button } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import Util, { logException, modelToSnakeCase } from "../common/Util"
import { toast } from "react-toastify"
import SupportArea from "../models/SupportArea"

type Props = {
  supportArea: SupportArea
  onCancel: () => void
  onSaved: () => void
}

type EditFormState = {
  name: string
}

@observer
export default class SupportAreaBasicInfoForm extends React.Component<Props, never> {
  @observable
  private formState = new FormState<EditFormState>({
    name: this.props.supportArea.name || '',
  })

  @observable
  private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable
  private submitting = false

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.supportAreas.update(this.props.supportArea.id, modelToSnakeCase(this.formHelper.toObject()))
      .then(() => {
        toast.success('Support Area Updated')
        this.onSaved()
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private onCancelClicked = () => {
    this.props.onCancel()
  }

  private onSaved = () => {
    this.props.onSaved()
  }

  render (): React.ReactNode {
    return <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      <div className="form-row">
        <div className="col-sm-12">
          {this.formHelper.renderTextInput({
            name: 'name',
            type: 'text',
            label: 'Support Area Name',
          })}
        </div>
      </div>

      <div className="form-buttons">
        <Button type="button" color="" onClick={() => this.onCancelClicked()}>Cancel</Button>
        <ButtonLoader type="submit" color="success" loading={this.submitting}>Save Changes</ButtonLoader>
      </div>
    </form>
  }
}