import { observer } from "mobx-react"
import React, { SyntheticEvent } from "react"
import { Alert, Button, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent } from "reactstrap"
import classNames from "classnames"
import { observable } from "mobx"
import LazyTabPane from "../LazyTabPane"
import SentSystemMessage from "../../models/SentSystemMessage"
import { BarLoader } from "react-spinners"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import Util, { modelToSnakeCase } from "../../common/Util"
import JSONPretty from "react-json-pretty"
import 'react-json-pretty/themes/monikai.css'
import _ from 'lodash';
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import FormHelper from "../../forms/FormHelper"
import AppStateStore from "../../stores/AppStateStore"
import { submitFormRequest } from "../../api/ApiHelper"
import { route } from "../../routes/routes"
import { toast } from "react-toastify"
import SentSystemMessageEvent from "../../models/SentSystemMessageEvent"

type Props = {
  sentSystemMessageId: number
}

const TABS = {
  preview: 'preview',
  data: 'data',
  events: 'events',
  send: 'send',
}

@observer
export default class SentSystemMessageDetail extends React.Component<Props> {
  private iframeRef = React.createRef<HTMLIFrameElement>()
  @observable private activeTab = TABS.preview
  @observable private message?: SentSystemMessage
  @observable private error?: string
  private rendered = false

  private initialFormState = {
    emailAddress: '',
  }

  @observable private formState = new FormState(this.initialFormState)

  @observable private formErrors = new ErrorBag()
  @observable private submitting = false

  @observable private detailSystemMessageEvent?: SentSystemMessageEvent
  @observable private showSystemMessageEventDetailModal = false

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()

    this.submitting = true
    AppStateStore.showModalSpinner()

    submitFormRequest(
      ApiClient.getInstance()
        .post(route(ApiRoutes.systemMessages.redeliver, { messageId: this.props.sentSystemMessageId }), modelToSnakeCase(this.formState.toObject())),
      this.formState,
      this.formErrors,
      () => {
        toast.success('Message Sent')
        this.formState.set('emailAddress', '')
      }
    )
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private loadMessage = () => {
    ApiClient.query(
      `
sentSystemMessage {
  *
  renderedOutput
  
  sentSystemMessageEvents {
    *
  }
}
`,
      {
        where: [{ id: this.props.sentSystemMessageId }],
      }
    )
      .then(response => this.message = new SentSystemMessage().init(response.data.sentSystemMessage))
      .catch(error => {
        this.error = Util.extractErrorMessage(error)
      })
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.message && this.iframeRef.current && !this.rendered) {
      this.rendered = true
      this.iframeRef.current.src = 'javascript:void((function(){var script = document.createElement(\'script\');' +
        'script.innerHTML = "(function() {' +
        'document.open();document.domain=\'' + document.domain +
        '\';document.close();})();";' +
        'document.write(' + JSON.stringify(this.message.renderedOutput) + ');})())'
    }
  }

  componentDidMount (): void {
    this.loadMessage()
  }

  private setTab = (tab: string) => {
    this.activeTab = tab
  }

  private renderMeta = (message: SentSystemMessage) => {
    if (message.channel === 'sms') {
      return <>
        <div className="row">
          <div className="col-6">
            <b>Message Type</b><br/>
            SMS
          </div>
          <div className="col-6">
            <b>Sent</b><br/>
            {message.createdAt.format('MM/DD/YY HH:mm Z')}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6">
            <b>To</b><br/>
            {message.extractTo().join(', ')}
          </div>
          <div className="col-6">
            <b>Status</b><br/>
            {message.status}
          </div>
        </div>
      </>
    } else if (message.channel === 'email') {
      return <>
        <div className="row">
          <div className="col-6">
            <b>Message Type</b><br/>
            Email
          </div>
          <div className="col-6">
            <b>Sent</b><br/>
            {message.createdAt.format('MM/DD/YY HH:mm Z')}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6">
            <b>To</b><br/>
            {message.extractTo().join(', ')}
          </div>
          <div className="col-6">
            <b>From</b><br/>
            {message.extractFrom()}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6">
            <b>Subject</b><br/>
            {message.meta.subject}
          </div>
          <div className="col-6">
            <b>Status</b><br/>
            {message.status}
          </div>
        </div>
      </>
    } else {
      return null
    }
  }

  private showEventDetails (ev: SentSystemMessageEvent) {
    this.showSystemMessageEventDetailModal = true
    this.detailSystemMessageEvent = ev
  }

  render () {
    return this.error
      ? <Alert color="danger">{this.error}</Alert>
      : this.message
        ? <div>
          <Nav tabs className="card-header-tabs">
            <NavItem className={classNames({ active: this.activeTab === TABS.preview })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.preview)
                }}
              >Preview</NavLink>
            </NavItem>
            <NavItem className={classNames({ active: this.activeTab === TABS.data })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.data)
                }}
              >Data</NavLink>
            </NavItem>
            <NavItem className={classNames({ active: this.activeTab === TABS.events })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.events)
                }}
              >Events</NavLink>
            </NavItem>
            {
              this.message && this.message.channel === 'email'
                ? <NavItem className={classNames({ active: this.activeTab === TABS.send })}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.send)
                    }}
                  >Send</NavLink>
                </NavItem>
                : null
            }
          </Nav>
          <div className="mt-4">
            <TabContent activeTab={this.activeTab}>
              <LazyTabPane tabId={TABS.preview} activeTab={this.activeTab}>
                {this.renderMeta(this.message)}
                <iframe ref={this.iframeRef} style={{ width: '100%', height: '100%', minHeight: 600 }} className="mt-4"/>
              </LazyTabPane>
              <LazyTabPane tabId={TABS.data} activeTab={this.activeTab}>
                <h6>Template Data</h6>
                <JSONPretty
                  json={this.message.data}
                />

                <h6>Message Metadata</h6>
                <JSONPretty json={this.message.meta}/>
              </LazyTabPane>
              <LazyTabPane tabId={TABS.events} activeTab={this.activeTab}>
                {
                  this.message.sentSystemMessageEvents.length
                    ? <table className="table table-striped">
                      <thead>
                      <tr>
                        <th>Date</th>
                        <th>Event</th>
                        <th>Error</th>
                        <th>Error Severity</th>
                        <th style={{ textAlign: 'center' }}>View</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        _.orderBy(this.message.sentSystemMessageEvents, e => e.eventTimestamp).map(ev => <tr key={ev.id}>
                          <td>{ev.eventTimestamp.format('MM/DD/YY HH:mm Z')}</td>
                          <td>{ev.eventType}</td>
                          <td>{ev.error}</td>
                          <td>{ev.errorSeverity}</td>
                          <td style={{ textAlign: 'center' }}><a href="#" onClick={e => {
                            e.preventDefault()
                            this.showEventDetails(ev)
                          }}><i className="fa fa-search"/></a></td>
                        </tr>)
                      }
                      </tbody>
                    </table>
                    : <div>There are no events for this message</div>
                }
              </LazyTabPane>
              <LazyTabPane tabId={TABS.send} activeTab={this.activeTab}>
                <p>Enter an email address below that you would like to send this email to</p>
                <form onSubmit={this.submit}>
                  <div className="form-row">
                    <div className="col-12">
                      {
                        this.formHelper.renderTextInput({
                          label: 'Email Address',
                          name: 'emailAddress',
                          disabled: this.submitting,
                        })
                      }
                    </div>
                  </div>
                  <div className="form-buttons">
                    <Button type="submit" color="primary">Send Email</Button>
                  </div>
                </form>
              </LazyTabPane>
            </TabContent>
          </div>
          {
            this.detailSystemMessageEvent
              ? <Modal
                size="lg"
                onClosed={() => this.detailSystemMessageEvent = undefined}
                isOpen={this.showSystemMessageEventDetailModal}
                toggle={() => this.showSystemMessageEventDetailModal = false}
              >
                <ModalHeader toggle={() => this.showSystemMessageEventDetailModal = false}>
                  Event Detail
                </ModalHeader>
                <ModalBody>
                  <div className="row">
                    <div className="col-6">
                      <b>Event Timestamp</b><br/>
                      {this.detailSystemMessageEvent.eventTimestamp.format('MM/DD/YY HH:mm Z')}
                    </div>
                    <div className="col-6">
                      <b>Event Type</b><br/>
                      {this.detailSystemMessageEvent.eventType}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <b>Error</b><br/>
                      {this.detailSystemMessageEvent.error || '---'}
                    </div>
                    <div className="col-6">
                      <b>Error Severity</b><br/>
                      {this.detailSystemMessageEvent.errorSeverity || '---'}
                    </div>
                  </div>
                  <JSONPretty
                    json={this.detailSystemMessageEvent.eventData}
                  />
                </ModalBody>
              </Modal>
              : null
          }
        </div>
        : <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
  }
}
