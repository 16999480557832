import { observer } from "mobx-react";
import * as React from "react";



@observer
export default class NtsBadgeView extends React.Component {
  getBadgeColor = (): { background: string; foreground: string; border: string } => {
    return { background: "#090", foreground: "#fff", border: "#00c900"}
  };

  render(): React.ReactNode {
    return (
      <div>
        <label
          style={{
            backgroundColor: this.getBadgeColor().background,
            color: this.getBadgeColor().foreground,
            borderWidth: 1,
            borderColor: this.getBadgeColor().border,
            borderRadius: 5,
            padding: 2,
            fontSize: 12,
            width: 50,
            height: 24,
            textAlign: "center",
            borderStyle: "solid",
            float: "right"
          }}
        >
          <span><i className="fa fa-check-circle text-success" style={{ fontSize: '110%' }}/> NTS</span>
        </label>
      </div>
    );
  }
}
