import * as React from "react"
import { observer } from "mobx-react"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import Tip from "../models/Tip"
import { observable } from "mobx"
import Util, { modelToCamelCase } from "../common/Util"
import TipCard from "./TipCard"
import classNames from 'classnames'
import axios, { CancelTokenSource } from "axios"
import * as _ from "lodash"
import Config from "../common/Config"
import { CircleLoader } from "react-spinners"
import VisibilitySensor from 'react-visibility-sensor'
import { Moment } from "moment-timezone"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import pluralize from 'pluralize'
import ExportButton, { ExportButtonFetchCallback } from "./ExportButton"
import TipsCsvTransformer from "../models/renderers/TipsCsvTransformer"
import AppStateStore from "../stores/AppStateStore"
import { route } from "../routes/routes"
import ButtonLoader from "./ButtonLoader"

const FETCH_SIZE = 100

export type TipCardListProps = {
  targetMemberId?: number
  sourceMemberId?: number
  chapterId?: number
  memberId?: number
  categoryId?: number
  targetChapterId?: number
  sourceChapterId?: number
  startDate?: Moment
  endDate?: Moment
  showFilters?: boolean
  allowPrint?: boolean
  allowExport?: boolean
  escrowTipsView?: boolean
  status?: string[]
  privacy?: string[]
  tipType?: string[]
}

@observer
export default class TipCardList extends React.Component<TipCardListProps> {
  static defaultProps = {
    allowExport: true,
  }
  @observable private tips: Tip[]
  @observable private error?: String
  @observable private loading = false
  @observable private loaded = false

  private fetchDataCancelToken?: CancelTokenSource
  private canSeeBottom = false
  @observable private _totalRecords = 0
  private currentPage = 0
  private fetchPage = 0

  @observable private selectedTips: Tip[] = []

  private tipCardListRef = React.createRef<HTMLDivElement>()

  get totalRecords (): number | undefined {
    return this.loaded ? this._totalRecords : undefined
  }

  componentDidMount (): void {
    this.fetchData()
    this.fetchData.flush()
  }

  private invalidateTips = () => {
    this.currentPage = 0
    this.fetchPage = 0
    this.fetchData()
    this.fetchData.flush()
  }

  componentDidUpdate (prevProps: Readonly<TipCardListProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if ([
      'targetMemberId',
      'sourceMemberId',
      'chapterId',
      'memberId',
      'categoryId',
      'targetChapterId',
      'sourceChapterId',
      'startDate',
      'endDate',
      'status',
      'privacy',
      'tipType',
    ].filter(k => prevProps[k] !== this.props[k])
      .length) {
      this.currentPage = 0
      this.fetchPage = 0
      this.fetchData()
      this.fetchData.flush()
    }
  }

  private buildFetchWhere = () => {
    const where = []

    if (this.props.sourceMemberId) {
      where.push({ sourceMemberId: this.props.sourceMemberId })
    }

    if (this.props.targetMemberId) {
      where.push({ targetMemberId: this.props.targetMemberId })
    }

    if (this.props.sourceChapterId) {
      where.push({ sourceChapterId: this.props.sourceChapterId })
    }

    if (this.props.targetChapterId) {
      where.push({ targetChapterId: this.props.targetChapterId })
    }

    if (this.props.chapterId) {
      where.push({ _scope: 'chapter', value: this.props.chapterId })
    }

    if (this.props.memberId) {
      where.push({ _scope: 'member', value: this.props.memberId })
    }

    if (this.props.categoryId) {
      where.push({ targetCategoryId: this.props.categoryId })
    }

    if (this.props.startDate) {
      where.push({ id: 'datePassed', op: '>=', value: this.props.startDate.format('YYYY-MM-DD') })
    }

    if (this.props.endDate) {
      where.push({ id: 'datePassed', op: '<=', value: this.props.endDate.format('YYYY-MM-DD') })
    }

    if (this.props.escrowTipsView) {
      where.push({ _scope: 'escrow' })
    }

    if (this.props.status && this.props.status.length) {
      where.push({ id: 'status', op: 'in', value: this.props.status })
    }

    if (this.props.privacy && this.props.privacy.length) {
      const map = {
        'Private': 1,
        'Normal': 0,
      }

      where.push({ id: 'isPrivate', op: 'in', value: this.props.privacy.map(p => map[p]) })
    }

    if (this.props.tipType && this.props.tipType.length) {
      where.push({ id: 'tipType', op: 'in', value: this.props.tipType })
    }

    return where
  }

  private fetchData = _.debounce(() => {
    if (this.fetchDataCancelToken) {
      this.fetchDataCancelToken.cancel()
    }
    this.fetchDataCancelToken = axios.CancelToken.source()

    this.loading = true

    const where = this.buildFetchWhere()

    this.loading = true

    ApiClient.query(`
tips {
  *

  sourceMember {
    id
    fullName
    profileImageUrl

    category {
      id
      name
    }
  }

  targetMember {
    id
    fullName
    profileImageUrl

    chapter {
      id
      name
    }

    category {
      id
      name
    }
  }

  targetChapter {
    *
  }

  targetCategory {
    id
    name
  }

  extendedNetworkMember {
    name
  }
}
    `,
      {
        where: where,
        order: [{ id: 'datePassed', desc: true }],
        offset: this.fetchPage * FETCH_SIZE,
        limit: FETCH_SIZE,
        returnTotal: true,
      })
      .then(response => {
        this.error = undefined

        const receivedPage = response.data._meta.offset / FETCH_SIZE

        if (receivedPage === this.fetchPage) {
          if (receivedPage === 0) {
            this.tips = response.data.tips.map((r: {}) => new Tip().init(modelToCamelCase(r)))
          } else {
            this.tips = this.tips.concat(response.data.tips.map((r: {}) => new Tip().init(modelToCamelCase(r))))
          }

          this.currentPage = receivedPage
          this.fetchPage = this.currentPage + 1
        }

        this.loaded = true
        this._totalRecords = response.data._meta.total

        setTimeout(() => {
          if (this.canSeeBottom && this.tips.length < this._totalRecords) {
            this.fetchData()
            this.fetchData.flush()
          }
        }, 200)
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          this.error = Util.extractErrorMessage(error.response)
        }
      })
      .then(() => this.loading = false)
  }, Config.DEBOUNCE_TIME_MS)

  private handleVisibilityChange = (isVisible: boolean) => {
    this.canSeeBottom = isVisible

    if (this.canSeeBottom) {
      if (this.tips.length < this._totalRecords) {
        this.fetchData()
        this.fetchData.flush()
      }
    }
  }

  @observable private showPrintTipsModal = false
  @observable private renderPrintTipsModal = false
  @observable private numberOfPrintCopies = 1

  private promptPrintTips = () => {
    this.numberOfPrintCopies = 1
    this.showPrintTipsModal = true
    this.renderPrintTipsModal = true
  }

  private printSelectedTips = () => {
    this.isPrintingAll = false
    this.promptPrintTips()
  }

  private printTips = () => {
    if (this.isPrintingAll) {
      this.fetchAllTips(tips => {
        this.downloadTipsPdf(tips.map(t => t.id))
      }, error => {
        AppStateStore.showAlertModal('Error', error)
        this.isPrintingAll = false
      }, () => {
      })
    } else {
      this.downloadTipsPdf(this.selectedTips.map(t => t.id))
    }
  }

  private downloadTipsPdf = (tipIds: number[]) => {
    ApiClient.getInstance().post(route(ApiRoutes.tips.printTips), {
      tip_ids: tipIds,
      number_of_copies: this.numberOfPrintCopies,
    }, {
      responseType: 'blob',
    })
      .then(response => {
        const blob = new Blob([response.data], {
          type: 'application/pdf'
        })

        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, 'tips.pdf')
        } else {
          const blobUrl = URL.createObjectURL(blob)

          const link = document.createElement('a')
          link.setAttribute('href', blobUrl)
          link.setAttribute('download', 'tips.pdf')
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          link.remove()

          // automatically clean up the object url after some time
          setTimeout(() => URL.revokeObjectURL(blobUrl), 1000 * 60 * 5)
        }

        this.isPrintingAll = false
      })
      .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error)))
      .then(() => {
      })
  }

  private onTipChecked = (tip: Tip, checked: boolean) => {
    if (checked) {
      if (!_.find(this.selectedTips, t => t.id === tip.id)) {
        this.selectedTips.push(tip)
      }
    } else {
      this.selectedTips = this.selectedTips.filter(t => t.id !== tip.id)
    }
  }

  private renderError = () => {
    return <div className="alert alert-danger">{this.error}</div>
  }

  @observable private isPrintingAll = false

  private printAllTips = () => {
    this.isPrintingAll = true
    this.promptPrintTips()
  }

  private fetchAllTips: ExportButtonFetchCallback<Tip> = async (onFetched, onError, onFinished) => {
    try {
      const allTips: Tip[] = []
      let offset = 0
      let totalRecords = -1

      const where = this.buildFetchWhere()

      while (totalRecords === -1 || offset < totalRecords - 1) {
        const response = await ApiClient.query(`
tips {
  *

  sourceMember {
    *

    category {
      *
    }

    chapter {
      *
    }
  }

  targetMember {
    *

    chapter {
      *
    }

    category {
      *
    }
  }

  targetChapter {
    *
  }

  targetCategory {
    *
  }
}
    `,
          {
            where: where,
            order: [{ id: 'datePassed', desc: true }, 'id'],
            offset: offset,
            limit: 500,
            returnTotal: true,
          })

        response.data.tips.forEach((t: {}) => allTips.push(new Tip().init(t)))
        totalRecords = response.data._meta.total
        offset += response.data.tips.length
      }

      onFetched(allTips)
    } catch (err) {
      onError(Util.extractErrorMessage(err))
    }

    onFinished()
  }

  private renderCards = () => {
    return <>
      {
        this.props.showFilters
          ? <div/>
          : null
      }
      <div>
        {
          this.props.allowExport
            ? <ExportButton
              fetch={this.fetchAllTips}
              transformerClass={() => new TipsCsvTransformer()}
            />
            : null
        }
        {
          this.props.allowPrint
            ? <ButtonLoader
              className="ml-2"
              type="button"
              color="primary"
              onClick={this.printAllTips}
              loading={this.isPrintingAll}
            ><i className="fa fa-print"/> Print All</ButtonLoader>
            : null
        }
      </div>
      <div className={classNames('tip-cards', { loading: this.loading })} ref={this.tipCardListRef}>
        {
          this.tips.length
            ? this.tips.map(tip => <TipCard
              key={`${tip.id}`}
              tip={tip}
              showCheckbox={this.props.allowPrint || false}
              onChecked={checked => this.onTipChecked(tip, checked)}
              isChecked={!!_.find(this.selectedTips, t => t.id === tip.id)}
              onInvalidated={() => this.invalidateTips()}
            />)
            : null
        }

        {this.loading && <div className="infinite-scroll-container">
          <CircleLoader className="app-loading-spinner" color="#12497d"/> <span>Loading more records...</span>
        </div>}

        {this.loaded && this._totalRecords == 0 && <div className="infinite-scroll-empty">There are no tips that match your search</div>}
      </div>
      {
        (this.props.allowPrint)
          ? <div className={classNames('floating-print-tips-button', { 'is-visible': this.selectedTips.length > 0 })}>
            <Button
              type="button"
              color="secondary"
              onClick={() => this.selectedTips = []}
            >Clear Selection</Button>
            <Button
              type="button"
              color="primary"
              onClick={() => this.promptPrintTips()}
            >Print {this.selectedTips.length} selected {pluralize('tip', this.selectedTips.length)}</Button>
          </div>
          : null
      }
      <VisibilitySensor
        partialVisibility={true}
        onChange={this.handleVisibilityChange}
        scrollCheck={true}
      >
        <div style={{ height: 30 }}/>
      </VisibilitySensor>

      {
        this.renderPrintTipsModal
          ? <Modal isOpen={this.showPrintTipsModal} onClosed={() => this.renderPrintTipsModal = false} toggle={() => this.showPrintTipsModal = false}>
            <ModalHeader toggle={() => this.showPrintTipsModal = false}>Print Tips</ModalHeader>
            <ModalBody>
              <p>You are about to print {this.isPrintingAll ? 'all visible tips' : `${this.selectedTips.length} ${pluralize('tip', this.selectedTips.length)}`}.</p>
              <p>
                How many copies of each tip would you like to print?<br/>
                <select
                  className="form-control"
                  onChange={ev => this.numberOfPrintCopies = Number(ev.target.value)}
                  value={String(this.numberOfPrintCopies)}>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  this.showPrintTipsModal = false
                  this.isPrintingAll = false
                }}
              >Cancel</Button>
              <Button
                type="button"
                color="primary"
                onClick={() => {
                  this.showPrintTipsModal = false
                  this.printTips()
                }}
              >Print</Button>
            </ModalFooter>
          </Modal>
          : null
      }
    </>
  }

  render (): React.ReactNode {
    return this.error ? this.renderError() : this.tips ? this.renderCards() : null
  }
}
