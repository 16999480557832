import { Moment } from "moment-timezone"
import BaseModel from "./BaseModel"
import Member from "./Member"
import Category from "./Category"
import Application from "./Application"
import EventGuest from "./EventGuest"
import AuditLog from "./AuditLog"
import User from "./User"
import AuthUser, { UserRole } from "./AuthUser"
import _ from 'lodash';

export default class Guest extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  archivedAt: Moment | null
  firstName: string
  lastName: string
  fullName: string
  phone: string
  email: string
  companyName: string
  message: string
  status: string
  bankCode: string | null
  bankCodeDocumentUrl: string | null

  createdByUserId: number | null
  createdByMemberId: number | null
  convertedMemberId: number | null
  categoryId: number | null

  numberOfMeetingsAttended: number | undefined

  createdByUser: User | null
  createdByMember: Member | null
  convertedMember: Member | null
  category: Category | null
  applications: Application[]
  guestEvents: EventGuest[]
  auditLogs: AuditLog[]

  get activeApplication () {
    return _.find(this.applications, app => app.status != 'Denied')
  }

  canUserSendApplication (user: AuthUser): boolean {
    if (user.hasAnyRole([UserRole.Admin, UserRole.Employee]) || user.isChairPosition('inspector')) {
      return true
    }

    if (this.activeApplication) {
      if (user.isBoardMember && this.activeApplication.targetChapterId == user.chapter.id) {
        return true
      }

      if (user.member && this.createdByMember && this.createdByMember.id == user.member.id) {
        return true
      }
    }

    if (this.createdByMember) {
      if (user.isBoardMember && this.createdByMember.chapterId == user.chapter.id) {
        return true
      }

      if (user.member && this.createdByMember.id == user.member.id) {
        return true
      }
    }

    if (user.isBoardMember && _.find(this.guestEvents, e => e.targetChapterId == user.chapter.id)) {
      return true
    }

    return false
  }

  setCreatedByUser (c: {}) {
    return c ? new User(c) : null
  }

  setCreatedByMember (m: {}) {
    return m ? new Member(m) : null
  }

  setConvertedMember (c: {}) {
    return c ? new Member(c) : null
  }

  setCategory (c: {}) {
    return c ? new Category(c) : null
  }

  setApplications (a: []) {
    return a ? a.map(aa => new Application().init(aa)) : []
  }

  setGuestEvents (a?: []) {
    return a ? a.map(a => new EventGuest().init(a)) : []
  }

  setAuditLogs (a?: []) {
    return a ? a.map(a => new AuditLog().init(a)) : []
  }

  getCasts () {
    return {
      'id': 'number',
      'createdAt': 'datetime',
      'updatedAt': 'datetime',
      'applicationSentAt': 'datetime',

      'createdByUserId': 'number',
      'createdByMemberId': 'number',
      'convertedMemberId': 'number',
      'categoryId': 'number',
    }
  }
}
