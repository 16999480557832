import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import classNames from "classnames"
import * as React from "react"
import { RefObject } from "react"
import * as _ from 'lodash'
import SelectInput, { LoadOptionsMethod, SelectOption } from "../components/inputs/SelectInput"
import DateTime from 'react-datetime'
import moment from "moment"
import FormError from "../components/FormError"
import { formatTime, normalizeUrl } from "../common/Util"

export type TextInputOptions = {
  ref?: RefObject<HTMLInputElement>
  name: string
  type?: string
  label?: string
  placeholder?: string
  prepend?: React.ReactNode
  append?: React.ReactNode
  additionalInputProps?: {}
  onChange?: (value: string) => void
  disabled?: boolean
  inputGroupProps?: {},
}

export function renderTextInput (formState: FormState<any>, formErrors: ErrorBag, options: TextInputOptions): React.ReactNode {
  const type = (options.type === 'time' || options.type === 'url') ? 'text' : options.type

  return (
    <div className="form-group">
      {options.label && <label htmlFor={options.name}>{options.label}</label>}
      <div className="input-group" {...options.inputGroupProps}>
        {options.prepend && (
          <div className="input-group-prepend">
              <span className="input-group-text">
                {options.prepend}
              </span>
          </div>
        )}
        <input type={type || 'text'}
               ref={options.ref}
               className={classNames('form-control', { 'is-invalid': formErrors.hasErrors(options.name) })}
               placeholder={options.placeholder}
               name={options.name}
               value={formState.get(options.name) || ''}
               disabled={options.disabled}
               onBlur={ev => {
                 if (options.type === 'time') {
                   ev.target.value = formatTime(ev.target.value)
                   formState.onChange(ev)
                   options.onChange && options.onChange(ev.target.value)
                 } else if (options.type === 'url') {
                   ev.target.value = normalizeUrl(ev.target.value)
                   formState.onChange(ev)
                   options.onChange && options.onChange(ev.target.value)
                 }
               }}
               onChange={ev => {
                 formState.onChange(ev)
                 options.onChange && options.onChange(ev.target.value)
               }}
               id={options.name}
               {...(options.additionalInputProps || {})}
        />
        {options.append && (
          <div className="input-group-append">
              <span className="input-group-text">
                {options.append}
              </span>
          </div>
        )}
      </div>
      <FormError errors={formErrors} fieldName={options.name}/>
    </div>
  )
}

export type TextAreaInputOptions = {
  name: string
  label?: string
  labelAccessory?: React.ReactNode
  placeholder?: string
  disabled?: boolean
  height?: number | string
}

export function renderTextAreaInput (formState: FormState<any>, formErrors: ErrorBag, options: TextAreaInputOptions): React.ReactNode {
  const style: {height?: string} = {}

  if (options.height) {
    style.height = _.isNumber(options.height) ? `${options.height}px` : options.height
  }
  return (
    <div className="form-group">
      <div style={{ display: 'flex' }}>
        {
          options.label
            ? <div style={{ marginRight: 10 }}><label htmlFor={options.name}>{options.label}</label></div>
            : null
        }
        {
          options.labelAccessory
            ? <div>{options.labelAccessory}</div>
            : null
        }
      </div>

      <div className="input-group">
          <textarea
            className={classNames('form-control', { 'is-invalid': formErrors.hasErrors(options.name) })}
            placeholder={options.placeholder}
            name={options.name}
            value={formState.get(options.name) || ''}
            onChange={formState.onChange}
            disabled={options.disabled}
            id={options.name}
            style={style}
          />
      </div>
      <FormError errors={formErrors} fieldName={options.name}/>
    </div>
  )
}

export type SelectInputOptions = {
  name: string
  options: {text: string, value: any, meta?: any}[]
  label?: string
  placeholder?: string
  prepend?: React.ReactNode
  prependContent?: boolean
  append?: React.ReactNode
  appendContent?: boolean
  onChange?: (value: any) => void
  disabled?: boolean
}

export function renderSelectInput (formState: FormState<any>, formErrors: ErrorBag, options: SelectInputOptions): React.ReactNode {
  return (
    <div className="form-group">
      {options.label && <label htmlFor={options.name}>{options.label}</label>}
      <div className="input-group">
        {options.prepend && (
          <div className="input-group-prepend">
            {options.prependContent ? options.prepend :
              <span className="input-group-text">
                {options.prepend}
              </span>}
          </div>
        )}
        <select
          className={classNames('form-control', { 'is-invalid': formErrors.hasErrors(options.name) })}
          placeholder={options.placeholder}
          name={options.name}
          onChange={ev => {
            formState.onChange(ev)
            options.onChange && options.onChange(ev.target.value)
          }}
          value={formState.get(options.name)}
          id={options.name}
          disabled={options.disabled}
        >
          {options.placeholder ? <option value="">{options.placeholder}</option> : null}
          {
            (!options.placeholder && !options.options.filter(o => o.value === formState.get(options.name)).length)
              ? <option value={String(formState.get(options.name))}>{formState.get(options.name) || '(choose)'}</option>
              : null
          }
          {_.map(options.options, (o, i) => <option key={i} value={String(o.value)}>{o.text}</option>)}
        </select>
        {options.append && (
          <div className="input-group-append">
            {options.appendContent ? options.append : <span className="input-group-text">
                {options.append}
              </span>}
          </div>
        )}
      </div>
      <FormError errors={formErrors} fieldName={options.name}/>
    </div>
  )
}

export type AsyncSelectInputOptions = {
  ref?: React.RefObject<SelectInput>
  name: string
  label?: string
  labelAccessory?: React.ReactNode
  loadOptions: LoadOptionsMethod
  defaultValue?: SelectOption | SelectOption[]
  value?: SelectOption
  onChange?: (option: SelectOption) => void
  minimumSearchCharacters?: number
  isMulti?: boolean
  disabled?: boolean
}

export function renderAsyncSelectInput (formState: FormState<any>, formErrors: ErrorBag | undefined, options: AsyncSelectInputOptions): React.ReactNode {
  return (
    <div className="form-group">
      <div style={{ display: 'flex' }}>
        {
          options.label
            ? <div style={{ marginRight: 10 }}><label htmlFor={options.name}>{options.label}</label></div>
            : null
        }
        {
          options.labelAccessory
            ? <div>{options.labelAccessory}</div>
            : null
        }
      </div>
      <div className="input-group">
        <SelectInput
          ref={options.ref}
          isMulti={options.isMulti || false}
          loadOptions={options.loadOptions}
          defaultValue={options.defaultValue}
          onChange={option => {
            formState.set(options.name, option ? (_.isArray(option) ? option.map(o => o.value) : option.value) : undefined)
            options.onChange && options.onChange(option)
          }}
          className={classNames('async-select-input', { 'is-invalid': (formErrors ? formErrors.hasErrors(options.name) : false) })}
          classNamePrefix="async-select-input"
          minimumSearchCharacters={options.minimumSearchCharacters}
          isDisabled={options.disabled || false}
        />
      </div>
      {
        formErrors
        ?<FormError errors={formErrors} fieldName={options.name}/>
        :null
      }
    </div>
  )
}

export type DatePickerInputOptions = {
  name: string
  inputProps?: {}
  label?: string
  onChange?: (val: string) => void
  prepend?: React.ReactNode
  append?: React.ReactNode
  disabled?: boolean
  readonly?: boolean
}

export function renderDatePickerInput (formState: FormState<any>, formErrors: ErrorBag, options: DatePickerInputOptions): React.ReactNode {
  return (
    <div className="form-group">
      {options.label && <label htmlFor={options.name}>{options.label}</label>}
      <div className="input-group">
        {options.prepend && (
          <div className="input-group-prepend">
            {options.prepend}
          </div>
        )}
        {
          options.disabled
            ? <input
              readOnly={options.readonly}
              type="text"
              className={classNames('form-control', { 'is-invalid': formErrors.hasErrors(options.name) })}
              value={formState.get(options.name) || ''}
              disabled={options.disabled}
            />
            : <DateTime
              inputProps={options.inputProps}
              input={!options.readonly}
              open={options.readonly ? true : undefined}
              className={classNames({ 'is-invalid': formErrors.hasErrors(options.name) })}
              value={formState.get(options.name) || ''}
              onChange={(v: any) => {
                formState.set(options.name, moment.isMoment(v) ? v.format('MM/DD/YYYY') : v)
                options.onChange && options.onChange(v)
              }}
              timeFormat={false}
              closeOnSelect={true}
            />
        }
        {options.append && (
          <div className="input-group-append">
            {options.append}
          </div>
        )}
      </div>
      <FormError errors={formErrors} fieldName={options.name}/>
    </div>
  )
}

export function renderTimePickerInput (formState: FormState<any>, formErrors: ErrorBag, options: DatePickerInputOptions): React.ReactNode {
  return (
    <div className="form-group">
      {options.label && <label htmlFor={options.name}>{options.label}</label>}
      <div className="input-group">
        <DateTime
          inputProps={options.inputProps}
          className={classNames({ 'is-invalid': formErrors.hasErrors(options.name) })}
          value={formState.get(options.name) || ''}
          onChange={(v: any) => formState.set(options.name, moment.isMoment(v) ? v.format('LT') : v)}
          dateFormat={false}
          closeOnSelect={true}
        />
      </div>
      <FormError errors={formErrors} fieldName={options.name}/>
    </div>
  )
}

export type CheckboxInputOptions = {
  name: string
  label?: string
  onChange?: (checked: boolean) => void
  helpContent?: React.ReactNode
  noMargin?: boolean
  disabled?: boolean
}

export function renderCheckboxInput (formState: FormState<any>, formErrors: ErrorBag, options: CheckboxInputOptions): React.ReactNode {
  return (
    <div className={options.noMargin ? '' : 'form-group'}>
      <div className="form-check form-checkbox">
        <label style={options.noMargin ? { marginBottom: 0 } : undefined}>
          <input type="checkbox"
                 className="form-check-input"
                 name={options.name}
                 disabled={options.disabled}
                 checked={formState.get(options.name)}
                 onChange={ev => {
                   formState.onChange(ev)
                   options.onChange && options.onChange(ev.target.checked)
                 }}
          />
          <span className="label-text">{options.label}</span>
        </label>
        {
          options.helpContent
            ? <div className="form-text text-muted">
              {options.helpContent}
            </div>
            : null
        }
      </div>
      <FormError errors={formErrors} fieldName={options.name}/>
    </div>
  )
}
