import { observer } from "mobx-react"
import classNames from 'classnames'
import * as React from "react"
import { ordinal } from "../common/Util"

@observer
export default class RankWidget extends React.Component<{
  title: string,
  rank: number,
  label: string,
  classes: string[],
}> {

  render (): React.ReactNode {
    return <div className="rank-widget">
      <div className="rank-widget-title">{this.props.title}</div>
      <div className="rank-widget-container">
        <div className={classNames(['rank-number', ...this.props.classes])}>
          {ordinal(this.props.rank)}
        </div>
        <div className="rank-label">
          {this.props.label}
        </div>
      </div>
    </div>
  }
}