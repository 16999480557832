import Chapter from "../models/Chapter"
import * as React from "react"
import { observer } from "mobx-react"
import LazyResourcePanel from "./LazyResourcePanel"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { Link } from "react-router-dom"

type Props = {
  chapter: Chapter
}

@observer
export default class ChapterMemberPositionsView extends React.Component<Props> {
  render (): React.ReactNode {
    const chapter = this.props.chapter

    return <>
      <div className="row">
        <div className="col-md-6">
          <h5>Board Members</h5>
          <LazyResourcePanel resource={chapter.boardMembers} emptyMessage="There are no board members assigned to this chapter">
            {boardMembers => {
              return boardMembers.map(bm => <p key={bm.id}><b>{bm.position.name}</b><br/><Link to={route(Routes.system.members.show, { id: bm.member.id })}>{bm.member.fullName}</Link></p>)
            }}
          </LazyResourcePanel>
        </div>

        <div className="col-md-6">
          <h5>Chair Members</h5>
          <LazyResourcePanel resource={chapter.chairMembers} emptyMessage="There are no chair members assigned to this chapter">
            {chairMembers => {
              return chairMembers.map(bm => <p key={bm.id}><b>{bm.position.name}</b><br/><Link to={route(Routes.system.members.show, { id: bm.member.id })}>{bm.member.fullName}</Link></p>)
            }}
          </LazyResourcePanel>
        </div>
      </div>
    </>
  }
}