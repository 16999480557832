import * as React from "react"
import { Link, Redirect } from "react-router-dom"
import { observable, toJS } from "mobx"
import { observer } from "mobx-react"
import { BarLoader } from "react-spinners"
import { Button, Card, CardBody } from "reactstrap"
import { LinkContainer } from "react-router-bootstrap"
import BaseView from "../BaseView"
import BackLink from "../../components/BackLink"
import AppStateStore from "../../stores/AppStateStore"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import Util, { formatDateForCalendar, modelToCamelCase } from "../../common/Util"
import AuthStore from '../../stores/AuthStore'
import { toast } from 'react-toastify'
import ExportButton, { ExportButtonFetchCallback } from '../../components/ExportButton'
import BoardTraining from "../../models/BoardTraining"
import BoardTrainingMember from "../../models/BoardTrainingMember"
import BoardTrainingMembersCsvTransformer from "../../models/renderers/BoardTrainingMembersCsvTransformer"
import moment from "moment"

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

@observer
export default class BoardTrainingView extends BaseView<Props> {
  @observable private redirect = false
  @observable private loading = false
  @observable private boardTraining?: BoardTraining

  @observable private members: BoardTrainingMember[] = []

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>Board Meetings</h1>
        <ul className="content-header-actions">
          <Button color="danger" className="mr-2" onClick={this.deleteEvent}><i className="fa fa-trash"/> Delete Event</Button>
          <ExportButton fetch={this.fetchExportMembers} transformerClass={() => new BoardTrainingMembersCsvTransformer()}/>
          <LinkContainer to={route(Routes.system.boardTraining.edit, { id: this.props.match.params.id })}><Button color="primary" className="ml-2"><i className="fa fa-pencil"/> Edit</Button></LinkContainer>
          <Button color="primary" className="ml-2" onClick={this.sendMissedNotice}><i className="fa fa-send"/> Send Missed Notice</Button>
          <Button color="primary" className="ml-2" onClick={this.sendCancellationNotice}><i className="fa fa-send"/> Send Cancellation Notice</Button>
          <Button color="primary" className="ml-2" onClick={this.sendReminder}><i className="fa fa-send"/> Send Reminder</Button>
        </ul>
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    this.loadData().then()
  }

  private fetchExportMembers: ExportButtonFetchCallback<BoardTrainingMember> = (onFetched, onError, onFinished) => {
    onFetched(toJS(this.members))
    onFinished()
  }

  private deleteEvent = () => {
    AppStateStore.showConfirmationModal('Confirm', 'Are you sure you want to delete this Board Training event?', (result, modal) => {
      if (result) {
        (async () => {
          AppStateStore.showModalSpinner()

          try {
            await ApiClient.getInstance().delete(route(ApiRoutes.boardTrainings.destroy, { id: this.props.match.params.id }))
          } catch (err) {
            AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
            this.loadData().then()
          }

          AppStateStore.dismissModalSpinner()

          toast.success('Board Training deleted')
          this.redirect = true
        })().then()
      }

      modal.hide()
    })
  }

  private sendReminder = () => {
    AppStateStore.showConfirmationModal('Confirm', 'Are you sure you want to send a reminder email to all scheduled members?', (result, modal) => {
      if (result) {
        (async () => {
          AppStateStore.showModalSpinner()

          try {
            const response = await ApiClient.getInstance().post(route(ApiRoutes.boardTrainings.sendReminder, { id: this.props.match.params.id }))

            toast.success(`Reminder sent to ${response.data.memberCount} members`)
          } catch (err) {
            AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
            this.loadData().then()
          }

          AppStateStore.dismissModalSpinner()
        })().then()
      }

      modal.hide()
    })
  }

  private sendMissedNotice = () => {
    AppStateStore.showConfirmationModal('Confirm', 'Are you sure you want to a message to all members that are not marked as having attended this event?', (result, modal) => {
      if (result) {
        (async () => {
          AppStateStore.showModalSpinner()

          try {
            const response = await ApiClient.getInstance().post(route(ApiRoutes.boardTrainings.sendMissedNotice, { id: this.props.match.params.id }))

            toast.success(`Email sent to ${response.data.memberCount} members`)
          } catch (err) {
            AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
            this.loadData().then()
          }

          AppStateStore.dismissModalSpinner()
        })().then()
      }

      modal.hide()
    })
  }

  private sendCancellationNotice = () => {
    AppStateStore.showConfirmationModal('Confirm', 'Are you sure you want to send a reminder email to all members that scheduled to attend this event?', (result, modal) => {
      if (result) {
        (async () => {
          AppStateStore.showModalSpinner()

          try {
            const response = await ApiClient.getInstance().post(route(ApiRoutes.boardTrainings.sendCancellationNotice, { id: this.props.match.params.id }))

            toast.success(`Email sent to ${response.data.memberCount} members`)
          } catch (err) {
            AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
            this.loadData().then()
          }

          AppStateStore.dismissModalSpinner()
        })().then()
      }

      modal.hide()
    })
  }

  private loadData = async () => {
    this.loading = true

    this.members = []

    try {
      let response = await ApiClient.query(`
      boardTraining {
          *
        }
        `, {
          where:
            [{ id: this.props.match.params.id }],
        }
      )

      const boardTrainingData = modelToCamelCase(response.data.boardTraining)
      this.boardTraining = new BoardTraining().init(boardTrainingData)
      this.loading = false

      response = await ApiClient.query(`
      boardTrainingMembers {
          *
          member {
            id
            fullName
            firstName
            lastName
            emailAddress
            joinDate
            badgeColor

            chapter {
              name
            }

            category {
              name
            }
          }
        }`, {
        where: [{ boardTrainingId: this.props.match.params.id }],
        order: ['member.fullName'],
      })
      this.members = response.data.boardTrainingMembers.map((m: any) => new BoardTrainingMember().init(m))
    } catch (err) {
      AppStateStore.showAlertModal('Error', 'The Board Training was not found', m => {
        m.hide()
        this.redirect = true
      })
    }
  }

  renderContentBody (): React.ReactNode {
    return this.redirect
      ? this.renderRedirect()
      : (this.loading || !this.boardTraining) ? this.renderLoading() : this.renderDetail(this.boardTraining)
  }

  private changeAttendance = async (member: BoardTrainingMember, didAttend: boolean) => {
    AppStateStore.showModalSpinner()

    try {
      await ApiClient.getInstance().post(route(ApiRoutes.boardTrainings.updateAttendance), { memberId: member.id, didAttend })
    } catch (err) {
      AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
      this.loadData().then()
    }

    AppStateStore.dismissModalSpinner()
  }


  private removeMember = (member: BoardTrainingMember) => {
    AppStateStore.showConfirmationModal('Confirm', 'Are you sure you want to remove this member from this Board Training event?', (result, modal) => {
      if (result) {
        (async () => {
          AppStateStore.showModalSpinner()

          try {
            await ApiClient.getInstance().post(route(ApiRoutes.boardTrainings.removeMember), { memberId: member.id })
            this.loadData().then()
          } catch (err) {
            AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
          }

          AppStateStore.dismissModalSpinner()
        })().then()
      }

      modal.hide()
    })
  }

  private renderLoading = () => <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>

  private renderDetail = (boardTraining: BoardTraining) => <>
    <Card>
      <CardBody>
        <div className="form-row">
          <div className="col-6">
            <b>Date</b><br/>
            {boardTraining.date.clone().tz(boardTraining.timezone).format('dddd, MMMM Do')} @ {formatDateForCalendar(boardTraining.date, boardTraining.timezone, AuthStore.getUser()!.timezone || 'UTC', false)}
            {boardTraining.cancelledAt ? ` (Cancelled)` : null}
          </div>
          <div className="col-6">
            <b>Number of Members Registered</b><br/>
            {boardTraining.numberOfMembers}
          </div>
        </div>
      </CardBody>
    </Card>

    <table className="table table-striped table-condensed program-table">
      <thead>
      <tr>
        <th>#</th>
        <th>Did Attend</th>
        <th>First</th>
        <th>Last</th>
        <th>Category</th>
        <th>Chapter</th>
        <th>Join Date</th>
        <th>Badge Color</th>
        <th>Email</th>
        <th/>
      </tr>
      </thead>
      <tbody>
      {this.members.map((member, index) =>
        <tr key={member.id}>
          <td>
            <b>{index+1}</b>
          </td>
          <td>
            <input
              type="checkbox"
              defaultChecked={member.didAttend}
              onChange={ev => this.changeAttendance(member, ev.target.checked)}
            />
          </td>
          <td>
            <Link to={route(Routes.system.members.show, { id: member.member.id })}>{member.member.firstName}</Link>
          </td>
          <td>
            <Link to={route(Routes.system.members.show, { id: member.member.id })}>{member.member.lastName}</Link>
          </td>
          <td>
            {member.member.category ? member.member.category.name : '---'}
          </td>
          <td>
            {member.member.chapter ? member.member.chapter.name : '---'}
          </td>
          <td>
            {member.member.joinDate ? moment(member.member.joinDate).format('MM/DD/YYYY') : '---'}
          </td>
          <td>
            {member.member.badgeColor || '---'}
          </td>
          <td>
            {member.member.emailAddress ? <a href={`mailto:${member.member.emailAddress}`}>{member.member.emailAddress}</a> : '---'}
          </td>
          <th>
            <Button
              size="sm"
              color="danger"
              onClick={() => this.removeMember(member)}
            ><i className="fa fa-trash"/> Remove</Button>
          </th>
        </tr>)}
      </tbody>
    </table>
  </>

  private renderRedirect = () => <Redirect to={route(Routes.system.boardTraining.index)}/>
}
