import CsvTransformer from "./CsvTransformer"
import { safeNull } from "../../common/Util"
import moment from "moment-timezone"
import Region from "../Region"
import Area from "../Area"

export default class AreasCsvTransformer extends CsvTransformer<Area> {
  get filename () {
    return `areas-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'id',
      valueTransformer: row => String(row.id),
    })

    this.addColumn({
      header: 'Area Name',
      valueTransformer: row => safeNull(() => row.name) || '',
    })

    this.addColumn({
      header: 'Region Name',
      valueTransformer: row => safeNull(() => row.region.name) || '',
    })

    this.addColumn({
      header: 'Chapters',
      valueTransformer: row => safeNull(() => row.numberOfChapters) || '',
    })

    this.addColumn({
      header: 'Members',
      valueTransformer: row => safeNull(() => row.numberOfMembers) || '',
    })
  }
}