import * as React from "react"
import Address, { IAddress } from "../models/Address"
import { observer } from "mobx-react"
import { IVirtualMeet } from "../models/VirtualMeet"

type Props = {
  virtualMeet: IVirtualMeet,
}

@observer
export default class VirtualMeetView extends React.Component<Props, never> {

  render(): React.ReactNode {
    const { virtualMeet } = this.props

    if (!virtualMeet) {
      return '---'
    }

    return <>
      <div>
        <label>{virtualMeet.meetingDate}</label> <label></label> <label> {virtualMeet.meetingStartTime} - {virtualMeet.meetingEndTime}</label>
      </div>
      <div>
        <label> <b>Chapter Name</b> {virtualMeet.chapterName}</label>
      </div>
      <br />
      <div style={{ 'display': 'flex', 'flexDirection': 'column' }}>
        <label><b>Web Conference Details</b></label>
        <label><b>Zoom</b> {virtualMeet.webConferenceDetails}</label>
      </div>
    </>
  }
}
