import { observer } from "mobx-react";
import * as React from "react";

type Props = {
  badgeColor: string;
};

@observer
export default class BadgeView extends React.Component<Props> {
  getBadgeColor = (badgeColor: string): { background: string; foreground: string; border: string } => {
    const colors: { [badgeColor: string]: { background: string; foreground: string; border: string } } = {
      White: { background: "#fff", foreground: "#000", border: "#333333" },
      Blue: { background: "#009", foreground: "#fff", border: "#0000c9" },
      Green: { background: "#090", foreground: "#fff", border: "#00c900" },
      Silver: { background: "#999", foreground: "#000", border: "#646464" },
      Platinum: { background: "#ecc", foreground: "#000", border: "#d57171" },
      Gold: { background: "#FFDF00", foreground: "#000", border: "#988500" },
      Purple: { background: "#90f", foreground: "#fff", border: "#6800af" }
    };

    return colors[badgeColor] || { background: "#fff", foreground: "#f000", border: "#333333" };
  };

  render(): React.ReactNode {
    return (
      <div>
        <label
          style={{
            backgroundColor: this.getBadgeColor(this.props.badgeColor).background,
            color: this.getBadgeColor(this.props.badgeColor).foreground,
            borderWidth: 1,
            borderColor: this.getBadgeColor(this.props.badgeColor).border,
            borderRadius: 5,
            padding: 2,
            fontSize: 12,
            width: 100,
            height: 24,
            textAlign: "center",
            borderStyle: "solid",
            float: "right"
          }}
        >
          Badge: {this.props.badgeColor}
        </label>
      </div>
    );
  }
}
