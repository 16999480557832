import * as React from "react"
import ApiClient, { QueryWhereClause } from "../api/ApiClient"
import { createLazyResource, formatNumber } from "../common/Util"
import { observer } from "mobx-react"
import { observable } from "mobx"
import { Button } from "reactstrap"
import BaseView from "./BaseView"
import { LinkContainer } from "react-router-bootstrap"
import { Routes } from "../routes/AppRoutes"
import { route } from "../routes/routes"
import { Link } from "react-router-dom"
import SupportArea from "../models/SupportArea"
import { UserRole } from "../models/AuthUser"
import RequireRole from "../components/RequireRole"
import { ApiTableViewAdapter } from "../components/table-view/ApiTableViewAdapter"
import RequirePermission, { Permission } from "../components/RequirePermission"
import { ManagedChooseableColumn } from "../components/table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "../components/table-view/ManagedTableView";
import SupportAreasCsvTransformer from "../models/renderers/SupportAreasCsvTransformer";

@observer
export default class SupportAreasView extends BaseView {
  @observable private numberOfActiveSupportAreas = createLazyResource<number>(() => {
    return ApiClient.query(`supportAreas { * }`, { returnTotal: true, limit: 0 })
  }, response => response.data._meta.total);

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>Support Areas</h1>
        <ul className="content-header-actions">
          <RequirePermission permission={Permission.CreateSupportArea}>{() =>
            <li>
              <LinkContainer to={route(Routes.system.supportAreas.create)}>
                <Button color="primary" onClick={() => {
                }}><i className="fa fa-plus-circle"/> Add New Support Area</Button>
              </LinkContainer>
            </li>
          }</RequirePermission>
        </ul>
      </>
    )
  }

  @observable private availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'name',
      column: {
        title: 'Name',
        accessor: 'name',
        sortable: true,
      },
      default: true
    },
    {
      id: 'numberOfChapters',
      column: {
        title: 'Chapters',
        headerClassName: 'justify-content-center',
        accessor: 'numberOfChapters',
        renderItem: (value, item) => {
          return (
            <div className="text-center">
              <Link type="link" to={{ pathname: route(Routes.system.chapters.index), state: { supportArea: { id: item.id, name: item.name } } }}>{item.numberOfChapters !== undefined ? formatNumber(item.numberOfChapters) : 'N/A'}</Link>
            </div>
          )
        },
      },
      default: true
    },
    {
      id: 'actions',
      column: {
        title: 'Actions',
        headerClassName: 'justify-content-center',
        renderItem: (value, item) => {
          return (
            <div className="table-actions">
              <Link type="link" to={{ pathname: route(Routes.system.supportAreas.show, { id: item.id }) }}><i className="fa fa-search"/></Link>
            </div>
          )
        }
      },
      fixed: true,
      hidden: true
    }
  ];

  private supportAreasAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      return {
        query: `
      supportAreas {
        *
        numberOfChapters
      }
    `,
        where: where,
      }
    },
    'supportAreas',
    SupportArea
  )

  renderContentBody (): React.ReactNode {
    return this.renderTable();
  }

  private renderTableViewHeader = () => {
    return <RequireRole role={UserRole.Admin}>
      {() => <>
        <div className="list-view-info-icon">
          <i className="flaticon-support"/>
        </div>
        <div className="list-view-info-text">
          <span>{this.numberOfActiveSupportAreas.loading ? <i className="fa fa-spinner fa-spin"/> : formatNumber(this.numberOfActiveSupportAreas.current!)}</span> Active Support Areas
        </div>
      </>}
    </RequireRole>
  }

  private renderTable = () => {
    return <ManagedTableView
      stateKey="SupportAreasView"
      hideItemCount={true}
      searchLabel="Find A Support Area"
      adapter={this.supportAreasAdapter}
      availableColumns={this.availableColumns}
      header={this.renderTableViewHeader()}
      exportTransformer={SupportAreasCsvTransformer}
    />
  }
}