import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import Util, { modelToSnakeCase } from "../../../common/Util"
import { BarLoader } from "react-spinners"
import FormState from "../../../common/FormState"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"
import Config from "../../../common/Config"
import ButtonLoader from "../../ButtonLoader"
import { route } from "../../../routes/routes"
import { toast } from "react-toastify"
import AppStateStore from "../../../stores/AppStateStore"
import Chapter from "../../../models/Chapter"
import moment from "moment-timezone"
import { loadAreaOptions } from "../../../api/AsyncHelpers"

type ManageChapterSettingsProps = {
  chapterId: number
  onChapterInvalidated?: () => void
}

@observer
export default class ManageChapterSettings extends React.Component<ManageChapterSettingsProps> {
  @observable private loading = false
  @observable private error?: string
  @observable private chapter?: Chapter
  @observable private submitting = false

  @observable private formState = new FormState({
    status: '',
    dateFounded: '',
    areaId: undefined,
    numberOfProgramSlots: '',
    defaultTipPrivacy: 'normal',
    billingRegion: '',
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private loadChapter = () => {
    this.loading = true
    this.error = undefined

    ApiClient.query(`
    chapter {
      *
      
      area {
        *
      }
    `, {
      where: [{ id: this.props.chapterId }],
    })
      .then(response => {
        this.chapter = new Chapter().init(response.data.chapter)
        this.formState.setAll({
          status: this.chapter.status || '',
          dateFounded: this.chapter.dateFounded ? this.chapter.dateFounded.format('MM/DD/YYYY') : '',
          areaId: this.chapter.area ? this.chapter.area.id : undefined,
          numberOfProgramSlots: this.chapter.numberOfProgramSlots,
          timezone: this.chapter.timezone,
          defaultTipPrivacy: this.chapter.defaultTipPrivacy,
          billingRegion: this.chapter.billingRegion,
        })
      })
      .catch(error => this.error = Util.extractErrorMessage(error.response))
      .then(() => this.loading = false)
  }

  componentDidMount (): void {
    this.loadChapter()
  }

  componentDidUpdate (prevProps: Readonly<ManageChapterSettingsProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.chapterId !== prevProps.chapterId) {
      this.loadChapter()
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitting = true

    ApiClient.getInstance()
      .post(route(ApiRoutes.chapters.updateSettings, { id: this.props.chapterId }), modelToSnakeCase(this.formHelper.toObject()))
      .then(() => {
        toast.success('Chapter settings updated')
        this.loadChapter()
        this.props.onChapterInvalidated && this.props.onChapterInvalidated()
      })
      .catch(error => Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      }))
      .then(() => this.submitting = false)
  }

  private renderContent = (chapter: Chapter) => {
    const renderOption = (tz: string) => {
      return <option value={tz}>{tz} - {moment().tz(tz).format('h:mm A')}</option>
    }

    return <>
      <h6 className="text-muted">Chapter Settings</h6>
      <form onSubmit={this.submit}>
        <div className="form-row">
          <div className="col-md-4">
            {
              this.formHelper.renderDatePickerInput({
                label: 'Date Founded',
                name: 'dateFounded',
              })
            }
          </div>
          <div className="col-md-4">
            {
              this.formHelper.renderSelectInput({
                label: 'Status',
                name: 'status',
                options: Config.CHAPTER_STATUS_OPTIONS.map(o => ({ value: o, text: o })),
              })
            }
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Timezone</label>
              <select
                className="form-control"
                value={this.formState.get('timezone') || ''}
                onChange={ev => this.formState.set('timezone', ev.target.value)}
              >
                <option value="">(choose one)</option>
                <optgroup label="Pacific Time">
                  {renderOption('America/Vancouver')}
                  {renderOption('America/Los_Angeles')}
                </optgroup>
                <optgroup label="Mountain Time">
                  {renderOption('America/Edmonton')}
                  {renderOption('America/Denver')}
                  {renderOption('America/Phoenix')}
                </optgroup>
                <optgroup label="Central Time">
                  {renderOption('America/Winnipeg')}
                  {renderOption('America/Chicago')}
                </optgroup>
                <optgroup label="Eastern Time">
                  {renderOption('America/Toronto')}
                  {renderOption('America/New_York')}
                </optgroup>
                <optgroup label="Hawaii Time">
                  {renderOption('Pacific/Honolulu')}
                </optgroup>
                <optgroup label="Alaska Time">
                  {renderOption('America/Anchorage')}
                </optgroup>
                <optgroup label="Newfoundland Time">
                  {renderOption('America/St_Johns')}
                </optgroup>
                <optgroup label="Atlantic Time">
                  {renderOption('America/Halifax')}
                </optgroup>
              </select>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-4">
            {
              this.formHelper.renderAsyncSelectInput({
                label: 'Area',
                name: 'areaId',
                loadOptions: loadAreaOptions,
                defaultValue: chapter.area ? { value: chapter.area.id, label: chapter.area.name } : undefined,
              })
            }
          </div>
          <div className="col-md-4">
            {
              this.formHelper.renderTextInput({
                label: 'Number of Program Slots',
                name: 'numberOfProgramSlots',
              })
            }
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Default Tip Privacy</label>
              <select
                className="form-control"
                value={this.formState.get('defaultTipPrivacy') || ''}
                onChange={ev => this.formState.set('defaultTipPrivacy', ev.target.value)}
              >
                <option value="">(choose one)</option>
                <option value="normal">Normal</option>
                <option value="private">Private</option>
              </select>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-4">
            <div className="form-group">
              <label>Billing Region</label>
              <select
                className="form-control"
                value={this.formState.get('billingRegion') || ''}
                onChange={ev => this.formState.set('billingRegion', ev.target.value)}
              >
                <option value="">(choose one)</option>
                <option value="United States">United States</option>
                <option value="Canada">Canada</option>
              </select>
            </div>
          </div>
        </div>
        <div className="form-buttons">
          <ButtonLoader
            type="submit"
            color="primary"
            loading={this.submitting}
          >Save Changes</ButtonLoader>
        </div>
      </form>
    </>
  }

  render () {
    return this.error
      ? <div className="alert alert-error">{this.error}</div>
      : (this.loading || !this.chapter)
        ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
        : this.renderContent(this.chapter)
  }
}
