import * as React from "react"
import { observer } from "mobx-react"
import { computed, observable } from 'mobx'
import { Bar, Line } from "react-chartjs-2"
import LazyResourcePanel from "./LazyResourcePanel"
import { createLazyResource, modelToCamelCase } from "../common/Util"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { route } from "../routes/routes"
import { Moment } from "moment-timezone"
import * as _ from "lodash"

type TipTotalType = {
  tipType: string
  month: number
  year: number
  total: number
}

type TipsChartProps = {
  chapterId?: number | string,
  dateRange: { start: Moment, end: Moment },
}

@observer
export default class TipsChart extends React.Component<TipsChartProps> {
  @observable private tipData = createLazyResource<TipTotalType[]>(() => {
    return ApiClient.getInstance().get(route(ApiRoutes.tips.stats, {}, {
      type: 'chapter',
      chapter: this.props.chapterId,
      start_date: this.props.dateRange.start.format('YYYY-MM-DD'),
      end_date: this.props.dateRange.end.format('YYYY-MM-DD'),
    }))
  }, response => response.data.tip_data.map((t: {}) => modelToCamelCase(t)))

  @computed get chartData () {
    const data = {
      'Inside': new Array(this.chartMonths.length).fill(0),
      'Outside': new Array(this.chartMonths.length).fill(0),
      'Inter-Chapter': new Array(this.chartMonths.length).fill(0),
    }

    this.chartMonths.forEach((date, idx) => {
      this.tipData.current && this.tipData.current.filter(td => td.month === date.month() + 1 && td.year === date.year())
        .forEach(td => {
          if (data[td.tipType]) {
            data[td.tipType][idx] += td.total
          }
        })
    })

    return data
  }

  @computed get chartMonths () {
    let cur = this.props.dateRange.start.clone().startOf('month')
    const months: Moment[] = []

    if (this.props.dateRange.start.isAfter(this.props.dateRange.end)) {
      return []
    }

    while (cur.isSameOrBefore(this.props.dateRange.end)) {
      months.push(cur.clone())
      cur.add(1, 'month')
    }

    return months
  }

  componentDidUpdate (prevProps: Readonly<TipsChartProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.chapterId !== this.props.chapterId || !_.isEqual(prevProps.dateRange, this.props.dateRange)) {
      this.tipData.invalidate()
    }
  }

  render (): React.ReactNode {
    return <>
      <LazyResourcePanel resource={this.tipData} emptyMessage={'There is no tip data to display'}>
        {() => <>
          <div className="text-center">
            <small>click box to remove from graph</small>
          </div>
          <Bar
            data={{
              labels: this.chartMonths.map(m => m.format('MMM YY')),
              datasets: [
                {
                  label: 'Inside',
                  data: this.chartData['Inside'],
                  backgroundColor: '#36a3f7',
                  borderColor: '#36a3f7',
                  fill: false,
                  lineTension: 0,

                },
                {
                  label: 'Outside',
                  data: this.chartData['Outside'],
                  backgroundColor: '#ffb822',
                  borderColor: '#ffb822',
                  fill: false,
                  lineTension: 0,
                },
                {
                  label: 'Inter-Chapter',
                  data: this.chartData['Inter-Chapter'],
                  backgroundColor: '#5ec18e',
                  borderColor: '#5ec18e',
                  fill: false,
                  lineTension: 0,
                },
              ],
            }}
            options={{
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    callback: value => value % 1 === 0 ? value : undefined,
                  },
                }],
              },
            }}
          />
        </>}
      </LazyResourcePanel>
    </>
  }
}