import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import User from "./User"

export default class Note extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  userId: number
  noteableType: string
  noteableId: number
  note: string

  user: User | null

  getCasts (): {} {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      userId: 'number',
      noteableType: 'number',
    }
  }

  setUser (u?: {}) {
    return u ? new User().init(u) : null
  }
}