import { FieldType, ResourceSpec } from "../ReportDataSource"
import Member from "../../models/Member"
import { ChapterResource } from "./ChapterResource"
import { CategoryResource } from "./CategoryResource"
import { ContactMethodResource } from "./ContactMethodResource"
import { PhoneNumberResource } from "./PhoneNumberResource"
import { BusinessResource } from "./BusinessResource"

export const MemberResource: ResourceSpec = {
  collectionResourceName: 'members',
  modelClass: Member,

  fields: [
    {
      name: 'id',
      type: FieldType.Number,
    },
    {
      name: 'fullName',
      type: FieldType.String,
    },
    {
      name: 'status',
      type: FieldType.Enum,
      values: [
        { value: 'Active' },
        { value: 'Resigned' },
      ],
    },
    {
      name: 'joinDate',
      type: FieldType.Date,
    },
    {
      name: 'badgeColor',
      type: FieldType.Enum,
      values: [
        { value: 'White' },
        { value: 'Green' },
        { value: 'Blue' },
        { value: 'Silver' },
        { value: 'Gold' },
        { value: 'Platinum' },
        { value: 'Purple' },
      ],
    },
    {
      name: 'ntsTrainingCompletedAt',
      type: FieldType.Date,
      nullable: true,
    },
    {
      name: 'membershipType',
      type: FieldType.Enum,
      values: [
        { value: 'Personal' },
        { value: 'Company' },
      ]
    },
    {
      name: 'primaryPositionName',
      type: FieldType.String,
      nullable: true,
    },
    {
      name: 'phoneNumber',
      type: FieldType.String,
    },
    {
      name: 'emailAddress',
      type: FieldType.String,
    },
  ],

  relationships: [
    {
      name: 'chapter',
      resourceSpec: ChapterResource
    },
    {
      name: 'business',
      resourceSpec: BusinessResource,
    },
    {
      name: 'category',
      resourceSpec: CategoryResource,
    },
  ]
}
