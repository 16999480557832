import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import AppStateStore from "../../stores/AppStateStore"
import ButtonLoader from "../ButtonLoader"
import ApiClient, { ApiRoutes } from '../../api/ApiClient'
import NtsEvent from '../../models/NtsEvent'
import Util, { formatDateForCalendar } from '../../common/Util'
import AuthStore from '../../stores/AuthStore'
import { route } from '../../routes/routes'
import { toast } from 'react-toastify'

type Props = {
  onClosed: () => void
  memberId: number
}

@observer
export class ScheduleNtsTrainingModal extends React.Component<Props> {
  @observable private isOpen = true
  @observable private submitting = false
  @observable private selectedNtsEvent: NtsEvent | undefined = undefined
  @observable private ntsEvents: NtsEvent[] | undefined = undefined
  @observable private scheduledEvents: NtsEvent[] = []

  componentDidMount () {
    this.loadData().then()
  }

  private loadData = async () => {
    try {
      let response = await ApiClient.query(`
ntsEvents {
  *
}`, {
        where: [{ _scope: 'canSchedule' }, { _scope: 'notAttending', value: this.props.memberId }],
        order: ['date'],
      })

      this.ntsEvents = response.data.ntsEvents.map((m: any) => new NtsEvent().init(m))

      response = await ApiClient.query(`
ntsEvents {
  *
}`, {
        where: [{ _scope: 'isAttending', value: this.props.memberId }],
        order: ['date'],
      })

      this.scheduledEvents = response.data.ntsEvents.map((m: any) => new NtsEvent().init(m))
    } catch (err) {
      AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response), modal => {
        modal.hide()
        this.toggle()
      })
    }
  }

  private unschedule = async (ntsEventId: number) => {
    this.submitting = true
    AppStateStore.showModalSpinner()

    try {
      await ApiClient.getInstance().post(route(ApiRoutes.ntsEvents.unschedule), {
        member_id: this.props.memberId,
        nts_event_id: ntsEventId,
      })

      toast.success('You have been unscheduled from NTS training')
      this.loadData().then()
    } catch (err) {
      AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response), modal => {
        modal.hide()
      })
    }

    this.submitting = false
    AppStateStore.dismissModalSpinner()
  }

  private submit = async (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitting = true
    AppStateStore.showModalSpinner()

    try {
      await ApiClient.getInstance().post(route(ApiRoutes.ntsEvents.schedule), {
        member_id: this.props.memberId,
        nts_event_id: this.selectedNtsEvent!.id,
      })

      toast.success('You have been scheduled for NTS training')
      this.isOpen = false
    } catch (err) {
      AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response), modal => {
        modal.hide()
      })
    }

    this.submitting = false
    AppStateStore.dismissModalSpinner()
  }

  private toggle = () => {
    if (!this.submitting) {
      this.isOpen = false
    }
  }

  render (): React.ReactNode {
    return <Modal isOpen={this.isOpen} toggle={this.toggle} size="md" onClosed={this.props.onClosed}>
      <ModalHeader toggle={this.toggle}>
        Schedule NTS Training
      </ModalHeader>
      <ModalBody>
        <p>
          Select an available date below to sign up for your NTS training
        </p>
        {
          this.ntsEvents
            ? <div>
              <select
                className="form-control"
                onChange={ev => this.selectedNtsEvent = this.ntsEvents!.find(e => String(e.id) === ev.target.value)}
              >
                <option value="">(select a date)</option>
                {
                  this.ntsEvents.map(e => <option key={e.id} value={String(e.id)}>{e.date.clone().tz(AuthStore.getUser()!.timezone || 'UTC').format(`dddd, MMMM Do @ h:mma z`)}</option>)
                }
              </select>
            </div>
            : <Spinner/>
        }
        {
          this.scheduledEvents.length
            ? <div>
              <hr/>
              <p>You are scheduled to attend the following events:</p>
              {
                this.scheduledEvents.map(scheduledEvent => <div key={scheduledEvent.id}>
                  <Button size="sm" color="danger" onClick={() => this.unschedule(scheduledEvent.id)}>Unsubscribe</Button> {scheduledEvent.date.clone().tz(AuthStore.getUser()!.timezone || 'UTC').format(`dddd, MMMM Do @ h:mma z`)}
                </div>)
              }
            </div>
            : null
        }
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={this.submitting} onClick={this.toggle}>Cancel</Button>
        <ButtonLoader type="button" color="primary" disabled={!this.selectedNtsEvent} loading={this.submitting} onClick={this.submit}>Submit</ButtonLoader>
      </ModalFooter>
    </Modal>
  }
}
