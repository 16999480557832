import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import FormHelper from "../../forms/FormHelper"
import AppStateStore from "../../stores/AppStateStore"
import ButtonLoader from "../ButtonLoader"
import EventBus from '../../common/EventBus'
import { submitFormRequest } from '../../api/ApiHelper'
import ApiClient, { ApiRoutes } from '../../api/ApiClient'
import { route } from '../../routes/routes'
import { modelToSnakeCase } from '../../common/Util'
import { toast } from 'react-toastify'

type Props = {
  onClosed: () => void
  eventBus: EventBus
}

@observer
export class InviteExtendedNetworkMemberModal extends React.Component<Props> {
  @observable private isOpen = true

  private initialFormState = {
    name: '',
    destinationType: 'phone',
    destination: '',
  }

  @observable private formState = new FormState(this.initialFormState)

  @observable private formErrors = new ErrorBag()
  @observable private submitting = false

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()

    this.submitting = true
    AppStateStore.showModalSpinner()

    submitFormRequest(
      ApiClient.getInstance()
        .post(route(ApiRoutes.extendedNetwork.sendInvitation), modelToSnakeCase(this.formState.toObject())),
      this.formState,
      this.formErrors,
      () => {
        toast.success('Invitation Sent')
        this.props.eventBus.dispatch('invalidate')
        this.isOpen = false
      }
    )
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private toggle = () => {
    if (!this.submitting) {
      this.isOpen = false
    }
  }

  render (): React.ReactNode {
    return <Modal isOpen={this.isOpen} toggle={this.toggle} size="md" onClosed={this.props.onClosed}>
      <ModalHeader toggle={this.toggle}>
        Invite Extended Network Contact
      </ModalHeader>
      <ModalBody>
        <p>
          To invite someone to be a part of your extended network, enter their name below and choose how you'd like to send the invitation. The invitation can be sent in a text message to a phone number or in an email.
        </p>
        <form onSubmit={this.submit}>
          <div className="form-row">
            <div className="col-12">
              {
                this.formHelper.renderTextInput({
                  label: 'Name',
                  name: 'name',
                  disabled: this.submitting,
                })
              }
            </div>
          </div>
          <div className="form-row">
            <div className="col-12">
              {
                this.formHelper.renderSelectInput({
                  label: 'Send Invitation To',
                  name: 'destinationType',
                  options: [
                    { value: 'phone', text: 'Phone (Text Message)' },
                    { value: 'email', text: 'Email Address' },
                  ],
                  disabled: this.submitting,
                })
              }
            </div>
          </div>
          <div className="form-row">
            <div className="col-12">
              {
                this.formHelper.renderTextInput({
                  label: this.formState.get('destinationType') === 'email' ? 'Email Address' : 'Phone Number',
                  name: 'destination',
                  disabled: this.submitting,
                })
              }
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={this.submitting} onClick={this.toggle}>Cancel</Button>
        <ButtonLoader type="button" color="primary" loading={this.submitting} onClick={this.submit}>Send Invitation</ButtonLoader>
      </ModalFooter>
    </Modal>
  }
}
