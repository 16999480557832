import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone/moment-timezone"

export default class MemberUsageStats extends BaseModel {
  lastSentMobileTipDate: Moment | null
  lastSentWebTipDate: Moment | null
  lastMobileActivityDate: Moment | null
  lastWebActivityDate: Moment | null

  getCasts (): {} {
    return {
      lastSentMobileTipDate: 'datetime',
      lastSentWebTipDate: 'datetime',
      lastMobileActivityDate: 'datetime',
      lastWebActivityDate: 'datetime',
    }
  }
}
