import * as React from "react"
import { observer } from "mobx-react"
import AuthStore from "../stores/AuthStore"
import * as _ from 'lodash'
import { UserRole } from "../models/AuthUser"
import { PermissionOperator } from "./RequirePermission"

type RoleRender = (allowed: boolean) => React.ReactNode

type Props = {
  role: string | string[]
  require: PermissionOperator
  children: RoleRender
  renderIfDenied: boolean
}

@observer
export default class RequireRole extends React.Component<Props, never> {
  static defaultProps = {
    require: 'any',
    renderIfDenied: false,
  }

  render (): React.ReactNode {
    const user = AuthStore.getUser()

    if (user && user.hasRole(UserRole.Admin)) {
      return this.props.children(true)
    }

    const roles = _.isArray(this.props.role) ? this.props.role : [this.props.role]

    let allowed = false;

    if (!roles.length) {
      allowed = true;
    } else if (user) {
      allowed = (this.props.require === PermissionOperator.Any && user.hasAnyRole(roles))
        || (this.props.require === PermissionOperator.All && user.hasAllRoles(roles));
    }

    if (allowed || this.props.renderIfDenied) {
      return this.props.children(allowed)
    } else {
      return null
    }
  }
}