import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import FormState from "../../../common/FormState"
import { observable } from "mobx"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"
import { loadChapterOptions, loadChapterOptionsForAdmin } from "../../../api/AsyncHelpers"
import moment, { Moment } from 'moment-timezone'
import { Button } from "reactstrap"
import { BarLoader } from "react-spinners"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import { route } from "../../../routes/routes"
import Util, { formatNumber, modelToCamelCase, modelToSnakeCase } from "../../../common/Util"
import * as _ from "lodash"

type ReportData = {
  guest: {
    id: number
    fullName: string,
    companyName?: string
  }

  invitingMember: {
    fullName: string
  } | null

  events: {
    date: Moment
  }[]

  attendance: { [status: string]: number }
}

type Props = {
  chapterId?: number
  filterForAdmin?: boolean
}

@observer
export default class GuestAttendanceReport extends React.Component<Props> {
  @observable private loading = false
  @observable private error?: string
  @observable private formState = new FormState({
    chapterId: undefined,
    startDate: moment().startOf('month').format('MM/DD/YYYY'),
    endDate: moment().endOf('month').format('MM/DD/YYYY'),
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable chapterData?: { id: number, name: string }
  @observable reportData?: ReportData[]
  @observable startDate?: Moment
  @observable endDate?: Moment

  componentDidMount (): void {
    if (this.props.chapterId) {
      this.formState.set('chapterId', this.props.chapterId)
    }
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.chapterId != this.props.chapterId) {
      this.formState.set('chapterId', this.props.chapterId)
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.error = undefined
    this.loading = true

    ApiClient.getInstance()
      .post(route(ApiRoutes.reporting.chapterGuestAttendanceReport, { chapterId: this.formState.get('chapterId') }), modelToSnakeCase(this.formState.toObject()))
      .then(response => {
        this.chapterData = modelToCamelCase(response.data.chapter) as any
        this.reportData = response.data.report_data.map((rd: any) => modelToCamelCase(rd))
        this.startDate = moment(response.data.start_date);
        this.endDate = moment(response.data.end_date)
      })
      .catch(error => this.error = Util.extractErrorMessage(error.response))
      .then(() => this.loading = false)
  }

  render () {
    return <>
      <div className="print-hide">
        <form action="" method="post" onSubmit={this.submit}>
          <div className="row align-items-end">
            {
              !this.props.chapterId
                ? <div className="col-md-4">
                  {this.formHelper.renderAsyncSelectInput({
                    label: 'Chapter',
                    name: 'chapterId',
                    loadOptions: this.props.filterForAdmin ? loadChapterOptionsForAdmin : loadChapterOptions,
                  })}
                </div>
                : null
            }
            <div className="col-md-3">
              {this.formHelper.renderDatePickerInput({
                label: 'Start Date',
                name: 'startDate',
              })}
            </div>
            <div className="col-md-3">
              {this.formHelper.renderDatePickerInput({
                label: 'End Date',
                name: 'endDate',
              })}
            </div>
            <div className="col-md-2 d-flex">
              <div className="form-group d-flex flex-column">
                <div className="flex-fill"/>
                <div>
                  <Button
                    disabled={this.loading || !this.formState.get('chapterId') || !this.formState.get('startDate').length || !this.formState.get('endDate').length}
                    style={{
                      height: 'calc(2.95rem + 2px)'
                    }}
                    type="submit"
                    color="success"
                  ><i className="fa fa-play"/> Run Report</Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {
        this.loading
          ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
          : this.error
          ? <div className="alert alert-danger">{this.error}</div>
          : this.reportData
            ? this.renderReport()
            : <p>Choose report parameters above</p>
      }
    </>
  }

  renderAttendance = (v: number) => {
    if (v > 0) {
      return formatNumber(v);
    } else {
      return '';
    }
  }

  private renderReport () {
    const chapter = this.chapterData!
    const reportData = this.reportData!
    const startDate = this.startDate!
    const endDate = this.endDate!

    return <>
      <h1 className="text-center my-4">Guest Attendance Report For {chapter.name}</h1>
      <h3 className="text-center mb-4">{startDate.format('MMMM D, YYYY')} - {endDate.format('MMMM D, YYYY')}</h3>
      <div className="form-buttons mb-4 print-hide">
        <Button
          type="button"
          color="primary"
          onClick={() => window.print()}
        ><i className="fa fa-print"/> Print Report</Button>
      </div>
      <table className="chapter-attendance-report-table">
        <thead>
        <tr>
          <th/>
          <th className="border-left border-top border-bottom text-left">Guest Name</th>
          <th className="border-top border-bottom text-left">Company</th>
          <th className="border-top border-bottom text-left">Inviting Member</th>
          <th className="border-top border-bottom border-left"># of Meetings</th>
          <th className="border-top border-bottom">Present</th>
          <th className="border-top border-bottom">Not Present</th>
        </tr>
        </thead>
        <tbody>
        {
          reportData.map((guestAttendanceData, index) => <tr key={guestAttendanceData.guest.id}>
            <td className="text-right">{index + 1}</td>
            <td style={{ whiteSpace: 'nowrap' }}>{guestAttendanceData.guest.fullName}</td>
            <td style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{guestAttendanceData.guest.companyName}</td>
            <td style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{guestAttendanceData.invitingMember ? guestAttendanceData.invitingMember.fullName : '---'}</td>
            <td className="border-left text-center">{formatNumber(_.values(guestAttendanceData.attendance).reduce((s, n) => s += n, 0))}</td>
            <td className="text-center">{formatNumber(guestAttendanceData.attendance.present)}</td>
            <td className="text-center">{formatNumber(guestAttendanceData.attendance.notPresent)}</td>
          </tr>)
        }
        </tbody>
      </table>
    </>
  }
}