import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"

export default class RecurringEvent extends BaseModel {
  id: number
  eventType: string
	meetingType: string
  calendarType: string
	recurringStartsOn: string
	recurringEndsOn: string
  hasEndsOn: boolean
  hasEndNever: boolean
  recurringMonth: string
  repeatCount:  string
  daysOfWeek: []
	startTime: string
	endTime: string
	timezone: string
	showInLocalTimezone: boolean
	title: string
	notes: string
	hasWebConferenceDetails: boolean
	webConferenceDetails: string
	visibility: string
	hasLocation: boolean
	locationName: string
	address:string
	address2: string
	city: string
	state: string
	zipCode: string
	country: string
  chapter: string
	region: number
	area: string

}
