import React from 'react'
import { observer } from 'mobx-react'
import { publicPath } from '../common/Util'

type Props = {
  url?: string | null,
  textStyle?: React.CSSProperties
  imageStyle?: React.CSSProperties
}

@observer
export default class BankEbookLink extends React.Component<Props> {
  render () {
    const style = {
      width: 250,
      ...this.props.imageStyle,
    }
    return this.props.url
      ? <div>
        <a href={this.props.url} target="_blank" style={{display:'block'}}>
          <div style={this.props.textStyle}>Access my Codebreaker Kickstarter</div>
          <img src={publicPath('/img/bank/kickstarter_image.png')} alt={"Why They Buy BANK eBook"} style={style}/>
        </a>
      </div>
      : null
  }
}
