import * as React from "react"
import { observable } from "mobx"
import Util, { createLazyResource } from "../common/Util"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import LazyResourcePanel from "./LazyResourcePanel"
import User from "../models/User"
import FormState from "../common/FormState"
import FormError from "./FormError"
import ErrorBag from "../common/ErrorBag"
import ButtonLoader from "./ButtonLoader"
import { route } from "../routes/routes"
import AppStateStore from "../stores/AppStateStore"
import { toast } from "react-toastify"
import { observer } from "mobx-react"
import RequireRole from "./RequireRole"
import { UserRole } from "../models/AuthUser"
import { Link } from "react-router-dom"
import { Routes } from "../routes/AppRoutes"

@observer
export default class UserLoginSettings extends React.Component<{
  userId: number
}> {
  @observable private user = createLazyResource<User>(() => {
    return ApiClient.query(
      `
user {
  *
}
      `,
      {
        where: [{ id: this.props.userId }]
      }
    )
  }, response => new User().init(response.data.user))

  @observable private formState = new FormState({
    password: '',
    repeatPassword: '',
  })
  @observable private formErrors = new ErrorBag()
  @observable private submitting = false

  private submit = () => {
    this.formErrors.clearErrors()

    if (this.formState.get('password') !== this.formState.get('repeatPassword')) {
      this.formErrors.addError('repeatPassword', 'The passwords do not match')
      return
    }

    this.submitting = true

    ApiClient.getInstance()
      .put(route(ApiRoutes.users.updatePassword, { id: this.props.userId }), this.formState.toObject())
      .then(response => {
        toast.success('Password updated')
        this.formState.setAll({
          password: '',
          repeatPassword: '',
        })
      })
      .catch(error => Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      )
      .then(() => this.submitting = false)
  }

  render (): React.ReactNode {
    return <>
      <h6 className="text-muted mb-4">Account Access</h6>
      <LazyResourcePanel resource={this.user}>
        {user => <>
          <div className="row">
            <div className="col-lg-3 col-md-4 col-12">
              <label>Username</label> <RequireRole role={UserRole.Admin}>
              {() => <Link to={route(Routes.system.users.edit, { id: user.id })}><i className="fa fa-pencil"/></Link>}
            </RequireRole>
              <input type="text" disabled className="form-control" value={user.username}/>
            </div>
            <div className="col-lg-3 col-md-4 col-12">
              <label>Change Password</label>
              <input type="password" className="form-control" name="password" value={this.formState.get('password')} onChange={this.formState.onChange}/>
              <FormError errors={this.formErrors} fieldName="password"/>
            </div>
            <div className="col-lg-3 col-md-4 col-12">
              <label>Repeat Password</label>
              <input type="password" className="form-control" name="repeatPassword" value={this.formState.get('repeatPassword')} onChange={this.formState.onChange}/>
              <FormError errors={this.formErrors} fieldName="repeatPassword"/>
            </div>
            <div className="col-lg-3 col-12">
              <label>&nbsp;</label>
              <div>
                <ButtonLoader
                  color="primary"
                  loading={this.submitting}
                  onClick={() => this.submit()}
                >Change Password</ButtonLoader>
              </div>
            </div>
          </div>
        </>}
      </LazyResourcePanel>
    </>
  }
}