import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import * as React from "react"
import * as FormInputs from "./FormInputs"

export default class FormHelper<P> {
  @observable
  public readonly formState: FormState<P>
  @observable
  public readonly formErrors: ErrorBag

  constructor (formState: FormState<P>, formErrors: ErrorBag) {
    this.formState = formState
    this.formErrors = formErrors
  }

  getFormState = () => {
    return this.formState
  }

  toObject () {
    return this.formState.toObject()
  }

  renderTextInput = (options: FormInputs.TextInputOptions): React.ReactNode => {
    return FormInputs.renderTextInput(this.formState, this.formErrors, options)
  }

  renderTextAreaInput = (options: FormInputs.TextAreaInputOptions): React.ReactNode => {
    return FormInputs.renderTextAreaInput(this.formState, this.formErrors, options)
  }

  renderDatePickerInput = (options: FormInputs.DatePickerInputOptions): React.ReactNode => {
    return FormInputs.renderDatePickerInput(this.formState, this.formErrors, options)
  }

  renderTimePickerInput = (options: FormInputs.DatePickerInputOptions): React.ReactNode => {
    return FormInputs.renderTimePickerInput(this.formState, this.formErrors, options)
  }

  renderSelectInput = (options: FormInputs.SelectInputOptions): React.ReactNode => {
    return FormInputs.renderSelectInput(this.formState, this.formErrors, options)
  }

  renderAsyncSelectInput = (options: FormInputs.AsyncSelectInputOptions): React.ReactNode => {
    return FormInputs.renderAsyncSelectInput(this.formState, this.formErrors, options)
  }

  renderCheckboxInput = (options: FormInputs.CheckboxInputOptions): React.ReactNode => {
    return FormInputs.renderCheckboxInput(this.formState, this.formErrors, options)
  }
}