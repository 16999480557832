import { observer } from 'mobx-react'
import React from 'react'
import { observable, toJS } from 'mobx'
import Util from '../../common/Util'
import ApiClient from '../../api/ApiClient'
import LoadingPanel from '../LoadingPanel'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { route } from '../../routes/routes'
import { Routes } from '../../routes/AppRoutes'
import TableView, { TableViewColumn } from '../table-view/TableView'
import { ArrayTableViewAdapter } from '../table-view/ArrayTableViewAdapter'

type ApplicationData = {
  id: number
  status: string
  guest: {
    fullName: string
    email: string | null
    phone: string | null
  } | null
  member: {
    fullName: string
    phoneNumber: string | null
    emailAddress: string | null
  }
  targetChapter: {
    id: number
    name: string
  }
}

type ApplicationListData = {
  applications: ApplicationData[]
}

type Props = {
  url: string
  title: string
}

@observer
export default class ApplicationList extends React.Component<Props> {
  @observable
  private applications?: ApplicationListData
  @observable
  private error?: string
  @observable
  private loading = false

  componentDidMount () {
    this.loadData().then()
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (!_.isEqual(toJS(prevProps.url), toJS(this.props.url))) {
      this.loadData().then()
    }
  }

  private loadData = async () => {
    try {
      this.error = undefined
      this.loading = true

      const response = await ApiClient.getInstance().get(this.props.url)

      this.applications = response.data

      this.loading = false
    } catch (err) {
      this.error = Util.extractErrorMessage(err.response)
    }
  }

  get columns (): TableViewColumn[] {
    return [
      {
        accessor: 'member',
        title: 'Name',
        sortable: true,
        renderItem: (name: string, application: ApplicationData) => <Link to={route(Routes.system.applications.show, { id: application.id })}>{application.member ? application.member.fullName : application.guest!.fullName}</Link>,
      },
      {
        accessor: 'email',
        sortable: true,
        title: 'Email',
        renderItem: (name: string, application: ApplicationData) => application.member ? application.member.emailAddress : application.guest!.email,
      },
      {
        accessor: 'phone',
        sortable: true,
        title: 'Phone',
        renderItem: (name: string, application: ApplicationData) => application.member ? application.member.phoneNumber : application.guest!.phone,
      },
      {
        accessor: 'status',
        sortable: true,
        title: 'Status',
      },
      {
        accessor: 'targetChapter',
        title: 'Chapter',
        sortable: true,
        renderItem: (chapter: {id: number, name: string} | null) => chapter ? <Link to={route(Routes.system.chapters.show, { id: chapter.id })}>{chapter.name}</Link> : '',
      },
    ]
  }

  render () {
    return <div>
      <h2>{this.props.title}</h2>
      <div>
        <LoadingPanel loading={this.loading} loaded={!!this.applications} error={this.error}>
          {
            this.applications
              ? <TableView
                hideItemCount={true}
                hideRowNumbers={true}
                adapter={new ArrayTableViewAdapter(this.applications.applications, {
                  sort: {
                    member: (guest: ApplicationData) => guest.member ? guest.member.fullName : guest.guest!.fullName,
                    email: (guest: ApplicationData) => guest.member ? guest.member.emailAddress : guest.guest!.email,
                    phone: (guest: ApplicationData) => guest.member ? guest.member.phoneNumber : guest.guest!.phone,
                    targetChapter: (guest: ApplicationData) => guest.targetChapter ? guest.targetChapter.name : '',
                  }
                })}
                columns={this.columns}
              />
              : null
          }
        </LoadingPanel>
      </div>
    </div>
  }
}
