import BaseModel from "./BaseModel"

export interface IAddress {
  id?: number
  businessName?: string,
  address: string
  address2?: string
  city: string
  state: string
  zipCode: string
  country: string
}

export default class Address extends BaseModel implements IAddress {
  id?: number
  address: string
  address2?: string
  city: string
  state: string
  zipCode: string
  country: string

  constructor (data = {}) {
    super();
    this.init(data);
  }

  getFullAddress = () => {
    return Address.getFullAddress(this)
  }

  getGoogleMapsUrl = () => {
    return Address.getGoogleMapsUrl(this)
  }

  static getFullAddress = (address: IAddress) => {
    return address.address
      ? [address.address, address.address2, address.city, address.state, address.zipCode, address.country].filter(p => p && p.trim().length).join(', ')
      : [address.city, address.state, address.zipCode, address.country].filter(p => p && p.trim().length).join(', ')
  }

  static getGoogleMapsUrl = (address: IAddress) => {
    return `https://maps.google.com/?q=${encodeURIComponent(Address.getFullAddress(address))}`
  }
}
