import BaseModel from "./BaseModel"
import { createLazyResource, modelToCamelCase } from "../common/Util"
import ApiClient from "../api/ApiClient"
import * as _ from "lodash"

export const BOARD_MEMBER_TYPE = 'board'
export const CHAIR_MEMBER_TYPE = 'chair'

export default class Position extends BaseModel {
  id: number
  type: string | 'board' | 'chair'
  displayType: string | 'board' | 'chair'
  code: string
  name: string
  maxAllowed: number
  displayOrder: number

  static createLazyResourceMany () {
    return createLazyResource<Position[]>(() => ApiClient.positions.getPositions(), response => {
      return _.map(response.data.positions, p => (new Position().init(modelToCamelCase(p))))
    })
  }
}
