import * as React from "react"
import { observer } from "mobx-react"
import ReportDataSource, { FieldSpec, FieldType, RelationshipSpec, ResourceSpec } from "../../../reporting/ReportDataSource"
import MemberReportDataSource from "../../../reporting/data-sources/MemberReportDataSource"
import ChapterReportDataSource from "../../../reporting/data-sources/ChapterReportDataSource"
import TableView, { TableViewColumn } from "../../table-view/TableView"
import { autorun, computed, IObservableArray, observable, toJS } from "mobx"
import { QueryWhereClause } from "../../../api/ApiClient"
import Config from "../../../common/Config"
import { ChooseableColumn, TableViewColumnChooser } from "../../table-view/TableViewColumnChooser"
import * as _ from "lodash"
import { escapeLike } from "../../../common/Util"
import moment from "moment-timezone"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import TableViewExportButton from "../../table-view/TableViewExportButton"
import DynamicReportCsvTransformer from "../../../reporting/DynamicReportCsvTransformer"
import { TableViewPagination } from "../../table-view/TableViewPagination"
import DateTime from "react-datetime"
import SelectInput from "../../inputs/SelectInput"

const uuid4 = require('uuid/v4')

type StringFilterData = {
  operator: string | 'starts_with' | 'contains' | 'equals'
  value: string
}

type DateFilterData = {
  operator: string | 'after' | 'before' | 'between' | 'equals'
  value1: string
  value2: string
}

type EnumFilterData = {
  values: string[]
}

type NumberFilterData = {
  operator: string | 'equals' | 'less_than' | 'greater_than' | 'between'
  value1: string
  value2: string
}

type BooleanFilterData = {
  value: boolean | undefined
}

type FieldFilter = {
  uuid: string
  fieldKey: string
  resourcePath: FilterListNode[]
  fieldSpec: FieldSpec
  filterData: StringFilterData | DateFilterData | EnumFilterData | NumberFilterData | BooleanFilterData
}

@observer
export default class CustomReportBuilder extends React.Component {
  private dataSources: { id: string, dataSource: ReportDataSource }[] = [
    { id: 'members', dataSource: new MemberReportDataSource() },
    { id: 'chapters', dataSource: new ChapterReportDataSource() },
  ]
  private tableViewRef = React.createRef<TableView>()

  @observable private selectedDataSourceId: string = ''
  @observable private tableViewOffset = 0
  @observable private chosenColumns: TableViewColumn[] = []
  @observable private selectedRelationships: string[] = []
  @observable private showFilterModal = false
  @observable private filters: FieldFilter[] = []
  @observable private filterWhereClauses: QueryWhereClause[] = []
  @observable private numberOfPages = 0
  @observable private currentPage = 0

  private previousDataSourceId?: string

  @computed
  private get reportAdapter () {
    // need to query these so reactive props work
    const where = this.filterWhereClauses
    const relationships = this.selectedRelationships
    return this.selectedDataSource ? this.selectedDataSource.createTableViewAdapter(relationships, where,
      {
        onFetched: result => {
          this.numberOfPages = Math.ceil(result.total / Config.TABLE_VIEW_PAGE_SIZE)
          if (this.currentPage < 1) {
            this.currentPage = 1
          }
          if (this.currentPage > this.numberOfPages) {
            this.currentPage = this.numberOfPages
          }
        }
      }) : undefined
  }

  @computed
  private get visibleColumns (): TableViewColumn[] {
    const columns = this.chosenColumns
    return this.reportAdapter ? columns : []
  }

  @computed
  private get availableColumns (): ChooseableColumn[] {
    const selectedRelationships = toJS(this.selectedRelationships)

    const listColumns = (resource: ResourceSpec, parents: RelationshipSpec[] = []): ChooseableColumn[] => {
      const columns: ChooseableColumn[] = resource.fields.map(field => (
          {
            column: {
              title: parents.map(p => p.label || _.startCase(p.name)).concat([field.label || _.startCase(field.name)]).join(' - '),
              accessor: parents.map(p => p.name).concat([field.name]).join('.'),
              renderItem: (value: any) => {
                if (field.type === FieldType.Date) {
                  return value ? value.format('MM/DD/YYYY') : ''
                } else if (field.type === FieldType.DateTime) {
                  return value ? value.format('MM/DD/YYYY HH:mm:ss') : ''
                } else {
                  return value
                }
              },
              sortable: true,
            },
            default: true,
          }
        )
      )

      return columns.concat(_.flatMap(resource.relationships.filter(r => selectedRelationships.indexOf(parents.map(p => p.name).concat([r.name]).join('.')) > -1), rel => listColumns(rel.resourceSpec, parents.concat([rel]))))
    }

    return this.selectedDataSource ? listColumns(this.selectedDataSource.resource) : []
  }

  @computed
  private get selectedDataSource (): ReportDataSource | undefined {
    return this.selectedDataSourceId ? this.dataSources.find(d => d.id === this.selectedDataSourceId)!.dataSource : undefined
  }

  componentDidMount (): void {
    autorun(() => {
      if (this.selectedDataSourceId !== this.previousDataSourceId) {
        this.previousDataSourceId = this.selectedDataSourceId
        this.filters = []
        this.selectedRelationships = []
        this.chosenColumns = this.selectedDataSource ? TableViewColumnChooser.getInitialColumns(this.availableColumns) : []
        this.currentPage = 0
      }
    })
  }

  private setPage = (page: number) => {
    this.currentPage = page
  }

  private toggleRelationship = (relationship: string, enabled: boolean) => {
    if (enabled) {
      if (this.selectedRelationships.indexOf(relationship) === -1) {
        this.selectedRelationships.push(relationship)
      }
    } else {
      if (this.selectedRelationships.indexOf(relationship) > -1) {
        (this.selectedRelationships as IObservableArray).remove(relationship)
      }
    }
  }

  private addFilter = (path: FilterListNode[], fieldKey: string, field: FieldSpec) => {
    if (!this.filters.filter(f => f.fieldKey === fieldKey).length) {
      if (field.type === FieldType.String) {
        this.filters.push({
          uuid: uuid4(),
          fieldKey: fieldKey,
          resourcePath: path,
          fieldSpec: field,
          filterData: {
            operator: 'starts_with',
            value: '',
          },
        })
      } else if (field.type === FieldType.Date || field.type === FieldType.DateTime) {
        this.filters.push({
          uuid: uuid4(),
          fieldKey: fieldKey,
          resourcePath: path,
          fieldSpec: field,
          filterData: {
            operator: 'equals',
            value1: '',
            value2: '',
          },
        })
      } else if (field.type === FieldType.Enum) {
        this.filters.push({
          uuid: uuid4(),
          fieldKey: fieldKey,
          resourcePath: path,
          fieldSpec: field,
          filterData: {
            values: [],
          },
        })
      } else if (field.type === FieldType.Number) {
        this.filters.push({
          uuid: uuid4(),
          fieldKey: fieldKey,
          resourcePath: path,
          fieldSpec: field,
          filterData: {
            operator: 'equals',
            value1: '',
            value2: '',
          },
        })
      } else if (field.type === FieldType.Boolean) {
        this.filters.push({
          uuid: uuid4(),
          fieldKey: fieldKey,
          resourcePath: path,
          fieldSpec: field,
          filterData: {
            value: undefined,
          },
        })
      }
    }

    this.showFilterModal = false
  }

  private removeFilter = (uuid: string) => {
    this.filters = this.filters.filter(f => f.uuid !== uuid)
  }

  private renderFilter = (filter: FieldFilter) => {
    if (filter.fieldSpec.type === FieldType.String) {
      return <StringFilter
        filter={filter}
        removeFilter={this.removeFilter}
      />
    } else if (filter.fieldSpec.type === FieldType.Date || filter.fieldSpec.type === FieldType.DateTime) {
      return <DateFilter
        filter={filter}
        removeFilter={this.removeFilter}
      />
    } else if (filter.fieldSpec.type === FieldType.Enum) {
      return <EnumFilter
        filter={filter}
        removeFilter={this.removeFilter}
      />
    } else if (filter.fieldSpec.type === FieldType.Number) {
      return <NumberFilter
        filter={filter}
        removeFilter={this.removeFilter}
      />
    } else if (filter.fieldSpec.type === FieldType.Boolean) {
      return <BooleanFilter
        filter={filter}
        removeFilter={this.removeFilter}
      />
    } else {
      return null
    }
  }

  private applyFilters = () => {
    const createWhereClause = (filter: FieldFilter) => {
      if (filter.fieldSpec.type === FieldType.String) {
        const filterData = filter.filterData as StringFilterData
        if (filterData.value.trim().length) {
          if (filterData.operator === 'starts_with') {
            return {
              id: filter.fieldKey,
              op: 'like',
              value: `${escapeLike(filterData.value)}%`,
            }
          } else if (filterData.operator === 'contains') {
            return {
              id: filter.fieldKey,
              op: 'like',
              value: `%${escapeLike(filterData.value)}%`,
            }
          } else if (filterData.operator === 'equals') {
            return {
              id: filter.fieldKey,
              op: '=',
              transform: filter.fieldSpec.fieldTransform || '',
              value: filterData.value,
            }
          }
        }
      } else if (filter.fieldSpec.type === FieldType.Date || filter.fieldSpec.type === FieldType.DateTime) {
        const filterData = filter.filterData as DateFilterData
        const parts: QueryWhereClause[] = []

        if (filterData.value1.trim().length && moment(filterData.value1).isValid()) {
          if (filterData.operator === 'equals') {
            parts.push({
              id: filter.fieldKey,
              op: 'date:=',
              value: moment(filterData.value1).format('YYYY-MM-DD')
            })
          } else if (filterData.operator === 'after') {
            parts.push({
              id: filter.fieldKey,
              op: 'date:>=',
              value: moment(filterData.value1).format('YYYY-MM-DD')
            })
          } else if (filterData.operator === 'before') {
            parts.push({
              id: filter.fieldKey,
              op: 'date:<=',
              value: moment(filterData.value1).format('YYYY-MM-DD')
            })
          } else if (filterData.operator === 'between') {
            parts.push({
              id: filter.fieldKey,
              op: 'date:>=',
              value: moment(filterData.value1).format('YYYY-MM-DD')
            })
          }
        }

        if (filterData.operator === 'between' && moment(filterData.value1).isValid()) {
          parts.push({
            id: filter.fieldKey,
            op: 'date:<=',
            value: moment(filterData.value2).format('YYYY-MM-DD')
          })
        }

        return parts
      } else if (filter.fieldSpec.type === FieldType.Enum) {
        const filterData = filter.filterData as EnumFilterData
        if (filterData.values.length) {
          return {
            id: filter.fieldKey,
            op: 'in',
            value: filterData.values,
          }
        }
      } else if (filter.fieldSpec.type === FieldType.Number) {
        const filterData = filter.filterData as NumberFilterData
        const parts: QueryWhereClause[] = []

        if (filterData.value1.trim().length) {
          if (filterData.operator === 'equals') {
            parts.push({
              id: filter.fieldKey,
              op: '=',
              value: filterData.value1,
            })
          } else if (filterData.operator === 'greater_than') {
            parts.push({
              id: filter.fieldKey,
              op: '>=',
              value: filterData.value1,
            })
          } else if (filterData.operator === 'less_than') {
            parts.push({
              id: filter.fieldKey,
              op: 'date:=',
              value: filterData.value1,
            })
          } else if (filterData.operator === 'between') {
            parts.push({
              id: filter.fieldKey,
              op: '>=',
              value: filterData.value1,
            })
          }
        }

        if (filterData.operator === 'between') {
          parts.push({
            id: filter.fieldKey,
            op: '<=',
            value: filterData.value2,
          })
        }

        return parts
      } else if (filter.fieldSpec.type === FieldType.Boolean) {
        const filterData = filter.filterData as BooleanFilterData
        if (filterData.value !== undefined) {
          return {
            id: filter.fieldKey,
            op: '=',
            value: filterData.value,
          }
        }
      }

      return undefined
    }

    this.filterWhereClauses = _.flatMap(this.filters, filter => createWhereClause(filter)).filter(p => p !== undefined)
  }

  private runReport = () => {
    this.applyFilters()
  }

  render () {
    return <>
      <div className="row">
        <div className="col-md-6">
          <select
            className="form-control"
            value={this.selectedDataSourceId}
            onChange={ev => this.selectedDataSourceId = ev.target.value}
          >
            <option value="">(select a report)</option>
            {this.dataSources.map(dataSource => <option key={dataSource.id} value={dataSource.id}>{dataSource.dataSource.resource.label || _.startCase(dataSource.dataSource.resource.collectionResourceName)}</option>)}
          </select>
        </div>
        <div className="col-md-6">
          {
            this.selectedDataSource
              ? <>
                <h6 className="text-muted">Choose Related Data</h6>
                <RelationshipChooser
                  resource={this.selectedDataSource.resource}
                  selectedRelationships={this.selectedRelationships}
                  onChange={this.toggleRelationship}
                />
              </>
              : null
          }
        </div>
      </div>
      {
        this.selectedDataSource
          ? <>
            <hr/>
            <h6 className="text-muted">Filters</h6>
            {
              this.filters.map(filter => <div key={filter.uuid}>
                {this.renderFilter(filter)}
              </div>)
            }
            <div>
              <Button
                type="button"
                color="primary"
                onClick={() => this.showFilterModal = true}
              >Add A Filter</Button>
            </div>
          </>
          : null
      }
      {
        this.selectedDataSource
          ? <div className="form-buttons">
            <TableViewColumnChooser
              columns={this.availableColumns}
              onChange={columns => {
                this.chosenColumns = columns
              }}
            />
            <TableViewExportButton
              tableViewRef={this.tableViewRef}
              transformerClass={() => new DynamicReportCsvTransformer(`${this.selectedDataSource!.resource.collectionResourceName}-${moment().format('YYYYMMDD')}.csv`, this.visibleColumns)}
            />
            <Button
              type="button"
              color="success"
              onClick={this.runReport}
            ><i className="fa fa-play"/> Run Report</Button>
          </div>
          : null
      }
      {
        this.reportAdapter
          ? <>
            <TableView
              ref={this.tableViewRef}
              adapter={this.reportAdapter}
              columns={this.visibleColumns}
              pagination={{
                offset: Math.max((this.currentPage - 1), 0) * Config.TABLE_VIEW_PAGE_SIZE,
                limit: Config.TABLE_VIEW_PAGE_SIZE,
              }}
            />
            {
              this.numberOfPages > 1
                ? <TableViewPagination numberOfPages={this.numberOfPages} currentPage={this.currentPage} setPage={page => this.setPage(page)}/>
                : null
            }
          </>
          : null
      }
      <Modal isOpen={this.showFilterModal} toggle={() => this.showFilterModal = false}>
        <ModalHeader toggle={() => this.showFilterModal = false}>Add Filter</ModalHeader>
        <ModalBody>
          {
            this.selectedDataSource
              ? <FilterList
                resource={this.selectedDataSource.resource}
                selectedRelationships={this.selectedRelationships}
                onFieldSelected={this.addFilter}
              />
              : null
          }
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            onClick={() => this.showFilterModal = false}
          >Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  }
}

type RelationshipChooserProps = {
  resource: ResourceSpec
  selectedRelationships: string[]
  onChange: (relationship: string, enabled: boolean) => void
}

@observer
class RelationshipChooser extends React.Component<RelationshipChooserProps> {
  render () {
    const expandRelationships = (rels: RelationshipSpec[], pathParts: string[] = []): React.ReactNode => {
      return rels.map(rel => {
        const newPathParts = pathParts.concat([rel.name])
        const path = newPathParts.join('.')

        return <div
          key={rel.name}
          style={{
            paddingLeft: 20,
          }}>
          <div className="form-check form-checkbox">
            <label>
              <input type="checkbox"
                     className="form-check-input"
                     checked={this.props.selectedRelationships.indexOf(path) > -1}
                     onChange={ev => this.props.onChange(path, ev.target.checked)}
              />
              <span className="label-text">{rel.label || _.startCase(rel.name)}</span>
            </label>
          </div>
          {
            this.props.selectedRelationships.indexOf(path) > -1
              ? expandRelationships(rel.resourceSpec.relationships, newPathParts)
              : null
          }
        </div>
      })
    }

    return <div>
      {expandRelationships(this.props.resource.relationships)}
    </div>
  }
}

type FilterListProps = {
  resource: ResourceSpec
  selectedRelationships: string[]
  onFieldSelected: (path: FilterListNode[], fieldKey: string, field: FieldSpec) => void
}

type FilterListNode = {
  relationship?: RelationshipSpec
  resource: ResourceSpec
}

@observer
class FilterList extends React.Component<FilterListProps> {
  private renderFields = (resource: ResourceSpec, path: FilterListNode[], namePath: string[]) => {
    return [
      <div
        className="filter-list-header"
        key={namePath.join('.')}
      >
        {path.map((node: FilterListNode) =>
          node.relationship
            ? (node.relationship.label || _.startCase(node.relationship.name))
            : _.startCase(node.resource.label || _.startCase(node.resource.collectionResourceName))).join(' - ')}
      </div>,
      resource.fields.map(field => {
        const fieldKey = namePath.concat([field.name]).join('.')
        return <div className="filter-list-field d-flex"
                    key={fieldKey}
        >
          <Button
            size="sm"
            type="button"
            color="success"
            onClick={() => this.props.onFieldSelected(path, fieldKey, field)}
          ><i className="fa fa-plus"/></Button>
          <div className="filter-field-name">{field.label || _.startCase(field.name)}</div>
        </div>
      })
    ]
  }

  render () {
    const renderChildren = (children: RelationshipSpec[], path: FilterListNode[] = [], namePath: string[] = []): React.ReactNode => {
      return children.filter(c => this.props.selectedRelationships.indexOf(namePath.concat([c.name]).join('.')) > -1)
        .map(rel => [
          this.renderFields(rel.resourceSpec, path.concat([{ relationship: rel, resource: rel.resourceSpec }]), namePath.concat([rel.name])),
          renderChildren(rel.resourceSpec.relationships, path.concat([{ relationship: rel, resource: rel.resourceSpec }]), namePath.concat([rel.name])),
        ])
    }

    return <>
      {this.renderFields(this.props.resource, [{ resource: this.props.resource }], [])}
      {renderChildren(this.props.resource.relationships, [{ resource: this.props.resource }])}
    </>
  }
}

type StringFilterProps = {
  filter: FieldFilter
  removeFilter: (uuid: string) => void
}

@observer
class StringFilter extends React.Component<StringFilterProps> {
  render () {
    const filter = this.props.filter
    const filterData = filter.filterData as StringFilterData

    return <div className="field-filter-widget">
      <div>
        <a href=""
           onClick={ev => {
             ev.preventDefault()
             this.props.removeFilter(this.props.filter.uuid)
           }}
        ><i className="fa fa-minus text-danger"/></a>
      </div>
      <div>{filter.resourcePath.map(node =>
        (node.relationship
          ? (node.relationship.label || _.startCase(node.relationship.name))
          : _.startCase(node.resource.label || _.startCase(node.resource.collectionResourceName)))).concat([filter.fieldSpec.label || _.startCase(filter.fieldSpec.name)]).join(' - ')}
      </div>
      <select
        className="form-control"
        style={{ width: 'auto' }}
        value={filterData ? filterData.operator : 'starts_with'}
        onChange={ev => filterData.operator = ev.target.value}
      >
        <option value="starts_with">Starts With</option>
        <option value="contains">Contains</option>
        <option value="equals">Equals</option>
      </select>
      <input
        className="form-control"
        style={{ width: 'auto' }}
        type="text"
        value={filterData.value}
        onChange={ev => filterData.value = ev.target.value}
      />
    </div>
  }
}

type DateFilterProps = {
  filter: FieldFilter
  removeFilter: (uuid: string) => void
}

@observer
class DateFilter extends React.Component<DateFilterProps> {

  render () {
    const filter = this.props.filter
    const filterData = filter.filterData as DateFilterData

    return <div className="field-filter-widget">
      <div>
        <a href=""
           onClick={ev => {
             ev.preventDefault()
             this.props.removeFilter(this.props.filter.uuid)
           }}
        ><i className="fa fa-minus text-danger"/></a>
      </div>
      <div>{filter.resourcePath.map(node =>
        (node.relationship
          ? (node.relationship.label || _.startCase(node.relationship.name))
          : _.startCase(node.resource.label || _.startCase(node.resource.collectionResourceName)))).concat([filter.fieldSpec.label || _.startCase(filter.fieldSpec.name)]).join(' - ')}
      </div>
      <select
        className="form-control"
        style={{ width: 'auto' }}
        value={filterData.operator}
        onChange={ev => filterData.operator = ev.target.value}
      >
        <option value="equals">Equals</option>
        <option value="after">Is After</option>
        <option value="before">Is Before</option>
        <option value="between">Is Between</option>
      </select>
      <DateTime
        value={filterData.value1}
        onChange={(v: any) => {
          filterData.value1 = moment.isMoment(v) ? v.format('MM/DD/YYYY') : v
        }}
        timeFormat={false}
        closeOnSelect={true}
      />
      {
        filterData.operator === 'between'
          ? <>
            <div> and</div>
            <DateTime
              value={filterData.value2}
              onChange={(v: any) => {
                filterData.value2 = moment.isMoment(v) ? v.format('MM/DD/YYYY') : v
              }}
              timeFormat={false}
              closeOnSelect={true}
            />
          </>
          : null
      }
    </div>
  }
}

type EnumFilterProps = {
  filter: FieldFilter
  removeFilter: (uuid: string) => void
}

@observer
class EnumFilter extends React.Component<EnumFilterProps> {
  render () {
    const filter = this.props.filter
    const filterData = filter.filterData as EnumFilterData

    return <div className="field-filter-widget">
      <div>
        <a href=""
           onClick={ev => {
             ev.preventDefault()
             this.props.removeFilter(this.props.filter.uuid)
           }}
        ><i className="fa fa-minus text-danger"/></a>
      </div>
      <div>{filter.resourcePath.map(node =>
        (node.relationship
          ? (node.relationship.label || _.startCase(node.relationship.name))
          : _.startCase(node.resource.label || _.startCase(node.resource.collectionResourceName)))).concat([filter.fieldSpec.label || _.startCase(filter.fieldSpec.name)]).join(' - ')}
      </div>
      <SelectInput
        className="field-filter-select"
        isMulti={true}
        loadOptions={(inputValue: any, callback: any) => {
          callback(
            filter.fieldSpec.values!.filter(v => !inputValue || inputValue.length == 0 || (v.label || v.value).toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
              .map(v => ({
                label: v.label || v.value,
                value: v
              }))
          )
        }}
        onChange={option => filterData.values = _.isArray(option) ? option.map(o => o.value) : [option.value]}
      />
    </div>
  }
}

type NumberFilterProps = {
  filter: FieldFilter
  removeFilter: (uuid: string) => void
}

@observer
class NumberFilter extends React.Component<NumberFilterProps> {

  render () {
    const filter = this.props.filter
    const filterData = filter.filterData as NumberFilterData

    return <div className="field-filter-widget">
      <div>
        <a href=""
           onClick={ev => {
             ev.preventDefault()
             this.props.removeFilter(this.props.filter.uuid)
           }}
        ><i className="fa fa-minus text-danger"/></a>
      </div>
      <div>{filter.resourcePath.map(node =>
        (node.relationship
          ? (node.relationship.label || _.startCase(node.relationship.name))
          : _.startCase(node.resource.label || _.startCase(node.resource.collectionResourceName)))).concat([filter.fieldSpec.label || _.startCase(filter.fieldSpec.name)]).join(' - ')}
      </div>
      <select
        className="form-control"
        style={{ width: 'auto' }}
        value={filterData.operator}
        onChange={ev => filterData.operator = ev.target.value}
      >
        <option value="equals">Equals</option>
        <option value="greater_than">Is Greater Than</option>
        <option value="less_than">Is Less Than</option>
        <option value="between">Is Between</option>
      </select>
      <input
        className="form-control"
        style={{ width: 'auto' }}
        type="text"
        value={filterData.value1}
        onChange={ev => filterData.value1 = ev.target.value}
      />
      {
        filterData.operator === 'between'
          ? <>
            <div> and</div>
            <input
              className="form-control"
              style={{ width: 'auto' }}
              type="text"
              value={filterData.value2}
              onChange={ev => filterData.value2 = ev.target.value}
            />
          </>
          : null
      }
    </div>
  }
}

type BooleanFilterProps = {
  filter: FieldFilter
  removeFilter: (uuid: string) => void
}

@observer
class BooleanFilter extends React.Component<BooleanFilterProps> {
  render () {
    const filter = this.props.filter
    const filterData = filter.filterData as BooleanFilterData

    return <div className="field-filter-widget">
      <div>
        <a href=""
           onClick={ev => {
             ev.preventDefault()
             this.props.removeFilter(this.props.filter.uuid)
           }}
        ><i className="fa fa-minus text-danger"/></a>
      </div>
      <div>{filter.resourcePath.map(node =>
        (node.relationship
          ? (node.relationship.label || _.startCase(node.relationship.name))
          : _.startCase(node.resource.label || _.startCase(node.resource.collectionResourceName)))).concat([filter.fieldSpec.label || _.startCase(filter.fieldSpec.name)]).join(' - ')}
      </div>
      <select
        className="form-control"
        style={{ width: 'auto' }}
        value={filterData.value === undefined ? '' : (filterData.value ? '1' : '0')}
        onChange={ev => filterData.value = ev.target.value === '' ? undefined : (!!ev.target.value)}
      >
        <option value="">Any</option>
        <option value="1">Is True</option>
        <option value="0">Is False</option>
      </select>
    </div>
  }
}