import AppStateStore from "../stores/AppStateStore"
import { observer } from "mobx-react"
import * as React from "react"
import ConfirmationModal from "./ConfirmationModal"

@observer
export default class AppModals extends React.Component {
  render (): React.ReactNode {
    return AppStateStore.modals.map(m => <ConfirmationModal key={m.key} {...m.props} />)
  }
}