import * as React from "react"
import { SyntheticEvent } from "react"
import { Prompt } from "react-router-dom"
import { observable, toJS } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { observer } from "mobx-react"
import ButtonLoader from "../components/ButtonLoader"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import Util, { logException, modelToSnakeCase } from "../common/Util"
import { Button } from "reactstrap"
import * as AsyncHelpers from "../api/AsyncHelpers"
import Chapter from "../models/Chapter"
import { toast } from "react-toastify"
import Config from "../common/Config"
import AuthStore from "../stores/AuthStore"
import { BarLoader } from "react-spinners"

type EditChapterFormState = {
  name: string
  websiteUrl: string
  bio: string
  description: string
  meetingDay: string
  meetingTime: string
  meetingLocationDescription: string
  meetingLocationAddress: {
    address: string
    address2: string
    city: string
    state: string
    zipCode: string
    country: string
  }

  socialLinks: {
    facebook: string
    twitter: string
    linkedin: string
    instagram: string
    youtube: string
    alignable: string
    pinterest: string
    snapchat: string
    vimeo: string,
  }
}

type Props = {
  chapterId: number
  onSaved: () => void
  onCancel: () => void
}

@observer
export default class ChapterBasicInfoForm extends React.Component<Props> {
  @observable chapter?: Chapter

  @observable private formState = new FormState<EditChapterFormState>({
    name: '',
    websiteUrl: '',
    bio: '',
    description: '',
    meetingDay: '',
    meetingTime: '',
    meetingLocationDescription: '',
    meetingLocationAddress: {
      address: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
    },

    socialLinks: {
      facebook: '',
      twitter: '',
      linkedin: '',
      instagram: '',
      youtube: '',
      alignable: '',
      pinterest: '',
      snapchat: '',
      vimeo: '',
    },
  })
  @observable private formErrors = new ErrorBag()
  private formHelper = new FormHelper(this.formState, this.formErrors)
  @observable private submitting = false
  @observable private error?: string

  componentWillMount (): void {
    this.loadChapter()
  }

  private loadChapter = () => {
    ApiClient.query(
      `
chapter {
  *

  meetingLocationAddress {
    *
  }
}
      `,
      {
        where: [{ id: this.props.chapterId }]
      }
    )
      .then(response => {
        this.chapter = new Chapter().init(response.data.chapter)

        const data:any = toJS(this.chapter.toObject())

        if (!data.meetingLocationAddress) {
          data.meetingLocationAddress = {
            address: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
          }
        }


        this.formState.setAll(data)
      })
      .catch(err => this.error = (err.response.status === 404 ? 'The chapter you are looking for does not exist!' : Util.extractErrorMessage(err.response)))
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.chapters.update(this.props.chapterId, modelToSnakeCase(this.formHelper.toObject()))
      .then(() => {
        toast.success('Chapter updated')
        this.props.onSaved()
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  render (): React.ReactNode {
    if (this.chapter) {
      const chapter = this.chapter

      return <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
        {this.formState.isDirty && <Prompt message="Are you sure you want to leave this page? You will lose any unsaved changes!"/>}
        <h5 className="text-muted">Chapter Information</h5>
        <div className="form-row">
          <div className="col-sm-6">
            {this.formHelper.renderTextInput({
              name: 'name',
              type: 'text',
              label: 'Chapter Name',
              disabled: !AuthStore.getUser()!.hasRole('Admin'),
            })}
          </div>
          <div className="col-sm-6">
            {this.formHelper.renderTextInput({
              name: 'websiteUrl',
              type: 'text',
              label: 'Website',
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-12">
            {this.formHelper.renderTextAreaInput({
              name: 'bio',
              label: 'Bio',
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-12">
            {this.formHelper.renderTextAreaInput({
              name: 'description',
              label: 'Description',
            })}
          </div>
        </div>
        <hr/>

        <h5 className="text-muted">General Meeting Information</h5>
        <div className="form-row">
          <div className="col-sm-6">
            {this.formHelper.renderAsyncSelectInput({
              name: 'meetingDay',
              label: 'Meeting Day',
              loadOptions: AsyncHelpers.loadMeetingDayOptions,
              defaultValue: { value: chapter.meetingDay, label: chapter.meetingDay }
            })}
          </div>
          <div className="col-sm-6">
            {this.formHelper.renderAsyncSelectInput({
              name: 'meetingTime',
              label: 'Meeting Time',
              loadOptions: AsyncHelpers.loadMeetingTimeOptions,
              defaultValue: { value: chapter.meetingTime, label: chapter.meetingTime }
            })}
          </div>
        </div>

        <div className="form-row">
          <div className="col-sm-12">
            {this.formHelper.renderTextInput({
              name: 'meetingLocationDescription',
              type: 'text',
              label: 'General Meeting Location',
            })}
          </div>
        </div>

        <div className="form-row">
          <div className="col-sm-8">
            {this.formHelper.renderTextInput({
              name: 'meetingLocationAddress.address',
              type: 'text',
              label: 'Street',
            })}
          </div>
          <div className="col-sm-4">
            {this.formHelper.renderTextInput({
              name: 'meetingLocationAddress.address2',
              type: 'text',
              label: 'Suite / Room / Apt #',
            })}
          </div>
        </div>

        <div className="form-row">
          <div className="col-sm-3">
            {this.formHelper.renderTextInput({
              name: 'meetingLocationAddress.city',
              type: 'text',
              label: 'City',
            })}
          </div>
          <div className="col-sm-3">
            {this.formHelper.renderTextInput({
              name: 'meetingLocationAddress.state',
              type: 'text',
              label: 'State',
            })}
          </div>
          <div className="col-sm-3">
            {this.formHelper.renderTextInput({
              name: 'meetingLocationAddress.zipCode',
              type: 'text',
              label: 'Zip Code',
            })}
          </div>
          <div className="col-sm-3">
            {this.formHelper.renderSelectInput({
              name: 'meetingLocationAddress.country',
              options: Config.COUNTRY_OPTIONS.map(c => ({ value: c, text: c })),
              label: 'Country',
            })}
          </div>
        </div>
        <hr/>

        <h5 className="text-muted">Social Media Profiles</h5>
        <div className="form-row">
          <div className="col-sm-4">
            {this.formHelper.renderTextInput({
              name: 'socialLinks.facebook',
              type: 'text',
              placeholder: 'Facebook',
              prepend: <i className="fa fa-facebook-square"/>,
            })}
          </div>
          <div className="col-sm-4">
            {this.formHelper.renderTextInput({
              name: 'socialLinks.twitter',
              type: 'text',
              placeholder: 'Twitter',
              prepend: <i className="fa fa-twitter-square"/>,
            })}
          </div>
          <div className="col-sm-4">
            {this.formHelper.renderTextInput({
              name: 'socialLinks.linkedin',
              type: 'text',
              placeholder: 'Linkedin',
              prepend: <i className="fa fa-linkedin-square"/>,
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-4">
            {this.formHelper.renderTextInput({
              name: 'socialLinks.instagram',
              type: 'text',
              placeholder: 'Instagram',
              prepend: <i className="fa fa-instagram"/>,
            })}
          </div>
          <div className="col-sm-4">
            {this.formHelper.renderTextInput({
              name: 'socialLinks.youtube',
              type: 'text',
              placeholder: 'YouTube',
              prepend: <i className="fa fa-youtube"/>,
            })}
          </div>
          <div className="col-sm-4">
            {this.formHelper.renderTextInput({
              name: 'socialLinks.alignable',
              type: 'text',
              placeholder: 'Alignable',
              prepend: <i className="fa fa-home"/>,
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-4">
            {this.formHelper.renderTextInput({
              name: 'socialLinks.pinterest',
              type: 'text',
              placeholder: 'Pinterest',
              prepend: <i className="fa fa-pinterest"/>,
            })}
          </div>
          <div className="col-sm-4">
            {this.formHelper.renderTextInput({
              name: 'socialLinks.snapchat',
              type: 'text',
              placeholder: 'Snapchat',
              prepend: <i className="fa fa-snapchat"/>,
            })}
          </div>
          <div className="col-sm-4">
            {this.formHelper.renderTextInput({
              name: 'socialLinks.vimeo',
              type: 'text',
              placeholder: 'Vimeo',
              prepend: <i className="fa fa-vimeo"/>,
            })}
          </div>
        </div>

        <div className="form-buttons">
          <Button type="button" color="secondary" onClick={this.props.onCancel}><i className="fa fa-ban"/> Cancel</Button>
          <ButtonLoader type="submit" color="success" loading={this.submitting}><i className="fa fa-save"/> Update Chapter</ButtonLoader>
        </div>
      </form>
    } else {
      return <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
    }
  }
}
