import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone/moment-timezone"
import User from './User'
import Position from "./Position"
import Member from "./Member"

export default class ArchivedBoardMember extends BaseModel {
  id: number
  archivedAt: Moment
  position: Position
  member: Member

  constructor (data = {}) {
    super();
    this.init(data);
  }

  getCasts (): {} {
    return {
      id: 'number',
      archivedAt: 'datetime',
    }
  }
}
