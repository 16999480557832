import CsvTransformer from "./CsvTransformer"
import { safeNull } from "../../common/Util"
import moment from "moment-timezone"
import SupportArea from "../SupportArea"

export default class SupportAreasCsvTransformer extends CsvTransformer<SupportArea> {
  get filename () {
    return `support-areas-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'id',
      valueTransformer: row => String(row.id),
    })

    this.addColumn({
      header: 'Support Area Name',
      valueTransformer: row => safeNull(() => row.name) || '',
    })

    this.addColumn({
      header: 'Chapters',
      valueTransformer: row => safeNull(() => row.numberOfChapters) || '',
    })
  }
}