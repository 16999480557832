import { observer } from 'mobx-react'
import React, { SyntheticEvent } from 'react'
import { observable } from 'mobx'
import FormState from '../../../common/FormState'
import ErrorBag from '../../../common/ErrorBag'
import FormHelper from '../../../forms/FormHelper'
import Member from '../../../models/Member'
import SponsorCredit from '../../../models/SponsorCredit'
import ApiClient, { ApiRoutes } from '../../../api/ApiClient'
import Util, { modelToSnakeCase, safeNull } from '../../../common/Util'
import { Button, Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'
import { route } from '../../../routes/routes'
import { Routes } from '../../../routes/AppRoutes'
import AppStateStore from '../../../stores/AppStateStore'
import { toast } from 'react-toastify'

type Props = {
  memberId: number
}

@observer
export class ManageSponsorCredits extends React.Component<Props> {
  @observable private loading = false
  @observable private error?: string
  @observable private member?: Member
  @observable private sponsorCredits?: SponsorCredit[]
  @observable private submitting = false

  @observable private formState = new FormState({
    numberOfSponsorCredits: '',
    note: '',
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private loadData = async () => {
    this.loading = true
    this.error = undefined

    try {
      let response = await ApiClient.query(`
    member {
      numberOfSponsorCredits
    }
    `, {
        where: [{ id: this.props.memberId }],
      })

      this.member = new Member().init(response.data.member)

      response = await ApiClient.query(`
      sponsorCredits {
        id
        createdAt
        sponsoredMember {
          id
          joinDate
          fullName
          chapter {
            name
          }
        }
      }
      `, {
        where: [
          { memberId: this.props.memberId },
          { id: 'appliedAt', op: 'null' },
        ],
      })

      this.sponsorCredits = response.data.sponsorCredits.map((d: any) => new SponsorCredit().init(d))

      this.formState.set('numberOfSponsorCredits', String(this.member.numberOfSponsorCredits))
    } catch (e) {
      console.error(e)
      this.error = Util.extractErrorMessage(e.response)
    }

    this.loading = false
  }

  componentDidMount (): void {
    this.loadData()
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.memberId !== prevProps.memberId) {
      this.loadData()
    }
  }

  removeSponsorCredit (id: number) {
    AppStateStore.showConfirmationModal('Confirm', 'Are you sure you want to remove this sponsorship credit? You will still need to manually adjust the number of sponsor credits this member has.', (result, modal) => {
      modal.hide()

      if (result) {
        ;(async () => {
          AppStateStore.showModalSpinner()

          try {
            await ApiClient.getInstance().delete(route(ApiRoutes.members.removeSponsorCredit, { id: id }))
          } catch (err) {
            AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
            this.loadData().then()
          }

          AppStateStore.dismissModalSpinner()

          this.loadData().then()

          toast.success('Sponsor credit entry removed')
        })()
      }
    })
  }

  submit = async (ev?: SyntheticEvent) => {
    ev && ev.preventDefault()

    this.submitting = true
    this.formErrors.clearErrors()

    AppStateStore.showModalSpinner()

    try {
      await ApiClient.getInstance()
        .post(route(ApiRoutes.members.updateSponsorCredits, { id: this.props.memberId }), modelToSnakeCase(this.formState.toObject()))
    } catch (error) {
      Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      })
    }

    toast.success('Sponsor credits updated')

    this.formState.set('note', '')
    this.loadData().then()

    this.submitting = false
    AppStateStore.dismissModalSpinner()
  }

  render () {
    return this.loading
      ? <Spinner/>
      : this.error
        ? <div className="alert alert-danger">{this.error}</div>
        : <div className="form-row">
          <div className="col-md-6">
            <form action="" onSubmit={this.submit} method={'get'}>
              {
                this.formHelper.renderTextInput({
                  name: 'numberOfSponsorCredits',
                  label: 'Current Sponsor Credits',
                  disabled: this.submitting,
                })
              }
              {
                this.formHelper.renderTextAreaInput({
                  name: 'note',
                  label: 'Note',
                  disabled: this.submitting,
                })
              }
              <div>
                <Button
                  disabled={this.submitting}
                  type={'submit'}
                  block={true}
                  color={'primary'}
                >Update Sponsor Credits</Button>
              </div>
            </form>
          </div>
          <div className="col-md-6">
            {
              (this.sponsorCredits && this.sponsorCredits.length)
                ? <>
                  <table className="table table-striped">
                    <thead>
                    <tr>
                      <th>Sponsored Member</th>
                      <th>Chapter</th>
                      <th>Join Date</th>
                      <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      this.sponsorCredits.map(sc => <tr key={sc.id}>
                        <td><Link to={route(Routes.system.members.show, { id: sc.sponsoredMember.id })}>{sc.sponsoredMember.fullName}</Link></td>
                        <td>{safeNull(() => sc.sponsoredMember.chapter!.name)}</td>
                        <td>{safeNull(() => sc.sponsoredMember.joinDate!.format('MM/DD/YYYY'))}</td>
                        <td>
                          <Button
                            color="danger"
                            onClick={() => this.removeSponsorCredit(sc.id)}
                            block
                          >Remove</Button>
                        </td>
                      </tr>)
                    }
                    </tbody>
                  </table>
                </>
                : null
            }
          </div>
        </div>
  }
}
