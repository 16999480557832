import { observer } from "mobx-react"
import * as React from "react"
import { SyntheticEvent } from "react"
import { computed, observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import AppStateStore from "../stores/AppStateStore"
import { submitFormRequest } from "../api/ApiHelper"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { toast } from "react-toastify"
import Util, { modelToSnakeCase, safeNull } from "../common/Util"
import { Button } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import { route } from "../routes/routes"
import FormError from "./FormError"
import Event from '../models/Event'
import Config from "../common/Config"
import HelpTooltip from './HelpTooltip'
import moment from 'moment-timezone'
import RecurringEvent from "../models/RecurringEvent"
import { loadRecurringMonthOptions } from "../api/AsyncHelpers"



export type EditRecurringEventFormProps = {
  onCancel: () => void
  onSaved: () => void
  onDelete: () => void
  event: RecurringEvent
}

@observer
export default class EditRecurringEventForm extends React.Component<EditRecurringEventFormProps> {
  static defaultProps = {
    clearAfterSave: true,
  }

  private initialFormState = {

    title: safeNull(() => this.props.event.title) || '',
    visibility: safeNull(() => this.props.event.visibility) || '',
    eventType: safeNull(() => this.props.event.eventType) || '',
    meetingType: safeNull(() => this.props.event.meetingType) || '',
    calendarType: safeNull(() => this.props.event.calendarType) || '',
    recurringStartsOn: safeNull(() => this.props.event.recurringStartsOn) || '',
    hasEndsOn: this.props.event.hasEndsOn,
    hasEndNever: this.props.event.hasEndNever,
    recurringEndsOn: safeNull(() => this.props.event.recurringEndsOn) || '',
    recurringMonth: safeNull(() => this.props.event.recurringMonth) || '',
    repeatCount: safeNull(() => this.props.event.repeatCount) || '',
    daysOfWeek: safeNull(() => this.getDayOfWeek(this.props.event.daysOfWeek)) || [],
    startTime: safeNull(() => this.props.event.startTime) || '',
    endTime: safeNull(() => this.props.event.endTime) || '',
    timezone: safeNull(() => this.props.event.timezone) || '',
    showInLocalTimezone: this.props.event.showInLocalTimezone,
    notes: safeNull(() => this.props.event.notes) || '',
    hasWebConferenceDetails: this.props.event.hasWebConferenceDetails,
    webConferenceDetails: safeNull(() => this.props.event.webConferenceDetails) || '',
    hasLocation: this.props.event.hasLocation,
    locationName: this.props.event.locationName || '',
    address: safeNull(() => this.props.event.address) || '',
    address2: safeNull(() => this.props.event.address2) || '',
    city: safeNull(() => this.props.event.city) || '',
    state: safeNull(() => this.props.event.state) || '',
    zipCode: safeNull(() => this.props.event.zipCode) || '',
    country: safeNull(() => this.props.event.country) || '',

    chapter: safeNull(() => this.props.event.chapter) || '',
    region: safeNull(() => this.props.event.region) || '',
    area: safeNull(() => this.props.event.area) || '',
  }

  @observable private formState = new FormState(this.initialFormState)

  @observable private formErrors = new ErrorBag()
  @observable private submitting = false

  private formHelper = new FormHelper(this.formState, this.formErrors)

  allowedDays = [
    { value: '1', label: 'Monday' },
    { value: '2', label: 'Tuesday' },
    { value: '3', label: 'Wednesday' },
    { value: '4', label: 'Thursday' },
    { value: '5', label: 'Friday' },
    { value: '6', label: 'Saturday' },
    { value: '7', label: 'Sunday' },
  ]

  allowedMeetingTypes = [
    { value: 'in-person', label: 'In-Person' },
    { value: 'virtual', label: 'Virtual' },
    { value: 'hybrid', label: 'Hybrid' }
  ]

  @computed get allowedCalendarTypes() {
    const v: { value: string, text: string }[] = []
    v.push({ value: 'days', text: 'Days' })
    v.push({ value: 'weeks', text: 'Weeks' })
    v.push({ value: 'months', text: 'Months' })
    return v
  }

  getDayOfWeek(value: any) {
    if(value && typeof value==='string'){
      return value.split(',').map(e => parseInt(e, 10))
    }
    return []
  }

  onChangeHandler = (dayOfWeek: number) => {
    let selected: number[] = this.formState.get('daysOfWeek')
    if (selected.includes(dayOfWeek)) {
      selected = selected.filter(x => x !== dayOfWeek)
    } else {
      selected.push(dayOfWeek);
    }
    this.formState.set('daysOfWeek', selected)
  }

  private renderTimezoneOption = (tz: string) => <option value={tz}>{tz} - {moment().tz(tz).format('h:mm A')}</option>


  getSelectedMeetingTypes = (meetingType: string) => {
    return this.allowedMeetingTypes.find(allowedType => allowedType.value === meetingType)
  }

  private onSubmit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()

    this.submitting = true
    AppStateStore.showModalSpinner()
    this.formState.set('meeting_time', this.formState.get('startTime') + '-' + this.formState.get('endTime'))

    if (this.formState.get("hasEndNever") == true) {
      this.formState.set("hasEndsOn", false)
      this.formState.set("recurringEndsOn", "")
    }

    if (this.formState.get("calendarType") == "days") {
      this.formState.set("recurringMonth", "")
      this.formState.set('daysOfWeek', [])
    }

    if (this.formState.get("calendarType") == "weeks") {
      this.formState.set("recurringMonth", "")
    }

    if (this.formState.get("calendarType") == "months") {
      this.formState.set('daysOfWeek', [])
    }

    submitFormRequest(
      ApiClient.getInstance().put(route(ApiRoutes.calendar.updateRecurringEvent,{ id: this.props.event.id }), {
        ...modelToSnakeCase(this.formState.toObject()),
      }),
      this.formState,
      this.formErrors,
      () => {
        toast.success('Recurring Event Updated')
        this.props.onSaved()
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }


  private onDelete = () => {
    if (!this.props.event.id) {
      return
    }
    ApiClient.getInstance()
      .delete(route(ApiRoutes.calendar.deleteRecurringEvent, { id: this.props.event.id }))
      .then(response => {
        this.props.onDelete();
      })
      .catch(error => {
        console.log("Error", error)
        AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response))
      })
  }


  render(): React.ReactNode {
    return <>
      <form onSubmit={this.onSubmit}>


        <div className="form-row">
          <div className="col-12">
            {this.formHelper.renderTextInput({
              name: 'title',
              label: 'Event Title',
            })}
          </div>
        </div>

        <div className="form-row">
          <div className="col-6">
            {this.formHelper.renderSelectInput({
              name: 'meetingType',
              label: 'Meeting Type',
              placeholder: '(choose)',
              options: this.allowedMeetingTypes.map(t => ({ value: t.value, text: t.label }))
            })}
          </div>
          <div className="col-6">
          </div>
        </div>

        <div className="form-row">
          <div className="col-6">
            {this.formHelper.renderDatePickerInput({
              name: 'recurringStartsOn',
              label: 'Recurring Starts On',
            })}
          </div>
        </div>

        {this.formState.get('recurringStartsOn') ? <div>

          <div className="form-row">
            <label style={{ alignSelf: 'center' }}>Repeat Every</label>
            <div className="col-2">
              <input
                type="text"
                className="form-control"
                value={this.formState.get('repeatCount')}
                onChange={ev => {
                  const clean = ev.target.value.replace(/[$,]/g, '')
                  if (clean.match(/^\d*(\.\d?\d?)?$/)) {
                    this.formState.set('repeatCount', clean)
                  }
                }} />
            </div>
            <div className="col-3">
              {this.formHelper.renderSelectInput({
                name: 'calendarType',
                label: '',
                placeholder: '(choose)',
                options: this.allowedCalendarTypes,
              })}
            </div>
          </div>

          <div className="form-row">
            <div className="col-12">
              {this.formState.get('calendarType') == 'days' && <div></div>}
              {this.formState.get('calendarType') == 'weeks' &&
                <div className="weekDays-selector">
                  <input type="checkbox" id="weekday-mon" className="weekday"
                    checked={this.formState.get('daysOfWeek').includes(1)}
                    onChange={(e) => this.onChangeHandler(1)} />
                  <label htmlFor="weekday-mon">M</label>
                  <input type="checkbox" id="weekday-tue" className="weekday"
                    checked={this.formState.get('daysOfWeek').includes(2)}
                    onChange={(e) => this.onChangeHandler(2)} />
                  <label htmlFor="weekday-tue">T</label>
                  <input type="checkbox" id="weekday-wed" className="weekday"
                    checked={this.formState.get('daysOfWeek').includes(3)}
                    onChange={(e) => this.onChangeHandler(3)} />
                  <label htmlFor="weekday-wed">W</label>
                  <input type="checkbox" id="weekday-thu" className="weekday"
                    checked={this.formState.get('daysOfWeek').includes(4)}
                    onChange={(e) => this.onChangeHandler(4)} />
                  <label htmlFor="weekday-thu">T</label>
                  <input type="checkbox" id="weekday-fri" className="weekday"
                    checked={this.formState.get('daysOfWeek').includes(5)}
                    onChange={(e) => this.onChangeHandler(5)} />
                  <label htmlFor="weekday-fri">F</label>
                  <input type="checkbox" id="weekday-sat" className="weekday"
                    checked={this.formState.get('daysOfWeek').includes(6)}
                    onChange={(e) => this.onChangeHandler(6)} />
                  <label htmlFor="weekday-sat">S</label>
                  <input type="checkbox" id="weekday-sun" className="weekday"
                    checked={this.formState.get('daysOfWeek').includes(7)}
                    onChange={(e) => this.onChangeHandler(7)} />
                  <label htmlFor="weekday-sun">S</label>
                </div>
              }
              {this.formState.get('calendarType') == 'months' &&
                <div className="form-row">
                  <div className="col-6">
                    {
                      this.formHelper.renderAsyncSelectInput({
                        label: '',
                        name: 'recurringMonth',
                        defaultValue: this.formState.get('recurringMonth') ? { value:  this.formState.get('recurringMonth'), label:  this.formState.get('recurringMonth') } : undefined,
                        loadOptions: loadRecurringMonthOptions(this.formState.get('recurringStartsOn')),
                      })
                    }
                  </div>
                </div>
              }
            </div>
          </div>

          <div className="form-row">
            <div className="col-2">
              <label style={{ alignSelf: 'center' }}>Ends</label>
            </div>
          </div>

          <div className="form-row" style={{ marginBottom: 'auto' }}>
            <div className="col-0" style={{ alignSelf: 'center' }}>
              <input type="checkbox"
                name="hasEndNever"
                value="Never"
                checked={this.formState.get('hasEndNever')}
                onChange={(e) => {
                  this.formState.set('hasEndsOn', false)
                  this.formState.onChange(e)
                }
                }
              />
            </div>
            <div className="col-2">
              <label>Never</label>
            </div>
          </div>

          <div className="form-row" style={{ alignItems: 'center' }}>
            <div className="col-0">
              <input type="checkbox"
                name="hasEndsOn"
                value="endsOn"
                checked={this.formState.get('hasEndsOn')}
                onChange={(e) => {
                  this.formState.set('hasEndNever', false)
                  this.formState.onChange(e)
                }
                }
              />
            </div>
            <div className="col-2" >
              <label>On</label>
            </div>
            <div className="col-sm-3">
              {this.formHelper.renderDatePickerInput({
                name: 'recurringEndsOn',
                label: '',
                disabled: !this.formState.get('hasEndsOn')
              })}
            </div>
          </div>


          <div className="form-row">
            <div className="col-6">
              {this.formHelper.renderTextInput({
                type: 'time',
                name: 'startTime',
                label: 'Start Time',
              })}
            </div>
            <div className="col-6">
              {this.formHelper.renderTextInput({
                type: 'time',
                name: 'endTime',
                label: 'End Time',
              })}
            </div>
          </div>
          <div className="form-row">
            <div className="col-4" />
            <div className="col-8">
              <div className="form-group">
                <label>Timezone</label>
                <select
                  className="form-control"
                  name="timezone"
                  value={this.formState.get('timezone') || ''}
                  onChange={this.formState.onChange}
                >
                  <option value="">(choose one)</option>
                  <optgroup label="Pacific Time">
                    {this.renderTimezoneOption('America/Vancouver')}
                    {this.renderTimezoneOption('America/Los_Angeles')}
                  </optgroup>
                  <optgroup label="Mountain Time">
                    {this.renderTimezoneOption('America/Edmonton')}
                    {this.renderTimezoneOption('America/Denver')}
                    {this.renderTimezoneOption('America/Phoenix')}
                  </optgroup>
                  <optgroup label="Central Time">
                    {this.renderTimezoneOption('America/Winnipeg')}
                    {this.renderTimezoneOption('America/Chicago')}
                  </optgroup>
                  <optgroup label="Eastern Time">
                    {this.renderTimezoneOption('America/Toronto')}
                    {this.renderTimezoneOption('America/New_York')}
                  </optgroup>
                  <optgroup label="Hawaii Time">
                    {this.renderTimezoneOption('Pacific/Honolulu')}
                  </optgroup>
                  <optgroup label="Alaska Time">
                    {this.renderTimezoneOption('America/Anchorage')}
                  </optgroup>
                  <optgroup label="Newfoundland Time">
                    {this.renderTimezoneOption('America/St_Johns')}
                  </optgroup>
                  <optgroup label="Atlantic Time">
                    {this.renderTimezoneOption('America/Halifax')}
                  </optgroup>
                </select>
                <FormError errors={this.formErrors} fieldName="timezone" />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-4" />
            <div className="col-8">
              <div className="form-check form-checkbox">
                <label>
                  <input type="checkbox"
                    className="form-check-input"
                    name="showInLocalTimezone"
                    checked={this.formState.get('showInLocalTimezone')}
                    onChange={this.formState.onChange}
                  />
                  <span className="label-text">Display times in local timezone</span>
                </label>
              </div>
              <FormError errors={this.formErrors} fieldName="showInLocalTimezone" />
            </div>
          </div>
          </div> : null}


          <div className="form-row">
            <div className="col-12">
              {this.formHelper.renderTextAreaInput({
                name: 'notes',
                label: 'Notes',
                disabled: true
              })}
            </div>
          </div>
          <div className="form-row">
            <div className="col-sm-12">
              <div className="form-check form-checkbox">
                <label>
                  <input type="checkbox"
                    className="form-check-input"
                    name="hasLocation"
                    checked={this.formState.get('hasLocation')}
                    onChange={this.formState.onChange}
                  />
                  <span className="label-text">This event has a location</span>
                </label>
              </div>
              <FormError errors={this.formErrors} fieldName="hasLocation" />
            </div>
          </div>
          {this.formState.get('hasLocation') ? <>
            <div className="form-row">
              <div className="col-12">
                {this.formHelper.renderTextInput({
                  name: 'locationName',
                  label: 'Location Name',
                })}
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-8">
                {this.formHelper.renderTextInput({
                  name: 'address',
                  type: 'text',
                  label: 'Street',
                })}
              </div>
              <div className="col-sm-4">
                {this.formHelper.renderTextInput({
                  name: 'address2',
                  type: 'text',
                  label: 'Suite / Room / Apt #',
                })}
              </div>
            </div>

            <div className="form-row">
              <div className="col-sm-3">
                {this.formHelper.renderTextInput({
                  name: 'city',
                  type: 'text',
                  label: 'City',
                })}
              </div>
              <div className="col-sm-3">
                {this.formHelper.renderTextInput({
                  name: 'state',
                  type: 'text',
                  label: 'State',
                })}
              </div>
              <div className="col-sm-3">
                {this.formHelper.renderTextInput({
                  name: 'zipCode',
                  type: 'text',
                  label: 'Zip Code',
                })}
              </div>
              <div className="col-sm-3">
                {this.formHelper.renderSelectInput({
                  name: 'country',
                  options: Config.COUNTRY_OPTIONS.map(c => ({ value: c, text: c })),
                  label: 'Country',
                })}
              </div>
            </div>
          </> : null}

          <div className="form-row">
            <div className="col-sm-12">
              <div className="form-check form-checkbox">
                <label>
                  <input type="checkbox"
                    className="form-check-input"
                    name="hasWebConferenceDetails"
                    checked={this.formState.get('hasWebConferenceDetails')}
                    onChange={this.formState.onChange}
                  />
                  <span className="label-text">This event has web conference details</span>
                </label>
              </div>
              <FormError errors={this.formErrors} fieldName="hasWebConferenceDetails" />
            </div>
          </div>
          {
            this.formState.get('hasWebConferenceDetails')
              ? <div className="form-row">
                <div className="col-12">
                  {this.formHelper.renderTextAreaInput({
                    name: 'webConferenceDetails',
                    label: 'Web Conference Details',
                    labelAccessory: <HelpTooltip
                      content="Paste the info needed to join your web conference in this field. Make sure to include any web links, conference phone numbers and pin codes provided for your web conference."
                      placement="right"
                    />,
                  })}
                </div>
              </div>
              : null
          }

          <div className="form-buttons">
            <Button color="danger" className="mr-2" onClick={() => this.onDelete()}><i className="fa fa-trash" /> Delete Event</Button>
            <Button color="secondary" onClick={() => this.props.onCancel()}>Cancel</Button>
            <ButtonLoader type="submit" color="primary" loading={this.submitting}>Save Changes</ButtonLoader>
          </div>
        </form>
    </>
  }
}
