import { observer } from 'mobx-react'
import React from 'react'
import { observable, toJS } from 'mobx'
import Util from '../../common/Util'
import ApiClient from '../../api/ApiClient'
import LoadingPanel from '../LoadingPanel'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { route } from '../../routes/routes'
import { Routes } from '../../routes/AppRoutes'
import ListHeaderButtons from './ListHeaderButtons'
import moment from 'moment-timezone'
import TableView, { TableViewColumn } from '../table-view/TableView'
import { ArrayTableViewAdapter } from '../table-view/ArrayTableViewAdapter'

type MemberData = {
  id: number
  fullName: string
  emailAddress: string | null
  phoneNumber: string | null
  joinDate: string | null
  chapter: {
    id: number
    name: string
  } | null
}

type MemberListData = {
  members: MemberData[]
}

type Props = {
  url: string
  title: string
  exportFilename?: string
  showJoinDate?: boolean
}

@observer
export default class MemberList extends React.Component<Props> {
  @observable
  private members?: MemberListData
  @observable
  private error?: string
  @observable
  private loading = false

  componentDidMount () {
    this.loadData().then()
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (!_.isEqual(toJS(prevProps.url), toJS(this.props.url))) {
      this.loadData().then()
    }
  }

  private loadData = async () => {
    try {
      this.error = undefined
      this.loading = true

      const response = await ApiClient.getInstance().get(this.props.url)

      this.members = response.data

      this.loading = false
    } catch (err) {
      this.error = Util.extractErrorMessage(err.response)
    }
  }

  get columns (): TableViewColumn[] {
    const columns: TableViewColumn[] = [
      {
        accessor: 'fullName',
        title: 'Name',
        sortable: true,
        renderItem: (name: string, member: MemberData) => <Link to={route(Routes.system.members.show, { id: member.id })}>{member.fullName}</Link>,
      },
      {
        accessor: 'emailAddress',
        sortable: true,
        title: 'Email',
      },
      {
        accessor: 'phoneNumber',
        sortable: true,
        title: 'Phone',
      },
      {
        accessor: 'chapter',
        title: 'Chapter',
        sortable: true,
        renderItem: (chapter: {id: number, name: string} | null) => chapter ? <Link to={route(Routes.system.chapters.show, { id: chapter.id })}>{chapter.name}</Link> : '',
      },
    ]

    if (this.props.showJoinDate) {
      columns.push({
        accessor: 'joinDate',
        title: 'Join Date',
        sortable: true,
        renderItem: (joinDate: string | null) => joinDate ? moment(joinDate).format('MM/DD/YYYY') : '',
      })
    }

    return columns
  }

  render () {
    return <div>
      <h2>{this.props.title}</h2>
      <div>
        <LoadingPanel loading={this.loading} loaded={!!this.members} error={this.error}>
          {
            this.members
              ? <TableView
                hideItemCount={true}
                hideRowNumbers={true}
                adapter={new ArrayTableViewAdapter(this.members.members, {
                  sort: {
                    chapter: (member: MemberData) => member.chapter ? member.chapter.name : '',
                    joinDate: (member: MemberData) => member.joinDate ? moment(member.joinDate).unix() : '',
                  }
                })}
                columns={this.columns}
              />
              : null
          }
        </LoadingPanel>
      </div>
    </div>
  }
}
