import { observer } from 'mobx-react'
import React from 'react'
import { observable, toJS } from 'mobx'
import Util, { SHORT_DATE_FORMAT } from '../../common/Util'
import ApiClient from '../../api/ApiClient'
import LoadingPanel from '../LoadingPanel'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone/moment-timezone'
import { Link } from 'react-router-dom'
import { route } from '../../routes/routes'
import { Routes } from '../../routes/AppRoutes'
import TableView, { TableViewColumn } from '../table-view/TableView'
import { ArrayTableViewAdapter } from '../table-view/ArrayTableViewAdapter'

type RenewalData = {
  id: number
  status: string
  renewalDate: Moment
  terminationDate: Moment
  member: {
    id: number
    fullName: string
    emailAddress: string | null
    phoneNumber: string | null
  }
  chapter: {
    id: number
    name: string
  }
}

type RenewalsListData = {
  renewals: RenewalData[]
}

type Props = {
  url: string
  title: string
}

@observer
export default class RenewalsList extends React.Component<Props> {
  @observable
  private renewals?: RenewalsListData
  @observable
  private error?: string
  @observable
  private loading = false

  componentDidMount () {
    this.loadData().then()
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (!_.isEqual(toJS(prevProps.url), toJS(this.props.url))) {
      this.loadData().then()
    }
  }

  private loadData = async () => {
    try {
      this.error = undefined
      this.loading = true

      const response = await ApiClient.getInstance().get(this.props.url)

      this.renewals = response.data

      this.loading = false
    } catch (err) {
      this.error = Util.extractErrorMessage(err.response)
    }
  }

  get columns (): TableViewColumn[] {
    return [
      {
        accessor: 'member',
        title: 'Name',
        sortable: true,
        renderItem: (name: string, renewal: RenewalData) => <Link to={route(Routes.system.members.show, { id: renewal.member.id })}>{renewal.member.fullName}</Link>,
      },
      {
        accessor: 'member.emailAddress',
        sortable: true,
        title: 'Email',
      },
      {
        accessor: 'member.phoneNumber',
        sortable: true,
        title: 'Phone',
      },
      {
        accessor: 'status',
        sortable: true,
        title: 'Status',
      },
      {
        accessor: 'renewalData',
        sortable: true,
        title: 'Renewal Date',
        renderItem: renewalDate => moment(renewalDate).format(SHORT_DATE_FORMAT),
      },
      {
        accessor: 'terminationDate',
        sortable: true,
        title: 'Termination Date',
        renderItem: terminationDate => moment(terminationDate).format(SHORT_DATE_FORMAT),
      },
      {
        accessor: 'chapter',
        title: 'Chapter',
        sortable: true,
        renderItem: (chapter: {id: number, name: string} | null) => chapter ? <Link to={route(Routes.system.chapters.show, { id: chapter.id })}>{chapter.name}</Link> : '',
      },
    ]
  }

  render () {
    return <div>
      <h2>{this.props.title}</h2>
      <div>
        <LoadingPanel loading={this.loading} loaded={!!this.renewals} error={this.error}>
          {
            this.renewals
              ? <TableView
                hideItemCount={true}
                hideRowNumbers={true}
                adapter={new ArrayTableViewAdapter(this.renewals.renewals, {
                  sort: {
                    member: (renewal: RenewalData) => renewal.member.fullName,
                    renewalDate: (renewal: RenewalData) => moment(renewal.renewalDate).unix(),
                    terminationDate: (renewal: RenewalData) => moment(renewal.terminationDate).unix(),
                    chapter: (renewal: RenewalData) => renewal.chapter.name,
                  }
                })}
                columns={this.columns}
              />
              : null
          }
        </LoadingPanel>
      </div>
    </div>
  }
}
