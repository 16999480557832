import BaseView from "../BaseView"
import { observer } from "mobx-react"
import * as React from "react"
import AdminEmailTemplates from "../../components/system/AdminEmailTemplates"

@observer
export default class AdminEmailTemplatesView extends BaseView {

  renderContentHeader () {
    return <>
      <h1>Email Templates</h1>
    </>
  }

  render () {
    return <div >
        <AdminEmailTemplates/>
    </div>
  }
}
