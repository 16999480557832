import * as React from "react"
import ApiClient, { QueryWhereClause } from "../api/ApiClient"
import { createLazyResource, formatNumber, safeNull } from "../common/Util"
import Region from "../models/Region"
import { observer } from "mobx-react"
import { observable } from "mobx"
import { Button } from "reactstrap"
import BaseView from "./BaseView"
import { LinkContainer } from "react-router-bootstrap"
import { Routes } from "../routes/AppRoutes"
import { route } from "../routes/routes"
import { Link } from "react-router-dom"
import classNames from 'classnames'
import RequirePermission, { Permission } from "../components/RequirePermission"
import { ApiTableViewAdapter } from "../components/table-view/ApiTableViewAdapter"
import RegionsCsvTransformer from "../models/renderers/RegionsCsvTransformer"
import { UserRole } from "../models/AuthUser"
import RequireRole from "../components/RequireRole"
import { ManagedChooseableColumn } from "../components/table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "../components/table-view/ManagedTableView";

@observer
export default class RegionsView extends BaseView {
  @observable private numberOfActiveRegions = createLazyResource<number>(() => {
    return ApiClient.query(`regions { * }`, { returnTotal: true, limit: 0, where: [{ _scope: 'active' }] })
  }, response => response.data._meta.total);

  @observable private availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'name',
      column: {
        title: 'Name',
        accessor: 'name',
        sortable: true,
        renderItem: (value, item) => {
          return (<div className={classNames({ 'text-muted': item.status === 'Inactive' })}>
            {item.name}{item.status === 'Inactive' && ' (Inactive)'}
          </div>)
        }
      },
      default: true
    },
    {
      id: 'numberOfAreas',
      column: {
        title: 'Areas',
        headerClassName: 'justify-content-center',
        accessor: 'numberOfAreas',
        renderItem: (value, item) => {
          return (
            <div className="text-center">
              <Link type="link" to={{ pathname: route(Routes.system.areas.index), state: { region: { id: item.id, name: item.name } } }}>{item.numberOfAreas !== undefined ? formatNumber(item.numberOfAreas) : 'N/A'}</Link>
            </div>
          )
        },
      },
      default: true
    },
    {
      id: 'numberOfChapters',
      column: {
        title: 'Chapters',
        headerClassName: 'justify-content-center',
        accessor: 'numberOfChapters',
        renderItem: (value, item) => {
          return (
            <div className="text-center">
              <Link type="link" to={{ pathname: route(Routes.system.chapters.index), state: { region: { id: item.id, name: item.name } } }}>{item.numberOfChapters !== undefined ? formatNumber(item.numberOfChapters) : 'N/A'}</Link>
            </div>
          )
        },
      },
      default: true
    },
    {
      id: 'numberOfMembers',
      column: {
        title: 'Members',
        headerClassName: 'justify-content-center',
        accessor: 'numberOfMembers',
        renderItem: (value, item) => {
          return (
            <div className="text-center">
              <Link type="link" to={{ pathname: route(Routes.system.members.index), state: { region: { id: item.id, name: item.name } } }}>{item.numberOfMembers !== undefined ? formatNumber(item.numberOfMembers) : 'N/A'}</Link>
            </div>
          )
        },
      },
      default: true
    },
    {
      id: 'actions',
      column: {
        title: 'Actions',
        headerClassName: 'justify-content-center',
        renderItem: (value, item) => {
          return (
            <div className="table-actions">
              <Link type="link" to={{ pathname: route(Routes.system.regions.edit, { id: item.id }), state: { id: item.id } }}><i className="fa fa-edit"/></Link>
            </div>
          )
        }
      },
      fixed: true,
      hidden: true
    }
  ];

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>Regions</h1>
        <ul className="content-header-actions">
          <RequirePermission permission={Permission.CreateRegion}>{() =>
            <li>
              <LinkContainer to={route(Routes.system.regions.create)}>
                <Button color="primary" onClick={() => {
                }}><i className="fa fa-plus-circle"/> Add New Region</Button>
              </LinkContainer>
            </li>
          }</RequirePermission>
        </ul>
      </>
    )
  }

  private tableViewRef = React.createRef<ManagedTableView>()

  private regionsAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      if (!safeNull(() => this.tableViewRef.current!.tableViewFilters!.showInactive)) {
        where.push({ _scope: 'active' })
      }

      return {
        query: `
      regions {
        *
        numberOfAreas
        numberOfMembers
        numberOfChapters
      }
    `,
        where: where,
      }
    },
    'regions',
    Region
  )

  renderContentBody (): React.ReactNode {
    return this.renderTable()
  }

  private renderTableViewHeader = () => {
    return <RequireRole role={UserRole.Admin}>
      {() => <>
        <div className="list-view-info-icon">
          <i className="flaticon-placeholder"/>
        </div>
        <div className="list-view-info-text">
          <span>{this.numberOfActiveRegions.loading ? <i className="fa fa-spinner fa-spin"/> : formatNumber(this.numberOfActiveRegions.current!)}</span> Active Regions
        </div>
      </>}
    </RequireRole>
  }

  private renderTable = () => {
    return <ManagedTableView
      stateKey="RegionsView"
      searchLabel="Find A Region"
      hideItemCount={true}
      ref={this.tableViewRef}
      adapter={this.regionsAdapter}
      availableColumns={this.availableColumns}
      exportTransformer={RegionsCsvTransformer}
      header={this.renderTableViewHeader()}
    />
  }
}