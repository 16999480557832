import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import AppStateStore from "../stores/AppStateStore"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { toast } from "react-toastify"
import { submitFormRequest } from "../api/ApiHelper"
import { route } from "../routes/routes"
import Guest from "../models/Guest"
import { modelToSnakeCase } from "../common/Util"
import { loadChapterOptions, loadMemberOptions } from "../api/AsyncHelpers"
import _ from 'lodash';
import EventGuest from "../models/EventGuest"
import Chapter from "../models/Chapter"
import Member from "../models/Member"
import HelpTooltip from './HelpTooltip'

type SendApplicationModalProps = {
  isOpen: boolean
  toggle: () => void
} & SendApplicationFormProps

@observer
export class SendApplicationModal extends React.Component<SendApplicationModalProps> {
  render (): React.ReactNode {
    const { isOpen, toggle, ...rest } = this.props

    return <Modal isOpen={isOpen} onClosed={this.props.onCancel} size="lg">
      <ModalHeader toggle={toggle}>
        Send a Membership Application{this.props.isReminder && ' Reminder'}
      </ModalHeader>
      <ModalBody>
        <SendApplicationForm
          {...rest}
        />
      </ModalBody>
    </Modal>
  }
}

type SendApplicationFormProps = {
  onCancel: () => void
  onSaved: () => void
  guest: Guest
  isReminder: boolean
}

@observer
export class SendApplicationForm extends React.Component<SendApplicationFormProps> {
  private initialFormState = {
    sponsorId: undefined,
    targetChapterId: undefined,
    message: '',
    sendCopy: false,
  }

  @observable private formState = new FormState(this.initialFormState)

  @observable private formErrors = new ErrorBag()
  @observable private submitting = false

  @observable private loaded = false
  @observable private defaultChapter?: Chapter
  @observable private defaultSponsor?: Member

  private formHelper = new FormHelper(this.formState, this.formErrors)

  componentDidMount (): void {
    ApiClient.query(`
    guest {
      *
    
      guestEvents {
        *
        
        targetChapter {
          *
        }
      }
      
      createdByMember {
        *
      }
    }
    `, {
      where: [{ id: this.props.guest.id }],
    }).then(response => {
      const guest = new Guest().init(response.data.guest)

      if (guest.guestEvents.length) {
        // find most recent event for default target chapter
        const lastEvent = _.last<EventGuest>(_.orderBy(guest.guestEvents, 'createdAt'))
        if (lastEvent) {
          this.formState.set('targetChapterId', lastEvent.targetChapterId)
          this.defaultChapter = lastEvent.targetChapter
        }
      }

      if (guest.createdByMember) {
        this.defaultSponsor = guest.createdByMember
        this.formState.set('sponsorId', guest.createdByMemberId)
      }
    }).catch(e => {
    })
      .then(() => this.loaded = true)
  }

  private onSubmit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()

    this.submitting = true
    AppStateStore.showModalSpinner()

    submitFormRequest(
      ApiClient.getInstance()
        .post(route(ApiRoutes.guests.sendApplication, { id: this.props.guest.id }), modelToSnakeCase(this.formState.toObject())),
      this.formState,
      this.formErrors,
      () => {
        toast.success('Application Sent')
        this.props.onSaved()
      }
    )
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  render (): React.ReactNode {
    const { guest } = this.props

    return <>
      <form onSubmit={this.onSubmit}>
        <div className="form-row">
          <div className="col-sm-12">
            <div className="form-group">
              <label>To</label><br/>
              {guest.fullName}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-6">
            <label>Category</label><br/>
            {guest.category ? guest.category.name : '---'}
          </div>
          <div className="col-sm-6">
            <label>Email Address</label><br/>
            {guest.email}
          </div>
        </div>
        <br/>
        {
          this.loaded
            ? <div className="form-row">
              <div className="col-sm-6">
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1 }}>
                    {this.formHelper.renderAsyncSelectInput({
                      label: 'Sponsor',
                      loadOptions: loadMemberOptions(),
                      name: 'sponsorId',
                      defaultValue: this.defaultSponsor ? { value: String(this.defaultSponsor.id), label: this.defaultSponsor.getPreferredFullName() } : undefined,
                    })}
                  </div>
                  <div style={{ alignSelf: 'flex-end', marginBottom: 20 }}>
                    <HelpTooltip
                      content="This is who sponsored this prospect"/>
                  </div>
                </div>

              </div>
              <div className="col-sm-6">
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1 }}>
                    {this.formHelper.renderAsyncSelectInput({
                      label: 'Chapter',
                      loadOptions: loadChapterOptions,
                      name: 'targetChapterId',
                      defaultValue: this.defaultChapter ? { value: String(this.defaultChapter.id), label: this.defaultChapter.name } : undefined,
                    })}
                  </div>
                  <div style={{ alignSelf: 'flex-end', marginBottom: 20 }}>
                    <HelpTooltip
                      content="What chapter is this prospect joining?"/>
                  </div>
                </div>
              </div>
            </div>
            : null
        }
        <div className="form-row">
          <div className="col-sm-12">
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                {this.formHelper.renderTextAreaInput({
                  name: 'message',
                  label: 'Personal Note / Message',
                })}
              </div>
              <div style={{ alignSelf: 'flex-end', marginBottom: 20 }}>
                <HelpTooltip
                  content="This message will show up on the email that goes to the prospect"/>
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-sm-12">
            <div className="form-group">
              <div className="form-check form-checkbox">
                <label>
                  <input type="checkbox"
                         className="form-check-input"
                         name="sendCopy"
                         onChange={this.formState.onChange}
                  />
                  <span className="label-text">Send a copy to my email</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="form-buttons">
          <Button color="secondary" onClick={() => this.props.onCancel()}>Cancel</Button>
          <ButtonLoader type="submit" color="primary" loading={this.submitting}>Send {this.props.isReminder ? 'Reminder' : 'Application'}</ButtonLoader>
        </div>
      </form>
    </>
  }
}
