import BaseModel from "./BaseModel"
import { Moment } from 'moment-timezone/moment-timezone'
import Chapter from './Chapter'
import Member from './Member'
import Position from './Position'

export default class BoardDocumentSignature extends BaseModel {
  // 'id',
  //             'created_at',
  //             'updated_at',
  //             'slug',
  //             'chapter_id',
  //             'position_id',
  //             'member_id',
  //             'signed_at',
  //             'signature_image_filename',
  //             'signature_image_url',
  //             'signed_pdf_filename',
  //             'signed_pdf_url',
  //             'sign_url',

  id: number
  createdAt: Moment
  updatedAt: Moment
  slug: string
  chapterId: number
  positionId: number | null
  memberId: number | null
  signedAt: Moment | null
  signatureImageFilename: string | null
  signatureImageUrl: string | null
  signedPdfFilename: string | null
  signedPdfUrl: string | null
  signUrl: string

  chapter: Chapter
  position: Position
  member: Member

  setChapter = (r: {}) => {
    return r ? new Chapter(r) : null
  }

  setPosition = (r: {}) => {
    return r ? new Position().init(r) : null
  }

  setMember = (r: {}) => {
    return r ? new Member().init(r) : null
  }

  getCasts () {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      signedAt: 'datetime',

      chapterId: 'number',
      positionId: 'number',
      memberId: 'number',
    }
  }
}
