import * as React from "react"
import { observer } from "mobx-react"
import { action, computed, observable, toJS } from "mobx"
import Util, { createLazyResource, modelToCamelCase, publicPath, safeNull, transformIf } from "../../common/Util"
import Chapter from "../../models/Chapter"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import ProgramPosition from "../../models/ProgramPosition"
import LazyResourcePanel from "../LazyResourcePanel"
import Member from "../../models/Member"
import * as _ from "lodash"
import MemberProfileImage from "../MemberProfileImage"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import AppStateStore from "../../stores/AppStateStore"
import classNames from 'classnames'
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { toast } from "react-toastify"
import ProgramScheduleRecord from "../../models/ProgramScheduleRecord"
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import Event from '../../models/Event'

@observer
export default class ManageProgram extends React.Component<{
  chapter: Chapter
}> {
  @observable private members = createLazyResource<Member[]>(() => {
      return ApiClient.query(
        `
members {
  *
}`,
        {
          where: [
            { chapterId: this.props.chapter.id },
            { _scope: 'active' },
          ]
        }
      )
    },
    response => response.data.members.map((m: any) => new Member().init(m))
  )

  @computed
  private get assignedMembers () {
    const current = this.members.current
    return current ? _.sortBy(current.filter(m => m.programOrder !== null), ['programOrder', 'fullName']) : []
  }

  @computed
  private get unassignedMembers () {
    const current = this.members.current

    return current
      ? _.sortBy(current.filter(m => m.programOrder === null), [(m) => m.lastProgramScheduledDate ? m.lastProgramScheduledDate.toISOString() : '', 'fullName']).filter(m => this.searchText.trim().length === 0 || m.fullName.toLocaleLowerCase().indexOf(this.searchText.toLowerCase()) > -1)
      : []
  }

  @observable private searchText = ''

  @observable private programPositions = createLazyResource<ProgramPosition[]>(() => {
      return ApiClient.query(
        `
programPositions {
  *
}
      `
      )
    },
    response => {
      return _.orderBy(response.data.programPositions.map((p: {}) => new ProgramPosition().init(p)), 'displayOrder')
    }
  )

  @observable private programSchedule = createLazyResource<ProgramScheduleRecord[]>(() => {
      return ApiClient.query(
        `
chapter {
  *

  programSchedule
}
      `,
        {
          where: [{ id: this.props.chapter.id }],
        }
      )
    },
    response => {
      return response.data.chapter.programSchedule.map((ps: any) => new ProgramScheduleRecord().init(modelToCamelCase(ps)))
    }
  )

  @observable private chapter = createLazyResource<Chapter>(() => {
      return ApiClient.query(
        `
chapter {
  *
}
      `,
        {
          where: [{ id: this.props.chapter.id }],
        }
      )
    },
    response => {
      return new Chapter().init(response.data.chapter)
    }
  )

  @observable private tempAssigned?: Member[] = undefined
  @observable private tempUnassigned?: Member[] = undefined
  @observable private _currentFirstProgramSlot?: string = undefined
  @observable private showManageMeetingsModal = false

  @computed
  private get currentFirstProgramSlot (): string {
    if (this._currentFirstProgramSlot) {
      return this._currentFirstProgramSlot
    } else {
      return String(this.chapter.current ? this.chapter.current.currentProgramSlot : '' || '')
    }
  }

  @computed get currentProgramSchedule () {
    return this.programSchedule.current ? this.programSchedule.current : []
  }

  componentDidMount (): void {
    this.programPositions.refresh().then(() => {
    })
  }

  private addMember = (memberId: string, sourceIndex: number, destinationIndex?: number) => {
    if (destinationIndex === undefined) {
      destinationIndex = this.assignedMembers.length
    }

    // add member to program
    AppStateStore.showModalSpinner()
    ApiClient.getInstance()
      .post(route(ApiRoutes.chapters.program.addMember, { id: this.props.chapter.id, memberId: memberId }), { order: destinationIndex })
      .then(() => {
        this.members.invalidate().then(() => {
        })
        this.programSchedule.invalidate().then(() => {
        });
        this.searchText = ''
      })
      .catch(error => {
          Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
            AppStateStore.showAlertModal('Error', message, m => {
              m.hide()
            })
            return true
          })
        }
      )
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.tempAssigned = undefined
        this.tempUnassigned = undefined
      })
  }

  private setCurrentFirstProgramSlot = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    this._currentFirstProgramSlot = ev.target.value

    AppStateStore.showModalSpinner()
    ApiClient.getInstance()
      .post(route(ApiRoutes.chapters.setCurrentProgramSlot, { id: this.props.chapter.id }), { program_slot: ev.target.value })
      .then(() => {
        this.chapter.invalidate().then(() => {
          this._currentFirstProgramSlot = undefined
        })
        this.programSchedule.invalidate().then(() => {
        })
      })
      .catch(error => {
          this._currentFirstProgramSlot = undefined

          Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
            AppStateStore.showAlertModal('Error', message, m => {
              m.hide()
            })
            return true
          })
        }
      )
      .then(() => {
        AppStateStore.dismissModalSpinner()
      })
  }

  private removeMember = (memberId: string) => {
    // remove member from program
    AppStateStore.showModalSpinner()
    ApiClient.getInstance()
      .delete(route(ApiRoutes.chapters.program.removeMember, { id: this.props.chapter.id, memberId: memberId }))
      .then(() => {
        this.members.invalidate().then(() => {
        })
        this.programSchedule.invalidate().then(() => {
        });
        this.searchText = ''
      })
      .catch(error =>
        Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      )
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.tempAssigned = undefined
        this.tempUnassigned = undefined
      })
  }
  @action
  private handleDragEnd = (result: DropResult) => {
    const source = result.source.droppableId
    const destination = safeNull(() => result.destination!.droppableId)

    if (source === 'unassigned' && destination === 'assigned') {
      this.tempUnassigned = toJS(this.unassignedMembers)
      const member = this.tempUnassigned.splice(result.source.index, 1)

      this.tempAssigned = toJS(this.assignedMembers)
      this.tempAssigned.splice(result.destination!.index, 0, member[0])

      this.addMember(result.draggableId, result.source.index, result.destination!.index)
    } else if (source === 'assigned' && destination === 'assigned') {
      this.tempAssigned = toJS(this.assignedMembers)
      const member = this.tempAssigned.splice(result.source.index, 1)
      this.tempAssigned.splice(result.destination!.index, 0, member[0])

      // change program order
      AppStateStore.showModalSpinner()
      ApiClient.getInstance()
        .put(route(ApiRoutes.chapters.program.setOrder, { id: this.props.chapter.id, memberId: result.draggableId }), { order: result.destination!.index })
        .then(() => {
          this.members.invalidate().then(() => {
          })
          this.programSchedule.invalidate().then(() => {
          });
          this.searchText = ''
        })
        .catch(error => {
            Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
              AppStateStore.showAlertModal('Error', message, m => {
                m.hide()
              })
              return true
            })
          }
        )
        .then(() => {
          AppStateStore.dismissModalSpinner()
          this.tempAssigned = undefined
          this.tempUnassigned = undefined
        })
    } else if (source === 'assigned' && destination === 'unassigned') {
      this.tempAssigned = toJS(this.assignedMembers)
      const member = this.tempAssigned.splice(result.source.index, 1)

      this.tempUnassigned = toJS(this.unassignedMembers)
      this.tempUnassigned.splice(result.destination!.index, 0, member[0])

      this.removeMember(result.draggableId)
    }
  }

  private sendProgram = () => {
    AppStateStore.showConfirmationModal('Send Program Schedule', 'Are you sure you want to send the program to all scheduled members?', (result, modal) => {
      modal.hide()

      if (result) {
        AppStateStore.showModalSpinner()

        ApiClient.getInstance().post(route(ApiRoutes.chapters.program.send, { id: this.props.chapter.id }))
          .then(response => {
            toast.success('Program sent')
          })
          .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response), m => {
            m.hide()
          }))
          .then(() => AppStateStore.dismissModalSpinner())
      }
    })
  }

  render (): React.ReactNode {
    const assigned = this.tempAssigned || this.assignedMembers
    const unassigned = this.tempUnassigned || this.unassignedMembers
    const programPositions = this.programPositions.current || []
    const currentProgramSchedule = this.currentProgramSchedule
    const chapter = this.chapter.current

    return <>
      <LazyResourcePanel resource={this.members}>
        {() => <>
          <Alert color="info">
            Click+Drag the members names to the right or click "add to schedule" to add
            members to the program rotation. We suggest setting the first position at the top right
            as "Speaker". For more information on Program Rotation, please read <i>5. Chapter Procedures - Understanding the LeTip Four Week Program</i> in the <Link to={route(Routes.network.documentLibrary)} target="_blank">Document Library</Link>. As
            members start being added, dates of meetings will populate automatically. Chapter
            sizes from 1-19 are defaulted to 1 role per member, size 20-49 are defaulted to 2 per
            member, over 50 are defaulted to 3 per member. Please contact corporate to adjust roles.
          </Alert>
          <div className="row">
            <div className="col-4">
              {(unassigned.length || this.searchText)
                ? <div className="member-card-search-search-input">
                  <i className="fa fa-search"/>
                  <input
                    className="form-control"
                    placeholder="Search members"
                    type="text"
                    name="search"
                    value={this.searchText}
                    onChange={ev => this.searchText = ev.target.value}
                  />
                </div>
                : null}
            </div>
            <div className="col-4">
              <div className="d-flex align-items-center" style={{ minHeight: 35 }}>
                <h5 style={{ margin: 0 }}>Program Schedule</h5>
                {
                  this.assignedMembers.length
                    ? <Button
                      style={{ marginLeft: 10 }}
                      color="primary"
                      onClick={() => {
                        this.sendProgram()
                      }}>
                      <i className="fa fa-send"/> Send
                    </Button>
                    : null
                }
              </div>
            </div>
            <div className="col-4">
              <div className="d-flex align-items-center">
                <div style={{ flex: 1 }}>
                  <Button
                    color="primary"
                    onClick={() => this.showManageMeetingsModal = true}
                  >Manage Meetings</Button>
                </div>
                <div>
                  <LazyResourcePanel resource={this.programPositions}>
                    {programPositions => <div className="d-flex align-items-center">
                      <div className="mr-2 flex-fill text-right">First Position</div>
                      <div><select
                        className="form-control"
                        value={this.currentFirstProgramSlot}
                        onChange={this.setCurrentFirstProgramSlot}
                      >
                        <option value="">(choose one)</option>
                        {programPositions.map((pp, idx) => <option key={pp.id} value={String(idx)}>{pp.title}</option>)}
                      </select>
                      </div>
                    </div>}
                  </LazyResourcePanel>
                </div>
              </div>
            </div>
          </div>
          <DragDropContext onDragEnd={this.handleDragEnd}>
            <div className="row">
              <Droppable droppableId={'unassigned'}>
                {(provided, snapshot) =>
                  <div className={classNames("col-4", "program-unassigned-list", { 'is-dragging-over': snapshot.isDraggingOver })}
                       ref={provided.innerRef}
                  >
                    {(unassigned.length || this.searchText !== '')
                      ? unassigned.map((member, idx) =>
                        <Draggable draggableId={member.id.toString()} index={idx} key={member.id}>
                          {(provided, snapshot) =>
                            <div ref={provided.innerRef}
                                 {...provided.draggableProps}
                                 {...provided.dragHandleProps}
                            >
                              <div className={classNames('program-unassigned-draggable', { 'is-dragging': snapshot.isDragging })}>
                                <div>
                                  <MemberProfileImage
                                    size={45}
                                    profileImageUrl={member.profileImageUrl}
                                    memberName={member.fullName}
                                  />
                                </div>
                                <div className="flex-fill ml-2 mr-2">
                                  <a href={route(Routes.network.member, { memberId: member.id })}>{member.fullName}</a>
                                  {
                                    member.ntsTrainingCompletedAt
                                      ? <div className="nts-badge"><i className="fa fa-check"/> NTS</div>
                                      : null
                                  }
                                  <br/>
                                  <span className="text-muted" style={{ fontSize: 12 }}>Last scheduled: {member.lastProgramScheduledDate ? member.lastProgramScheduledDate.tz(chapter ? chapter.timezone : 'UTC').format('MM/DD/YYYY') : 'Never'}</span>
                                </div>
                                <a href=""
                                   className="program-add-link"
                                   onClick={ev => {
                                     ev.preventDefault()
                                     this.addMember(member.id.toString(), idx)
                                   }}
                                ><i className="fa fa-plus-circle"/> Add to schedule</a>
                              </div>
                            </div>}
                        </Draggable>)
                      : <p className="mt-4 text-center">You have scheduled all of your members</p>}
                    {provided.placeholder}
                  </div>}
              </Droppable>
              <Droppable droppableId={'assigned'}>
                {(provided, snapshot) =>
                  <div className={classNames("col-8", "program-assigned-list", { 'is-dragging-over': snapshot.isDraggingOver }, { 'empty': assigned.length === 0 })}
                       ref={provided.innerRef}>
                    {assigned.length
                      ? <div className="scrollable-table">
                        <table className="table table-striped table-condensed program-table">
                          <thead>
                          <tr>
                            <th/>
                            {programPositions.map((p, idx) => <th key={idx}>{p.title}</th>)}
                          </tr>
                          </thead>
                          <tbody>
                          {assigned.map((member, idx) =>
                            <tr key={member.id}>
                              <td>
                                <Draggable draggableId={member.id.toString()} index={idx}>
                                  {(provided, snapshot) => <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className={classNames('program-assigned-draggable', { 'is-dragging': snapshot.isDragging })}>
                                      <div>
                                        <i className="fa fa-reorder mr-2"/>
                                        <MemberProfileImage
                                          size={45}
                                          profileImageUrl={member.profileImageUrl}
                                          memberName={member.fullName}
                                        />
                                      </div>
                                      <div className="flex-fill ml-2 mr-2 text-left">
                                        <a href={route(Routes.network.member, { memberId: member.id })}>{member.fullName}</a>
                                        {
                                          member.ntsTrainingCompletedAt
                                            ? <div className="nts-badge"><i className="fa fa-check"/> NTS</div>
                                            : null
                                        }
                                      </div>
                                      <a href=""
                                         onClick={ev => {
                                           ev.preventDefault()
                                           this.removeMember(member.id.toString())
                                         }}
                                         className="text-danger program-remove-link"
                                      ><i className="fa fa-minus-circle"/> Remove from schedule</a>
                                    </div>
                                  </div>}
                                </Draggable>
                              </td>
                              {
                                programPositions.map(pp => <td key={pp.id}>
                                  {
                                    transformIf(_.find(currentProgramSchedule, ps => ps.position.id == pp.id && ps.member.id == member.id), (ps: ProgramScheduleRecord) => ps.event.startsAt.tz(ps.event.timezone).format('MMM D')) || '---'
                                  }
                                </td>)
                              }
                            </tr>)}
                          </tbody>
                        </table>
                      </div>
                      : <div>
                        <p className="mt-4">You have not set up your Program Schedule!</p>
                        <img src={publicPath('img/drag-arrow.png')} style={{
                          position: 'absolute',
                          top: 100,
                          left: -70,
                          maxWidth: 200,
                          pointerEvents: 'none',
                        }}/>
                        <p>Drag members onto the schedule from the left column.</p>
                      </div>
                    }
                    {provided.placeholder}
                  </div>
                }
              </Droppable>
            </div>
          </DragDropContext>
        </>}
      </LazyResourcePanel>
      {
        this.showManageMeetingsModal
          ? <ManageMeetingsModal
            chapterId={this.props.chapter.id}
            onClosed={() => this.showManageMeetingsModal = false}
            onChange={() => this.programSchedule.refresh(true)}
          />
          : null
      }
    </>
  }
}

type ManageMeetingsModalProps = {
  chapterId: number
  onClosed: () => void
  onChange: () => void
}

@observer
class ManageMeetingsModal extends React.Component<ManageMeetingsModalProps> {
  @observable private show = true

  @observable private upcomingMeetings = createLazyResource<Event[]>(() => {
      return ApiClient.query(
        `
events {
  *
}
`,
        {
          where: [
            { _scope: 'chapter', value: this.props.chapterId },
            { id: 'startsAt', op: '>=', value: moment().toISOString() },
            { status: 'Active' },
          ],
          order: [{ id: 'startsAt' }],
        }
      )
    },
    response => {
      return response.data.events.map((ps: any) => new Event().init(modelToCamelCase(ps)))
    }
  )

  @computed
  private get skippedMeetings () {
    return this.upcomingMeetings.current
      ? this.upcomingMeetings.current.filter(e => e.skipProgramSchedule)
      : []
  }

  @computed
  private get skippableMeetings () {
    return this.upcomingMeetings.current
      ? this.upcomingMeetings.current.filter(e => !e.skipProgramSchedule)
      : []
  }

  private skipMeeting = async (eventId: number, skip: boolean) => {
    AppStateStore.showModalSpinner()

    try {
      await ApiClient.getInstance().post(route(ApiRoutes.chapters.program.skipMeeting, { id: this.props.chapterId }), { event_id: eventId, skip: skip ? 1 : 0 })
      this.props.onChange()
      this.upcomingMeetings.refresh(true).then()
      if (skip) {
        AppStateStore.showAlertModal('Calendar', 'Removing a meeting from your program schedule does not remove it from your chapter calendar automatically. You must cancel the meeting on your chapter calendar if you would like to remove it from the calendar as well.')
      }
    } catch (err) {
      console.log(err)
      AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
    }

    AppStateStore.dismissModalSpinner()
  }

  componentDidMount () {
    this.upcomingMeetings.refresh().then()
  }

  render () {
    return <Modal
      isOpen={this.show}
      size="lg"
      onClosed={() => this.props.onClosed()}
    >
      <ModalHeader toggle={() => this.show = false}>Manage Meetings</ModalHeader>
      <ModalBody>
        <p>
          If you are not planning on having any program speakers at a particular meeting, you can choose to skip it in the program rotation below.
        </p>
        <div style={{ maxHeight: 400, overflow: 'auto' }}>
          <div className="row">
            <div className="col-6">
              <h4>Meeting on Schedule</h4>
              {
                this.skippableMeetings.map(meeting => <div key={meeting.id} style={{ borderBottom: 'solid 1px #eee', padding: 4 }}>
                  <b>{meeting.startsAt.format('MMM D')}</b> (<a href="#" onClick={ev => {
                  ev.preventDefault()
                  this.skipMeeting(meeting.id, true).then()
                }}>remove from program schedule</a>)<br/>
                  {meeting.displayTitle}
                </div>)
              }
            </div>
            <div className="col-6">
              <h4>Skipped Meetings</h4>
              {
                this.skippedMeetings.map(meeting => <div key={meeting.id} style={{ borderBottom: 'solid 1px #eee', padding: 4 }}>
                  <b>{meeting.startsAt.format('MMM D')}</b> (<a href="#" onClick={ev => {
                  ev.preventDefault()
                  this.skipMeeting(meeting.id, false).then()
                }}>add to program schedule</a>)<br/>
                  {meeting.displayTitle}
                </div>)
              }
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => this.show = false}
        >Close</Button>
      </ModalFooter>
    </Modal>
  }
}
