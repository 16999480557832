import BaseModel from "./BaseModel"
import Chapter from "./Chapter"
import Category from "./Category"
import { modelToCamelCase, publicPath } from "../common/Util"
import ContactMethod from "./ContactMethod"
import Address from "./Address"
import Business from "./Business"
import * as _ from 'lodash'
import { Moment } from "moment"
import Membership from "./Membership"
import PaymentMethod from "./PaymentMethod"
import ApiClient from "../api/ApiClient"
import LazyResource from "./LazyResource"
import DerivedLazyResource from "./DerivedLazyResource"
import MembershipFees from "./MembershipFees"
import FileUpload from "./FileUpload"
import MemberPosition from "./MemberPosition"
import User from "./User"
import Position from "./Position"
import AuditLog from "./AuditLog"
import ProgramPositionMember from "./ProgramPositionMember"
import Application from './Application'
import CategoryChangeRequest from './CategoryChangeRequest'
import ChapterTransferRequest from './ChapterTransferRequest'
import CompanyReplacementRequest from './CompanyReplacementRequest'
import Testimony from "./Testimony"

export type VideoData = {
  id: string
  type: 'youtube' | 'vimeo'
  thumbnailUrl: string
  videoUrl: string
}

export default class Member extends BaseModel {
  id: number
  prefix: string | null
  firstName: string | null
  middleName: string | null
  fullName: string
  lastName: string | null
  preferredName: string | null
  preferredFullName: string
  phoneNumber: string | null
  emailAddress: string | null
  suffix: string | null
  suffix2: string | null
  websiteUrl: string
  bio: string
  status: string | 'Resigned' | 'New' | 'Active' | 'Terminated' | 'Chapter Disbanded' | 'Banned'
  profileImageUrl?: string
  inputDate?: Moment
  joinDate?: Moment
  birthDate?: Moment
  gender?: string
  badgeColor: string
  membershipType: string
  ntsTrainingCompletedAt: Moment | null
  boardTrainingCompletedAt: Moment | null
  programOrder: number
  shippingNotes: string | null
  videoData: VideoData | null
  chapterId: number
  timezone: string | null
  userId: number | null
  categoryId: number | null
  isCompanyReplacement: boolean
  replacedCompanyMemberId: number | null
  billingEmailAddress: string | null
  lastProgramScheduledDate: Moment | null
  numberOfSponsoredMembers: number
  applicationStatus: string | null
  calculatedInactiveDate: Moment | null
  defaultTipPrivacy: string
  bankCode: string | null
  bankCodeDocumentUrl: string | null
  crackMyCodeLandingUrl: string | null
  codebreakerEbookUrl: string | null
  renewalDiscountType: string | null
  renewalDiscountPercent: number | null
  numberOfSponsorCredits: number

  replacedCompanyMember: Member | null
  replacedByCompanyMember: Member | null
  address?: Address
  shippingAddress?: Address
  business?: Business
  chapter?: Chapter
  category?: Category
  primaryMemberPosition: MemberPosition | null
  primaryPositionName: string | null
  sponsors: Member[]
  sponsoredMembers: Member[]
  scheduledProgramPositions: ProgramPositionMember[]
  user: User
  positions: Position[]
  networkAuditLogs: AuditLog[]
  loginInfoSentAt: Moment | null
  welcomeLetterSentAt: Moment | null
  applications: Application[]
  categoryChangeRequests: CategoryChangeRequest[]
  chapterTransferRequests: ChapterTransferRequest[]
  companyReplacementRequests: CompanyReplacementRequest[]
  testimony: Testimony

  constructor (data?: {}) {
    super()

    data && this.init(data)

    if (!this.sponsors) {
      this.sponsors = []
    }

    if (!this.positions) {
      this.positions = []
    }

    if (!this.networkAuditLogs) {
      this.networkAuditLogs = []
    }

    if (!this.applications) {
      this.applications = []
    }

    if (!this.chapterTransferRequests) {
      this.chapterTransferRequests = []
    }

    if (!this.companyReplacementRequests) {
      this.companyReplacementRequests = []
    }
  }

  getCasts (): {} {
    return {
      inputDate: 'datetime',
      joinDate: 'datetime',
      birthDate: 'datetime',
      ntsTrainingCompletedAt: 'datetime',
      boardTrainingCompletedAt: 'datetime',
      userId: 'number',
      categoryId: 'number',
      chapterId: 'number',
      replacedCompanyMemberId: 'number',
      isCompanyReplacement: 'boolean',
      lastProgramScheduledDate: 'datetime',
      numberOfSponsoredMembers: 'number',
      loginInfoSentAt: 'datetime',
      welcomeLetterSentAt: 'datetime',
      calculatedInactiveDate: 'datetime',
      renewalDiscountPercent: 'number',
      numberOfSponsorCredits: 'number',
    }
  }

  setNetworkAuditLogs (p?: []) {
    return p ? p.map(pp => new AuditLog().init(pp)) : []
  }

  setReplacedCompanyMember (m?: {}) {
    return m ? new Member().init(m) : null
  }

  setReplacedByCompanyMember (m?: {}) {
    return m ? new Member().init(m) : null
  }

  setPositions (p?: []) {
    return p ? p.map(pp => new Position().init(pp)) : []
  }

  setUser (u?: {}) {
    return u ? new User().init(u) : null
  }

  setChapter (c?: {}) {
    return c ? new Chapter(c) : undefined
  }

  setCategory (c?: {}) {
    return c ? new Category(c) : undefined
  }

  setBusiness (b?: {}) {
    return b ? new Business(b) : undefined
  }

  setPaymentMethods (p?: []) {
    return p ? p.map(pp => new PaymentMethod().init(pp)) : []
  }

  setPrimaryMemberPosition (p?: {}) {
    return p ? new MemberPosition(p) : null
  }

  setAddress (a?: {}) {
    return a ? new Address(a) : undefined
  }

  setShippingAddress (a?: {}) {
    return a ? new Address(a) : undefined
  }

  setSponsors (s?: []) {
    return s ? s.map(s => new Member(s)) : []
  }

  setSponsoredMembers (s?: []) {
    return s ? s.map(s => new Member(s)) : []
  }

  setMemberships (a?: []) {
    return a ? a.map(aa => new Membership().init(aa)) : []
  }

  setActiveMembership (a?: {}) {
    return a ? new Membership(a) : null
  }

  setScheduledProgramPositions (p?: []) {
    return p ? p.map((pp: any) => new ProgramPositionMember().init(pp)) : []
  }

  setApplications (a?: []) {
    return a ? a.map(aa => new Application().init(aa)) : []
  }

  setCategoryChangeRequests (a?: []) {
    return a ? a.map((aa: any) => new CategoryChangeRequest().init(aa)) : []
  }

  setChapterTransferRequests (a?: []) {
    return a ? a.map((aa: any) => new ChapterTransferRequest().init(aa)) : []
  }

  setCompanyReplacementRequests (a?: []) {
    return a ? a.map((aa: any) => new CompanyReplacementRequest().init(aa)) : []
  }

  setTestimony(t?:{}){
    return t ? new Testimony(t) : null
  }

  getDisplayName = () => {
    return this.preferredName || this.fullName
  }

  getProfileImageUrl = () => {
    return this.profileImageUrl || publicPath('/img/user-profile-placeholder.png')
  }

  getPreferredFullName = () => {
    return this.preferredName ? `${this.preferredName} ${this.lastName}` : this.fullName
  }

  static getProfileImageUrl (profileImageUrl: string | null | undefined) {
    return profileImageUrl || publicPath('/img/user-profile-placeholder.png')
  }

  isDisabled = () => {
    return ['Resigned', 'Terminated', 'Chapter Disbanded', 'Banned'].indexOf(this.status) > -1
  }

  getAreaName = () => {
    return _.get(this, 'chapter.area.name')
  }

  getPositionName = () => {
    return this.primaryMemberPosition ? this.primaryMemberPosition.position.name : null
  }

  paymentMethods = new LazyResource<PaymentMethod[]>((callback, error) => {
    ApiClient.members.getPaymentMethods(this.id)
      .then(response => callback(response.data.payment_methods.map((p: {}) => new PaymentMethod(modelToCamelCase(p)))))
      .catch(() => error('There was an error loading the payment types'))
  })

  defaultPaymentMethod = new DerivedLazyResource<PaymentMethod, PaymentMethod[]>(this.paymentMethods, paymentMethods => _.find(paymentMethods, m => m.isDefault))

  memberships = new LazyResource<Membership[]>((callback, error) => {
    ApiClient.members.getMemberships(this.id)
      .then(response => callback(response.data.memberships.map((m: {}) => new Membership(modelToCamelCase(m)))))
      .catch(() => error('There was an error loading the membership data'))
  })

  activeMembership = new DerivedLazyResource<Membership, Membership[]>(this.memberships, memberships => _.find(memberships, m => m.isActive))

  pastMemberships = new DerivedLazyResource<Membership[], Membership[]>(this.memberships, memberships => _.filter(memberships, m => !m.isActive))

  membershipFees = new LazyResource<MembershipFees>((callback, error) => {
    ApiClient.members.getMembershipFees(this.id)
      .then(response => callback(new MembershipFees(modelToCamelCase(response.data.fees))))
      .catch(() => error('There was an error loading the membership fees'))
  })

  documents = new LazyResource<FileUpload[]>((callback, error) => {
    ApiClient.query(`
      fileUploads {
        id
        createdAt
        filename
        mimeType
        fileSizeBytes
        url
      }
    `, {
      where: [
        { code: FileUpload.CODE_MEMBER_DOCUMENT },
        { uploadable_id: this.id },
        { uploadable_type: 'member' },
      ],
      order: [{ id: 'createdAt', desc: true }],
    })
      .then(response => callback(response.data.fileUploads.map((t: {}) => new FileUpload().init(t))))
      .catch(() => error('There was an error loading the documents'))
  })
}
