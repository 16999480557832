import * as React from "react"
import { computed, observable } from "mobx"
import { observer } from "mobx-react"
import ApiClient from '../api/ApiClient'
import classNames from 'classnames'

type Props = {
  chapterId: number
  className?: string
}

@observer
export default class EmailChapterButton extends React.Component<Props> {
  @observable private emailAddresses: string[]
  @observable private loading = false

  @computed get href () {
    return (this.loading || !this.emailAddresses || !this.emailAddresses.length)
      ? '#'
      : `mailto:${this.emailAddresses.map(em => `<${encodeURIComponent(em)}>`).join(';')}`
  }

  componentDidMount (): void {
    this.loading = true
    ApiClient.query(`
    members {
      emailAddress
    `, {
      where: [
        { _scope: 'chapter', value: this.props.chapterId },
        { _scope: 'active' },
      ],
    }).then(response => {
      this.emailAddresses = response.data.members.filter((m: any) => !!m.emailAddress).map((m: any) => m.emailAddress)
      this.loading = false
    })
  }

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <a
      target="_blank"
      href={this.href}
      className={classNames('btn btn-primary', this.props.className)}
    >Email Entire Chapter</a>
  }
}
