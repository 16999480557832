import * as React from "react"
import { observer } from "mobx-react"
import { LinkContainer } from "react-router-bootstrap"
import * as H from "history"
import { Button } from "reactstrap"
import Notice from "../../models/Notice"
import BaseView from "../BaseView"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { ApiTableViewAdapter } from "../../components/table-view/ApiTableViewAdapter"
import { QueryWhereClause } from "../../api/ApiClient"
import { Link } from "react-router-dom"
import { ManagedChooseableColumn } from "../../components/table-view/ManagedTableViewColumnChooser";
import ManagedTableView from "../../components/table-view/ManagedTableView";
import { formatNumber } from "../../common/Util";
import BoardDocument, { AllowedPosition } from '../../models/BoardDocument'
import AuthStore from '../../stores/AuthStore'

type Props = {
  location: H.Location<H.LocationState>,
}

@observer
export default class BoardDocumentsView extends BaseView<Props> {
  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>Board Documents</h1>
        <ul className="content-header-actions">
          <li>
            <LinkContainer to={route(Routes.system.boardDocuments.create)}>
              <Button color="primary" onClick={() => {
              }}><i className="fa fa-plus-circle"/> Add New Board Document</Button>
            </LinkContainer>
          </li>
        </ul>
      </>
    )
  }

  private tableViewRef = React.createRef<ManagedTableView>()

  private availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'title',
      column: {
        accessor: 'title',
        title: 'Title',
        renderItem: (title: string, doc: BoardDocument) => <Link to={route(Routes.system.boardDocuments.show, { id: doc.id })}>{title}</Link>
      },
      fixed: true,
    },
    {
      id: 'pdfName',
      column: {
        accessor: 'pdfName',
        title: 'PDF',
        renderItem: (pdfName: string, doc: BoardDocument) => <a target="_blank" href={`${doc.pdfUrl}?token=${AuthStore.generateTempAuthToken()}`}>{pdfName}</a>
      },
      fixed: true,
    },
    {
      id: 'allowedBoardPositions',
      column: {
        accessor: 'allowedBoardPositions',
        title: 'Permission',
        renderItem: d => d ? d.map((p: AllowedPosition) => p.name).join(', ') : 'Anyone board member',
      },
      default: true,
    },
    {
      id: 'signatures',
      column: {
        renderItem: (_: any, boardDocument: BoardDocument) => `${formatNumber(boardDocument.signedCount)} / ${formatNumber(boardDocument.totalCount)}`,
        title: 'Signatures',
      },
      fixed: true,
    },
    {
      id: 'createdAt',
      column: {
        accessor: 'createdAt',
        title: 'Created',
        renderItem: d => d.format('MM/DD/YYYY')
      },
      default: true,
    },
  ]

  private boardDocumentsAdapter = new ApiTableViewAdapter(() => {
      const where: QueryWhereClause[] = []

      return {
        query: `
      boardDocuments {
        *
      }
    `,
        where: where,
      }
    },
    'boardDocuments',
    BoardDocument
  )

  renderContentBody (): React.ReactNode {
    return this.renderTable()
  }

  private renderTable = () => {
    return <ManagedTableView
      stateKey="BoardDocumentsView"
      hideItemCount={false}
      allowShowInactive={false}
      ref={this.tableViewRef}
      adapter={this.boardDocumentsAdapter}
      availableColumns={this.availableColumns}
    />
  }
}
