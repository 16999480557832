import BaseModel from "./BaseModel"
import { Moment } from 'moment-timezone/moment-timezone'

export default class ExtendedNetworkMember extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  email: string
  phone: string
  name: string
  status: string
  numberOfTipsPassed: number
  invitationSentAt: Moment | null
  acceptedAt: Moment | null

  getCasts (): {} {
    return {
      id: 'number',
      numberOfTipsPassed: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      invitationSentAt: 'datetime',
      acceptedAt: 'datetime',
    }
  }
}
