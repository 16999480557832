import { observer } from "mobx-react"
import Member from "../models/Member"
import React, { SyntheticEvent } from "react"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import AppStateStore from "../stores/AppStateStore"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import Util, { logException, modelToSnakeCase, safeNull } from "../common/Util"
import { toast } from "react-toastify"
import { Button } from "reactstrap"
import ButtonLoader from "./ButtonLoader"
import _ from "lodash"



type Props = {
  member: Member
  onCancel: () => void
  onSaved: () => void
}

type EditFormState = {
    testimony:string
}

@observer
export default class MemberTestimonyForm extends React.Component<Props, never> {

  // private initialFormState = {
  //   sourceMemberId: safeNull(() => AuthStore.getUser()!.member!.id),
  //   testimony: _.get(this.props.member.testimony, 'testimony', '') || '',
  //   targetMemberId: safeNull(() => AuthStore.getUser()!.member!.id),
  // };


  @observable
  private formState = new FormState<EditFormState>({
    testimony:  _.get(this.props.member.testimony[0], 'testimony', '') || '',
  })

  // private formState = new FormState(this.initialFormState);

  @observable
  private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable
  private submitting = false

  private isDirty = false

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.members.updateTestimonyText(this.props.member.id, modelToSnakeCase(this.formHelper.toObject()))
      .then((response: any) => {
        toast.success('Success Story updated')
        this.isDirty = true
        this.onSaved()
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .catch((ex: any) => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private onCancelClicked = () => {
    this.props.onCancel()
  }

  private onSaved = () => {
    this.props.onSaved()
  }

  render (): React.ReactNode {
    return <>
      <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
        <div className="form-row">
          <div className="col-sm-12">
            {this.formHelper.renderTextAreaInput({
              name: 'testimony',
              placeholder: 'Enter Testimony..',
            })}
          </div>
        </div>

        <div className="form-buttons">
          <Button type="button" color="" onClick={() => this.onCancelClicked()}>Cancel</Button>
          <ButtonLoader type="submit" color="success" loading={this.submitting}>Save Changes</ButtonLoader>
        </div>
      </form>
    </>
  }
}