import * as React from "react"
import { SyntheticEvent } from "react"
import BaseView from "./BaseView"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { Prompt, Redirect } from "react-router-dom"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { observer } from "mobx-react"
import ButtonLoader from "../components/ButtonLoader"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import Util, { logException, modelToSnakeCase } from "../common/Util"
import { BarLoader } from "react-spinners"
import { Button } from "reactstrap"
import { LinkContainer } from "react-router-bootstrap"
import { toast } from "react-toastify"
import BackLink from "../components/BackLink"

type CreateSupportAreaFormState = {
  name: string
}

@observer
export default class CreateSupportAreaView extends BaseView {
  @observable private formState = new FormState<CreateSupportAreaFormState>({
    name: '',
  })
  @observable private formErrors = new ErrorBag()
  private formHelper = new FormHelper(this.formState, this.formErrors)
  @observable private submitting = false
  @observable private loading = false
  @observable private redirect = false

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>{'Add New Support Area'}</h1>
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    ApiClient.supportAreas.create(modelToSnakeCase(this.formHelper.toObject()))
      .then(() => {
        toast.success('Support Area created')
        this.redirect = true
      }, error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  renderContentBody (): React.ReactNode {
    return this.redirect
      ? this.renderRedirect()
      : this.loading ? this.renderLoading() : this.renderForm()
  }

  private renderLoading = () => <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>

  private renderForm = () => (
    <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      {this.formState.isDirty && <Prompt message="Are you sure you want to leave this page? You will lose any unsaved changes!"/>}
      <div className="form-row">
        <div className="col-sm-12">
          {this.formHelper.renderTextInput({
            name: 'name',
            type: 'text',
            label: 'Support Area Name',
          })}
        </div>
      </div>

      <div className="form-buttons">
        <LinkContainer to={route(Routes.system.supportAreas.index)}><Button type="button" color=""><i className="fa fa-ban"/> Cancel</Button></LinkContainer>
        <ButtonLoader type="submit" color="success" loading={this.submitting}><i className="fa fa-save"/> {'Save New Support Area'}</ButtonLoader>
      </div>
    </form>
  )

  private renderRedirect = () => <Redirect to={route(Routes.system.supportAreas.index)}/>
}