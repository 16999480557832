import * as React from "react"
import { SyntheticEvent } from "react"
import BaseView from "./BaseView"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { Prompt, Redirect } from "react-router-dom"
import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { observer } from "mobx-react"
import ButtonLoader from "../components/ButtonLoader"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import Util, { logException, modelToCamelCase, modelToSnakeCase } from "../common/Util"
import { BarLoader } from "react-spinners"
import { Button } from "reactstrap"
import { LinkContainer } from "react-router-bootstrap"
import Area from "../models/Area"
import * as AsyncHelpers from "../api/AsyncHelpers"
import { toast } from "react-toastify"
import BackLink from "../components/BackLink"

type EditAreaFormState = {
  name: string
  status: string
  regionId?: number
}

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

@observer
export default class EditAreaView extends BaseView<Props> {
  @observable private formState = new FormState<EditAreaFormState>({
    name: '',
    status: 'Active',
  })
  @observable private formErrors = new ErrorBag()
  private formHelper = new FormHelper(this.formState, this.formErrors)
  @observable private submitting = false
  @observable private loading = false
  @observable private redirect = false
  @observable private editArea?: Area

  private get isEdit () {
    return !!this.props.match.params.id
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>{this.isEdit ? 'Update Existing Area' : 'Add New Area'}</h1>
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    if (this.props.match.params.id) {
      this.loading = true

      ApiClient.areas.show(this.props.match.params.id)
        .then(response => {
          const areaData = modelToCamelCase(response.data.area)
          this.editArea = new Area(modelToCamelCase(areaData))
          this.formState.setAll(areaData as EditAreaFormState)
          this.formState.set('regionId', this.editArea.region.id)
          this.formState.clearDirty()
          this.loading = false
        }, () => {
          AppStateStore.showAlertModal('Error', 'The area was not found', m => {
            m.hide()
            this.redirect = true
          })
        })
        .catch(ex => {
          logException(ex)
          AppStateStore.showAlertModal('Error', 'The area was not found', m => {
            m.hide()
            this.redirect = true
          })
        })
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    this.submitting = true
    AppStateStore.showModalSpinner()

    const apiCall = this.editArea
      ? () => ApiClient.areas.update(this.editArea!.id, modelToSnakeCase(this.formHelper.toObject()))
      : () => ApiClient.areas.create(modelToSnakeCase(this.formHelper.toObject()))

    apiCall().then(() => {
      toast.success(this.isEdit ? 'Area updated' : 'Area created')
      this.redirect = true
    }, error => {
      const errors = new ErrorBag()
      Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      })

      this.formErrors.addErrors(errors.getErrorList())
    })
      .catch(ex => {
        logException(ex)
        this.formErrors = new ErrorBag().addError('_form', 'A server error has occurred')
      })
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  renderContentBody (): React.ReactNode {
    return this.redirect
      ? this.renderRedirect()
      : this.loading ? this.renderLoading() : this.renderForm()
  }

  private renderLoading = () => <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>

  private renderForm = () => (
    <form method="post" action="" acceptCharset="UTF-8" onSubmit={this.submit}>
      {this.formState.isDirty && <Prompt message="Are you sure you want to leave this page? You will lose any unsaved changes!"/>}
      <div className="form-row">
        <div className="col-sm-6">
          {this.formHelper.renderTextInput({
            name: 'name',
            type: 'text',
            label: 'Area Name',
          })}
        </div>
        <div className="col-sm-6">
          {this.formHelper.renderSelectInput({
            name: 'status',
            label: 'Status',
            options: [
              { value: 'Active', text: 'Active' },
              { value: 'Inactive', text: 'Inactive' },
            ]
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          {this.formHelper.renderAsyncSelectInput({
            name: 'regionId',
            label: 'Region',
            loadOptions: AsyncHelpers.loadRegionOptions,
            defaultValue: this.editArea ? { value: this.editArea.region.id, label: this.editArea.region.name } : undefined,
          })}
        </div>
      </div>

      <div className="form-buttons">
        <LinkContainer to={route(Routes.system.areas.index)}><Button type="button" color=""><i className="fa fa-ban"/> Cancel</Button></LinkContainer>
        <ButtonLoader type="submit" color="success" loading={this.submitting}><i className="fa fa-save"/> {this.isEdit ? 'Update Area' : 'Save New Area'}</ButtonLoader>
      </div>
    </form>
  )

  private renderRedirect = () => <Redirect to={route(Routes.system.areas.index)}/>
}
