import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import * as React from "react"
import { observer } from "mobx-react"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import Event, { EventStatus } from "../models/Event"
import { computed, observable } from "mobx"
import AddressView from "./AddressView"
import Util, { formatDateForCalendar, nl2br, safeNull } from "../common/Util"
import RequirePermission, { Permission } from "./RequirePermission"
import AppStateStore from "../stores/AppStateStore"
import { route } from "../routes/routes"
import { toast } from "react-toastify"
import MemberProfileImage from "./MemberProfileImage"
import * as _ from "lodash"
import { Link } from "react-router-dom"
import { Routes } from "../routes/AppRoutes"
import ProgramScheduleRecord from "../models/ProgramScheduleRecord"
import ReactLinkify from 'react-linkify'
import AuthStore from '../stores/AuthStore'

type EventDetailModalProps = {
  isOpen: boolean
  toggle: () => void
  eventId: number
  onEventDeleted: () => void
  onEventUpdated: (event: Event) => void
  onEditEvent: (event: Event) => void
}

@observer
export class EventDetailModal extends React.Component<EventDetailModalProps> {
  @observable private event?: Event
  @observable private error?: string

  private fetchEvent = () => {
    if (!this.props.eventId) {
      this.event = undefined
      return
    }

    ApiClient.query(
      `
event {
  *

  programSchedule

  address {
    *
  }

  chapter {
    *
  }
}
      `,
      {
        where: [{ id: this.props.eventId }]
      }
    )
      .then(response => this.event = new Event().init(response.data.event))
      .catch(error => this.error = Util.extractErrorMessage(error.response))
  }

  componentDidMount(): void {
    this.fetchEvent()
  }

  componentDidUpdate(prevProps: Readonly<EventDetailModalProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.eventId !== prevProps.eventId) {
      this.fetchEvent()
    }
  }

  private deleteEvent = () => {
    AppStateStore.showConfirmationModal('Delete Event', 'Are you sure you want to delete this event from the calendar?', (result, modal) => {
      modal.hide()

      if (result) {
        ApiClient.getInstance()
          .delete(route(ApiRoutes.calendar.deleteEvent, { eventId: this.props.eventId }))
          .then(() => {
            toast.success('Event deleted')
            this.props.onEventDeleted()
          })
          .catch(err => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response)))
      }
    })
  }

  private editEvent = () => {
    this.props.onEditEvent(this.event!)
  }

  private cancelEvent = () => {
    AppStateStore.showConfirmationModal('Cancel Meeting', 'Are you sure you want to cancel this meeting?', (result, modal) => {
      modal.hide()

      if (result) {
        ApiClient.getInstance()
          .post(route(ApiRoutes.calendar.cancelEvent, { eventId: this.props.eventId }))
          .then(() => {
            toast.success('Event cancelled')
            this.props.onEventUpdated(this.event!)
          })
          .catch(err => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response)))
      }
    })
  }

  @computed get modalTitle() {
    const event = this.event
    if (event) {
      if (event.chapter) {
        return `${event.chapter.name} Event`
      } else if (event.area) {
        return `${event.area.name} Event`
      } else if (event.region) {
        return `${event.region.name} Event`
      }

      return event.title
    } else {
      return 'Event Details'
    }
  }

  renderMeeting = (event: Event) => {
    return <div className="row">
    {
      event.locationName || event.address
        ? <div className="col-6">
          <h5>Location</h5>
          {
            event.locationName
              ? <h6>{event.locationName}</h6>
              : null
          }
          {
            event.address
              ? <AddressView address={event.address} showMapLink={true}/>
              : null
          }
        </div>
        : null
    }
    {
      event.webConferenceDetails
        ? <div className="col-6">
          <h5>Web Conference Details</h5>
          <div>
            <ReactLinkify>{nl2br(event.webConferenceDetails)}</ReactLinkify>
          </div>
        </div>
        : null
    }
  </div>

  }

  renderLocation = (event: Event) => {
    console.log(event.meetingType)
    return  event.locationName || event.address ? <div className="col-6">
        <h5>Location</h5>
        {
          event.locationName
            ? <h6>{event.locationName}</h6>
            : null
        }
        {
          event.address
            ? <AddressView address={event.address} showMapLink={true} />
            : null
        }
      </div> : null
  }

  renderWeb = (event: Event) => {
    return event.webConferenceDetails
    ? <div className="col-6">
      <h5>Web Conference Details</h5>
      <div>
        <ReactLinkify>{nl2br(event.webConferenceDetails)}</ReactLinkify>
      </div>
    </div>
    : null
  }



  render(): React.ReactNode {
    const { isOpen, toggle } = this.props

    const event = this.event

    return <Modal isOpen={isOpen} size="lg" toggle={() => this.props.toggle()}>
      <ModalHeader toggle={toggle}>
        {this.modalTitle}
      </ModalHeader>
      <ModalBody>
        {
          this.error
            ? <div className="alert alert-error">{this.error}</div>
            :
            event ? <>
              {
                event.status === EventStatus.Canceled
                  ? <div className="alert alert-danger text-center">This event has been canceled</div>
                  : null
              }
              <div className="d-flex">
                <div className="flex-fill"><h5>{event.startsAt.clone().tz(event.timezone).format('dddd, MMMM Do')}</h5></div>
                <div><h5>{formatDateForCalendar(event.startsAt, event.timezone, AuthStore.getUser()!.timezone || 'UTC', event.showInLocalTimezone, false)} - {formatDateForCalendar(event.endsAt, event.timezone, AuthStore.getUser()!.timezone || 'UTC', event.showInLocalTimezone)}</h5></div>
              </div>
              <h3>{event.title}</h3>
              <p>{event.description}</p>
              {
                event.notes
                  ? <div>Notes:<p>{event.notes}</p></div>
                  : null
              }
              {
                event.chapter
                  ? <div>
                    <b>Chapter:</b> {event.chapter.name}
                  </div>
                  : null
              }
              <hr />
                {
                  event.meetingType === null || event.meetingType === 'hybrid' ? this.renderMeeting(event)
                    : event.meetingType === 'in-person' ? <div className="row"> {this.renderLocation(event)} </div>
                    : event.meetingType === 'virtual' ? <div className="row"> {this.renderWeb(event)}</div>
                    : null
                }

            </>
              : null
        }
        {event && event.skipProgramSchedule
          ? <>
            <hr />
            <Alert color="info">No program speakers are scheduled for this meeting</Alert>
          </>
          : null
        }
        {
          event && event.programSchedule.length
            ? <>
              <hr />
              {_.orderBy(event.programSchedule, 'programPosition.displayOrder').map((sch: ProgramScheduleRecord) => <div key={sch.member.id} className="d-flex mb-2">
                <div>
                  <MemberProfileImage
                    size={45}
                    profileImageUrl={sch.member.profileImageUrl}
                    memberName={sch.member.fullName}
                  />
                </div>
                <div className="flex-fill ml-2 mr-2">
                  <div><Link to={route(Routes.network.member, { memberId: sch.member.id })}>{sch.member.fullName}</Link></div>
                  <b>{sch.position.title}</b>
                </div>
              </div>)}
            </>
            : null
        }
      </ModalBody>
      <ModalFooter>
        <RequirePermission
          permission={Permission.ManageEvent}
          context={{ event: event, chapterId: safeNull(() => event!.chapterId), regionId: safeNull(() => event!.regionId) }}
        >
          {() => <Button
            type="button"
            color="danger"
            onClick={() => {
              this.deleteEvent()
            }}>Delete Event</Button>}
        </RequirePermission>
        <RequirePermission
          permission={Permission.EditEvent}
          context={{ event: event, chapterId: safeNull(() => event!.chapterId), regionId: safeNull(() => event!.regionId) }}
        >
          {() => <Button
            type="button"
            color="primary"
            onClick={() => {
              this.editEvent()
            }}>Edit Event</Button>}
        </RequirePermission>
        <RequirePermission
          permission={Permission.CancelEvent}
          context={{ event: event }}
        >
          {() => <Button
            type="button"
            color="danger"
            onClick={() => {
              this.cancelEvent()
            }}>Cancel Meeting</Button>}
        </RequirePermission>
        <Button
          color="secondary"
          onClick={() => this.props.toggle()}
        >Close Window</Button>
      </ModalFooter>
    </Modal>
  }
}
