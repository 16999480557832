import BaseView from "../BaseView"
import { observer } from "mobx-react"
import * as React from "react"
import * as _ from "lodash"
import { observable } from "mobx"
import { createBrowserHistory } from "history"
import { Nav, NavItem, NavLink, TabContent } from "reactstrap"
import classNames from "classnames"
import LazyTabPane from "../../components/LazyTabPane"
import PendingShipments from "../../components/system/fulfillment/PendingShipments"
import EventBus from "../../common/EventBus"
import ShipmentHistory from "../../components/system/fulfillment/ShipmentHistory"

const TABS = {
  pending: 'pending',
  history: 'history',
}

@observer
export default class FulfillmentView extends BaseView {
  @observable private activeTab = TABS.pending

  private history = createBrowserHistory()
  private pendingEventBus = new EventBus()
  private historyEventBus = new EventBus()

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>Badge Shipping Requests</h1>
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    this.activeTab = _.get(this.history.location.state, 'activeTab') || TABS.pending
  }

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(window.location.pathname, { ...this.history.location.state, activeTab: this.activeTab })
      if (tab === TABS.pending) {
        this.pendingEventBus.dispatch('invalidate')
      } else if (tab === TABS.history) {
        this.historyEventBus.dispatch('invalidate')
      }
    }
  }

  render (): React.ReactNode {
    return <>
      <div className="card">
        <div className="card-header">
          <Nav tabs className="card-header-tabs">
            <NavItem className={classNames({ active: this.activeTab === TABS.pending })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.pending)
                }}
              >Pending Shipments</NavLink>
            </NavItem>
            <NavItem className={classNames({ active: this.activeTab === TABS.history })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.history)
                }}
              >History</NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="card-body">
          <TabContent activeTab={this.activeTab}>
            <LazyTabPane tabId={TABS.pending} activeTab={this.activeTab}>
              <PendingShipments eventBus={this.pendingEventBus}/>
            </LazyTabPane>
            <LazyTabPane tabId={TABS.history} activeTab={this.activeTab}>
              <ShipmentHistory eventBus={this.historyEventBus}/>
            </LazyTabPane>
          </TabContent>
        </div>
      </div>
    </>
  }
}