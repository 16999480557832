import { Moment } from "moment-timezone"
import BaseModel from "./BaseModel"
import Event from "./Event"
import Guest from "./Guest"
import Chapter from "./Chapter"
import Member from "./Member"

export default class EventGuest extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  status: string | null
  attendance: string | null

  guestId: number
  eventId: number
  targetChapterId: number
  sourceMemberId: number | null

  guest: Guest
  event: Event
  targetChapter: Chapter
  sourceMember: Member

  setGuest (g: {}) {
    return g ? new Guest().init(g) : null
  }

  setEvent (e: {}) {
    return e ? new Event().init(e) : null
  }

  setTargetChapter (c?: {}) {
    return c ? new Chapter().init(c) : null
  }

  setSourceMember (m?: {}) {
    return m ? new Member().init(m) : null
  }

  getCasts () {
    return {
      'id': 'number',
      'createdAt': 'datetime',
      'updatedAt': 'datetime',
      'guestId': 'number',
      'eventId': 'number',
      'targetChapterId': 'number',
      'sourceMemberId': 'number',
    }
  }
}