import CsvTransformer from "./CsvTransformer"
import { safeNull } from "../../common/Util"
import moment from "moment-timezone"
import Guest from '../Guest'

export default class ChapterGuestsCsvTransformer extends CsvTransformer<Guest> {
  get filename () {
    return `chapter-guests-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    //     {
    //       id: 'createdAt',
    //       column: {
    //         title: 'Date',
    //         accessor: 'createdAt',
    //         renderItem: (value: Moment) => value.format('MM/DD/YYYY'),
    //         sortable: true,
    //       },
    //       fixed: true,
    //     },
    //     {
    //       id: 'guest',
    //       column: {
    //         title: 'Guest',
    //         accessor: 'fullName',
    //         sortKey: 'lastName',
    //         sortable: true,
    //         renderItem: (value, item) => (
    //           <Link type="link" to={{ pathname: route(Routes.system.guests.show, { id: item.id }), state: { id: item.id } }}>{value}</Link>
    //         )
    //       },
    //       fixed: true,
    //     },
    //     {
    //       id: 'category',
    //       column: {
    //         title: 'Category',
    //         accessor: 'category.name',
    //         sortable: true,
    //       },
    //       default: false
    //     },
    //     {
    //       id: 'companyName',
    //       column: {
    //         title: 'Company',
    //         accessor: 'companyName',
    //         sortable: true,
    //       },
    //       default: false
    //     },
    //     {
    //       id: 'email',
    //       column: {
    //         title: 'Email',
    //         accessor: 'email',
    //         sortable: true,
    //       },
    //       fixed: false,
    //       default: true,
    //     },
    //     {
    //       id: 'phone',
    //       column: {
    //         title: 'Phone Number',
    //         accessor: 'phone',
    //         sortable: true,
    //       },
    //       fixed: false,
    //       default: true,
    //     },
    //     {
    //       id: 'status',
    //       column: {
    //         title: 'Status',
    //         accessor: 'status',
    //         sortable: true,
    //       },
    //       fixed: false,
    //       default: true,
    //     },
    //     {
    //       id: 'invitedTo',
    //       column: {
    //         title: 'Invited To',
    //         accessor: 'guestEvents',
    //         renderItem: (value: EventGuest[]) => value.length ? value[0].targetChapter.name : '---',
    //       },
    //       fixed: false,
    //     },
    //     {
    //       id: 'invitedBy',
    //       column: {
    //         title: 'Invited By',
    //         accessor: 'createdByMember.fullName',
    //       },
    //       fixed: false,
    //     }

    this.addColumn({
      header: 'Date',
      valueTransformer: row => row.createdAt.format('MM/DD/YYYY'),
    })

    this.addColumn({
      header: 'Guest',
      valueTransformer: row => row.fullName,
    })

    this.addColumn({
      header: 'Category',
      valueTransformer: row => safeNull(() => row.category!.name) || '',
    })

    this.addColumn({
      header: 'Company',
      valueTransformer: row => row.companyName || '',
    })

    this.addColumn({
      header: 'Email',
      valueTransformer: row => row.email || '',
    })

    this.addColumn({
      header: 'Phone',
      valueTransformer: row => row.phone || '',
    })

    this.addColumn({
      header: 'Status',
      valueTransformer: row => row.status || '',
    })

    this.addColumn({
      header: 'Invited To',
      valueTransformer: row => row.guestEvents.length ? row.guestEvents[0].targetChapter.name : '',
    })

    this.addColumn({
      header: 'Invited By',
      valueTransformer: row => row.createdByMember ? row.createdByMember.fullName : '',
    })

  }
}