import * as React from "react"
import { CircleLoader } from "react-spinners"
import { publicPath } from "../common/Util"
import { observer } from "mobx-react"

@observer
export default class LoadingView extends React.Component {
  componentDidMount (): void {
    document.body.classList.add('login')
  }

  componentWillUnmount (): void {
    document.body.classList.remove('login')
  }

  render (): React.ReactNode {
    return (
      <div>
        <div className="login-container">
          <div className="logo">
            <img src={publicPath('img/logo-login.png')} alt="LeTip Wired"/>
          </div>
          <div className="app-loading-container">
            <CircleLoader className="app-loading-spinner" color="#ffffff"/>
            <div className="app-loading-text">Loading, please wait...</div>
          </div>
        </div>
      </div>
    )
  }
}