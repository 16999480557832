import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Member from "./Member"
import PaymentMethod from "./PaymentMethod"
import Category from './Category'
import Chapter from './Chapter'

export default class CategoryChangeRequest extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  viewedAt: Moment
  submittedAt: Moment
  processedAt: Moment | null
  archivedAt: Moment | null
  status: string
  isCompleted: boolean
  isProcessed: boolean
  applicationPdfUrl: string | null

  categoryChangeFee: number
  taxRate: number

  applicationSignatureImageUrl: string

  memberId: number
  chapterId: number
  oldCategoryId: number
  categoryId: number
  paymentMethodId: number
  emailAddress: string | null
  businessName: string | null

  oldCategory: Category
  category: Category
  member: Member
  chapter: Chapter
  paymentMethod: PaymentMethod

  getCategoryChangeFeeTotal () {
    return Math.round((this.categoryChangeFee + this.categoryChangeFee * this.taxRate) * 100) / 100
  }

  setCategory (c?: {}) {
    return c ? new Category().init(c) : null
  }

  setOldCategory (c?: {}) {
    return c ? new Category().init(c) : null
  }

  setMember (m?: {}) {
    return m ? new Member().init(m) : null
  }

  setChapter (m?: {}) {
    return m ? new Chapter().init(m) : null
  }

  setPaymentMethod (p?: {}) {
    return p ? new PaymentMethod(p) : null
  }

  getCasts () {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      processedAt: 'datetime',
      archivedAt: 'datetime',
      viewedAt: 'datetime',
      submittedAt: 'datetime',
      memberId: 'number',
      chapterId: 'number',
      paymentMethodId: 'number',
      categoryId: 'number',
      oldCategoryId: 'number',
      categoryChangeFee: 'number',
      taxRate: 'number',
    }
  }
}
