import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone/moment-timezone"
import ConversationParticipant from "./ConversationParticipant"
import Message from "./Message"

export default class Conversation extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment

  groupName: string | null
  isBroadcast: boolean
  participants: ConversationParticipant[]
  lastMessage: Message
  participantsCache: CachedParticipant[]
  isDeleted: boolean

  getCasts (): {} {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
    }
  }

  setParticipants (p?: []) {
    return p ? p.map(pp => new ConversationParticipant().init(pp)) : []
  }

  setLastMessage (m?: {}) {
    return m ? new Message().init(m) : null
  }
}

export type CachedParticipant = {
  user: {
    id: number
    name: string
  }

  member: {
    id: number
    preferredFullName: string
    profileImageUrl: string | null
  } | null
}
