import BaseModel from "./BaseModel"

export default class Region extends BaseModel {
  id: number
  name: string
  status: string | 'Active' | 'Inactive'
  monthlyMembersGoal: number
  monthlyChaptersGoal: number
  monthlyRenewalsPercentageGoal: number
  monthlyGuestsGoal: number

  numberOfAreas?: number
  numberOfChapters?: number
  numberOfMembers?: number

  constructor (data = {}) {
    super();
    this.init(data);
  }
}