import { Modal, ModalBody, ModalHeader } from "reactstrap"
import * as React from "react"
import { observer } from "mobx-react"
import InviteGuestForm, { InviteGuestFormProps } from "./InviteGuestForm"

type InviteGuestModalProps = {
  isOpen: boolean
  toggle: () => void
} & InviteGuestFormProps

@observer
export class InviteGuestModal extends React.Component<InviteGuestModalProps> {
  static defaultProps = {
    showCancelButton: true,
    clearAfterSave: true,
  }

  render (): React.ReactNode {
    const { isOpen, toggle, ...rest } = this.props

    return <Modal isOpen={isOpen} onClosed={this.props.onCancel} size="lg">
      <ModalHeader toggle={toggle}>
        Invite a Guest
      </ModalHeader>
      <ModalBody>
        <InviteGuestForm
          {...rest}
        />
      </ModalBody>
    </Modal>
  }
}