import * as React from "react"
import { observer } from "mobx-react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import { route } from "../../routes/routes"
import { observable, toJS } from "mobx"
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import FormHelper from "../../forms/FormHelper"
import Util, { modelToSnakeCase } from "../../common/Util"
import AppStateStore from "../../stores/AppStateStore"
import ButtonLoader from "../ButtonLoader"
import { IFileBrowserNode } from "./FileBrowser"
import _ from 'lodash'

type RenameItemModalProps = {
  toggle: () => void
  isOpen: boolean
  onItemSaved: () => void
  node?: IFileBrowserNode
  availableTags: string[]
}

@observer
export default class RenameItemModal extends React.Component<RenameItemModalProps> {
  private initialFormState = {
    name: '',
  }

  @observable private formState = new FormState(this.initialFormState)
  @observable private formErrors = new ErrorBag()
  @observable private submitting = false
  @observable private tags: string[] = []
  @observable private newTag = ''

  private inputRef = React.createRef<HTMLInputElement>()

  private toggle = () => {
    this.formErrors.clearErrors()
    this.formState.setAll(this.initialFormState)

    if (!this.submitting) {
      this.props.toggle()
    }
  }

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private submit = () => {
    if (!this.props.node) {
      return
    }

    this.formErrors.clearErrors()
    this.submitting = true

    ApiClient.getInstance().put(route(ApiRoutes.documentLibrary.renameLibraryItem, { id: this.props.node.id }),
      modelToSnakeCase({
        ...this.formState.toObject(),
        tags: toJS(this.tags),
      }))
      .then(() => {
        this.formState.setAll(this.initialFormState)
        this.props.onItemSaved()
      })
      .catch(error => {
        Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      })
      .then(() => this.submitting = false)
  }

  componentDidUpdate (prevProps: Readonly<RenameItemModalProps>, prevState: Readonly<{}>, snapshot?: any) {
    const prev = prevProps.node ? prevProps.node.tags.slice() : []
    const cur = this.props.node ? this.props.node.tags.slice() : []

    if (_.difference(prev, cur).length || _.difference(cur, prev).length) {
      this.tags = this.props.node ? this.props.node.tags.slice() : []
    }
  }

  componentDidMount () {
    this.tags = this.props.node ? this.props.node.tags.slice() : []
  }

  addTag = () => {
    const tag = this.newTag.trim()

    if (tag.length) {
      if (!this.props.availableTags.find(t => t.toLowerCase() === tag.toLowerCase())) {
        this.props.availableTags.push(tag)
      }

      if (!this.tags.find(t => t.toLowerCase() === tag.toLowerCase())) {
        this.tags.push(tag)
      }

      this.newTag = ''
    }
  }

  toggleTag = (tag: string, checked: boolean) => {
    if (checked) {
      if (!this.tags.filter(t => t.toLowerCase() === tag.toLowerCase()).length) {
        this.tags.push(tag)
      }
    } else {
      this.tags = this.tags.filter(t => t.toLowerCase() !== tag.toLowerCase())
    }
  }

  render (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <Modal
      isOpen={this.props.isOpen}
      toggle={this.toggle}
      onOpened={() => {
        this.props.node && this.formState.set('name', this.props.node.name)
        this.inputRef.current && this.inputRef.current.focus()
      }}
    >
      <ModalHeader toggle={this.toggle}>
        Update {this.props.node ? this.props.node.type : 'Item'}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={ev => {
          ev.preventDefault()
          this.submit()
        }}>
          {
            this.formHelper.renderTextInput({
              ref: this.inputRef,
              label: `${this.props.node ? this.props.node.type : 'Item'} Name`,
              name: 'name',
              disabled: this.submitting,
            })
          }
          {
            (this.props.node && this.props.node.type === 'Video')
              ? <>
                {
                  this.props.availableTags.map(availableTag => <div key={availableTag} className="form-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={!!this.tags.find(t => t.toLowerCase() === availableTag.toLowerCase())}
                        onChange={ev => (this.toggleTag(availableTag, ev.target.checked))}
                      />
                      <span className="label-text">{availableTag}</span>
                    </label>
                  </div>)
                }
                <div className="input-group">
                  <input
                    type={'text'}
                    className={'form-control'}
                    value={this.newTag}
                    onChange={e => this.newTag = e.target.value}
                  />
                  <div className={'input-group-append'}>
                    <Button color={'primary'} onClick={this.addTag}>Add Tag</Button>
                  </div>
                </div>
              </>
              : null
          }
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="secondary"
          onClick={this.toggle}
          disabled={this.submitting}
        >Cancel</Button>
        <ButtonLoader
          type="button"
          color="primary"
          onClick={() => this.submit()}
          loading={this.submitting}
        >Update</ButtonLoader>
      </ModalFooter>
    </Modal>
  }
}
