import { ApiTableViewAdapter, ApiTableViewAdapterOptions } from "../components/table-view/ApiTableViewAdapter"
import BaseModel from "../models/BaseModel"
import { QueryWhereClause } from "../api/ApiClient"

export interface ResourceSpec {
  collectionResourceName: string
  label?: string
  modelClass: new () => BaseModel

  fields: FieldSpec[]

  relationships: RelationshipSpec[]
}

export enum FieldType {
  Number,
  String,
  DateTime,
  Date,
  Boolean,
  Enum,
}

export enum FieldTransform {
  Phone = 'phone',
}

export interface FieldSpec {
  name: string
  label?: string
  type: FieldType
  values?: { value: string, label?: string }[]
  nullable?: boolean
  fieldTransform?: FieldTransform
}

export interface RelationshipSpec {
  name: string
  label?: string
  resourceSpec: ResourceSpec
}


type RelationshipNode = {
  name: string
  children: RelationshipNode[]
}

export default abstract class ReportDataSource {
  protected constructor (public resource: ResourceSpec) {
  }

  protected expandRelationships (relationships: string[]): string {
    const nodes: RelationshipNode[] = []

    relationships.forEach(relationshipPath => {
      const parts = relationshipPath.split('.')

      let parent: RelationshipNode | undefined = undefined

      parts.forEach(part => {
        let node = (parent ? parent.children : nodes).find(n => n.name === part)

        if (!node) {
          node = {
            name: part,
            children: [],
          }

          if (parent) {
            parent.children.push(node)
          } else {
            nodes.push(node)
          }
        }

        parent = node
      })
    })

    const expandNodes = (nodes: RelationshipNode[]): string => {
      return nodes.length ? nodes.map(node => `
      ${node.name} {
        *
        ${expandNodes(node.children)}
      }
      `).join('') : ''
    }

    return expandNodes(nodes)
  }

  abstract createTableViewAdapter (withRelationships: string[], where: QueryWhereClause[], options?: ApiTableViewAdapterOptions<any>): ApiTableViewAdapter<any>
}