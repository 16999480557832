import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import * as _ from "lodash"
import Member from "./Member"

export enum UserRole {
  Admin = 'Admin',
  Employee = 'Employee',
  Member = 'Member',
}

export default class AuthUser extends BaseModel {

  public id: number
  public username: string
  public email: string
  public name: string
  public lastLoginAt: Moment
  public roles: string[]
  public member: Member | null
  public chapter: {
    id: number
    name: string
  }
  public profileImageUrl: string
  public isBoardMember: boolean
  public isRegionAdmin: boolean
  public adminRegionIds: number[]
  public isAreaRep: boolean
  public supportAreaChapters: number[]
  public timezone: string | null
  public calendarFeedUrl: string

  constructor (data = {}) {
    super();
    this.init(data);
  }

  get isSupportAreaRep () {
    return !!(this.supportAreaChapters && this.supportAreaChapters.length)
  }

  setMember (m?: {}) {
    return m ? new Member().init(m) : null
  }

  isMember = () => {
    return !!this.member
  }

  getCasts (): {} {
    return {
      lastLoginAt: 'datetime',
    }
  }

  isBoardPosition = (positionCode: string) => {
    return !!this.member && !!this.member.positions && !!_.find(this.member.positions, p => p.type === 'board' && p.code === positionCode)
  }

  isChairPosition = (positionCode: string) => {
    return this.member && this.member.positions && !!_.find(this.member.positions, p => p.type === 'chair' && p.code === positionCode)
  }

  hasRole = (role: string): boolean => {
    return this.roles.indexOf(role) > -1
  }

  hasAnyRole = (roles: string[]): boolean => {
    return roles.filter(r => {
      return this.hasRole(r)
    }).length > 0
  }

  hasAllRoles = (roles: string[]): boolean => {
    return roles.filter(r => {
      return this.hasRole(r)
    }).length === roles.length
  }

  isBoardMemberForChapter (chapterId: number) {
    return (this.chapter && this.chapter.id == chapterId)
  }

  isSupportAreaRepForChapter (chapterId: number) {
    return this.supportAreaChapters && this.supportAreaChapters.indexOf(Number(chapterId)) > -1
  }
}
