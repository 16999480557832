import * as React from "react"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import { observable } from "mobx"
import { observer } from "mobx-react"
import AddPaymentMethodForm from "./AddPaymentMethodForm"
import PaymentMethodList from "./PaymentMethodList"
import RequirePermission, { Permission } from "./RequirePermission"
import { EventBusContext } from "../common/EventBus"
import ChapterBillingHistory from "./ChapterBillingHistory"

type Props = {
  chapterId: number
  onChapterInvalidated: () => void
}

@observer
export default class ChapterBilling extends React.Component<Props, never> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  @observable
  private showModal = false
  @observable
  private editModalContent?: React.ReactNode
  @observable
  private editModalSize = 'lg'
  @observable
  private editModalTitle = 'Edit'

  private showEditModal = (title: string, component: React.ReactNode, size: string = 'lg') => {
    this.editModalTitle = title
    this.editModalContent = component
    this.showModal = true
    this.editModalSize = size
  }

  private closeEditModal = () => {
    this.showModal = false
    this.editModalContent = undefined
  }

  render (): React.ReactNode {
    return <>
      <RequirePermission permission={Permission.ViewChapterPaymentMethods} context={{ chapterId: this.props.chapterId }}>
        {() => <>
          <hr/>

          <h5 className="text-muted">Payment Methods</h5>
          <PaymentMethodList
            chapterId={this.props.chapterId}
          />
          <Button type="button" color="primary" onClick={() => this.showEditModal('Add Payment Method', <AddPaymentMethodForm chapterId={this.props.chapterId} onCancel={this.closeEditModal} onSaved={() => {
            this.closeEditModal()
            this.context.eventBus.dispatch('chapter-invalidated')
          }}/>, 'md')}>Add Payment Method</Button>
        </>}
      </RequirePermission>

      <RequirePermission permission={Permission.ViewChapterBilling} context={{ chapterId: this.props.chapterId }}>
        {() => <div>
          <hr/>
          <h5 className="text-muted">Billing History</h5>
          <ChapterBillingHistory
            chapterId={this.props.chapterId}
          />
        </div>}
      </RequirePermission>

      <Modal
        isOpen={this.showModal}
        size={this.editModalSize}
      >
        <ModalHeader toggle={this.closeEditModal}>
          {this.editModalTitle}
        </ModalHeader>
        <ModalBody>{this.editModalContent}</ModalBody>
      </Modal>
    </>
  }
}