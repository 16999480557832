import BaseModel from "./BaseModel"
import Chapter from './Chapter'

export default class ChapterFee extends BaseModel {
  id: number
  code: string | null
  label: string
  amount: number
  chapterId: number

  chapter: Chapter

  getCasts (): {} {
    return {
      id: 'number',
      amount: 'number',
      chapterId: 'number',
    }
  }

  setChapter (c: {}) {
    return new Chapter().init(c)
  }
}
