import React from 'react'
import { observer } from 'mobx-react'
import { autorun, computed, observable } from 'mobx'
import Util, { createLazyResource, formatNumber, safeNull } from '../common/Util'
import ApiClient, { ApiRoutes } from '../api/ApiClient'
import { ManagedChooseableColumn } from './table-view/ManagedTableViewColumnChooser'
import { Moment } from 'moment-timezone/moment-timezone'
import Application, { ApplicationType } from '../models/Application'
import { Link } from 'react-router-dom'
import { route } from '../routes/routes'
import { Routes } from '../routes/AppRoutes'
import MemberCell from './cells/MemberCell'
import { can, Permission } from './RequirePermission'
import AuthStore from '../stores/AuthStore'
import { Button } from 'reactstrap'
import ManagedTableView from './table-view/ManagedTableView'
import AppStateStore from '../stores/AppStateStore'
import { ApiTableViewAdapter } from './table-view/ApiTableViewAdapter'
import RequireRole from './RequireRole'
import { UserRole } from '../models/AuthUser'
import SelectFilter, { SelectFilterTargetType } from './table-view/filters/SelectFilter'
import DateFilter from './table-view/filters/DateFilter'
import AsyncSelectFilter, { AsyncSelectFilterTargetType } from './table-view/filters/AsyncSelectFilter'
import { loadCategoryOptions, loadMemberOptions } from '../api/AsyncHelpers'

type Props = {
  onBadgeCountChanged?: (count: number) => void
}

@observer
export default class MembershipApplicationsList extends React.Component<Props> {
  @observable private numberOfApplications = createLazyResource<number>(() => {
    return ApiClient.query(`applications { * }`, { returnTotal: true, limit: 0, where: [{ _scope: 'inQueue' }, { _scope: 'notArchived' }] })
  }, response => response.data._meta.total);

  componentDidMount (): void {
    autorun(() => {
      this.props.onBadgeCountChanged && this.props.onBadgeCountChanged(this.numberOfApplications.current || 0)
    })
  }

  @computed
  private get availableColumns (): ManagedChooseableColumn[] {
    const columns: ManagedChooseableColumn[] = [
      {
        id: 'receivedDate',
        column: {
          title: 'Received Date',
          accessor: 'submittedAt',
          renderItem: (value: Moment) => value ? value.format('MM/DD/YYYY') : '---',
          sortable: true,
        },
        fixed: true,
      },
      {
        id: 'sentDate',
        column: {
          title: 'Sent Date',
          accessor: 'createdAt',
          renderItem: (value: Moment) => value.format('MM/DD/YYYY'),
          sortable: true,
        },
        default: true,
      },
      {
        id: 'status',
        column: {
          title: 'Status',
          accessor: 'status',
          sortable: true,
          renderItem: (status: string, application: Application) => application.getStatusString()
        },
        default: true,
      },
      {
        id: 'applicant',
        column: {
          title: 'Applicant',
          sortable: true,
          sortKey: 'custom:applicant',
          renderItem: (value, item: Application) => {
            let url: any = route(Routes.system.applications.show, { id: item.id })

            if (item.applicationType === ApplicationType.NewMember && item.status === 'Pending') {
              url = { pathname: route(Routes.system.guests.show, { id: item.guest.id }), state: { id: item.guest.id } }
            }

            return item.applicationType == ApplicationType.ChapterTransfer
              ? <>
                <div><Link type="link" to={url}>{item.member.fullName}</Link></div>
                <div>{safeNull(() => item.member.category!.name)}</div>
                <div>{safeNull(() => item.member.business!.name)}</div>
              </>
              : <>
                <div><Link type="link" to={url}>{item.guest.fullName}</Link></div>
                <div>{safeNull(() => item.guest.category!.name)}</div>
                <div>{item.guest.companyName}</div>
              </>
          },
        },
        fixed: true,
      },
      {
        id: 'category',
        column: {
          title: 'Category',
          renderItem: (value, item: Application) => safeNull(() => item.category.name)
        },
        default: false,
      },
      {
        id: 'chapter',
        column: {
          accessor: 'targetChapter.name',
          title: 'Chapter',
          renderItem: (value, item: Application) => <Link to={route(Routes.system.chapters.show, { id: item.targetChapter.id })}>{item.targetChapter.name}</Link>,
          sortable: true,
        },
        default: true,
      },
      {
        id: 'invitedBy',
        column: {
          accessor: 'sponsor.full_name',
          title: 'Invited By',
          renderItem: (value, item: Application) => item.sponsor ? <MemberCell member={item.sponsor}/> : '---',
          sortable: true,
        },
        default: true,
      },
    ]

    if (can(Permission.DownloadApplicationPdf)) {
      columns.push({
        id: 'applicationPdf',
        column: {
          accessor: 'applicationPdfUrl',
          title: 'PDF',
          renderItem: (url: string) => url ? <a href={`${url}?token=${AuthStore.generateTempAuthToken()}`} target="_blank">Download</a> : '---'
        },
        default: true,
      })
    }

    if (can(Permission.ArchiveApplication)) {
      columns.push({
        id: 'archiveApplication',
        column: {
          title: 'Actions',
          renderItem: (_, application: Application) => (!application.archivedAt && can(Permission.ArchiveMemberApplication, { chapterId: application.targetChapterId })) ?
            <Button type="button" color="primary" title="Archive Application" onClick={() => this.archiveApplication(application)}><i className="fa fa-archive"/></Button> : null,
        },
        fixed: true,
      })
    }

    return columns
  }

  private tableViewRef = React.createRef<ManagedTableView>()

  private archiveApplication = (application: Application) => {
    AppStateStore.showConfirmationModal('Archive Application', <>
      <p>Archiving applications removes them from this list, but they can still be viewed later if necessary by clicking on the “Show Archived” filter below the “Find an Application” search bar.</p>
      <p>Are you sure you want to archive this application?</p>
    </>, (result, modal) => {
      if (result) {
        AppStateStore.showModalSpinner()

        ApiClient.getInstance().post(route(ApiRoutes.applications.archive, { id: application.id }))
          .then(() => {
            this.tableViewRef.current && this.tableViewRef.current.fetchData()
            this.numberOfApplications.invalidate().then()
          })
          .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
          .then(() => AppStateStore.dismissModalSpinner())
      }

      modal.hide()
    })
  }

  private applicationsAdapter = new ApiTableViewAdapter(() => {
      const where = [{ _scope: 'inQueue' }]

      if (!safeNull(() => this.tableViewRef.current!.tableViewFilters!.showInactive)) {
        where.push({ _scope: 'notArchived' })
      }

      return {
        query: `
    applications {
      id
      createdAt
      viewedAt
      submittedAt
      archivedAt
      status
      applicationType
      applicationPdfUrl
      
      targetChapter {
        id
        name
      }
      
      sponsor {
        id
        fullName
        profileImageUrl

        category {
          name
        }
      }

      guest {
        id
        fullName
        companyName
        
        category {
          name
        }
      }
            
      member {
        id
        fullName
        profileImageUrl

        chapter {
          id
          name
        }

        category {
          name
        }

        business {
          name
        }
      }
    }`,
        where: where,
      }
    },
    'applications',
    Application
  )

  private renderTableViewHeader = () => {
    return <RequireRole role={UserRole.Admin}>
      {() =>
        <>
          <div className="list-view-info-icon">
            <i className="flaticon-suitcase"/>
          </div>
          <div className="list-view-info-text">
            <span>{this.numberOfApplications.loading ? <i className="fa fa-spinner fa-spin"/> : formatNumber(this.numberOfApplications.current!)}</span> Applications
          </div>
        </>
      }
    </RequireRole>
  }

  private filters = [
    new SelectFilter('status', SelectFilterTargetType.Field, 'status', 'Status', [
      'Pending',
      'Submitted',
      'Approved',
      'Denied',
    ].map(s => ({ value: s, label: s }))),
    new DateFilter('createdAt', 'createdAt', 'Date Sent'),
    new AsyncSelectFilter('invitedBy', AsyncSelectFilterTargetType.Field, 'sponsorId', 'Invited By', loadMemberOptions()),
    new AsyncSelectFilter('category', AsyncSelectFilterTargetType.Field, 'guest.category_id', 'Category', loadCategoryOptions()),
  ]

  render (): React.ReactNode {
    return <ManagedTableView
      ref={this.tableViewRef}
      stateKey="ApplicationsView"
      searchLabel="Find An Application"
      availableColumns={this.availableColumns}
      adapter={this.applicationsAdapter}
      header={this.renderTableViewHeader()}
      filters={this.filters}
      allowShowInactive={true}
      showInactiveLabel="Show Archived"
    />
  }
}
