import * as React from "react"
import { SyntheticEvent } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { computed, observable } from "mobx"
import { observer } from "mobx-react"
import Util, { formatCurrency } from "../common/Util"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { route } from "../routes/routes"
import { toast } from "react-toastify"
import AppStateStore from "../stores/AppStateStore"
import ButtonLoader from "./ButtonLoader"
import ErrorBag from "../common/ErrorBag"
import FormError from "./FormError"
import { EventBusContext } from "../common/EventBus"

const uuid4 = require('uuid/v4')

type Props = {
  memberId?: number
  chapterId?: number
  invoiceId?: number
  isOpen: boolean
  toggle: () => void
  onClosed: () => void
}

type InvoiceLineItem = {
  uuid: string
  description: string
  amount: string
}

@observer
export default class EditInvoiceModal extends React.Component<Props> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  @observable submitting = false
  @observable private invoiceDescription = ''
  @observable private lineItems: InvoiceLineItem[] = [{
    uuid: uuid4(),
    description: '',
    amount: '',
  }]

  @observable private formErrors = new ErrorBag(false)

  private toggle = () => {
    if (!this.submitting) {
      this.props.toggle()
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    const items = {}
    this.lineItems.forEach(lineItem => {
      items[lineItem.uuid] = {
        description: lineItem.description,
        amount: lineItem.amount,
      }
    })

    this.submitting = true
    this.formErrors.clearErrors()

    ApiClient.getInstance()
      .post(route(ApiRoutes.invoices.store), {
        member_id: this.props.memberId,
        chapter_id: this.props.chapterId,
        invoice_description: this.invoiceDescription,
        line_items: items,
      })
      .then(() => {
        toast.success('Invoice created')
        this.context.eventBus.dispatch('invoice-invalidated')
        this.props.toggle()
      })
      .catch(error => Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      }))
      .then(() => {
        this.submitting = false
      })
  }

  private addLineItem = () => {
    this.lineItems.push({
      uuid: uuid4(),
      description: '',
      amount: '',
    })
  }

  private removeLineItem = (lineItem: InvoiceLineItem) => {
    this.lineItems = this.lineItems.filter(li => li.uuid !== lineItem.uuid)
  }

  @computed
  private get invoiceTotal () {
    return this.lineItems.filter(li => !isNaN(Number(li.amount))).reduce((acc, lineItem) => acc + Number(lineItem.amount), 0)
  }

  render () {
    return <Modal isOpen={this.props.isOpen} toggle={this.toggle} onClosed={this.props.onClosed} size="lg">
      <ModalHeader toggle={this.toggle}>
        Invoice
      </ModalHeader>
      <ModalBody>
        <form onSubmit={this.submit}>
          <div className="row">
            <div className="col-12">
              <label>Invoice Description</label>
              <input
                type="text"
                className="form-control"
                value={this.invoiceDescription}
                onChange={ev => this.invoiceDescription = ev.target.value}
              />
              <FormError errors={this.formErrors} fieldName="invoice_description"/>
            </div>
          </div>
          <table className="table table-condensed table-striped">
            <thead>
            <tr>
              <th>Line Item Description</th>
              <th className="text-right">Amount</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {
              this.lineItems.map(lineItem => <tr key={lineItem.uuid}>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={lineItem.description}
                    onChange={ev => lineItem.description = ev.target.value}
                  />
                  <FormError errors={this.formErrors} fieldName={`line_items.${lineItem.uuid}.description`}/>
                </td>
                <td style={{ width: 200 }}>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={lineItem.amount}
                      onChange={ev => {
                        const clean = ev.target.value.replace(/[$,]/g, '')
                        if (clean.match(/^\d*(\.\d?\d?)?$/)) {
                          lineItem.amount = clean
                        }
                      }}
                    />
                  </div>
                  <FormError errors={this.formErrors} fieldName={`line_items.${lineItem.uuid}.amount`}/>
                </td>
                <td style={{ width: 30, verticalAlign: 'middle' }}>
                  <a href="" onClick={ev => {
                    ev.preventDefault()
                    this.removeLineItem(lineItem)
                  }} className="text-danger"><i className="fa fa-trash"/></a>
                </td>
              </tr>)
            }
            </tbody>
            {
              this.lineItems.length > 0
                ? <tfoot>
                <tr>
                  <td colSpan={3} className="text-right">
                    <b>Total: {formatCurrency(this.invoiceTotal, true)}</b>
                  </td>
                </tr>
                </tfoot>
                : null
            }
          </table>
          <FormError errors={this.formErrors} fieldName="line_items"/>
          <div className="form-buttons">
            <Button
              type="button"
              color="primary"
              onClick={this.addLineItem}
            >Add Line Item</Button>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="secondary"
          onClick={this.toggle}
        >Cancel</Button>
        <ButtonLoader
          loading={this.submitting}
          type="button"
          color="success"
          onClick={this.submit}
        >Create Invoice</ButtonLoader>
      </ModalFooter>
    </Modal>
  }
}