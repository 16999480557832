import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import FormState from "../../../common/FormState"
import { observable } from "mobx"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"
import { EventBusContext } from "../../../common/EventBus"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import { route } from "../../../routes/routes"
import AppStateStore from "../../../stores/AppStateStore"
import Util, { modelToSnakeCase } from "../../../common/Util"
import { toast } from "react-toastify"
import ButtonLoader from "../../ButtonLoader"

type Props = {
  membershipRenewalId: number
  isOpen: boolean
  toggle: () => void
  onClosed: () => void
}

@observer
export default class AddRenewalExemptionModal extends React.Component<Props> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  @observable private formState = new FormState({
    endsAt: '',
    note: '',
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable private submitting = false

  protected submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitting = true
    this.formErrors.clearErrors()

    ApiClient.getInstance().post(route(ApiRoutes.membershipRenewals.addExemption, { membershipRenewal: this.props.membershipRenewalId }), modelToSnakeCase(this.formHelper.toObject()))
      .then(response => {
        toast.success('Exemption added')
        this.context.eventBus.dispatch('member-invalidated')
        this.props.toggle()
      })
      .catch(error => {
        Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })
      })
      .then(() => this.submitting = false)
  }

  render () {
    return <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} onClosed={this.props.onClosed}>
      <ModalHeader toggle={this.props.toggle}>
        Add Renewal Exemption
      </ModalHeader>
      <ModalBody>
        <form onSubmit={this.submit}>
          <div className="form-row">
            <div className="col-12">
              {this.formHelper.renderDatePickerInput({
                name: 'endsAt',
                label: 'End Date',
              })}
            </div>
          </div>
          <div className="form-row">
            <div className="col-12">
              {this.formHelper.renderTextAreaInput({
                name: 'note',
                label: 'Notes',
              })}
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <ButtonLoader
          loading={this.submitting}
          type="button"
          color="primary"
          onClick={this.submit}
        >Add Exemption</ButtonLoader>
        <Button
          type="button"
          color="secondary"
          onClick={this.props.toggle}
        >Cancel</Button>
      </ModalFooter>
    </Modal>
  }
}