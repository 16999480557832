import * as React from "react"
import ApiClient, { QueryWhereClause } from "../api/ApiClient"
import { observer } from "mobx-react"
import { computed, observable } from "mobx"
import { Button } from "reactstrap"
import Config from "../common/Config"
import classNames from 'classnames'
import Member from "../models/Member"
import { Link } from "react-router-dom"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import TableView, { SortDescriptor } from "./table-view/TableView"
import { ApiTableViewAdapter } from "./table-view/ApiTableViewAdapter"
import { TableViewPagination } from "./table-view/TableViewPagination"
import { TableViewFilters } from "./table-view/TableViewFilters"
import { Moment } from "moment-timezone/moment-timezone"
import { logException, normalizeUrl } from "../common/Util"
import TableViewExportButton from "./table-view/TableViewExportButton"
import SearchableMemberListCsvTransformer from "../models/renderers/SearchableMemberListCsvTransformer"
import Guest from '../models/Guest'
import BankCode from './BankCode'
import { ManagedChooseableColumn, ManagedTableViewColumnChooser } from './table-view/ManagedTableViewColumnChooser'
import _ from 'lodash';

export enum MemberListColumn {
  Name = 'name',
  Company = 'company',
  Chapter = 'chapter',
  JoinDate = 'joinDate',
  Category = 'category',
  Position = 'position',
  BadgeColor = 'badgeColor',
  Status = 'status',
  Actions = 'actions',
  PhoneNumber = 'phoneNumber',
  EmailAddress = 'emailAddress',
  Website = 'website',
  NTS = 'nts',
  BankCode = 'bankCode',
  MembershipType = 'membershipType',
}

export enum SearchableMemberListContext {
  Network,
  System,
}

type Props = {
  hideActions: boolean
  onChoose: (member: Member) => void
  filters?: SearchableMemberListFilterType
  style: {}
  columns: MemberListColumn[]
  additionalColumns?: MemberListColumn[]
  allowClickableColumns: boolean
  showColumnChooser?: boolean
  allowExport?: boolean
  allowEntireNetwork?: boolean
  actionsComponent: (row: SearchableMemberListRowType, onChoose: (member: Member) => void) => React.ReactNode
  linkToNetworkPage?: boolean
  tableClassName?: string
  defaultSort?: SortDescriptor
  context: SearchableMemberListContext
  stateKey?: string
}

export type SearchableMemberListFilterType = {
  chapter?: number
  showInactive?: boolean
  allowEntireNetwork?: boolean
  ignoreMemberId?: number
}

type TableViewState = {
  columns: string[]
  page: number
  search?: string
  showEntireNetwork?: boolean
  sortDescriptor?: SortDescriptor
}

interface MyComponentState {
  data: any[];
  loading: boolean;
  error: string | null;
}
@observer
export default class SearchableMemberList extends React.Component<Props, MyComponentState> {
  static defaultProps = {
    hideActions: false,
    allowEntireNetwork: true,
    allowClickableColumns: true,
    onChoose: () => {
    },
    style: {},
    columns: [
      MemberListColumn.Name,
      MemberListColumn.Chapter,
      MemberListColumn.Category,
      MemberListColumn.Status,
      MemberListColumn.Actions,
    ],
    actionsComponent: (row: SearchableMemberListRowType, onChoose: (member: Member) => void) => <ActionsComponent row={row} onChoose={onChoose}/>,
    createMemberUrl: (member: Member) => route(Routes.system.members.show, { id: member.id }),
    context: SearchableMemberListContext.System,
  }

  @observable private numberOfPages: number = 0
  @observable private currentPage: number = 1
  @observable private showEntireNetwork = false
  @observable private filters: {search:string, filters:any[]} = {search: '', filters:[]}
  @observable private _sortDescriptor?: SortDescriptor
  private tableViewRef = React.createRef<TableView>()

  get stateKey () {
    return `SearchableMemberListState_${this.props.stateKey}`
  }

  constructor(props:any){
    super(props);
      this.state = {
       data: [] ,
       loading: true,
       error: null
      };
  }

  private memberPositions :any;

  componentDidMount () {
    this.restoreState()
    this.fetchData()
  }

  fetchData = async () => {
    try{
    ApiClient.chapters.getMemberPositions(this.props.filters!.chapter!)
      .then(response => {
        if (response.data.member_positions && response.data.member_positions.length > 0) {
          const sorted = response.data.member_positions.sort((a:any, b:any) => {
            const orderA = a.position ? a.position.display_order : Infinity
            const orderB = b.position ? b.position.display_order : Infinity
            return orderA - orderB
          });
          this.memberPositions = sorted;
          this.setState({data: this.memberPositions , loading:false});
        }
        else{
          this.memberPositions = []
          this.setState({data: [] , loading:false});
        }
      })
      .catch(error => <div>{error}</div>)
    }
    catch(err){
      this.setState({data: [] , loading:false});
    }  
  }

  private setPage = (page: number) => {
    this.currentPage = page
    this.persistState()
  }

  private membersAdapter = new ApiTableViewAdapter(() => {
    const where: QueryWhereClause[] = []

    if (this.props.filters && this.props.filters.chapter && !this.showEntireNetwork) {
      where.push({
        _scope: 'chapter',
        value: this.props.filters.chapter,
      })
    }

    if (this.props.filters && this.props.filters.ignoreMemberId) {
      where.push({
        id: 'id',
        op: '!=',
        value: this.props.filters.ignoreMemberId,
      })
    }

    if (!this.props.filters || !this.props.filters.showInactive) {
      where.push({ _scope: 'active' })
    }

    return {
      query: `
      members {
        *
        
        chapter {
          *
        }
        
        business {
          *
        }
        
        category {
          *
        }
        
        primaryMemberPosition {
          *
          
          position {
            *
          }
        }
      }
    `,
      where: where,
    }
  }, 'members', Member, {
    onFetched: result => {
      this.numberOfPages = Math.ceil(result.total / Config.TABLE_VIEW_PAGE_SIZE)
      if (this.currentPage < 1) {
        this.currentPage = 1
      }
      if (this.currentPage > this.numberOfPages) {
        this.currentPage = this.numberOfPages
      }
    }
  })

  refreshTest = () => {
    this.fetchData();
    console.log('refresh called-')
  }
 
  @computed
  private get availableColumns () {
    const columns: ManagedChooseableColumn[] = []

    const avail = this.props.columns.filter(c => c !== MemberListColumn.Actions)

    avail.forEach(col => {
      const c = _.find(this.allColumns, c => c.id === col)
      if (c) {
        columns.push({
          id: c.id,
          column: c.column,
          default: true,
        })
      }
    })

    if (this.props.additionalColumns) {
      this.props.additionalColumns.forEach(col => {
        const c = _.find(this.allColumns, c => c.id === col)
        if (c) {
          columns.push({
            id: c.id,
            column: c.column,
            default: false,
          })
        }
      })
    }

    if (!this.props.hideActions) {
      const c = _.find(this.allColumns, c => c.id === MemberListColumn.Actions)

      if (c) {
        columns.push({
          id: c.id,
          column: c.column,
          fixed: true,
        })
      }
    }

    return columns
  }

  @observable private visibleColumns: ManagedChooseableColumn[] = ManagedTableViewColumnChooser.getInitialColumns(this.availableColumns)

  private allColumns: ManagedChooseableColumn[] = [
    {
      id: MemberListColumn.Name,
      column: {
        title: 'Member',
        accessor: 'fullName',
        sortKey: 'lastName',
        renderItem: (value: string, item: Member) => this.props.allowClickableColumns
          ? <Link type="link" to={this.props.context === SearchableMemberListContext.System ? route(Routes.system.members.show, { id: item.id }) : route(Routes.network.member, { memberId: item.id })}>
            <div className={classNames({ 'text-muted': item.isDisabled() })}>
              {value}{item.isDisabled() && ` (${item.status})`}
            </div>
          </Link>
          : <div className={classNames({ 'text-muted': item.isDisabled() })}>
            {value}{item.isDisabled() && ` (${item.status})`}
          </div>,
        sortable: true,
      },
    },
    {
      id:MemberListColumn.Chapter,
      column: {
        title: 'Chapter',
        accessor: 'chapter.name',
        sortable: true,
        renderItem: (value: string, item: Member) => item.chapter
          ? this.props.allowClickableColumns
            ? <Link type="link" to={this.props.context === SearchableMemberListContext.System ? route(Routes.system.chapters.show, { id: item.id }) : route(Routes.network.chapter, { chapterId: item.chapterId })}>
              {value}
            </Link>
            : value
          : null
      },
    },
    {
      id: MemberListColumn.Category,
      column: {
        title: 'Category',
        accessor: 'category.name',
        sortable: true,
      },
    },
    {
      id: MemberListColumn.Company,
      column: {
      title: 'Company',
      accessor: 'business.name',
      sortable: true,
    },
    },
    {
      id: MemberListColumn.JoinDate,
      column: {
        title: 'Join Date',
        accessor: 'joinDate',
        renderItem: (value: Moment) => value ? value.format('MM/DD/YYYY') : '---',
        sortable: true,
      }
    },
    {
  id: MemberListColumn.PhoneNumber,
  column: {
    title: 'Phone Number',
    accessor: 'phoneNumber',
    sortable: true,
  },
    },
    {
  id: MemberListColumn.EmailAddress,
    column: {
      title: 'Email',
      accessor: 'emailAddress',
      sortable: true,
      }
    },
    {
      id: MemberListColumn.Website,
      column: {
        title: 'Website',
        accessor: 'website_url',
        renderItem: (value: string | null) => value ? <a href={normalizeUrl(value)} target="_blank">{value}</a> : '---',
        sortable: true,
      },
    },
    {
      id: MemberListColumn.Position,
      column: {
        title: 'Position',
        accessor: 'primaryMemberPosition.position.name',
        sortable: true,
      },
    },
    {
      id: MemberListColumn.BadgeColor,
      column: {
        title: 'Badge Color',
        accessor: 'badgeColor',
        sortable: true,
      },
    },
    {
      id: MemberListColumn.Status,
      column: {
        title: 'Status',
        accessor: 'status',
        sortable: true,
      },
    },
    {
      id: MemberListColumn.NTS,
      column: {
        title: 'NTS',
        accessor: 'ntsTrainingCompletedAt',
        headerClassName: 'justify-content-center',
        renderItem: (value: Moment | undefined, item: Member) => value ? 'Yes' : 'No',
        sortable: true,
      }
    },
    {
      id: MemberListColumn.BankCode,
      column: {
        title: 'BANKCODE',
        accessor: 'bankCode',
        sortable: true,
        renderItem: (bankCode: string, item: Guest) => bankCode ? <BankCode bankCode={bankCode} documentUrl={item.bankCodeDocumentUrl!}/> : null,
      },
    },
    {
      id: MemberListColumn.MembershipType,
      column: {
        title: 'Membership Type',
        accessor: 'membershipType',
        sortable: true,
      },
    },
    {
      id: MemberListColumn.Status,
      column: {
      title: 'Status',
      accessor: 'status',
      sortable: true,
    },
    },
    {
      id: MemberListColumn.Actions,
      column: {
        title: 'Actions',
        headerClassName: 'justify-content-center',
        renderItem: (value: any, item: Member) => this.props.actionsComponent({ original: item }, this.onChoose),
      },
    },
  ]

  private onChoose = (member: Member) => {
    this.props.onChoose(member)
  }

  private persistState = () => {
    const state: TableViewState = {
      columns: this.visibleColumns.map(c => c.id),
      page: this.currentPage,
      search: this.filters ? this.filters.search : undefined,
      showEntireNetwork: this.showEntireNetwork,
      sortDescriptor: this._sortDescriptor,
    }

    try {
      localStorage.setItem(this.stateKey, JSON.stringify(state))
    } catch (e) {
      logException(e)
    }
  }

  private restoreState = () => {
    const stateJson = localStorage.getItem(this.stateKey)
    console.log('state', stateJson)
    if (stateJson) {
      try {
        const state: TableViewState = JSON.parse(stateJson)

        if (state) {
          const selectedColumns: string[] = _.union(
            this.availableColumns.filter(c => c.fixed).map(c => c.id),
            state.columns
          )

          this.visibleColumns = this.availableColumns.filter(c => selectedColumns.indexOf(c.id) > -1)

          this.currentPage = state.page

          this.filters = {
            search: state.search || '',
            filters: [],
          }

          this.showEntireNetwork = this.props.allowEntireNetwork ? (state.showEntireNetwork || false) : false

          this._sortDescriptor = state.sortDescriptor
        }
      } catch (e) {
        logException(e)
      }
    }
  }

  render (): React.ReactNode {
    const { loading } = this.state;
    if (loading) {
      return <div>Loading...</div>;
    }
    return <>
      <div className="d-flex">
        <div className="flex-fill">
          <TableViewFilters
            initialState={{ searchText: this.filters.search || '' }}
            searchLabel="Find A Member"
            onChanged={filters => {
              this.filters = { search: filters.search, filters: filters.filters.map(f => f.getWhereClause()) }
              this.persistState()
            }}
          />
          {
            this.props.allowEntireNetwork
            ? <div className="list-view-search-inactive form-check form-checkbox">
                <label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={this.showEntireNetwork}
                    onChange={ev => {
                      this.showEntireNetwork = ev.target.checked
                      this.persistState()
                      this.tableViewRef.current!.fetchData()
                    }}
                  />
                  <span className="label-text">Show Entire Network</span>
                </label>
              </div>
              : null
          }
        </div>
        <div>
          {
            this.props.showColumnChooser
              ? <ManagedTableViewColumnChooser
                availableColumns={this.availableColumns}
                visibleColumns={this.visibleColumns.map(c => c.id)}
                onChange={columns => {
                  this.visibleColumns = columns
                  this.persistState()
                }}
              />
              : null
          }
          &nbsp;
          {
            this.props.allowExport
              ? <TableViewExportButton
                tableViewRef={this.tableViewRef}
                transformerClass={() => new SearchableMemberListCsvTransformer}
              />
              : null
          }
        </div>
      </div>
      <TableView
        defaultSort={this._sortDescriptor || this.props.defaultSort || { sortKey: 'lastName' }}
        onSortChanged={sortDescriptor => {
          this._sortDescriptor = sortDescriptor
          this.persistState()
        }}
        hideItemCount={true}
        ref={this.tableViewRef}
        adapter={this.membersAdapter}
        columns={this.visibleColumns.map(c => c.column)}
        tableClassName={this.props.tableClassName}
        pagination={{
          offset: Math.max((this.currentPage - 1), 0) * Config.TABLE_VIEW_PAGE_SIZE,
          limit: Config.TABLE_VIEW_PAGE_SIZE,
        }}
        filters={this.filters}
        memberPosition = {this.memberPositions}
        refreshTest={this.refreshTest}
      />
      {
        this.numberOfPages > 1
          ? <TableViewPagination numberOfPages={this.numberOfPages} currentPage={this.currentPage} setPage={page => this.setPage(page)}/>
          : null
      }
    </>
  }
}

export type SearchableMemberListRowType = {
  original: Member
}

type ActionsComponentProps = {
  row: SearchableMemberListRowType
  onChoose: (member: Member) => void
}

class ActionsComponent extends React.Component<ActionsComponentProps> {
  static defaultProps = {
    onChoose: () => {
    }
  }

  render (): React.ReactNode {
    return <div className="text-right">
      <Button
        type="button"
        color="primary"
        size="sm"
        onClick={() => this.props.onChoose(this.props.row.original)}
      >choose</Button>
    </div>
  }
}
