import * as React from "react"
import AsyncSelect from 'react-select/lib/Async'
import { observer } from "mobx-react"
import { observable } from "mobx"

export type SelectOption = {
  value: any
  label: string
}

export type LoadOptionsMethod = (inputValue: string, callback: LoadOptionsCallback) => void
export type LoadOptionsCallback = (options: SelectOption[]) => void
export type OnChangeMethod = (option: SelectOption) => void

type SelectInputProps = {
  loadOptions: LoadOptionsMethod
  onChange: OnChangeMethod
  className?: string
  classNamePrefix?: string
  defaultValue?: SelectOption | SelectOption[]
  minimumSearchCharacters?: number
  isMulti: boolean
  isDisabled: boolean
}

@observer
export default class SelectInput extends React.Component<SelectInputProps> {
  static defaultProps = {
    minimumSearchCharacters: 0,
    isMulti: false,
    isDisabled: false,
  }

  private currentInputValue: string = ''

  @observable showMinimumSearch = false

  componentWillMount (): void {
    this.updateShowMinimumSearch()
  }

  private updateShowMinimumSearch = () => {
    this.showMinimumSearch = !!(this.props.minimumSearchCharacters && this.props.minimumSearchCharacters > 0 && this.currentInputValue.length < this.props.minimumSearchCharacters)
  }

  onInputChange = (newValue: string) => {
    this.currentInputValue = newValue
    this.updateShowMinimumSearch()
    return newValue
  }

  loadOptions = (inputValue: string, callback: LoadOptionsCallback) => {
    if (this.showMinimumSearch) {
      callback([])
    } else {
      this.props.loadOptions(inputValue, callback)
    }
  }

  private noOptionsMessage = (props: any) => {
    return <div className="minimum-search-characters-message">{`Type at least ${this.props.minimumSearchCharacters} characters...`}</div>
  }

  render (): React.ReactNode {
    return (
      <AsyncSelect
        isMulti={this.props.isMulti}
        cacheOptions
        defaultOptions
        defaultValue={this.props.defaultValue}
        loadOptions={this.loadOptions}
        // @ts-ignore
        onChange={this.props.onChange}
        className={this.props.className}
        classNamePrefix={this.props.classNamePrefix}
        components={this.showMinimumSearch ? { NoOptionsMessage: this.noOptionsMessage } : {}}
        onInputChange={this.onInputChange}
        isClearable={true}
        escapeClearsValue={true}
        isDisabled={this.props.isDisabled}
      />
    )
  }
}