import BaseView from "../BaseView"
import * as React from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import { RouteComponentProps } from "react-router"
import { createBrowserHistory } from "history"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { Nav, NavItem, NavLink, TabContent } from "reactstrap"
import classNames from "classnames"
import LazyTabPane from "../../components/LazyTabPane"
import AuthStore from "../../stores/AuthStore"
import ChapterTipList from "../../components/ChapterTipList"
import EscrowTipList from "../../components/EscrowTipList"
import { can, Permission } from '../../components/RequirePermission'
import { safeNull } from '../../common/Util'

const TABS = {
  memberTips: 'member-tips',
  escrowTips: 'escrow-tips',
}

type Props = {
  match: {
    params: {
      tab?: string
    }
  }
} & RouteComponentProps

@observer
export default class ChapterTipsView extends BaseView<Props> {
  @observable private error?: string
  @observable private loading = false

  @observable private searchFormState = new FormState({
    categoryId: undefined,
  })
  @observable private searchFormErrors = new ErrorBag()

  @observable
  private activeTab = TABS.memberTips

  private history = createBrowserHistory()

  private setTab (tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(route(Routes.chapter.tips, { tab: this.activeTab }))
    }
  }

  componentDidMount (): void {
    super.componentDidMount()

    this.activeTab = this.props.match.params.tab || TABS.memberTips
  }

  renderContentHeader (): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <h1>Chapter Tips</h1>
  }

  render (): React.ReactNode | null {
    return <>
      <div className="card">
        <div className="card-header">
          <Nav tabs className="card-header-tabs">
            <NavItem className={classNames({ active: this.activeTab === TABS.memberTips })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.memberTips)
                }}
              >Member Tips</NavLink>
            </NavItem>
            <NavItem className={classNames({ active: this.activeTab === TABS.escrowTips })}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.escrowTips)
                }}
              >Escrow Tips</NavLink>
            </NavItem>
          </Nav>
        </div>

        <div className="card-body">
          <TabContent activeTab={this.activeTab}>
            <LazyTabPane tabId={TABS.memberTips} activeTab={this.activeTab}>
              <ChapterTipList
                allowExport={can(Permission.ExportChapterTips, { chapterId: safeNull(() => AuthStore.getUser()!.chapter!.id) })}
                allowPrint={can(Permission.ExportChapterTips, { chapterId: safeNull(() => AuthStore.getUser()!.chapter!.id) })}
                chapterId={AuthStore.getUser()!.chapter.id}
              />
            </LazyTabPane>
            <LazyTabPane tabId={TABS.escrowTips} activeTab={this.activeTab}>
              <EscrowTipList
                chapterId={AuthStore.getUser()!.chapter.id}
              />
            </LazyTabPane>
          </TabContent>
        </div>
      </div>
    </>
  }
}
