import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import FormHelper from "../../forms/FormHelper"
import AppStateStore from "../../stores/AppStateStore"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import { toast } from "react-toastify"
import { submitFormRequest } from "../../api/ApiHelper"
import { route } from "../../routes/routes"
import { modelToSnakeCase, safeNull } from "../../common/Util"
import ButtonLoader from "../ButtonLoader"
import moment from 'moment-timezone'
import AuthStore from '../../stores/AuthStore'
import RequirePermission, { Permission } from '../RequirePermission'
import { MemberPickerInput } from '../inputs/MemberPickerInput'
import { UserRole } from '../../models/AuthUser'
import * as _ from 'lodash'

type CreateTipBatchModalProps = {
  isOpen: boolean
  toggle: () => void
  onSaved: () => void
  onClosed?: () => void
}

@observer
export class CreateTipBatchModal extends React.Component<CreateTipBatchModalProps> {
  private initialFormState = {
    memberId: safeNull(() => AuthStore.getUser()!.member!.id),
    date: moment().format('MM/DD/YYYY'),
    value: '',
    note: '',
  }

  @observable private formState = new FormState(this.initialFormState)

  @observable private formErrors = new ErrorBag()
  @observable private submitting = false

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()

    this.submitting = true
    AppStateStore.showModalSpinner()

    submitFormRequest(
      ApiClient.getInstance()
        .post(route(ApiRoutes.tips.batches.store), modelToSnakeCase(this.formState.toObject())),
      this.formState,
      this.formErrors,
      () => {
        toast.success('Tip Batch Created')
        this.props.onSaved()
      }
    )
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private toggle = () => {
    !this.submitting && this.props.toggle()
  }

  render (): React.ReactNode {
    const { isOpen } = this.props

    return <Modal isOpen={isOpen} toggle={this.toggle} size="md" onClosed={this.props.onClosed}>
      <ModalHeader toggle={this.toggle}>
        Create Tip Batch
      </ModalHeader>
      <ModalBody>
        <form onSubmit={this.submit}>
          <RequirePermission permission={Permission.SendTipAsMember} renderIfDenied={false}>
            {() => <div className="form-row">
              <div className="col-12">
                <label>Member</label>
                <MemberPickerInput
                  filters={AuthStore.getUser()!.hasAnyRole([UserRole.Admin, UserRole.Employee]) ? {} : { chapter: AuthStore.getUser()!.chapter.id }}
                  placeholder="Choose Member"
                  onChange={member => this.formState.set('memberId', _.get(member, 'id'))}
                  value={this.formState.get('memberId')}
                  allowClear={false}
                />
              </div>
            </div>}
          </RequirePermission>
          <div className="form-row">
            <div className="col-md-6">
              {
                this.formHelper.renderDatePickerInput({
                  label: 'Batch Date',
                  name: 'date',
                  disabled: this.submitting,
                })
              }
            </div>
            <div className="col-md-6">
              {
                this.formHelper.renderTextInput({
                  label: 'Value',
                  name: 'value',
                  prepend: '$',
                  disabled: this.submitting,
                })
              }
            </div>
          </div>
          <div className="form-row">
            <div className="col-12">
              {
                this.formHelper.renderTextAreaInput({
                  label: 'Notes',
                  name: 'note',
                  disabled: this.submitting,
                })
              }
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={this.submitting} onClick={this.toggle}>Cancel</Button>
        <ButtonLoader type="button" color="primary" loading={this.submitting} onClick={this.submit}>Create Batch</ButtonLoader>
      </ModalFooter>
    </Modal>
  }
}
