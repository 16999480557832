import * as React from "react"
import { observer } from "mobx-react"
import TipCardList, { TipCardListProps } from "./TipCardList"
import { computed, observable, toJS } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import * as AsyncHelpers from "../api/AsyncHelpers"
import moment from 'moment-timezone'
import { formatNumber } from "../common/Util"
import pluralize from 'pluralize'
import { LoadOptionsMethod } from "./inputs/SelectInput"

@observer
export default class ChapterTipList extends React.Component<{
  chapterId: number
  allowExport?: boolean
  allowPrint?: boolean
}> {
  static defaultProps = {
    allowExport: true,
    allowPrint: true,
  }

  @observable private formState = new FormState({
    startDate: '',
    endDate: '',
    status: [],
    tipDirection: 'all',
    memberId: undefined,
    privacy: [],
    tipType: [],
  })

  @observable private formErrors = new ErrorBag()
  private formHelper = new FormHelper(this.formState, this.formErrors)

  @computed get tipListProps (): TipCardListProps {
    const props: TipCardListProps = {
      chapterId: this.props.chapterId,
    }

    if (this.formState.get('startDate').trim()) {
      props.startDate = moment(this.formState.get('startDate'))
    }

    if (this.formState.get('endDate').trim()) {
      props.endDate = moment(this.formState.get('endDate'))
    }

    if (this.formState.get('memberId')) {
      if (this.formState.get('tipDirection') === 'sent') {
        props.sourceMemberId = this.formState.get('memberId')
      } else if (this.formState.get('tipDirection') === 'received') {
        props.targetMemberId = this.formState.get('memberId')
      } else {
        props.memberId = this.formState.get('memberId')
      }
    } else {
      if (this.formState.get('tipDirection') === 'sent') {
        props.sourceChapterId = this.props.chapterId
      } else if (this.formState.get('tipDirection') === 'received') {
        props.targetChapterId = this.props.chapterId
      }
    }

    if (this.formState.get('privacy').length) {
      props.privacy = toJS(this.formState.get('privacy'))
    }

    if (this.formState.get('status').length) {
      props.status = toJS(this.formState.get('status'))
    }

    if (this.formState.get('tipType').length) {
      props.tipType = toJS(this.formState.get('tipType'))
    }

    return props
  }

  private loadStatusOptions: LoadOptionsMethod = (searchString, callback) => {
    callback([
      'New',
      'Bogus',
      'Invalid',
      'Closed',
      'Working',
    ].filter(o => o.toLowerCase().indexOf(searchString.trim().toLowerCase()) > -1).map(o => ({
      value: o,
      label: o,
    })))
  }

  private loadPrivacyOptions: LoadOptionsMethod = (searchString, callback) => {
    callback([
      'Private',
      'Normal',
    ].filter(o => o.toLowerCase().indexOf(searchString.trim().toLowerCase()) > -1).map(o => ({
      value: o,
      label: o,
    })))
  }

  private loadTipTypeOptions: LoadOptionsMethod = (searchString, callback) => {
    callback([
      'Inside',
      'Outside',
      'Inter-Chapter',
      'Social-Media'
    ].filter(o => o.toLowerCase().indexOf(searchString.trim().toLowerCase()) > -1).map(o => ({
      value: o,
      label: o,
    })))
  }

  @observable private tipCardListRef = React.createRef<TipCardList>()

  render (): React.ReactNode {
    return <>
      <div className="row">
        <div className="col-4">
          {this.formHelper.renderDatePickerInput({
            label: 'Start Date',
            name: 'startDate',
          })}
        </div>
        <div className="col-4">
          {this.formHelper.renderDatePickerInput({
            label: 'End Date',
            name: 'endDate',
          })}
        </div>
        <div className="col-4">
          {this.formHelper.renderAsyncSelectInput({
            label: 'Status',
            name: 'status',
            isMulti: true,
            loadOptions: this.loadStatusOptions,
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          {this.formHelper.renderSelectInput({
            label: 'Sent/Recv',
            name: 'tipDirection',
            options: [
              { value: 'all', text: 'All Tips' },
              { value: 'sent', text: 'Sent Tips' },
              { value: 'received', text: 'Received Tips' },
            ]
          })}
        </div>
        <div className="col-4">
          {this.formHelper.renderAsyncSelectInput({
            name: 'memberId',
            label: 'Member',
            loadOptions: AsyncHelpers.loadMemberOptions(this.props.chapterId),
          })}
        </div>
        <div className="col-4">
          {this.formHelper.renderAsyncSelectInput({
            label: 'Show Private Tips',
            name: 'privacy',
            isMulti: true,
            loadOptions: this.loadPrivacyOptions,
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          {this.formHelper.renderAsyncSelectInput({
            label: 'Tip Type',
            name: 'tipType',
            isMulti: true,
            loadOptions: this.loadTipTypeOptions,
          })}
        </div>
        <div className="col-4">
        </div>
        <div className="col-4">
        </div>
      </div>
      {this.tipCardListRef.current && this.tipCardListRef.current.totalRecords
        ? <div>
          <h5 className="text-muted text-center">{formatNumber(this.tipCardListRef.current.totalRecords)} matching {pluralize('tip', this.tipCardListRef.current.totalRecords)}</h5>
        </div>
        : null}
      <TipCardList
        allowExport={this.props.allowExport}
        allowPrint={this.props.allowPrint}
        ref={this.tipCardListRef}
        {...this.tipListProps}
      />
    </>
  }
}
