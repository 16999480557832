import * as React from "react"
import Config from "../common/Config"
import { Redirect } from "react-router"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import AuthStore from "../stores/AuthStore"

export default class HomeView extends React.Component {
  private getRedirectUrl = () => {
    return route(AuthStore.getUser()!.isMember() ? Routes.member.dashboard : Routes.member.settings)
  }

  public render () {
    return <>
      {Config.NODE_ENV === 'development'
        ? <div className="d-flex">
          <div className="d-flex flex-column">
            <div><i className="flaticon-email-black-circular-button"/> flaticon-email-black-circular-button</div>
            <div><i className="flaticon-map"/> flaticon-map</div>
            <div><i className="flaticon-alert-off"/> flaticon-alert-off</div>
            <div><i className="flaticon-alert"/> flaticon-alert</div>
            <div><i className="flaticon-computer"/> flaticon-computer</div>
            <div><i className="flaticon-responsive"/> flaticon-responsive</div>
            <div><i className="flaticon-presentation"/> flaticon-presentation</div>
            <div><i className="flaticon-arrows"/> flaticon-arrows</div>
            <div><i className="flaticon-rocket"/> flaticon-rocket</div>
            <div><i className="flaticon-reply"/> flaticon-reply</div>
            <div><i className="flaticon-gift"/> flaticon-gift</div>
            <div><i className="flaticon-confetti"/> flaticon-confetti</div>
            <div><i className="flaticon-piggy-bank"/> flaticon-piggy-bank</div>
            <div><i className="flaticon-support"/> flaticon-support</div>
            <div><i className="flaticon-delete"/> flaticon-delete</div>
            <div><i className="flaticon-eye"/> flaticon-eye</div>
            <div><i className="flaticon-multimedia"/> flaticon-multimedia</div>
            <div><i className="flaticon-whatsapp"/> flaticon-whatsapp</div>
            <div><i className="flaticon-multimedia-2"/> flaticon-multimedia-2</div>
            <div><i className="flaticon-email"/> flaticon-email</div>
            <div><i className="flaticon-presentation-1"/> flaticon-presentation-1</div>
            <div><i className="flaticon-trophy"/> flaticon-trophy</div>
            <div><i className="flaticon-psd"/> flaticon-psd</div>
            <div><i className="flaticon-layer"/> flaticon-layer</div>
            <div><i className="flaticon-doc"/> flaticon-doc</div>
            <div><i className="flaticon-file"/> flaticon-file</div>
            <div><i className="flaticon-network"/> flaticon-network</div>
            <div><i className="flaticon-bus-stop"/> flaticon-bus-stop</div>
            <div><i className="flaticon-globe"/> flaticon-globe</div>
            <div><i className="flaticon-upload"/> flaticon-upload</div>
            <div><i className="flaticon-squares"/> flaticon-squares</div>
            <div><i className="flaticon-technology"/> flaticon-technology</div>
            <div><i className="flaticon-up-arrow"/> flaticon-up-arrow</div>
            <div><i className="flaticon-browser"/> flaticon-browser</div>
            <div><i className="flaticon-speech-bubble"/> flaticon-speech-bubble</div>
            <div><i className="flaticon-coins"/> flaticon-coins</div>
            <div><i className="flaticon-open-box"/> flaticon-open-box</div>
            <div><i className="flaticon-speech-bubble-1"/> flaticon-speech-bubble-1</div>
            <div><i className="flaticon-attachment"/> flaticon-attachment</div>
            <div><i className="flaticon-photo-camera"/> flaticon-photo-camera</div>
            <div><i className="flaticon-skype-logo"/> flaticon-skype-logo</div>
            <div><i className="flaticon-linkedin-logo"/> flaticon-linkedin-logo</div>
            <div><i className="flaticon-twitter-logo"/> flaticon-twitter-logo</div>
            <div><i className="flaticon-facebook-letter-logo"/> flaticon-facebook-letter-logo</div>
            <div><i className="flaticon-calendar-with-a-clock-time-tools"/> flaticon-calendar-with-a-clock-time-tools</div>
            <div><i className="flaticon-youtube"/> flaticon-youtube</div>
            <div><i className="flaticon-add-circular-button"/> flaticon-add-circular-button</div>
            <div><i className="flaticon-more-v2"/> flaticon-more-v2</div>
            <div><i className="flaticon-search"/> flaticon-search</div>
            <div><i className="flaticon-search-magnifier-interface-symbol"/> flaticon-search-magnifier-interface-symbol</div>
            <div><i className="flaticon-questions-circular-button"/> flaticon-questions-circular-button</div>
            <div><i className="flaticon-refresh"/> flaticon-refresh</div>
            <div><i className="flaticon-logout"/> flaticon-logout</div>
            <div><i className="flaticon-event-calendar-symbol"/> flaticon-event-calendar-symbol</div>
            <div><i className="flaticon-laptop"/> flaticon-laptop</div>
            <div><i className="flaticon-tool"/> flaticon-tool</div>
            <div><i className="flaticon-graphic"/> flaticon-graphic</div>
            <div><i className="flaticon-symbol"/> flaticon-symbol</div>
            <div><i className="flaticon-graphic-1"/> flaticon-graphic-1</div>
            <div><i className="flaticon-clock"/> flaticon-clock</div>
            <div><i className="flaticon-squares-1"/> flaticon-squares-1</div>
            <div><i className="flaticon-black"/> flaticon-black</div>
            <div><i className="flaticon-book"/> flaticon-book</div>
            <div><i className="flaticon-cogwheel"/> flaticon-cogwheel</div>
            <div><i className="flaticon-exclamation"/> flaticon-exclamation</div>
            <div><i className="flaticon-add-label-button"/> flaticon-add-label-button</div>
            <div><i className="flaticon-delete-1"/> flaticon-delete-1</div>
            <div><i className="flaticon-interface"/> flaticon-interface</div>
            <div><i className="flaticon-more"/> flaticon-more</div>
            <div><i className="flaticon-warning-sign"/> flaticon-warning-sign</div>
            <div><i className="flaticon-calendar"/> flaticon-calendar</div>
            <div><i className="flaticon-instagram-logo"/> flaticon-instagram-logo</div>
            <div><i className="flaticon-linkedin"/> flaticon-linkedin</div>
            <div><i className="flaticon-facebook-logo-button"/> flaticon-facebook-logo-button</div>
            <div><i className="flaticon-twitter-logo-button"/> flaticon-twitter-logo-button</div>
            <div><i className="flaticon-cancel"/> flaticon-cancel</div>
            <div><i className="flaticon-exclamation-square"/> flaticon-exclamation-square</div>
            <div><i className="flaticon-buildings"/> flaticon-buildings</div>
            <div><i className="flaticon-danger"/> flaticon-danger</div>
            <div><i className="flaticon-technology-1"/> flaticon-technology-1</div>
            <div><i className="flaticon-letter-g"/> flaticon-letter-g</div>
            <div><i className="flaticon-interface-1"/> flaticon-interface-1</div>
            <div><i className="flaticon-circle"/> flaticon-circle</div>
            <div><i className="flaticon-pin"/> flaticon-pin</div>
            <div><i className="flaticon-close"/> flaticon-close</div>
            <div><i className="flaticon-clock-1"/> flaticon-clock-1</div>
            <div><i className="flaticon-apps"/> flaticon-apps</div>
            <div><i className="flaticon-user"/> flaticon-user</div>
            <div><i className="flaticon-menu-button"/> flaticon-menu-button</div>
            <div><i className="flaticon-settings"/> flaticon-settings</div>
            <div><i className="flaticon-home"/> flaticon-home</div>
            <div><i className="flaticon-clock-2"/> flaticon-clock-2</div>
            <div><i className="flaticon-lifebuoy"/> flaticon-lifebuoy</div>
            <div><i className="flaticon-cogwheel-1"/> flaticon-cogwheel-1</div>
            <div><i className="flaticon-paper-plane"/> flaticon-paper-plane</div>
            <div><i className="flaticon-statistics"/> flaticon-statistics</div>
            <div><i className="flaticon-diagram"/> flaticon-diagram</div>
            <div><i className="flaticon-line-graph"/> flaticon-line-graph</div>
            <div><i className="flaticon-customer"/> flaticon-customer</div>
            <div><i className="flaticon-visible"/> flaticon-visible</div>
            <div><i className="flaticon-shopping-basket"/> flaticon-shopping-basket</div>
            <div><i className="flaticon-price-tag"/> flaticon-price-tag</div>
            <div><i className="flaticon-businesswoman"/> flaticon-businesswoman</div>
            <div><i className="flaticon-medal"/> flaticon-medal</div>
            <div><i className="flaticon-like"/> flaticon-like</div>
            <div><i className="flaticon-edit"/> flaticon-edit</div>
            <div><i className="flaticon-avatar"/> flaticon-avatar</div>
            <div><i className="flaticon-download"/> flaticon-download</div>
            <div><i className="flaticon-home-1"/> flaticon-home-1</div>
            <div><i className="flaticon-mail"/> flaticon-mail</div>
            <div><i className="flaticon-mail-1"/> flaticon-mail-1</div>
            <div><i className="flaticon-warning"/> flaticon-warning</div>
            <div><i className="flaticon-cart"/> flaticon-cart</div>
            <div><i className="flaticon-bag"/> flaticon-bag</div>
            <div><i className="flaticon-pie-chart"/> flaticon-pie-chart</div>
            <div><i className="flaticon-graph"/> flaticon-graph</div>
            <div><i className="flaticon-interface-2"/> flaticon-interface-2</div>
            <div><i className="flaticon-chat"/> flaticon-chat</div>
            <div><i className="flaticon-envelope"/> flaticon-envelope</div>
            <div><i className="flaticon-chat-1"/> flaticon-chat-1</div>
            <div><i className="flaticon-interface-3"/> flaticon-interface-3</div>
            <div><i className="flaticon-background"/> flaticon-background</div>
            <div><i className="flaticon-file-1"/> flaticon-file-1</div>
            <div><i className="flaticon-interface-4"/> flaticon-interface-4</div>
            <div><i className="flaticon-multimedia-3"/> flaticon-multimedia-3</div>
            <div><i className="flaticon-list"/> flaticon-list</div>
            <div><i className="flaticon-time"/> flaticon-time</div>
            <div><i className="flaticon-profile"/> flaticon-profile</div>
            <div><i className="flaticon-imac"/> flaticon-imac</div>
            <div><i className="flaticon-medical"/> flaticon-medical</div>
            <div><i className="flaticon-music"/> flaticon-music</div>
            <div><i className="flaticon-plus"/> flaticon-plus</div>
            <div><i className="flaticon-exclamation-1"/> flaticon-exclamation-1</div>
            <div><i className="flaticon-info"/> flaticon-info</div>
            <div><i className="flaticon-menu-1"/> flaticon-menu-1</div>
            <div><i className="flaticon-menu-2"/> flaticon-menu-2</div>
            <div><i className="flaticon-share"/> flaticon-share</div>
            <div><i className="flaticon-interface-5"/> flaticon-interface-5</div>
            <div><i className="flaticon-signs"/> flaticon-signs</div>
            <div><i className="flaticon-tabs"/> flaticon-tabs</div>
            <div><i className="flaticon-multimedia-4"/> flaticon-multimedia-4</div>
            <div><i className="flaticon-upload-1"/> flaticon-upload-1</div>
            <div><i className="flaticon-web"/> flaticon-web</div>
            <div><i className="flaticon-placeholder"/> flaticon-placeholder</div>
            <div><i className="flaticon-placeholder-1"/> flaticon-placeholder-1</div>
            <div><i className="flaticon-layers"/> flaticon-layers</div>
            <div><i className="flaticon-interface-6"/> flaticon-interface-6</div>
            <div><i className="flaticon-interface-7"/> flaticon-interface-7</div>
            <div><i className="flaticon-interface-8"/> flaticon-interface-8</div>
            <div><i className="flaticon-tool-1"/> flaticon-tool-1</div>
            <div><i className="flaticon-settings-1"/> flaticon-settings-1</div>
            <div><i className="flaticon-alarm"/> flaticon-alarm</div>
            <div><i className="flaticon-search-1"/> flaticon-search-1</div>
            <div><i className="flaticon-time-1"/> flaticon-time-1</div>
            <div><i className="flaticon-stopwatch"/> flaticon-stopwatch</div>
            <div><i className="flaticon-folder"/> flaticon-folder</div>
            <div><i className="flaticon-folder-1"/> flaticon-folder-1</div>
            <div><i className="flaticon-folder-2"/> flaticon-folder-2</div>
            <div><i className="flaticon-folder-3"/> flaticon-folder-3</div>
            <div><i className="flaticon-file-2"/> flaticon-file-2</div>
            <div><i className="flaticon-list-1"/> flaticon-list-1</div>
            <div><i className="flaticon-list-2"/> flaticon-list-2</div>
            <div><i className="flaticon-calendar-1"/> flaticon-calendar-1</div>
            <div><i className="flaticon-time-2"/> flaticon-time-2</div>
            <div><i className="flaticon-interface-9"/> flaticon-interface-9</div>
            <div><i className="flaticon-app"/> flaticon-app</div>
            <div><i className="flaticon-suitcase"/> flaticon-suitcase</div>
            <div><i className="flaticon-grid-menu-v2"/> flaticon-grid-menu-v2</div>
            <div><i className="flaticon-more-v6"/> flaticon-more-v6</div>
            <div><i className="flaticon-more-v5"/> flaticon-more-v5</div>
            <div><i className="flaticon-add"/> flaticon-add</div>
            <div><i className="flaticon-multimedia-5"/> flaticon-multimedia-5</div>
            <div><i className="flaticon-more-v4"/> flaticon-more-v4</div>
            <div><i className="flaticon-placeholder-2"/> flaticon-placeholder-2</div>
            <div><i className="flaticon-map-location"/> flaticon-map-location</div>
            <div><i className="flaticon-users"/> flaticon-users</div>
            <div><i className="flaticon-profile-1"/> flaticon-profile-1</div>
            <div><i className="flaticon-lock"/> flaticon-lock</div>
            <div><i className="flaticon-sound"/> flaticon-sound</div>
            <div><i className="flaticon-star"/> flaticon-star</div>
            <div><i className="flaticon-placeholder-3"/> flaticon-placeholder-3</div>
            <div><i className="flaticon-bell"/> flaticon-bell</div>
            <div><i className="flaticon-paper-plane-1"/> flaticon-paper-plane-1</div>
            <div><i className="flaticon-users-1"/> flaticon-users-1</div>
            <div><i className="flaticon-more-1"/> flaticon-more-1</div>
            <div><i className="flaticon-up-arrow-1"/> flaticon-up-arrow-1</div>
            <div><i className="flaticon-grid-menu"/> flaticon-grid-menu</div>
            <div><i className="flaticon-alarm-1"/> flaticon-alarm-1</div>
            <div><i className="flaticon-earth-globe"/> flaticon-earth-globe</div>
            <div><i className="flaticon-alert-1"/> flaticon-alert-1</div>
            <div><i className="flaticon-internet"/> flaticon-internet</div>
            <div><i className="flaticon-user-ok"/> flaticon-user-ok</div>
            <div><i className="flaticon-user-add"/> flaticon-user-add</div>
            <div><i className="flaticon-user-settings"/> flaticon-user-settings</div>
            <div><i className="flaticon-truck"/> flaticon-truck</div>
            <div><i className="flaticon-analytics"/> flaticon-analytics</div>
            <div><i className="flaticon-notes"/> flaticon-notes</div>
            <div><i className="flaticon-tea-cup"/> flaticon-tea-cup</div>
            <div><i className="flaticon-exclamation-2"/> flaticon-exclamation-2</div>
            <div><i className="flaticon-technology-2"/> flaticon-technology-2</div>
            <div><i className="flaticon-location"/> flaticon-location</div>
            <div><i className="flaticon-edit-1"/> flaticon-edit-1</div>
            <div><i className="flaticon-home-2"/> flaticon-home-2</div>
            <div><i className="flaticon-dashboard"/> flaticon-dashboard</div>
            <div><i className="flaticon-information"/> flaticon-information</div>
            <div><i className="flaticon-light"/> flaticon-light</div>
            <div><i className="flaticon-car"/> flaticon-car</div>
            <div><i className="flaticon-business"/> flaticon-business</div>
            <div><i className="flaticon-squares-2"/> flaticon-squares-2</div>
            <div><i className="flaticon-signs-1"/> flaticon-signs-1</div>
            <div><i className="flaticon-mark"/> flaticon-mark</div>
            <div><i className="flaticon-squares-3"/> flaticon-squares-3</div>
            <div><i className="flaticon-comment"/> flaticon-comment</div>
            <div><i className="flaticon-shapes"/> flaticon-shapes</div>
            <div><i className="flaticon-clipboard"/> flaticon-clipboard</div>
            <div><i className="flaticon-squares-4"/> flaticon-squares-4</div>
            <div><i className="flaticon-delete-2"/> flaticon-delete-2</div>
            <div><i className="flaticon-bell-1"/> flaticon-bell-1</div>
            <div><i className="flaticon-list-3"/> flaticon-list-3</div>
            <div><i className="flaticon-infinity"/> flaticon-infinity</div>
            <div><i className="flaticon-chat-2"/> flaticon-chat-2</div>
            <div><i className="flaticon-calendar-2"/> flaticon-calendar-2</div>
            <div><i className="flaticon-signs-2"/> flaticon-signs-2</div>
            <div><i className="flaticon-time-3"/> flaticon-time-3</div>
            <div><i className="flaticon-calendar-3"/> flaticon-calendar-3</div>
            <div><i className="flaticon-interface-10"/> flaticon-interface-10</div>
            <div><i className="flaticon-interface-11"/> flaticon-interface-11</div>
            <div><i className="flaticon-folder-4"/> flaticon-folder-4</div>
            <div><i className="flaticon-alert-2"/> flaticon-alert-2</div>
            <div><i className="flaticon-cogwheel-2"/> flaticon-cogwheel-2</div>
            <div><i className="flaticon-graphic-2"/> flaticon-graphic-2</div>
            <div><i className="flaticon-rotate"/> flaticon-rotate</div>
            <div><i className="flaticon-feed"/> flaticon-feed</div>
            <div><i className="flaticon-safe-shield-protection"/> flaticon-safe-shield-protection</div>
            <div><i className="flaticon-box"/> flaticon-box</div>
            <div><i className="flaticon-security"/> flaticon-security</div>
            <div><i className="flaticon-download-1"/> flaticon-download-1</div>
            <div><i className="flaticon-box-1"/> flaticon-box-1</div>
            <div><i className="flaticon-pie-chart-1"/> flaticon-pie-chart-1</div>
            <div><i className="flaticon-notepad"/> flaticon-notepad</div>
          </div>
          <div className="flex-column">
            <div><i className="fa fa-500px"/> fa fa-500px</div>
            <div><i className="fa fa-adjust"/> fa fa-adjust</div>
            <div><i className="fa fa-adn"/> fa fa-adn</div>
            <div><i className="fa fa-align-center"/> fa fa-align-center</div>
            <div><i className="fa fa-align-justify"/> fa fa-align-justify</div>
            <div><i className="fa fa-align-left"/> fa fa-align-left</div>
            <div><i className="fa fa-align-right"/> fa fa-align-right</div>
            <div><i className="fa fa-amazon"/> fa fa-amazon</div>
            <div><i className="fa fa-ambulance"/> fa fa-ambulance</div>
            <div><i className="fa fa-anchor"/> fa fa-anchor</div>
            <div><i className="fa fa-android"/> fa fa-android</div>
            <div><i className="fa fa-angellist"/> fa fa-angellist</div>
            <div><i className="fa fa-angle-double-down"/> fa fa-angle-double-down</div>
            <div><i className="fa fa-angle-double-left"/> fa fa-angle-double-left</div>
            <div><i className="fa fa-angle-double-right"/> fa fa-angle-double-right</div>
            <div><i className="fa fa-angle-double-up"/> fa fa-angle-double-up</div>
            <div><i className="fa fa-angle-down"/> fa fa-angle-down</div>
            <div><i className="fa fa-angle-left"/> fa fa-angle-left</div>
            <div><i className="fa fa-angle-right"/> fa fa-angle-right</div>
            <div><i className="fa fa-angle-up"/> fa fa-angle-up</div>
            <div><i className="fa fa-apple"/> fa fa-apple</div>
            <div><i className="fa fa-archive"/> fa fa-archive</div>
            <div><i className="fa fa-area-chart"/> fa fa-area-chart</div>
            <div><i className="fa fa-arrow-circle-down"/> fa fa-arrow-circle-down</div>
            <div><i className="fa fa-arrow-circle-left"/> fa fa-arrow-circle-left</div>
            <div><i className="fa fa-arrow-circle-o-down"/> fa fa-arrow-circle-o-down</div>
            <div><i className="fa fa-arrow-circle-o-left"/> fa fa-arrow-circle-o-left</div>
            <div><i className="fa fa-arrow-circle-o-right"/> fa fa-arrow-circle-o-right</div>
            <div><i className="fa fa-arrow-circle-o-up"/> fa fa-arrow-circle-o-up</div>
            <div><i className="fa fa-arrow-circle-right"/> fa fa-arrow-circle-right</div>
            <div><i className="fa fa-arrow-circle-up"/> fa fa-arrow-circle-up</div>
            <div><i className="fa fa-arrow-down"/> fa fa-arrow-down</div>
            <div><i className="fa fa-arrow-left"/> fa fa-arrow-left</div>
            <div><i className="fa fa-arrow-right"/> fa fa-arrow-right</div>
            <div><i className="fa fa-arrow-up"/> fa fa-arrow-up</div>
            <div><i className="fa fa-arrows"/> fa fa-arrows</div>
            <div><i className="fa fa-arrows-alt"/> fa fa-arrows-alt</div>
            <div><i className="fa fa-arrows-h"/> fa fa-arrows-h</div>
            <div><i className="fa fa-arrows-v"/> fa fa-arrows-v</div>
            <div><i className="fa fa-asterisk"/> fa fa-asterisk</div>
            <div><i className="fa fa-at"/> fa fa-at</div>
            <div><i className="fa fa-automobile"/> fa fa-automobile</div>
            <div><i className="fa fa-backward"/> fa fa-backward</div>
            <div><i className="fa fa-balance-scale"/> fa fa-balance-scale</div>
            <div><i className="fa fa-ban"/> fa fa-ban</div>
            <div><i className="fa fa-bank"/> fa fa-bank</div>
            <div><i className="fa fa-bar-chart"/> fa fa-bar-chart</div>
            <div><i className="fa fa-bar-chart-o"/> fa fa-bar-chart-o</div>
            <div><i className="fa fa-barcode"/> fa fa-barcode</div>
            <div><i className="fa fa-bars"/> fa fa-bars</div>
            <div><i className="fa fa-battery-0"/> fa fa-battery-0</div>
            <div><i className="fa fa-battery-1"/> fa fa-battery-1</div>
            <div><i className="fa fa-battery-2"/> fa fa-battery-2</div>
            <div><i className="fa fa-battery-3"/> fa fa-battery-3</div>
            <div><i className="fa fa-battery-4"/> fa fa-battery-4</div>
            <div><i className="fa fa-battery-empty"/> fa fa-battery-empty</div>
            <div><i className="fa fa-battery-full"/> fa fa-battery-full</div>
            <div><i className="fa fa-battery-half"/> fa fa-battery-half</div>
            <div><i className="fa fa-battery-quarter"/> fa fa-battery-quarter</div>
            <div><i className="fa fa-battery-three-quarters"/> fa fa-battery-three-quarters</div>
            <div><i className="fa fa-bed"/> fa fa-bed</div>
            <div><i className="fa fa-beer"/> fa fa-beer</div>
            <div><i className="fa fa-behance"/> fa fa-behance</div>
            <div><i className="fa fa-behance-square"/> fa fa-behance-square</div>
            <div><i className="fa fa-bell"/> fa fa-bell</div>
            <div><i className="fa fa-bell-o"/> fa fa-bell-o</div>
            <div><i className="fa fa-bell-slash"/> fa fa-bell-slash</div>
            <div><i className="fa fa-bell-slash-o"/> fa fa-bell-slash-o</div>
            <div><i className="fa fa-bicycle"/> fa fa-bicycle</div>
            <div><i className="fa fa-binoculars"/> fa fa-binoculars</div>
            <div><i className="fa fa-birthday-cake"/> fa fa-birthday-cake</div>
            <div><i className="fa fa-bitbucket"/> fa fa-bitbucket</div>
            <div><i className="fa fa-bitbucket-square"/> fa fa-bitbucket-square</div>
            <div><i className="fa fa-bitcoin"/> fa fa-bitcoin</div>
            <div><i className="fa fa-black-tie"/> fa fa-black-tie</div>
            <div><i className="fa fa-bold"/> fa fa-bold</div>
            <div><i className="fa fa-bolt"/> fa fa-bolt</div>
            <div><i className="fa fa-bomb"/> fa fa-bomb</div>
            <div><i className="fa fa-book"/> fa fa-book</div>
            <div><i className="fa fa-bookmark"/> fa fa-bookmark</div>
            <div><i className="fa fa-bookmark-o"/> fa fa-bookmark-o</div>
            <div><i className="fa fa-briefcase"/> fa fa-briefcase</div>
            <div><i className="fa fa-btc"/> fa fa-btc</div>
            <div><i className="fa fa-bug"/> fa fa-bug</div>
            <div><i className="fa fa-building"/> fa fa-building</div>
            <div><i className="fa fa-building-o"/> fa fa-building-o</div>
            <div><i className="fa fa-bullhorn"/> fa fa-bullhorn</div>
            <div><i className="fa fa-bullseye"/> fa fa-bullseye</div>
            <div><i className="fa fa-bus"/> fa fa-bus</div>
            <div><i className="fa fa-buysellads"/> fa fa-buysellads</div>
            <div><i className="fa fa-cab"/> fa fa-cab</div>
            <div><i className="fa fa-calculator"/> fa fa-calculator</div>
            <div><i className="fa fa-calendar"/> fa fa-calendar</div>
            <div><i className="fa fa-calendar-check-o"/> fa fa-calendar-check-o</div>
            <div><i className="fa fa-calendar-minus-o"/> fa fa-calendar-minus-o</div>
            <div><i className="fa fa-calendar-o"/> fa fa-calendar-o</div>
            <div><i className="fa fa-calendar-plus-o"/> fa fa-calendar-plus-o</div>
            <div><i className="fa fa-calendar-times-o"/> fa fa-calendar-times-o</div>
            <div><i className="fa fa-camera"/> fa fa-camera</div>
            <div><i className="fa fa-camera-retro"/> fa fa-camera-retro</div>
            <div><i className="fa fa-car"/> fa fa-car</div>
            <div><i className="fa fa-caret-down"/> fa fa-caret-down</div>
            <div><i className="fa fa-caret-left"/> fa fa-caret-left</div>
            <div><i className="fa fa-caret-right"/> fa fa-caret-right</div>
            <div><i className="fa fa-caret-square-o-down"/> fa fa-caret-square-o-down</div>
            <div><i className="fa fa-caret-square-o-left"/> fa fa-caret-square-o-left</div>
            <div><i className="fa fa-caret-square-o-right"/> fa fa-caret-square-o-right</div>
            <div><i className="fa fa-caret-square-o-up"/> fa fa-caret-square-o-up</div>
            <div><i className="fa fa-caret-up"/> fa fa-caret-up</div>
            <div><i className="fa fa-cart-arrow-down"/> fa fa-cart-arrow-down</div>
            <div><i className="fa fa-cart-plus"/> fa fa-cart-plus</div>
            <div><i className="fa fa-cc"/> fa fa-cc</div>
            <div><i className="fa fa-cc-amex"/> fa fa-cc-amex</div>
            <div><i className="fa fa-cc-diners-club"/> fa fa-cc-diners-club</div>
            <div><i className="fa fa-cc-discover"/> fa fa-cc-discover</div>
            <div><i className="fa fa-cc-jcb"/> fa fa-cc-jcb</div>
            <div><i className="fa fa-cc-mastercard"/> fa fa-cc-mastercard</div>
            <div><i className="fa fa-cc-paypal"/> fa fa-cc-paypal</div>
            <div><i className="fa fa-cc-stripe"/> fa fa-cc-stripe</div>
            <div><i className="fa fa-cc-visa"/> fa fa-cc-visa</div>
            <div><i className="fa fa-certificate"/> fa fa-certificate</div>
            <div><i className="fa fa-chain"/> fa fa-chain</div>
            <div><i className="fa fa-chain-broken"/> fa fa-chain-broken</div>
            <div><i className="fa fa-check"/> fa fa-check</div>
            <div><i className="fa fa-check-circle"/> fa fa-check-circle</div>
            <div><i className="fa fa-check-circle-o"/> fa fa-check-circle-o</div>
            <div><i className="fa fa-check-square"/> fa fa-check-square</div>
            <div><i className="fa fa-check-square-o"/> fa fa-check-square-o</div>
            <div><i className="fa fa-chevron-circle-down"/> fa fa-chevron-circle-down</div>
            <div><i className="fa fa-chevron-circle-left"/> fa fa-chevron-circle-left</div>
            <div><i className="fa fa-chevron-circle-right"/> fa fa-chevron-circle-right</div>
            <div><i className="fa fa-chevron-circle-up"/> fa fa-chevron-circle-up</div>
            <div><i className="fa fa-chevron-down"/> fa fa-chevron-down</div>
            <div><i className="fa fa-chevron-left"/> fa fa-chevron-left</div>
            <div><i className="fa fa-chevron-right"/> fa fa-chevron-right</div>
            <div><i className="fa fa-chevron-up"/> fa fa-chevron-up</div>
            <div><i className="fa fa-child"/> fa fa-child</div>
            <div><i className="fa fa-chrome"/> fa fa-chrome</div>
            <div><i className="fa fa-circle"/> fa fa-circle</div>
            <div><i className="fa fa-circle-o"/> fa fa-circle-o</div>
            <div><i className="fa fa-circle-o-notch"/> fa fa-circle-o-notch</div>
            <div><i className="fa fa-circle-thin"/> fa fa-circle-thin</div>
            <div><i className="fa fa-clipboard"/> fa fa-clipboard</div>
            <div><i className="fa fa-clock-o"/> fa fa-clock-o</div>
            <div><i className="fa fa-clone"/> fa fa-clone</div>
            <div><i className="fa fa-close"/> fa fa-close</div>
            <div><i className="fa fa-cloud"/> fa fa-cloud</div>
            <div><i className="fa fa-cloud-download"/> fa fa-cloud-download</div>
            <div><i className="fa fa-cloud-upload"/> fa fa-cloud-upload</div>
            <div><i className="fa fa-cny"/> fa fa-cny</div>
            <div><i className="fa fa-code"/> fa fa-code</div>
            <div><i className="fa fa-code-fork"/> fa fa-code-fork</div>
            <div><i className="fa fa-codepen"/> fa fa-codepen</div>
            <div><i className="fa fa-coffee"/> fa fa-coffee</div>
            <div><i className="fa fa-cog"/> fa fa-cog</div>
            <div><i className="fa fa-cogs"/> fa fa-cogs</div>
            <div><i className="fa fa-columns"/> fa fa-columns</div>
            <div><i className="fa fa-comment"/> fa fa-comment</div>
            <div><i className="fa fa-comment-o"/> fa fa-comment-o</div>
            <div><i className="fa fa-commenting"/> fa fa-commenting</div>
            <div><i className="fa fa-commenting-o"/> fa fa-commenting-o</div>
            <div><i className="fa fa-comments"/> fa fa-comments</div>
            <div><i className="fa fa-comments-o"/> fa fa-comments-o</div>
            <div><i className="fa fa-compass"/> fa fa-compass</div>
            <div><i className="fa fa-compress"/> fa fa-compress</div>
            <div><i className="fa fa-connectdevelop"/> fa fa-connectdevelop</div>
            <div><i className="fa fa-contao"/> fa fa-contao</div>
            <div><i className="fa fa-copy"/> fa fa-copy</div>
            <div><i className="fa fa-copyright"/> fa fa-copyright</div>
            <div><i className="fa fa-creative-commons"/> fa fa-creative-commons</div>
            <div><i className="fa fa-credit-card"/> fa fa-credit-card</div>
            <div><i className="fa fa-crop"/> fa fa-crop</div>
            <div><i className="fa fa-crosshairs"/> fa fa-crosshairs</div>
            <div><i className="fa fa-css3"/> fa fa-css3</div>
            <div><i className="fa fa-cube"/> fa fa-cube</div>
            <div><i className="fa fa-cubes"/> fa fa-cubes</div>
            <div><i className="fa fa-cut"/> fa fa-cut</div>
            <div><i className="fa fa-cutlery"/> fa fa-cutlery</div>
            <div><i className="fa fa-dashboard"/> fa fa-dashboard</div>
            <div><i className="fa fa-dashcube"/> fa fa-dashcube</div>
            <div><i className="fa fa-database"/> fa fa-database</div>
            <div><i className="fa fa-dedent"/> fa fa-dedent</div>
            <div><i className="fa fa-delicious"/> fa fa-delicious</div>
            <div><i className="fa fa-desktop"/> fa fa-desktop</div>
            <div><i className="fa fa-deviantart"/> fa fa-deviantart</div>
            <div><i className="fa fa-diamond"/> fa fa-diamond</div>
            <div><i className="fa fa-digg"/> fa fa-digg</div>
            <div><i className="fa fa-dollar"/> fa fa-dollar</div>
            <div><i className="fa fa-dot-circle-o"/> fa fa-dot-circle-o</div>
            <div><i className="fa fa-download"/> fa fa-download</div>
            <div><i className="fa fa-dribbble"/> fa fa-dribbble</div>
            <div><i className="fa fa-dropbox"/> fa fa-dropbox</div>
            <div><i className="fa fa-drupal"/> fa fa-drupal</div>
            <div><i className="fa fa-edit"/> fa fa-edit</div>
            <div><i className="fa fa-eject"/> fa fa-eject</div>
            <div><i className="fa fa-ellipsis-h"/> fa fa-ellipsis-h</div>
            <div><i className="fa fa-ellipsis-v"/> fa fa-ellipsis-v</div>
            <div><i className="fa fa-empire"/> fa fa-empire</div>
            <div><i className="fa fa-envelope"/> fa fa-envelope</div>
            <div><i className="fa fa-envelope-o"/> fa fa-envelope-o</div>
            <div><i className="fa fa-envelope-square"/> fa fa-envelope-square</div>
            <div><i className="fa fa-eraser"/> fa fa-eraser</div>
            <div><i className="fa fa-eur"/> fa fa-eur</div>
            <div><i className="fa fa-euro"/> fa fa-euro</div>
            <div><i className="fa fa-exchange"/> fa fa-exchange</div>
            <div><i className="fa fa-exclamation"/> fa fa-exclamation</div>
            <div><i className="fa fa-exclamation-circle"/> fa fa-exclamation-circle</div>
            <div><i className="fa fa-exclamation-triangle"/> fa fa-exclamation-triangle</div>
            <div><i className="fa fa-expand"/> fa fa-expand</div>
            <div><i className="fa fa-expeditedssl"/> fa fa-expeditedssl</div>
            <div><i className="fa fa-external-link"/> fa fa-external-link</div>
            <div><i className="fa fa-external-link-square"/> fa fa-external-link-square</div>
            <div><i className="fa fa-eye"/> fa fa-eye</div>
            <div><i className="fa fa-eye-slash"/> fa fa-eye-slash</div>
            <div><i className="fa fa-eyedropper"/> fa fa-eyedropper</div>
            <div><i className="fa fa-facebook"/> fa fa-facebook</div>
            <div><i className="fa fa-facebook-official"/> fa fa-facebook-official</div>
            <div><i className="fa fa-facebook-square"/> fa fa-facebook-square</div>
            <div><i className="fa fa-fast-backward"/> fa fa-fast-backward</div>
            <div><i className="fa fa-fast-forward"/> fa fa-fast-forward</div>
            <div><i className="fa fa-fax"/> fa fa-fax</div>
            <div><i className="fa fa-female"/> fa fa-female</div>
            <div><i className="fa fa-fighter-jet"/> fa fa-fighter-jet</div>
            <div><i className="fa fa-file"/> fa fa-file</div>
            <div><i className="fa fa-file-archive-o"/> fa fa-file-archive-o</div>
            <div><i className="fa fa-file-audio-o"/> fa fa-file-audio-o</div>
            <div><i className="fa fa-file-code-o"/> fa fa-file-code-o</div>
            <div><i className="fa fa-file-excel-o"/> fa fa-file-excel-o</div>
            <div><i className="fa fa-file-image-o"/> fa fa-file-image-o</div>
            <div><i className="fa fa-file-movie-o"/> fa fa-file-movie-o</div>
            <div><i className="fa fa-file-o"/> fa fa-file-o</div>
            <div><i className="fa fa-file-pdf-o"/> fa fa-file-pdf-o</div>
            <div><i className="fa fa-file-photo-o"/> fa fa-file-photo-o</div>
            <div><i className="fa fa-file-picture-o"/> fa fa-file-picture-o</div>
            <div><i className="fa fa-file-powerpoint-o"/> fa fa-file-powerpoint-o</div>
            <div><i className="fa fa-file-sound-o"/> fa fa-file-sound-o</div>
            <div><i className="fa fa-file-text"/> fa fa-file-text</div>
            <div><i className="fa fa-file-text-o"/> fa fa-file-text-o</div>
            <div><i className="fa fa-file-video-o"/> fa fa-file-video-o</div>
            <div><i className="fa fa-file-word-o"/> fa fa-file-word-o</div>
            <div><i className="fa fa-file-zip-o"/> fa fa-file-zip-o</div>
            <div><i className="fa fa-files-o"/> fa fa-files-o</div>
            <div><i className="fa fa-film"/> fa fa-film</div>
            <div><i className="fa fa-filter"/> fa fa-filter</div>
            <div><i className="fa fa-fire"/> fa fa-fire</div>
            <div><i className="fa fa-fire-extinguisher"/> fa fa-fire-extinguisher</div>
            <div><i className="fa fa-firefox"/> fa fa-firefox</div>
            <div><i className="fa fa-flag"/> fa fa-flag</div>
            <div><i className="fa fa-flag-checkered"/> fa fa-flag-checkered</div>
            <div><i className="fa fa-flag-o"/> fa fa-flag-o</div>
            <div><i className="fa fa-flash"/> fa fa-flash</div>
            <div><i className="fa fa-flask"/> fa fa-flask</div>
            <div><i className="fa fa-flickr"/> fa fa-flickr</div>
            <div><i className="fa fa-floppy-o"/> fa fa-floppy-o</div>
            <div><i className="fa fa-folder"/> fa fa-folder</div>
            <div><i className="fa fa-folder-o"/> fa fa-folder-o</div>
            <div><i className="fa fa-folder-open"/> fa fa-folder-open</div>
            <div><i className="fa fa-folder-open-o"/> fa fa-folder-open-o</div>
            <div><i className="fa fa-font"/> fa fa-font</div>
            <div><i className="fa fa-fonticons"/> fa fa-fonticons</div>
            <div><i className="fa fa-forumbee"/> fa fa-forumbee</div>
            <div><i className="fa fa-forward"/> fa fa-forward</div>
            <div><i className="fa fa-foursquare"/> fa fa-foursquare</div>
            <div><i className="fa fa-frown-o"/> fa fa-frown-o</div>
            <div><i className="fa fa-futbol-o"/> fa fa-futbol-o</div>
            <div><i className="fa fa-gamepad"/> fa fa-gamepad</div>
            <div><i className="fa fa-gavel"/> fa fa-gavel</div>
            <div><i className="fa fa-gbp"/> fa fa-gbp</div>
            <div><i className="fa fa-gear"/> fa fa-gear</div>
            <div><i className="fa fa-gears"/> fa fa-gears</div>
            <div><i className="fa fa-genderless"/> fa fa-genderless</div>
            <div><i className="fa fa-get-pocket"/> fa fa-get-pocket</div>
            <div><i className="fa fa-gg"/> fa fa-gg</div>
            <div><i className="fa fa-gg-circle"/> fa fa-gg-circle</div>
            <div><i className="fa fa-gift"/> fa fa-gift</div>
            <div><i className="fa fa-git"/> fa fa-git</div>
            <div><i className="fa fa-git-square"/> fa fa-git-square</div>
            <div><i className="fa fa-github"/> fa fa-github</div>
            <div><i className="fa fa-github-alt"/> fa fa-github-alt</div>
            <div><i className="fa fa-github-square"/> fa fa-github-square</div>
            <div><i className="fa fa-glass"/> fa fa-glass</div>
            <div><i className="fa fa-globe"/> fa fa-globe</div>
            <div><i className="fa fa-google"/> fa fa-google</div>
            <div><i className="fa fa-google-plus"/> fa fa-google-plus</div>
            <div><i className="fa fa-google-plus-square"/> fa fa-google-plus-square</div>
            <div><i className="fa fa-google-wallet"/> fa fa-google-wallet</div>
            <div><i className="fa fa-graduation-cap"/> fa fa-graduation-cap</div>
            <div><i className="fa fa-gratipay"/> fa fa-gratipay</div>
            <div><i className="fa fa-group"/> fa fa-group</div>
            <div><i className="fa fa-h-square"/> fa fa-h-square</div>
            <div><i className="fa fa-hacker-news"/> fa fa-hacker-news</div>
            <div><i className="fa fa-hand-grab-o"/> fa fa-hand-grab-o</div>
            <div><i className="fa fa-hand-lizard-o"/> fa fa-hand-lizard-o</div>
            <div><i className="fa fa-hand-o-down"/> fa fa-hand-o-down</div>
            <div><i className="fa fa-hand-o-left"/> fa fa-hand-o-left</div>
            <div><i className="fa fa-hand-o-right"/> fa fa-hand-o-right</div>
            <div><i className="fa fa-hand-o-up"/> fa fa-hand-o-up</div>
            <div><i className="fa fa-hand-paper-o"/> fa fa-hand-paper-o</div>
            <div><i className="fa fa-hand-peace-o"/> fa fa-hand-peace-o</div>
            <div><i className="fa fa-hand-pointer-o"/> fa fa-hand-pointer-o</div>
            <div><i className="fa fa-hand-rock-o"/> fa fa-hand-rock-o</div>
            <div><i className="fa fa-hand-scissors-o"/> fa fa-hand-scissors-o</div>
            <div><i className="fa fa-hand-spock-o"/> fa fa-hand-spock-o</div>
            <div><i className="fa fa-hand-stop-o"/> fa fa-hand-stop-o</div>
            <div><i className="fa fa-hdd-o"/> fa fa-hdd-o</div>
            <div><i className="fa fa-header"/> fa fa-header</div>
            <div><i className="fa fa-headphones"/> fa fa-headphones</div>
            <div><i className="fa fa-heart"/> fa fa-heart</div>
            <div><i className="fa fa-heart-o"/> fa fa-heart-o</div>
            <div><i className="fa fa-heartbeat"/> fa fa-heartbeat</div>
            <div><i className="fa fa-history"/> fa fa-history</div>
            <div><i className="fa fa-home"/> fa fa-home</div>
            <div><i className="fa fa-hospital-o"/> fa fa-hospital-o</div>
            <div><i className="fa fa-hotel"/> fa fa-hotel</div>
            <div><i className="fa fa-hourglass"/> fa fa-hourglass</div>
            <div><i className="fa fa-hourglass-1"/> fa fa-hourglass-1</div>
            <div><i className="fa fa-hourglass-2"/> fa fa-hourglass-2</div>
            <div><i className="fa fa-hourglass-3"/> fa fa-hourglass-3</div>
            <div><i className="fa fa-hourglass-end"/> fa fa-hourglass-end</div>
            <div><i className="fa fa-hourglass-half"/> fa fa-hourglass-half</div>
            <div><i className="fa fa-hourglass-o"/> fa fa-hourglass-o</div>
            <div><i className="fa fa-hourglass-start"/> fa fa-hourglass-start</div>
            <div><i className="fa fa-houzz"/> fa fa-houzz</div>
            <div><i className="fa fa-html5"/> fa fa-html5</div>
            <div><i className="fa fa-i-cursor"/> fa fa-i-cursor</div>
            <div><i className="fa fa-ils"/> fa fa-ils</div>
            <div><i className="fa fa-image"/> fa fa-image</div>
            <div><i className="fa fa-inbox"/> fa fa-inbox</div>
            <div><i className="fa fa-indent"/> fa fa-indent</div>
            <div><i className="fa fa-industry"/> fa fa-industry</div>
            <div><i className="fa fa-info"/> fa fa-info</div>
            <div><i className="fa fa-info-circle"/> fa fa-info-circle</div>
            <div><i className="fa fa-inr"/> fa fa-inr</div>
            <div><i className="fa fa-instagram"/> fa fa-instagram</div>
            <div><i className="fa fa-institution"/> fa fa-institution</div>
            <div><i className="fa fa-internet-explorer"/> fa fa-internet-explorer</div>
            <div><i className="fa fa-ioxhost"/> fa fa-ioxhost</div>
            <div><i className="fa fa-italic"/> fa fa-italic</div>
            <div><i className="fa fa-joomla"/> fa fa-joomla</div>
            <div><i className="fa fa-jpy"/> fa fa-jpy</div>
            <div><i className="fa fa-jsfiddle"/> fa fa-jsfiddle</div>
            <div><i className="fa fa-key"/> fa fa-key</div>
            <div><i className="fa fa-keyboard-o"/> fa fa-keyboard-o</div>
            <div><i className="fa fa-krw"/> fa fa-krw</div>
            <div><i className="fa fa-language"/> fa fa-language</div>
            <div><i className="fa fa-laptop"/> fa fa-laptop</div>
            <div><i className="fa fa-lastfm"/> fa fa-lastfm</div>
            <div><i className="fa fa-lastfm-square"/> fa fa-lastfm-square</div>
            <div><i className="fa fa-leaf"/> fa fa-leaf</div>
            <div><i className="fa fa-leanpub"/> fa fa-leanpub</div>
            <div><i className="fa fa-legal"/> fa fa-legal</div>
            <div><i className="fa fa-lemon-o"/> fa fa-lemon-o</div>
            <div><i className="fa fa-level-down"/> fa fa-level-down</div>
            <div><i className="fa fa-level-up"/> fa fa-level-up</div>
            <div><i className="fa fa-life-bouy"/> fa fa-life-bouy</div>
            <div><i className="fa fa-life-buoy"/> fa fa-life-buoy</div>
            <div><i className="fa fa-life-ring"/> fa fa-life-ring</div>
            <div><i className="fa fa-life-saver"/> fa fa-life-saver</div>
            <div><i className="fa fa-lightbulb-o"/> fa fa-lightbulb-o</div>
            <div><i className="fa fa-line-chart"/> fa fa-line-chart</div>
            <div><i className="fa fa-link"/> fa fa-link</div>
            <div><i className="fa fa-linkedin"/> fa fa-linkedin</div>
            <div><i className="fa fa-linkedin-square"/> fa fa-linkedin-square</div>
            <div><i className="fa fa-linux"/> fa fa-linux</div>
            <div><i className="fa fa-list"/> fa fa-list</div>
            <div><i className="fa fa-list-alt"/> fa fa-list-alt</div>
            <div><i className="fa fa-list-ol"/> fa fa-list-ol</div>
            <div><i className="fa fa-list-ul"/> fa fa-list-ul</div>
            <div><i className="fa fa-location-arrow"/> fa fa-location-arrow</div>
            <div><i className="fa fa-lock"/> fa fa-lock</div>
            <div><i className="fa fa-long-arrow-down"/> fa fa-long-arrow-down</div>
            <div><i className="fa fa-long-arrow-left"/> fa fa-long-arrow-left</div>
            <div><i className="fa fa-long-arrow-right"/> fa fa-long-arrow-right</div>
            <div><i className="fa fa-long-arrow-up"/> fa fa-long-arrow-up</div>
            <div><i className="fa fa-magic"/> fa fa-magic</div>
            <div><i className="fa fa-magnet"/> fa fa-magnet</div>
            <div><i className="fa fa-mail-forward"/> fa fa-mail-forward</div>
            <div><i className="fa fa-mail-reply"/> fa fa-mail-reply</div>
            <div><i className="fa fa-mail-reply-all"/> fa fa-mail-reply-all</div>
            <div><i className="fa fa-male"/> fa fa-male</div>
            <div><i className="fa fa-map"/> fa fa-map</div>
            <div><i className="fa fa-map-marker"/> fa fa-map-marker</div>
            <div><i className="fa fa-map-o"/> fa fa-map-o</div>
            <div><i className="fa fa-map-pin"/> fa fa-map-pin</div>
            <div><i className="fa fa-map-signs"/> fa fa-map-signs</div>
            <div><i className="fa fa-mars"/> fa fa-mars</div>
            <div><i className="fa fa-mars-double"/> fa fa-mars-double</div>
            <div><i className="fa fa-mars-stroke"/> fa fa-mars-stroke</div>
            <div><i className="fa fa-mars-stroke-h"/> fa fa-mars-stroke-h</div>
            <div><i className="fa fa-mars-stroke-v"/> fa fa-mars-stroke-v</div>
            <div><i className="fa fa-maxcdn"/> fa fa-maxcdn</div>
            <div><i className="fa fa-meanpath"/> fa fa-meanpath</div>
            <div><i className="fa fa-medium"/> fa fa-medium</div>
            <div><i className="fa fa-medkit"/> fa fa-medkit</div>
            <div><i className="fa fa-meh-o"/> fa fa-meh-o</div>
            <div><i className="fa fa-mercury"/> fa fa-mercury</div>
            <div><i className="fa fa-microphone"/> fa fa-microphone</div>
            <div><i className="fa fa-microphone-slash"/> fa fa-microphone-slash</div>
            <div><i className="fa fa-minus"/> fa fa-minus</div>
            <div><i className="fa fa-minus-circle"/> fa fa-minus-circle</div>
            <div><i className="fa fa-minus-square"/> fa fa-minus-square</div>
            <div><i className="fa fa-minus-square-o"/> fa fa-minus-square-o</div>
            <div><i className="fa fa-mobile"/> fa fa-mobile</div>
            <div><i className="fa fa-mobile-phone"/> fa fa-mobile-phone</div>
            <div><i className="fa fa-money"/> fa fa-money</div>
            <div><i className="fa fa-moon-o"/> fa fa-moon-o</div>
            <div><i className="fa fa-mortar-board"/> fa fa-mortar-board</div>
            <div><i className="fa fa-motorcycle"/> fa fa-motorcycle</div>
            <div><i className="fa fa-mouse-pointer"/> fa fa-mouse-pointer</div>
            <div><i className="fa fa-music"/> fa fa-music</div>
            <div><i className="fa fa-navicon"/> fa fa-navicon</div>
            <div><i className="fa fa-neuter"/> fa fa-neuter</div>
            <div><i className="fa fa-newspaper-o"/> fa fa-newspaper-o</div>
            <div><i className="fa fa-object-group"/> fa fa-object-group</div>
            <div><i className="fa fa-object-ungroup"/> fa fa-object-ungroup</div>
            <div><i className="fa fa-odnoklassniki"/> fa fa-odnoklassniki</div>
            <div><i className="fa fa-odnoklassniki-square"/> fa fa-odnoklassniki-square</div>
            <div><i className="fa fa-opencart"/> fa fa-opencart</div>
            <div><i className="fa fa-openid"/> fa fa-openid</div>
            <div><i className="fa fa-opera"/> fa fa-opera</div>
            <div><i className="fa fa-optin-monster"/> fa fa-optin-monster</div>
            <div><i className="fa fa-outdent"/> fa fa-outdent</div>
            <div><i className="fa fa-pagelines"/> fa fa-pagelines</div>
            <div><i className="fa fa-paint-brush"/> fa fa-paint-brush</div>
            <div><i className="fa fa-paper-plane"/> fa fa-paper-plane</div>
            <div><i className="fa fa-paper-plane-o"/> fa fa-paper-plane-o</div>
            <div><i className="fa fa-paperclip"/> fa fa-paperclip</div>
            <div><i className="fa fa-paragraph"/> fa fa-paragraph</div>
            <div><i className="fa fa-paste"/> fa fa-paste</div>
            <div><i className="fa fa-pause"/> fa fa-pause</div>
            <div><i className="fa fa-paw"/> fa fa-paw</div>
            <div><i className="fa fa-paypal"/> fa fa-paypal</div>
            <div><i className="fa fa-pencil"/> fa fa-pencil</div>
            <div><i className="fa fa-pencil-square"/> fa fa-pencil-square</div>
            <div><i className="fa fa-pencil-square-o"/> fa fa-pencil-square-o</div>
            <div><i className="fa fa-phone"/> fa fa-phone</div>
            <div><i className="fa fa-phone-square"/> fa fa-phone-square</div>
            <div><i className="fa fa-photo"/> fa fa-photo</div>
            <div><i className="fa fa-picture-o"/> fa fa-picture-o</div>
            <div><i className="fa fa-pie-chart"/> fa fa-pie-chart</div>
            <div><i className="fa fa-pied-piper"/> fa fa-pied-piper</div>
            <div><i className="fa fa-pied-piper-alt"/> fa fa-pied-piper-alt</div>
            <div><i className="fa fa-pinterest"/> fa fa-pinterest</div>
            <div><i className="fa fa-pinterest-p"/> fa fa-pinterest-p</div>
            <div><i className="fa fa-pinterest-square"/> fa fa-pinterest-square</div>
            <div><i className="fa fa-plane"/> fa fa-plane</div>
            <div><i className="fa fa-play"/> fa fa-play</div>
            <div><i className="fa fa-play-circle"/> fa fa-play-circle</div>
            <div><i className="fa fa-play-circle-o"/> fa fa-play-circle-o</div>
            <div><i className="fa fa-plug"/> fa fa-plug</div>
            <div><i className="fa fa-plus"/> fa fa-plus</div>
            <div><i className="fa fa-plus-circle"/> fa fa-plus-circle</div>
            <div><i className="fa fa-plus-square"/> fa fa-plus-square</div>
            <div><i className="fa fa-plus-square-o"/> fa fa-plus-square-o</div>
            <div><i className="fa fa-power-off"/> fa fa-power-off</div>
            <div><i className="fa fa-print"/> fa fa-print</div>
            <div><i className="fa fa-puzzle-piece"/> fa fa-puzzle-piece</div>
            <div><i className="fa fa-qq"/> fa fa-qq</div>
            <div><i className="fa fa-qrcode"/> fa fa-qrcode</div>
            <div><i className="fa fa-question"/> fa fa-question</div>
            <div><i className="fa fa-question-circle"/> fa fa-question-circle</div>
            <div><i className="fa fa-quote-left"/> fa fa-quote-left</div>
            <div><i className="fa fa-quote-right"/> fa fa-quote-right</div>
            <div><i className="fa fa-ra"/> fa fa-ra</div>
            <div><i className="fa fa-random"/> fa fa-random</div>
            <div><i className="fa fa-rebel"/> fa fa-rebel</div>
            <div><i className="fa fa-recycle"/> fa fa-recycle</div>
            <div><i className="fa fa-reddit"/> fa fa-reddit</div>
            <div><i className="fa fa-reddit-square"/> fa fa-reddit-square</div>
            <div><i className="fa fa-refresh"/> fa fa-refresh</div>
            <div><i className="fa fa-registered"/> fa fa-registered</div>
            <div><i className="fa fa-renren"/> fa fa-renren</div>
            <div><i className="fa fa-reorder"/> fa fa-reorder</div>
            <div><i className="fa fa-repeat"/> fa fa-repeat</div>
            <div><i className="fa fa-reply"/> fa fa-reply</div>
            <div><i className="fa fa-reply-all"/> fa fa-reply-all</div>
            <div><i className="fa fa-retweet"/> fa fa-retweet</div>
            <div><i className="fa fa-rmb"/> fa fa-rmb</div>
            <div><i className="fa fa-road"/> fa fa-road</div>
            <div><i className="fa fa-rocket"/> fa fa-rocket</div>
            <div><i className="fa fa-rotate-left"/> fa fa-rotate-left</div>
            <div><i className="fa fa-rotate-right"/> fa fa-rotate-right</div>
            <div><i className="fa fa-rouble"/> fa fa-rouble</div>
            <div><i className="fa fa-rss"/> fa fa-rss</div>
            <div><i className="fa fa-rss-square"/> fa fa-rss-square</div>
            <div><i className="fa fa-rub"/> fa fa-rub</div>
            <div><i className="fa fa-ruble"/> fa fa-ruble</div>
            <div><i className="fa fa-rupee"/> fa fa-rupee</div>
            <div><i className="fa fa-safari"/> fa fa-safari</div>
            <div><i className="fa fa-save"/> fa fa-save</div>
            <div><i className="fa fa-scissors"/> fa fa-scissors</div>
            <div><i className="fa fa-search"/> fa fa-search</div>
            <div><i className="fa fa-search-minus"/> fa fa-search-minus</div>
            <div><i className="fa fa-search-plus"/> fa fa-search-plus</div>
            <div><i className="fa fa-sellsy"/> fa fa-sellsy</div>
            <div><i className="fa fa-server"/> fa fa-server</div>
            <div><i className="fa fa-share"/> fa fa-share</div>
            <div><i className="fa fa-share-alt"/> fa fa-share-alt</div>
            <div><i className="fa fa-share-alt-square"/> fa fa-share-alt-square</div>
            <div><i className="fa fa-share-square"/> fa fa-share-square</div>
            <div><i className="fa fa-share-square-o"/> fa fa-share-square-o</div>
            <div><i className="fa fa-shekel"/> fa fa-shekel</div>
            <div><i className="fa fa-sheqel"/> fa fa-sheqel</div>
            <div><i className="fa fa-shield"/> fa fa-shield</div>
            <div><i className="fa fa-ship"/> fa fa-ship</div>
            <div><i className="fa fa-shirtsinbulk"/> fa fa-shirtsinbulk</div>
            <div><i className="fa fa-shopping-cart"/> fa fa-shopping-cart</div>
            <div><i className="fa fa-sign-in"/> fa fa-sign-in</div>
            <div><i className="fa fa-sign-out"/> fa fa-sign-out</div>
            <div><i className="fa fa-signal"/> fa fa-signal</div>
            <div><i className="fa fa-simplybuilt"/> fa fa-simplybuilt</div>
            <div><i className="fa fa-sitemap"/> fa fa-sitemap</div>
            <div><i className="fa fa-skyatlas"/> fa fa-skyatlas</div>
            <div><i className="fa fa-skype"/> fa fa-skype</div>
            <div><i className="fa fa-slack"/> fa fa-slack</div>
            <div><i className="fa fa-sliders"/> fa fa-sliders</div>
            <div><i className="fa fa-slideshare"/> fa fa-slideshare</div>
            <div><i className="fa fa-smile-o"/> fa fa-smile-o</div>
            <div><i className="fa fa-sort"/> fa fa-sort</div>
            <div><i className="fa fa-sort-alpha-asc"/> fa fa-sort-alpha-asc</div>
            <div><i className="fa fa-sort-alpha-desc"/> fa fa-sort-alpha-desc</div>
            <div><i className="fa fa-sort-amount-asc"/> fa fa-sort-amount-asc</div>
            <div><i className="fa fa-sort-amount-desc"/> fa fa-sort-amount-desc</div>
            <div><i className="fa fa-sort-asc"/> fa fa-sort-asc</div>
            <div><i className="fa fa-sort-desc"/> fa fa-sort-desc</div>
            <div><i className="fa fa-sort-numeric-asc"/> fa fa-sort-numeric-asc</div>
            <div><i className="fa fa-sort-numeric-desc"/> fa fa-sort-numeric-desc</div>
            <div><i className="fa fa-soundcloud"/> fa fa-soundcloud</div>
            <div><i className="fa fa-space-shuttle"/> fa fa-space-shuttle</div>
            <div><i className="fa fa-spinner"/> fa fa-spinner</div>
            <div><i className="fa fa-spoon"/> fa fa-spoon</div>
            <div><i className="fa fa-spotify"/> fa fa-spotify</div>
            <div><i className="fa fa-square"/> fa fa-square</div>
            <div><i className="fa fa-square-o"/> fa fa-square-o</div>
            <div><i className="fa fa-stack-exchange"/> fa fa-stack-exchange</div>
            <div><i className="fa fa-stack-overflow"/> fa fa-stack-overflow</div>
            <div><i className="fa fa-star"/> fa fa-star</div>
            <div><i className="fa fa-star-half"/> fa fa-star-half</div>
            <div><i className="fa fa-star-half-o"/> fa fa-star-half-o</div>
            <div><i className="fa fa-star-o"/> fa fa-star-o</div>
            <div><i className="fa fa-steam"/> fa fa-steam</div>
            <div><i className="fa fa-steam-square"/> fa fa-steam-square</div>
            <div><i className="fa fa-step-backward"/> fa fa-step-backward</div>
            <div><i className="fa fa-step-forward"/> fa fa-step-forward</div>
            <div><i className="fa fa-stethoscope"/> fa fa-stethoscope</div>
            <div><i className="fa fa-sticky-note"/> fa fa-sticky-note</div>
            <div><i className="fa fa-sticky-note-o"/> fa fa-sticky-note-o</div>
            <div><i className="fa fa-stop"/> fa fa-stop</div>
            <div><i className="fa fa-street-view"/> fa fa-street-view</div>
            <div><i className="fa fa-strikethrough"/> fa fa-strikethrough</div>
            <div><i className="fa fa-stumbleupon"/> fa fa-stumbleupon</div>
            <div><i className="fa fa-stumbleupon-circle"/> fa fa-stumbleupon-circle</div>
            <div><i className="fa fa-subscript"/> fa fa-subscript</div>
            <div><i className="fa fa-subway"/> fa fa-subway</div>
            <div><i className="fa fa-suitcase"/> fa fa-suitcase</div>
            <div><i className="fa fa-sun-o"/> fa fa-sun-o</div>
            <div><i className="fa fa-superscript"/> fa fa-superscript</div>
            <div><i className="fa fa-table"/> fa fa-table</div>
            <div><i className="fa fa-tablet"/> fa fa-tablet</div>
            <div><i className="fa fa-tachometer"/> fa fa-tachometer</div>
            <div><i className="fa fa-tag"/> fa fa-tag</div>
            <div><i className="fa fa-tags"/> fa fa-tags</div>
            <div><i className="fa fa-tasks"/> fa fa-tasks</div>
            <div><i className="fa fa-taxi"/> fa fa-taxi</div>
            <div><i className="fa fa-television"/> fa fa-television</div>
            <div><i className="fa fa-tencent-weibo"/> fa fa-tencent-weibo</div>
            <div><i className="fa fa-terminal"/> fa fa-terminal</div>
            <div><i className="fa fa-text-height"/> fa fa-text-height</div>
            <div><i className="fa fa-text-width"/> fa fa-text-width</div>
            <div><i className="fa fa-th"/> fa fa-th</div>
            <div><i className="fa fa-th-large"/> fa fa-th-large</div>
            <div><i className="fa fa-th-list"/> fa fa-th-list</div>
            <div><i className="fa fa-thumb-tack"/> fa fa-thumb-tack</div>
            <div><i className="fa fa-thumbs-down"/> fa fa-thumbs-down</div>
            <div><i className="fa fa-thumbs-o-down"/> fa fa-thumbs-o-down</div>
            <div><i className="fa fa-thumbs-o-up"/> fa fa-thumbs-o-up</div>
            <div><i className="fa fa-thumbs-up"/> fa fa-thumbs-up</div>
            <div><i className="fa fa-ticket"/> fa fa-ticket</div>
            <div><i className="fa fa-times"/> fa fa-times</div>
            <div><i className="fa fa-times-circle"/> fa fa-times-circle</div>
            <div><i className="fa fa-times-circle-o"/> fa fa-times-circle-o</div>
            <div><i className="fa fa-tint"/> fa fa-tint</div>
            <div><i className="fa fa-toggle-off"/> fa fa-toggle-off</div>
            <div><i className="fa fa-toggle-on"/> fa fa-toggle-on</div>
            <div><i className="fa fa-trademark"/> fa fa-trademark</div>
            <div><i className="fa fa-train"/> fa fa-train</div>
            <div><i className="fa fa-transgender"/> fa fa-transgender</div>
            <div><i className="fa fa-transgender-alt"/> fa fa-transgender-alt</div>
            <div><i className="fa fa-trash"/> fa fa-trash</div>
            <div><i className="fa fa-trash-o"/> fa fa-trash-o</div>
            <div><i className="fa fa-tree"/> fa fa-tree</div>
            <div><i className="fa fa-trello"/> fa fa-trello</div>
            <div><i className="fa fa-tripadvisor"/> fa fa-tripadvisor</div>
            <div><i className="fa fa-trophy"/> fa fa-trophy</div>
            <div><i className="fa fa-truck"/> fa fa-truck</div>
            <div><i className="fa fa-try"/> fa fa-try</div>
            <div><i className="fa fa-tty"/> fa fa-tty</div>
            <div><i className="fa fa-tumblr"/> fa fa-tumblr</div>
            <div><i className="fa fa-tumblr-square"/> fa fa-tumblr-square</div>
            <div><i className="fa fa-turkish-lira"/> fa fa-turkish-lira</div>
            <div><i className="fa fa-twitch"/> fa fa-twitch</div>
            <div><i className="fa fa-twitter"/> fa fa-twitter</div>
            <div><i className="fa fa-twitter-square"/> fa fa-twitter-square</div>
            <div><i className="fa fa-umbrella"/> fa fa-umbrella</div>
            <div><i className="fa fa-underline"/> fa fa-underline</div>
            <div><i className="fa fa-undo"/> fa fa-undo</div>
            <div><i className="fa fa-university"/> fa fa-university</div>
            <div><i className="fa fa-unlink"/> fa fa-unlink</div>
            <div><i className="fa fa-unlock"/> fa fa-unlock</div>
            <div><i className="fa fa-unlock-alt"/> fa fa-unlock-alt</div>
            <div><i className="fa fa-upload"/> fa fa-upload</div>
            <div><i className="fa fa-usd"/> fa fa-usd</div>
            <div><i className="fa fa-user"/> fa fa-user</div>
            <div><i className="fa fa-user-md"/> fa fa-user-md</div>
            <div><i className="fa fa-user-plus"/> fa fa-user-plus</div>
            <div><i className="fa fa-user-secret"/> fa fa-user-secret</div>
            <div><i className="fa fa-user-times"/> fa fa-user-times</div>
            <div><i className="fa fa-users"/> fa fa-users</div>
            <div><i className="fa fa-venus"/> fa fa-venus</div>
            <div><i className="fa fa-venus-double"/> fa fa-venus-double</div>
            <div><i className="fa fa-venus-mars"/> fa fa-venus-mars</div>
            <div><i className="fa fa-viacoin"/> fa fa-viacoin</div>
            <div><i className="fa fa-video-camera"/> fa fa-video-camera</div>
            <div><i className="fa fa-vimeo"/> fa fa-vimeo</div>
            <div><i className="fa fa-vimeo-square"/> fa fa-vimeo-square</div>
            <div><i className="fa fa-vine"/> fa fa-vine</div>
            <div><i className="fa fa-vk"/> fa fa-vk</div>
            <div><i className="fa fa-volume-down"/> fa fa-volume-down</div>
            <div><i className="fa fa-volume-off"/> fa fa-volume-off</div>
            <div><i className="fa fa-volume-up"/> fa fa-volume-up</div>
            <div><i className="fa fa-warning"/> fa fa-warning</div>
            <div><i className="fa fa-wechat"/> fa fa-wechat</div>
            <div><i className="fa fa-weibo"/> fa fa-weibo</div>
            <div><i className="fa fa-weixin"/> fa fa-weixin</div>
            <div><i className="fa fa-whatsapp"/> fa fa-whatsapp</div>
            <div><i className="fa fa-wheelchair"/> fa fa-wheelchair</div>
            <div><i className="fa fa-wifi"/> fa fa-wifi</div>
            <div><i className="fa fa-wikipedia-w"/> fa fa-wikipedia-w</div>
            <div><i className="fa fa-windows"/> fa fa-windows</div>
            <div><i className="fa fa-won"/> fa fa-won</div>
            <div><i className="fa fa-wordpress"/> fa fa-wordpress</div>
            <div><i className="fa fa-wrench"/> fa fa-wrench</div>
            <div><i className="fa fa-xing"/> fa fa-xing</div>
            <div><i className="fa fa-xing-square"/> fa fa-xing-square</div>
            <div><i className="fa fa-y-combinator"/> fa fa-y-combinator</div>
            <div><i className="fa fa-y-combinator-square"/> fa fa-y-combinator-square</div>
            <div><i className="fa fa-yahoo"/> fa fa-yahoo</div>
            <div><i className="fa fa-yc"/> fa fa-yc</div>
            <div><i className="fa fa-yc-square"/> fa fa-yc-square</div>
            <div><i className="fa fa-yelp"/> fa fa-yelp</div>
            <div><i className="fa fa-yen"/> fa fa-yen</div>
            <div><i className="fa fa-youtube"/> fa fa-youtube</div>
            <div><i className="fa fa-youtube-play"/> fa fa-youtube-play</div>
            <div><i className="fa fa-youtube-square"/> fa fa-youtube-square</div>
          </div>
        </div>
        : <Redirect to={this.getRedirectUrl()}/>
      }
    </>
  }
}