import CsvTransformer from "./CsvTransformer"
import moment from "moment-timezone"
import { ChapterTipOpenReportData } from '../../components/system/reporting/ChapterTipOpenReport'

export default class ChapterTipOpenCsvTransformer extends CsvTransformer<ChapterTipOpenReportData> {
  get filename () {
    return `open-tips-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'Date Passed',
      valueTransformer: row => row.tip.datePassed,
    })

    this.addColumn({
      header: 'Tip Recipient',
      valueTransformer: row => row.recipient.fullName,
    })

    this.addColumn({
      header: 'Tipper',
      valueTransformer: row => row.sender.fullName,
    })

    this.addColumn({
      header: 'Tip Type',
      valueTransformer: row => row.tip.type,
    })

    this.addColumn({
      header: 'Tip Description',
      valueTransformer: row => row.tip.description,
    })

    this.addColumn({
      header: 'Tip Status',
      valueTransformer: row => row.tip.status,
    })

    this.addColumn({
      header: 'Tip Value',
      valueTransformer: row => row.tip.value,
    })
  }
}
