import CsvTransformer from "./CsvTransformer"
import moment from "moment-timezone"
import { TippersStackedReportData } from "../../components/system/reporting/ChapterTippersStackedReport"

export default class ChapterTippersStackedCsvTransformer extends CsvTransformer<TippersStackedReportData> {
  get filename () {
    return `tippers-stacked-${moment().format('YYYYMMDD')}.csv`
  }

  constructor () {
    super()

    this.addColumn({
      header: 'First Name',
      valueTransformer: row => row.member.firstName,
    })

    this.addColumn({
      header: 'Last Name',
      valueTransformer: row => row.member.lastName,
    })

    this.addColumn({
      header: 'Join Date',
      valueTransformer: row => row.member.joinDate ? moment(row.member.joinDate).format('MM/DD/YYYY') : '',
    })

    this.addColumn({
      header: 'Inactive Date',
      valueTransformer: row => row.member.inactiveDate ? moment(row.member.inactiveDate).format('MM/DD/YYYY') : '',
    })

    this.addColumn({
      header: 'Count',
      valueTransformer: row => row.tipData.totalSent,
    })

    this.addColumn({
      header: 'Value Sum',
      valueTransformer: row => row.tipData.totalSentValue,
    })

    this.addColumn({
      header: 'Value Avg',
      valueTransformer: row => row.tipData.totalSent ? row.tipData.totalSentValue / row.tipData.totalSent : '',
    })
  }
}