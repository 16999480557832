import BaseModel from "./BaseModel"
import { modelToCamelCase } from "../common/Util"
import LazyResource from "./LazyResource"
import ApiClient from "../api/ApiClient"
import SupportAreaChapter from "./SupportAreaChapter"
import SupportAreaUser from "./SupportAreaUser"

export default class SupportArea extends BaseModel {
  id: number
  name: string

  numberOfChapters?: number

  supportAreaChapters = new LazyResource<SupportAreaChapter[]>((callback, error) => {
    ApiClient.supportAreas.getSupportAreaChapters(this.id)
      .then(response => callback(response.data.support_area_chapters.map((p: {}) => new SupportAreaChapter().init(modelToCamelCase(p)))))
      .catch(() => error('There was an error loading the chapters'))
  })

  setSupportAreaUsers (u?: []) {
    return u ? u.map(u => new SupportAreaUser().init(u)) : []
  }

  supportAreaUsers = new LazyResource<SupportAreaUser[]>((callback, error) => {
    ApiClient.supportAreas.getSupportAreaUsers(this.id)
      .then(response => callback(response.data.support_area_users.map((p: {}) => new SupportAreaUser().init(modelToCamelCase(p)))))
      .catch(() => error('There was an error loading the users'))
  })
}