import { observer } from "mobx-react"
import * as React from "react"
import { computed, IObservableArray, observable } from "mobx"
import { Button, Modal, ModalFooter, ModalHeader } from "reactstrap"
import ModalBody from "reactstrap/lib/ModalBody"
import { TableViewColumn } from "./TableView"
import _ from 'lodash';

export type ManagedChooseableColumn = {
  id: string
  column: TableViewColumn
  fixed?: boolean
  hidden?: boolean
  default?: boolean
}

type Props = {
  availableColumns: ManagedChooseableColumn[],
  visibleColumns: string[]
  onChange: (visibleColumns: ManagedChooseableColumn[]) => void
}

@observer
export class ManagedTableViewColumnChooser extends React.Component<Props> {
  @observable private isOpen = false
  @observable private selectedColumns: IObservableArray<string> = observable.array(this.props.visibleColumns)

  static getInitialColumns (columns: ManagedChooseableColumn[]) {
    return columns.filter(col => col.fixed || col.default)
  }

  private toggle = () => {
    if (!this.isOpen) {
      this.selectedColumns = observable.array(
        _.union(
          this.props.availableColumns.filter(c => c.fixed)
            .map(c => c.id),
          this.props.visibleColumns
        )
      )
    }

    this.isOpen = !this.isOpen
  }

  private toggleColumn = (id: string) => {
    if (this.selectedColumns.indexOf(id) === -1) {
      this.selectedColumns.push(id)
    } else {
      this.selectedColumns.remove(id)
    }
  }

  private getVisibleColumns = () => {
    return this.props.availableColumns
      .filter(col => col.fixed || this.selectedColumns.indexOf(col.id) > -1)
  }

  @computed
  private get hasChooseableColumns () {
    return this.props.availableColumns.filter(c => !c.fixed).length > 0
  }

  render (): React.ReactNode {
    return <>
      {
        this.hasChooseableColumns
          ? <Button
            color="primary"
            onClick={this.toggle}>
            <i className="fa fa-list"/> Choose Columns
          </Button>
          : null
      }
      <Modal toggle={this.toggle} isOpen={this.isOpen} onClosed={() => this.props.onChange(this.getVisibleColumns())}>
        <ModalHeader toggle={this.toggle}>Choose Visible Columns</ModalHeader>
        <ModalBody>
          {this.props.availableColumns.map((col, idx) => col.hidden ? null : <div
            key={idx}
            style={{ cursor: 'pointer' }}
            onClick={() => !col.fixed && this.toggleColumn(col.id)}
            className="d-flex"
          >
            <div style={{ width: 30 }}>
              {
                col.fixed
                  ? <i className="fa fa-check text-muted"/>
                  :
                  this.selectedColumns.indexOf(col.id) > -1
                    ? <i className="fa fa-check text-success"/>
                    : null
              }
            </div>
            {col.column.title}
          </div>)}

        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={this.toggle}
          >Close</Button>
        </ModalFooter>
      </Modal>
    </>
  }
}
