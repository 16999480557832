import * as React from "react"
import { observable } from "mobx"
import Util, { createLazyResource, safeNull } from "../common/Util"
import Member from "../models/Member"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import LazyResourcePanel from "./LazyResourcePanel"
import { Button } from "reactstrap"
import MemberProfileImage from "./MemberProfileImage"
import { observer } from "mobx-react"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { Link } from "react-router-dom"
import ChooseMemberModal from "./ChooseMemberModal"
import AppStateStore from "../stores/AppStateStore"
import { can, Permission } from "./RequirePermission"

@observer
export default class SponsorList extends React.Component<{
  memberId: number
  onMemberInvalidated: () => void
  hideAddSponsor?: boolean
}> {
  @observable private showChooseMemberModal = false

  @observable private member = createLazyResource<Member>(() => {
    return ApiClient.query(
      `
member {
  *
  
  chapter {
    area {
      region {
        id
      }
    }
  }
  
  sponsors {
    *
    
    category {
      *
    }    
  }
}
      `,
      {
        where: [{ id: this.props.memberId }]
      }
    )
  }, response => new Member(response.data.member))

  private invalidateMember = () => {
    this.member.invalidate().then(() => {
    })
    this.props.onMemberInvalidated()
  }

  render (): React.ReactNode {
    return <>
      <LazyResourcePanel resource={this.member}>
        {member => <>
          <table className="table table-striped">
            <thead>
            <tr>
              <th>Member</th>
              <th>Category</th>
              <th>Badge Color</th>
              <th>Phone Number</th>
              <th>Email Address</th>
              {
                can(Permission.EditMemberNetwork, { memberId: member.id, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region!.id) })
                  ? <th/>
                  : null
              }
            </tr>
            </thead>
            <tbody>
            {
              member.sponsors.map(sponsor => <tr key={sponsor.id}>
                <td style={{ verticalAlign: 'middle' }}>
                  <div className="d-flex align-items-center">
                    <div>
                      <MemberProfileImage profileImageUrl={sponsor.profileImageUrl} size={35}/>
                    </div>
                    <div className="ml-2">
                      <Link to={route(Routes.network.member, { memberId: sponsor.id })}>{sponsor.fullName}</Link>
                    </div>
                  </div>
                </td>
                <td style={{ verticalAlign: 'middle' }}>{safeNull(() => sponsor.category!.name) || '---'}</td>
                <td style={{ verticalAlign: 'middle' }}>{sponsor.badgeColor || '---'}</td>
                <td style={{ verticalAlign: 'middle' }}>{safeNull(() => sponsor.phoneNumber) || '---'}</td>
                <td style={{ verticalAlign: 'middle' }}>{safeNull(() => sponsor.emailAddress) || '---'}</td>
                {
                  can(Permission.EditMemberNetwork, { memberId: member.id, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region!.id) })
                    ? <td>
                      <a className="text-danger" href="" onClick={(ev) => {
                        ev.preventDefault()
                        AppStateStore.showConfirmationModal('Remove Sponsor', 'Are you sure you want to remove this sponsor?', (result, modal) => {
                          if (result) {
                            AppStateStore.showModalSpinner()

                            ApiClient.getInstance()
                              .delete(route(ApiRoutes.members.removeSponsor, {
                                id: this.props.memberId,
                                sponsorId: sponsor.id,
                              }))
                              .then(() => {
                                this.invalidateMember()
                                modal.hide()
                              })
                              .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
                              .then(() => AppStateStore.dismissModalSpinner())
                          } else {
                            modal.hide()
                          }
                        })
                      }}><i className="fa fa-trash"/></a>
                    </td>
                    : null
                }
              </tr>)
            }
            </tbody>
          </table>
          {
            !member.sponsors.length
              ? <div>This member does not have any sponsors</div>
              : null
          }
          {
            can(Permission.EditMemberNetwork, { memberId: member.id, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region!.id) })
              ? <div className="form-buttons">
                <Button
                  color="primary"
                  type="button"
                  onClick={() => this.showChooseMemberModal = true}
                >Add Sponsor</Button>
              </div>
              : null
          }

          <ChooseMemberModal
            isOpen={this.showChooseMemberModal}
            toggle={() => this.showChooseMemberModal = !this.showChooseMemberModal}
            excludeMemberIds={member.sponsors.map(s => s.id).concat([member.id])}
            onChoose={(sponsor: Member) => {
              AppStateStore.showModalSpinner()

              ApiClient.getInstance()
                .post(route(ApiRoutes.members.addSponsor, { id: this.props.memberId }), { sponsor_id: sponsor.id })
                .then(() => {
                  this.invalidateMember()
                  this.showChooseMemberModal = false
                })
                .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
                .then(() => AppStateStore.dismissModalSpinner())
            }}
            onCancel={() => {
              this.showChooseMemberModal = false
            }}
            title={"Choose a Sponsor"}/>
        </>}
      </LazyResourcePanel>
    </>
  }
}
