import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Member from './Member'
import BoardTraining from "./BoardTraining"

export default class BoardTrainingMember extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  ntsEventId: number
  memberId: number
  didAttend: boolean

  boardTraining: BoardTraining
  member: Member

  getCasts () {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      ntsEventId: 'number',
      memberId: 'number',
      didAttend: 'boolean',
    }
  }

  setNtsEvent (u?: {}) {
    return u ? new BoardTraining().init(u) : null
  }

  setMember (m?: {}) {
    return m ? new BoardTraining().init(m) : null
  }
}
