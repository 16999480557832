import BaseView from "../BaseView"
import * as React from "react"
import { observable } from "mobx"
import { Link, RouteComponentProps } from "react-router-dom"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { ApiTableViewAdapter } from "../../components/table-view/ApiTableViewAdapter"
import ApiClient, { QueryWhereClause } from "../../api/ApiClient"
import { LocationState } from "history"
import Chapter from "../../models/Chapter"
import { observer } from "mobx-react"
import FormState from "../../common/FormState"
import ErrorBag from "../../common/ErrorBag"
import { StaticContext } from "react-router"
import AddressView from "../../components/AddressView"
import Category from "../../models/Category"
import { BOARD_MEMBER_TYPE } from "../../models/Position"
import { safeNull } from "../../common/Util"
import { ManagedChooseableColumn } from '../../components/table-view/ManagedTableViewColumnChooser'
import ManagedTableView from '../../components/table-view/ManagedTableView'
import AsyncSelectFilter, { AsyncSelectFilterTargetType } from '../../components/table-view/filters/AsyncSelectFilter'
import { loadAreaOptions } from '../../api/AsyncHelpers'
import OpenCategoryReportCsvTransformer from '../../models/renderers/OpenCategoryReportCsvTransformer'

type Props = {
  match: {
    params: {
      categoryId: string
    }
  }
} & RouteComponentProps

@observer
export default class NetworkOpenCategoryReport extends BaseView<Props> {
  @observable private category?: Category

  renderContentHeader () {
    return <>
      <h1>LeTip International Open Category Search</h1>
    </>
  }

  componentDidMount (): void {
    super.componentDidMount()
    this.fetchData()
  }

  componentDidUpdate (prevProps: Readonly<{match: {params: {categoryId: string}}} & RouteComponentProps<{}, StaticContext, LocationState>>, prevState: Readonly<any>, snapshot?: any): void {
    if (this.props.match.params.categoryId !== prevProps.match.params.categoryId) {
      this.fetchData()
    }
  }

  private fetchData = () => {
    this.category = undefined
    ApiClient.query(`
    category {
      *
    }`, {
      where: [{ id: this.props.match.params.categoryId }],
    })
      .then(response => this.category = new Category().init(response.data.category))
  }

  @observable availableColumns: ManagedChooseableColumn[] = [
    {
      id: 'chapter',
      column: {
        title: 'Chapter',
        accessor: 'name',
        renderItem: (value, item) => <Link type="link" to={{ pathname: route(Routes.network.chapter, { chapterId: item.id }) }}>
          {value}
        </Link>,
        sortable: true,
      },
      fixed: true,
    },
    {
      id: 'president',
      column: {
        title: 'President',
        renderItem: (value, item: Chapter) => {
          const president = item.getMemberForPosition('President', BOARD_MEMBER_TYPE)
          if (president) {
            return president.member.fullName
          } else {
            return '---'
          }
        },
      },
      default: true,
    },
    {
      id: 'email',
      column: {
        title: 'Email',
        renderItem: (value, item: Chapter) => {
          const president = item.getMemberForPosition('President', BOARD_MEMBER_TYPE)
          return safeNull(() => president!.member.emailAddress) || '---'
        },
      },
      default: true,
    },
    {
      id: 'phone',
      column: {
        title: 'Phone',
        renderItem: (value, item: Chapter) => {
          const president = item.getMemberForPosition('President', BOARD_MEMBER_TYPE)
          return safeNull(() => president!.member.phoneNumber) || '---'
        },
      },
      default: true,
    },
    {
      id: 'meetingDay',
      column: {
        title: 'Meeting Day',
        accessor: 'meetingDay',
      },
      default: true,
    },
    {
      id: 'meetingTime',
      column: {
        title: 'Meeting Time',
        accessor: 'meetingTime',
      },
      default: true,
    },
    {
      id: 'meetingLocation',
      column: {
        title: 'Meeting Location',
        accessor: 'meetingLocationDescription',
      },
      default: true,
    },
    {
      id: 'meetingAddress',
      column: {
        title: 'Meeting Address',
        accessor: 'meetingLocationAddress',
        renderItem: value => value ? <AddressView address={value}/> : '---',
      },
      default: true,
    },
  ]

  @observable private searchFormState = new FormState({
    openCategoryId: undefined
  })

  @observable private searchFormErrors = new ErrorBag()

  private chaptersAdapter = new ApiTableViewAdapter(
    () => {
      const where: QueryWhereClause[] = []

      where.push({
        _scope: 'availableForCategory',
        value: this.props.match.params.categoryId,
      })

      where.push({ _scope: 'active' })

      return {
        query: `
      chapters {
        *
        
        memberPositions {
          *
          
          position {
            *
          }
          
          member {
            *
          }
        }
        
        meetingLocationAddress {
          *
        }
      }
    `,
        where: where,
      }
    },
    'chapters',
    Chapter
  )

  renderContentBody (): React.ReactNode {
    return this.renderTable()
  }

  private filters = [
    new AsyncSelectFilter('area', AsyncSelectFilterTargetType.Field, 'area.id', 'Area', loadAreaOptions),
  ]

  private renderTable = () => {
    return (
      <>
        {
          this.category ?
            <h5 className="text-muted text-center mb-4">Showing chapters open for {this.category.name}</h5>
            : null
        }
        <ManagedTableView
          stateKey="NetworkOpenCategoryReport"
          hideItemCount={true}
          adapter={this.chaptersAdapter}
          availableColumns={this.availableColumns}
          searchLabel={'Find A Chapter'}
          defaultSort={{ sortKey: 'name' }}
          filters={this.filters}
          alwaysShowFilters={true}
          exportTransformer={OpenCategoryReportCsvTransformer}
        />
      </>
    )
  }
}
