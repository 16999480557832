import * as React from "react"
import { ChangeEvent } from "react"
import BaseView from "../BaseView"
import { observer } from "mobx-react"
import Calendar from "../../components/calendar/Calendar"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, TabContent } from "reactstrap"
import { IObservableArray, observable } from "mobx"
import { createBrowserHistory } from "history"
import * as _ from "lodash"
import LazyTabPane from "../../components/LazyTabPane"
import ProgramSchedule from "./ProgramSchedule"
import AuthStore from "../../stores/AuthStore"
import RequirePermission, { Permission } from "../../components/RequirePermission"
import { MeetingTypeFilters } from "../../components/calendar/MeetingTypeFilters"
import { AreaFilters } from "../../components/calendar/AreaFilters"
import MeetingTypesView from "./MeetingTypesView"

const TABS = {
  calendar: 'calendar',
  program: 'program',
  meetingTypes: 'meetingTypes'}

@observer
export default class ChapterCalendarView extends BaseView {
  @observable private activeTab = TABS.calendar
  private calendarRef = React.createRef<Calendar>()

  @observable private selectedMeetingTypes: string[] = []
  @observable private selectedAreas: number[] = []

  @observable private _showSubscribeCalenderModal = false
  @observable private renderSubscribeCalendarModal = false

  @observable private isCreateRecurringEventModalVisible = false

  private history = createBrowserHistory()

  private toggleMeetingTypeFilter = (ev: ChangeEvent<HTMLInputElement>, meetingTypeId: string) => {
    if (!ev.target.checked) {
      (this.selectedMeetingTypes as IObservableArray).remove(meetingTypeId)
    } else {
      if (this.selectedMeetingTypes.indexOf(meetingTypeId) === -1) {
        this.selectedMeetingTypes.push(meetingTypeId)
      }
    }
  }

  private toggleAreaFilter = (ev: ChangeEvent<HTMLInputElement>, areaId: number) => {
    if (!ev.target.checked) {
      (this.selectedAreas as IObservableArray).remove(areaId)
    } else {
      if (this.selectedAreas.indexOf(areaId) === -1) {
        this.selectedAreas.push(areaId)
      }
    }
  }

  showCreateRecurringEventModal = () => {
    this.isCreateRecurringEventModalVisible = true
  }

  componentDidMount(): void {
    super.componentDidMount()

    this.activeTab = _.get(this.history.location.state, 'activeTab') || TABS.calendar
  }

  private setTab(tab: string) {
    if (tab !== this.activeTab) {
      this.activeTab = tab
      this.history.replace(window.location.pathname, { ...this.history.location.state, activeTab: this.activeTab })
    }
  }

  private showSubscribeCalendarModal = () => {
    this._showSubscribeCalenderModal = true
    this.renderSubscribeCalendarModal = true
  }

  renderContentHeader(): React.ReactNode | null {
    return (
      <>
        <h1>Calendar</h1>
        {
          this.activeTab === TABS.calendar
            ? <ul className="content-header-actions">
              <li>
                {/* <Button color="primary" onClick={() => this.calendarRef.current && this.showSubscribeCalendarModal()}><i className="fa fa-share" /> Share Calendar</Button> */}
              </li>
              <RequirePermission permission={Permission.ManageChapterCalendar} context={{ chapterId: AuthStore.getUser()!.chapter.id }}>
                {() =>
                  <li>
                    <Button color="primary" onClick={() => this.calendarRef.current && this.calendarRef.current.showCreateEventModal()}><i className="fa fa-plus-circle" /> Add New Event</Button>
                  </li>
                }
              </RequirePermission>
            </ul>
            : null
        }
      </>
    )
  }

  render(): React.ReactNode {
    return <>
      <div className="card">
        <div className="card-header tabs">
          <Nav tabs className="card-header-tabs">
            <NavItem active={this.activeTab === TABS.calendar}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.calendar)
                }}
              >Calendar</NavLink>
            </NavItem>
            <NavItem active={this.activeTab === TABS.program}>
              <NavLink
                onClick={() => {
                  this.setTab(TABS.program)
                }}
              >Program</NavLink>
            </NavItem>
            <RequirePermission permission={Permission.ManageChapterCalendar} context={{ chapterId: AuthStore.getUser()!.chapter.id }}>
              {() =>
                <NavItem active={this.activeTab === TABS.meetingTypes}>
                  <NavLink
                    onClick={() => {
                      this.setTab(TABS.meetingTypes)
                    }}
                  >Meeting Types</NavLink>
                </NavItem>
              }
            </RequirePermission>
          </Nav>
        </div>
        <div className="card-body">
          <TabContent activeTab={this.activeTab}>
            <LazyTabPane tabId={TABS.calendar} activeTab={this.activeTab}>
              <div className="calendar-container">
                <div className={'calendar-filters'}>
                  <h5 className={'text-muted'}>Meeting Types</h5>
                  <MeetingTypeFilters selectedMeetingTypes={this.selectedMeetingTypes} onClicked={this.toggleMeetingTypeFilter} />
                  <br />
                  <h5 className={'text-muted'}>Areas</h5>
                  <AreaFilters selectedAreas={this.selectedAreas} onClicked={this.toggleAreaFilter} />
                </div>

                <Calendar
                  ref={this.calendarRef}
                  visibleAreas={this.selectedAreas}
                  visibleMeetingTypes={this.selectedMeetingTypes}
                />
              </div>
            </LazyTabPane>
            <LazyTabPane tabId={TABS.program} activeTab={this.activeTab}>
              <ProgramSchedule chapterId={AuthStore.getUser()!.chapter.id} />
            </LazyTabPane>
            <LazyTabPane tabId={TABS.meetingTypes} activeTab={this.activeTab}>
              <MeetingTypesView chapterId={AuthStore.getUser()!.chapter.id} />
            </LazyTabPane>
          </TabContent>
        </div>
      </div>
      {
       /* this.renderSubscribeCalendarModal
          ? <Modal isOpen={this._showSubscribeCalenderModal} size="lg" toggle={() => this._showSubscribeCalenderModal = false} onClosed={() => this.renderSubscribeCalendarModal = false}>
            <ModalHeader toggle={() => this._showSubscribeCalenderModal = false}>
              Share Calendar
            </ModalHeader>
            <ModalBody>
              <p>To share your calendar to your mobile device or Google/Apple Calendar, use the URL below and subscribe to your iCal feed.</p>
              <input className="form-control" value={AuthStore.getUser()!.calendarFeedUrl} readOnly />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => this._showSubscribeCalenderModal = false}>Close Window</Button>
            </ModalFooter>
          </Modal>
          : null */
      }
    </>
  }
}
