import BaseView from "../BaseView"
import * as React from "react"
import BackLink from "../../components/BackLink"
import { computed, observable } from "mobx"
import ApiClient from "../../api/ApiClient"
import { BarLoader } from "react-spinners"
import { observer } from "mobx-react"
import Util, { nl2br, normalizeUrl, safeNull } from "../../common/Util"
import Member from "../../models/Member"
import AddressView from "../../components/AddressView"
import MapView from "../../components/MapView"
import { LinkContainer } from "react-router-bootstrap"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import VideoPlayer from "../../components/VideoPlayer"
import _ from 'lodash';
import SponsorList from "../../components/SponsorList"
import SponsoredList from "../../components/SponsoredList"
import { can, Permission } from "../../components/RequirePermission"
import BankCode from '../../components/BankCode'
import NtsBadgeView from "../../components/badge/ntsbadgeview"

type Props = {
  match: {
    params: {
      memberId: number
    }
  }
}

@observer
export default class NetworkMemberDetailView extends BaseView<Props> {
  @observable loading = false
  @observable error?: string
  @observable private member?: Member

  componentDidMount(): void {
    super.componentDidMount()

    this.loadMember()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any): void {
    if (prevProps.match.params.memberId !== this.props.match.params.memberId) {
      this.loadMember()
    }
  }

  private loadMember = () => {
    this.loading = true

    ApiClient.query(
      `
member {
  *

  chapter {
    *

    area {
      *

      region {
        *
      }
    }
  }

  category {
    *
  }

  business {
    *

    address {
      *
    }
  }
}
      `,
      {
        where: [{ id: this.props.match.params.memberId }]
      }
    )
      .then(response => this.member = new Member().init(response.data.member))
      .catch(err => this.error = (err.response.status === 404 ? 'The member you are looking for does not exist!' : Util.extractErrorMessage(err.response)))
      .then(() => this.loading = false)
  }

  @computed
  private get memberEditButton() {
    if (this.member) {
      if (can(Permission.AdministerMember, { memberId: this.member.id, chapterId: this.member.chapterId, regionId: safeNull(() => this.member!.chapter!.area!.region.id) })) {
        return <ul className="content-header-actions">
          <li>
            <LinkContainer to={route(Routes.system.members.show, { id: this.props.match.params.memberId })}>
              <Button color="primary" onClick={() => {
              }}>Edit Member</Button>
            </LinkContainer>
          </li>
        </ul>
      } else if (can(Permission.EditMemberProfile, { chapterId: this.member.chapterId })) {
        return <ul className="content-header-actions">
          <li>
            <LinkContainer to={route(Routes.board.editMemberProfile, { id: this.props.match.params.memberId })}>
              <Button color="primary" onClick={() => {
              }}>Edit Profile</Button>
            </LinkContainer>
          </li>
        </ul>
      }
    }

    return null
  }

  renderContentHeader() {
    return (
      <>
        <BackLink />
        <h1>Member Detail{this.member && ` for ${this.member.fullName}`}</h1>

        {
          this.memberEditButton
        }
      </>
    )
  }

  render() {
    return this.loading
      ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d" />
      : this.error
        ? <div className="alert alert-danger">{this.error}</div>
        : this.member
          ? this.renderDetail(this.member)
          : null
  }

  private renderDetail = (member: Member) => {
    return <>
      <div className="row">
        <div className="col-md-4">
          <ProfilePanel member={member} />
          <VideoWidget member={member} />
        </div>
        <div className="col-md-8">
          <BusinessPanel member={member} />

          <div className="card">
            <div className="card-body">
              <div>
                <h4>Sponsored By</h4>
                <SponsorList memberId={member.id} onMemberInvalidated={() => {
                }} />
              </div>
              <div className="mt-4">
                <h4>Sponsored ({member.numberOfSponsoredMembers})</h4>
                <SponsoredList memberId={member.id} onMemberInvalidated={() => {
                }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  }
}

@observer
class ProfilePanel extends React.Component<{ member: Member }> {
  render() {
    const member = this.props.member
    return <div className="card">
      <div className="card-body">
        <h6 className="text-muted">Member Profile</h6>
        <div className="d-flex flex-row">
          <div style={{ paddingRight: 20 }}>
            <img alt="Member Profile Image" src={member.getProfileImageUrl()} style={{
              width: 65,
              height: 65,
              objectFit: 'cover',
              objectPosition: 'top center',
              backgroundColor: '#ccc',
            }} />
          </div>
          <div className="flex-fill">
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <h5>{member.fullName}</h5>
              </div>
              <div>
                {
                  member.bankCode
                    ? <BankCode bankCode={member.bankCode} documentUrl={member.bankCodeDocumentUrl!} />
                    : null
                }
              </div>
            </div>
            {member.category && <div className="mb-1">{member.category.name}</div>}
            {member.chapter && <div className="mb-1"><b><a href={route(Routes.network.chapter, { chapterId: member.chapterId })}>{member.chapter.name}</a></b></div>}
            {member.joinDate && <div className="mb-1">Member Since {member.joinDate!.format('MM/DD/YYYY')}</div>}
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                {member.badgeColor && <div className="mb-1">
                  <i className="fa fa-id-card text-muted" /> <b>{member.badgeColor} Badge</b>
                </div>}
              </div>
              <div>
                {
                  member.ntsTrainingCompletedAt
                    ? <NtsBadgeView />
                    : null
                }
              </div>
            </div>
            {member.emailAddress && <div className="mb-1">
              <i className="fa fa-envelope-o" /> <a href={`mailto:${member.emailAddress}`}>{member.emailAddress}</a>
            </div>}
            {member.phoneNumber && <div className="mb-1">
              <i className="fa fa-phone" /> {member.phoneNumber}
            </div>}
            {member.websiteUrl && <div className="mb-1">
              <a href={normalizeUrl(member.websiteUrl)} target="_blank">{member.websiteUrl}</a>
            </div>}
          </div>
        </div>
        {
          member.bio
            ? <div className="mt-4"><p>{nl2br(member.bio)}</p></div>
            : null
        }
      </div>
    </div>
  }
}

@observer
class BusinessPanel extends React.Component<{ member: Member }> {
  private renderSocialMediaLinks(social: {}) {
    const sites = {
      'facebook': { name: 'Facebook', icon: 'fa fa-facebook-square' },
      'twitter': { name: 'Twitter', icon: 'fa fa-twitter-square' },
      'linkedin': { name: 'Linkedin', icon: 'fa fa-linkedin-square' },
      'instagram': { name: 'Instagram', icon: 'fa fa-instagram' },
      'youtube': { name: 'YouTube', icon: 'fa fa-youtube' },
      'alignable': { name: 'Alignable', icon: 'fa fa-home' },
      'pinterest': { name: 'Pinterest', icon: 'fa fa-pinterest' },
      'snapchat': { name: 'Snapchat', icon: 'fa fa-snapchat' },
      'vimeo': { name: 'Vimeo', icon: 'fa fa-vimeo' },
    }

    const links = [
      'facebook',
      'twitter',
      'linkedin',
      'instagram',
      'youtube',
      'alignable',
      'pinterest',
      'snapchat',
      'vimeo',
    ].filter(soc => !!social[soc])

    return links.length
      ? links.map(soc => <div key={soc} className="social-link-list-item">
        <a href={normalizeUrl(social[soc])} target="_blank"><i className={sites[soc].icon} /> {sites[soc].name}</a>
      </div>)
      : <p>Social media links have not been entered</p>
  }

  private hasSocialMediaLinks(social: {}) {
    return [
      'facebook',
      'twitter',
      'linkedin',
      'instagram',
      'youtube',
      'alignable',
      'pinterest',
      'snapchat',
      'vimeo',
    ].filter(soc => !!social[soc]).length > 0
  }

  render() {
    const member = this.props.member
    return <div className="card">
      <div className="card-body">
        <h6 className="text-muted">Company Profile</h6>
        <div className="row">
          <div className="col-6">
            {
              member.business
                ? <>
                  <div className="mb-2">
                    <b>{member.business.name}</b>
                  </div>
                  {
                    member.business.logoUrl
                      ? <div className="my-4"><img src={member.business.getLogoImageUrl()} width={130} height={130} style={{ backgroundColor: '#ccc' }} /></div>
                      : null
                  }
                  {member.business.address && <div className="mb-2"><AddressView address={member.business.address} showMapLink={false} /></div>}
                  {member.business.phoneNumber && <div>
                    {member.business.phoneNumber}
                  </div>}
                  {member.business.emailAddress && <div>
                    <a href={`mailto:${member.business.emailAddress}`}>{member.business.emailAddress}</a>
                  </div>}
                  {member.business.websiteUrl && <div className="mt-2">
                    <a href={normalizeUrl(member.business.websiteUrl)} target="_blank">{member.business.websiteUrl}</a>
                  </div>}
                  {member.business.address && <div><MapView address={member.business.address} width={500} height={300} /></div>}
                </>
                : <p>This member has not entered any company info</p>
            }

            {
              (member.business && member.business.description && _.trim(member.business.description).length)
                ? <>
                  <h6 className="text-muted mt-4">About My Business</h6>
                  <div className="form-row">
                    <div className="col-12">
                      {nl2br(member.business.description)}
                    </div>
                  </div>
                </>
                : null
            }

            {
              (member.business && this.hasSocialMediaLinks(member.business.socialLinks))
                ? <>
                  <h6 className="text-muted mt-4">Social Media Profiles7</h6>
                  <div className="form-row">
                    <div className="col-12">
                      {this.renderSocialMediaLinks(member.business.socialLinks)}
                    </div>
                  </div>
                </>
                : null
            }

          </div>
          <div className="col-6">

            {
              (member.business && member.business.hoursOfOperation)
                ? <>
                  <h6 className="text-muted">Hours of Operation</h6>
                  {member.business.hoursOfOperation.map(({ day, open, close }) => <>
                    <p key={day}><span className="text-muted">{day}</span><br />{open && close ? `${open} to ${close}` : 'Closed'}</p>
                  </>
                  )}
                </>
                : null
            }
          </div>
        </div>
      </div>
    </div>
  }
}

@observer
class VideoWidget extends React.Component<{ member: Member }> {
  @observable private showModal = false

  render(): React.ReactNode {
    return this.props.member.videoData
      ? <>
        <div className="card">
          <div className="card-body text-center">
            <div className="video-thumbnail" onClick={() => this.showModal = true}>
              {
                this.props.member.videoData.thumbnailUrl
                  ? <img
                    style={{ maxWidth: '100%' }}
                    src={this.props.member.videoData.thumbnailUrl} />
                  : null
              }
              <div className="video-thumbnail-play"><i className="fa fa-play" /></div>
            </div>
          </div>
        </div>

        <Modal isOpen={this.showModal} size="lg" toggle={() => this.showModal = false}>
          <ModalHeader toggle={() => this.showModal = false}>
            {this.props.member.business ? this.props.member.business.name : 'Video Player'}
          </ModalHeader>
          <ModalBody>
            <VideoPlayer videoData={this.props.member.videoData!} />
          </ModalBody>
        </Modal>
      </>
      : null
  }
}
