import { observer } from "mobx-react";
import * as React from "react";
import { SyntheticEvent } from "react";
import { observable } from "mobx";
import FormState from "../common/FormState";
import ErrorBag from "../common/ErrorBag";
import FormHelper from "../forms/FormHelper";
import AppStateStore from "../stores/AppStateStore";
import { submitFormRequest } from "../api/ApiHelper";
import ApiClient, { ApiRoutes } from "../api/ApiClient";
import { toast } from "react-toastify";
import { modelToSnakeCase, safeNull } from "../common/Util";
import * as AsyncHelpers from "../api/AsyncHelpers";
import { Button } from "reactstrap";
import ButtonLoader from "./ButtonLoader";
import Guest from "../models/Guest";
import { route } from "../routes/routes";
import { can, Permission } from "./RequirePermission";
import { MemberPickerInput } from "./inputs/MemberPickerInput";
import AuthStore from "../stores/AuthStore";
import { UserRole } from "../models/AuthUser";
import _ from 'lodash';

export type GuestDetailFormProps = {
  guest: Guest;
  onCancel: () => void;
  onSaved: () => void;
  showCategoryOnly?: boolean;
};

@observer
export default class GuestDetailForm extends React.Component<GuestDetailFormProps> {
  private initialFormState = {
    firstName: this.props.guest.firstName,
    lastName: this.props.guest.lastName,
    phone: this.props.guest.phone,
    email: this.props.guest.email,
    companyName: this.props.guest.companyName || "",
    categoryId: safeNull(() => this.props.guest.category!.id),
    bankCode: this.props.guest.bankCode,
    sourceMemberId: this.props.guest.createdByMember!.id
  };

  @observable private formState = new FormState(this.initialFormState);

  @observable private formErrors = new ErrorBag();
  @observable private submitting = false;

  private formHelper = new FormHelper(this.formState, this.formErrors);

  private onSubmit = (ev: SyntheticEvent) => {
    ev.preventDefault();

    this.formErrors.clearErrors();

    this.submitting = true;
    AppStateStore.showModalSpinner();

    const data = this.props.showCategoryOnly
      ? {
          update_category_only: 1,
          category_id: this.formState.get("categoryId")
        }
      : modelToSnakeCase(this.formState.toObject());

    submitFormRequest(ApiClient.getInstance().put(route(ApiRoutes.guests.update, { id: this.props.guest.id }), data), this.formState, this.formErrors, () => {
      toast.success("Guest Updated");
      this.props.onSaved();
    }).then(() => {
      AppStateStore.dismissModalSpinner();
      this.submitting = false;
    });
  };

  render(): React.ReactNode {
    return (
      <>
        <form onSubmit={this.onSubmit}>
          {!this.props.showCategoryOnly ? (
            <>
              <div className="form-row">
                <div className="col-sm-6">
                  {this.formHelper.renderTextInput({
                    name: "firstName",
                    label: "Guest First Name"
                  })}
                </div>
                <div className="col-sm-6">
                  {this.formHelper.renderTextInput({
                    name: "lastName",
                    label: "Guest Last Name"
                  })}
                </div>
              </div>
              <div className="form-row">
                <div className="col-sm-6">
                  {this.formHelper.renderTextInput({
                    name: "phone",
                    label: "Guest Phone Number",
                    type: "tel"
                  })}
                </div>
                <div className="col-sm-6">
                  {this.formHelper.renderTextInput({
                    name: "email",
                    label: "Guest Email Address",
                    type: "email"
                  })}
                </div>
              </div>
              <div className="form-row">
                <div className="col-sm-12">
                  {this.formHelper.renderTextInput({
                    name: "companyName",
                    label: "Guest Company Name",
                    type: "text"
                  })}
                </div>
              </div>
            </>
          ) : null}
          <div className="form-row">
            <div className={this.props.showCategoryOnly ? "col-sm-12" : "col-sm-6"}>
              {this.formHelper.renderAsyncSelectInput({
                name: "categoryId",
                label: "Category",
                loadOptions: AsyncHelpers.loadCategoryOptions(),
                defaultValue: this.props.guest.category ? { value: this.props.guest.category.id, label: this.props.guest.category.name } : undefined
              })}
            </div>
            {!this.props.showCategoryOnly && can(Permission.EditGuestBankCode) ? (
              <div className="col-sm-6">
                {this.formHelper.renderTextInput({
                  name: "bankCode",
                  label: "BANKCODE",
                  type: "text"
                })}
              </div>
            ) : null}
          </div>

          <div className="form-group form-row pb-2">
            <div className="col-sm-6">
            <label>Invited By</label>
            <MemberPickerInput
              placeholder="Choose the inviting member"
              onChange={member => this.formState.set("sourceMemberId", _.get(member, "id"))}
              filters={!AuthStore.getUser()!.hasAnyRole([UserRole.Admin, UserRole.Employee]) ? { chapter: AuthStore.getUser()!.chapter.id, allowEntireNetwork: true } : {}}
              value={this.formState.get("sourceMemberId")}
            />
            </div>
          </div>

            <div className="form-buttons">
              <Button color="secondary" onClick={() => this.props.onCancel()}>
                Cancel
              </Button>
              <ButtonLoader type="submit" color="primary" loading={this.submitting}>
                Save Changes
              </ButtonLoader>
            </div>

        </form>
      </>
    );
  }
}
