import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import FormState from "../../../common/FormState"
import { computed, observable } from "mobx"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"
import { loadChapterOptions, loadChapterOptionsForAdmin } from "../../../api/AsyncHelpers"
import moment from 'moment-timezone'
import { Button } from "reactstrap"
import { BarLoader } from "react-spinners"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import { route } from "../../../routes/routes"
import Util, { formatCurrency, formatNumber, modelToCamelCase, modelToSnakeCase } from "../../../common/Util"
import * as _ from "lodash"
import { Moment } from "moment-timezone/moment-timezone"

type MonthWeeks = Moment[]

type WeeklyTipData = {
  inside: {
    sent: number
    sentValue: number
  },
  outside: {
    sent: number
    sentValue: number
  },
  socialMedia: {
    sent: number
    sentValue: number
  },
  interChapter: {
    sent: number
    sentValue: number
  },
}

type ReportData = { [date: string]: WeeklyTipData }

type Props = {
  chapterId?: number
  filterForAdmin?: boolean
}

@observer
export default class WeeklyTipReport extends React.Component<Props> {
  @observable private loading = false
  @observable private error?: string
  @observable private formState = new FormState({
    chapterId: undefined,
    year: moment().year().toString(),
  })

  @observable private formErrors = new ErrorBag()

  @computed get yearOptions () {
    return _.range(2010, moment().year() + 1)
  }

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable chapterData?: { id: number, name: string }
  @observable reportData?: ReportData
  @observable weeks?: string[]
  @observable year?: number

  @computed get groupedWeeks () {
    const weeks: MonthWeeks[] = [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
    ]

    return this.weeks
      ? this.weeks.reduce((acc: MonthWeeks[], cur: string) => {
        const d = moment(cur)
        acc[d.month()].push(d)
        return acc
      }, weeks)
      : []
  }

  componentDidMount (): void {
    if (this.props.chapterId) {
      this.formState.set('chapterId', this.props.chapterId)
    }
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.chapterId != this.props.chapterId) {
      this.formState.set('chapterId', this.props.chapterId)
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.error = undefined
    this.loading = true

    ApiClient.getInstance()
      .post(route(ApiRoutes.reporting.weeklyTipReport, { chapterId: this.formState.get('chapterId') }), modelToSnakeCase(this.formState.toObject()))
      .then(response => {
        this.chapterData = modelToCamelCase(response.data.chapter) as any
        this.weeks = response.data.weeks
        this.year = Number(response.data.year)
        const reportData = {}
        this.weeks!.forEach(week => {
          reportData[week] = modelToCamelCase(response.data.report_data[week])
        })
        this.reportData = reportData
      })
      .catch(error => this.error = Util.extractErrorMessage(error.response))
      .then(() => this.loading = false)
  }

  render () {
    return <>
      <div className="print-hide">
        <form action="" method="post" onSubmit={this.submit}>
          <div className="row align-items-end">
            {
              !this.props.chapterId
                ? <div className="col-md-4">
                  {this.formHelper.renderAsyncSelectInput({
                    label: 'Chapter',
                    name: 'chapterId',
                    loadOptions: this.props.filterForAdmin ? loadChapterOptionsForAdmin : loadChapterOptions,
                  })}
                </div>
                : null
            }
            <div className="col-md-3">
              {this.formHelper.renderSelectInput({
                label: 'Year',
                name: 'year',
                options: this.yearOptions.map(y => ({ value: y.toString(), text: y.toString() })),
              })}
            </div>
            <div className="col-md-2 d-flex">
              <div className="form-group d-flex flex-column">
                <div className="flex-fill"/>
                <div>
                  <Button
                    disabled={this.loading || !this.formState.get('chapterId') || !this.formState.get('year')}
                    style={{
                      height: 'calc(2.95rem + 2px)'
                    }}
                    type="submit"
                    color="success"
                  ><i className="fa fa-play"/> Run Report</Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {
        this.loading
          ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
          : this.error
          ? <div className="alert alert-danger">{this.error}</div>
          : this.reportData
            ? this.renderReport()
            : <p>Choose report parameters above</p>
      }
    </>
  }

  private renderWeek (month: number, weekIndex: number, reportData: ReportData) {
    if (this.groupedWeeks[month].length > weekIndex) {
      const weeklyData = reportData[this.groupedWeeks[month][weekIndex].format('YYYY-MM-DD')];
      return <>
        <td className="border-left border-top border-bottom text-center">{this.groupedWeeks[month][weekIndex].format('D-MMM')}</td>
        <td className="border-left border-top border-bottom text-center">
          {formatNumber(weeklyData.inside.sent)}
        </td>
        <td className="border-left border-top border-bottom text-center">
          {formatNumber(weeklyData.outside.sent)}
        </td>
        <td className="border-left border-top border-bottom text-center">
          {formatNumber(weeklyData.socialMedia.sent)}
        </td>
        <td className="border-left border-top border-bottom text-center">
          {formatNumber(weeklyData.interChapter.sent)}
        </td>
        <td className="border-left border-top border-bottom text-center">
          {formatNumber(weeklyData.inside.sent + weeklyData.outside.sent + weeklyData.interChapter.sent + weeklyData.socialMedia.sent)}
        </td>
        <td className="border-left border-top border-bottom text-center">
          {formatCurrency(weeklyData.inside.sentValue + weeklyData.outside.sentValue + weeklyData.interChapter.sentValue + weeklyData.socialMedia.sentValue)}
        </td>
      </>
    } else {
      return <>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"/>
      </>
    }
  }

  private renderMonthRow (month: number, reportData: ReportData) {
    const month2 = month + 6
    const weekCount = Math.max(this.groupedWeeks[month].length, this.groupedWeeks[month2].length)

    return <>
      <tr>
        <td className="border-left border-top border-bottom"><b>{moment().month(month).format('MMMM')}</b></td>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"><b>{moment().month(month2).format('MMMM')}</b></td>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"/>
        <td className="border-left border-top border-bottom"/>
      </tr>
      {
        _.range(weekCount).map(weekIndex => <tr key={weekIndex}>
          {this.renderWeek(month, weekIndex, reportData)}
          {this.renderWeek(month2, weekIndex, reportData)}
        </tr>)
      }
    </>
  }

  private renderReport () {
    const chapter = this.chapterData!
    const reportData = this.reportData!
    const year = this.year || 0

    return <>
      <h1 className="text-center my-4">{chapter.name} {year} Tip Totals</h1>
      <div className="form-buttons mb-4 print-hide">
        <Button
          type="button"
          color="primary"
          onClick={() => window.print()}
        ><i className="fa fa-print"/> Print Report</Button>
      </div>

      <table className="chapter-tip-report-table">
        <thead>
        <tr>
          <th className="border-left border-top border-bottom"/>
          <th className="border-left border-top border-bottom">Inside</th>
          <th className="border-left border-top border-bottom">Outside</th>
          <th className="border-left border-top border-bottom">Social-Media</th>
          <th className="border-left border-top border-bottom">Inter-Chapter</th>
          <th className="border-left border-top border-bottom">Total</th>
          <th className="border-left border-top border-bottom">Total $</th>
          <th className="border-left border-top border-bottom"/>
          <th className="border-left border-top border-bottom">Inside</th>
          <th className="border-left border-top border-bottom">Outside</th>
          <th className="border-left border-top border-bottom">Social-Media</th>
          <th className="border-left border-top border-bottom">Inter-Chapter</th>
          <th className="border-left border-top border-bottom">Total</th>
          <th className="border-left border-top border-bottom">Total $</th>
        </tr>
        <tr>
          <th className="border-left border-top border-bottom"/>
          <th className="border-left border-top border-bottom"/>
          <th className="border-left border-top border-bottom"/>
          <th className="border-left border-top border-bottom"/>
          <th className="border-left border-top border-bottom"/>
          <th className="border-left border-top border-bottom"/>
          <th className="border-left border-top border-bottom"/>
          <th className="border-left border-top border-bottom"/>
          <th className="border-left border-top border-bottom"/>
          <th className="border-left border-top border-bottom"/>
          <th className="border-left border-top border-bottom"/>
          <th className="border-left border-top border-bottom"/>
          <th className="border-left border-top border-bottom"/>
          <th className="border-left border-top border-bottom"/>
        </tr>
        </thead>
        <tbody>
        {this.renderMonthRow(0, reportData)}
        {this.renderMonthRow(1, reportData)}
        {this.renderMonthRow(2, reportData)}
        {this.renderMonthRow(3, reportData)}
        {this.renderMonthRow(4, reportData)}
        {this.renderMonthRow(5, reportData)}
        </tbody>
      </table>
    </>
  }
}