import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import SentSystemMessage from "./SentSystemMessage"

export default class SentSystemMessageEvent extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  eventType: string
  eventData: object
  eventTimestamp: Moment
  error: string | null
  errorSeverity: string | null

  sentSystemMessageId: number

  sentSystemMessage: SentSystemMessage

  setSentSystemMessage (t?: {}) {
    return t ? new SentSystemMessage().init(t) : null
  }

  getCasts () {
    return {
      createdAt: 'datetime',
      updatedAt: 'datetime',
      eventTimestamp: 'datetime',
      sentSystemMessageId: 'number',
    }
  }
}
