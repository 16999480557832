import { observer } from 'mobx-react'
import React from 'react'
import { Button } from 'reactstrap'
import { observable } from 'mobx'
import Member from '../../models/Member'
import ApiClient from '../../api/ApiClient'
import { ScheduleNtsTrainingModal } from './ScheduleNtsTrainingModal'
import NtsEventMember from '../../models/NtsEventMember'

type Props = {
  memberId: number
}

@observer
export class ScheduleNtsTrainingButton extends React.Component<Props> {
  @observable private renderScheduleNtsEventModal = false
  @observable private member?: Member

  loadMember = async () => {
    let response = await ApiClient.query(`
    member {
      ntsTrainingCompletedAt
    }`, {
      where: [{ id: this.props.memberId }],
    })

    this.member = new Member(response.data.member)
  }

  componentDidMount () {
    this.loadMember().then()
  }

  render () {
    return <>
      {
        this.member
          ? <Button color="primary" block onClick={() => this.renderScheduleNtsEventModal = true}>
            {this.member.ntsTrainingCompletedAt ? 'View Available NTS Events' : 'Schedule NTS Training'}
          </Button>
          : null
      }
      {
        this.renderScheduleNtsEventModal
          ? <ScheduleNtsTrainingModal onClosed={() => this.renderScheduleNtsEventModal = false} memberId={this.props.memberId}/>
          : null
      }
    </>
  }
}
