import * as React from "react"
import { computed, observable } from "mobx"
import MeetingAttendee from "../models/MeetingAttendee"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { Alert, Button } from "reactstrap"
import { BarLoader } from "react-spinners"
import Util, { safeNull } from "../common/Util"
import Event from "../models/Event"
import { observer } from "mobx-react"
import AppStateStore from "../stores/AppStateStore"
import _ from 'lodash'
import { route } from "../routes/routes"
import MemberProfileImage from "./MemberProfileImage"
import EventGuest from "../models/EventGuest"
import Member from "../models/Member"

type MemberAttendanceItem = {
  member: Member
  status: string | null
}

@observer
export default class AttendanceForm extends React.Component<{
  eventId: number
}> {
  @observable private members: Member[] = []
  @observable private event: Event
  @observable private attendees: MeetingAttendee[] = []
  @observable private eventGuests: EventGuest[] = []
  @observable private loaded = false
  @observable private error?: string = undefined
  @observable private eventGuestStatuses: {[guestId: string]: string} = {}
  @observable private updatedAttendanceStatuses: {[memberId: string]: string} = {}

  @computed get memberAttendanceItems () {
    const members = this.members
    const attendees = this.attendees
    const updatedAttendanceStatuses = this.updatedAttendanceStatuses

    const items: MemberAttendanceItem[] = members.map(m => ({
      member: m,
      status: ''
    }))

    attendees.forEach(attendee => {
      const item = _.find(items, i => i.member.id === attendee.member.id)

      if (!item) {
        items.push({
          member: attendee.member,
          status: attendee.status || '',
        })
      } else {
        item.status = attendee.status || ''
      }
    })

    // need to override status for updated items
    for (let memberId in updatedAttendanceStatuses) {
      const item = _.find(items, i => String(i.member.id) === memberId)
      if (item) {
        item.status = updatedAttendanceStatuses[memberId] || ''
      }
    }

    return _.orderBy(items.filter(item => !!(item.status || item.member.status === 'Active')), 'member.fullName')
  }

  private loadEvent = async () => {
    this.loaded = false
    this.error = undefined
    this.updatedAttendanceStatuses = {}

    try {
      let response = await ApiClient.query(`
    event {
      *
    }
    `, {
        where: [
          { id: this.props.eventId },
        ]
      })

      this.event = new Event().init(response.data.event)

      response = await ApiClient.query(`
        meetingAttendees {
          *
          member {
            *
            category {
              *
            }
          }
        }
        `, {
        where: [
          { eventId: this.props.eventId },
        ],
      })

      this.attendees = _.orderBy(response.data.meetingAttendees, 'member.fullName').map((a: {}) => new MeetingAttendee().init(a))

      response = await ApiClient.query(`
        eventGuests {
          *
          guest {
            *
            category {
              *
            }
          }

          sourceMember {
            *
          }
        }
        `, {
        where: [
          { eventId: this.props.eventId },
          { _scope: 'visibleOnAttendance' },
        ],
      })

      this.eventGuests = _.orderBy(response.data.eventGuests, 'guest.fullName').map((a: {}) => new EventGuest().init(a))
      const eventGuestStatuses = {}
      this.eventGuests.forEach(a => eventGuestStatuses[a.id] = a.attendance || '')
      this.eventGuestStatuses = eventGuestStatuses

      response = await ApiClient.query(`
        members {
          *
          category {
            *
          }
        }
        `, {
        where: [
          { chapterId: this.event.chapterId },
          { _scope: 'active' },
        ],
      })

      this.members = _.orderBy(response.data.members, 'fullName').map((a: {}) => new Member().init(a))

      const attendanceStatuses: {[id: string]: string} = {}
      this.members.forEach(m => {
        const att = _.find(this.attendees, a => a.member.id == m.id)
        attendanceStatuses[m.id] = (att ? att.status : null) || ''
      })

      this.attendees.forEach(attendee => {
        if (!attendanceStatuses[attendee.member.id]) {
          attendanceStatuses[attendee.member.id] = attendee.status || ''
        }
      })

      this.updatedAttendanceStatuses = attendanceStatuses
    } catch (error) {
      this.error = Util.extractErrorMessage(error.response)
      return
    }

    this.loaded = true
  }

  componentDidMount (): void {
    this.loadEvent().then(() => {
    })
  }

  componentDidUpdate (prevProps: Readonly<{eventId: number}>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.eventId !== this.props.eventId) {
      this.loadEvent().then(() => {
      })
    }
  }

  private updateAllUnsetStatus = (status: string) => {
    ApiClient.getInstance().post(route(ApiRoutes.attendance.setUnsetMeetingAttendeeStatuses, { id: this.props.eventId }), { status })
      .then(response => {
        this.loadEvent().then(() => {
        })
      })
      .catch(err => {
        AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
        this.loadEvent().then(() => {
        })
      })
  }

  private updateStatus = (attendee: MemberAttendanceItem, status: string) => {
    this.updatedAttendanceStatuses[String(attendee.member.id)] = status || ''

    ApiClient.getInstance().post(route(ApiRoutes.attendance.setMeetingAttendeeStatus, { eventId: this.event.id, memberId: attendee.member.id }), { status: status })
      .then(response => {
        this.updatedAttendanceStatuses[String(attendee.member.id)] = response.data.status || ''
      })
      .catch(err => {
        AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
        this.loadEvent().then(() => {
        })
      })
  }

  private updateAllUnsetGuestStatus = (status: string) => {
    ApiClient.getInstance().post(route(ApiRoutes.attendance.setUnsetEventGuestAttendance, { id: this.props.eventId }), { attendance: status })
      .then(response => {
        this.loadEvent().then(() => {
        })
      })
      .catch(err => {
        AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
        this.loadEvent().then(() => {
        })
      })
  }

  private updateGuestStatus = (eventGuest: EventGuest, status: string) => {
    this.eventGuestStatuses[eventGuest.id] = status || ''

    ApiClient.getInstance().post(route(ApiRoutes.attendance.setEventGuestAttendance, { id: eventGuest.id }), { attendance: status })
      .then(response => {
        this.eventGuestStatuses[eventGuest.id] = response.data.event_guest.attendance || ''
      })
      .catch(err => {
        AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
        this.loadEvent().then(() => {
        })
      })
  }

  @computed
  private get isAttendanceComplete () {
    return this.memberAttendanceItems.filter(a => !a.status).length === 0
  }

  private renderAttendance = () => {
    return <>
      <div className="row">
        <div className="col-md-6">
          <h2>Members</h2>
          {
            !this.isAttendanceComplete
              ? <Alert color="warning">
                You have not marked the attendance status of all members. Please make sure to set at status for all applicable members to ensure correct reporting.
              </Alert>
              : null
          }
          {this.memberAttendanceItems.length ? <>
            <div className="text-right">
              <Button
                type="button"
                color="primary"
                size="sm"
                onClick={() => this.updateAllUnsetStatus('Present')}
              >Mark all unset present</Button>
            </div>
            <br/>
            <table className="table table-striped">
              <tbody>
              {this.memberAttendanceItems.map(a => <tr key={a.member.id}>
                <td>
                  <div className="d-flex">
                    <div>
                      <MemberProfileImage
                        size={45}
                        profileImageUrl={a.member.profileImageUrl}
                        memberName={a.member.fullName}
                      />
                    </div>
                    <div className="flex-fill ml-2 mr-2">
                      <div>{a.member.fullName}</div>
                      <div className="text-muted">{a.member.category!.name}</div>
                    </div>
                  </div>
                </td>
                <td>
                  <select
                    className="form-control"
                    value={a.status || ''}
                    onChange={ev => this.updateStatus(a, ev.target.value)}
                  >
                    <option value="">(not set)</option>
                    {[
                      'Present',
                      'Excused',
                      'Unexcused',
                      'Late',
                      'Alternate',
                      'Left Early',
                      'Leave Of Absence',
                    ].map(v => <option key={v} value={v}>{v}</option>)}
                  </select>
                </td>
              </tr>)}
              </tbody>
            </table>
          </> : <div>There are no members for this meeting</div>}
        </div>
        <div className="col-md-6">
          <h2>Guests</h2>
          {
            this.eventGuests.length
              ? <>
                <div className="text-right">
                  <Button
                    type="button"
                    color="primary"
                    size="sm"
                    onClick={() => this.updateAllUnsetGuestStatus('Present')}
                  >Mark all unset present</Button>
                </div>
                <br/>
                <table className="table table-striped">
                  <tbody>
                  {this.eventGuests.map(g => <tr key={g.id}>
                    <td>
                      <div>{g.guest.fullName}</div>
                      <div className="text-muted">{safeNull(() => g.guest.category!.name) || '---'}</div>
                      <div className="text-muted">Invited by {g.sourceMember.fullName}</div>
                    </td>
                    <td>
                      <select
                        className="form-control"
                        value={this.eventGuestStatuses[g.id]}
                        onChange={ev => this.updateGuestStatus(g, ev.target.value)}
                      >
                        <option value="">(not set)</option>
                        {[
                          'Present',
                          'Not Present',
                        ].map(v => <option key={v} value={v}>{v}</option>)}
                      </select>
                    </td>
                  </tr>)}
                  </tbody>
                </table>
              </>
              : <div>There are no guests for this meeting</div>
          }
        </div>
      </div>
    </>
  }

  render (): React.ReactNode {
    return this.loaded ?
      this.error
        ? <Alert color="danger">There was an error loading the attendance data for this event: {this.error}</Alert>
        : this.renderAttendance()
      : <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
  }
}
