import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import Invoice from "./Invoice"

export enum InvoiceLineItemType {
  Charge = 'Charge',
  Adjustment = 'Adjustment',
  Tax = 'Tax',
}

export default class InvoiceLineItem extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  invoiceId: number
  type: InvoiceLineItemType
  description: string
  amount: number

  invoice: Invoice

  getCasts () {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      invoiceId: 'number',
      amount: 'number',
    }
  }

  setInvoice (i?: {}) {
    return i ? new Invoice().init(i) : null
  }
}