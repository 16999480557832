import { observer } from 'mobx-react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import React from 'react'
import { observable, toJS } from 'mobx'
import Util from '../../common/Util'
import ApiClient, { ApiRoutes } from '../../api/ApiClient'
import { route } from '../../routes/routes'
import LoadingPanel from '../LoadingPanel'
import _ from 'lodash'

type NewChaptersData = {
  newChaptersCount: {
    day: number
    month: number
    year: number
  }
}

type Props = {
  filters: {
    state?: string
    areaId?: number
    chapterId?: number
  }
  onShowChaptersList: (url: string, title: string) => void
}

@observer
export default class NewChaptersDashboard extends React.Component<Props> {
  @observable
  private newChapters?: NewChaptersData
  @observable
  private error?: string
  @observable
  private loading = false

  componentDidMount () {
    this.loadData().then()
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (!_.isEqual(toJS(prevProps.filters), toJS(this.props.filters))) {
      this.loadData().then()
    }
  }

  private loadData = async () => {
    try {
      this.error = undefined
      this.loading = true

      const response = await ApiClient.getInstance().get(route(ApiRoutes.dashboard.newChapters, {}, this.props.filters))

      this.newChapters = response.data

      this.loading = false
    } catch (err) {
      this.error = Util.extractErrorMessage(err.response)
    }
  }

  render () {
    return <Card>
      <CardHeader>
        <b>New Chapters</b>
      </CardHeader>
      <CardBody>
        <LoadingPanel loading={this.loading} loaded={!!this.newChapters} error={this.error}>
          {
            this.newChapters
              ? <>
                <div className="d-flex">
                  <div className="flex-fill">
                    Today
                  </div>
                  <div className="text-right">
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.props.onShowChaptersList(route(ApiRoutes.dashboard.newChapters, {}, {
                        ...this.props.filters,
                        list: 'day',
                      }), 'New Chapters Today')
                    }}>
                    {Util.formatNumber(this.newChapters!.newChaptersCount.day)}
                    </a>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="flex-fill">
                    This Month
                  </div>
                  <div className="text-right">
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.props.onShowChaptersList(route(ApiRoutes.dashboard.newChapters, {}, {
                        ...this.props.filters,
                        list: 'month',
                      }), 'New Chapters This Month')
                    }}>
                    {Util.formatNumber(this.newChapters!.newChaptersCount.month)}
                    </a>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="flex-fill">
                    This Year
                  </div>
                  <div className="text-right">
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.props.onShowChaptersList(route(ApiRoutes.dashboard.newChapters, {}, {
                        ...this.props.filters,
                        list: 'year',
                      }), 'New Chapters This Year')
                    }}>
                    {Util.formatNumber(this.newChapters!.newChaptersCount.year)}
                    </a>
                  </div>
                </div>
              </>
              : null
          }
        </LoadingPanel>
      </CardBody>
    </Card>
  }
}
