import * as React from "react"
import BaseView from "./BaseView"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import { Link, Redirect, RouteComponentProps } from "react-router-dom"
import { computed, observable } from "mobx"
import { observer } from "mobx-react"
import AppStateStore from "../stores/AppStateStore"
import ApiClient from "../api/ApiClient"
import { BarLoader } from "react-spinners"
import LazyResource from "../models/LazyResource"
import BackLink from "../components/BackLink"
import Guest from "../models/Guest"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import { SendApplicationModal } from "../components/SendApplicationModal"
import pluralize from 'pluralize'
import RequirePermission, { Permission } from "../components/RequirePermission"
import GuestDetailForm from "../components/GuestInfoForm"
import { safeNull } from "../common/Util"
import _ from 'lodash';
import InviteGuestToMeetingForm from "../components/InviteGuestToMeetingForm"
import AuthStore from "../stores/AuthStore"
import NotesList from "../components/NotesList"
import BankCode from '../components/BankCode'
import Application from '../models/Application'

type Props = {
  match: {
    params: {
      id: number
    }
  }
}

@observer
export default class GuestDetailView extends BaseView<Props & RouteComponentProps> {
  @observable private redirect = false
  @observable private showSendApplicationModal = false
  @observable private guest = new LazyResource<Guest>((callback, error) => {
    ApiClient.query(
      `
guest {
  id
  firstName
  lastName
  fullName
  companyName
  phone
  email
  applicationSentAt
  bankCode
  bankCodeDocumentUrl
  
  numberOfMeetingsAttended
  
  guestEvents {
    *
    event {
      *
      chapter {
        *
      }
    }
    sourceMember {
      *
    }
  }

  category {
    id
    name
  }
  auditLogs {
    *
  }
  
  applications {
    *
  }
  
  convertedMember {
    *
  }
  
  createdByMember {
    *
  }
}
      `,
      { where: [{ id: this.props.match.params.id }] }
    )
      .then(response => callback(new Guest().init(response.data.guest)))
      .catch(() => {
        error('There was an error loading the guest data')

        AppStateStore.showAlertModal('Error', 'There was an error loading the guest data', m => {
          m.hide()
          this.redirect = true
        })
      })
  })

  @computed get activeApplication (): Application | undefined {
    return this.guest.current ? _.find(this.guest.current.applications, app => app.status != 'Denied') : undefined
  }

  @computed get pastApplications () {
    return this.guest.current ? _.filter(this.guest.current.applications, app => app.status == 'Denied') : []
  }

  @observable
  private showModal = false
  @observable
  private editModalContent?: React.ReactNode
  @observable
  private editModalSize = 'lg'
  @observable
  private editModalTitle = 'Edit'

  private showEditModal = (title: string, component: React.ReactNode, size: string = 'lg') => {
    this.editModalTitle = title
    this.editModalContent = component
    this.showModal = true
    this.editModalSize = size
  }

  private closeEditModal = () => {
    this.showModal = false
    this.editModalContent = undefined
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <BackLink/>
        <h1>Guest Detail{this.guest.current && ` for ${this.guest.current.fullName}`}</h1>
      </>
    )
  }

  componentDidMount (): void {
    super.componentDidMount()

    if (!this.props.match.params.id) {
      this.redirect = true
    }
  }

  private sendApplication = () => {
    if (!this.guest.current!.category) {
      AppStateStore.showAlertModal('Category Required', 'You must choose a category for this guest before sending an application')
    } else {
      this.showSendApplicationModal = true
    }
  }

  render (): React.ReactNode {
    return this.redirect
      ? this.renderRedirect()
      : !this.guest.current || !this.guest.loaded ? this.renderLoading() : this.renderContent()
  }

  private renderLoading = () => <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>

  private renderContent = () => {
    const guest = this.guest.current
    const activeApplication = this.activeApplication

    return guest ? (
      <>
        <div className="row">
          <div className="col-md-5 col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>
                  Guest Information <RequirePermission permission={Permission.EditGuests}>
                  {() =>
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.showEditModal('Guest Info',
                        <GuestDetailForm
                          guest={guest}
                          onCancel={() => this.closeEditModal()}
                          onSaved={() => {
                            this.closeEditModal()
                            this.guest.invalidate().then(() => {
                            })
                          }}
                        />)
                    }}><i className="fa fa-pencil"/></a>
                  }</RequirePermission>
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <h5 style={{ marginBottom: 12 }}>{guest.fullName}</h5>
                  </div>
                  <div className="col-sm-6">
                    {
                      guest.bankCode
                        ? <BankCode bankCode={guest.bankCode} documentUrl={guest.bankCodeDocumentUrl!}/>
                        : null
                    }
                  </div>
                </div>
                <div className="stacked-rows" style={{ fontSize: 13 }}>
                  <div className="row">
                    <div className="col-sm-6">
                      Company Name:
                    </div>
                    <div className="col-sm-6">
                      {guest.companyName}
                    </div>
                  </div>
                  {<div className="row">
                    <div className="col-sm-6">
                      Category:
                    </div>
                    <div className="col-sm-6">
                      {safeNull(() => guest.category!.name) || <span><b className="text-danger">PLACEHOLDER</b> <a href="" onClick={ev => {
                        ev.preventDefault()
                        this.showEditModal('Guest Info',
                          <GuestDetailForm
                            showCategoryOnly={true}
                            guest={guest}
                            onCancel={() => this.closeEditModal()}
                            onSaved={() => {
                              this.closeEditModal()
                              this.guest.invalidate().then(() => {
                              })
                            }}
                          />)
                      }}><i className="fa fa-pencil"/></a></span>}
                    </div>
                  </div>}
                  <div className="row">
                    <div className="col-sm-6">
                      Invited By:
                    </div>
                    <div className="col-sm-6">
                      {safeNull(() => guest.createdByMember!.fullName) || '---'}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      Phone Number:
                    </div>
                    <div className="col-sm-6">
                      {guest.phone}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      Email Address:
                    </div>
                    <div className="col-sm-6">
                      {guest.email}
                    </div>
                  </div>
                </div>
                <hr/>
                <div>
                  <div className="mb-2">
                    <div>{
                      activeApplication
                        ? <span>Application sent on <b>{activeApplication.createdAt.format('MM/DD/YYYY')}</b></span>
                        : 'This guest has not been sent an application'
                    }</div>
                    {activeApplication && activeApplication.isCompleted && <div>
                      <span>Application submitted on <b>{activeApplication.submittedAt.format('MM/DD/YYYY')}</b></span>
                    </div>}

                    {
                      guest.canUserSendApplication(AuthStore.getUser()!)
                      && (!activeApplication || !activeApplication.isCompleted)
                      && <Button
                        color="primary"
                        size="sm"
                        block
                        onClick={this.sendApplication}
                      >
                        {activeApplication ? 'Send Reminder' : 'Send Application'}
                      </Button>
                    }
                  </div>
                </div>
                {activeApplication && <div className="mt-4">
                  <div className="d-flex mb-2">
                    <div className="pr-4"><b>Application Status:</b></div>
                    <div>
                      {activeApplication.status}
                    </div>
                  </div>
                  <RequirePermission permission={Permission.ViewApplication} context={{ chapterId: activeApplication.targetChapterId }}>
                    {
                      () => activeApplication!.isCompleted
                        ? <Link to={route(Routes.system.applications.show, { id: activeApplication!.id })}
                                className="btn btn-primary btn-sm btn-block">
                          View Application
                        </Link>
                        : null
                    }
                  </RequirePermission>
                </div>}
                {
                  this.pastApplications.length
                    ? <div>
                      <hr/>
                      <b>Previous Applications</b>
                      {
                        this.pastApplications.map(app => <div>
                          Denied {app.processedAt ? `on ${app.processedAt.format('MM/DD/YYYY')}` : null} (<Link
                          to={route(Routes.system.applications.show, { id: app.id })}>
                          View
                        </Link>)
                        </div>)
                      }
                    </div>
                    : null
                }
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <div className="flex-fill">
                    <h5>
                      Invitations
                    </h5>
                  </div>
                  {
                    !guest.convertedMember
                      ? <div>
                        <Button
                          type="button"
                          color="primary"
                          onClick={() => this.showEditModal('Invite Guest To Meeting', <InviteGuestToMeetingForm guestId={guest.id} onCancel={() => this.closeEditModal()} onSaved={() => {
                            this.closeEditModal()
                            this.guest.invalidate()
                          }}/>)}
                        >Invite To A Meeting</Button>
                      </div>
                      : null
                  }
                </div>
              </div>
              <div className="card-body">
                {
                  guest.guestEvents.map(guestEvent => <div key={guestEvent.id}>
                    <b>{guestEvent.event.startsAt.clone().tz(guestEvent.event.timezone).format('MM/DD/YYYY h:mm a')}</b><br/>
                    <Link to={route(Routes.network.chapter, { chapterId: guestEvent.event.chapter!.id })}>{guestEvent.event.chapter!.name}</Link><br/>
                    {guestEvent.event.title}
                  </div>)
                }
              </div>
            </div>
          </div>

          <div className="col-md-7 col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Activity History</h5>
                {
                  guest.numberOfMeetingsAttended
                    ? `${guest.numberOfMeetingsAttended} ${pluralize('meeting', guest.numberOfMeetingsAttended)} attended`
                    : null
                }
              </div>
              <div className="card-body">
                {
                  guest.auditLogs.length
                    ? guest.auditLogs.map(log => <div key={log.id}>
                      <b>{log.createdAt.format('MM/DD/YYYY')}</b><br/>
                      {log.description}
                    </div>)
                    : 'There is no activity for this guest'
                }
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <h5>Notes</h5>
              </div>
              <div className="card-body">
                <NotesList guestId={guest.id}/>
              </div>
            </div>
          </div>
        </div>

        <SendApplicationModal
          isOpen={this.showSendApplicationModal}
          toggle={() => this.showSendApplicationModal = !this.showSendApplicationModal}
          onCancel={() => this.showSendApplicationModal = false}
          onSaved={() => {
            this.showSendApplicationModal = false
            this.guest.invalidate().then(() => {
            })
          }}
          guest={this.guest.current!}
          isReminder={!!(this.guest.current && activeApplication)}
        />

        <Modal
          isOpen={this.showModal}
          size={this.editModalSize}
        >
          <ModalHeader>{this.editModalTitle}</ModalHeader>
          <ModalBody>{this.editModalContent}</ModalBody>
        </Modal>
      </>
    ) : null
  }

  private renderRedirect = () => <Redirect to={route(Routes.system.guests.index)}/>
}
