import { action, computed, observable, toJS } from "mobx"
import { ChangeEvent } from "react"
import * as _ from 'lodash'

export default class FormState<P> {
  @observable
  private readonly data: P
  @observable private _isDirty = false

  @computed get isDirty () {
    return this._isDirty
  }

  constructor (data: P) {
    this.data = data
  }

  @action
  set (k: keyof P | string, v: any) {
    if (this.get(k) !== v) {
      this._isDirty = true
    }

    _.set(this.data as {}, k, v)
  }

  @action
  setAll (data: P | any, prefix: string = '') {
    Object.getOwnPropertyNames(data).forEach(p => this.set(`${prefix}${p}`, data[p]))
  }

  get (k: keyof P | string): any {
    return _.get(this.data, k)
  }

  @action
  clearDirty () {
    this._isDirty = false
  }

  onChange = (ev: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    let val: any = ev.target.value

    if (ev.target.type === 'checkbox') {
      val = (ev as ChangeEvent<HTMLInputElement>).target.checked
    }

    this.set(ev.target.name as keyof P, val)
  }

  toObject: () => P = () => {
    return toJS(this.data)
  }
}
