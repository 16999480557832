import BaseModel from "./BaseModel"
import { Moment } from "moment-timezone"
import NtsEvent from './NtsEvent'
import Member from './Member'

export default class NtsEventMember extends BaseModel {
  id: number
  createdAt: Moment
  updatedAt: Moment
  ntsEventId: number
  memberId: number
  didAttend: boolean

  ntsEvent: NtsEvent
  member: Member

  getCasts () {
    return {
      id: 'number',
      createdAt: 'datetime',
      updatedAt: 'datetime',
      ntsEventId: 'number',
      memberId: 'number',
      didAttend: 'boolean',
    }
  }

  setNtsEvent (u?: {}) {
    return u ? new NtsEvent().init(u) : null
  }

  setMember (m?: {}) {
    return m ? new Member().init(m) : null
  }
}
