import CsvTransformer from "../models/renderers/CsvTransformer"
import { TableViewColumn } from "../components/table-view/TableView"
import * as _ from "lodash"

export default class DynamicReportCsvTransformer extends CsvTransformer<any> {
  constructor (public filename: string, public columnDefs: TableViewColumn[]) {
    super()

    columnDefs.forEach(column => {
      this.addColumn({
        header: column.title!,
        valueTransformer: row => column.renderItem ? column.renderItem(_.get(row, column.accessor!), row, column) : _.get(row, column.accessor!),
      })
    })
  }
}